<div
  class="helpdesk-toast-container d-flex flex-column"
  [ngStyle]="element.coordinates"
>
  <div class="toast-content">
    <div class="action-header d-flex flex-row-reverse">
      <i
        class="pi pi-times closeToaster cursor-pointer"
        aria-hidden="true"
        (click)="closeToast()"
        id="btn_HelpToastClose"
      ></i>
    </div>

    <p class="toast-header ps-3 m-0" id="value_HelpToastOperation" >{{ operation }}</p>

    <span class="toast-message ps-3 d-flex" id="label_HelpTixAssignName"
      >{{translate?.ticket.messages.this}}&nbsp;{{translate?.ticket.label.singular}}&nbsp;{{translate?.ticket.messages.hasBeenAssignedTo }}&nbsp;{{ userfullname }}</span
    >

    <div class="action-footer d-flex flex-row-reverse">
      <span class="undo cursor-pointer" id="link_HelpUndoAssign" (click)="onUndo()">{{ translate?.general.buttons.undo.singular}}</span>
    </div>
  </div>

  <div class="toast-progress-bar-container">
    <div
      class="bar"
      [ngStyle]="{
        width: barWidth + '%',
        'border-radius':
          barWidth === 100 ? '0px 0px 5px 5px' : '0px 5px 5px 5px'
      }"
    ></div>
  </div>
</div>
