import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderTitleService {
  public refreshMenuType$: EventEmitter<any>;
  public refreshSide$: EventEmitter<any>;
  title = new BehaviorSubject('Custella-WFC');

  private currentMenuType: any;

  constructor(){
    this.refreshMenuType$ = new EventEmitter();
    this.refreshSide$ = new EventEmitter();
  }

  setTitle(title: string) {
    this.title.next(title);
  }

  public menuType(type : any){
    this.currentMenuType = type;
    this.refreshMenuType$.emit(type);
  }

  public refreshSideBar(){
    this.refreshSide$.emit();
  }

  public getCurrentMenuType() {
    return this.currentMenuType;
  }
}
