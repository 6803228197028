import { LatLngBounds } from "../latLngBounds";
import { ComponentRestrictions } from "./componentRestrictions";

export class Options {
    public bounds: LatLngBounds | undefined;
    public componentRestrictions: ComponentRestrictions | undefined;
    public types: string[] | undefined;

    public constructor(opt?: Partial<Options>) {
        if (!opt)
            return;

        Object.assign(this, opt);
    }
}