import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingService } from 'src/app/_services/admin/setting.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';

import { EngLangData,MalayLangData, chineseLangData, languageOptions } from "src/app/_models/language.data.model";
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  connect : any = {};
  connectForm: UntypedFormGroup;
  submitTouched:boolean=false;
  currentLang: any = 'English'
 
  @ViewChild('chooseLang') chooseLangEl: ElementRef;

  langData: any;
  languageList: any;
  translate: any;
  constructor(private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private settingService : SettingService,
    private languageTranslateService: LanguageTranslateService) { 
      super()
    this.connectForm = new UntypedFormGroup({
      'clientId': new UntypedFormControl(null, [Validators.required]),
      'clientSecret': new UntypedFormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    // console.log("SalesForce Connect")
    this.langData = EngLangData   ;
    this.currentLang = "English"
    this.languageList = Object.values(languageOptions);
    this.getLanguageData()
  }

  connectSalesforce(){
    // this.spinner.show();
    this.submitTouched=true;
    if(!this.connectForm.valid){
      for (var i in this.connectForm.controls) {
        this.connectForm.controls[i].markAsTouched();
      }
      // this.spinner.hide();
      return false;
    }
    
    var data = {
      "clientId": this.connectForm.value.clientId,
      "clientSecret": this.connectForm.value.clientSecret,
    }

    this.settingService.connectToSalesforce(data).subscribe(res=>{
      // console.log("SF Resp ", res);
      if(res.status == 200){
        window.location.href = res.body.webRedirectUrl;
      }
    });
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        console.log("translate",this.translate)
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.checklist.label.plural
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
