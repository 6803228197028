import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HillsService {
  // Hills Dashboard
  private ENDPOINT_LIST_HILLS_BOX_CHART = "/hills/box-chart";
  private ENDPOINT_LIST_HILLS_FAULT_BY_SYSTEM = "/hills/fault-by-system";
  private ENDPOINT_LIST_HILLS_PIE_CHART = "/hills/pie-chart";
  private ENDPOINT_LIST_HILLS_LINE_CHART = "/hills/line-chart";
  private ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH =
    "/hills/closed-job-per-month";
  private ENDPOINT_LIST_HILLS_CLOSED_JOB_BY_TIMEFRAME =
    "/hills/closed-job-by-timeframe";
  private ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH_BY_TECHNICIAN =
    "/hills/closed-job-per-month-by-technician";
  // private ENDPOINT_GET_LAST_SIX_MONTH_LIST="/hills/get-last-six-month-list";

  // customer inside dashboard
  private ENDPOINT_FAULT_TYPE_BY_BAR_CHART =
    "/hills/fault-type-by-system/bar-chart";
  private ENDPOINT_RESOLUTION_BY_SYSTEM_BAR_CHART =
    "/hills/resolution-by-system/bar-chart";
  private ENDPOINT_NUMBER_OF_FAULTS_LOGGED = "/hills/number-of-faults-logged";
  private ENDPOINT_LINE_CHART_FAULTS_ABLE_TO_FIX =
    "/hills/line-chart/faults-able-to-fix";
  private ENDPOINT_LINE_CHART_BILLABLE_JOBS = "/hills/line-chart/billable-jobs";
  private ENDPOINT_PRODUCT_COUNT = "/hills/product-count";
  private ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH_BY_TECHNICIAN_V2 =
    "/hills/v2/closed-job-per-month-by-technician";
  private ENDPOINT_JOB_BY_MONTH = "/hills/jobs-by-month";
  private ENDPOINT_RED_FLAGS = "/hills/red-flag-jobs-by-month";
  private ENDPOINT_JOBS_BY_TECHNICIAN = "/hills/jobs-by-technician";

  constructor(private http: HttpClient) {}

  ///////////////////////////////////// hills dashboard//////////////////////////////////////////////
  public getBoxesData() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_HILLS_BOX_CHART)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getBarChart() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_HILLS_FAULT_BY_SYSTEM)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getPieChart() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_HILLS_PIE_CHART)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getLineChart() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_HILLS_LINE_CHART)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getHorizontalStacked(redFlag: any) {
    let params = new HttpParams();
    params = params.set("redFlag", redFlag);
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getVerticalStacked(byState: any) {
    let params = new HttpParams();
    params = params.set("byState", byState);
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_LIST_HILLS_CLOSED_JOB_BY_TIMEFRAME,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTechniciansData() {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH_BY_TECHNICIAN
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  ////////////////////////////////// customer inside dashboard ////////////////////////////////////////
  public getVerticalStackedByMonth(customerId: any, groupBy: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("groupBy", groupBy);
    params = params.set("months", months);
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_LIST_HILLS_CLOSED_JOB_BY_TIMEFRAME,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/fault-type-by-system/bar-chart?customerId=&months=6&system=HTR
  public getClosedFaultType(customerId: any, months: any, system: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    params = params.set("system", system);
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_TYPE_BY_BAR_CHART, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  // {{ip}}/api/hills/resolution-by-system/bar-chart?customerId=&months=6&system=HTR
  public getResolutionType(customerId: any, months: any, system: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    params = params.set("system", system);
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_RESOLUTION_BY_SYSTEM_BAR_CHART,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/number-of-faults-logged?customerId=&months=12
  public getFaultsData(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_NUMBER_OF_FAULTS_LOGGED, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/line-chart/faults-able-to-fix?months=12&ableToFix=true
  public getFaultFixed(months: any, ableToFix: any) {
    let params = new HttpParams();
    params = params.set("months", months);
    params = params.set("ableToFix", ableToFix);
    return this.http
      .get(environment.base_url + this.ENDPOINT_LINE_CHART_FAULTS_ABLE_TO_FIX, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/line-chart/billable-jobs?months=12&billable=true
  public getBillableJobs(months: any, billable: any) {
    let params = new HttpParams();
    params = params.set("months", months);
    params = params.set("billable", billable);
    return this.http
      .get(environment.base_url + this.ENDPOINT_LINE_CHART_BILLABLE_JOBS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/product-count?customerId=15&months=12
  public getProductUsage(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_PRODUCT_COUNT, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/jobs-by-month?customerId=&months=12
  public getClosedJobOrders(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOB_BY_MONTH, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/red-flag-jobs-by-month?customerId=&months=12
  public getClosedRedFlags(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_RED_FLAGS, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/jobs-by-technician?customerId=&months=12
  public getJobsTechnician(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOBS_BY_TECHNICIAN, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
