import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { SharedModule } from "../shared/shared.module";
import { PublicRoutingModule } from "./public-routing.module";
import { PublicComponent } from "./public.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { PasswordActivationComponent } from "./password-activation/password-activation.component";
import { ProfileInfoComponent } from "./profile-info/profile-info.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { GoogleCalendarComponent } from './google-calendar/google-calendar.component';
import { ConnectGoogleCalenderFailureComponent } from './google-calendar/connect-google-calender-failure/connect-google-calender-failure.component';
import { ConnectGoogleCalenderSuccessComponent } from './google-calendar/connect-google-calender-success/connect-google-calender-success.component';
import { DisconnectConfmationComponent } from './google-calendar/disconnect-confmation/disconnect-confmation.component';
import { CalendlyThemeComponent } from './calendly-theme/calendly-theme.component';
import { CustomerSurveyComponent } from './customer-survey/customer-survey.component';
import { PreviewCustomerSurveyComponent } from './preview-customer-survey/preview-customer-survey.component';
import { ConnectComponent } from './sales-force/connect/connect.component';
import { LoginFromPhoneComponent } from './login-from-phone/login-from-phone.component';

@NgModule({
  imports: [CommonModule, PublicRoutingModule, SharedModule],
  declarations: [
    PublicComponent,
    LoginComponent,
    ForgotPasswordComponent,
    PasswordActivationComponent,
    ProfileInfoComponent,
    ResetPasswordComponent,
    GoogleCalendarComponent,
    ConnectGoogleCalenderFailureComponent,
    ConnectGoogleCalenderSuccessComponent,
    DisconnectConfmationComponent,
    CalendlyThemeComponent,
    CustomerSurveyComponent,
    PreviewCustomerSurveyComponent,
    ConnectComponent,
    LoginFromPhoneComponent,
  ],
})
export class PublicModule {}
