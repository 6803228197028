<div class="m-0">
    <div class="rounded-2">
        <div class="custella-form-container height-80 overflow-scroll position-relative d-block  m-0">
            <form [formGroup]="addJourneyForm">
                <!-- Container Content -->
                <div class="custella-form-container-title-two text-14 font-medium" id="title_TaskJourneyDet" >
                    {{ translate?.task.sections.sec5.singular }}
                </div>
                <div class="custella-form-container-content">
                    <!-- equipment_type -->
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskJourneyDateTime" >{{ translate?.task.fields.dateTime.singular }}</span>
                            <p-calendar [ngModelOptions]="{standalone: true}" readonly="true" id="picklist_TaskJourneyDateTimeField"
                                (onSelect)="onDateChange($event)" appendTo="body" pattern="hh:mm a"
                                dateFormat="dd/mm/yy" hourFormat="12" [(ngModel)]="addJourney.startDateTime"
                                [showTime]="true" [showSeconds]="false" class="me-1"
                                [placeholder]="translate?.general.messages.none" [yearNavigator]="true"
                                yearRange="2000:2030" appendTo="body"></p-calendar>
                            <div class="text-10 p-error"
                                *ngIf="addJourneyForm.controls['startDateTime'].touched && addJourneyForm.controls['startDateTime'].invalid">
                                <span *ngIf="addJourneyForm.controls['startDateTime'].errors?.required" id="label_TaskJourneyDateTimeReqField"
                                    >{{translate?.general.validationFE.requiredField}}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskJourneyUser" >{{ translate?.task.fields.user.singular }}</span>
                            <p-dropdown [options]="users" optionLabel="firstName" name="userId" id="picklist_TaskJourneyUserField"
                            [placeholder]="translate?.general.messages.none" formControlName="userId"
                                [(ngModel)]="addJourney.userId" optionValue="id" [showClear]="false" appendTo="body">
                                <ng-template let-user pTemplate="user">
                                    <div class="country-item" id="value_TaskJourneyUserField">
                                        <div>{{user.firstName}} {{user.lastName}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="text-10 p-error"
                                *ngIf="addJourneyForm.controls['userId'].touched && addJourneyForm.controls['userId'].invalid">
                                <span *ngIf="addJourneyForm.controls['userId'].errors?.required" id="label_TaskJourneyUserReqField"
                                    >{{translate?.general.validationFE.requiredField}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  Equipment -->
                <div class="custella-form-container-title-two text-14 font-medium" id="title_TaskJourneyStartEndLoc" >{{ translate?.task.sections.sec19.singular }}
                </div>
                <div class="custella-form-container-content">
                    <!-- equipment_type -->
                    <div class="custella-content-row pe-0 mb-3">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskJourneyLocFrom" >{{ translate?.task.fields.from.singular }}</span>
                            <div class="d-flex align-items-center">
                                <div class="col-10 col-xxl-10 d-flex p-0" *ngIf="addJourney.fromType == 'Task'">
                                    <p-dropdown [options]="locationByTaskData" name="fence" [placeholder]="translate?.general.messages.none"
                                        formControlName="fromTaskId" [(ngModel)]="addJourney.fromTaskId" id="picklist_TaskJourneyLocFromField"
                                        (onChange)="getTaskDetail('startLocation',addJourney.fromTaskId)"
                                        [showClear]="true" appendTo="body" optionLabel="customeName" optionValue="id"
                                        class="col-6 pl-0">
                                    </p-dropdown>
                                    <input pInputText autocomplete="off" readonly formControlName="startLocation" id="input_TaskJourneyLocFromField"
                                        [(ngModel)]="addJourney.startLocation.address" name="startLocation"
                                        [placeholder]="translate?.general.messages.none" />

                                </div>
                                <div class="col d-flex p-0" *ngIf="addJourney.fromType != 'Task'">
                                    <input pInputText autocomplete="off" id="input_TaskJourneySearchLocField"
                                    [placeholder]="translate?.general.messages.none"
                                        (keyup)="getPlaceApiCall($event)" formControlName="startLocation"
                                        name="startLocation" autocorrect="off" autocapitalize="off" spellcheck="off"
                                        type="text" (change)="manualSearch('home')" #input_TaskJourneySearchLocField
                                        [(ngModel)]="addJourney.startLocation.address">
                                </div>
                                <div class="d-flex align-items-center justify-content-end ms-auto">
                                    <button class="btn-list-icon ms-2"   (click)="setStartLocation('Home')" id="btn_TaskJourneyFromHome" [pTooltip]="translate?.task.fields.home.singular" tooltipPosition="top">
                                        <i class="fa-regular fa-house"></i>
                                    </button>
                                    <button class="btn-list-icon ms-2" (click)="setStartLocation('Office')" id="btn_TaskJourneyFromOffice" [pTooltip]="translate?.task.fields.office.singular" tooltipPosition="top">
                                        <i class="fa-regular fa-briefcase"></i>
                                    </button>
                                    <button class="btn-list-icon ms-2" (click)="setStartLocation('Task')" id="btn_TaskJourneyFromTask" [pTooltip]="translate?.task.label.singular" tooltipPosition="top">
                                        <i class="fa-regular fa-circle-check"></i>
                                    </button>
                                    <button class="btn-list-icon ms-2" (click)="setStartLocation('Current Location')" id="btn_TaskJourneyFromCurrent" [pTooltip]="translate?.task.fields.currentLocation.singular" tooltipPosition="top">
                                        <i class="fa-regular fa-location-dot"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-10 d-flex p-0">

                                <div class="col-6 pl-0" *ngIf="addJourney.fromType == 'Task'">
                                    <div class="text-10 p-error"
                                        *ngIf="addJourneyForm.controls['fromTaskId'].touched && addJourneyForm.controls['fromTaskId'].invalid">
                                        <span *ngIf="addJourneyForm.controls['fromTaskId'].errors?.required" id="label_TaskJourneyLocFromReqField"
                                            >{{translate?.general.validationFE.requiredField}}</span>
                                    </div>
                                </div>
                                <div class="col-6 p-0" [ngClass]="{'col-md-12': addJourney.fromType != 'Task'}">
                                    <div class="text-10 p-error"
                                        *ngIf="addJourneyForm.controls['startLocation'].touched && addJourneyForm.controls['startLocation'].invalid">
                                        <span *ngIf="addJourneyForm.controls['startLocation']?.errors?.required" id="label_TaskJourneyStartLocReqField"
                                            >{{translate?.general.validationFE.requiredField}}</span>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>
                    <div class="custella-content-row pe-0 mb-3">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskJourneyLocTo" >{{ translate?.task.fields.to.singular }}</span>
                            <div class="d-flex align-items-center">
                                <div class="col-10 col-xxl-10 d-flex p-0" *ngIf="addJourney.toType == 'Task'">
                                    <p-dropdown [options]="locationByEndTaskData" name="fence" [placeholder]="translate?.general.messages.none" id="picklist_TaskJourneyLocToField"
                                        formControlName="toTaskId" [(ngModel)]="addJourney.toTaskId"
                                        (onChange)="getTaskDetail('endLocation',addJourney.toTaskId)" [showClear]="true"
                                        appendTo="body" optionLabel="customeName" optionValue="id" class="col-6 ps-0">

                                    </p-dropdown>
                                    <input pInputText autocomplete="off" readonly formControlName="endLocation" id="input_TaskJourneyLocToField"
                                        [(ngModel)]="addJourney.endLocation.address" name="endLocation"
                                        [placeholder]="translate?.general.messages.none" />
                                </div>
                                <div class="col d-flex p-0" *ngIf="addJourney.toType != 'Task'">
                                    <input pInputText autocomplete="off" id="input_TaskJourneySearchLocToField"
                                    [placeholder]="translate?.general.messages.none"
                                        (keyup)="getPlaceApiCall1($event)" formControlName="endLocation"
                                        name="endLocation" autocorrect="off" autocapitalize="off" spellcheck="off"
                                        type="text" #officeAddressSearch (change)="manualSearch('office')"
                                        [(ngModel)]="addJourney.endLocation.address" id="officeAddressSearch1">
                                </div>
                                <div class="d-flex align-items-center justify-content-end ms-auto">
                                    <button class="btn-list-icon ms-2"  (click)="setEndLocation('Home')" id="btn_TaskJourneyToHome" [pTooltip]="translate?.task.fields.home.singular" tooltipPosition="top">
                                        <i class="fa-regular fa-house"></i>
                                    </button>
                                    <button class="btn-list-icon ms-2" (click)="setEndLocation('Office')" id="btn_TaskJourneyToOffice" [pTooltip]="translate?.task.fields.office.singular" tooltipPosition="top">
                                        <i class="fa-regular fa-briefcase"></i>
                                    </button>
                                    <button class="btn-list-icon ms-2" (click)="setEndLocation('Task')" id="btn_TaskJourneyToTask" [pTooltip]="translate?.task.label.singular" tooltipPosition="top">
                                        <i class="fa-regular fa-circle-check"></i>
                                    </button>
                                    <button class="btn-list-icon ms-2" (click)="setEndLocation('Current Location')" id="btn_TaskJourneyToCurrent" [pTooltip]="translate?.task.fields.currentLocation.singular" tooltipPosition="top">
                                        <i class="fa-regular fa-location-dot"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-10 d-flex p-0">
                                <div class="col-6 pl-0" *ngIf="addJourney.toType == 'Task'">
                                    <div class="text-10 p-error"
                                        *ngIf="addJourneyForm.controls['toTaskId'].touched && addJourneyForm.controls['toTaskId'].invalid">
                                        <span *ngIf="addJourneyForm.controls['toTaskId'].errors?.required" id="label_TaskJourneyLocToReqField"
                                            >{{translate?.general.validationFE.requiredField}}</span>
                                    </div>
                                </div>
                                <div class="col-6 p-0" [ngClass]="{'col-md-12': addJourney.toType != 'Task'}">
                                    <div class="text-10 p-error"
                                        *ngIf="addJourneyForm.controls['endLocation'].touched && addJourneyForm.controls['endLocation'].invalid">
                                        <span *ngIf="addJourneyForm.controls['endLocation']?.errors?.required" id="label_TaskJourneyEndLocReqField"
                                            >{{translate?.general.validationFE.requiredField}}</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <!-- Duration & Distance -->
                <div class="custella-form-container-title-two flex-between" >
                    <span class="font-medium text-14" id="title_TaskCalcDistTime">{{ translate?.task.sections.sec20.singular }}</span>
                    <p-button [label]="translate?.general.buttons.calculate.singular" styleClass="p-button-secondary py-1" id="btn_TaskCalcDistTime"
                    (onClick)="calculateDistance()"></p-button>
                </div>
                <div class="custella-form-container-content">
                    <!-- equipment_type -->
                    <div class="d-flex mb-3">
                        <div class="custella-content-row-input me-5">
                            <span *ngIf="distanceData" class="custella-content-row-input-title" id="label_TaskCalcDuration"
                                >{{ translate?.task.fields.duration.singular }}</span>
                            <span class="custella-content-row-input-title" id="value_TaskCalcDurationMsg"
                                style="color: black; font-size: 16px; font-weight: bold;"
                                >{{distanceData?.message}}</span>
                        </div>
                        <div class="custella-content-row-input">
                            <span *ngIf="distanceKM" class="custella-content-row-input-title" id="label_TaskCalcDistance"
                                >{{ translate?.task.fields.distance.singular }}</span>
                            <span class="custella-content-row-input-title" id="value_TaskCalcDistanceMsg"
                                style="color: black; font-size: 16px; font-weight: bold;" >{{distanceKM}}
                                {{distanceKM ? 'KM':''}}</span>
                        </div>

                    </div>
                </div>
                <!-- Container Footer -->
                <div class="custella-form-container-footer-two">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" id="btn_TaskJourneyCancel" (click)="goBackTable()">{{translate?.general.buttons.cancel.singular}}</button>
                        <p-button [label]="translate?.general.buttons.save.singular" [disabled]="submitTouched" id="btn_TaskJourneySubmit" (onClick)="onSubmitForm()" styleClass="ms-2 h-32"></p-button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
