<div class="position-relative fw-normal d-flex h-100">
    <div *ngIf="noProductsDataIcon" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2">
            <img src="assets\svg\empty-state.svg" alt="" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" >
                {{translate?.general.messages.noRecordsFound}}
            </p>
        </div>
    </div>
    <div [hidden]="!noProducts" class="ag-grid-table-full" style="height: 100%;">

        <ag-grid-angular #agGrid style="height: 100%;width: 100%;" class="ag-theme-balham" [columnDefs]="columnDefs"
            [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [maxConcurrentDatasourceRequests]="-1"
            [suppressDragLeaveHidesColumns]="true" [allowContextMenuWithControlKey]="true"
            [getContextMenuItems]="getContextMenuItems" [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)" (cellClicked)="viewProduct($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p>
        </div>
    </div>
</div>