import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Http, RequestOptions, Headers } from "@angular/http";
import { param } from 'jquery';
import { throwError, of as observableOf, Subject, BehaviorSubject, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageTranslateService implements OnDestroy {
  public words: any;

  private _wordSource = new BehaviorSubject<any>({});
  wordSrc$ = this._wordSource.asObservable();
  
  private _menuSource = new BehaviorSubject<any>({});
  menuSrc$ = this._menuSource.asObservable();

  public refreshList$: EventEmitter<any>;
  private selectSubject = new Subject<void>();

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) {
    this.refreshList$ = new EventEmitter();
  }

  ngOnDestroy(): void {
    // Complete any observables that need manual cleanup
    this.selectSubject.complete();
    this._menuSource.complete();
    this._wordSource.complete();
  }

  // Update all words
  updateWords(words: any) {
    console.log("words", words);
    this.words = words;
    this._wordSource.next(words);
  }

  // Update all words for menu
  updateMenu(menu: any) {
    this._menuSource.next(menu);
  }

completeSubjects() {
  this.selectSubject.complete();
  this._menuSource.complete()
  this._wordSource.complete()
}
  /******************************************** get Language Base on Selected Lang ******************************************/

  public getTranslateByLanguage(language: string) {
    return this.http.get(`${environment.base_url}/mongo/dynamic-label-setup/${language}/setup?source=WEB`, { observe: 'response' })
      .pipe(
        map((resp: any) => resp),
        catchError(error => throwError(error))
      );
  }

  /******************************************** Save Language Data******************************************/
  public saveLanguage(body: any, module: string, language: string) {
    return this.http.post(`${environment.base_url}/mongo/dynamic-label-setup/${language}/setup?source=WEB&module=${module}`, body, { observe: 'response' })
      .pipe(
        map((resp: any) => resp),
        catchError(error => throwError(error))
      );
  }

  public getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

}
