import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Model } from '@bryntum/scheduler';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendlyIntService } from 'src/app/_services/calendly-int.service';
export { };
declare global {
  interface Window {
    Calendly: any;
  }
}
declare var preInitCalendly: Function;

@Component({
  selector: 'app-calendly-theme',
  templateUrl: './calendly-theme.component.html'
})
export class CalendlyThemeComponent implements OnInit {
  integrationType: any;
  tenantId: any;
  calendlyData: any;
  urlSafe!: SafeResourceUrl;

  url = 'https://calendly.com/custella-demo/job-order-schedule';
  link: any;
  utm_source: any;
  utm_campaign: any;
  utm_medium: any;
  utm_content: any;
  constructor(
    private calendlyIntService: CalendlyIntService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {

      this.integrationType = params['integrationType'];
      this.tenantId = params['tenantId'];
      this.link = params['link'];
      this.utm_source = params['jobOrderNumber']
      this.utm_campaign = params['jobOrderId']
      this.utm_medium = params['jobOrderName']
      this.utm_content = params['tenantId']
      this.url = this.link + '?embed_domain=localhost%3A4500&amp;embed_type=Inline&utm_source='
      +this.utm_source+'&utm_campaign='+this.utm_campaign+'&utm_medium='+this.utm_medium
      +'&utm_content='+this.utm_content

      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      this.loadData()

    });
  }

  loadData() {
    this.calendlyIntService.getPublicCalendlyTheme(this.tenantId).subscribe((res: any) => {
      this.calendlyData = res;
      console.log(this.calendlyData)
      this.spinner.hide();
      setTimeout(() => {

        $('iframe').contents().find('*').addClass('strike');
     
      }, 3000);
      

    })
  }

}
