import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { Http } from '@angular/http';
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class ScheduleService {

  passData$: EventEmitter<{}>;
  private weatherAPIKey="810733ed29772985938b3cd3531733d9";

  constructor(private http: Http) { 
    this.passData$ = new EventEmitter();
  }

  public sendModalDataToParent(startLocation: any){
    return this.passData$.emit(startLocation);
  }

  public cancelModal(){
    return this.passData$.emit('modalClosed');
  }

  public getWeatherByLatLng(lat: any, lng: any){
    return this.http.get("https://api.openweathermap.org/data/2.5/forecast?lat="+lat+"&lon="+lng+"&appid="+this.weatherAPIKey+"&units=metric").pipe(map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }

  public getWeatherNowByLatLng(lat: any, lng: any){
    return this.http.get("https://api.openweathermap.org/data/2.5/weather?lat="+lat+"&lon="+lng+"&appid="+this.weatherAPIKey+"&units=metric").pipe(map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }

}
