<div
  class="helpdesk-user-selection-container d-flex flex-column"
  [ngStyle]="elementStyle"
>
  <div class="flex-between mb-3">
    <span class="header-text" style="text-align: start;" id="label_HelpSelAssignee">{{ translate?.helpdesk.sections.sec4.singular }}</span>
    <i class="pi pi-times close cursor-pointer" aria-hidden="true" id="btn_HelpAssigneeClose" (click)="close()" ></i>
  </div>


  <p-dropdown
    [placeholder]="translate?.general.messages.none"
    [options]="allUsers"
    optionLabel="fullName"
    optionValue="id"
    name="userSelection"
    [(ngModel)]="selectedUser"
    [filter]="true"
    [showClear]="selectedUser ? true : false"
    id="picklist_HelpSelAssigneeField"
  ></p-dropdown>

  <ng-template #showNoUser>
    <p class="text-1 mb-0 text-center text-white" id="label_HelpNoAssignee">
      {{ translate?.general.messages.noRecordsFound }}
    </p>
  </ng-template>

  <div class="loader" *ngIf="isLoading"></div>

  <button
    type="button"
    pButton
    class=""
    [disabled]="!selectedUser"
    (click)="onTicketAssignee()"
    *ngIf="isLoading === false"
    [label]="translate?.general.buttons.assign.singular"
    id="btn_HelpSelAssign"
  ></button>
</div>
