import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DynamicFormService } from 'src/app/_services/dynamic-form.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-delete-dynamic-data',
  templateUrl: './delete-dynamic-data.component.html',
  styleUrls: ['./delete-dynamic-data.component.css']
})
export class DeleteDynamicDataComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  @Input() data : any;
  @Input() page : any;
  @Input() formAPIName : any;
  @Input() apiServerType: any
  @Output() closeModel : EventEmitter<any> = new EventEmitter<any>();
  id: any;
  translate: any;

  constructor( 
    private dynamicFormService : DynamicFormService,
    private languageTranslateService: LanguageTranslateService
   ) {
    super()
    
    }

  ngOnInit() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    if(this.apiServerType == "magnum"){
      this.id= this.data._id

    }else  if(this.apiServerType == "java"){
       this.id= this.data.id
    }else{
      this.id= this.data._id
    }
  }

  onSubmit() {
    this.dynamicFormService.deleteDynamicData(this.id, this.page, this.formAPIName, this.apiServerType).subscribe((res:any)=>{
      //console.log("Response ", res);
      if(res.status === 200 || res.status === 201){
      //  this.modalRef.hide();
      //  this.toaster.success('', this.page+' removed successfully')
      } else {
       // this.modalRef.hide();
        //this.toaster.error('', res.error.title)
      }
    });
  }
  closeDeleteModel(){
    this.closeModel.emit(this.page);

  }
  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  
}