import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-login-from-phone',
  templateUrl: './login-from-phone.component.html',
  styleUrls: ['./login-from-phone.component.scss']
})
export class LoginFromPhoneComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  translate: any;

  constructor( private languageTranslateService: LanguageTranslateService) { 
    super()

  }

  ngOnInit(): void {
    this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
