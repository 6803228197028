import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { ProductService } from 'src/app/_services/product.service';

@Component({
  selector: 'app-custella-products',
  templateUrl: './custella-products.component.html',
  styleUrls: ['./custella-products.component.scss']
})
export class CustellaProductsComponent implements OnInit {

  @Output() productSelector = new EventEmitter();
  @Output() closeProductView = new EventEmitter();
  @Input() lookupFilter: any =''
  @Input() data: any;
  
  searchValue: any
 
   products: any;
   currentRecordNumber: any;
   noCustomers: boolean = false;
   noCustomersIcon: boolean = false;
   filter: any = {productStatuses : '',productTypes : '',fromDate: '', toDate : ''}
   dateFormat: any;
   productitemsHandlerSubscription: Subscription
   filterData: boolean = true
  translate: any;
  constructor(
    private router : Router,
    private productService:ProductService,
    private languageTranslateService: LanguageTranslateService

  ) { 
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
    this.productitemsHandlerSubscription = productService.productitemsHandler.subscribe((response: any) => {
      console.log(response)
      this.selectCustomer( response);
  });
  }

  ngOnInit() {
    this.dateFormat = localStorage.getItem('date_format')
   
  }
 

  selectCustomer(val:any){
    this.productSelector.emit(val);
  }

  closeProduct(){
    this.closeProductView.emit(false)
  }
  ngOnDestroy() {
    
    if (this.productitemsHandlerSubscription) {
      this.productitemsHandlerSubscription.unsubscribe();
  }
  }
}