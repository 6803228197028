<div>
  <div class="flex-between"> 
  
    <i class="fa-regular fa-xmark w-24px dialog-close-icon" id="btn_ProductsClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="closeProduct()"></i>
  </div>
  <div class="position-relative text-start">
    <span class="title" id="label_ProductsSelectList"> {{translate?.general.commonComponent.sections.sec9.singular}}</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText autocomplete="off" id="input_ProductsSelectListField" [placeholder]="translate?.general.buttons.search.singular" [(ngModel)]="searchValue"/>
      </span>
      </div>
    <div class="height-42-stat border" *ngIf="translate">
      <app-product-table [translate]="translate" [lookupFilter]="lookupFilter" [translate]="translate" [filterData]="filterData" [searchValue]="searchValue" ></app-product-table>
    </div>
  </div>

</div>