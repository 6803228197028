<div class="custella-modal">
  <div class="custella-modal-header align-items-center">
      <span class="text-14 font-medium" id="title_JobOrdersHpForm">{{ translate?.jobOrder.sections.sec44.singular }}</span>
      <i class="pi pi-times pt-1" [pTooltip]="translate?.general.buttons.close.singular" (click)="modalRef.hide()"></i>
  </div>

  <div class="">
    <form (ngSubmit)="onSubmit()">
      <div>
        <div class="custella-form-container height-80 overflow-scroll w-100 rounded-2 m-0">
          <div class="custella-form-container-title-two-grey">
            <span class="text-14 font-medium me-2" id="title_JobOrdersWoDetails">{{ translate?.jobOrder.fields.sec43.singular }}</span>
          </div>
          <div class="custella-form-container-content m-0">
            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersWoNumber">{{ translate?.jobOrder.fields.woNumber.singular }}</span>
                <input pInputText autocomplete="off" type="text" id="input_JobOrdersWoNumberField" [(ngModel)]="hpForm.jobOrderNumber"
                  [ngModelOptions]="{ standalone: true }" />
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersHpCustomer">{{ translate?.jobOrder.fields.hpCustomer.singular }}</span>
                <input pInputText autocomplete="off" type="text" id="input_JobOrdersHpCustomerField" [(ngModel)]="hpForm.customerName"
                  [ngModelOptions]="{ standalone: true }" />
              </div>
            </div>

            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersHpStatus">{{ translate?.jobOrder.fields.hpStatus.singular }}</span>
                <p-dropdown [placeholder]="translate?.general.messages.none" [options]="statuses" id="picklist_JobOrdersHpStatusField" optionLabel="label" optionValue="value"
                  name="status" [ngModelOptions]="{ standalone: true }" (onChange)="statusChange()"
                  [(ngModel)]="hpForm.status"></p-dropdown>
              </div>
            </div>
          </div>

          <div class="custella-form-container-title-two-grey">
            <span class="text-14 font-medium me-2" id="title_JobOrdersCaseDetails">{{ translate?.jobOrder.sections.sec6.singular }}</span>
          </div>

          <div class="custella-form-container-content m-0">
            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersReceivedDate">{{ translate?.jobOrder.fields.receivedDate.singular }}</span>
                <p-calendar [defaultDate]="defaultDate" name="dueDate" [placeholder]="translate?.general.messages.none" id="picklist_JobOrdersReceivedDateField" selectionMode="single"
                  dateFormat="mm/dd/yy" yearRange="2000:2030" [(ngModel)]="hpForm.receivedDate"
                  [ngModelOptions]="{ standalone: true }" [yearNavigator]="true" appendTo="body"></p-calendar>
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersReceivedTime">{{ translate?.jobOrder.fields.receivedTime.singular }}</span>
                <p-calendar [placeholder]="translate?.general.messages.none" [timeOnly]="true" id="picklist_JobOrdersReceivedTimeField" hourFormat="12" inputId="timeonly"
                  [(ngModel)]="hpForm.receivedTime" [ngModelOptions]="{ standalone: true }" appendTo="body"></p-calendar>
              </div>
            </div>

            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersCaseId">{{ translate?.jobOrder.fields.caseId.singular }}</span>
                <input pInputText autocomplete="off" type="text" [(ngModel)]="hpForm.caseId" id="input_JobOrdersCaseIdField" [ngModelOptions]="{ standalone: true }" />
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersCaseDetails">{{ translate?.jobOrder.fields.caseDetails.singular }}</span>
                <input pInputText autocomplete="off" type="text" [(ngModel)]="hpForm.caseDetails" id="input_JobOrdersCaseDetailsField" maxlength="255"
                  [ngModelOptions]="{ standalone: true }" />
              </div>
            </div>
          </div>

          <div class="custella-form-container-title-two-grey">
            <span class="text-14 font-medium me-2" id="title_JobOrdersAssetDetails">{{ translate?.jobOrder.sections.sec1.singular }}</span>
          </div>

          <div class="custella-form-container-content m-0">
            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersAssetNo">{{ translate?.jobOrder.fields.assetNo.singular }}</span>
                <input pInputText autocomplete="off" type="text" [(ngModel)]="hpForm.assetNumber" [ngModelOptions]="{ standalone: true }" id="input_JobOrdersAssetNoField" />
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersSerialNo">{{ translate?.jobOrder.fields.serialNo.singular }}</span>
                <input pInputText autocomplete="off" type="text" [(ngModel)]="hpForm.serialNumber" [ngModelOptions]="{ standalone: true }" id="input_JobOrdersSerialNoField" />
              </div>
            </div>
          </div>

          <div class="custella-form-container-title-two-grey">
            <span class="text-14 font-medium me-2" id="title_JobOrdersResolutionDetails">{{ translate?.jobOrder.sections.sec24.singular }}</span>
          </div>

          <div class="custella-form-container-content m-0">
            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersClosureCode">{{ translate?.jobOrder.fields.closureCode.singular }}</span>
                <p-dropdown [placeholder]="translate?.general.messages.none" [options]="closureCodes" optionLabel="code" optionValue="id"
                  [(ngModel)]="hpForm.closureCode.id" [ngModelOptions]="{ standalone: true }" id="input_JobOrdersClosureCodeField"></p-dropdown>
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersDelayCode">{{ translate?.jobOrder.fields.delayCodes.singular }}</span>
                <p-dropdown [placeholder]="translate?.general.messages.none" [options]="closureCodes" optionLabel="code" optionValue="id"
                  id="picklist_JobOrdersDelayCodeField" [disabled]="hpForm.status != 'Complete' && hpForm.status != 'Request to Close'"
                  [(ngModel)]="hpForm.delayCode.id" [ngModelOptions]="{ standalone: true }"></p-dropdown>
              </div>
            </div>
          </div>

          <ng-container *ngIf="hpForm.status == 'Rejected' || hpForm.status == 'Requested Reassign' || hpForm.status == 'Rescheduled' || hpForm.status == 'Customer Delay' || hpForm.status == 'Request to Cancel' || hpForm.status == 'Request Escalation' || hpForm.status == 'Complete'">
            <div class="custella-form-container-title-two-grey">
              <span class="text-14 font-medium me-2" id="title_JobOrdersFurtherDetails">{{ translate?.jobOrder.sections.sec15.singular }}</span>
            </div>
            <div class="custella-form-container-content m-0">
              <div class="custella-content-row">
                <div class="custella-content-row-input col-6" *ngIf="hpForm.status == 'Rejected' || hpForm.status == 'Requested Reassign' || hpForm.status == 'Rescheduled' || hpForm.status == 'Customer Delay' || hpForm.status == 'Request to Cancel' || hpForm.status == 'Request Escalation'">
                  <span class="custella-content-row-input-title" id="label_JobOrdersFurtherReason">{{ translate?.jobOrder.fields.reason.singular }}</span>
                  <p-dropdown [placeholder]="translate?.general.messages.none" [options]="rejectedCodes" optionLabel="code" optionValue="code"
                    id="picklist_JobOrdersFurtherReasonField" name="reasonCode" [(ngModel)]="hpForm.reason" [ngModelOptions]="{ standalone: true }"></p-dropdown>
                </div>

                <div class="custella-content-row-input col-6" *ngIf="hpForm.status == 'Rescheduled' || hpForm.status == 'Customer Delay'">
                  <span class="custella-content-row-input-title" id="label_JobOrdersRescheduleDate">{{ translate?.jobOrder.fields.rescheduleDateTo.singular }}</span>
                  <p-calendar [defaultDate]="defaultDate" name="rescheduleDate" [placeholder]="translate?.general.messages.none" selectionMode="single"
                    dateFormat="mm/dd/yy" yearRange="2000:2030" [(ngModel)]="hpForm.rescheduleDateTo" [yearNavigator]="true" id="picklist_JobOrdersRescheduleDateField"
                    appendTo="body"></p-calendar>
                </div>

                <div class="custella-content-row-input col-6" *ngIf="hpForm.status == 'Request to Cancel' || hpForm.status == 'Request Escalation'">
                  <span class="custella-content-row-input-title" id="label_JobOrdersFollowUp">{{ translate?.jobOrder.fields.followUp.singular }}</span>
                  <textarea pInputTextarea autocomplete="off" rows="3" [(ngModel)]="hpForm.followUp" [ngModelOptions]="{ standalone: true }" [maxlength]="320000"
                    id="input_JobOrdersFollowUpField"></textarea>
                </div>
              </div>

              <div class="custella-content-row">
                <div class="custella-content-row-input col-6" *ngIf="hpForm.status == 'Request Escalation' || hpForm.status == 'Complete'">
                  <span class="custella-content-row-input-title" id="label_JobOrdersFindings">{{ translate?.jobOrder.fields.findings.singular }}</span>
                  <textarea pInputTextarea autocomplete="off" type="text" rows="3" id="input_JobOrdersFindingsField" [(ngModel)]="hpForm.findings"
                    [ngModelOptions]="{ standalone: true }" [maxlength]="320000"></textarea>
                </div>

                <div class="custella-content-row-input col-6" *ngIf="hpForm.status == 'Request Escalation'">
                  <span class="custella-content-row-input-title" id="label_JobOrdersAdditionalParts">{{ translate?.jobOrder.fields.additionalParts.singular }}</span>
                  <textarea pInputTextarea autocomplete="off" type="text" rows="3" id="input_JobOrdersAdditionalPartsField" [(ngModel)]="hpForm.additionalPart"
                    [ngModelOptions]="{ standalone: true }" [maxlength]="320000"></textarea>
                </div>
              </div>

              <div class="custella-content-row">
                <div class="custella-content-row-input col-6" *ngIf="hpForm.status == 'Request Escalation'">
                  <span class="custella-content-row-input-title" id="label_JobOrdersEscalatedTo">{{ translate?.jobOrder.fields.escalatedTo.singular }}</span>
                  <textarea pInputTextarea autocomplete="off" type="text" rows="3" id="input_JobOrdersEscalatedToField" [(ngModel)]="hpForm.additionalPart"
                    [ngModelOptions]="{ standalone: true }" [maxlength]="320000"></textarea>
                </div>
              </div>

              <div class="custella-content-row">
                <div class="custella-content-row-input col-6" *ngIf="hpForm.reason == 'Others'">
                  <span class="custella-content-row-input-title" id="label_JobOrdersOtherReason">{{ translate?.jobOrder.fields.otherReason.singular }}</span>
                  <textarea pInputTextarea autocomplete="off" id="input_JobOrdersOtherReasonField" type="text" rows="3" [(ngModel)]="hpForm.otherReason"
                    [ngModelOptions]="{ standalone: true }" [maxlength]="320000"></textarea>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="custella-form-container-footer-two pb-0">
            <div class="d-flex align-items-center justify-content-end">
              <button class="custella-btn-white text-12 mx-2" id="btn_JobOrdersCancel" (click)="modalRef.hide()">{{ translate?.general.buttons.cancel.singular }}</button>
              <p-button [label]="translate?.general.buttons.save.singular" id="btn_JobOrdersSubmit" (onClick)="onSubmit()"></p-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
