import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceToKm'
})
export class DistanceToKmPipe implements PipeTransform {

  transform(value: number): string {
    var distance: string = (value / 1000).toFixed(2).toString();
    if(value == 0){
      return distance + 'km';
    }
    else if(value > 0 && value < 1000){
      return distance + 'm';
    } else {
      return distance + ' km';
    }
 }

}
