
 <div *ngIf="!field.multiSelect">
  <div class="p-inputgroup search" *ngIf="!field.multiSelect">
<!--=================== Single Input ====================-->
<div class="p-input-icon-right w-100" [formGroup]="group" *ngIf="field">
  
  <p-autoComplete [disabled]="field.readOnly"  [id]="field.name"  [(ngModel)]='searchData' (onBlur)="onvalid()"
  (onSelect)="selectValue($event)" field="displayColumn" [formControlName]="field.name" [placeholder]="field.placeholder ? field.placeholder:''"
  [suggestions]="data" (completeMethod)="getCustomerIdsFirstWay($event,'single')"  styleClass="w-100">
</p-autoComplete>

  <i *ngIf="autoCompleteData!= null && !field.readOnly"  (click)="clearValue()" class="p-autocomplete-clear-icon pi pi-times "></i>
 
  </div>
  <button type="button" pButton icon="fa-regular fa-magnifying-glass" *ngIf="field.searchTo != null" (click)="showTable()"></button>
</div>
  <!--=================== Validation of form ====================-->
  <div *ngIf="field.validations != null">
        
  <ng-container *ngFor="let validation of field.validations.rules;" ngProjectAs="mat-error">
      <div *ngIf="(group.controls[field.name].touched && group.controls[field.name].invalid) && validation.name !== 'nullable'">
        <span class="text-10 p-error" *ngIf="group.controls[field.name].errors?.required" >{{validation.message}}</span>
    </div>
  </ng-container>
  </div>
<!--=================== Display table base on search to ====================-->

    <footer *ngIf="displayTable" class="custella-form-container-footer">
  <p-dialog *ngIf="field.searchTo == 'Customer'" [header]="translate?.customer.sections.sec6.singular" [(visible)]="displayTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false">
    <app-custella-customers [lookupFilter]="lookupFilter"   (selectCustomerEvent)="gotSelectedData($event)" (closeCustomerEvent)="closeView()"></app-custella-customers>
  </p-dialog>

  <p-dialog *ngIf="field.searchTo == 'Contact'" [header]="translate?.customer.sections.sec6.singular" [(visible)]="displayTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false">
    <app-search-customer-contact [field]="field" [lookupFilter]="lookupFilter" *ngIf="field.searchTo == 'Contact'"  (customerSelector)="gotSelectedData($event)" (closeCustomerView)="closeView()"></app-search-customer-contact>
  </p-dialog>

  <p-dialog [header]="translate?.asset.sections.sec16.singular"  *ngIf="field.searchTo == 'Asset'"  [(visible)]="displayTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false">  
    <app-custella-assets [lookupFilter]="lookupFilter" displayAction="false" (assetSelector)="gotSelectedData($event)" (closeAssetView)="closeView()"></app-custella-assets>
  </p-dialog>

    <p-dialog [header]="translate?.jobOrder.sections.sec40.singular" *ngIf="field.searchTo == 'Job Order'"  [(visible)]="displayTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false"> 
      <app-custella-job-orders [lookupFilter]="lookupFilter"   (itemSelector)="gotSelectedData($event)" (closeView)="closeView()"></app-custella-job-orders>
   </p-dialog>

   <p-dialog [header]="translate?.product.sections.sec4.singular" *ngIf="field.searchTo == 'Product'"  [(visible)]="displayTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false"> 
    <app-custella-products [lookupFilter]="lookupFilter" (productSelector)="gotSelectedData($event)" (closePartView)="closeView()"></app-custella-products>
  </p-dialog>

    <p-dialog *ngIf="field.searchTo == 'LocationBin'" [header]="translate?.binTransfer.sections.sec1.singular" [(visible)]="displayTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false"> 
       <app-search-location-bin [lookupFilter]="lookupFilter"  (customerLocationBin)="gotSelectedData($event)" (closePartView)="closeView()"></app-search-location-bin>
  </p-dialog>

   
    </footer>


</div>

<div class="p-inputgroup search" *ngIf="field.multiSelect"> 
  <!--=================== Muliple Input ====================-->
  <div class="p-input-icon-right w-100" [formGroup]="group" *ngIf="field">
      <p-autoComplete [readonly]="field.readOnly" [formControlName]="field.name" 
      [placeholder]="field.placeholder ? field.placeholder:''" 
       (blur)="onvalidMulti()" [id]="field.name"  [(ngModel)]='field.value'
      (onSelect)="gotSelectedMulipleData($event)" [field]="field.displayColumn" styleClass="w-100"
      [suggestions]="data" (completeMethod)="getMultipleData($event,'multi')" [multiple]="true" (onClear)="removeData($event)">
    </p-autoComplete>
  </div>
  <button type="button" pButton icon="fa-regular fa-magnifying-glass" *ngIf="field.searchTo != null" (click)="showTable()"></button>
 
  
    <!--=================== Validation of form ====================-->
    <div *ngIf="field.validations != null">
          
    <ng-container *ngFor="let validation of field.validations.rules;" ngProjectAs="mat-error">
        <div class="alert col px-0 py-0 text-right" *ngIf="(group.controls[field.name].touched && group.controls[field.name].invalid) && validation.name !== 'nullable'">
          <span *ngIf="group.controls[field.name].errors?.required" >{{validation.message}}</span>
      </div>
    </ng-container>
    </div>
  <!--=================== Display table base on search to ====================-->
    <div *ngIf="displayTableMul" class="customer-displayTable" style="width: 900px !important; right: -50px !important;">
     
<footer *ngIf="displayTable" class="custella-form-container-footer">
  <p-dialog [header]="translate?.customer.sections.sec5.singular" [(visible)]="displayTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false">
    <app-custella-customers [lookupFilter]="lookupFilter" *ngIf="field.searchTo == 'Customer'"  (selectCustomerEvent)="gotSelectedMulipleData($event)" (closeCustomerEvent)="closeMulipleView()"></app-custella-customers>
  </p-dialog>

     
      <!-- <app-search-assets [lookupFilter]="lookupFilter" *ngIf="field.searchTo == 'Asset'"  (assetSelector)="gotSelectedMulipleData($event)" (closeAssetView)="closeMulipleView($event)"></app-search-assets>
  
      <app-search-jo  [lookupFilter]="lookupFilter" *ngIf="field.searchTo == 'Job Order'"  (customerSelectorJO)="gotSelectedMulipleData($event)" (closeJOView)="closeMulipleView($event)"></app-search-jo>
     
      <app-search-product [lookupFilter]="lookupFilter"  *ngIf="field.searchTo == 'Product'"  (customerProduct)="gotSelectedMulipleData($event)" (closePartView)="closeMulipleView($event)"></app-search-product>

      <app-search-location-bin [lookupFilter]="lookupFilter"  *ngIf="field.searchTo == 'LocationBin'"  (customerLocationBin)="gotSelectedMulipleData($event)" (closePartView)="closeMulipleView($event)"></app-search-location-bin> -->

      
    </footer>
  
  </div>
  </div>


