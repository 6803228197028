import { Component, Input, OnInit } from '@angular/core';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

import {Message, MessageService} from 'primeng/api';

@Component({
  selector: 'app-toast',
  template: ` <p-toast [hidden]="toasterData?.severity =='success'" key="tst" [showTransformOptions]="'translateY(80%)'" id="toast_ToastEmpty"></p-toast>
  <p-toast [hidden]="toasterData?.severity != 'success'" key="tst" [showTransformOptions]="'translateY(80%)'" id="toast_ToastBox">
    <ng-template let-service pTemplate="service">
    <p *ngIf="toasterData?.detail" class="p-0 m-0 col" innerHtml="{{service.detail}}" id="label_ToastMessage"></p>
        <p *ngIf="!toasterData?.detail" class="p-0 m-0 col" id="label_ToastSummary">{{service.summary}}</p>
    </ng-template>
</p-toast>`,
  providers: [MessageService]
})
export class ToastComponent extends SubscriptionUtil implements  OnInit {
//type is either 'error','warning','info'
showWelcomeModal = false;
toasterData : any
constructor(
  private errorUtil: ErrorUtil,
  private service: MessageService
) {
  super();
  this.push(
    this.errorUtil.subjectOpenToastModal$.subscribe((data: any) => {
      console.log(data);
      this.toasterData = data
      this.service.clear();
      this.showWelcomeModal = data.showWelcomeModal;
       this.service.add({
        key: 'tst', 
        severity: data.severity,
        summary:data.summary,
        detail: data.detail,
        life: data.life
        });
      
    })
  )

}

ngOnInit() {
}
showInfoViaToast() {
  this.service.add({key: 'tst', severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks'});
}

push(obs:any) {
  super.push(obs);
}

ngOnDestroy() {
  super.ngOnDestroy();
}

}


