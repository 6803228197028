import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { SignUpService } from "src/app/_services/sign-up.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { EngLangData, MalayLangData, chineseLangData, deutschLangData, languageOptions, spanishLangData } from "src/app/_models/language.data.model";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  submitTouched: boolean = false;
  forgotPassword: any = {};
  forgotPasswordForm!: UntypedFormGroup;
  disableSubmitButton!: boolean;

  
  @ViewChild('chooseLang') chooseLangEl: ElementRef;


   langData: any;
   languageList: any;
  currentLang: any;

  constructor(
    private signUpService: SignUpService,
    private router: Router,
    private errorUtil: ErrorUtil
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    var langCode = localStorage.getItem('lang');
    if (langCode == 'my') this.onChangeLanguage('Bahasa Melayu')
    else if (langCode == 'en') this.onChangeLanguage('English')
    else if (langCode == 'zh') this.onChangeLanguage('简体中文')
    else if (langCode == 'de') this.onChangeLanguage('Deutsch')
    else if (langCode == 'es') this.onChangeLanguage('Español')
    else this.onChangeLanguage('English')
    this.languageList = Object.values(languageOptions);
    this.forgotPasswordForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.emailPattern),
      ]),
    });
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Submit Forgot Password ******************************************/
  public submitForgotPassword() {
    
    this.disableSubmitButton = true
    this.submitTouched = true;
    if (!this.forgotPasswordForm.valid) {
      for (var i in this.forgotPasswordForm.controls) {
        this.forgotPasswordForm.controls[i].markAsTouched();
      }
      return false;
    }

    var formData = {
      login: this.forgotPassword.username,
    };

    this.push;
    this.signUpService.forgotPassword(formData).subscribe((res: any) => {
      this.submitTouched = false;
      if (res.status === 200 || res.status === 201) {
        this.errorUtil.setErrorMessage(
          200,
          this.langData.general.messages.passwordResetEmailSent,
          null,
          "success",
          1000
        );
        this.goToLogin();
      } else {
        var body = JSON.parse(res._body);
        this.disableSubmitButton = false;
        this.errorUtil.setErrorMessage(
          res.status,
          null,
          body.title,
          "error",
          2000
        );
      }
    });
  }

  /******************************************** Go To Landing ******************************************/
  private goToLogin() {
    this.router.navigate(["/login"]);
  }

  onChangeLanguage(landKey: any){
    console.log(landKey)
    //debugger
    if (landKey.value == "English" || landKey == "English") {
      this.currentLang = languageOptions.english;
      this.langData = EngLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "en");
    }
    if (landKey.value == "Bahasa Melayu" || landKey == "Bahasa Melayu") {
      this.currentLang = languageOptions.malay;
      this.langData = MalayLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "my");
    }
    if (landKey.value == "简体中文" || landKey == "简体中文") {
      this.currentLang = languageOptions.chinese;
      this.langData = chineseLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "zh");
    }
    if (landKey.value == "Deutsch" || landKey == "Deutsch") {
      this.currentLang = languageOptions.deutsch;
      this.langData = deutschLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "de");
    }
    if (landKey.value == "Español" || landKey == "Español") {
      this.currentLang = languageOptions.chinese;
      this.langData = spanishLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "es");
    }
  console.log(this.langData)
}

}

