import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
declare var google: any;

@Component({
  selector: 'app-view-google-map',
  templateUrl: './view-google-map.component.html',
  styleUrls: ['./view-google-map.component.scss']
})
export class ViewGoogleMapComponent implements OnInit, OnChanges {

  @Input() locationDetail: any
  @Input() onStartActive = false;
  options: any;
  overlays: any
  @Input() activeState: any = false;
  addressB: any;

  @Output() outputActiveState = new EventEmitter();
  translate: any;

  constructor(private languageTranslateService: LanguageTranslateService) { 
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(!this.locationDetail.street && !this.locationDetail.city && !this.locationDetail.state &&
      !this.locationDetail.postcode &&  !this.locationDetail.country){
        this.addressB ='-'
      }
      
    if(this.locationDetail.locationLat && this.locationDetail.locationLong){
      this.options = {
       center: {lat: this.locationDetail.locationLat, lng: this.locationDetail.locationLong},
       zoom: 18,
       scrollwheel:true
       }
  
     this.overlays = [
       new google.maps.Marker({position: {lat:this.locationDetail.locationLat, lng: this.locationDetail.locationLong}, title:"Konyaalti",draggable: false})];

       if (this.onStartActive) {
        this.activeState = true;
       }
 }
  }

  ngOnInit(): void {

    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })

    if(!this.locationDetail.street && !this.locationDetail.city && !this.locationDetail.state &&
      !this.locationDetail.postcode &&  !this.locationDetail.country){
        this.addressB ='-'
      }
      
    if(this.locationDetail.locationLat && this.locationDetail.locationLong){
      this.options = {
       center: {lat: this.locationDetail.locationLat, lng: this.locationDetail.locationLong},
       zoom: 18,
       scrollwheel:true
       }
  
     this.overlays = [
       new google.maps.Marker({position: {lat:this.locationDetail.locationLat, lng: this.locationDetail.locationLong}, title:"Konyaalti",draggable: false})];

       if (this.onStartActive) {
        this.activeState = true;
       }
 }
  }

  toggle(index: number) {
      this.activeState = !this.activeState

      this.outputActiveState.emit(this.activeState);
   
}
 

}
