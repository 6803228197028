import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { throwError,  of as observableOf, Subject,  } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  public refreshList$: EventEmitter<any>;
  private selectSubject = new Subject();
  
  constructor(private http: HttpClient) {
    this.refreshList$ = new EventEmitter();
  }

  private ENDPOINT_ATTENDANCE = "ms/v1/attendances/"
  private ENDPOINT_GET_ALL_ATTENDANCE = "ms/v1/attendances/filter";
  private ENDPOINT_GET_ALL_ATTENDANCE_STATUS = "ms/v1/attendance-statuses/";
  private ENDPOINT_UPDATE_ATTENDANCE_STATUS = "ms/v1/attendances/status";
  private ENDPOINT_GET_ALL_OUT_TURN_CODES = "ms/v1/out-turn-codes/";
  private ENDPOINT_ATTENDENCE_GET_BY_ID = "ms/v1/attendances/"
  private ENDPOINT_ATTENDENCE_UPDATE_REJECT_REASON = "ms/v1/attendances/reason"
 
  public select(val: any) {
    this.selectSubject.next(val);
  }
  public getSelectItem() {
    return this.selectSubject.asObservable();
  }


  public getAllAttendance(searchValue: any, parameters: any, apiParams: any) {
    let params = new HttpParams();
    if (searchValue) {
     params = params.set('search', searchValue);
    }
    if (apiParams.fromDate) {
      params = params.set('fromDate', apiParams.fromDate);
    }
    if (apiParams.toDate) {
      params = params.set('toDate', apiParams.toDate);
    }

    if (apiParams.siteId) {
      params = params.set('siteId', apiParams.siteId)
    }
    if (apiParams.status) {
      params = params.set('status', apiParams.status)
    }
    if (apiParams.userId) {
      params = params.set('userId', apiParams.userId)
    }

    //params = params.set('origin', 'customer');
    if (parameters)
      params = params.set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_GET_ALL_ATTENDANCE, { observe: 'response', params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public getAllAttendanceStatus() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_GET_ALL_ATTENDANCE_STATUS, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public getAllOutTurnCodes() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_GET_ALL_OUT_TURN_CODES, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  /************************************* Update visits  status *************************************************************************/
  public updateAttendanceStatus(body: any,) {

    // let params = new HttpParams();
    // params = params.set('id', id);
    // params = params.set('status', status)
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_UPDATE_ATTENDANCE_STATUS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Status Updated");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

    /************************************* Get Visit Info By ID *************************************************************************/
    public getAttendanceById(id: any) {
      return this.http
        .get(environment.ms_node_base_url + this.ENDPOINT_ATTENDENCE_GET_BY_ID + id)
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            ////console.log(error);
            return throwError(error);
          })
        );
    }

      /************************************* Update attendance reject status *************************************************************************/
  public updateAttendanceRejectReason(body: any) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_ATTENDENCE_UPDATE_REJECT_REASON, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Status Updated");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

   /******************************************** edit Attendance ******************************************/
   public editAttendance(body: any) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_ATTENDANCE, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

    /******************************************** create Attendance ******************************************/
    public createAttendance(body: any) {
      return this.http
        .post(environment.ms_node_base_url + this.ENDPOINT_ATTENDANCE, body, {
          observe: "response",
        })
        .pipe(
          map(
            (resp: any) => {
              return resp;
            },
            catchError((error) => {
              //console.log(error);
              return throwError(error);
            })
          )
        );
    }


      /************************************* Delete Attendance *************************************************************************/
  public deleteAttendance(id: any) {
    return this.http
      .delete(environment.ms_node_base_url + this.ENDPOINT_ATTENDANCE + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Visit");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }
  
    /************************************* Get Attendance History By ID *************************************************************************/
    public getAttendanceHistoryById(attendanceId: any) {
      return this.http
        .get(environment.ms_node_base_url + 'ms/v1/attendance-audit-event-histories?attendanceId='+attendanceId)
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            ////console.log(error);
            return throwError(error);
          })
        );
    }

}
