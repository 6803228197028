import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BreadcrumbService, MenuItemPlus } from 'src/app/_services/breadcrumb.service';
import { NavigationBreadCrumbService } from 'src/app/_services/navigation-bread-crumb.service';
import { PermissionService } from 'src/app/auth/permission.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit ,AfterViewInit{

   /****************** Breadcrumb Variable *********/
   breadcrumbSetupSubscription!: Subscription;
   setupItems: MenuItemPlus[]=[];

   hasItems = false;

   setupAccess: any;
   
  constructor(
    private breadcrumbService : BreadcrumbService,
    private cdr: ChangeDetectorRef,
    private navibarService: NavigationBreadCrumbService,
    private perm: PermissionService
  ) { 
    this.breadcrumbSetupSubscription = breadcrumbService.setupItemsHandler.subscribe((response: any) => {
      this.setupItems = response;
   });

   this.navibarService.getAllNavigationItems().subscribe(data => {
    const theArray = data ?? [];
    
    this.setupItems = theArray;

    if (this.setupItems.length > 0) {
      this.hasItems = true;
    } else {
      this.hasItems = false;
    }

    console.log(this.setupItems);
   })
   this.setupAccess = this.perm.capable("setup", "read");
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

}
