import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesSeconds'
})
export class MinutesSecondsPipe implements PipeTransform {
//converts seconds to hours and minutes (hours and minutes format)

  transform(value: number): string {
    if(value < 3600){
      var hours : string = '';
      var minutes: string = Math.floor(value / 60).toString();
    } else {
      var hours : string = Math.floor(value/3600).toString();
      var minutes: string = Math.floor((value % 3600)/3600 * 60).toString();
    }

    if(value){
      if(hours == '1'){
        return hours + ' H ' + minutes + ' Mins';
      } else if(hours !== '1' && hours !== ''){
        return hours + ' H ' + minutes + ' Mins';
      } else {
        return minutes + ' Mins';
      }
    } else {
      return '-';
    }
 }

}
