import { EventEmitter, Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { MenuItem } from "primeng/api";

@Injectable()
export class DeleteRecordService {
  private afterDeleteEvent = new Subject<any>();

  private deleteItemsSource = new Subject<MenuItem[]>();

  deleteItems = this.deleteItemsSource.asObservable();

  /******************************************** during activation ******************************************/
  public getItems(items: any = null) {
    this.deleteItemsSource.next(items);

    return this.afterDeleteEvent.asObservable();
  }

  /******************************************** after delete ******************************************/
  public triggerAfterDelete(data: any) {
    this.afterDeleteEvent.next(data);
  }
}
