<div class="custella-modal">
  <!-- Container -->
  <div class="custella-modal-header d-flex align-items-center" *ngIf="first">
    <div class="custella-modal-header-text">
      <i class="pi pi-arrow-left me-2" id="btn_TaskReturnCreateTask" (click)="goBackToCreateTask()"></i>
      <span class="text-14 font-medium" id="title_TaskSmartAssign">{{ translate?.general.buttons.assignTask.singular }}</span>
    </div>
    <i class="pi pi-times" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" id="btn_TaskSmartAssignClose" (click)="modalRef.hide()"></i>
  </div>

  <!--------------------------------------------- Smart Assign------------------------------------------------->
  <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0" *ngIf="first">
    <div>
      <!-- Topic -->
      <div class="custella-form-container p-3 p-input-icon-right col-12 m-0" >
        <span class="text-14 font-medium color-var-500" id="title_TaskFindAgent" >{{ translate?.task.tooltips.letFieldexFindBestFieldAgent.singular }}</span>
      </div>

      <div class="custella-form-container-title-two flex-between py-2">
        <div class="text-12">
          <span id="label_TaskSmartStepOne">{{ translate?.general.messages.step1 }}</span> :
          <span id="label_TaskSmartIndicateNo">{{ translate?.task.fields.indicateTheNumber.singular }}</span>
        </div>
      </div>

      <!-- number of users -->
      <div class="custella-form-container-content">
        <div class="custella-content-row pe-0">
          <div class="custella-content-row-input col me-0">
            <span class="custella-content-row-input-title" id="label_TaskSmartNoUsers">{{ translate?.task.fields.numberUsers.singular }}</span>
            <p-dropdown [placeholder]="translate?.general.messages.none" [disabled]="!assignTask.userNames.length" id="picklist_TaskSmartNoUsersField"
              [(ngModel)]="assignTask.numberOfUsers" [options]="allNumberOfUsers" optionValue="value" optionLabel="value"></p-dropdown>
          </div>
        </div>
      </div>

      <div class="custella-form-container-title-two flex-between m-0 py-2">
        <div class="text-12">
          <span id="label_TaskSmartStepTwo">{{ translate?.general.messages.step2 }}</span> :
          <span id="label_TaskSmartPreferTeam">{{ translate?.task.fields.selectPreferred.singular}} ({{data?.displayTeamName}})</span>
        </div>
      </div>

      <!-- User / Team -->
      <div class="custella-form-container-content">
        <div class="custella-content-row pe-0">
          <div class="custella-content-row-input col-12">
            <ng-container>
              <div *ngIf="assignMode == 'team'" id="label_TaskSmartTeamName" class="custella-content-row-input-title" >{{ translate?.task.fields.team.singular }}</div>
              <div *ngIf="assignMode == 'user'" id="label_TaskSmartUserName" class="custella-content-row-input-title" >{{ translate?.task.fields.user.singular }}</div>
              <div class="d-flex align-items-center">
                <div *ngIf="assignMode == 'team'" class="p-input-icon-right col">
                  <p-dropdown [placeholder]="translate?.general.messages.none" [options]="teams" optionLabel="teamName" id="picklist_TaskSmartTeamNameField"
                    name="team" (onChange)="selectTeam(assignTask.teamName)" [(ngModel)]="assignTask.teamName" [filter]="true" filterBy="teamName"></p-dropdown>
                </div>
                <div *ngIf="assignMode == 'user'" class="p-input-icon-right col">
                  <p-autoComplete [placeholder]="translate?.general.messages.none" [suggestions]="users" optionLabel="fullName" id="picklist_TaskSmartUserNameField"
                    name="users" [(ngModel)]="searchUserVal" (completeMethod)="getSearchedUserList(searchUserVal)"
                    (onSelect)="addUser($event)">
                    <ng-template let-user pTemplate="item">
                      <div class="d-flex align-items-center border p-1" style="border-radius: 4px">
                        <p-avatar *ngIf="user.imageUrl" [image]="user.imageUrl" styleClass="mr-2 mt-2" id="img_TaskSmartUserAvatar" shape="circle"></p-avatar>
                        <p-avatar *ngIf="!user.imageUrl" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-2" id="img_TaskSmartUserAvatar" shape="circle"></p-avatar>
                        <span class="ms-1" id="value_TaskSmartUserFullName">{{ user?.fullName }}</span>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <div class="justify-content-between">
                  <button type="button" class="border p-2 ms-1" (click)="resetSelection('user')" id="btn_TaskSmartAssignUser"
                    [ngClass]=" assignMode == 'user' ? 'color-primary-bg text-white' : '' " style="border-radius: 4px">
                    <i class="pi pi-user fs-5 align-middle"></i>
                  </button>
                  <button type="button" class="border p-2 ms-1" (click)="callTeamApi('team')" id="btn_TaskSmartAssignTeam"
                    [ngClass]=" assignMode == 'team' ? 'color-primary-bg text-white' : '' " style="border-radius: 4px">
                    <i class="pi pi-users fs-5 align-middle"></i>
                  </button>
                </div>
              </div>
            </ng-container>

          </div>
        </div>

        <!-- user 1 -->
        <div class="custella-form-container-content-border-bottom text-12 font-medium px-0" *ngFor="let user of assignTask?.userNames" style="min-height: unset;">
          <div class="custella-content-row my-1 pe-0">
            <div class="d-flex align-items-center my-2 col-12">
              <p-avatar *ngIf="user.imageUrl" [image]="user.imageUrl" styleClass="d-flex me-2" id="img_TaskSmartUserOneAvatar" shape="circle"></p-avatar>
              <p-avatar *ngIf="!user.imageUrl" image="assets/svg/Avatar.svg" styleClass="d-flex me-2" id="img_TaskSmartUserTwoAvatar" shape="circle"></p-avatar>
              <span class="ms-1" id="value_TaskSmartUserOneFullName">{{ user?.fullName }}</span>
              <div class="action-container d-flex gap-1 ms-auto align-items-center">
                <button *ngIf="user.owner" class="border px-2 py-1 ms-1" [ngClass]="user.owner ? 'color-primary-bg text-white' : ''" style="border-radius: 4px; cursor: auto;">
                  {{ translate?.task.fields.currentOwner.singular }} </button>
                <button *ngIf="!user.owner" (click)="makeOwnerclick(user)" class="border px-2 py-1 ms-1" [ngClass]="user.owner ? 'color-primary-bg text-white' : ''" style="border-radius: 4px">
                  {{ translate?.general.buttons.makeOwner.singular }} </button>
                <p class="p-error ms-3 hand cursor-pointer" id="btn_TaskSmartUserOneRemove" (click)="removeUser(user)" >{{ translate?.general.buttons.remove.singular }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- step 2 -->
      <div class="custella-form-container-title-two flex-between py-2">
        <div class="text-12">
          <span id="label_TaskSmartStepThree">{{ translate?.general.messages.step3 }}</span> :
          <span id="label_TaskSmartConditions">{{ translate?.task.fields.conditionsBeforeAssigning.singular }}</span>
        </div>
      </div>

      <!-- conditions -->
      <div class="custella-form-container-content">
        <div class="custella-content-row">
          <div class="custella-content-row-input col-12">
            <div class="">
              <div class="custella-content-row-checkbox col">
                <div class="p-field-checkbox"><p-checkbox [binary]="true" [disabled]="data.adhoc" id="check_TaskSmartReqAvail"
                    [(ngModel)]="assignTask.teamCategory.available"></p-checkbox></div>
                <span class="custella-content-row-checkbox-text" id="label_TaskSmartReqAvail">{{ translate?.task.fields.requireToBeFree.singular }}</span>
                <i class="pi pi-info-circle ms-2" [pTooltip]="translate?.task.tooltips.selectingThisEliminates.singular" id="tooltip_TaskSmartSelEliminates"
                  tooltipPosition="top" [escape]="false"></i>
              </div>
              <div class="custella-content-row-checkbox col">
                <div class="p-field-checkbox"><p-checkbox [binary]="true"
                    [(ngModel)]="assignTask.teamCategory.location" id="check_TaskSmartLoc"></p-checkbox></div>
                <span class="custella-content-row-checkbox-text" id="label_TaskSmartReqTravel">{{ translate?.task.fields.requireToTravel.singular }}</span>
                <i class="pi pi-info-circle ms-2" [pTooltip]="translate?.task.tooltips.futureTaskOnTime.singular" id="tooltip_TaskSmartFutureOnTime"
                  tooltipPosition="top" [escape]="false"></i>
              </div>
              <div class="custella-content-row-checkbox col">
                <div class="p-field-checkbox"><p-checkbox [binary]="true"
                    [(ngModel)]="assignTask.teamCategory.skills" id="check_TaskSmartSkills"></p-checkbox></div>
                <span class="custella-content-row-checkbox-text mb-0" id="label_TaskSmartReqSkills">{{ translate?.task.fields.requireSkills.singular }}</span>
                <i class="pi pi-info-circle ms-2" [pTooltip]="translate?.task.tooltips.specifiedTaskSkills.singular" id="tooltip_TaskSmartSpecifySkills"
                  tooltipPosition="top" [escape]="false"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="custella-form-container-footer mb-0">
      <div class="d-flex align-items-center justify-content-end">
        <button class="custella-btn-white text-12 ms-2" id="btn_TaskSmartAssignCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
        <ng-container *ngIf="!data.adhoc">
          <button class="custella-btn-white text-12 ms-2" id="btn_TaskSmartAssignCheck" [disabled]="submitTouched || (!assignTask.userNames.length)"
            (click)="onSubmit('showTeamMemberOptions')" >{{translate?.general.buttons.checkAvailability.singular}}</button>

          <button pButton [disabled]="submitTouched || (!assignTask.userNames.length)" id="btn_TaskSmartAssignSubmit" class="ms-2 h-32" type="button"
            (click)="showConfirmation('', 'assign')">{{ translate?.general.buttons.assign.singular }}</button>
        </ng-container>

        <ng-container *ngIf="data.adhoc">
          <button pButton [disabled]="submitTouched || (!assignTask.userNames.length)" type="button" id="btn_TaskSmartAssignFindTime"
            (click)="onSubmit('showTeamMemberOptions')">{{translate?.general.buttons.findTime.singular}}</button>
        </ng-container>
      </div>
    </div>


  </div>

 <!--------------------------------------------- Check Avilability------------------------------------------------->
  <div class="custella-form-container position-relative d-block w-100 m-0 pb-4 checkAv" *ngIf="!first"
    style="box-shadow: none;background: #FAFAFA !important;">
    <div>
      <div class="d-flex align-items-center px-4 my-3" *ngIf="!first">
        <div>
          <i class="pi pi-arrow-left me-2 text-black" id="btn_TaskReturnCreateTask2" (click)="goBackToCreateTask()"></i>
          <span class="text-24 font-semiBold text-black" id="title_TaskCheckAvail">{{translate?.task.sections.sec15.singular}}</span>
        </div>
        <i class="pi pi-times text-black ms-auto" id="btn_TaskCheckAvailClose" (click)="modalRef.hide()"></i>
      </div>
      <div class="d-flex align-items-center rounded-2 custella-form-container">
        <div class="w-60 pe-3 py-4" style="padding-left: 2.15rem">
          <span class="text-20 font-semiBold" id="title_TaskCheckAvailName">{{data?.taskName}}</span>
          <div class="d-flex align-items-center mt-1">
            <span class="d-flex align-items-center color-var-500 me-4">
              <i class="pi pi-calendar cursor-none"></i>
              <span class="text-12 ms-2" id="label_TaskCheckAvailDateTime">{{ data?.startDateTime | momentDate : dateFormat : timezone}}</span>
            </span>
            <span class="d-flex align-items-center color-var-500 me-2">
              <i class="pi pi-clock cursor-none"></i>
              <span class="text-12 ms-2" id="label_TaskCheckAvailDuration" *ngIf="data.adhoc">{{ data.duration | durationFormatter }}</span>
              <span class="text-12 ms-2" id="label_TaskCheckAvailStartEndTime" *ngIf="!data.adhoc">{{ data?.startDateTime | momentDate : 'shortTime' : timezone}} - {{ data?.endDateTime | momentDate : 'shortTime' : timezone}}</span>
            </span>
          </div>
          <span class="d-flex align-items-center color-var-500 mt-1">
            <i class="pi pi-map-marker cursor-none"></i>
            <span *ngIf="data.location" class="text-12 ms-2" id="label_TaskCheckAvailLoc">{{data.location}}</span>
            <span *ngIf="data.locationName" class="text-12 ms-2" id="label_TaskCheckAvailLocName">{{ data.locationName }}</span>
          </span>
        </div>
        <div class="w-40 bg-white2" *ngIf="assignMode == 'team'" style="height: 140px">
          <div class="px-3 py-4">
            <p-dropdown [placeholder]="translate?.general.messages.none" [options]="teams" optionLabel="teamName" name="team" id="picklist_TaskCheckAvailTeamField"
              (onChange)="selectTeam(assignTask.teamName)" [(ngModel)]="assignTask.teamName" [filter]="true"
              filterBy="teamName"></p-dropdown>
            <span class="d-flex align-items-center font-black m-1">
              <span class="custella-content-row-input-title-three me-1" id="img_TaskCheckAvailTeamAccept" *ngIf="data?.teamSettings?.acceptDeclineTask"><i class="pi pi-check font-green"></i> </span>
              <span class="custella-content-row-input-title-three me-1" id="img_TaskCheckAvailTeamDecline" *ngIf="!data?.teamSettings?.acceptDeclineTask"><i class="pi pi-times font-red"></i> </span>
              <span class="text-12 font-medium d-block" id="label_TaskCheckAvailDeclAccept">{{translate?.task.fields.acceptDeclineTask.singular}}</span>
            </span>

            <span class="d-flex align-items-center font-black m-1">
              <span class="custella-content-row-input-title-three me-1" id="img_TaskCheckAvailTeamCheckIn" *ngIf="data?.teamSettings?.checkInFromLocation"><i class="pi pi-check font-green"></i> </span>
              <span class="custella-content-row-input-title-three me-1" id="img_TaskCheckAvailTeamCheckOut" *ngIf="!data?.teamSettings?.checkInFromLocation"><i class="pi pi-times font-red"></i> </span>
              <span class="text-12 font-medium d-block" id="label_TaskCheckAvailCheckInOut">{{translate?.task.fields.checkInOut.singular}}</span>
            </span>

            <span class="d-flex align-items-center font-black m-1">
              <span class="custella-content-row-input-title-three me-1" id="img_TaskCheckAvailInclTaskManager" *ngIf="data?.teamSettings?.includeManagerForTaskAssignment"><i class="pi pi-check font-green"></i> </span>
              <span class="custella-content-row-input-title-three me-1" id="img_TaskCheckAvailExclTaskManager" *ngIf="!data?.teamSettings?.includeManagerForTaskAssignment"><i class="pi pi-times font-red"></i> </span>
              <span class="text-12 font-medium d-block" id="label_TaskCheckAvailInclTeamManager">{{translate?.task.fields.includeTeamManager.singular}}</span>
            </span>
          </div>
        </div>
      </div>

      <div class="my-2 ms-3 rounded-2">
        <div class="custella-form-container overflow-scroll position-relative d-block rounded-2 ms-2">
          <div style="height:60vh">
            <bryntum-scheduler #scheduler 
            [resources]="resources" 
            [events]="events" 
            [columns]="schedulerConfig.columns"
              [startDate]="schedulerConfig.startDate!" 
              [endDate]="schedulerConfig.endDate!" 
              [viewPreset]="viewPreset"
              [rowHeight]="schedulerConfig.rowHeight" 
              [barMargin]="schedulerConfig.barMargin"
              [resourceTimeRangesFeature]="schedulerConfig.features.resourceTimeRanges"
              [eventTooltipFeature]="schedulerConfig.features.eventTooltip" 
              [listeners]="schedulerConfig.listeners"
              (onCellClick)="onCellClick($event)"  
              [eventEditFeature]  = false
              [eventRenderer]     = "eventRenderer"
              [readOnly] = true
              [panFeature] = "true"
              [eventLayout]="{type: 'none'}"
              ></bryntum-scheduler>

          </div>

          <div class="custella-form-container-footer mx-4 mb-0 pb-0 flex-between">
            <div class="d-flex align-items-center">
              <p-avatarGroup styleClass="p-mb-3" *ngFor="let user of selectedUsers;">
                <p-avatar [image]="user?.imageUrl" shape="circle" *ngIf="user?.imageUrl" [pTooltip]="user?.fullName" id="img_TaskCheckAvailUserAvatar"
                  tooltipPosition="top"></p-avatar>
                <p-avatar *ngIf="!user?.imageUrl" image="../../../../../assets/svg/Avatar.svg" shape="circle" id="img_TaskCheckAvailUserAvatar"
                  [pTooltip]="user?.fullName" tooltipPosition="top"></p-avatar>
              </p-avatarGroup>
              <span class="text-12 mx-2" id="label_TaskCheckAvailUserSel">{{selectedUsers.length}}&nbsp;{{translate?.task.fields.selected.singular}}</span>
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <button class="custella-btn-white text-12 ms-2" id="btn_TaskCheckAvailCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
              <p-button [label]="translate?.general.buttons.assign.singular" styleClass="ms-2 h-32" *ngIf="checkUsers?.matchedUsers.length" id="btn_TaskCheckAvailSubmit"
                (onClick)="showConfirmation(selectedUsers, 'assignToTeam')"></p-button>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</div>
