<div>
  <div class="flex-between">
    <i class="fa-regular fa-xmark w-24px dialog-close-icon" id="btn_SitesClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="closeTable()"></i>
  </div>
  <div class="position-relative text-start">
    <span class="title" id="label_SitesSelectList">{{translate?.general.commonComponent.sections.sec11.singular}}</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText autocomplete="off" id="input_SitesSelectListField" [placeholder]="translate?.general.buttons.search.singular" [(ngModel)]="searchValue" />
      </span>
    </div>
    <div class="height-42-stat border" *ngIf="translate">
      <app-custella-sites-table [searchValue]="searchValue" [translate]="translate"></app-custella-sites-table>
    </div>
  </div>
</div>