<div class="left-right-space flex-column-100 overflow-hidden">
    <!-- Title -->
    <div class="flex-between mb-2">
        <!-- Filter and New -->
        <div class="custella-dashboard-top-right d-flex ms-auto">
            <button type="button" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_TixJobRefresh" *ngIf="noTicketJobOrders !== null && noTicketJobOrders === false" (click)="onRefresh()">
                <i class="fa-regular fa-arrows-rotate"></i>
            </button>
            <button class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" id="btn_TixJobFilter" *ngIf="noTicketJobOrders !== null && noTicketJobOrders === false" (click)="toggleFilter($event)">
                <i class="fa-regular fa-filter-list"></i>
            </button>
            <div class="p-inputgroup search ms-2" *ngIf="noTicketJobOrders !== null && noTicketJobOrders === false">
                <span class="p-input-icon-right ">
                    <input type="text" class="w-100" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" id="input_TixJobSearchField" [(ngModel)]="searchValue" (keydown.enter)="onSearch()"  (keyup)="onSearchCheck()" />
                    <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" id="btn_TixJobSearchClear" (click)="onClear()"></i>
                </span>   
                <button type="button" pButton icon="fa-regular fa-magnifying-glass" id="btn_TixJobSearch" (click)="onSearch()"></button>
            </div>
            <p-button [label]="translate?.general.buttons.new.singular"  icon="fa-regular fa-plus" styleClass="ms-2 h-32" id="btn_TixJobNew" (onClick)="newJobOrder()" *ngIf="createJobOrder"></p-button>
        </div>
    </div>

    <div *ngIf="noTicketJobOrders" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" >
                {{translate?.general.messages.noRecordsFound}}
            </p>
        </div>
    </div>

    <div *ngIf="translate"  [hidden]="noTicketJobOrders === null || noTicketJobOrders === true" [ngClass]="loadedDymanically ? 'h-100-70' : 'h-95-20'" >
        <app-job-orders-table [translate]="translate"  class="cust-jo" [accessFrom]="accessFrom" [data]="data" [search]="search" [extraData]="ticketData" (noDataEvent)="noData($event)"></app-job-orders-table>
    </div>
</div>
