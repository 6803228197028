import { Component, OnDestroy, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImportExportService } from 'src/app/_services/import-export.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
var translateData: any;
@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html'
})
export class ExportDataComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  display: boolean = true;
  buttonLabel: any;

  first: boolean = true;
  data: any;
  selectAllFields: boolean = false;
  moduleList: any = [];
  selectedModule: any = { key: null, value: null };
  moduleFieldList: any = [];
  selectedModuleFieldList: any = Array();
  body = new Array();
  downloadFileName = "";

  isExport = false;
  translate: any;

  constructor(public modalRef: BsModalRef,
    private dataExportService: ImportExportService,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService,
    private languageTranslateService: LanguageTranslateService
  ) {
    super()
   }

  ngOnInit(): void {
    this.getLanguageData();
  }

  changeMode() {
    this.body = [];
    console.log(this.selectedModule,"selectAllFields")
    if(this.selectedModule) {
        this.moduleFieldList.forEach((itm: any) => {
          if (itm.checked) {
            this.body.push(itm);
          }
        });
        if (this.body.length > 0) {
          this.first = false;
        } else {
          this.errorUtil.setErrorMessage(400, null ,  this.translate?.setup.importExport.messages.specifyOneMoreFields, 'error',3000);
        
        }
    } else {
      this.errorUtil.setErrorMessage(400, null ,  this.translate?.setup.importExport.messages.pleaseSelectModule, 'error',3000);
    
  }
  }

  onModuleChange() {
    console.log(this.selectedModule,"selectedModule")
    if (this.selectedModule != null && this.selectedModule.key) {
      this.moduleList.forEach((s: any) => { if (s.key === this.selectedModule.key) this.selectedModule = s })
      this.spinner.show();
      this.dataExportService.getModuleFieldList(this.selectedModule.key, true).subscribe(res => {
        this.moduleFieldList = res;
        if(this.selectAllFields) { 
          this.moduleFieldList.forEach((field: any) => {
            field.checked = true;
          });
        }
        this.spinner.hide();
      });
      this.downloadFileName = this.selectedModule.value + moment().format('DD-MM-YYYY') + ".csv";
    } else this.moduleFieldList = [];
  }

  onSelectedAllFieldChange(event: any) {
    if (this.moduleFieldList) {
      this.moduleFieldList.forEach((field: any) => {
        if (event.checked)
          field.checked = true;
        else
          field.checked = false;
      });
    }
  }

  exportData() {
    if (this.body.length > 0) {
      this.dataExportService.exportCsv(this.body, this.selectedModule.key).subscribe(res => {
        // let blob = new Blob([res._body], { type: res._body.type });
        let blob: any = new Blob([res], { type: 'text/json; charset=utf-8' });
        FileSaver.saveAs(blob, `${this.selectedModule.value}_${moment().format('DD-MM-YYYY')}.csv`);
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200,  this.translate?.setup.importExport.messages.exportedSuccesfully, null, 'success', 3000);
        this.dataExportService.markResultSuccess();
      }, (error: any) => {
        this.errorUtil.setErrorMessage(error.status, null,  this.translate?.setup.importExport.messages.unableToSave, 'error', 3000);
      });
    } else {
      this.errorUtil.setErrorMessage(400, null, this.translate?.setup.importExport.messages.specifyOneMoreFields, 'error', 3000);
    }
  }

  next() {
    this.changeMode();
    if (this.selectedModule != null && this.body.length > 0) {
      this.isExport = true;
    }
  }

  goBack() {
    this.isExport = false;
  }

  getLanguageData() {
    this.spinner.show()
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
          this.dataExportService.getModuleList().subscribe(res => {
            this.moduleList = res;

          });
          this.buttonLabel = this.translate?.general.buttons.next.singular
          this.spinner.hide()
        }
      }))

    //this.mainLabel = this.translate?.site.label.plural
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
