 <form class="custella-custom-dialog-no" [formGroup]="passwordForm" *ngIf="type == 'self'"  (click)="onClickBackground()">
    <div class="modal-header p-dialog-header flex-between">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 font-medium ms-3" id="title_SetUserResetPwd">{{translate?.resetPassword.label.singular }}</span>
      </div>
      <i class="fa-regular fa-xmark" (click)="modalRef.hide()" id="btn_SetUserResetPwdClose"></i>
    </div>
      <div class="modal-body">
      <div class="container">   
          <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" id="label_SetUserOldPwd" >{{translate?.resetPassword.fields.oldPassword.singular}}</span>
            <span class="p-input-icon-right w-100">
              <input pInputText autocomplete="off" formControlName="oldPassword" id="input_SetUserOldPwdField" [(ngModel)]="password.oldPassword" name="oldPassword" [type]="fieldTextType ? 'text' : 'password'"  >
              <i class="pi" [ngClass]="{ 'pi-eye-slash': !fieldTextType, 'pi-eye': fieldTextType}"(click)="showPassword()" id="btn_LoginPwdShow"></i>
            </span>
                  <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['oldPassword'].touched && passwordForm.controls['oldPassword'].invalid">
                    <span *ngIf="passwordForm.controls['oldPassword'].errors?.required" class="text-10 p-error" id="label_SetUserOldPwdReqField" >{{translate?.general.validationFE.requiredField }}</span>
                    <!-- <span *ngIf="passwordForm.controls['oldPassword'].errors?.pattern" class="text-10 p-error" id="label_SetUserOldPwdMustBe" >{{'password_must_be'}}</span> -->
                  </div>  
          </div>                 
          <div class="custella-content-row-input col-12 mb-3">
              <span class="custella-content-row-input-title" id="label_SetUserNewPwd" >{{translate?.resetPassword.fields.newPassword.singular}}</span>
              <span class="p-input-icon-right w-100">
                <input pInputText autocomplete="off" formControlName="password" [(ngModel)]="password.password" name="password" [type]="fieldTextType2 ? 'text' : 'password'"  id="input_SetUserNewPwdField" (input)="getPassowrdLength($event)">
                <i class="pi" [ngClass]="{ 'pi-eye-slash': !fieldTextType2, 'pi-eye': fieldTextType2}"(click)="showNewPassword()" id="btn_LoginPwdShow"></i>
              </span>
                  <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['password'].touched && passwordForm.controls['password'].invalid">
                    <span *ngIf="passwordForm.controls['password'].errors?.required" class="text-10 p-error" id="label_SetUserNewPwdReqField" >{{translate?.general.validationFE.requiredField }}</span>
                    <!-- <span *ngIf="passwordForm.controls['password'].errors?.pattern" class="text-10 p-error" id="label_SetUserNewPwdMustBe" >{{'password_must_be'}}</span> -->
                  </div>  
                  <div class="pwd-valid" *ngIf="showtooltip || isPasswordHasUserName">
                    <div class="pwd-valid-body">
                        <div class="pb-1">
                          <span id="label_SetUserPwdRequirement">{{translate?.resetPassword.messages.passwordRequirement}}&colon;</span>
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" id="icon_SetUserPwdRequirementOneUppercaseSuccess" *ngIf="passwordFormField?.value?.match('^(?=.*[A-Z])')"></i>
                              <i class="fa-regular fa-xmark p-error" id="icon_SetUserPwdRequirementOneUppercaseError" *ngIf="!passwordFormField?.value?.match('^(?=.*[A-Z])')"></i>
                            </span> 
                            <span class=" text-10 ms-2"[ngClass]="{'text-success':passwordFormField?.value?.match('^(?=.*[A-Z])')}" id="label_SetUserPwdRequirementOneUppercase">{{translate?.resetPassword.messages.oneUppercase}}</span>
                                   
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success"  id="icon_SetUserPwdRequirementOneLowercaseSuccess" *ngIf="passwordFormField?.value?.match('^(?=.*[a-z])')"></i>
                              <i class="fa-regular fa-xmark p-error"  id="icon_SetUserPwdRequirementOneLowercaseError"*ngIf="!passwordFormField?.value?.match('^(?=.*[a-z])')"></i>
                            </span>
                            <span class="text-10 ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('^(?=.*[a-z])')}" id="label_SetUserPwdRequirementOneLowercase">{{translate?.resetPassword.messages.oneLowercase}}</span>
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" id="icon_SetUserPwdRequirementOneNumericSuccess" *ngIf="passwordFormField?.value?.match('(.*[0-9].*)')"></i>
                              <i class="fa-regular fa-xmark p-error" id="icon_SetUserPwdRequirementOneNumericError" *ngIf="!passwordFormField?.value?.match('(.*[0-9].*)')"></i>
                            </span>
                            <span class="text-10 ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('(.*[0-9].*)')}" id="label_SetUserPwdRequirementOneNumeric">{{translate?.resetPassword.messages.oneNumeric}}</span>
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" id="icon_SetUserPwdRequirementMinCharSuccess" *ngIf="passwordFormField?.value?.match('.{8,}')"></i>
                              <i class="fa-regular fa-xmark p-error" id="icon_SetUserPwdRequirementMinCharError" *ngIf="!passwordFormField?.value?.match('.{8,}')"></i>
                            </span>
                            <span class="text-10 ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('.{8,}')}" id="label_SetUserPwdRequirementMinChar">{{translate?.resetPassword.messages.minimumChars}}</span>
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" id="icon_SetUserPwdRequirementOneSymbolSuccess" *ngIf="passwordFormField?.value?.match('(?=.*[!@#$%^&*])')"></i>
                              <i class="fa-regular fa-xmark p-error" id="icon_SetUserPwdRequirementOneSymbolError" *ngIf="!passwordFormField?.value?.match('(?=.*[!@#$%^&*])')"></i>
                            </span>
                            <span class="text-10 ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('(?=.*[!@#$%^&*])')}" id="label_SetUserPwdRequirementOneSymbol">{{translate?.resetPassword.messages.oneSymbol}}</span>
                        </div>
                        <div class="flex-align-center" >
                          <span class="w-12px lh-1">
                            <i class="fa-regular fa-check text-success" id="icon_SetUserPwdRequirementUsernameExclSuccess" *ngIf="!isPasswordHasUserName"></i>
                            <i class="fa-regular fa-xmark p-error"  id="icon_SetUserPwdRequirementUsernameExclError" *ngIf="isPasswordHasUserName"></i>
                          </span>
                          <span class="text-10 ms-2" [ngClass]="{'text-success':!isPasswordHasUserName}" id="label_SetUserPwdRequirementUsernameExcl">{{translate?.resetPassword.messages.usernameExclusion}}</span>
                      </div>
                    </div>
                 </div> 
          </div>
          <div class="custella-content-row-input col-12">
              <span class="custella-content-row-input-title" id="label_SetUserConfirmNewPwd" >{{translate?.resetPassword.fields.confirmNewPassword.singular}}</span>
              <span class="p-input-icon-right w-100">
                <input pInputText autocomplete="off" formControlName="cPassword" (input)="getCPassowrdLength($event)" [(ngModel)]="password.cPassword" id="input_SetUserConfirmNewPwdField" name="cPassword" type="password" [type]="fieldTextType3 ? 'text' : 'password'"  >
                <i class="pi" [ngClass]="{ 'pi-eye-slash': !fieldTextType3, 'pi-eye': fieldTextType3}"(click)="showConfirmPassword()" id="btn_LoginPwdShow"></i>
              </span>
                <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['cPassword'].touched && passwordForm.controls['cPassword'].invalid">
                  <span *ngIf="passwordForm.controls['cPassword'].errors?.required" class="text-10 p-error" id="label_SetUserConfirmNewPwdReqField" >{{translate?.general.validationFE.requiredField }}</span>
                  <!-- <span *ngIf="passwordForm.controls['cPassword'].errors?.pattern" class="text-10 p-error" id="label_SetUserConfirmNewPwdMustBe" >{{'password_must_be'}}</span> -->
                </div>
                <div *ngIf="!isPasswordMatching && (password.cPassword && password.cPassword.length > 0)">
                  <span class="p-error text-10" id="label_PwdActivateRetypePwdReqField">{{translate?.resetPassword.validationFE.passwordsDoNotMatch }}</span>
                  <!-- <span class="p-error text-10" id="label_PwdActivateRetypePwdMustBe" *ngIf="accountForm.controls['cPassword'].errors?.pattern" >{{'password_must_be'}}</span> -->
              </div>    
          </div>
      </div>
    </div>
    <div class="modal-footer p-0">
        <button class="custella-btn-white mx-1 text-12" id="btn_SetUserPwdCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular }}</button>
        <p-button (onClick)="changePassword()" [disabled]="submitTouched" id="btn_SetUserPwdReset" [label]="translate?.general.buttons.reset.singular " styleClass="mx-1"></p-button>
    </div>
  </form>
  
  <form class="custella-custom-dialog-no"  *ngIf="type == 'others' || type == 'resendCustomerUserEmail'">
    <div class="modal-header p-dialog-header flex-between">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 ffont-medium ms-3" id="title_SetUserResetPwdEmail">{{translate?.resetPassword.label.singular }}</span>
      </div>
      <i class="fa-regular fa-xmark" (click)="modalRef.hide()" id="btn_SetUserResetPwdEmailClose"></i>
    </div>
    <div class="modal-body">
      <div class="container text-center my-4">       
        <p translate id="label_SetUserResetPwdEmailDesc">{{translate?.resetPassword.messages.anEmailWillBeSentTo }}&nbsp;{{data1.email}}.&nbsp;{{translate?.resetPassword.messages.doYouWishProceed }}</p>
      </div>
    </div>
    <div class="modal-footer p-0">
        <button [disabled]="submitTouched"  class="custella-btn-white text-12" id="btn_SetUserResetPwdEmailNo" (click)="modalRef.hide()">{{translate?.general.buttons.no.singular}}</button>
        <p-button  [disabled]="submitTouched" (onClick)="onSubmit('reset')" [disabled]="submitTouched" [label]="translate?.general.buttons.yes.singular" id="btn_SetUserResetPwdEmailYes" styleClass=""></p-button>
    </div>

  </form>
  
  
  <form class="custella-custom-dialog-no" *ngIf="type == 'resendEmail'">
    <div class="modal-header p-dialog-header">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 ffont-medium ms-3" id="title_SetUserResendEmail">{{translate?.resetPassword.sections.sec2.singular }}</span>
      </div>
      <i class="fa-regular fa-xmark" id="btn_SetUserResendEmailClose" (click)="modalRef.hide()"></i>
    </div>
      <div class="modal-body">
        <div class="container text-center my-4">       
          <p id="label_SetUserResendEmailDesc">{{translate?.resetPassword.messages.anEmailWillBeSentTo }}&nbsp;{{data1?.email}}.&nbsp;{{translate?.resetPassword.messages.doYouWishProceed }}</p>
        </div>
      </div>
      
      <div class="modal-footer p-0">
        <button [disabled]="submitTouched"  class="custella-btn-white text-12" id="btn_SetUserResendEmailNo" (click)="modalRef.hide()">{{translate?.general.buttons.no.singular}}</button>
        <p-button  [disabled]="submitTouched" (onClick)="onSubmit('email')" id="btn_SetUserResendEmailYes" [disabled]="submitTouched" [label]="translate?.general.buttons.yes.singular" styleClass=""></p-button>
    </div>
    </form>


   
      