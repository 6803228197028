<div class="left-right-space flex-column-100 overflow-hidden">
  <div class="flex-between mb-2">
      <div class="custella-dashboard-top-right d-flex ms-auto">
          <button  type="button" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" (click)="onRefreshList()" id="btn_CheckProdRefresh">
              <i class="fa-regular fa-arrows-rotate"></i>
          </button>
          <div class="p-inputgroup search ms-2">
              <span class="p-input-icon-right ">
                <input type="text" class="w-100" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" id="input_CheckProdSearchField" [(ngModel)]="searchValue" (keyup)="onSearchCheck()" (keydown.enter)="onSearch()" />
                <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()" id="btn_CheckProdSearchClear"></i>
              </span>   
              <button type="button" pButton icon="fa-regular fa-magnifying-glass" id="btn_CheckProdSearch" (click)="onSearch()"></button>
          </div>
      </div>
  </div>


  <div *ngIf="noProductIcon" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
        <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
        <p class="text-6 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
    </div>
  </div>

  <div [hidden]="!noProduct" class="position-relative fw-normal h-95-20">
    <div class="position-relative h-100-10">
      <ag-grid-angular
      #agGrid
      style="height: 100%;"
      class="ag-theme-balham"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [defaultColDef]="defaultColDef"
      [maxConcurrentDatasourceRequests]="-1"
      [suppressDragLeaveHidesColumns]="true"
      [paginationPageSize]="25"
      [pagination]="false"
      [paginationPageSize]="25"
      [rowData]="products"
      [frameworkComponents]="frameworkComponents"
      (gridReady)="onGridReady($event)"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      (cellClicked)="viewProduct($event)"
      [getContextMenuItems]="getContextMenuItems"
  ></ag-grid-angular>
    
    <div class="col-12"> <p class="ag-record-row-summary-panel padds text-end" id="label_CheckProdTableRec">
        {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
      </p>
    </div>
    
  </div>
  </div>
</div>