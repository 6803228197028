<div class="integration-center">
    <div class="flex-align-center" style="gap: 5rem;">
        <div>
            <img src="assets/svg/google-calendar.svg" class="w-70px" alt="">
        </div>
        <div class="text-center">
            <h5>{{translate?.setup.integrations.messages.connecting}}</h5>
            <p>{{translate?.setup.integrations.messages.windowCloseIn}}&nbsp;{{time}}&nbsp;{{translate?.setup.integrations.messages.seconds}}</p>
        </div>
        <div>
            <img src="../../../../assets/svg/table/Fieldex_Bg.svg" class="w-70px" alt="">
        </div>
    </div>
</div>