
<div *ngIf="CustomerSummary" class="custella-form-container box-none bg-white m-0">
    <!-- Additional Details -->
    <div class="custella-form-container-title-two flex-between">
        <div class="flex-align-center">
            <span class="text-14 font-medium" id="title_SlideCustSummary">{{translate?.general.summary.sections.sec4.singular}}</span>
            <span class="ms-2">
                <i class="fa-regular fa-arrow-up-right-from-square" id="btn_SlideCustNewTab" [pTooltip]="translate?.general.buttons.openInNewTab.singular" tooltipPosition="bottom" (click)="onCustomerViewOnNewTab()"></i>
            </span>
        </div>
        <span  (click)="closeRightNav()" id="btn_SlideCustSummaryClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="bottom"><i class="pi pi-times"></i></span>
    </div>
    <div class="scroll">
        <div class="ov-content-solo pb-3">
            <div class="ov-row">
                <div class="label-c-0" id="label_SlideCustName">{{CustomerSummary.name}}</div>
            </div>
            <div class="ov-row" *ngIf="CustomerSummary.description" >
                  <span class="value-desc" id="label_SlideCustLatestReading">{{CustomerSummary.description}}</span>
            </div>
        </div>
        <div class="ov-title-summ">
            <label class="font-medium" id="title_SlideCustGenDets">{{translate?.general.summary.sections.sec1.singular}}</label>
        </div>
        <div class="ov-content-solo">
            <div class="container p-0">
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_SlideCustType">{{translate?.customer.fields.type.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div  *ngIf="CustomerSummary.customersType" class="p-0 value" id="value_SlideCustType">{{CustomerSummary.customersType.label}}</div>
                    <span *ngIf="!CustomerSummary.customersType" class="value" id="value_SlideCustType">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_SlideCustType">{{translate?.customer.fields.category.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div  *ngIf="CustomerSummary.categoryType" class="p-0 value" id="value_SlideCustType">{{CustomerSummary.categoryType.label}}</div>
                    <span *ngIf="!CustomerSummary.categoryType" class="value" id="value_SlideCustType">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_SlideCustSector">{{translate?.customer.fields.sector.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div  *ngIf="CustomerSummary.customerSector" class="p-0 value" id="value_SlideCustSector">{{CustomerSummary.customerSector.label}}</div>
                    <span *ngIf="!CustomerSummary.customerSector" class="value" id="value_SlideCustSector">{{"-"}}</span>
                  </div>
                </div>
                <div class="row g-2 ov-row-0">
                  <div class="col-6 text-start">
                    <div class="p-0 label" id="label_SlideCustSubSector">{{translate?.customer.fields.subSector.singular}}&colon;&nbsp;</div>
                  </div>
                  <div class="col-6">
                    <div  *ngIf="CustomerSummary.customerSubSector" class="p-0 value" id="value_SlideCustSubSector">{{CustomerSummary.customerSubSector.label}}</div>
                    <span *ngIf="!CustomerSummary.customerSubSector" class="value" id="value_SlideCustSubSector">{{"-"}}</span>
                  </div>
                </div>
            </div>
        </div>
        <div class="ov-title-summ">
            <label class="font-medium" id="title_SlideCustLocDets">{{translate?.general.locationDetails.sections.sec0.singular}}</label>
        </div>
        <div class="ov-content-solo">
            <div class="container p-0">
              <div class="row g-2 ov-row-0">
                <div class="col-6 text-start">
                  <div class="p-0 label" id="label_SlideCustSerZone">{{translate?.setup.serviceZone.label.singular}}&colon;&nbsp;</div>
                </div>
                <div class="col-6">
                  <div  *ngIf="CustomerSummary.serviceZone" class="p-0 value" id="value_SlideCustSerZone">{{CustomerSummary.serviceZone.label}}</div>
                  <span *ngIf="!CustomerSummary.serviceZone" class="value" id="value_SlideCustSerZone">{{"-"}}</span>
                </div>
              </div>
              <div class="row g-2 ov-row-0">
                <div class="col-6 text-start">
                  <div class="p-0 label" id="label_SlideCustGeofence">{{translate?.setup.geofence.label.singular}}&colon;&nbsp;</div>
                </div>
                <div class="col-6">
                  <div  *ngIf="CustomerSummary.geoFence" class="p-0 value" id="value_SlideCustGeofence">{{CustomerSummary.geoFence.geoFenceName}}</div>
                  <span *ngIf="!CustomerSummary.geoFence" class="value" id="value_SlideCustGeofence">{{"-"}}</span>
                </div>
              </div>
              <div class="row g-2 ov-row-0">
                <div class="col-6 text-start">
                  <div class="p-0 label" id="label_SlideCustArea">{{translate?.customer.fields.area.singular}}&colon;&nbsp;</div>
                </div>
                <div class="col-6">
                  <div  *ngIf="CustomerSummary.area" class="p-0 value" id="value_SlideCustArea">{{CustomerSummary.area}}</div>
                  <span *ngIf="!CustomerSummary.area" class="value" id="value_SlideCustArea">{{"-"}}</span>
                </div>
              </div>
              <div class="row g-2 ov-row-0">
                <div class="col-6 text-start">
                  <div class="p-0 label" id="label_SlideCustLocation">{{translate?.customer.fields.location.singular}}&colon;&nbsp;</div>
                </div>
                <div class="col-6">
                  <div  *ngIf="CustomerSummary.shippingStreet" class="p-0 value" id="value_SlideCustLocStreet">{{CustomerSummary.shippingStreet}}</div>
                  <div  *ngIf="CustomerSummary.shippingCity" class="p-0 value" id="value_SlideCustLocCity">{{CustomerSummary.shippingCity}}</div>
                  <div  *ngIf="CustomerSummary.shippingState" class="p-0 value" id="value_SlideCustLocState">{{CustomerSummary.shippingState}}</div>
                  <div  *ngIf="CustomerSummary.shippingPostcode" class="p-0 value" id="value_SlideCustLocPostcode">{{CustomerSummary.shippingPostcode}}</div>
                  <div  *ngIf="CustomerSummary.shippingCountry" class="p-0 value" id="value_SlideCustLocCountry">{{CustomerSummary.shippingCountry}}</div>
                  <span 
                  *ngIf="!CustomerSummary.shippingStreet
                    && !CustomerSummary.shippingCity
                    && !CustomerSummary.shippingState
                   && !CustomerSummary.shippingPostcode
                   && !CustomerSummary.shippingCountry  " class="value" id="value_SlideCustLocation">{{"-"}}</span>
                </div>
              </div>
            </div>
        </div>
        <div class="ov-title-summ">
            <label class="font-medium" id="title_SlideCustContaDets">{{translate?.contacts.sections.sec0.singular}}</label>
        </div>
        <div class="ov-content-solo border-0">
            <div class="container p-0">
              <div class="row g-2 ov-row-0">
                <div class="col-6 text-start">
                  <div class="p-0 label" id="label_SlideCustContName">{{translate?.contacts.fields.contactName.singular}}&colon;&nbsp;</div>
                </div>
                <div class="col-6">
                  <div  *ngIf="CustomerSummary.contactPersonName && CustomerSummary.contactPersonName != '' " class="p-0 value" id="value_SlideCustContName">{{CustomerSummary.contactPersonName}}</div>
                  <span *ngIf="!CustomerSummary.contactPersonName || CustomerSummary.contactPersonName == ''" class="value" id="value_SlideCustContName">{{"-"}}</span>
                </div>
              </div>
              <div class="row g-2 ov-row-0">
                <div class="col-6 text-start">
                  <div class="p-0 label" id="label_SlideCustEmail">{{translate?.contacts.fields.email.singular}}&colon;&nbsp;</div>
                </div>
                <div class="col-6">
                  <div  *ngIf="CustomerSummary.email" class="p-0 value" id="value_SlideCustEmail">{{CustomerSummary.email}}</div>
                  <span *ngIf="!CustomerSummary.email" class="value" id="value_SlideCustEmail">{{"-"}}</span>
                </div>
              </div>
              <div class="row g-2 ov-row-0">
                <div class="col-6 text-start">
                  <div class="p-0 label" id="label_SlideCustPhone">{{translate?.contacts.fields.phone.singular}}&colon;&nbsp;</div>
                </div>
                <div class="col-6">
                  <div  *ngIf="CustomerSummary.phone && CustomerSummary.phone != ''" class="p-0 value" id="value_SlideCustPhone">{{CustomerSummary.phone}}</div>
                  <span *ngIf="!CustomerSummary.phone || CustomerSummary.phone == ''" class="value" id="value_SlideCustPhone">{{"-"}}</span>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>