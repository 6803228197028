import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-download-export-data',
  templateUrl: './download-export-data.component.html',
  styleUrls: ['./download-export-data.component.scss']
})
export class DownloadExportDataComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  display : boolean = true;
  buttonLabel : any
  translate: any;

  constructor(public modalRef: BsModalRef, private util: UtilServiceService,

    private languageTranslateService: LanguageTranslateService
  ) { 
    super()
  }

  ngOnInit(): void {
    this.getLanguageData();
  }

  goBack(){
    this.util.goBack()
   }

   getLanguageData() {
   // this.spinner.show()
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
          this.buttonLabel = this.translate?.general.buttons.save.singular
        //  this.spinner.hide();
        }
      }))
   // console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
