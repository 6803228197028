import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-optimize-planned-actual',
  templateUrl: './optimize-planned-actual.component.html'
})
export class OptimizePlannedActualComponent implements OnInit {

  markerOptions: google.maps.MarkerOptions = {draggable: false};
   mapOptions: google.maps.MapOptions = {maxZoom: 22,styles: [ 
    { "featureType": "administrative", "elementType": "geometry", "stylers": [ { "visibility": "off" } ] }, 
    { "featureType": "poi", "stylers": [ { "visibility": "off" } ] }, 
    { "featureType": "road", "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, 
    { "featureType": "transit", "stylers": [ { "visibility": "off" } ] } 
]
  };
  @ViewChild(MapInfoWindow,{static: false})
  infoWindow!: MapInfoWindow;
  optionsMap:any
  optionsMap1:any
  pointer: any={x:12.4,y:13}
  scaledSize :any ={height: 34,width: 24}

  selectedMarker:any
  selectedIndex: any

  view : string = 'Map';
  optimized: any;
  counter : any = 0;
  timezone: any
  

  public latitude: number = 3.100176;
  public longitude: number = 101.591085;
  polylinePoints: any[] = [];
  markers: any[] =[];
  travelMode = 'DRIVING';
  tasks: any[] = [];
  completedTasks: any[] = [];
  // date: any;
  latlngBounds: any;
  routeOptimized: boolean = false;
  totalTravelTime: number = 0;
  totalTravelDistance: number = 0;
  polyLines: any[] = [];
  colors = ["red", "green", "black", "blue", "orange", "purple", "maroon"];
  styleValue: any;
  markerWaypoint : any[] =[];translate: any;
;
  planMarkers: any[] =[];;
  totalTravelTime1: number = 0;
  totalTravelDistance1: number = 0;

  constructor(private cdr: ChangeDetectorRef,
    private spinner : NgxSpinnerService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private taskService: TasksService,
    private router : Router,
    private languageTranslateService: LanguageTranslateService) { 
      this.languageTranslateService.menuSrc$.subscribe(data => {
        if(Object.keys(data).length != 0){ this.translate = data}
      })
    }

  ngOnInit() {
    this.optionsMap = {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: 14,
    };

    this.optionsMap1 = {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: 14,
    };
    this.timezone = this.auth.getUserTimezone()
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.loadPlanMap(params.userId, params.date)
      this.loadMap(params.userId, params.date);
    
    });
  }

  goBack(){
    window.history.back();
  }

  changeView(view : any){
    this.view = view;
  }

  

  setOptimized(val : any){
    this.optimized = val;
  }

  showLoader(val : any){
    this.counter += val;
    console.log("Loader ", this.counter);
    if(this.counter == 2){
      this.spinner.hide();
      this.counter = 0;
    }
  }
/********************************************************** Planned API *********************************************/
  loadPlanMap(userId : any, date: any) {
    //console.log("Users Received ", users);
    this.tasks = [];
    this.markers = [];
    forkJoin(this.taskService.getTodaysTasks(userId, date, this.auth.getUserTimezone()), 
    this.taskService.getRouteOptimizationByDate(userId, date))
    .subscribe(([tasksResponse, optimizedResponse]) => {
      this.tasks = tasksResponse;
      if(optimizedResponse && optimizedResponse.isOptimized){
        this.routeOptimized = true;
      }
      this.setOptimized(this.routeOptimized);
      if(tasksResponse.length){
        //Show only accepted tasks
        this.tasks = _.filter(this.tasks, (o) => {
          return o.assignStatus == 'ACCEPTED';
        })
          var i=0
        for(var j=0;j<this.tasks.length;j++){
          i++
          if(optimizedResponse && optimizedResponse.isOptimized){
            if(this.tasks[j].nextTaskTravelTime){
              this.totalTravelTime1 += parseFloat(this.tasks[j].nextTaskTravelTime);
            }
            if(this.tasks[j].nextTaskDistance){
              this.totalTravelDistance1 += parseFloat(this.tasks[j].nextTaskDistance);
            }
          }
          var profilePic= 'assets/svg/Avatar.svg'
          if(this.tasks[j].assignedTo.imageUrl){
             profilePic = this.tasks[j].assignedTo.imageUrl
          }
          if(j == 0){
            console.log(this.latitude,"===",this.longitude,"----",this.tasks[j])
            var temp : any = {
              origin: { lat:  this.tasks[j].latitude, lng:this.tasks[j].longitude },
              destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
              visible: true
            }
            var marker : any= {
              latitude : this.tasks[j].latitude,
              longitude : this.tasks[j].longitude,
              lat : this.tasks[j].latitude,
              lng : this.tasks[j].longitude,
              location : this.tasks[j].location,
              taskName : this.tasks[j].taskName,
              icon : '../../../../assets/images/planning-hub/Green Pin.png',
              startTime : this.tasks[j].startDateTime,
              endTime : this.tasks[j].endDateTime,
              id : this.tasks[j].id,
              label:i.toString()
            }
          } else if (j == this.tasks.length-1){
            var temp : any = {
              origin: { lat: this.tasks[j-1].latitude, lng: this.tasks[j-1].longitude  },
              destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
              visible: true
            }
            var marker : any= {
              latitude : this.tasks[j].latitude,
              longitude : this.tasks[j].longitude,
              lat : this.tasks[j].latitude,
              lng : this.tasks[j].longitude,
              location : this.tasks[j].location,
              taskName : this.tasks[j].taskName,
              icon : '../../../../assets/images/planning-hub/Green Pin.png',
              startTime : this.tasks[j].startDateTime,
              endTime : this.tasks[j].endDateTime,
              id : this.tasks[j].id,
              label:i.toString()
            }
          } else {
            var temp : any = {
              origin: { lat: this.tasks[j-1].latitude, lng: this.tasks[j-1].longitude  },
              destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
              visible: true
            }
            var marker : any= {
              latitude : this.tasks[j].latitude,
              longitude : this.tasks[j].longitude,
              lat : this.tasks[j].latitude,
              lng : this.tasks[j].longitude,
              location : this.tasks[j].location,
              taskName : this.tasks[j].taskName,
              icon : '../../../../assets/images/planning-hub/Green Pin.png',
              startTime : this.tasks[j].startDateTime,
              endTime : this.tasks[j].endDateTime,
              id : this.tasks[j].id ,
              label:i.toString()
            }
          }
          ////this.markerWaypoints.push(temp);
          this.planMarkers.push(marker);
        }
      }
      var groupedMatchingLocations = _.groupBy(this.planMarkers, 'location');
      for (var key in groupedMatchingLocations) {
        if (groupedMatchingLocations.hasOwnProperty(key) && groupedMatchingLocations[key].length > 1) {
          for (var index in groupedMatchingLocations[key]) {
            if(parseInt(index) > 0){
              groupedMatchingLocations[key][index].latitude = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
              groupedMatchingLocations[key][index].longitude = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);

              groupedMatchingLocations[key][index].lat = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
              groupedMatchingLocations[key][index].lng = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
              //console.log(key, groupedMatchingLocations[key][index]);
            }
          }
        }
      }
      if(this.planMarkers.length)
        this.fitBoundsPlan(this.planMarkers);
      else
        this.showLoader(1);
      console.log("Optimized Values ", this.routeOptimized, this.totalTravelTime1, this.totalTravelDistance1);
    });
  }

  fitBoundsPlan(markers: any) {
    setTimeout(() => {
      // this.mapsAPILoader.load().then(() => {
        this.latlngBounds = new window['google'].maps.LatLngBounds();
        markers.forEach((marker: any) => {
          this.latlngBounds.extend(new window['google'].maps.LatLng(marker.latitude, marker.longitude))
          this.optionsMap1.center= { lat: marker.lat, lng: marker.lng }
          this.optionsMap1.zoom = 18
        }); 
     // });
     // this.gmap.triggerResize();  
      this.showLoader(1);
      // this.cdr.detectChanges();
    }, 500);
  }

/********************************************************** Actual API *********************************************/

  loadMap(userId: any, date: any) {
    //console.log("Users Received ", users);
    this.tasks = [];
    this.markers = [];
    forkJoin(this.taskService.getTodaysTasks(userId, date, this.auth.getUserTimezone()), 
    this.taskService.getActualRouteByDate(userId, date))
    .subscribe(([tasksResponse, polylineResponse]) => {
      this.tasks = tasksResponse;
      this.spinner.hide()
      this.polyLines = polylineResponse;
      var colorIndex = 0;
      var i =0
      this.polyLines.forEach((element, ind) => {
        i++
        console.log(element)
        if(ind == 0){
          var marker : any = {
            latitude : this.polyLines[0].tripList.length >=1 ? this.polyLines[0].tripList[0].latitude : this.polyLines[0].userLocationRouteTable.startLocationUserDetails.latitude,
            longitude : this.polyLines[0].tripList >=1 ? this.polyLines[0].tripList[0].longitude : this.polyLines[0].userLocationRouteTable.startLocationUserDetails.longitude,
            lat : this.polyLines[0].tripList.length >=1 ? this.polyLines[0].tripList[0].latitude : this.polyLines[0].userLocationRouteTable.startLocationUserDetails.latitude,
            lng : this.polyLines[0].tripList >=1 ? this.polyLines[0].tripList[0].longitude : this.polyLines[0].userLocationRouteTable.startLocationUserDetails.longitude,
            taskName : 'Start Location',
            icon : '../../../../assets/images/planning-hub/Green Pin.png',
            label:'1'
          }
          this.markers.push(marker);
        }
        element.polyLine.forEach((elementP: any) => {
          elementP.lng=elementP.lon
        });
        var newPolyOption={
          strokeColor: element.strokeColor,
          strokeOpacity: '0.6',
          strokeWeight:'5'
        }
        element.newPolyOption=newPolyOption
        var seconds = 0;
        var duration = moment.duration(moment(element.userLocationRouteTable.endLocationUserDetails.createdDateTime).diff(moment(element.userLocationRouteTable.startLocationUserDetails.createdDateTime)));
        seconds = duration.asSeconds();
        this.totalTravelTime += seconds;
        element.tripList.forEach((trip : any) => {
          this.totalTravelDistance += trip.totalDistanceTravelled;
        });
        colorIndex ++;
        if(ind == 6){
          colorIndex = 0;
        }
      });

      if(tasksResponse.length){
        //Show only accepted tasks
        this.tasks = _.filter(this.tasks, (o) => {
          return o.assignStatus == 'ACCEPTED';
        })
        this.completedTasks = _.filter(this.tasks, (o) => {
          return o.taskStatus == 'COMPLETED';
        })
        var s=0
        for(var j=0;j<this.tasks.length;j++){
          s++
          if(j == 0){
            var marker : any= {
              latitude : this.tasks[j].latitude,
              longitude : this.tasks[j].longitude,
              lat : this.tasks[j].latitude,
              lng : this.tasks[j].longitude,
              location : this.tasks[j].location,
              taskName : this.tasks[j].taskName,
              icon : '../../../../assets/images/planning-hub/Green Pin.png',
              startTime : this.tasks[j].startDateTime,
              endTime : this.tasks[j].endDateTime,
              id : this.tasks[j].id,
              label:s.toString()
            }
          } else if (j == this.tasks.length-1){
            var marker : any= {
              latitude : this.tasks[j].latitude,
              longitude : this.tasks[j].longitude,
              lat : this.tasks[j].latitude,
              lng : this.tasks[j].longitude,
              location : this.tasks[j].location,
              taskName : this.tasks[j].taskName,
              icon : '.../../../../assets/images/planning-hub/Green Pin.png',
              startTime : this.tasks[j].startDateTime,
              endTime : this.tasks[j].endDateTime,
              id : this.tasks[j].id,
              label:s.toString()
            }
          } else {
            var marker : any= {
              latitude : this.tasks[j].latitude,
              longitude : this.tasks[j].longitude,
              lat : this.tasks[j].latitude,
              lng : this.tasks[j].longitude,
              location : this.tasks[j].location,
              taskName : this.tasks[j].taskName,
              icon : '../../../../assets/images/planning-hub/Green Pin.png',
              startTime : this.tasks[j].startDateTime,
              endTime : this.tasks[j].endDateTime,
              id : this.tasks[j].id ,
              label:s.toString()
            }
          }
          this.markers.push(marker);
        }
      }
      var groupedMatchingLocations = _.groupBy(this.markers, 'location');
      for (var key in groupedMatchingLocations) {
        if (groupedMatchingLocations.hasOwnProperty(key) && groupedMatchingLocations[key].length > 1) {
          for (var index in groupedMatchingLocations[key]) {
            if(parseInt(index) > 0){
              groupedMatchingLocations[key][index].latitude = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
              groupedMatchingLocations[key][index].longitude = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);

              groupedMatchingLocations[key][index].lat = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
              groupedMatchingLocations[key][index].lng = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
              //console.log(key, groupedMatchingLocations[key][index]);
            }
          }
        }
      }

      if(this.markers.length)
        this.fitBounds(this.markers);
      else
        this.showLoader(1);
      console.log("Optimized Values ", this.routeOptimized, this.totalTravelTime, this.totalTravelDistance);
    });
  }

  fitBounds(markers : any) {
    setTimeout(() => {
    //  this.mapsAPILoader.load().then(() => {
        this.latlngBounds = new window['google'].maps.LatLngBounds();
        markers.forEach((marker: any) => {
          this.latlngBounds.extend(new window['google'].maps.LatLng(marker.lat, marker.lng))
          this.optionsMap.center= { lat: marker.lat, lng: marker.lng }
          this.optionsMap.zoom = 18
        }); 
     // })
     // this.gmap.triggerResize();  
      this.showLoader(1);
    }, 500);
  }
openInfoWindow(marker: MapMarker,data : any,index: any) {
   this.selectedMarker = data
   this.selectedIndex = index
    this.infoWindow.open(marker);
  }

  showTaskDetails(marker: any){
    let selectedTask = _.filter(this.tasks, (o) => {
      return o.id == marker.id
    });
    if(selectedTask.length)
      this.viewTaskDetail(selectedTask[0]);
  }

  viewTaskDetail(data : any){
     this.router.navigate(["/dashboard/tasks/"+data.id], { queryParams: {id: data.id}});
  }
}
