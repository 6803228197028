import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { BreadcrumbService } from '../_services/breadcrumb.service';
import { ConfirmActionsService } from '../_services/confirm-actions.service';
import { DeleteRecordService } from '../_services/delete-record.service';
import { UtilServiceService } from '../_services/utilService/util-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, AfterContentChecked {

  public menuInactiveDesktop!: boolean;


  public profileActive!: boolean;

  public topMenuActive!: boolean;

  public topMenuLeaving!: boolean;

  documentClickListener!: () => void;

  menuClick!: boolean;

  topMenuButtonClick!: boolean;

  configActive!: boolean;

  configClick!: boolean;
  model: any
  /****************** Breadcrumb Variable *********/
  breadcrumbSubscription!: Subscription;
  items: MenuItem[] = [];

  deleteRecordSubscription: Subscription;
  deleteData: any = null
  collapsMenuActive: boolean = false;

  confirmActionSub: Subscription;
  confirmdata: any = null;
  compareWidth = 0;

  constructor(
    public renderer: Renderer2,
    public app: AppComponent,
    public breadcrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef,
    private deleteService: DeleteRecordService,
    private util: UtilServiceService,
    private confirmActionService: ConfirmActionsService,
    private router: Router
  ) {
    this.breadcrumbSubscription = breadcrumbService.itemsHandler.subscribe((response: any) => {
      this.items = response;
    });
    this.deleteRecordSubscription = deleteService.deleteItems.subscribe((response: any) => {
      console.log(response)
      this.deleteData = response;

    });

    this.confirmActionSub = this.confirmActionService.confirmSubObservable.subscribe((response: any) => {
      console.log(response,"----")
      this.confirmdata = response;
    });

  }

  ngOnInit() {
    console.log(this.util.getNavCollapseStatus())
    var collapseStatus: any = this.util.getNavCollapseStatus()
  
    if (collapseStatus) {
      this.menuInactiveDesktop = true
    } else {
      this.menuInactiveDesktop = false
    }
    console.log(collapseStatus, "===", this.menuInactiveDesktop)
  }

  ngAfterViewInit() {
    // hides the overlay menu and top menu if outside is clicked
    this.documentClickListener = this.renderer.listen('body', 'click', (event) => {


      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.configClick = false;
      this.menuClick = false;
      this.topMenuButtonClick = false;
    });
    this.cdr.detectChanges();
  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  /*************************** Lock and unlock Menu *************************************/
  toggleMenu(event: Event) {
    console.log(event)
    this.menuClick = true;

    this.menuInactiveDesktop = !this.menuInactiveDesktop;
    if (this.menuInactiveDesktop) {
      console.log("we are inn")
      this.util.setNavCollapseStatus(true);
      var collapseStatus: any = {
        status: this.menuInactiveDesktop,
        event: 'mouseOut',
      }
      this.util.setNavCollapseStatus1(collapseStatus);
     
    } else {
      console.log("we are innnnn")
      var collapseStatus: any = {
        status: this.menuInactiveDesktop,
        event: 'mouseOver',
      }
      this.util.setNavCollapseStatus1(collapseStatus);

      this.util.setNavCollapseStatus(false);
    }



    console.log(this.menuClick, "========", this.menuInactiveDesktop, "====")
    event.preventDefault();
  }
  /*************************** If Menu is Unlock the enable mouse over event to show menu *************************************/
  getMouseEvent(event: Event) {
    this.collapsMenuActive = this.menuInactiveDesktop ? true : false
    this.util.setNavCollapseStatus(this.collapsMenuActive);

    var collapseStatus: any = {
      status: this.menuInactiveDesktop,
      event: 'mouseOver',
    }
    this.util.setNavCollapseStatus1(collapseStatus);
    // console.log("Menu Loack====",this.menuInactiveDesktop)
    event.preventDefault();
  }
  getMouseEventOut(event: Event) {
    this.collapsMenuActive = this.menuInactiveDesktop ? false : false
    this.util.setNavCollapseStatus(this.collapsMenuActive);
    var collapseStatus: any = {
      status: this.menuInactiveDesktop,
      event: 'mouseOut',
    }
    this.util.setNavCollapseStatus1(collapseStatus);

    // console.log("Menu Loack====",this.menuInactiveDesktop)
    event.preventDefault();
  }
  toggleProfile(event: Event) {
    this.profileActive = !this.profileActive;
    event.preventDefault();
  }
  /*************************** If Menu is Unlock the enable mouse over event to show menu *************************************/


  isDesktop() {
    return window.innerWidth > 1024;
  }

  onSearchClick() {
    this.topMenuButtonClick = true;
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }


  }
  ngAfterViewChecked() {

    var dashContainer: any = document.getElementById('main');
    if (dashContainer) {
      var setWidth = dashContainer.clientWidth - 40
      var custellaNav: any = document.getElementsByClassName('p-tabview-nav-container');
      if (custellaNav.length) {
        custellaNav[0].style.width = setWidth + 'px';
      }
    }

    var headerContainer: any = document.getElementById('right-setup');
    if (headerContainer) {
      var setWidth = headerContainer.clientWidth - 40
      var custellaNav: any = document.getElementsByClassName('p-tabview-nav-container');
      // console.log(custellaNav)
      if (custellaNav.length) {
        custellaNav[0].style.width = setWidth + 'px';
      }
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {

        var dashContainer: any = document.getElementById('main');
        if (dashContainer) {
          var setWidth = dashContainer.clientWidth - 40
          var custellaNav: any = document.getElementsByClassName('p-tabview-nav-container');
          if (custellaNav.length) {
            custellaNav[0].style.width = dashContainer + 'px';
          }
        }

        var headerContainer: any = document.getElementById('right-setup');
        if (headerContainer) {
          var setWidth = headerContainer.clientWidth - 40
          var custellaNav: any = document.getElementsByClassName('setup-tabView p-tabview-nav-container');
          if (custellaNav.length) {
            custellaNav[0].style.width = setWidth + 'px';
          }
        }


      }
    });

  }

}