import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-check-availability',
  templateUrl: './check-availability.component.html'
})
export class CheckAvailabilityComponent implements OnInit {
  files !: any[];
  translate: any;
  constructor(public modalRef: BsModalRef,
    private languageTranslateService: LanguageTranslateService
  ) { }

  ngOnInit(): void {
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
    this.files=[
      {name: '50ft Truck', category: '50 Feet Truck', model: 'Honda'}
    ]
  }

}
