<!-- <ul  class="sub-topic ps-3 my-0 {{dataId}}" >
    <li *ngFor="let item of data; let i = index" routerLinkActive="{{item.routerLink ? 'active-menuitem':''}}" [ngClass]="{'setup-sub-manu-two ': item.data.length == 0,'d-none': item.hide}" class="accordion-collapse collapse ps-0" id="{{dataId}}" attr.aria-labelledby="setup-header{{nodeIndex}}" [routerLink]="item.routerLink" tabindex="i" href="javascript:void(0)">
        <div *ngIf="!isExpandable(item);" class="accordion-header"  [hidden]="item.hide" [ngClass]="{'d-none': item.hide}">
            <button class="accordion-button collapsed justify-content-between py-1 px-0" data-bs-toggle="collapse" attr.data-bs-target="#{{item.value}}" aria-expanded="false"  [attr.aria-controls]="item.value">
                {{item.name}}
            </button>
        </div>
        <div *ngIf="isExpandable(item);" class="accordion-header"  [hidden]="item.hide" [ngClass]="{'d-none': item.hide}">
            <button type="button" (click)="changeData(item) ; toggle(wfcElem)" class="accordion-button collapsed justify-content-between py-1 px-0" data-bs-toggle="collapse" attr.data-bs-target="#{{item.value}}" aria-expanded="false"  [attr.aria-controls]="item.value" [attr.href]="'#collapseBox' + i" >
                {{item.name}}
                <i class="pi pi-fw pi-angle-right" #wfcElem></i>
            </button>
        </div>
        <app-setup-menu-item *ngIf="isExpandable(item);" [data]="item.data"  [dataId]="item.value" [nodeIndex]="i" [id]="'collapseBox' + i"></app-setup-menu-item>
    </li>
</ul> -->


<ul  class="sub-topic ps-3 my-0" >
    <div *ngFor="let item of data; let i = index">
    <li *ngIf="!isExpandable(item);"  (click)="goToSetup()" routerLinkActive="{{item.routerLink ? 'active-menuitem':''}}" [ngClass]="{'setup-sub-manu-two ': item.data.length == 0,'d-none': item.hide}" class="accordion-collapse collapse ps-0 {{item.value}}-2" id="{{dataId}}" attr.aria-labelledby="setup-header{{nodeIndex}}" [routerLink]="item.routerLink" [tabindex]="i" href="javascript:void(0)" style="height: 0;">
        <div class="accordion-header">
            <button class="accordion-button justify-content-between py-1 px-0">
                {{item.name}}
            </button>
        </div>
        </li>
        <li  *ngIf="isExpandable(item);"  (click)="goToSetup()"  [ngClass]="{'setup-sub-manu-two ': item.data.length == 0,'d-none': item.hide}" class="accordion-collapse collapse ps-0 {{item.value}}-2" id="{{dataId}}" attr.aria-labelledby="setup-header{{nodeIndex}}" tabindex="i" href="javascript:void(0)" style="height: 0;">
            <div *ngIf="isExpandable(item);" class="accordion-header" id="setup-header{{i}}" [hidden]="item.hide" [ngClass]="{'d-none': item.hide}">
                <button (click)="changeData(item); toggle(a)" class="accordion-button collapsed justify-content-between py-1 px-0" data-bs-toggle="collapse" attr.data-bs-target="#{{item.value}}" aria-expanded="false"  [attr.aria-controls]="item.value" [attr.href]="'#collapseBox' + i">
                    {{item.name}}
                    <i class="sub-menu-icon pi pi-angle-right {{dataId}}-1" #a></i>
                </button>
            </div>
            <app-setup-menu-item *ngIf="isExpandable(item);" [data]="item.data"  [dataId]="item.value" [nodeIndex]="i" [id]="'collapseBox' + i"></app-setup-menu-item>
    </li>
</div>
</ul>