import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";

import * as moment from "moment-timezone";
import { PartOrderService } from "src/app/_services/part-order.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { AppEvents } from "src/app/_models/global.data.model";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-add-edit-part-order",
  templateUrl: "./add-edit-part-order.component.html",
  styleUrls: ["./add-edit-part-order.component.scss"],
})
export class AddEditPartOrderComponent implements OnInit, OnDestroy {
  public defaultDate = new Date();

  partOrder: any = {
    id: "",
    jobOrder: { id: "" },
    jobOrderNumber: "",
    partOrderNumber: "",
    orderDate: new Date(),
    orderStatus: "",
  };
  mode: any;
  data: any;
  dateConfig: any;
  partOrderForm!: UntypedFormGroup;

  public allStatus = [
    {
      value: "",
      label: "select_status",
    },
    {
      value: "Issued",
      label: "issued",
    },
    {
      value: "Waiting for Parts",
      label: "waiting_for_parts",
    },
    {
      value: "Back Ordered",
      label: "back_ordered",
    },
    {
      value: "Ordered",
      label: "Ordered",
    },
    {
      value: "Shipped",
      label: "shipped",
    },
    {
      value: "Received",
      label: "received",
    },
    {
      value: "Transferred to CE",
      label: "transferred_to_ce",
    },
  ];

  constructor(
    private partOrderService: PartOrderService,
    private spinner: NgxSpinnerService,
    private auth: AuthenticationService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private breadCrumb: BreadcrumbService,
    private location: Location
  ) {
    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.breadCrumb.setItems([]);
  }

  /******************************************** pre start ******************************************/
  private preInit() {
    this.util.getData().subscribe((data: any) => {
      if (data) {
        if (
          data &&
          data.action &&
          data.action === AppEvents.PASS_JO_DATA_TO_PART_ORDER_CREATE
        ) {
          this.mode = data.data.mode;

          if (this.mode === "add") {
            this.data = data.data.jobOrder;
          } else {
            this.data = data.data.partOrder;
            console.log(this.data);
          }

          this.breadCrumb.setItems([
            { label: "Part Order" },
            {
              label: this.mode == "add" ? "Add Part Order" : "Edit Part Order",
            },
          ]);
        }
      }
    });

    this.partOrderForm = new UntypedFormGroup({
      partOrderNo: new UntypedFormControl(null, [Validators.required]),
    });
  }

  /******************************************** start ******************************************/
  private init() {
    this.dateConfig = {
      dateInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
      rangeInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    if (this.mode == "add") {
      this.partOrder.orderStatus = "Waiting for Parts";
      this.partOrder.jobOrder.id = this.data.jobOrder.id;
      this.partOrder.jobOrderNumber = this.data.jobOrder.jobOrderNumber;
    }

    if (this.mode == "edit") {
      this.partOrderService
        .getViewPartOrder(this.data.id)
        .subscribe((res: any) => {
          this.partOrder = res;
          this.partOrder.orderDate = moment(this.partOrder.orderDate).toDate();
        });
    }
  }

  /******************************************** submit ******************************************/
  public onSubmit() {
    if (!this.partOrderForm.valid) {
      for (var i in this.partOrderForm.controls) {
        this.partOrderForm.controls[i].markAsTouched();
      }
      return false;
    }

    if (this.mode == "add") {
      this.partOrderService
        .createPartOrder(this.partOrder)
        .subscribe((res: any) => {
          if (res.status === 200 || res.status === 201) {
            this.errorUtil.setErrorMessage(
              200,
              null,
              "Part Order saved successfully",
              "success",
              2000
            );

            this.router.navigate(["/dashboard/partOrder/view/" + res.body.id], {
              queryParams: { id: res.body.id },
            });
          } else {
            this.errorUtil.setErrorMessage(
              200,
              null,
              res.error.title,
              "error",
              2000
            );
          }
        });
    } else if (this.mode === "edit") {
      let formData;
      formData = JSON.parse(JSON.stringify(this.partOrder));

      let orderDate = moment(
        formData.orderDate,
        localStorage.getItem("date_format")!.toUpperCase()
      );

      formData.orderDate = moment.tz(orderDate, this.auth.getUserTimezone());

      this.partOrderService.editPartOrder(formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          this.errorUtil.setErrorMessage(
            200,
            null,
            "Part order updated successfully",
            "success",
            1000
          );

          this.router.navigate(["/dashboard/partOrder/view/" + res.body.id], {
            queryParams: { id: res.body.id },
          });
        } else {
          this.errorUtil.setErrorMessage(
            200,
            null,
            res.error.title,
            "error",
            2000
          );
        }
        this.spinner.hide();
      });
    }
  }

  /******************************************** cancel ******************************************/
  public cancel() {
    this.location.back();
  }
}
