import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { PERSISTANT_ITEM, ServerRequest } from 'src/app/_models/global.data.model';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { OvertimeService } from 'src/app/_services/overtime.service';
import { SiteService } from 'src/app/_services/site.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { PermissionService } from 'src/app/auth/permission.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { SelectAllComponent } from 'src/app/renderer/select-all.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';

@Component({
  selector: 'app-all-overtime',
  templateUrl: './all-overtime.component.html'
})
export class AllOvertimeComponent extends SubscriptionUtil implements OnInit {
  filterView = false;

  @ViewChild('dateFilter', { static: false })
  private dateFilter: any;
  @Input() pendingStatus: any = null
  @Input() machineSpecialists : any =null
  @Input() display: any = null;
  @Input() tabName: any;
  overlayNoRowsTemplate!: string;
  frameworkComponents: any;
  update: any;
  remove: any;
  create!: boolean;
  refresher: Subscription | undefined
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  gridParams: any;
  parameters: any;
  gridOptions!: GridOptions;
  noOvertimeDataIcon!: boolean;
  searchValue: any = "";
  currentRecordNumber: any;
  totalRecord: any;
  overtimeData: any;
  overtimeType: any;
  approvalStatus: any;
  statuses: any[] = [];
  allSite: any;
  filterActive: boolean = false;
  filterClick: boolean = false;
  myGroup!: any;
  hideOnDateTimeSelect: boolean = true;
  dialogStatusData = {
    module: "",
    recordNumber: "",
    actionStatus: "",
    actionDescription: false,
    multipleDataAccept: "",
    headerTitle: "",
    multipleDataAcceptTitle:""
  };

  filter:any = {
    status: null,
    userId: null,
    siteId: null,
    fromDate: null,
    toDate: null
  };
  selectedId: any;
  selectAllChecked: any = false;
  showStatusDialog: boolean = false;

  noOvertime = false;
  displayIconOvertime = false;
  dateRange: any;
  allSites: any;
  site: any;
  status: any;

  public showingTable = {
    sites: false,
    machineSpecialist: false,
  };
  selectedSite: any;
  selectMachineSpecialist: any;
  allMachineSpecialist: any;
  searchValueData: boolean = false;
  selectedIds: any = [];
  selectedRows: any = [];
  attributeIdName: any;
  translate: any;
  constructor(private router: Router,
    private overtimeService: OvertimeService,
    private deleteRecordService: DeleteRecordService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private sitesService: SiteService,
    private checklistService: ChecklistService,
    private perm: PermissionService,
    private languageTranslateService: LanguageTranslateService) {
    super()
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
    //this.preInit();
    this.push(
      this.overtimeService.getSelectItem().subscribe((data) => {
        if (data) {
          this.selectAllChecked=data;
        }
      })
    );
  }

  ngOnInit(): void {
    this.create = this.perm.capable("overtime", "create");

    console.log(this.pendingStatus)

    this.util.resetTabView();
    this.tabName == "pending" ? this.attributeIdName = "OvertimePend": null;
    this.tabName == "todayPending" ? this.attributeIdName = "OvertimeTodPend": null;
    this.tabName == "all" ? this.attributeIdName = "Overtime": null;
    if(this.display){
      var todays = new Date()
      this.dateRange =[todays, todays]
      let startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      let endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
       this.filter.fromDate = this.util.timeFormatter( moment("00:00:00", "h:mm A").format("h:mm A"), moment(this.dateRange[0], "YYYY-MM-DD").format("YYYY-MM-DD")) .toISOString();
       this.filter.toDate = this.util .timeFormatter( moment("23:59:00", "h:mm A").format("h:mm A"),moment(this.dateRange[1], "YYYY-MM-DD").format("YYYY-MM-DD") ) .toISOString();
   
    }
    if (this.pendingStatus) {
      this.filter.status = this.pendingStatus
    }
    if(this.machineSpecialists){
      let dataObj = {
        fullname: this.machineSpecialists.user.firstName+' '+this.machineSpecialists.user.lastName,
        userId: this.machineSpecialists.user.id,
        siteId:  this.machineSpecialists.defaultSite.id,
        SiteName:  this.machineSpecialists.defaultSite.siteName,
        userGroup:  this.machineSpecialists.defaultSite.userGroup.name
      };
  
     
  
      this.util.setPersistantItem(PERSISTANT_ITEM.MS_VISIT, dataObj);
      
      this.selectMachineSpecialist = this.machineSpecialists.user.firstName
      this.filter.userId = this.machineSpecialists.user.id
    }
  

    this.init();
    this.preInit();
  }
  ngOnDestroy(): void {
    this.onDestroy();

  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.push(
      this.refresher = this.overtimeService.refreshList$.subscribe((overtime) => {
        this.onGridReady(this.gridParams);
      })
    );

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      selectAllComponent: SelectAllComponent
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      paginationPageSize: 25,
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.columnDefs = [
      {
        hide: this.pendingStatus == null ? true : false,
        width: 70, sortble: false,
        headerComponent: 'selectAllComponent',
        headerComponentParams: {
          checkboxSelection: true,
        },
        checkboxSelection: true,

        cellRenderer: (params: any) => {
          //////////////debugger
          if (params.data) {
            console.log("&&&&&&&&&&", params.data)
            if (this.selectAllChecked) {
              params.node.setSelected(true);
            }
            else if (!this.selectAllChecked) {
              //   console.log(params.node,"************************************************************")
              params.node.setSelected(false);

            }
            else {
              params.node.setSelected(false);
            }
          }
        }
      },
      {
        headerName: "Overtime No",
        field: "runningNo",
        width: 250,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["table_default_color"];
        },
      },
      {
        headerName: "Machine Specialist",
        field: "user.fullname",
        width: 250,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["table_default_color"];
        },
      },
      {
        headerName: "Date Time",
        field: "startDateTime",
        width: 250,
        headerTooltip: "#",
        cellRenderer: (params: any) => {
          if (params.data) {
            if (
              params.data.startDateTime !== null &&
              params.data.startDateTime !== undefined &&
              params.data.startDateTime !== "") {
              return (
                moment(params.data.startDateTime).format(
                  localStorage.getItem("date_format")?.toUpperCase()
                ) +
                ", " +
                moment(params.data.startDateTime).format("hh:mm A")
              );
            } else {
              return moment(params.data.startDateTime).format(
                localStorage.getItem("date_format")?.toUpperCase()
              );
            }
          }
        }
      },
      {
        headerName: "OT Hours",
        field: "otMinutes",
        width: 250,
        headerTooltip: "#",
        // cellRenderer: function (params: any) {
        //   return
        // }
      },

      {
        headerName: "Reject Reason",
        field: "rejectReason",
        width: 220,
        headerTooltip: "#",
      },
      {
        headerName: "Remarks",
        field: "remark",
        width: 220,
        headerTooltip: "#",
      },

      {
        headerName: "Status",
        field: "status.name",
        width: 220,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.status) {
            return ' <span class="p-mr-2 p-tag p-component" style="background-color:' + params.data.status.colorCode + '50 !important;"> <span class="p-tag-value text-dark"> ' + params.data.status.label + '</span></span>'
          }
        }
      },
      {
        headerName: "Action",
        sortable: false,
        filter: false,
        width: 200,
        headerTooltip: "#",
        headerClass: "marging-auto hide-action-border",
        cellStyle: { "text-align": "right" },
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.editOverTime.bind(this),
          actionMS: "ActionMS",
          permissions: {
            update: this.perm.capable("overtime", "update"),
            remove: this.perm.capable("overtime", "remove"),
          },
          hidden: {
            update: !this.perm.capable("overtime", "update"),
            remove: !this.perm.capable("overtime", "remove"),
          },
        },
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };

    //get overtime Status
    this.push(
      this.overtimeService.getAllOvertimeStatus()
        .subscribe((res: any) => {
          console.log(res)
          this.statuses = res.body
        })
    );
    this.getSite();


    this.myGroup = new UntypedFormGroup({
      dateRange: new UntypedFormControl(""),

    });
    this.overlayNoRowsTemplate =
      "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";
  }

  /******************************************** Init ******************************************/
  private init() {
    //this.getTeams();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }



  public toggleFilterView() {
    this.filterView = !this.filterView;
  }


  createView() {
    this.router.navigate(['dashboard/overtime/create'])
  }

  /********************************** Click Cell Event *****************************/
  private editOverTime(e: any) {
    if (e.action === "edit") {
      this.router.navigate(["dashboard/overtime/update"], {
        queryParams: { id: e.rowData.id },
      });
    } else if (e.action === "delete") {
      e.rowData.deleteType = "Overtime";
      e.rowData.message =
        "Are you sure you want to delete " + e.rowData.runningNo + "?";

      this.deleteRecordService.getItems(e.rowData);
    } else if (e.action === "acceptMS") {
      this.selectedId = e.rowData.id
      this.showStatusDialog = true;
      this.dialogStatusData.module = "Overtime"
      this.dialogStatusData.recordNumber = e.rowData.runningNo
      this.dialogStatusData.actionStatus = "APPROVED"
      this.dialogStatusData.actionDescription = false
      this.dialogStatusData.multipleDataAccept = "SINGLE",
        this.dialogStatusData.headerTitle = "Approve Overtime"
    }
  }

  /********************************** View Singal Record **************************/
  onGridReady(params: any) {
    this.gridParams = params;
    this.gridApi = params.api;
    //this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    // this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }
  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    this.gridApi.hideOverlay();
    this.spinner.show();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);

    console.log(this.filter);
    this.push(
      this.overtimeService
        .getAllOvertime(this.searchValue, serverRequest, this.filter)
        .subscribe((data: any) => {
          this.spinner.hide();
          if (data.status === 200 || data.status === 201) {
            this.overtimeData = data.body.data;
            if (this.overtimeData) {            
              this.overtimeData.forEach((element: any) => {
                 element.hideActionButton = 'hideAction';//for hide delete button
               
                if(element.user){
                  element.user.fullname = element.user.firstName + " " + element.user.lastName
                }
              });    
            }
            console.log(this.overtimeData);
            this.totalRecord = data.body.total;
            this.setGridData(
              agGridGetRowsParams,
              this.overtimeData,
              data.body.total,
              data.body.status
            );
          } else {
            this.errorUtil.setErrorMessage(data.status, null);
          }
        
        })
    );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    //if (resultItems) {

    if (status === "NO_DATA") {
      this.noOvertime = false;
      this.noOvertimeDataIcon = true;
      this.gridParams.api.showNoRowsOverlay();
    } else {
      this.noOvertime = true;
      this.noOvertimeDataIcon = false;
    }
    if (resultItems.length === 0) {
      this.gridParams.api.showNoRowsOverlay();
    } else {
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "runningNo", sort: "desc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,

    };
  }

  /********************************** View Singal Record **************************/
  viewVisit(e: any) {
    console.log(e.colDef)
    if (e.colDef.field === "runningNo") {
      this.router.navigate(['dashboard/overtime/view/' + e.data.id], { queryParams: { id: e.data.id } });
    }
    if (e.colDef.field === "user.fullname") {
      this.router.navigate( ['dashboard/setup/allUser/view'], { queryParams: { id: e.data.user.id, edit:false } });
    }
    //this.selectCustomer(e.data)
  }

  /******************************************** Refresh ******************************************/
  public refresh() {
    this.spinner.show();
    this.searchValue = "";
    this.onGridReady(this.gridParams);
  }

  /******************************************** Search ******************************************/
  public onSearch() {
    if (this.searchValue && this.searchValue.length > 0) {
      this.searchValueData = true
      this.onGridReady(this.gridParams);
    }
  }

  /******************************************** call table after filter ******************************************/
  public filterResults($event: any) {
    console.log(this.approvalStatus);
    this.onGridReady(this.gridParams);
  }

  /******************************************** Get All Sites By Query ******************************************/
  public getSite() {
    this.push(
      this.sitesService.getSitePicklist("").subscribe((sites: any) => {
        this.allSites = sites;
      })
    );
  }

  /******************************************** Clear Value Dynamic Picklist ******************************************/
  public clearValue() {
    this.myGroup.get("dateRange").setValue(null);
    this.filter = {
      visitType: null,
      toDate: this.display ? this.util.timeFormatter(moment("23:59:00", "h:mm A").format("h:mm A"), moment(new Date() , "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString(): null,
      fromDate: this.display ? this.util.timeFormatter( moment("00:00:00", "h:mm A").format("h:mm A"), moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")) .toISOString(): null,
      
      status: this.pendingStatus ?? null,
      siteId: null,
      userId: this.machineSpecialists ? this.machineSpecialists.user.id:null
    };
    this.selectedSite = null
    this.selectMachineSpecialist = null;
    this.refresh();
  }


  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string) {
    if (what === "sites") {
      this.showingTable.sites = true;
    }

    if (what === "machineSpecialist") {
      this.showingTable.machineSpecialist = true;
    }
  }


  /******************************************** Click yes on Dialog status confirmation method event ******************************************/
    onClickDialogYes(event: any) {
    this.spinner.show();
    
    if (event) {
      if (event.actionStatus == "APPROVED") {
        const status = "APPROVED"
        let data = { id:  this.selectedIds.length > 0 ? this.selectedIds : [this.selectedId],
          status: status}
        this.overtimeService.updateOvertimeStatus(data).subscribe((res: any) => {
          if (res.status == 200) {
            this.errorUtil.setErrorMessage(res.status, "Overtime Approval Status updated successfully", null, "success", 2000);
            if (this.selectedId) {
              this.router.navigate(['dashboard/overtime/view/' + this.selectedId], { queryParams: { id: this.selectedId } });
            }
            if (this.selectedIds.length > 0) {
              this.selectedRows = [];
              this.refresh();
            }
          }
        }, err => {
          this.errorUtil.setErrorMessage(err.status, null, err.error.title, "error", 3000);
        });
      }
    }
    this.showStatusDialog = false;
  }

  /******************************************** Show dialog varible make false after Dialog close ******************************************/
  visibleOut(e: any) {
    this.showStatusDialog = e;
  }


  /******************************************** Close Table Dynamic Picklist ******************************************/
  public closeTable(what: string) {
    if (what === "sites") {
      this.showingTable.sites = false;
    }

    if (what === "machineSpecialist") {
      this.showingTable.machineSpecialist = false;
    }
  }


  /******************************************** On Project Select Value ******************************************/
  public onMachineSpecialistSelect(event: any) {
    console.log(event)
    this.selectMachineSpecialist = { name: event.user.fullname };
    this.filter.userId = event.user.id;
    this.closeTable("machineSpecialist");
    this.refresh()
  }

  /******************************************** Get All Project By Query ******************************************/
  public getMachineSpecialist(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      var serverRequest: any = {
        startRow: 0,
        pageSize: 25,
        filterModel: null,
        sortModel: [{ colId: "id", sort: "asc" }]
      }
      this.push(this.checklistService.getAllMachinesSpecialist(query, serverRequest, null, null).subscribe((projects: any) => {
        this.allMachineSpecialist = projects;
      })
      );
    }
  }
  /******************************************** clear customer ******************************************/
  public clearValueFrom(what: string) {

    if (what === "site") {
      this.selectedSite = null;
      this.filter.siteId = null;
      this.refresh();
    }

    if (what === "machineSpecialist") {
      this.selectMachineSpecialist = null;
      this.filter.userId = null;
      this.refresh();
    }
  }


  public onSearchCheck() {
    if (this.searchValue.length == 0) {
      this.searchValueData = false
      this.searchValue = ''
      this.refresh()
    }
  }
  public onClear() {
    this.searchValueData = false
    this.searchValue = ''
    this.refresh()

  }

  public onChangeDate() {

    if (this.dateRange[0] != null && this.dateRange[1] !== null) {
      let startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      let endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      this.filter.fromDate = this.util.timeFormatter(moment("00:00:00", "h:mm A").format("h:mm A"), moment(this.dateRange[0], "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();
      this.filter.toDate = this.util.timeFormatter(moment("23:59:00", "h:mm A").format("h:mm A"), moment(this.dateRange[1], "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();

      this.dateFilter.hideOverlay()
      this.refresh();
    }

  }

  onSelectionChanged(event: any): void {
    const selectedRows: string[] = event.api.getSelectedRows().map((t: any) => t);
    console.log('selectedRows');
    console.log(selectedRows);
    ////debugger
    this.selectedRows = [];
    this.selectedRows = selectedRows;
    if (this.selectedRows.length > 0) {
      $('.ms-action-button').css('visibility', 'hidden');
    } else {
      $('.ms-action-button').css('visibility', 'visible');
    }
    //$('#checkboxCustella1').addClass('c-checkbox-icon');

  }


  /********************************** Accept multip-le statuses **************************/
  onAcceptMultipleStatus() {

    console.log("", this.selectedRows);
    this.selectedIds = [];
    this.selectedRows.forEach((row: any) => {
      this.selectedIds.push(row.id)
    })

    console.log(this.selectedIds.length)
    if (this.selectedIds.length > 0) {
      this.showStatusDialog = true;
      this.dialogStatusData.actionStatus = "APPROVED"
      this.dialogStatusData.module = "Overtime"
      this.dialogStatusData.multipleDataAccept = "MULTI";
      this.dialogStatusData.multipleDataAcceptTitle = this.selectedIds.length + " Overtime records will now be approved. Do you wish to continue ? "
      this.dialogStatusData.headerTitle = "Approve Overtime"
      this.showStatusDialog = true;

    }
    else if (this.selectedIds.length < 1) {
      window.alert("select atleast one record")
    }
  }

  /******************************************** Right Click Context Menu ******************************************/
public getContextMenuItems = (params: any) => {
  params.node.data.field = params.column.colId;
  let url = "";
  let data = "";

  let results = [];

  if (params.column.colId == "runningNo" || params.column.colId === "site.siteName" || params.column.colId === "user.fullname") {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  return results;
};

/******************************************** Redirect To ******************************************/
private redirectToNewTab(data: any) {
  this.util.resetTabView();
  console.log(data)
  let url;
  if (data.field === "runningNo") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ['dashboard/overtime/view/'+ data.id], { queryParams: { id: data.id}}
      )
    );
  }else if (data.field === "site.siteName") {
    this.util.resetTabView();
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ['dashboard/sites/view/'+ data.site.id], { queryParams: { id: data.site.id}}
      )
    );
  }
  else if (data.field === "user.fullname") {
    this.util.resetTabView();
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ['dashboard/setup/allUser/view'], { queryParams: { id: data.user.id, edit:false } }
      )
    );

  }

  if (url) {
    url = url.replace("%23", "#");
    this.util.openItemToNewTab(url);
  }
}
}
