import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UsetifulService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadScript(userId: any, customer: any, inventory: any, sla: any, Segment: any): void {
    const scriptId = 'usetifulScript';

    if (document.getElementById(scriptId)) {
      console.log('Usetiful script already loaded.');
      return;
    }

    const head = this.renderer.selectRootElement('head', true);

    // Adding usetifulTags script
    const usetifulTagsScript = this.renderer.createElement('script');
    usetifulTagsScript.type = 'text/javascript';
    usetifulTagsScript.text = `window.usetifulTags = { userId : "${userId}", customer: "${customer}", inventory: "${inventory}", sla: "${sla}" , segment: "${Segment}"};`;
    this.renderer.appendChild(head, usetifulTagsScript);
    console.log('usetifulTags script added.');

    // Adding usetiful.js script
    const script = this.renderer.createElement('script');
    script.async = true;
    script.src = 'https://www.usetiful.com/dist/usetiful.js';
    script.id = scriptId;
    script.dataset.token = '40001aec32f0a136405cb4142bd2aab1';

    script.onload = () => {
      console.log('Usetiful script loaded successfully.');
    };

    script.onerror = (error: any) => {
      console.error('Error loading Usetiful script:', error);
    };

    this.renderer.appendChild(head, script);
   
  }

  removeScript(): void {
    const scriptId = 'usetifulScript';
    const script = document.getElementById(scriptId);
    if (script) {
      script.parentNode?.removeChild(script);
      console.log('Usetiful script removed.');
    }

    // Remove usetifulTags script
    const usetifulTagsScript = Array.from(document.getElementsByTagName('script')).find(script => script.text.includes('window.usetifulTags'));
    if (usetifulTagsScript) {
      usetifulTagsScript.parentNode?.removeChild(usetifulTagsScript);
      console.log('usetifulTags script removed.');
    }

    // Clear usetifulTags object
    (window as any).usetifulTags = undefined;
    console.log('usetifulTags object cleared.');
    // Remove Usetiful elements and clean up instances
    this.cleanupUsetifulInstances();
  }

  private cleanupUsetifulInstances(): void {
    // Assuming Usetiful creates elements with a specific class or ID
    // Replace '.usetiful-element' with the actual selector used by Usetiful
    const usetifulElements = document.querySelectorAll('.usetiful-element');
    usetifulElements.forEach(element => element.parentNode?.removeChild(element));
    console.log('Usetiful elements removed.');

    // Destroy any existing Usetiful instances if such a method exists
    if ((window as any).Usetiful) {
      (window as any).Usetiful.destroyAll();
      console.log('Usetiful instances destroyed.');
    }
  }

  clearUsetifulProgressForUser(targetUserId: string): void {
    this.ensureUsetifulInitialized(() => {
      try {
        const USETIFUL = (window as any).USETIFUL;
        if (USETIFUL && USETIFUL.user) {
          USETIFUL.user.setId('null'); // remove userId
          USETIFUL.user.remove(targetUserId); 
          console.log('User ID set to null');
          USETIFUL.user.removeTag('segment'); // remove userId

          USETIFUL.user.clearProgress(); // remove progress
          console.log('User progress cleared');
          USETIFUL.reinitialize(); // reinitialize Usetiful
          console.log('Usetiful reinitialized');
        } else {
          console.log('Usetiful is not initialized.');
        }
      } catch (error) {
        console.error('Error resetting Usetiful user:', error);
      }
    });
  }

  private ensureUsetifulInitialized(callback: () => void): void {
    const checkInterval = setInterval(() => {
      if ((window as any).USETIFUL && (window as any).USETIFUL.user) {
        clearInterval(checkInterval);
        callback();
      }
    }, 100);
  }

  addResetScript(): void {
    const scriptId = 'resetUsetifulScript';

    if (document.getElementById(scriptId)) {
      console.log('Reset Usetiful script already added.');
      return;
    }

    const head = this.renderer.selectRootElement('head', true);

    // Adding reset script
    const resetScript = this.renderer.createElement('script');
    resetScript.type = 'text/javascript';
    resetScript.id = scriptId;
    resetScript.text = `
   
        window.USETIFUL.user.setId(null); // remove userId
        window.USETIFUL.user.clearProgress(); // remove progress
        window.USETIFUL.reinitialize();
    
    `;
    this.renderer.appendChild(head, resetScript);
    console.log('Reset Usetiful script added.');
  }
}
