import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from "@angular/http";
import { param } from 'jquery';
import { throwError, of as observableOf, Subject, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PmvService {

  public refreshList$: EventEmitter<any>;
  public refresQuestionList$: EventEmitter<any>;
  public refreshRedirectLink$: EventEmitter<any>;

  private ENDPOINT_PMVS = "ms/v1/pmvs";
  private ENDPOINT_CHECKLIST_QUESTION="/v1/tmpl-checklist-questions";

  private ENDPOINT_CHECKLIST_PMV="/ms/v1/checklist-questions";

  private selectSubject = new Subject();
  
  constructor(private http: HttpClient,
    private httpOnly: Http,
    private auth: AuthenticationService) {
      this.refreshList$ = new EventEmitter();
      this.refresQuestionList$ = new EventEmitter();
      this.refreshRedirectLink$ = new EventEmitter();
  }
  public select(val: any) {
    this.selectSubject.next(val);
  }
  public getSelectItem() {
    return this.selectSubject.asObservable();
  }
  
  public getAllPmvs(search: any, parameters: any,filter: any){
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (filter !== "" && filter.serviceZoneId != null) {
      params = params.set("serviceZoneId", filter.serviceZoneId);
    }
  
    if (filter !== "" && filter.assetTypeId != null) {
      params = params.set("assetsTypeId", filter.assetTypeId);
    }
  
    if (filter.machineType !== "" && filter.machineType != null) {
      params = params.set("machineType", filter.machineType);
    }
    if (filter.status !== "" && filter.status != null) {
      params = params.set("status", filter.status);
    }
    if (filter.fromDate) {
      params = params.set('fromDate', filter.fromDate);
    }
    if (filter.toDate) {
      params = params.set('toDate', filter.toDate);
    }
    if (parameters)
      //console.log(parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
  
  
    return this.http .get(environment.ms_node_base_url + this.ENDPOINT_PMVS+'/filter', { observe: "response", params: params}).pipe(map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

/******************************************** create task Template ******************************************/
public createPMV(body: any) {
  return this.http.post(environment.base_url + this.ENDPOINT_CHECKLIST_PMV, body, { observe: "response" }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Update CheckList ******************************************/
public editPMV(body: any) {
  return this.http.put(environment.base_url +this.ENDPOINT_CHECKLIST_PMV, body, { observe: "response" }).pipe(
      map((resp: any) => {
       return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}

/******************************************** Get CheckList By ID ******************************************/
public getChecklistByAssetType(equipmentType: any,assetId: any) {
  let params = new HttpParams();
    if (equipmentType !== "" && equipmentType != null) {
      params = params.set("equipmentType", equipmentType);
    }
    if (assetId !== "" && assetId != null) {
      params = params.set("assetId", assetId);
    }
  
    
  return this.http.get(environment.base_url +'/tmpl-checklists/picklist', {params: params}).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}

/******************************************** Get CheckList By ID ******************************************/
public getPMVById(id: any) {
  return this.http.get(environment.ms_node_base_url + this.ENDPOINT_PMVS+'/'+ id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}

/******************************************** Get Question By ID ******************************************/
public getQuestionForm(id: any) {
  return this.http
    .get(environment.base_url + '/ms/v1/checklist-questions?pmvId=' + id, { observe: "response" })
    .pipe(map(
      (resp: any) => {
        return resp.body;
      },
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    )
    );
}

/******************************************** Get CheckList QuestionBy ID ******************************************/
public getChecklistQuestion(id: any) {
  return this.http.get(environment.base_url +'/ms/v1/pmv-tmpl/'+ id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}

/************************************************* delete PMV **********************************************/
public deletePMV(id: any){
  return this.http.delete( environment.ms_node_base_url+this.ENDPOINT_PMVS+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit('Deleted PMV');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
  }
