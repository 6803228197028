
import {of as observableOf,  Observable, throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';
import { Http, RequestOptions, Headers } from "@angular/http";
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class EmailTemplateService {
 
  refresheremails$: EventEmitter<{}>;
  refreshImageList$: EventEmitter<{}>;
  private ENDPOINT_LIST_WORKFLOWS="/workflow-email-templates/paged";
  private ENDPOINT_BY_ID_WORKFLOWS="/workflow-email-templates/";

  
  constructor(private http: HttpClient,
    private httpOnly: Http,
    private auth: AuthenticationService,
  ) {
    this.refresheremails$ = new EventEmitter();
    this.refreshImageList$ = new EventEmitter();
   }
/************************************* Get the list of emailTemplate  *****************************/
  public getListOfEmailTemplates(searchValue: any,parameters: any){
    let params = new HttpParams();
    
    if(searchValue !== '' && searchValue != null){
      params = params.set('search', searchValue);
    }
    console.log(parameters)
    if(parameters)
  
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get( `${environment.base_url}${this.ENDPOINT_LIST_WORKFLOWS}`, { 
      observe: 'response',params : params} ).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }
 
  /************************************* Get emailTemplate Module *********************************/
  public getEmailTemplateModules(){
   
    return this.http.get( environment.base_url+'/workflow-email-templates/module', {observe: "response"}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
  /************************************* Send Email template *********************************/
  public sendEmailTemplate(templateId : any ,  sendTo: any){
    let params = new HttpParams();
    
      params = params.set('templateId', templateId);
      params = params.set('sendTo', sendTo);
    return this.http.get( environment.base_url+'/workflow-email-templates/content-v2', {observe: "response",params : params}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
  /************************************* Get Message Field *********************************/
  public getMessageFields(moduleName: any){
    let params = new HttpParams();
    
      params = params.set('moduleName', moduleName);
     
    return this.http.get( environment.base_url+'/workflow-email-templates/message-fields', {observe: "response",params : params}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
 /************************************* get Button Navigate to types *********************************/
 public getButtonNavigateToTypes(origin: any){
  let params = new HttpParams();
  params = params.set('origin', origin);
  return this.http.get( environment.base_url+'/workflow-email-templates/navigate-to-types', {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/************************************* Get Child Table  Module *********************************/
public getTableModules(parentModuleName: any){
   
    let params = new HttpParams();
    params = params.set('parentModuleName', parentModuleName);
    return this.http.get( environment.base_url+'/workflow-email-templates/table-module', {observe: "response",params : params}).pipe(map( (resp:any) => {
      return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/************************************* Get Child Table Field By Module *********************************/
public getTableFields(moduleName: any){
   
  let params = new HttpParams();
  params = params.set('moduleName', moduleName);
  return this.http.get( environment.base_url+'/workflow-email-templates/table-fields', {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
}
),catchError( error => {
    return observableOf( error );
}),);
}
/************************************* Get Child Table Field By Module *********************************/
public getTableFilterOption(moduleName: any){
   
  let params = new HttpParams();
  params = params.set('tableModuleName', moduleName);
  return this.http.get( environment.base_url+'/workflow-email-templates/table-filter-options', {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
}
),catchError( error => {
    return observableOf( error );
}),);
}
/************************************* Get Child Table Field By Module *********************************/
public getTableFilterOperator(){
   
  let params = new HttpParams();
  return this.http.get( environment.base_url+'/workflow-email-templates/table-filter-operator', {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
}
),catchError( error => {
    return observableOf( error );
}),);
}
 /************************************* get Button Navigate to types *********************************/
 public getTableFilterByData(url : any){
  let params = new HttpParams();
   
  return this.http.get( environment.base_url+"/"+url, {observe: "response",params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

  /************************************* Get emailTemplate By Id  *********************************/
  public getEmailTemplateById(id : any){
   
    return this.http.get( environment.base_url+this.ENDPOINT_BY_ID_WORKFLOWS+id, {observe: "response"}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
/************************************* Create emailTemplate By Id  *********************************/
public createEmailTemplate(body:any){
  return this.http.post( environment.base_url+'/workflow-email-templates', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresheremails$.emit({ data: body });
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/***************************************** Update emailTemplate By Id  *****************************/
public updateEmailTemplate(body:any){
  return this.http.put( environment.base_url+'/workflow-email-templates', body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresheremails$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}
/************************************* Delete emailTemplate By Id  *****************************/
public deleteEmailTemplate(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_BY_ID_WORKFLOWS+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresheremails$.emit('Deleted Email Template');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

 /***************************************** Upload Image Attchment *********************************************************************/
 public uploadImageForEmailTemplate(type: any, formData: any) {
  const headers = new Headers({});
  headers.append("Authorization", "Bearer " + this.auth.getToken());
  let options = new RequestOptions({ headers: headers });
  return this.httpOnly
    .post( environment.base_url + '/attachments/email-image-upload?parentType=' + type, formData,  options )
    .pipe(
      map((resp: any) => {
        this.refreshImageList$.emit({ data: resp._body,type:type });
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}

/************************************* Get the list of emailTemplate  *****************************/
public getListOfEmailNotification(searchValue: any,parameters: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}${this.ENDPOINT_LIST_WORKFLOWS}`, { 
    observe: 'response',params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************* Delete emailTemplate By Id  *****************************/
public deleteEmailTemplateAttchament(url: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_BY_ID_WORKFLOWS+url, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresheremails$.emit('Deleted Email Template');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

}
