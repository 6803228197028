import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=CUSTELLA SDN. BHD.,LicensedApplication=Custella,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-032187,SupportServicesEnd=24_October_2023_[v2]_MTY5ODEwMjAwMDAwMA==f283b7884958c0e13552daf392a7cf97");

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  //console.log(environment.production,"=====>>>")
if (environment.production) {
  window.console.log = function () { }; // disable any console.log debugging statements in production mode
  }