import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionService } from "src/app/auth/permission.service";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { TicketsService } from "src/app/_services/tickets.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { JobOrdersTableComponent } from "../../shared/job-orders-table/job-orders-table.component";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-ticket-job-orders",
  templateUrl: "./ticket-job-orders.component.html",
  styleUrls: ["./ticket-job-orders.component.scss"],
})
export class TicketJobOrdersComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  public noTicketJobOrders: boolean | null = null;
  public searchValue: any;
  public search: any;

  public data: any;

  public accessFrom = "tickets";

  public ticketData: any;

  @Input() ticketId: any;
  @Input() loadedDymanically: boolean = false;
  @ViewChild(JobOrdersTableComponent, { static: false })
  joTableComp!: JobOrdersTableComponent;

  createJobOrder: any;
  searchValueData: boolean = false;
  translate: any;

  constructor(
    private route: ActivatedRoute,
    private ticketService: TicketsService,
    private errorUtil: ErrorUtil,
    private router: Router,
    private util: UtilServiceService,
    private perm: PermissionService,
    private languageTranslateService: LanguageTranslateService
  ) {
    super();
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.createJobOrder = this.perm.capable("job order", "create");

    if (!this.ticketId) {
      this.ticketId = this.route.snapshot.queryParamMap.get("id");
    }

    this.data = {
      ticketId: this.ticketId,
    };

    this.loadDataForJOCreate();
  }

  /******************************************** Get Data ******************************************/
  private loadDataForJOCreate() {
    this.push(
      this.ticketService.getTicketById(this.ticketId).subscribe(
        (data: any) => {
          this.ticketData = data;
        },
        (error) =>
          this.errorUtil.setErrorMessage(
            error.statusCode,
            error,
            error.error.message,
            "warn",
            2000
          )
      )
    );
  }

  /******************************************** New Job Order - For Ticket ******************************************/
  public newJobOrder() {
    const data = {
      ...this.ticketData,
      ticketType: "Tickets",
      ticket: { id: String(this.ticketId) },
    };

    this.util.setPersistantItem(PERSISTANT_ITEM.JOB_ORDER, data);

    this.router.navigate(["/dashboard/jobOrders/create"]);
  }

  /******************************************** On No Data From Table ******************************************/
  public noData($event: any) {
    this.noTicketJobOrders = $event;
  }

  /******************************************** Toggle Filter Table ******************************************/
  public toggleFilter($event: any) {
    this.joTableComp.filterToggle();
  }

  /******************************************** Reload Table ******************************************/
  public onRefresh() {
    this.search = "";
    this.joTableComp.rerender();
  }
  push(obs:any) {
    super.push(obs);
  }

  public onSearch(){
    if(this.searchValue.length > 0) 
    {
      this.search = this.searchValue;
      this.searchValueData = true;
    }
  
  }
  public onSearchCheck()
  {
    if(this.searchValue.length == 0)
    {
      this.searchValueData = false
      this.searchValue = ''
      this.search = this.searchValue
    }
  }

  public onClear(){
    this.searchValueData = false
    this.searchValue = ''
    this.search = this.searchValue   
  }
}
