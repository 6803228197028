import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ProfilesService } from "src/app/_services/admin/profiles.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-clone-access",
  templateUrl: "./clone-access.component.html",
  styleUrls: ["./clone-access.component.scss"],
})
export class CloneAccessComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  formData: any = {};
  profile: any = {};
  submitTouched = false;
  addProfileForm!: UntypedFormGroup;
  data: any;
  translate: any;

  constructor(
    public modalRef: BsModalRef,
    private profileService: ProfilesService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService
  ) {
    super()
    this.preInit();
  }

  ngOnInit() {
    this.getLanguageData();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.addProfileForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
    });
  }

  /******************************************** On Submit ******************************************/
  public onSubmit() {
    this.submitTouched = true;
    if (!this.addProfileForm.valid) {
      for (const i in this.addProfileForm.controls) {
        this.addProfileForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    this.profileService
      .cloneProfile(this.data.id, this.profile.name)
      .subscribe((res: any) => {
        //console.log("Response ", res);
        if (res.status === 200 || res.status === 201) {
          this.modalRef.hide();

          this.errorUtil.setErrorMessage(
            200,
            this.translate?.general.messages.savedSuccessfully,
            null,
            "success",
            1000
          );

          this.router.navigate(
            ["dashboard/setup/profiles/view/" + res.body.profile.id],
            { queryParams: { id: res.body.profile.id } }
          );
        }

        this.submitTouched = false;
      });
  }

  /******************************************** Assign Field Name ******************************************/
  public assignFieldName(val: any) {
    if (val) {
      this.profile.fieldName = this.profile.name.replace(/ /g, "_");
    }
  }

  getLanguageData() {
   // this.spinner.show()
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
        }
      }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }
  
  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
