<div class="modal-header p-dialog-header flex-between">
  <div *ngIf="loadList == 0" class="d-flex align-items-center">
      <span class="text-16 font-medium ms-3" id="title_TaskJourney">{{title}}</span>
  </div>
  <div *ngIf="loadList == 1" class="d-flex align-items-center">
      <i class="pi pi-arrow-left me-2" (click)="goBackTablePage()"></i>
      <span class="text-16 font-medium ms-3" id="title_TaskJourneyReturn">{{title}}</span>
  </div>
  <div *ngIf="loadList == 2" class="d-flex align-items-center">
      <i class="pi pi-arrow-left me-2" (click)="goBackTablePage()"></i>
      <span class="text-16 font-medium ms-3" id="title_TaskViewJourney">{{ translate?.task.sections.sec17.singular }}</span>
  </div>
  <i class="pi pi-times w-24px" id="btn_TaskJourneyClose" [pTooltip]="translate?.general.buttons.close.singular"  (click)="modalRef.hide()"></i>
</div>

<div *ngIf="loadList == 0" class="modal-body pt-0">
  <div class="container text-center px-0" style="padding: 10px;">
      <div class="h-100">
          <!-- Title -->
          <div class="flex-between mt-1 ms-2 mb-2">
              <!-- Filter and New -->
              <div class="custella-dashboard-top-right d-flex ms-auto">
                <button *ngIf="noJourney" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_TaskJourneyRefresh" (click)="getFilter()">
                    <i class="fa-regular fa-arrows-rotate"></i>
                  </button>
                  <div class="p-inputgroup search ms-2" *ngIf="noJourney" >
                    <span class="p-input-icon-right ">
                      <input type="text" class="w-100" pInputText autocomplete="off" id="input_TaskJourneySearchField" [placeholder]="translate?.general.buttons.search.singular" (keydown.enter)="quickSearch()" [(ngModel)]="searchValue" (keyup)="onSearch()" id="input_TeamSearchField" />
                      <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" id="btn_TaskJourneySearchClear" (click)="onClear()"></i>
                    </span>   
                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" id="btn_TaskJourneySearch" (click)="quickSearch()"></button>
                </div>
                  <p-button [label]="translate?.general.buttons.new.singular" icon="fa-regular fa-plus" (onClick)="openNewForm()" id="btn_TaskJourneyNew" styleClass="ms-2 h-32" *ngIf="create"></p-button>
              </div>
          </div>
          <div *ngIf="noJourneyIcon" class="col-12 mb-5 py-4">
              <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                  <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
                   <p class="text-6 my-1 font-weight-bold">{{translate?.general.messages.noRecordsFound}}</p>
              </div>
          </div>

          <!-- TabMenu -->
          <div [hidden]="!noJourney" class="position-relative fw-normal d-flex display-table">
              <div [hidden]="!noJourney" class="border ag-grid-table-full" style="height: 400px;">
                  <ag-grid-angular
                      #agGrid
                      style="height: 100%;"
                      class="ag-theme-balham"
                      [columnDefs]="columnDefs"
                      [gridOptions]="gridOptions"
                      [defaultColDef]="defaultColDef"
                      [maxConcurrentDatasourceRequests]="-1"
                      [suppressDragLeaveHidesColumns]="true"
                      [paginationPageSize]="25"
                      [pagination]="false"
                      [paginationPageSize]="25"
                      [rowData]="taskJourneys"
                      [frameworkComponents]="frameworkComponents"
                      (gridReady)="onGridReady($event)"
                      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                      (cellClicked)="viewJourney($event)"
                  ></ag-grid-angular>

                  <div class="col-12">
                      <p class="ag-record-row-summary-panel padds text-end" id="label_TaskJourTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div *ngIf="loadList == 1" class="modal-body p-0"><app-add-edit-journey [data]="data" [mode]="mode" [journeyId]="jId" (goBackTablePage)="goBackTablePage()"></app-add-edit-journey></div>
<div *ngIf="loadList == 2" class="modal-body p-0"><app-view-journey [data]="data" [journeyId]="jId"></app-view-journey></div>
