
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { RequestOptions, Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class SterraFormService {
  public refresherSterraForm$: EventEmitter<any>;
  public refreshVanLogList$: EventEmitter<any>;

private ENDPOINT_TASK_TYPE_LIST="/v1/task-list/paged";
private ENDPOINT_TASK_TYPE_BY_ID="/v1/task-list/";



 constructor(private http: HttpClient,
  private auth: AuthenticationService,
  private httpOnly : Http,
  private router: Router){
    this.refresherSterraForm$ = new EventEmitter();
    this.refreshVanLogList$ = new EventEmitter();
  }

/**=========================== Form  =============================================================
 * ========================================================================================================= */


public getDataBySchecma(schemas : any,parentName : any,parentId : any){
  let params = new HttpParams();

  params = params.set('schemas', schemas);
  params = params.set('parentName', parentName);
  params = params.set('parentId', parentId); 
  return this.http.get(  environment.multiTenant_node_url+'/dynamic-form-schema-data/data/check', {observe: "response",params : params}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

public getDataBySchecmaForTNG(schemas : any,parentName : any,parentId : any){
  let params = new HttpParams();

  params = params.set('schemas', schemas);
  params = params.set('parentName', parentName);
  params = params.set('parentId', parentId);
  return this.http.get(  environment.touchandgo_node_url+'/v1/dynamic-form/data/check', {observe: "response",params : params}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return observableOf( error );
  }),);
}


public getFaultUnitList(searchValue : any, parameters : any, parentName : any, parentId : any){
  let params = new HttpParams();
    params = params.set('parentName', parentName);
    params = params.set('parentId', parentId);

  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }

  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.multiTenant_node_url}/v1/dynamic-form/data/`, {observe: 'response',params : params} ).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);

}

public getSingleByParent(parentName : any, parameters : any,  parentId : any){
  let params = new HttpParams();
    params = params.set('parentName', parentName);
    params = params.set('parentId', parentId);
  return this.http.get( `${environment.multiTenant_node_url}/v1/dynamic-form/data/FaultCall/first`, {observe: 'response',params : params} ).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);

}
public vanLogData(searchValue : any,parameters : any,assetId : any){
  let params = new HttpParams();

  params = params.set('parentName', 'assets');
  params = params.set('parentId', assetId);
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.multiTenant_node_url}/v1/dynamic-form/data/VanLog`, {observe: 'response', params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return observableOf( error );
  }),);
}

public initTransfer(faultCallId : any){
  let params = new HttpParams();
    //params = params.set('parentName', parentName);
    params = params.set('faultCallId', faultCallId);




  return this.http.get( `${environment.multiTenant_node_url}/v1/dynamic-form/data/custom/Sterra/bin-transfer-load`, {observe: 'response',params : params} ).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);

}

/////////////////////////////// Create templet of Dynamic form ----------------------------------
public binTransfer(body:any){
  return this.http.post( environment.base_url+'/bin-transfers/Sterra', body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refresherSterraForm$.emit(resp);

  return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
/////////////////////////////// Get List of notification ----------------------------------
public getNotification(){
  return this.http.get( `${environment.base_url}/preventive-maintenance-notification-setups`, {observe: 'response'} ).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);

}
/////////////////////////////// Get List of notification ----------------------------------
public getDataBySchecmaForCoffex(schemas: any,parentName: any,parentId: any){
  let params = new HttpParams();

  params = params.set('schemas', schemas);
  params = params.set('parentName', parentName);
  params = params.set('parentId', parentId);
  return this.http.get(environment.coffex_node_url+'/v1/dynamic-form/data/check', {observe: "response",params : params}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);

}

}
