<div class="custella-modal">
    <!-- Container -->
    <div class="custella-modal-header flex-align-center" >
        <div class="custella-modal-header-text" id="title_SetJobAssignTaskTempl"> {{ translate?.setup.modules.templates.sections.sec13.singular }} </div>
        <i class="fa-regular fa-xmark" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  id="btn_SetJobAssignTaskTemplClose" (click)="modalRef.hide()"></i>
    </div>

    <div class="custella-form-container position-relative d-block w-100 m-0"  style="box-shadow: none;">
        <!-- Container Content -->
        <div class="custella-form-container-title-two-grey fst-italic ps-4">
            <span class="text-12 font-bold" id="title_SetCheckQuestDetsStepOne">{{translate?.general.messages.step1}}&colon;&nbsp;</span>
            <span class="text-12 font-medium" id="title_SetCheckQuestDets">{{translate?.setup.modules.templates.sections.sec14.singular}}</span>
        </div>
        <div class="custella-form-container-content">
            <div class="custella-content-row mb-3">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" id="label_SetJobAssignTaskSite" >{{translate?.site.label.singular}}</span>
                    <p-dropdown class="pe-2" id="picklist_SetJobAssignTaskSiteField" [options]="allSites" [placeholder]="translate?.general.messages.none" optionLabel="siteName" optionValue="id" [(ngModel)]="filter.siteId" [filter]="true"  [showClear]="filter.siteId ? true : false"></p-dropdown>
               

                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" id="label_SetJobAssignTaskProj" >{{translate?.project.label.singular}}</span>
                    <p-dropdown  id="picklist_SetJobAssignTaskProjField" [options]="allProjects" [filter]="true" [placeholder]="translate?.general.messages.none" optionLabel="name" optionValue="id" [(ngModel)]="filter.projectId"  [showClear]="filter.projectId ? true : false"></p-dropdown>
              
                </div>
            </div>
            <div class="custella-content-row mb-3">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" id="label_SetJobAssignTaskTeam" >{{translate?.setup.users.teams.label.singular}}</span>
                    <p-dropdown class="pe-2" id="picklist_SetJobAssignTaskTeamField"  [(ngModel)]="filter.teamId" name="status" [options]="teams" optionLabel="name"  optionValue="id"[placeholder]="translate?.general.messages.none"></p-dropdown>
      

                </div>
                <div class="custella-content-row-input col-6">
                   
                </div>
            </div>
          
        </div>
        <div class="custella-form-container-footer mb-0">
            <div class="flex-align-center justify-content-end">
                <button pButton pRipple class="p-button-secondary" (click)="resetSearch()" id="btn_SetJobAssignTaskReset" [label]="translate?.general.buttons.reset.singular" style="margin-right: 7px;"></button>
                <p-button pRipple (onClick)="quickSearch()" id="btn_SetJobAssignTaskSearch" [label]="translate?.general.buttons.search.singular" class="p-button-text">
                </p-button>
            </div>
        </div>
        <!-- Task Information -->
        <div class="custella-form-container-title-two" id="title_SetJobAssignTaskDets" >{{translate?.setup.modules.templates.sections.sec15.singular}}</div>
        <div class="custella-form-container-title-two-grey fst-italic ps-4" >
            <span class="text-12 font-bold" id="subtitle_SetJobAssignTaskDetsStepOne" >{{translate?.general.messages.step2}}&colon;&nbsp;</span>
            <span class="text-12 font-medium" id="subtitle_SetJobAssignTaskDets" >{{translate?.setup.modules.templates.sections.sec16.singular}}</span>
        </div>
        <div class="custella-form-container-content">
            <div  class="custella-content-row mb-1 pe-0">
                <div class="custella-content-row-input col-12">
                    <span class="p-input-icon-left w-100" [ngClass]="searchValue?.length>0 ? 'p-input-icon-right':''">
                        <i class="pi pi-search me-3" style="width: 10px;"></i>
                        <input type="text" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" id="input_SetJobAssignTaskSearchField"
                            [(ngModel)]="searchValue" class="w-100" name="searchValue" (keyup)="quickSearch()"/>
                       
                        <!-- <i class="pi pi-times-circle" (click)="onSearchClear()"  *ngIf="searchValue?.length>0"></i> -->
                    </span>
                </div>
            </div>
            
            <!-- ag-grid -->
            <div class="custella-form-container-content-table">
                <div class="custella-content-row mt-0 pe-0">
                    <div class="custella-content-row-input col-12">
                        <div style="padding-top: 0px;margin-top: 5px; height: 200px;width: 100%;">
                            <ag-grid-angular #agGrid id="myGrid" style="height: 100%;width:100%"
                                class="ag-theme-balham" 
                                [pagination]=true 
                                [paginationPageSize]="25"
                                [gridOptions]="gridOptions" 
                                [columnDefs]="columnDefs"
                                [defaultColDef]="defaultColDef" 
                                [suppressRowClickSelection]="true"
                                [rowSelection]="rowSelection"
                                 (rowSelected)="onSelectionChanged($event)"
                                (gridReady)="onGridReady($event)"
                                [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                                [maxConcurrentDatasourceRequests]="-1"
                                [allowContextMenuWithControlKey]="false" ></ag-grid-angular>
                               
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="custella-form-container-footer mb-0">
            <div class="flex-align-center justify-content-end">
                <button  (click)="modalRef.hide()" id="btn_SetJobAssignTaskCancel" class="custella-btn-white text-12 ms-2">{{translate?.general.buttons.cancel.singular}}</button>
                <p-button styleClass="ms-2 h-32" id="btn_SetJobAssignTaskSubmit" [disabled]="submitTouched" type="button"  [label]="translate?.general.buttons.save.singular" (onClick)="addTask()"> </p-button>
            </div>
        </div>
    </div>
</div>
