<div class="d-flex button-box-top-right">
  <p-button [label]="'general.buttons.edit.singular'" styleClass="ms-2 h-28"
    (click)="editPartOrder()" id="btn_JobPartOrdEdit"></p-button>
  <button class="btn-view-icon ms-2" [pTooltip]="'general.buttons.more.singular'" tooltipPosition="bottom" id="btn_JobPartOrdDropdown" (click)="menu.toggle($event)">
    <i class="fa-regular fa-ellipsis-vertical"></i>
  </button>
  <p-slideMenu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu [model]="items" [popup]="true"
    class="options2"></p-slideMenu>
</div>

<div class="custella-form-container position-relative d-block rounded-2" style="margin-top: 3.5rem">
  <!-- Container Content -->
  <div class="custella-form-container-title text-14 font-medium rounded-top" id="title_JobPartOrdDets">
    <span>{{ "part_order_details"  }}</span>
  </div>

  <div class="custella-form-container-content-border-bottom text-12 font-medium">
    <div class="custella-content-row">
      <div class="custella-content-row-input col-6">
        <span class="custella-content-row-input-title-two" id="label_JobPartOrdId" >{{
          "job_order_id"
          }}</span>

        <span class="custella-content-row-input-title" id="value_JobPartOrdId">
          {{ partOrder?.jobOrder?.jobOrderNumber }}
        </span>
      </div>

      <div class="custella-content-row-input col-6">
        <span class="custella-content-row-input-title-two" id="label_JobPartOrdNo" >{{
          "part_order_no"
          }}</span>

        <span class="custella-content-row-input-title" id="value_JobPartOrdNo">
          {{ partOrder?.partOrderNumber }}
        </span>
      </div>
    </div>
  </div>

  <div class="custella-form-container-content-border-bottom text-12 font-medium">
    <div class="custella-content-row">
      <div class="custella-content-row-input col-6">
        <span class="custella-content-row-input-title-two" id="label_JobPartOrdDate" >{{
          "order_date"
          }}</span>

        <span class="custella-content-row-input-title" id="value_JobPartOrdDate">
          {{ partOrder?.orderDate | momentDate: dateFormat }}
        </span>
      </div>

      <div class="custella-content-row-input col-6">
        <span class="custella-content-row-input-title-two" id="label_JobPartOrdStatus" >{{
          "order_status"
          }}</span>

        <span class="custella-content-row-input-title" id="value_JobPartOrdStatus">
          {{ partOrder?.orderStatus }}
        </span>
      </div>
    </div>
  </div>

  <div class="custella-form-container-title-two flex-between ">
    <div class="font-medium text-14" id="title_JobPartOrdParts" >{{ "parts"  }}</div>
  </div>

  <div class="custella-content-row">
    <div class="custella-content-row-input col-12 mb-4">
      <app-parts-table [id]="id"></app-parts-table>
    </div>
  </div>

  <app-system-information [createdByUser]="partOrder?.createdBy" [createdDate]="partOrder?.createdDateTime"
    [modifiedByUser]="partOrder?.modifiedBy" [lastModifiedDate]="partOrder?.modifiedDateTime"></app-system-information>
</div>