import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { Address } from 'src/app/shared/ngx-google-places-autocomplete/objects/address';
import { TasksService } from 'src/app/_services/tasks.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DashboardComponent } from '../../dashboard.component';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';


@Component({
  selector: 'app-optimize-distance',
  templateUrl: './optimize-distance.component.html'
})
export class OptimizeDistanceComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  options!: { fields: string[] };
  updateLocationForm: UntypedFormGroup[] = [];
  setGooglePlace!: boolean;
  data: any;
  title: any;
  timezone: any
  dateFormat: any
  allPriorities: any;
  translate: any;

  constructor(public modalRef: BsModalRef,
    private taskService: TasksService,
    private auth: AuthenticationService,
    private util: UtilServiceService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData() }

  ngOnInit() {
    this.allPriorities = this.taskService.getAllTasksPriorities();
    this.dateFormat = localStorage.getItem('date_format');
    this.timezone = this.auth.getUserTimezone()
    this.options = this.util.getAutocompleteOptions();
    // console.log("data ", this.data);
    this.data.forEach((element: any, index: any) => {
      element.oldLocation = element.location;
      // element.location = null;
      var selectedPriority = this.allPriorities.find((prio: any) => prio.value == element.priority);
console.log(selectedPriority)
      element.selectedPriority = selectedPriority
      if(element.locationName == null)
      {
        element.locationView = element.location;
      }
      else{
        element.locationView = element.locationName + ', ' + element.location;
      }
      
      this.updateLocationForm[index] = new UntypedFormGroup({
        'location': new UntypedFormControl(null, [Validators.required]),
      });
    });
  }

  public handleAddressChange(ind: any, address: Address) {
    //console.log("address changed ", address);
      this.data[ind].location = address.formatted_address;
      if(address.types.includes('establishment')){
        this.data[ind].locationName = address.name;
        this.data[ind].locationFull = address.name + ', ' + address.formatted_address;
      } 
      else {
        this.data[ind].locationName = null;
        this.data[ind].locationFull = address.formatted_address;
      }
      this.data[ind].latitude = address.geometry.location.lat();
      this.data[ind].longitude = address.geometry.location.lng();
      this.data[ind].placeId = address.place_id;
      this.data[ind].setGooglePlace = true;
  }

  resetPlaceId(ind: any){
    if(!this.data[ind].setGooglePlace){
      this.data[ind].latitude = null;
      this.data[ind].longitude = null;
      this.data[ind].placeId = null;
      this.data[ind].setGooglePlace = false
    }
  }

  onSubmit(){
    this.updateLocationForm.forEach(form => {
      if(!form.valid){
        for (var i in form.controls) {
          form.controls[i].markAsTouched();
        }
      }
    });

    var check = _.filter(this.updateLocationForm, (o) => {
      return o.status == 'INVALID';
    })
    if(check.length > 0){
      return
    } else {
      var req: any = {};
      var reqArray: any[] = [];
      this.data.forEach((event: any) => {
          var formData = 
          {
            id : event.id,
            startDateTime : moment.tz(event.startDateTime, this.auth.getUserTimezone()),
            endDateTime : moment.tz(event.endDateTime, this.auth.getUserTimezone()),
            placeId : event.placeId,
            location : event.location,
            locationName : event.locationName,
            latitude : event.latitude,
            longitude : event.longitude
          }
          reqArray.push(formData);
      });
      req.tasks = reqArray;
      this.taskService.bulkEditTask(req).subscribe((res:any) => {
        if(res.status == 200 || res.status == 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200, null, this.translate?.general.messages.updatedSuccessfully, "success",3000);
        }
      });
    }

  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.accessories.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


}
