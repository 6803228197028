
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';

@Injectable()
export class NotificationsService {

  refreshList$: EventEmitter<{}>;
  private ENDPOINT_LIST_NOTIFICATIONS="/notifications/get-all-by-user";
  private ENDPOINT_LIST_UNREAD_NOTIFICATIONS="/notifications/unread-by-user";
  private ENDPOINT_LIST_READ_NOTIFICATIONS="/notifications/read-by-user";
  private ENDPOINT_SET_NOTIFICATIONS_STATUS="/notifications/update-status?notificationId=";
  private ENDPOINT_CLER_NOTIFICATIONS="/notifications/clear-all-by-user";


  constructor(private http: HttpClient) {
    this.refreshList$ = new EventEmitter();
   }

  public getNotifications(id : any){
    let params = new HttpParams().set('userId', id);
    var startDate = moment().subtract(14,'d').format('YYYY-MM-DD');
    var endDate = moment(new Date()).format('YYYY-MM-DD');
    params = params.set('startDate', startDate);
    params = params.set('endDate', endDate);
    return this.http.get( environment.base_url+this.ENDPOINT_LIST_NOTIFICATIONS, {params:params}).pipe(map( (resp:any) => {
      // //console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getReadNotifications(id : any){
    let params = new HttpParams().set('userId', id);
    var startDate = moment().subtract(14,'d').format('YYYY-MM-DD');
    var endDate = moment(new Date()).format('YYYY-MM-DD');
    params = params.set('startDate', startDate);
    params = params.set('endDate', endDate);
    return this.http.get( environment.base_url+this.ENDPOINT_LIST_READ_NOTIFICATIONS, {params:params}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  public getUnreadNotifications(id : any){
    let params = new HttpParams().set('userId', id);
    var startDate = moment().subtract(14,'d').format('YYYY-MM-DD');
    var endDate = moment(new Date()).format('YYYY-MM-DD');
    params = params.set('startDate', startDate);
    params = params.set('endDate', endDate);
    return this.http.get( environment.base_url+this.ENDPOINT_LIST_UNREAD_NOTIFICATIONS, {params:params}).pipe(map( (resp:any) => {
      // //console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public setNotificationStatus(id : any){
    return this.http.put( environment.base_url+this.ENDPOINT_SET_NOTIFICATIONS_STATUS+id+'&newStatus=READ', { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(resp);
      // //console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public clearUnreadNotifications(id : any){
    let params = new HttpParams().set('userId', id);
    return this.http.get( environment.base_url+this.ENDPOINT_CLER_NOTIFICATIONS, {params:params}).pipe(map( (resp:any) => {
      this.refreshList$.emit(resp);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }


  public getStandardNotifications(module : any){
    let params = new HttpParams().set('module', module);
    return this.http.get( environment.base_url+'/standard-notifications', {params:params}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }


  public saveStandardNotifications(body: any,status: any ){
    return this.http.put( environment.base_url +'/standard-notifications/bulk?overrideForUsers='+status, body, {observe: "response"}).pipe(map( (resp:any) => {
     
        return resp;
      
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  public getUserStandardNotifications(){
    //let params = new HttpParams().set('module', module);
    return this.http.get( environment.base_url+'/user-standard-notifications/grouped').pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }


  public saveUserStandardNotifications(body: any ){
    return this.http.put( environment.base_url +'/user-standard-notifications/bulk', body, {observe: "response"}).pipe(map( (resp:any) => {
     
        return resp;
      
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

}
