
import {of as observableOf,  Observable, throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class ApiService {

  private ENDPOINT_ACCOUNT="/account";

  constructor(private http: HttpClient,
  private authService: AuthenticationService) { }

  public getProfile(){
    return this.http.get( environment.base_url+this.ENDPOINT_ACCOUNT).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return throwError( error.status );
    }),);
  }

  public get getAuthHeaders(){
    var myHeaders: any = {
      'Authorization': 'Bearer '+this.authService.getToken(),
    };

    return myHeaders;
  }

}
