import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { ComponentSharedModule } from "../shared/component-shared.module";
import { AddEditTaskComponent } from "./add-edit-task/add-edit-task.component";
import { DynamicSkillsService } from "src/app/_services/admin/dynamic-skills.service";
import { SmartAssignComponent } from './smart-assign/smart-assign.component';
import { ListTasksComponent } from './list-tasks/list-tasks.component';
import { TaskFrameComponent } from './layout/task-frame/task-frame.component';
import { TasksRoutingModule } from './tasks-routing.module';
import { TasksComponent } from './tasks.component';
import { CheckAvailabilityComponent } from './check-availability/check-availability.component';
import { TaskCalendarViewComponent } from './task-calendar-view/task-calendar-view.component';
import { TaskOptimizeComponent } from './task-optimize/task-optimize.component';
import { TaskCloneComponent } from './task-clone/task-clone.component';
import { TaskJourneyComponent } from './task-journey/task-journey.component';
import { AddEditJourneyComponent } from './task-journey/add-edit-journey/add-edit-journey.component';
import { ViewJourneyComponent } from './task-journey/view-journey/view-journey.component';
import { AgGridModule } from "ag-grid-angular";
import { RouterModule } from "@angular/router";
import { AcceptDeclineTaskComponent } from './accept-decline-task/accept-decline-task.component';
import { AllMyTaskComponent } from './all-my-task/all-my-task.component';
import { AllStaffTaskComponent } from './all-staff-task/all-staff-task.component';
import { AllTeamTaskComponent } from './all-team-task/all-team-task.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { OptimizeDistanceComponent } from './optimize-distance/optimize-distance.component';
import { AddEditNotesComponent } from './view-tasks/add-edit-notes/add-edit-notes.component';
import { RequestAssistanceComponent } from './request-assistance/request-assistance.component';
import { ViewTaskSchedulesComponent } from './view-task-schedules/view-task-schedules.component';
import { ViewStaffTaskSchedulesComponent } from './view-staff-task-schedules/view-staff-task-schedules.component';
import { StartLocationComponent } from './start-location/start-location.component';
import { TaskMapComponent } from './task-map/task-map.component';
import { OptimizePlannedActualComponent } from './optimize-planned-actual/optimize-planned-actual.component';
import { HazardControlComponent } from './hazard-control/hazard-control.component';
import { ViewCheckinMapComponent } from './view-checkin-map/view-checkin-map.component';
import { OpenNewTabViewTaskComponent } from './open-new-tab-view-task/open-new-tab-view-task.component';
import { ExactAssignComponent } from './exact-assign/exact-assign.component';
@NgModule({
  declarations: [
    AddEditTaskComponent,
    SmartAssignComponent,
    ListTasksComponent,
    TaskFrameComponent,
    TasksComponent,
    CheckAvailabilityComponent,
    TaskCalendarViewComponent,
    TaskOptimizeComponent,
    TaskCloneComponent,
    TaskJourneyComponent,
    AddEditJourneyComponent,
    ViewJourneyComponent,
    AcceptDeclineTaskComponent,
    AllMyTaskComponent, 
    AllStaffTaskComponent, 
    AllTeamTaskComponent, 
    ConfirmationDialogComponent, 
    AddMemberComponent, 
    OptimizeDistanceComponent, 
    AddEditNotesComponent, 
    RequestAssistanceComponent, ViewTaskSchedulesComponent, ViewStaffTaskSchedulesComponent, StartLocationComponent, TaskMapComponent, OptimizePlannedActualComponent, HazardControlComponent, ViewCheckinMapComponent, OpenNewTabViewTaskComponent, ExactAssignComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    AgGridModule,
    TasksRoutingModule
  ],
  providers: [DynamicSkillsService],
  exports: [AddEditTaskComponent,ViewCheckinMapComponent],
})
export class TasksModule { }
