<div>
    <div class="flex-between"> 
     <i class="fa-regular fa-xmark w-24px dialog-close-icon" id="btn_SalesOrderClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="closeProduct()"></i>
    </div>
    <div class="position-relative text-start">
      <span class="title-lookup" id="label_SalesOrderSelectFromList">{{translate?.general.commonComponent.sections.sec14.singular}}</span>
      <div class="d-flex align-items-center mt-2">
        <span class="p-input-icon-left w-100 py-2">
          <i class="pi pi-search"></i>
          <input type="text" pInputText autocomplete="off" id="input_SalesOrderSelectFromListField"  [placeholder]="translate?.general.buttons.search.singular"  [(ngModel)]="searchValue"/>
        </span>
       </div>
      <div class="height-42-stat border">
        <app-so-table  [filterData]="filterData" [translate]="translate" [searchValue]="searchValue" [customerId]="customerId"></app-so-table>
      </div>
    </div>
  
  </div>
