<!-- <div class="modal-content modal-fullscreen p-0"> -->
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <img src="assets\svg\dialog\Confirmation-w.svg" id="img_InfoIcon">
            <span class="text-16 font-medium ms-3" id="title_SetAssetFormat" >{{translate?.asset.label.singular}}&nbsp;{{translate?.setup.modules.configuration.fields.format.singular}}</span>
        </div>
        <i class="fa-regular fa-xmark" id="btn_SetAssetClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="modalRef.hide()"></i>
    </div>
    <div class="modal-body tix height-80">
        <div class="m-0 pb-3 px-2">
            <span class="text-12 d-block font-semiBold" id="label_SetAssetWhatJobFormat" >{{translate?.setup.modules.configuration.messages.whatIs}}&nbsp;{{translate?.asset.label.singular}}&nbsp;{{translate?.setup.modules.configuration.fields.format.singular}}&quest;</span>
            <span class="text-12" id="label_SetAssetAllowsCust" >{{translate?.asset.label.singular}}&nbsp;{{translate?.setup.modules.configuration.fields.format.singular}}&nbsp;{{translate?.setup.modules.configuration.messages.allowsCustomize}}</span>
        </div>
        <div>
            <p-table [value]="tix" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="">
                        <th class="col-2" id="label_SetAssetVar">{{translate?.setup.modules.configuration.sections.sec2.singular}}</th>
                        <th class="col-2 text-left" id="label_SetAssetNec">{{translate?.setup.modules.configuration.sections.sec3.singular}}</th>
                        <th class="col-2 text-left" id="label_SetAssetDesc">{{translate?.setup.modules.configuration.sections.sec4.singular}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-tix>
                    <tr class="text-12 font-medium">
                        <td class="col-2" id="value_SetAssetVar">{{tix.variable}}</td>
                        <td class="col-2 text-left" id="value_SetAssetNec">{{tix.necessity}}</td>
                        <td class="col-2 text-left" id="value_SetAssetDesc">{{tix.desc}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="pt-2">
            <div class="m-1"><span class="text-12 font-medium" id="label_SetAssetFormatEg">{{translate?.setup.modules.configuration.messages.hereExamples}}</span></div>
            <p-table [value]="tix2" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="">
                        <th class="col-2" id="label_SetAssetTixFormat">{{translate?.asset.label.singular}}&nbsp;{{translate?.setup.modules.configuration.fields.format.singular}}</th>
                        <th class="col-2" id="label_SetAssetSeqNo">{{translate?.setup.modules.configuration.sections.sec6.singular}}</th>
                        <th class="col-2" id="label_SetAssetFormatNo">{{translate?.setup.modules.configuration.sections.sec7.singular}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-tix2>
                    <tr class="text-12 font-medium">
                        <td id="value_SetAssetTixFormat">{{tix2.format}}</td>
                        <td class="col-2 text-left">
                            <tr id="value_SetAssetSeqNo1">{{tix2.seq1}}</tr>
                            <tr id="value_SetAssetSeqNo2">{{tix2.seq2}}</tr>    
                        </td>
                        <td class="col-2 text-left">
                            <tr id="value_SetAssetFormatNo1">{{tix2.number1}}</tr>
                            <tr id="value_SetAssetFormatNo2">{{tix2.number2}}</tr>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    
    
    </div>
    <!-- </div> -->