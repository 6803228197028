
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Http } from '@angular/http';

@Injectable()
export class SignUpService {

  private ENDPOINT_REGSITER_ORG="/register"
  private ENDPOINT_FORGOT_PASSWORD="/users/reset-password/init"
  private ENDPOINT_RESET_PASSWORD="/users/reset-password/finish"
  private ENDPOINT_RESEND_EMAIL="/resend-activation-email/"
  private ENDPOINT_RESEND_CUSTOMER_USER_EMAIL="/resend-customer-user-activation-email/"

  constructor(private http: Http, private http2 : HttpClient) { }

  public registerOrg(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_REGSITER_ORG, body).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);
  }

  public resetPasswordForCustomer(body:any){
    return this.http.post( environment.base_url+'/customer-users/reset-password/init', body).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);
  }

  public forgotPassword(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_FORGOT_PASSWORD, body).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);
  }

  public resetPassword(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_RESET_PASSWORD, body).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);
  }

  public resendEmail(id:any){
    return this.http2.put( environment.base_url+this.ENDPOINT_RESEND_EMAIL + id, {}, {observe : 'response'}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);
  }

  public resendCustomerUserEmail(id:any){
    return this.http2.put( environment.base_url+this.ENDPOINT_RESEND_CUSTOMER_USER_EMAIL + id, {}, {observe : 'response'}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    //console.log(error);
        return observableOf( error );
    }),);
  }

  

}
