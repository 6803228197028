<ag-grid-angular
  #agGrid
  style="height: 100%; width: 100%"
  class="ag-theme-balham"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
  [defaultColDef]="defaultColDef"
  [maxConcurrentDatasourceRequests]="-1"
  [suppressDragLeaveHidesColumns]="true"
  (gridReady)="onGridReady($event)"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  (cellClicked)="viewTicket($event)"
  [getContextMenuItems]="getContextMenuItems"
></ag-grid-angular>
<div class="col-12">
  <p class="ag-record-row-summary-panel padds text-end">{{ totalRecord }}&nbsp;{{translate?.general.messages.records}}</p>
</div>
