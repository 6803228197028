import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-project-format",
  templateUrl: "./project-format.component.html",
  styleUrls: ["./project-format.component.scss"],
})
export class ProjectFormatComponent implements OnInit {
  tix!: any[];
  tix2!: any[];
  translate: any;

  constructor(public modalRef: BsModalRef,
    private languageTranslateService: LanguageTranslateService
  ) {

  }

  ngOnInit(): void {
    this.getLanguageData()
   
  }

  getLanguageData() {
    this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
          console.log("translate",this.translate) 

          this.tix = [
            {variable:"{0}", necessity:this.translate?.setup.modules.configuration.fields.required.singular, desc: this.translate?.setup.modules.configuration.messages.zeroVariable},
            {variable:"{YY} / {YYYY}", necessity:this.translate?.setup.modules.configuration.fields.optional.singular, desc: this.translate?.setup.modules.configuration.messages.yearVariable},
            {variable:"{MM}", necessity:this.translate?.setup.modules.configuration.fields.optional.singular, desc:this.translate?.setup.modules.configuration.messages.monthVariable},
            {variable:"{DD}", necessity:this.translate?.setup.modules.configuration.fields.optional.singular, desc:this.translate?.setup.modules.configuration.messages.dayVariable}
          ],
            this.tix2 = [
              {
                format: "{0}",
                seq1: "10",
                seq2: "1000",
                number1: "10",
                number2: "1000",
              },
              {
                format: "{000000}",
                seq1: "10",
                seq2: "1000",
                number1: "000010",
                number2: "001000",
              },
              {
                format: "{YYYY}{MM}-{0}",
                seq1: "10",
                seq2: "1000",
                number1: "202108-10",
                number2: "202108-1000",
              },
              {
                format: "PRJ# {000000000}",
                seq1: "10",
                seq2: "1000",
                number1: "PRJ# 000000010",
                number2: "PRJ# 000001000",
              },
            ];
        }
      })

    //this.mainLabel = this.translate?.site.label.plural
  }

}
