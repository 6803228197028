import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { SiteService } from "src/app/_services/site.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-custella-sites",
  templateUrl: "./custella-sites.component.html",
  styleUrls: ["./custella-sites.component.scss"],
})
export class CustellaSitesComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy, OnChanges
{
  @Output() itemSelector = new EventEmitter();
  @Output() closeView = new EventEmitter();
  @Input() assetData: any;
  @Input() customerId: any;

  public searchValue = "";
  translate: any;

  constructor(private siteService: SiteService,
    private languageTranslateService: LanguageTranslateService
  ) {
    super();
   this.push( this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    }))
    this.push(
      this.siteService.getSelectItem().subscribe((data) => {
        if (data) {
          this.selectItem(data);
        }
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
   
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  selectItem(val: any) {
    this.itemSelector.emit(val);
  }

  public closeTable() {
    this.closeView.emit(false);
  }
   
  push(obs:any) {
    super.push(obs);
  }
}
