import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MenuComponent } from "./menu/menu.component";
import { SharedModule } from "src/app/shared/shared.module";
import { HelpdeskSearchBoxComponent } from './helpdesk-search-box/helpdesk-search-box.component';
import { HelpdeskComponent } from "./helpdesk.component";
import { HelpdeskTabsComponent } from './helpdesk-tabs/helpdesk-tabs.component';
import { HelpdeskTableComponent } from './helpdesk-table/helpdesk-table.component';
import { AgGridModule } from "ag-grid-angular";
import { ActionButtonRendererComponent } from './action-button-renderer/action-button-renderer.component';
import { HelpdeskToastComponent } from './helpdesk-toast/helpdesk-toast.component';
import { HelpdeskUserSelectionComponent } from './helpdesk-user-selection/helpdesk-user-selection.component';

@NgModule({
  declarations: [HelpdeskComponent, MenuComponent, HelpdeskSearchBoxComponent, HelpdeskTabsComponent, HelpdeskTableComponent, ActionButtonRendererComponent, HelpdeskToastComponent, HelpdeskUserSelectionComponent],
  imports: [CommonModule, SharedModule, AgGridModule],
  exports: [HelpdeskComponent, MenuComponent],
})
export class HelpdeskModule {}
