
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class DynamicSkillsService {

  refreshList$ = new EventEmitter();
  userList$ = new EventEmitter();

  constructor(private http: HttpClient,) {
    this.refreshList$ = new EventEmitter(); 
    this.userList$ = new EventEmitter(); 
  }

  public getAllDepartments(){
    return this.http.get( environment.base_url+'/skill-types').pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getAllCategories(){
    return this.http.get( environment.base_url+'/skill-categories').pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getAllLevels(){
    return this.http.get( environment.base_url+'/skill-levels').pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public createDepartment(body:any){
    return this.http.post( environment.base_url+'/skill-types', body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(resp);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getUsersByStatus(status:any, search:any){
    let params = new HttpParams().set('skillAssignedStatus', status);
    if(search){
      params = params.set('search', search)
    }
    return this.http.get( environment.base_url+'/users/dynamic-skills', {params : params}).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getDepartmentInfoById(dep:any, users :any){
    let params;
    if(users){
      params = new HttpParams().set('includeUsers', 'true');
    }
    
    return this.http.get( environment.base_url+'/skill-types/category-level/'+dep.id, {params : params}).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getSkillCategoriesByName(search :any){
    let params = new HttpParams().set('name', search);
    return this.http.get( environment.base_url+'/skill-categories-with-levels', {params : params}).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getSkillCategoriesById(id:any){
    return this.http.get( environment.base_url+'/skill-type/'+id+'/skill-categories').pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }


  public getSkillLevelsByName(search :any){
    let params = new HttpParams().set('name', search);
    return this.http.get( environment.base_url+'/skill-levels', {params : params}).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getSkillLevelsByCategory(id :any){
    let params = new HttpParams().set('categoryId', id);
    return this.http.get( environment.base_url+'/skill-levels', {params : params}).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public createLevels(body:any){
    return this.http.post( environment.base_url+'/skill-levels', body, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public editLevel(body:any){
    return this.http.put( environment.base_url+'/skill-levels', body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(body);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }
  
  public createDepartmentSkills(body:any){
    return this.http.post( environment.base_url+'/skill-types/add-category-level', body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(body);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public createDynamicSkillsWithUsers(body:any){
    return this.http.post( environment.base_url+'/user-dynamic-skills/users', body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(body);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public emitUserArray(userArray :any){
    this.userList$.emit(userArray);
  }

  public getSkillsByUsers(body :any){
    return this.http.put( environment.base_url+'/skill-types/category-level/by-users', body).pipe(map( (resp:any) => {
      // this.refreshList$.emit(body);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public deleteDepartment(id :any){
    return this.http.delete( environment.base_url+'/skill-types/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit('Deleted Department');
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

}
