import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class PackagesService {
  public refreshList$: EventEmitter<any>;
  refreshPhoto$: EventEmitter<{}>;
  private ENDPOINT_PACKAGES_SETTINGS = "/package/settings";
  private ENDPOINT_PACKAGES_SUB_TYPES_ALL = "/package-sub-types/all";
  private ENDPOINT_PACKAGE_CATEGORIES_STATUS = "/package-categories/status";
  private ENDPOINT_PACKAGE_TYPES_STATUS = "/package-types/status";
  private ENDPOINT_PACKAGE_SUB_TYPES_STATUS = "/package-sub-types/status";
  private ENDPOINT_SAVE_TYPE = "/package-types";
  private ENDPOINT_TYPE_BY_ID = "/package-types/";
  private ENDPOINT_SAVE_SUB_TYPE = "/package-sub-types";
  private ENDPOINT_SUB_TYPE_BY_ID = "/package-sub-types/";
  private ENDPOINT_PACKAGE_ATTACHMENTS_UPLOAD =
    "/attachments/upload?parentType=";
  private ENDPOINT_LENGTH_OPTIONS = "/package/length-unit/options";
  private ENDPOINT_WEIGHT_OPTIONS = "/package/weight-unit/options";

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: Http
  ) {
    this.refreshList$ = new EventEmitter();
    this.refreshPhoto$ = new EventEmitter();
  }

  public deleteType(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_TYPE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteSubType(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_SUB_TYPE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted");
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllLengths() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LENGTH_OPTIONS)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllWeights() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_WEIGHT_OPTIONS)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSettings() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_PACKAGES_SETTINGS, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSubTypes(typeId: any) {
    let params = new HttpParams().set("typeId", typeId);
    return this.http
      .get(environment.base_url + this.ENDPOINT_PACKAGES_SUB_TYPES_ALL, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllSubTypes() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_PACKAGES_SUB_TYPES_ALL)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getPackageCategoryByStatus(id: any, active: any) {
    let params = new HttpParams().set("active", active);
    if (id) {
      params = params.set("id", id);
    }

    return this.http
      .put(
        environment.base_url + this.ENDPOINT_PACKAGE_CATEGORIES_STATUS,
        null,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getPackageTypeByStatus(id: any, active: any) {
    let params = new HttpParams().set("active", active);
    if (id) {
      params = params.set("id", id);
    }
    return this.http
      .put(environment.base_url + this.ENDPOINT_PACKAGE_TYPES_STATUS, null, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getPackageSubTypesByStatus(id: any, active: any) {
    let params = new HttpParams().set("active", active);
    if (id) {
      params = params.set("id", id);
    }
    return this.http
      .put(
        environment.base_url + this.ENDPOINT_PACKAGE_SUB_TYPES_STATUS,
        null,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //save type
  public createType(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SAVE_TYPE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //get data - edit type
  public getTypeById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_TYPE_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //edit save type
  public editType(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SAVE_TYPE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //save subtype
  public createSubtype(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SAVE_SUB_TYPE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //get data - edit subtype
  public getSubTypeById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_SUB_TYPE_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //edit save subtype
  public editSubtype(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SAVE_SUB_TYPE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public uploadAttachment(parentType: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url +
          this.ENDPOINT_PACKAGE_ATTACHMENTS_UPLOAD +
          parentType,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          this.refreshPhoto$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getPackageByName(searchName: any) {
    return this.http
      .get(
        environment.base_url +
          "/package-sub-types/paged?startFrom=1&perPage=25&columnName=name&sortOrder=asc&search=" +
          searchName
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getPackageType() {
    let params = new HttpParams().set("active", "true");

    return this.http
      .put(environment.base_url + this.ENDPOINT_PACKAGE_TYPES_STATUS, null, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getPackageCategories() {
    return this.http.get(`${environment.base_url}/package-categories`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public getPackageTypes(type: any, id: any) {
    let params = new HttpParams();
    if (type == "category") {
      params = params.set("categoryId", id);
    } else if (type == "subCategory") {
      params = params.set("subCategoryId", id);
    }
    return this.http
      .get(`${environment.base_url}/package-types`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  //************************************* Get Package List *************************************************************************/
  public getPackageList(
    search: any,
    parameters: any,
    categoryId: any,
    subCategoryId: any
  ) {
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (categoryId !== "" && categoryId != null) {
      params = params.set("categoryId", categoryId);
    }
    console.log(subCategoryId)
    if (subCategoryId !== "" && subCategoryId != null) {
      params = params.set("typeId", subCategoryId);
    }
    if (parameters)
      //console.log(parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(environment.base_url + "/package-sub-types/paged", {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //************************************* Get Package By Customer Id *************************************************************************/
  public getCustomerPackage(customerId: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });

    let url =
      environment.base_url + "/package-sub-types?customerId=" + customerId;

    return this.httpOnly.get(url, options).pipe(
      map((resp: any) => {
        console.log(resp);
        return JSON.parse(resp._body);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
