import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin } from 'rxjs';
import { PERSISTANT_ITEM, ServerRequest } from 'src/app/_models/global.data.model';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { SiteService } from 'src/app/_services/site.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { SelectAllComponent } from 'src/app/renderer/select-all.component';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { ProductivityService } from 'src/app/_services/productivity.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
var dateFormateChange: any;

@Component({
  selector: 'app-all-productivity',
  templateUrl: './all-productivity.component.html'
})
export class AllProductivityComponent extends SubscriptionUtil
  implements OnInit, OnDestroy {
    @Input() display: any = null
  @Input() pendingStatus: any = null
  @Input() machineSpecialists: any = null
  @Input() todayTab: any;
  @Input() tabName: any;
  filterView = false;

  productivityTypes: any[] = [];
  approvalStatuses: any[] = [];
  statuses: any[] = [];
  refresher: Subscription | undefined
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  noProductivityDataIcon!: boolean;

  searchValue: any = "";
  currentRecordNumber: any;
  totalRecord: any;
  ProductivityData: any;
  productivityType: any;
  approvalStatus: any;

  filterActive: boolean = false;
  filterClick: boolean = false;

  dialogStatusData = {
    module: "",
    recordNumber: "",
    actionStatus: "",
    actionDescription: false,
    multipleDataAccept: "",
    multipleDataAcceptTitle: "",
    headerTitle: "",
  };
  selectedId: any;


  noProductivity = false;
  displayIconProductivity = false;
  dateRange!: Date[];
  myGroup!: any;
  hideOnDateTimeSelect: boolean = true;
  @ViewChild('dateFilter', { static: false })
  private dateFilter: any;
  allSites: any;
  site: any;
  status: any;
  filter: any = {
    status: null,
    productivityType: null,
    dueEndDate: null,
    fromDate: null,
    toDate: null,
    approvalStatus: null,
    siteId: null
  };
  filterAPILoaded: boolean = false;
  searchValueData: boolean = false;

  public showingTable = {
    sites: false,
    machineSpecialist: false,
  };
  selectedSite: any;
  selectMachineSpecialist: any;
  allMachineSpecialist: any;
  selectAllChecked: any = false;
  selectedRows: any = [];

  showStatusDialog: boolean = false;
  selectedIds: any[] = []
  create!: boolean;
  attributeIdName: any;
  translate: any;

  constructor(private router: Router,
    private productivityService: ProductivityService,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private sitesService: SiteService,
    private checklistService: ChecklistService,
    private perm: PermissionService,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService) {
    super();
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
    this.push(
      this.productivityService.getSelectItem().subscribe((data) => {
        if (data) {
          this.selectAllChecked = data;
        }
      })
    );

  }

  ngOnInit(): void {
    dateFormateChange = this.auth.getUserTimezone();
    this.create = this.perm.capable("productivity", "create");

    this.util.resetTabView();
    this.tabName == "pending" ? this.attributeIdName = "ProdPend": null;
    this.tabName == "todayPending" ? this.attributeIdName = "ProdTodPend": null;
    this.tabName == "all" ? this.attributeIdName = "Prod": null;
    console.log("tabb",this.attributeIdName)
    console.log(this.pendingStatus)
    this.todayTab == "true" ? this.todayTab = true : this.todayTab = false;

    if(this.display){
      var todays = new Date()
      this.dateRange =[todays, todays]
      let startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      let endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
       this.filter.fromDate = this.util.timeFormatter( moment("00:00:00", "h:mm A").format("h:mm A"), moment(this.dateRange[0], "YYYY-MM-DD").format("YYYY-MM-DD")) .toISOString();
       this.filter.toDate = this.util .timeFormatter( moment("23:59:00", "h:mm A").format("h:mm A"),moment(this.dateRange[1], "YYYY-MM-DD").format("YYYY-MM-DD") ) .toISOString();

       
    }

    if (this.pendingStatus) {
      this.filter.status = this.pendingStatus
    }
    if (this.machineSpecialists) {
      let dataObj = {
        fullname: this.machineSpecialists.user.firstName + ' ' + this.machineSpecialists.user.lastName,
        userId: this.machineSpecialists.user.id,
        siteId: this.machineSpecialists.defaultSite.id,
        SiteName: this.machineSpecialists.defaultSite.siteName,
        userGroup: this.machineSpecialists.defaultSite.userGroup.name
      };



      this.util.setPersistantItem(PERSISTANT_ITEM.MS_VISIT, dataObj);

      this.selectMachineSpecialist = this.machineSpecialists.user.firstName
      this.filter.userId = this.machineSpecialists.user.id
    }

    this.init();
    this.preInit();
  }

  ngOnDestroy(): void {
    this.onDestroy();

  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.push(
      this.refresher = this.productivityService.refreshList$.subscribe((productivity) => {
        this.refresh();
      })
    );

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      selectAllComponent: SelectAllComponent
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      paginationPageSize: 25,
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
    console.log(this.pendingStatus)
    this.columnDefs = [
      {
        hide: this.pendingStatus == null ? true : false,
        width: 70, sortble: false,
        headerComponent: 'selectAllComponent',
        headerComponentParams: {
          checkboxSelection: true,
        },
        checkboxSelection: true,

        cellRenderer: (params: any) => {
          //////////////debugger
          if (params.data) {
            console.log("&&&&&&&&&&", params.data)
            if (this.selectAllChecked) {
              params.node.setSelected(true);
            }
            else if (!this.selectAllChecked) {
              //   console.log(params.node,"************************************************************")
              params.node.setSelected(false);

            }
            else {
              params.node.setSelected(false);
            }
          }
        }
      },
      { headerName: "Productivity Running No", field: "runningNo", width: 250, headerTooltip: "#", cellClass: function (params: any) { return ["table_default_color"]; } },
      {
        headerName: "Outturn Code",
        field: "outTurnCode.name",
        width: 250,
        headerTooltip: "#",
      },
      { headerName: 'Date & Time', field: 'dateTime', width: 200, headerTooltip: "#",
      cellRenderer: function (params: any) {
        if (params.data && params.data.dateTime) {
          return ( 
            moment.tz(params.data.dateTime,dateFormateChange) .format(localStorage.getItem("date_format")!.toUpperCase())+' , '+moment.tz(params.data.dateTime, dateFormateChange) .format("hh:mm A")  );
        }
      }, },
      {
        headerName: "Machine Specialist",
        field: "user.fullname",
        width: 250,
        headerTooltip: "#",
        cellClass: function (params: any) { return ["table_default_color"]; }
      },
      {
        headerName: "Division",
        field: "division",
        width: 250,
        headerTooltip: "#",
      },
      {
        headerName: "Estate",
        field: "site.siteName",
        width: 250,
        headerTooltip: "#",
        cellClass: function (params: any) { return ["table_default_color"]; }
      },
    
      {
        headerName: "Status",
        field: "status.name",
        width: 220,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.status) {
            return ' <span class="p-mr-2 p-tag p-component" style="background-color:' + params.data.status.colorCode + '50 !important;"> <span class="p-tag-value text-dark"> ' + params.data.status.label + '</span></span>'
          }
        }
      },
      {
        headerName: "Action",
        sortable: false,
        filter: false,
        width: 200,
        headerTooltip: "#",
        headerClass: "marging-auto hide-action-border",
        cellStyle: { "text-align": "right" },
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.editProductivity.bind(this),
          actionMS: "ActionMS",
          permissions: {
            update: this.perm.capable("productivity", "update"),
            remove: this.perm.capable("productivity", "remove"),
          },
          hidden: {
            update: !this.perm.capable("productivity", "update"),
            remove: !this.perm.capable("productivity", "remove"),
          },
        },
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };

    //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";
    this.myGroup = new UntypedFormGroup({
      dateRange: new UntypedFormControl(""),

    });
  }
  onSelectionChanged(event: any): void {
    const selectedRows: string[] = event.api.getSelectedRows().map((t: any) => t);
    console.log('selectedRows');
    console.log(selectedRows);
    ////debugger
    this.selectedRows = [];
    this.selectedRows = selectedRows;
    if (this.selectedRows.length > 0) {
      $('.ms-action-button').css('visibility', 'hidden');
    } else {
      $('.ms-action-button').css('visibility', 'visible');
    }
    //$('#checkboxCustella1').addClass('c-checkbox-icon');

  }
  /******************************************** Init ******************************************/
  private init() {
    this.spinner.show()
    //this.getTeams();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Filter List ******************************************/
  getFilterList() {
    this.push(
      this.productivityService
        .getAllProductivityStatus()
        .subscribe((res: any) => {
          console.log(res)
          this.statuses = res.body
        })
    );
  }

  public toggleFilterView() {
    this.filterActive = !this.filterActive;
    this.filterClick = !this.filterClick;
    if (this.filterAPILoaded === false) {
      this.getFilterList()
    }
    this.filterAPILoaded = true;
  }

  createView() {
    this.router.navigate(['dashboard/productivity/create'])
  }

  viewPage() {
    this.router.navigate(['dashboard/productivity/view'])
  }

  /********************************** Click Cell Event *****************************/
  private editProductivity(e: any) {
    if (e.action === "edit") {
      this.router.navigate(["dashboard/productivity/update"], {
        queryParams: { id: e.rowData.id },
      });
    } else if (e.action === "delete") {
      console.log(e)
      e.rowData.deleteType = "Productivity";
      e.rowData.message =
        "Are you sure you want to delete " + e.rowData.runningNo + "?";

      this.deleteRecordService.getItems(e.rowData);
    } else if (e.action === "acceptMS") {
      this.selectedId = e.rowData.id
      this.showStatusDialog = true;
      this.dialogStatusData.module = "Productivity"
      this.dialogStatusData.recordNumber = e.rowData.runningNo
      this.dialogStatusData.actionStatus = "APPROVED"
      this.dialogStatusData.actionDescription = false
      this.dialogStatusData.multipleDataAccept = "SINGLE",
        this.dialogStatusData.headerTitle = "Approve Productivity"
      this.showStatusDialog = true;
    }
  }


  /********************************** View Singal Record **************************/
  onGridReady(params: any) {
    //console.log(params)
    this.gridParams = params;
    this.gridApi = params.api;
    //this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    // this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }
  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    console.log(this.filter)

    this.push(
      this.productivityService.getAllProductivities(this.searchValue, serverRequest, this.filter).subscribe((data: any) => {
        this.spinner.hide();
        if (data.status === 200 || data.status === 201) {
          this.ProductivityData = data.body.data;
          console.log(this.ProductivityData);
          if (this.ProductivityData) {
            this.ProductivityData.forEach((element: any) => {
               element.hideActionButton = 'hideAction';//for hide delete button
             
              if (element.user) {
                element.user.fullname = element.user.firstName + " " + element.user.lastName
              }
            });
          }
          this.totalRecord = data.body.total;
          this.setGridData(
            agGridGetRowsParams,
            this.ProductivityData,
            data.body.total,
            data.body.status
          );
          console.log(this.ProductivityData);
        } else {
          this.errorUtil.setErrorMessage(data.status, null);
        }
      })
    );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    //if (resultItems) {

    if (status === "NO_DATA") {
      this.noProductivity = false;
      this.noProductivityDataIcon = true;
      this.gridParams.api.showNoRowsOverlay();
    } else {
      this.noProductivity = true;
      this.noProductivityDataIcon = false;
    }
    if (resultItems.length === 0) {
      this.gridParams.api.showNoRowsOverlay();
    } else {
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "runningNo", sort: "desc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,

    };
  }


  /******************************************** Refresh ******************************************/
  public refresh() {
    this.spinner.show()
    console.log(this.site);
    this.onGridReady(this.gridParams);
  }

  /******************************************** Search ******************************************/
  public onSearch() {
    if (this.searchValue && this.searchValue.length > 0) {
      this.searchValueData = true
      this.refresh();
    }
  }

  /******************************************** call table after filter ******************************************/
  public filterResults($event: any) {
    console.log(this.approvalStatus);
    this.refresh();
  }

  /******************************************** reset filter ******************************************/
  public resetSearch() {
    //this.dateRange = undefined;
    this.myGroup.get("dateRange").setValue(null);
    this.filter = {
      status: null,
      customerId: null,
      salesOrderId: null,
      fromDate: "",
      toDate: "",
    };

    this.refresh();
  }

  /********************************** View Singal Record **************************/
  viewProductivity(e: any) {
    console.log(e.colDef)
    if (e.colDef.field === "runningNo") {
      this.router.navigate(['dashboard/productivity/view/' + e.data.id], { queryParams: { id: e.data.id } });
    }
    if (e.colDef.field === "site.siteName") {
      this.router.navigate(['dashboard/sites/view/' + e.data.site.id], { queryParams: { id: e.data.site.id } });
    }
    if (e.colDef.field === "user.fullname") {
      this.router.navigate(['dashboard/setup/allUser/view'], { queryParams: { id: e.data.user.id, edit: false } });
    }
  }


  public onChangeDate() {

    if (this.dateRange[0] != null && this.dateRange[1] !== null) {
      let startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      let endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      this.filter.fromDate = this.util.timeFormatter(moment("00:00:00", "h:mm A").format("h:mm A"), moment(this.dateRange[0], "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();
      this.filter.toDate = this.util.timeFormatter(moment("23:59:00", "h:mm A").format("h:mm A"), moment(this.dateRange[1], "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString();

      this.dateFilter.hideOverlay()
      this.refresh();
    }

  }

  /******************************************** Clear Value Dynamic Picklist ******************************************/
  public clearValue() {
    
    this.myGroup.get("dateRange").setValue(null);
    this.filter = {
      productivityType: null,
      fromDate: this.todayTab ? this.util.timeFormatter(moment("00:00:00", "h:mm A").format("h:mm A"), moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString() : null,
      toDate: this.todayTab ? this.util.timeFormatter(moment("00:00:00", "h:mm A").format("h:mm A"), moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")).toISOString() : null,
      status: this.pendingStatus ?? null,
      siteId: null,
      userId: this.machineSpecialists ? this.machineSpecialists.user.id:null
    };
    this.selectedSite = null
    this.selectMachineSpecialist = null;
    this.refresh();
  }



  onClickDialgYes(event: any) {
    this.spinner.show();
    
    if (event) {
      if (event.actionStatus == "APPROVED") {
        const status = "APPROVED"
        let data = {
          id: this.selectedIds.length > 0 ? this.selectedIds : [this.selectedId],

          status: status
        }
        this.productivityService.updateProductivityStatus(data).subscribe((res: any) => {
          if (res.status == 200) {
            this.errorUtil.setErrorMessage(res.status, "Productivity Approval Status updated successfully", null, "success", 2000);
            if (this.selectedId) {
              this.router.navigate(['dashboard/productivity/view/' + this.selectedId], { queryParams: { id: this.selectedId } });
            }
            if (this.selectedIds.length > 0) {
              this.selectedRows = [];
              this.refresh();
            }
          }
        }, err => {
          this.errorUtil.setErrorMessage(err.status, null, err.error.title, "error", 3000);
        });
      }
    }
    this.showStatusDialog = false;
  }

  visibleOut(e: any) {
    console.log("e", e)
    this.showStatusDialog = e;
    console.log("e2", e)
  }

  public onSearchCheck() {
    if (this.searchValue.length == 0) {
      this.searchValueData = false
      this.searchValue = ''
      this.refresh()
    }
  }
  public onClear() {
    this.searchValueData = false
    this.searchValue = ''
    this.refresh()

  }

  /******************************************** On Site Select Value ******************************************/
  public onSiteSelect(event: any) {
    
    this.selectedSite = event;
    this.filter.siteId = event.id;
    this.closeTable("sites");
    this.refresh()
  }

  /******************************************** Get All Site By Query ******************************************/
  public getSite(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      this.push(
        this.sitesService.getSitePicklist(query).subscribe((sites: any) => {
          this.allSites = sites;
        })
      );
    }
  }

  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string) {
    if (what === "sites") {
      this.showingTable.sites = true;
    }

    if (what === "machineSpecialist") {
      this.showingTable.machineSpecialist = true;
    }
  }

  /******************************************** Close Table Dynamic Picklist ******************************************/
  public closeTable(what: string) {
    if (what === "sites") {
      this.showingTable.sites = false;
    }

    if (what === "machineSpecialist") {
      this.showingTable.machineSpecialist = false;
    }

  }

  /******************************************** On Machine Specialist Select Value ******************************************/
  public onMachineSpecialistSelect(event: any) {
    console.log(event)
    this.selectMachineSpecialist = { name: event.user.firstName + ' ' + event.user.lastName };
    this.filter.userId = event.user.id;
    this.closeTable("machineSpecialist");
    this.refresh()
  }

  /******************************************** Get All Machine Specialist By Query ******************************************/
  public getMachineSpecialist(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      var serverRequest: any = {
        startRow: 0,
        pageSize: 25,
        filterModel: null,
        sortModel: [{ colId: "id", sort: "asc" }]
      }
      this.push(this.checklistService.getAllMachinesSpecialist(query, serverRequest, null, null).subscribe((response: any) => {
        this.allMachineSpecialist = response.body.data;
        console.log(response)
        if (this.allMachineSpecialist) {
          this.allMachineSpecialist.forEach((element: any) => {
            element.name = element.user.firstName + ' ' + element.user.lastName
          });

        }
      })
      );
    }
  }
  /******************************************** clear customer ******************************************/
  public clearValueFrom(what: string) {

    if (what === "site") {
      this.selectedSite = null;
      this.filter.siteId = null;
    }

    if (what === "machineSpecialist") {
      this.selectMachineSpecialist = null;
      this.filter.userId = null;
    }
    this.refresh()
  }


  /********************************** Accept multip-le statuses **************************/
  onAcceptMultipleStatus() {

    console.log("", this.selectedRows);
    this.selectedIds = [];
    this.selectedRows.forEach((row: any) => {
      this.selectedIds.push(row.id)
    })

    console.log(this.selectedRows.length)
    if (this.selectedRows.length > 0) {
      this.showStatusDialog = true;
      this.dialogStatusData.actionStatus = "APPROVED"
      this.dialogStatusData.module = "Productivity"
      this.dialogStatusData.multipleDataAccept = "MULTI";
      this.dialogStatusData.multipleDataAcceptTitle = this.selectedRows.length + " Productivity records will now be approved. Do you wish to continue ? "
      this.dialogStatusData.headerTitle = "Approve Productivity"
      this.showStatusDialog = true;

    }
    else if (this.selectedRows.length < 1) {
      window.alert("select atleast one record")
    }



  }


  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems (params: any)  {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];

    if (params.column.colId == "runningNo" || params.column.colId === "user.fullname" || params.column.colId === "site.siteName") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
    this.util.resetTabView();
    let url;
    if (data.field === "runningNo") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ['dashboard/productivity/view/' + data.id], { queryParams: { id: data.id } }
        )
      );
    } else if (data.field === "site.siteName") {
      this.util.resetTabView();
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ['dashboard/sites/view/' + data.site.id], { queryParams: { id: data.site.id } }
        )
      );

    }
    else if (data.field === "user.fullname") {
      this.util.resetTabView();
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ['dashboard/setup/allUser/view'], { queryParams: { id: data.user.id, edit: false } }
        )
      );

    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }

}
