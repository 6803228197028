<div class="custella-modal">
    <form>
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2"> {{ "export_data"  }} </span>
            <i class="pi pi-times" (click)="modalRef.hide()"></i>
        </div>

        <div class="">
            <!-- Container -->
            <div class="custella-form-container position-relative d-block w-100 rounded-2 pb-3 m-0"
                style="height: fit-content;">
                <!--  -->
                <div class="px-4 py-2">
                    <div class="d-flex align-items-center justify-content-center m-4">
                        <div class="custella-import-checkpoint"></div>
                        <div class="custella-import-path-dash-export"></div>
                        <div class="custella-import-uncheckpoint"></div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center m-4">
                        <span class="text-12 font-medium ms-3" >{{'select'}}</span>
                        <div class="custella-import-path-none-export"></div>
                        <span class="text-12 font-medium ms-3" >{{'download'}}</span>
                    </div>
                </div>
                <!-- Match Any -->
                <div class="custella-form-container-title-two-grey d-flex justify-content-between align-items-center">
                    <span class="text-14 font-medium" >{{"download"}}</span>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-12">
                            <div class="flex-between border-bottom" style="margin-top: 10px; padding-bottom: 10px;">
                                <div>
                                    <i class="pi pi-file"></i>
                                    <span class="text-12 padding-left-10px padding-right-10px">Job Order Completion Form.pdf</span>
                                </div>
                                <button pButton [label]="'general.buttons.download.singular'" icon="fa-regular fa-download"
                                    class="p-button-outlined p-mr-2 p-mb-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-footer-two">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" (click)="goBack()">{{translate?.general.buttons.cancel.singular}}</button>
                        <p-button [label]="buttonLabel" styleClass="ms-2 h-32"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>