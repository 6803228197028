import { of as observableOf, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class RolesService {
  refreshList$ = new EventEmitter();
  saveChangesPrompt$ = new EventEmitter();
  saveChanges$ = new EventEmitter();
  private ENDPOINT_ROLES = "/roles";
  private ENDPOINT_DELETE_ROLES = "/roles/";
  private ENDPOINT_TREE_ROLES = "/roles/tree";
  private ENDPOINT_ASSIGN_ROLES = "/users/assign-role";

  constructor(private http: HttpClient) {
    this.refreshList$ = new EventEmitter();
    this.saveChangesPrompt$ = new EventEmitter();
    this.saveChanges$ = new EventEmitter();
  }

  public getRoles() {
    return this.http.get(environment.base_url + this.ENDPOINT_ROLES).pipe(
      map((resp: any) => {
        // return <Admin>resp;
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return observableOf(error);
      })
    );
  }

  public getRoleTree() {
    return this.http.get(environment.base_url + this.ENDPOINT_TREE_ROLES).pipe(
      map((resp: any) => {
        // return <Admin>resp;
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return observableOf(error);
      })
    );
  }

  public createRole(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_ROLES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return observableOf(error);
        })
      );
  }

  public editRole(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_ROLES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return observableOf(error);
        })
      );
  }

  public deleteRole(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_ROLES + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Role");
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return observableOf(error);
        })
      );
  }

  // public emitSaveChangesPrompt(url) {
  //   //console.log("Prompting Save");
  //   this.saveChangesPrompt$.emit({mode:'saveChangesPrompt', returnUrl : url});
  // }

  public emitSaveChanges() {
    // //console.log("Doing Save");
    this.saveChanges$.emit("save");
  }

  public assignRole(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_ASSIGN_ROLES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }
}
