<!-- <div class="modal-content modal-fullscreen p-0"> -->
<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">
        <span class="text-16 font-medium ms-3" id="title_TaskCheckOut">{{translate?.task.fields.checkoutTask.singular}}</span>
    </div>
    <i class="fa-regular fa-xmark" (click)="modalRef.hide()" id="btn_TaskCheckOutClose"></i>
</div>

<div class="modal-body pt-0">
    <div class="container px-0" style="padding: 20px;">
        <p class="mb-0 text-4 pb-2 px-3" id="label_CheckOutMsg">{{message}}</p>

    </div>
</div>
<div class="modal-footer p-0">
    <button type="button" id="btn_CheckOutCancel" class="custella-btn-white mx-1 text-12" (click)="onSubmit(false)">{{translate?.general.buttons.no.singular}}</button>
    <p-button type="button" id="btn_CheckOutSubmit" (onClick)="onSubmit(true)" [label]="translate?.general.buttons.yes.singular"></p-button>
</div>



<!-- </div> -->