<div class="custella-content-row" *ngIf="locationDetail">
        <div class="custella-content-row-input col-12">
            <span>
                <p *ngIf="locationDetail?.street" id="value_LocStreet" class="custella-content-row-input-title-three mb-0">{{locationDetail?.street}},</p>
                <p *ngIf="locationDetail?.city" id="value_LocCity" class="custella-content-row-input-title-three mb-0">{{locationDetail?.city}},</p>
                <p *ngIf="locationDetail?.state" id="value_LocState" class="custella-content-row-input-title-three mb-0">{{locationDetail?.state}},</p>
                <p *ngIf="locationDetail?.postCode" id="value_LocPostCode" class="custella-content-row-input-title-three mb-0">{{locationDetail?.postCode}},</p>
                <p *ngIf="locationDetail?.country" id="value_LocCountry" class="custella-content-row-input-title-three mb-0">{{locationDetail?.country}}</p>

                <ng-container *ngIf="locationDetail?.locationLat && locationDetail?.locationLong">
                    <p class="custella-content-row-input-title-three mb-0" id="value_LocLatLong">({{locationDetail?.locationLat}}, {{locationDetail?.locationLong}})</p>
                </ng-container>

                <ng-container *ngIf="!locationDetail?.locationLat && locationDetail?.locationLong">
                    <p class="custella-content-row-input-title-three mb-0" id="value_LocLong">(- , {{locationDetail?.locationLong}})</p>
                </ng-container>

                <ng-container *ngIf="locationDetail?.locationLat && !locationDetail?.locationLong">
                    <p class="custella-content-row-input-title-three mb-0" id="value_LocLat">({{locationDetail?.locationLat}}, -)</p>
                </ng-container>

                <ng-container *ngIf="!locationDetail?.locationLat && !locationDetail?.locationLong">

                </ng-container>


            </span>
           
            <p-accordion *ngIf="locationDetail?.locationLat && locationDetail?.locationLong" (onOpen)="toggle($event)" (onClose)="toggle($event)">
               
                <p-accordionTab [transitionOptions]="'0ms'" [(selected)]="activeState"  *ngIf="locationDetail?.locationLat && locationDetail?.locationLong" >
                    <p-header>
                        <span #header id="btn_LocShowMap">
                            {{translate?.general.buttons.showMap.singular}}
                        </span>
                    </p-header>
                    <p-gmap *ngIf="options" [overlays]="overlays" [options]="options" [style]="{'width':'calc(100% - 3px)','height':'320px', 'margin-left':'3px', 'border': '1px solid #e2e2e2'}"></p-gmap>
                </p-accordionTab>
            </p-accordion>

        </div>
       
    </div>
