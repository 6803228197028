import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AddEditTaskComponent } from "../add-edit-task/add-edit-task.component";
import { UsersService } from "src/app/_services/admin/users.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TeamService } from "src/app/_services/team.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { TasksService } from "src/app/_services/tasks.service";

import * as _ from "lodash";
import * as moment from "moment-timezone";
import { NgxSpinnerService } from "ngx-spinner";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-exact-assign",
  templateUrl: "./exact-assign.component.html",
  styleUrls: ["./exact-assign.component.scss"],
})
export class ExactAssignComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  first: boolean = true;
  data: any;
  tempTeam: any;
  parentMode: any;

  ownerSelected: any; // Variable to store the selected owner

  assignTask: any = { teamName: null, userNames: [], teamCategory: {} }; // Object to store task assignment details

  // Configuration for medium-sized modal
  configMd: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-md",
  };

  // Configuration for custom medium-sized modal
  configCustomMd: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true,
    class: "custom-modal-md",
  };

  teams: any[] = []; // Array to store team data
  users: any[] = []; // Array to store user data

  assignMode: any = "user"; // Mode of assignment (team or user)

  submitTouched: boolean = false; // Flag to track if the form is submitted

  teamSelected: boolean = false; // Flag to check if a team is selected

  searchUserVal = null; // Value for the user search field

  showNoUser = false; // Flag to show "No User Found" message

  count = 0; // Counter to track number of times API is called

  formData: any; // Form data for task creation

  dateConfig: any; // Configuration for date format

  comingFrom: string = "Task"; // Context for the task (e.g., from a task or project)

  public addTask: any = {
    joName: null,
    customerName: null,
    hasBuffer: true,
    adhoc: false,
    duration: "30",
    jobOrder: { id: null },
    userDynamicSkillList: [],
    showServiceChecklist: false,
    showHandoverCertificate: false,
    showCustomerServiceReport: false,
    serviceChecklistRequired: false,
    customerServiceReportRequired: false,
    handoverCertificateRequired: false,
    team: { id: null },
    date: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    site: { id: null },
    project: { id: null },
  };

  addonFeature: any; // Variable to store addon features

  public allPriorities: any = [];
  translate: any;

  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private userService: UsersService,
    private teamService: TeamService,
    private auth: AuthenticationService,
    private util: UtilServiceService,
    private errorUtil: ErrorUtil,
    private tasksService: TasksService,
    private spinner: NgxSpinnerService,
    private languageTranslateService: LanguageTranslateService,
    ) {
      super();
      this.getLanguageData();
  }

  ngOnInit(): void {
    this.init();
  }

  /******************************************** Init ******************************************/
  private init() {
    console.log("DATA ", this.data);

    this.parentMode = this.data.parentMode; // Set the mode of the parent component
    this.addonFeature = this.auth.getAddonFeatureLists(); // Retrieve addon features

    // Set date format configuration from local storage
    this.dateConfig = {
      dateInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    // Get all task priorities from the service
    this.allPriorities = this.tasksService.getAllTasksPriorities();

    // If a team is already assigned, switch to "team" mode and fetch team data
    if (this.data?.team?.id) {
      this.assignMode = "team";
      this.callTeamApi("team");
    }

    this.data.assignedUsersList = []; // Initialize assigned users list

    this.formData = _.cloneDeep(this.data); // Clone the data to avoid mutations

    console.log("After DATA ", this.formData);
  }

  /******************************************** Go Back To Create Task ******************************************/
  public goBackToCreateTask() {
    let modalState: any = {};

    if (this.first) {
      this.modalRef.hide(); // Hide the current modal

      if (this.tempTeam) {
        this.data.team.id = this.tempTeam.id; // Set the team ID if a temp team is selected
      }

      // Determine modal state based on the parent mode
      if (this.parentMode == "add" || this.parentMode == "add&back") {
        modalState = {
          title: "New Task",
          mode: "add&back",
          data: this.data,
        };
      } else {
        modalState = {
          title: "Edit Task",
          mode: "edit&back",
          data: this.data,
        };
      }
      this.ownerSelected = null; // Reset owner selection

      this.configMd.initialState = modalState; // Set initial state of the modal

      // Show the Add/Edit Task modal
      this.modalRef = this.modalService.show(
        AddEditTaskComponent,
        this.configMd
      );
    } else {
      this.ownerSelected = null; // Reset owner selection
      this.first = true; // Reset the first submission flag
      this.submitTouched = false; // Reset the submitTouched flag
    }
  }

  /******************************************** Select Team ******************************************/
  public selectTeam(team: any) {
    console.log(team);
    this.data.displayTeamName = team.teamName; // Display the selected team's name

    this.assignTask.userNames = []; // Clear any previously assigned users
    this.data.team = { id: team.id, teamCategory: team.categories }; // Set the selected team ID and categories
    this.data.teamSettings = team.teamSettingsVM; // Set the team settings
    this.tempTeam = team; // Temporarily store the selected team
    this.teamSelected = true; // Mark the team as selected

    // Set task assignment settings based on team category and adhoc status
    if (this.data.teamSettings.teamCategory.indexOf("LOCATION") >= 0) {
      this.assignTask.teamCategory.location = true;
    }
    if (this.data.teamSettings.teamCategory.indexOf("SKILL") >= 0) {
      this.assignTask.teamCategory.skills = true;
    }
    if (this.data.adhoc) {
      this.assignTask.teamCategory.available = true;
    }

    // Automatically assign the first user as owner if no owner is found
    let findOwner = _.find(this.tempTeam.userList, (o) => {
      return o.owner;
    });

    this.tempTeam.userList.forEach((user: any, i: any) => {
      if (i == 0 && findOwner == undefined) {
        user.owner = true;
      }
      user.member = false;
      this.assignTask.userNames.push(user); // Add users to the task
    });
  }

  /******************************************** Search and Add User ******************************************/
  public getSearchedUserList(event: any) {
    if (event) {
      var team = null;

      if (this.assignMode == "team") {
        team = this.tempTeam;
      }

      // Fetch users based on search input
      this.userService.getUsersBySearch(event).subscribe((users: any[]) => {
        this.users = users;

        if (this.users.length) {
          this.showNoUser = false; // Hide "No User Found" message if users are found
        } else {
          this.showNoUser = true; // Show "No User Found" message if no users are found
        }
      });
    }
  }

  /******************************************** Add Selected User to Task ******************************************/
  public addUser(user: any) {
    // Check if the user is already added to the task
    var checkExistingUser = _.find(this.assignTask.userNames, (o) => {
      return o.id == user.id;
    });

    if (!checkExistingUser) {
      if (!this.assignTask.userNames.length) {
        user.owner = true; // Set the first user as the owner
        user.member = false;
      } else {
        user.owner = false; // Set subsequent users as members
        user.member = true;
      }
      this.assignTask.userNames.push(user); // Add the user to the task
    }
    if (this.assignTask.userNames.length < 6) {
      this.assignTask.numberOfUsers = "ALL"; // Allow all users if less than 6 users are added
    } else {
      this.assignTask.numberOfUsers = "1"; // Restrict to 1 user if more than 6 users are added
    }
    this.searchUserVal = null; // Reset search input
  }

  /******************************************** Call API to Fetch Teams ******************************************/
  public callTeamApi(val: any) {
    this.users = []; // Clear the user list
    this.assignTask.userNames = []; // Clear assigned users

    if (this.count == 0) {
      this.getTeamApi(); // Fetch teams if not already done
    } else {
      this.resetSelection("team"); // Reset team selection
    }
    this.count++;
    console.log(this.count, "=====", val);

    this.assignMode = "team"; // Set assign mode to "team"
  }

  /******************************************** Reset Team/User Selection ******************************************/
  public resetSelection(val: any) {
    this.teamSelected = false; // Mark the team as not selected
    this.assignTask.userNames = []; // Clear assigned users
    this.assignMode = val; // Set assign mode to the given value

    if (this.data.team && this.data.team.id) {
      let matchTeam = _.filter(this.teams, (o) => {
        return o.id == this.data.team.id;
      });
      if (matchTeam.length) {
        this.assignTask.teamName = matchTeam[0]; // Assign the matched team
      }
    }
    if (this.assignMode == "user") {
      let matchTeam = _.filter(this.teams, (o) => {
        return o.id == this.data.team.id;
      });

      if (matchTeam) {
        this.tempTeam = matchTeam[0]; // Temporarily store the matched team
        this.assignTask.teamName = this.tempTeam; // Set the matched team as selected
        this.assignTask.teamName = null; // Reset team name
      }
    } else {
      if (this.tempTeam) {
        let matchTeam = _.filter(this.teams, (o) => {
          return o.id == this.data.team.id;
        });
        if (matchTeam.length) {
          this.tempTeam = matchTeam[0]; // Temporarily store the matched team
          this.assignTask.teamName = this.tempTeam; // Set the matched team as selected
          this.selectTeam(this.tempTeam); // Select the matched team
        }
      }
    }
  }

  /******************************************** Fetch Teams from API ******************************************/
  public getTeamApi() {
    this.push(
      this.teamService
        .getTeamsByUserId(this.auth.getUserId())
        .subscribe((teams) => {
          this.teams = teams; // Store the fetched teams

          if (this.data.team) {
            var matchTeam = _.filter(this.teams, (o) => {
              return o.id == this.data.team.id;
            });

            if (matchTeam.length) {
              this.resetSelection("team"); // Reset selection if team matches
              this.tempTeam = matchTeam[0]; // Temporarily store the matched team
              this.assignTask.teamName = this.tempTeam; // Set the matched team as selected
              this.selectTeam(this.tempTeam); // Select the matched team
            }
          }

          console.log("Teams Search ", this.teams);
        })
    );
  }

  /******************************************** Make Selected User the Owner ******************************************/
  public makeOwnerclick(user: any) {
    this.assignTask.userNames.forEach((element: any) => {
      console.log(element.owner,"---->>>Element ", element.firstName,"---->>>User ", element.id);
      element.member = element.owner ? true : element.member; // If owner is true, make the user a member before switching ownership
      element.owner = false; // Reset owner status for all users
    });

    var findCurrentOwner = _.find(this.assignTask.userNames, (o) => {
      return o.owner;
    });

    if (findCurrentOwner) {
      findCurrentOwner.owner = false; // Reset owner status if found
    }

    user.owner = true; // Set the selected user as the owner

    if (user.member) {
      user.member = false; // Remove member status if the user becomes the owner
    }
  }

  /******************************************** Toggle Member Status for Selected User ******************************************/
  public makeMember(user: any) {
    user.member = !user.member; // Toggle member status
  }

  /******************************************** Remove Selected User from Task ******************************************/
  public removeUser(user: any) {
    _.remove(this.assignTask.userNames, (o: any) => {
      return o.id == user.id; // Remove the selected user from the task
    });
  }

  /******************************************** Submit Task Assignment ******************************************/
  public onSubmit(submitType: any) {
    const date = moment(
      this.addTask.date,
      this.dateConfig.dateInputFormat
    ).format("YYYY-MM-DD"); // Format the task date

    let userIdArray: any[] = []; // Array to store user IDs

    // Check if no users are assigned
    if (this.assignTask.userNames.length === 0) {
      this.errorUtil.setErrorMessage(
        200,
        null,
        this.translate?.task.messages.selectOwner,
        "warn",
        2000
      );

      return;
    }

    console.log(this.assignTask.userNames);

    let length = 0;

    // Count the number of owners and members
    this.assignTask.userNames.forEach((element: any) => {
      if (element.owner) {
        length++;
      }

      if (element.member) {
        length++;
      }
    });

    // Check if more than 5 users are assigned
    if (length > 5) {
      this.errorUtil.setErrorMessage(
        200,
        null,
        this.translate?.task.messages.maxUsersTask,
        "warn",
        2000
      );

      return;
    }

    // Prepare the assigned users list
    this.assignTask.userNames.forEach((element: any) => {
      let user = {
        owner: element.owner ? true : false,
        assignedTo: {
          id: element.id,
        },
      };

      if (element.owner || element.member) {
        userIdArray.push(user); // Add the user to the assigned list
      }
    });

    this.formData.assignedUsersList = userIdArray; // Assign the prepared list to the form data
    this.spinner.show(); // Show the loading spinner
    this.submitTouched = true; // Mark the form as submitted

    // Create the task with the form data
    this.tasksService.createTask(this.formData).subscribe((taskRes: any) => {
      if (taskRes.status === 200 || taskRes.status === 201) {
        let req = {
          routeDate: date,
          isOptimized: false,
          user: {
            id: this.auth.getUserId(),
          },
        };
        
        // Optimize the route for the task
        this.tasksService.createRouteOptimization(req).subscribe((res: any) => {
          if (res.status === 200 || res.status === 201) {
            this.modalRef.hide(); // Hide the modal
    
            this.errorUtil.setErrorMessage(
              200,
              null,
              this.translate?.general.messages.savedSuccessfully,
              "success",
              3000
            );
            this.spinner.hide(); // Hide the loading spinner
            this.submitTouched = false; // Reset the submitTouched flag
    
            this.util.openTaskVIewPage(taskRes.body.id); // Open the task view page
          } else {
            this.spinner.hide(); // Hide the loading spinner
            this.submitTouched = false; // Reset the submitTouched flag
          }
        });
      }
    });
  }


 getLanguageData() {
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
  console.log("translate",this.translate)
}

push(obs:any) {
  super.push(obs);
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}

}
