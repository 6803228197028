
import {of as observableOf,  Subject, Observable, throwError } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthenticationService {


    public STRADM:string="admin";
    public STRAGN:string="agent";

    authorities=[];

    constructor ( private http: HttpClient) { }

    public getToken (): string {
        return localStorage.getItem( 'jwt_token' )  || '';
    }

    public static getToken2 (): string {
        return localStorage.getItem( 'jwt_token' )  || '';
    }

    public getRefreshToken (): string {
        return localStorage.getItem( 'refresh_token' )  || '';
    }

    public isAuthenticated ():boolean {
        var token= localStorage.getItem( 'jwt_token' );
        return token!=null;
    }


    public getAuthRole ():string | null {
        var type= localStorage.getItem('role_token');
        return type;
    }

    public getUserId ():string {
        var type= atob(localStorage.getItem('profile_id')  || '');
        return type;
    }

    public getUsername ():string {
        var name = atob(localStorage.getItem('user_name')  || '');
        return name;
    }

    public getFullName ():string | null {
        var name = localStorage.getItem('full_name');
        return name;
    }

    public getProfileInfo ():string {
        var profileItem = atob(localStorage.getItem('profile_info')  || '');
        return profileItem;
    }

    public getSupervisor ():string | null {
        var supervisor = localStorage.getItem('superVisor');
        return supervisor;
    }

    public getTenantId ():string {
        var id= JSON.parse(atob(localStorage.getItem('profile_info')  || '')).tenantId;
        return id;
    }
    public getloginUser ():string {
        var id= JSON.parse(atob(localStorage.getItem('profile_info')  || '')).login;
        return id;
    }

    public getShowTour ():string | null {
        var tour = localStorage.getItem('showTour');
        return tour;
    }

    public isAdmin():boolean{

        return this.getAuthRole()==btoa(this.STRADM);
    }

    public isAgent():boolean{

        return this.getAuthRole()==btoa(this.STRAGN);
    }

    public getHomeAddress ():any{
         if(localStorage.getItem('home_address')){
        var address = JSON.parse(localStorage.getItem('home_address')  || '');
        return address;
         }
    }

    public getOfficeAddress ():any {
        console.log(localStorage.getItem('office_address') )
        if(localStorage.getItem('office_address')){
        var address = JSON.parse(localStorage.getItem('office_address')  || '');
        return address;
        }
    }

    public getUserBuffer ():string |null {
        var buffer = localStorage.getItem('buffer');
        return buffer;
    }

    public getUserTimezone ():string{
        var timezone = localStorage.getItem('timezone') || '';
        return timezone;
    }



    public addonFeature ():string |null {
        var addonFeature = localStorage.getItem('addonFeature');
        return addonFeature;
    }
    public menuType ():string |null {
        //var menuType = localStorage.getItem('menuType');
        var menuType = sessionStorage.getItem('menuType');
        return menuType;
    }
    public menuPermissions ():any |null {
        var menuPermissions = JSON.parse(localStorage.getItem('menuPermissions')  || '');
        return menuPermissions;
    }
    public shiftWorking ():string |null {
        var shiftWorking = localStorage.getItem('shiftWorking');
        return shiftWorking;
    }
    public allowAssignShift ():string | null{
        var allowAssignShift = localStorage.getItem('allowAssignShift');
        return allowAssignShift;
    }
    public addonFeatureList ():string |null {
        var addonFeatureList = JSON.parse(localStorage.getItem('addonFeatureList')  || '');
        return addonFeatureList;
    }

    public can(module:string, action:string):boolean{
        var userType= atob(this.getAuthRole()  || '');

        var can=false;
        can=this._can(userType,module,action);


        return can;
    }

    private _can(userType:string, module:string,action:string){
        var can=false;
        var permissions : any[] = [JSON.parse(localStorage.getItem('permissions')  || '')];
        var roles = permissions.filter(per=>{
            return per.role==userType;
        }).map(res=>{
            return res.roles;
        });

        for(let r of roles){
            for(let r1 of r){
                ////console.log(r1);
                if(r1.moduleName==module){
                    can=r1[action];
                }

            }
        }
        permissions = [];

        return can;
    }

    getAddonFeatureLists() {

          var addonFeatureLists = this.addonFeatureList();
          //console.log(addonFeatureLists,"===")
          var addonFeatureList =_.filter(addonFeatureLists, (o) => {return o == 'INVENTORY';});
          
          //SLA ========================================================
          var addonFeatureListSLA =_.filter(addonFeatureLists, (o) => { return o == 'SLA'; });
          //TTL ========================================================
          var addonFeatureListTTL =_.filter(addonFeatureLists, (o) => { return o == 'TTL_QC'; });//remove
           //Report ========================================================
           var addonFeatureReports =_.filter(addonFeatureLists, (o) => { return o == 'REPORTS'; });
          //TOUCHANDGO ========================================================
            var addonFeatureTOUCHANDGO =_.filter(addonFeatureLists, (o) => { return o == 'TOUCHANDGO'; });//remove
          //MAGNUM ========================================================
           var addonFeatureMAGNUM =_.filter(addonFeatureLists, (o) => { return o == 'MAGNUM'; });
           //HILLS ========================================================
           var addonFeatureHILLS =_.filter(addonFeatureLists, (o) => { return o == 'HILLS'; });
           //CHUBB ========================================================
           var addonFeatureCHUBB =_.filter(addonFeatureLists, (o) => { return o == 'CHUBB'; });
           //CHUBB ========================================================
           var addonFeatureSNL =_.filter(addonFeatureLists, (o) => { return o == 'SNL_TENANT'; });//remove
           //SWAP ASIA ========================================================
           var addonFeatureSWAPASIA =_.filter(addonFeatureLists, (o) => { return o == 'SWAP_ASIA'; });//remove
           //DYNAMIC MAINTENANCE ========================================================
           var addonFeatureDYNAMICMAINTENANCE =_.filter(addonFeatureLists, (o) => { return o == 'DYNAMIC_MAINTENANCE'; });
           //COFFEX ========================================================
           var addonFeatureCOFFEX =_.filter(addonFeatureLists, (o) => { return o == 'COFFEX'; });
           //PSS ========================================================
           var addonFeaturePSS =_.filter(addonFeatureLists, (o) => { return o == 'PSS'; });//remove
           //AGRIBIO ========================================================
           var addonFeatureAGRIBIO =_.filter(addonFeatureLists, (o) => { return o == 'AGRIBIO'; });
           //MACHINE_SPECIALIST ========================================================
           var addonFeatureMS =_.filter(addonFeatureLists, (o) => { return o == 'MACHINE_SPECIALIST'; });
            //CHECKLIST_JAVA ========================================================
            var addonFeatureChecklist =_.filter(addonFeatureLists, (o) => { return o == 'CHECKLIST_JAVA'; });//remove

            //STERRA ========================================================
            var addonFeatureSTERRA =_.filter(addonFeatureLists, (o) => { return o == 'STERRA'; });

            //SURVEY ========================================================
            var addonFeatureSURVEY =_.filter(addonFeatureLists, (o) => { return o == 'SURVEY'; });

             //PROJECT ========================================================
             var addonFeaturePROJECT =_.filter(addonFeatureLists, (o) => { return o == 'PROJECT'; });

              //DYNAMIC_FORM ========================================================
              var addonFeatureDF =_.filter(addonFeatureLists, (o) => { return o == 'DYNAMIC_FORM'; });
            
              //REQUSITION ========================================================
            var addonFeatureREQUSITION =_.filter(addonFeatureLists, (o) => { return o == 'REQUISITION'; });

              //DYNAMIC_LABEL ========================================================
              var addonFeatureLABEL =_.filter(addonFeatureLists, (o) => { return o == 'LABEL'; });
           

          //console.log(addonFeatureList)
            var addonFeatureData={
                addonINVENTORY : addonFeatureList[0] ? addonFeatureList[0]: null,
                addonSLA: addonFeatureListSLA[0] ? addonFeatureListSLA[0]: null,
                addonTTL: addonFeatureListTTL[0] ? addonFeatureListTTL[0]: null,
                addonReports: addonFeatureReports[0] ? addonFeatureReports[0]: null,
                addonTOUCHANDGO: addonFeatureTOUCHANDGO[0] ? addonFeatureTOUCHANDGO[0]: null,
                addonMAGNUM: addonFeatureMAGNUM[0] ? addonFeatureMAGNUM[0]: null,
                addonHILLS: addonFeatureHILLS[0] ? addonFeatureHILLS[0]: null,
                addonCHUBB: addonFeatureCHUBB[0] ? addonFeatureCHUBB[0]: null,
                addonSNL: addonFeatureSNL[0] ? addonFeatureSNL[0]: null,
                addonSWAPASIA: addonFeatureSWAPASIA[0] ? addonFeatureSWAPASIA[0]: null,
                addonDYNAMICMAINTENANCE: addonFeatureDYNAMICMAINTENANCE[0] ? addonFeatureDYNAMICMAINTENANCE[0]: null,
                addonCOFFEX: addonFeatureCOFFEX[0] ? addonFeatureCOFFEX[0]: null,
                addonPSS: addonFeaturePSS[0] ? addonFeaturePSS[0]: null,
                addonAGRIBIO: addonFeatureAGRIBIO[0] ? addonFeatureAGRIBIO[0]: null,
                addonMS: addonFeatureMS[0] ? addonFeatureMS[0]: null,
                addonChecklist: addonFeatureChecklist[0] ? addonFeatureChecklist[0]: null,
                addonSTERRA: addonFeatureSTERRA[0] ? addonFeatureSTERRA[0]: null,
                addonSURVEY: addonFeatureSURVEY[0] ? addonFeatureSURVEY[0]: null,
                addonPROJECT: addonFeaturePROJECT[0] ? addonFeaturePROJECT[0]: null,
                addonDF: addonFeatureDF[0] ? addonFeatureDF[0]: null,
                addonREQUSITION: addonFeatureREQUSITION[0] ? addonFeatureREQUSITION[0]: null,
                addonLABEL: addonFeatureLABEL[0] ? addonFeatureLABEL[0]: null,
            }
            return addonFeatureData;
        // this.storeLocation = new StoreLocation(this.holder);
        // //  this.storeLocation._setAirport('aa');
        // //console.log(this.storeLocation);
    
      }
      public setCompanyName(name : any) {
        localStorage.setItem("org_home", name);
      }
    
      public getCompanyName() {
        return localStorage.getItem("org_home");
      }

    public loginAgent (body:any) {

        let h = new Headers(
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            } );
        const options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            })
             }

        //let body = `username=${ username }&password=${ password }`;
        return this.http.post( environment.base_url+"/agent/authenticate", body, options ).pipe(tap( (resp:any) => {
            ////console.log(resp);
            if ( resp ) {
            localStorage.setItem( 'jwt_token', resp.json().id_token );
            localStorage.setItem( 'role_token', btoa(this.STRAGN) );
            //localStorage.setItem( 'refresh_token', resp.json().refresh_token );
            }
        } ),catchError( error => {
            return observableOf( false );
        } ),);
    }

    public loginAdmin (body:any) {

        let h = new HttpHeaders(
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        );
        const options =  { 
            headers: h} ;
        return this.http.post( environment.base_url+"/authenticate", body, options).pipe(tap( (resp: any) => {
            //console.log(resp.id_token)
            if ( resp ) {
            localStorage.setItem( 'jwt_token', resp.id_token );
            }
        } ),catchError( error => {
            return throwError( error );
        } ),);
    }

    public getTocketWYN(body:any){

    return this.http.post( environment.base_url+"/authenticate/wyn", body).pipe(tap( (resp: any) => {
    console.log(resp,"------",resp.access_token)
        if ( resp!='' ) {
          localStorage.setItem( 'access_token', resp.access_token );
        //   localStorage.setItem( 'role_token', btoa(this.STRADM) );
          //localStorage.setItem( 'refresh_token', resp.json().refresh_token );
        }
    } ),catchError( error => {
        return observableOf( error );
    } ),);

    }

}
