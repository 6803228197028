<!-- <div class="modal-content modal-fullscreen p-0"> -->
<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">
        <span class="text-16 font-medium ms-3" id="title_SetTixFormat">{{translate?.ticket.label.singular}}&nbsp;{{translate?.setup.modules.configuration.fields.format.singular}}</span>
    </div>
    <i class="fa-regular fa-xmark" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" id="btn_SetTixFormatClose"></i>
</div>
<div class="modal-body tix height-80">
    <div class="m-0 pb-3 px-2">
        <span class="text-12 d-block font-semiBold" id="label_SetTixFormat" >{{translate?.setup.modules.configuration.messages.whatIs}}&nbsp;{{translate?.ticket.label.singular}}&nbsp;{{translate?.setup.modules.configuration.fields.format.singular}}&quest;</span>
        <span class="text-12" id="value_SetTixFormat" >{{translate?.ticket.label.singular}}&nbsp;{{translate?.setup.modules.configuration.fields.format.singular}}&nbsp;{{translate?.setup.modules.configuration.messages.allowsCustomize}}</span>
    </div>
    <div>
        <p-table [value]="tix" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
                <tr class="">
                    <th class="col-2" id="label_SetTixVar">{{translate?.setup.modules.configuration.sections.sec2.singular}}</th>
                    <th class="col-2" id="label_SetTixNec">{{translate?.setup.modules.configuration.sections.sec3.singular}}</th>
                    <th class="col-2" id="label_SetTixDesc">{{translate?.setup.modules.configuration.sections.sec4.singular}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tix>
                <tr class="text-12 font-medium">
                    <td class="col-2" id="value_SetTixVar">{{tix.variable}}</td>
                    <td class="col-2 text-left" id="value_SetTixNec">{{tix.necessity}}</td>
                    <td class="col-2 text-left" id="value_SetTixDesc">{{tix.desc}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="pt-2">
        <div class="m-1"><span class="text-12 font-medium" id="label_SetTixEg">{{translate?.setup.modules.configuration.messages.hereExamples}}</span></div>
        <p-table [value]="tix2" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
                <tr class="">
                    <th class="col-2" id="label_SetTixFormat">{{translate?.ticket.label.singular}}&nbsp;{{translate?.setup.modules.configuration.fields.format.singular}}</th>
                    <th class="col-2" id="label_SetTixSeqNo">{{translate?.setup.modules.configuration.sections.sec6.singular}}</th>
                    <th class="col-2" id="label_SetTixFormatNo">{{translate?.setup.modules.configuration.sections.sec7.singular}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tix2>
                <tr class="text-12 font-medium">
                    <td id="value_SetTixFormat">{{tix2.format}}</td>
                    <td class="col-2 text-left">
                        <tr id="value_SetTixSeqNo1">{{tix2.seq1}}</tr>
                        <tr id="value_SetTixSeqNo2">{{tix2.seq2}}</tr>    
                    </td>
                    <td class="col-2 text-left">
                        <tr id="value_SetTixFormatNo1">{{tix2.number1}}</tr>
                        <tr id="value_SetTixFormatNo2">{{tix2.number2}}</tr>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>


</div>
<!-- </div> -->