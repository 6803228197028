import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ApiService } from 'src/app/_services/api.service';
import { ProfileFeaturesService } from 'src/app/_services/profile-features.service';
import { SignUpService } from 'src/app/_services/sign-up.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent  extends SubscriptionUtil  implements OnInit, OnDestroy {

  submitTouched: boolean = false;
  password: any = {};
  passwordForm!: UntypedFormGroup;
  params: any;
  data: any;
  data1: any;
  type: any;
  isPasswordMatching!: boolean;
  showtooltip!: boolean;
  fieldTextType!: boolean;
  fieldTextType2!: boolean;
  fieldTextType3!: boolean;
  isPasswordHasUserName: boolean=false;
  userName: any ;
  translate: any;
  constructor(
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private apiService: ApiService,
    private profileService: ProfileFeaturesService,
    public modalRef: BsModalRef,
    private signUpService: SignUpService,
    private errorUtil: ErrorUtil,    
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    
  }

  ngOnInit() {
    console.log(this.data)
    this.getLanguageData()
    this.passwordForm = new UntypedFormGroup({
      'oldPassword': new UntypedFormControl(null, [Validators.required]),
      'password': new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.strongPasswordRegx), this.passwordValidator]),
      'cPassword': new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.strongPasswordRegx)]),
    });
    ///this.data.email ? this.userEmail = this.data.email : this.userEmail = this.data1.email
    this.userName = this.data
    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.params = params;
      } else {
        this.params = { id: this.auth.getUserId() };
      }
    });
  }

  changePassword() {
    this.submitTouched = true;
    if (!this.passwordForm.valid) {
      for (var i in this.passwordForm.controls) {
        this.passwordForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }
    if (this.password.password !== this.password.cPassword) {
      this.errorUtil.setErrorMessage(
        "400",
        null,
        this.translate?.setup.users.users.messages.invalidCredentials,
        2000
      );
      this.submitTouched = false;
      return false;
      ///////  this.toaster.warn("", "Passwords do not match. Please try again");
    } 
    
    if (this.isPasswordHasUserName) {
      this.errorUtil.setErrorMessage(
        "400",
        null,
        this.translate?.setup.users.users.messages.weakPassword,
        "warn",
        2000
      );
      this.submitTouched = false;
      return false;
      ///////  this.toaster.warn("", "Passwords do not match. Please try again");
    }
    else {
      if (this.params.id == this.auth.getUserId()) {
        this.apiService.getProfile().subscribe((res: any) => {
          var formData = {
            login: res.login,
            newPassword: this.password.password,
            oldPassword: this.password.oldPassword
          }
          this.profileService.changePassword(formData).subscribe((res: any) => {
            //console.log("Response ", res);
            if (res.status === 200 || res.status === 201) {
              this.password = {};
              this.errorUtil.setErrorMessage(200, this.translate?.general.messages.updatedSuccessfully, null, 'success', 1000);
              ///////  this.toaster.success('', 'Password updated succesfully');
              this.modalRef.hide();
            } else {
              ////////  this.toaster.error("", res.error.message);
              this.errorUtil.setErrorMessage(400, null, res.error.message, 'error', 3000);
            }
          });
        });
      }

    }
  }

  onSubmit(type: any) {
    this.submitTouched = true;
    if (type == "reset" && this.type != "resendCustomerUserEmail") {
      var formData: any = {
        login: this.data
      }
      this.signUpService.forgotPassword(formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200,  this.translate?.setup.users.users.messages.passwordResetEmailSent, null, 'success', 1000);
          /////  this.toaster.success('', 'An email has been sent to help you reset your password');
        } else {
          this.submitTouched = false;
          var body = JSON.parse(res._body);
          this.errorUtil.setErrorMessage(400, null, body.title, 'error', 3000);
          ////// this.toaster.error("", body.title);
        }
      });
    }
    else if (this.type == "resendCustomerUserEmail") {
      var formData: any = this.data
      this.signUpService.resendCustomerUserEmail(formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200, this.translate?.setup.users.users.messages.accountActivationEmailSent, null, 'success', 1000);
          ////// this.toaster.success('', 'An email has been sent to you to activate the account');
        } else {
          this.submitTouched = false;
          var body = JSON.parse(res._body);
          this.errorUtil.setErrorMessage(400, null, body.title, 'error', 3000);
          /////  this.toaster.error("", body.title);
        }
      });
    }
    else {
      var formData: any = this.data
      this.signUpService.resendEmail(formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          this.modalRef.hide();
          //////this.toaster.success('', 'An email has been sent to you to activate the account');
          this.errorUtil.setErrorMessage(200, this.translate?.setup.users.users.messages.accountActivationEmailSent, null, 'success', 1000);
        } else {
          this.submitTouched = false;
          var body = JSON.parse(res._body);
          this.errorUtil.setErrorMessage(400, null, body.title, 'error', 3000);
          /////this.toaster.error("", body.title);
        }
      });
    }
  }

  /******************************************** Confirm Password Length ******************************************/
  public getCPassowrdLength(cPassword: any) {
    const value = cPassword.target.value;

    if (this.password.password !== this.password.cPassword) {
      this.isPasswordMatching = false;
    }
    if (this.password.password == this.password.cPassword) {
      this.isPasswordMatching = true
    }
  }

  public getPassowrdLength(password: any) {
    const value = password.target.value;
    console.log(this.userName, "====", value, "====", this.passwordForm.get('password')?.valid)
    const substringLower = value.toLowerCase();
    let matchFound = false;

    if(this.isPasswordHasUserName)
    {console.log("has 1")
      if(substringLower && substringLower.length > 4 && this.userName && this.userName.indexOf(substringLower) !== -1)
       {
        if(substringLower && substringLower.length > 5 && this.userName && this.userName.indexOf(substringLower) == -1)
        {
        this.isPasswordHasUserName = true;
        }
       }
       else if(substringLower && substringLower.length < 5){
        this.isPasswordHasUserName = false;
       }
    }
    else{
      if(substringLower && substringLower.length > 5 && this.userName && this.userName.indexOf(substringLower) !== -1){
        this.isPasswordHasUserName = true;
       }
    }

    if (this.passwordForm.get('password')?.valid == false) {
      this.showtooltip = true;
    }
    else {
      this.showtooltip = false;
    }
  }

  onClickBackground() {
    console.log("click", this.passwordForm.get('password')?.valid)
    if (this.passwordForm.get('password')?.valid == true) {
      this.showtooltip = false;
    }
  }

  get passwordFormField() {
    return this.passwordForm.get('password');
  }

  
  showPassword() {
    this.fieldTextType = !this.fieldTextType;
  }
  showNewPassword() {
    this.fieldTextType2 = !this.fieldTextType2;
  }
  showConfirmPassword() {
    this.fieldTextType3 = !this.fieldTextType3;
  }

  passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!this.isPasswordHasUserName) {
      return null; // No error if password dose not have username
    }
    else {
      //alert('invalid')
      return { dynamicError: { message: 'Please enter a valid value.' } };
    }
  };

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.setup.users.users.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
