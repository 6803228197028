import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChubbDashboardAppEvents } from "src/app/_models/global.data.model";
import { ChubbServicesService } from "src/app/_services/chubb-services.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-chubb-team-status",
  templateUrl: "./chubb-team-status.component.html",
  styleUrls: ["./chubb-team-status.component.scss"],
})
export class ChubbTeamStatusComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Input() isSlideshowOn = false;

  public teamCharts = {
    selectedRegion: "Central",
    selectedTimeframe: "daily",
    regions: <any[]>[],
    timeFrames: <any[]>[],
    teams: <any>[],
    allStatus: <any[]>[],
    chartConfig: {
      view: <any>[150, 150],
      legend: false,
      isDonut: false,
      legendTitle: "",
    },
  };
  translate: any;

  constructor(
    private router: Router,
    private chubbService: ChubbServicesService,
    private utilService: UtilServiceService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService
  ) {
    super();
    this.push(  this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    }))
    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.teamCharts.allStatus = this.chubbService.getAllStatus();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.teamCharts.regions = this.chubbService.getRegions();
    this.teamCharts.timeFrames = this.chubbService.getTimeFrames("noYear");

    this.push(
      this.utilService.getData().subscribe((data: any) => {
        if (data && data.action === ChubbDashboardAppEvents.REFRESH_PANEL_TWO) {
          this.getData();
        }
      })
    );

    this.getData();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Get Data ******************************************/
  private getData() {
    const params = {
      region: encodeURIComponent(this.teamCharts.selectedRegion),
      duration: this.teamCharts.selectedTimeframe,
    };

    this.push(
      this.chubbService.getAllTeamTasksStatus(params).subscribe(
        (resp) => {
          let allTeams = resp;

          const finalTeams: any[] = [];

          if (allTeams.length > 8) {
            allTeams = allTeams.splice(0, 8);
          }

          for (let aT = 0; aT < allTeams.length; aT++) {
            const teamChartData = {
              id: allTeams[aT].id,
              name: allTeams[aT].name,
              data: <any>[],
              colorScheme: {
                domain: <any[]>[],
              },
            };

            const teamCharts = allTeams[aT].chart;

            for (let aS = 0; aS < this.teamCharts.allStatus.length; aS++) {
              for (let tc = 0; tc < teamCharts.length; tc++) {
                const statusLabel = this.teamCharts.allStatus[aS]
                  .status as string;

                if (statusLabel === teamCharts[tc].name) {
                  const colorCode = this.teamCharts.allStatus[aS].colorCode;

                  // add the status to color scheme
                  teamChartData.colorScheme.domain.push(colorCode);
                  const dataValue = {
                    name: teamCharts[tc].name,
                    value: teamCharts[tc].value,
                    colorCode,
                    order: teamCharts[tc].order,
                  };

                  teamChartData.data.push(dataValue);
                }
              }
            }

            finalTeams.push(teamChartData);
          }

          this.teamCharts.teams = finalTeams.sort(
            (teamA, teamB) => teamA.order - teamB.order
          );
        },
        (err) => {
          //this.spinner.hide()
         const title = this.errorUtil.processErrorTitle(err.error,this.translate)
         this.errorUtil.setErrorMessage(400, null ,  title, 'error',3000);
        
      }
      )
    );
  }

  /******************************************** Navigate To Job Status By Team Page ******************************************/
  public navigateTo(team: any) {
    const id = team.id;
    const name = team.name;

    this.chubbService.setTempData({
      action: ChubbDashboardAppEvents.DATA_PASS_BY,
      data: {
        current: {
          duration: this.teamCharts.selectedTimeframe,
        },
      },
    });

    this.router.navigate([`/dashboard/chubb/jobsStatusByTeam/${id}/${name}`]);
  }

  /******************************************** Update Date ******************************************/
  public updateData($event: any) {
    this.getData();
  }
   
  push(obs:any) {
    super.push(obs);
  }
}
