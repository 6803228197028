import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerSurveyService } from 'src/app/_services/customer-survey.service';
import { Model, SurveyNG, StylesManager } from "survey-angular";

@Component({
  selector: 'app-preview-customer-survey',
  templateUrl: './preview-customer-survey.component.html',
  styleUrls: ['./preview-customer-survey.component.scss']
})
export class PreviewCustomerSurveyComponent implements OnInit {
  csId: any;
  csDetail: any;
   surveyJson:any =  { };
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private customerSurveyService: CustomerSurveyService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      if (params.csid) {
        this.csId = params.csid
        this.loadData()        
      }
  });

  }

loadData(){
    this.customerSurveyService.getBuildSurveyById(this.csId).subscribe((res:any) => {
      this.csDetail = res;
      if(this.csDetail){
        this.surveyJson= this.csDetail.content
      }
      this.initSurveyCreator()
      this.spinner.hide();
     
    })
}
 initSurveyCreator(){
      StylesManager.applyTheme("modern");

          const survey = new Model(this.surveyJson);
          survey.focusFirstQuestionAutomatic = false;

          survey.onComplete.add(this.alertResults);
          SurveyNG.render("surveyContainer", { model: survey });
}

  alertResults(sender: any) {
    const results = JSON.stringify(sender.data);
    alert(results);
  }


}
