<!-- TabMenu -->
<div class="position-relative col-12 d-flex h-100-28">
    <div class="ag-grid-table-full" [ngClass]="{ ' ag-grid-table': isFilterToggleOn }">
        <ag-grid-angular
            #agGrid
            class="ag-theme-balham h-100"
            [suppressDragLeaveHidesColumns]="true"
            [allowContextMenuWithControlKey]="false"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [maxConcurrentDatasourceRequests]="-1"
            [getContextMenuItems]="getContextMenuItems"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="viewData($event)"
            (gridReady)="onGridReady($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            (cellValueChanged)="onCellValueChanged($event)"

        >
        </ag-grid-angular>
        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_JobTableRec">
                {{ totalRecord }}&nbsp;{{translate?.general?.messages.records}}
            </p>
        </div>
    </div>

    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': isFilterToggleOn }">
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="label_JobOrdersFilter">{{ translate?.general?.messages.filterBy }}</span>
            <i  class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" (click)="filterToggle()" id="btn_JobOrdersClose"></i>
        </div>
        <div class="custella-content">
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_JobOrdersDateRange">{{ translate?.jobOrder?.fields.orderDate.singular }}</span>
                <span class="p-input-icon-right w-100">
                    <i class="pi pi-calendar" style="z-index: 1;"></i>
                    <p-calendar class="custella-calendar" [(ngModel)]="dateRange" (ngModelChange)="onChangeDate()" id="picklist_JobOrdersDate" [placeholder]="translate?.general.messages.none" selectionMode="range" dateFormat="dd/mm/yy" yearRange="2000:2030" [yearNavigator]="true" [showButtonBar]="false"appendTo="body"></p-calendar>
                </span>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_JobOrdersStatus">{{ translate?.jobOrder?.fields.status.singular }}</span>
                <p-dropdown name="status" [(ngModel)]="filter.status" (onChange)="filterResults($event)" id="picklist_JobOrdersStatus" [options]="statuses" [showClear]="true" [placeholder]="translate?.general.messages.none" optionLabel="fieldLabel" optionValue="name" [filter]="true">
                </p-dropdown>
            </div>

            <!-- DON NOT KEEP REMOVING! -->
            <!-- <div class="custella-content-row-input mb-3 col-12" *ngIf="accessFrom !== 'customer'">
                <span class="custella-content-row-input-title">{{ "customer"  }}</span>
                <p-dropdown
                    class="customerInput"
                    [options]="filteredCustomers"
                    optionLabel="name"
                    optionValue="id"
                    [filter]="true"
                    filterBy="name"
                    [showClear]="true"
                    [placeholder]="'enter_customer_name' "
                    (onFilter)="onFilter($event)"
                    (onChange)="filterResults($event)"
                    [(ngModel)]="filter.customerId"
                >
                    <ng-template let-customer pTemplate="item">
                        <div class="country-item">
                            <div>{{ customer.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div> -->

            <div class="custella-content-input col-12" *ngIf="accessFrom !== 'customer'">
                <span class="custella-content-input-title" id="label_JobOrdersCustomer" >{{ translate?.customer?.label.singular }}</span>
                <div class="p-inputgroup search">
                    <span class="p-input-icon-right w-100">
                        <p-autoComplete
                        (onSelect)="onCustomerSelect($event)"
                        field="name"
                        [suggestions]="customers"
                        (completeMethod)="getCustomer($event)"
                        [(ngModel)]="selectedCustomer"
                        [placeholder]="translate?.general.messages.none"
                        id="picklist_JobOrdersCustomer"
                        styleClass="w-100"
                        >
                        </p-autoComplete>
                        <i *ngIf="selectedCustomer && selectedCustomer?.id" id="btn_JobOrdersCustomerClose" (click)="clearValue('customer')" class="p-autocomplete-clear-icon pi pi-times"></i>
                    </span>
                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.customer?.tooltips.customerLookup.singular" tooltipPosition="left" (click)="showTable('customer')" id="btn_JobOrdersSearch"></button>
                </div>
            </div>

            <div>
                <span href="#" class="clear" (click)="resetSearch()" id="subtitle_JobOrdersClear">{{translate?.general?.buttons.clearAll.singular }}</span>
            </div>
        </div>
    </div>
</div>

<!-- FOR CENTER IN PAGE -->
<footer *ngIf="showingTable.customer" class="custella-form-container-footer">
    <p-dialog [header]="translate?.customer.sections.sec5.singular" [(visible)]="showingTable.customer" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
        <app-custella-customers (selectCustomerEvent)="onCustomerSelect($event)" (closeCustomerEvent)="closeTable('customer')"></app-custella-customers>
    </p-dialog>
</footer>
