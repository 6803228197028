import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-custella-job-orders",
  templateUrl: "./custella-job-orders.component.html",
  styleUrls: ["./custella-job-orders.component.scss"],
})
export class CustellaJobOrdersComponent implements OnInit {
  @Output() itemSelector = new EventEmitter();
  @Output() closeView = new EventEmitter();
  @Input() lookupFilter: any =''

  public searchValue = "";
  translate: any;

  constructor(private jobOrdersService: JobOrdersService,
    private languageTranslateService: LanguageTranslateService
  ) {
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
    this.jobOrdersService.getSelectJobOrder().subscribe((data) => {
      if (data) {
        this.selectItem(data);
      }
    });
  }

  ngOnInit(): void {}

  selectItem(val: any) {
    this.itemSelector.emit(val);
  }

  public closeTable() {
    this.closeView.emit(false);
  }
}
