import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicTableColumnsService } from 'src/app/_services/dynamic-table-column.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { PermissionService } from 'src/app/auth/permission.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-user-list-view',
  templateUrl: './user-list-view.component.html'
})
export class UserListViewComponent    extends SubscriptionUtil implements OnInit, OnDestroy {
  default!: any[]
  edit: boolean = false;
  confirmYes: boolean = false;

  @Input() profileId:any
  @Input() profile: any
  profileTableColumnData: any
  selectedData: any;
  menuType: any;
  module!: boolean;

  salePer!: boolean;
  servicePer!: boolean;
  fleetPer!: boolean;
  translate: any;

  constructor(
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private perm: PermissionService,
    private auth: AuthenticationService,
    private router: Router,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();}

    ngOnInit(): void {

    this.getLanguageData() 
      this.module = this.perm.capable('manage module', 'read');
      var profileInfo = JSON.parse(this.auth.getProfileInfo());
    this.findMenuPermissions(profileInfo.tenantCategories);
    this.getAccessTableColumnSetups()
    }
   /******************************************** Load Default Table template ******************************************/
   public getAccessTableColumnSetups() {
    this.dynamicTableColumnsService.getProfileTableColumnSetups(this.profileId).subscribe((res: any) => {
      this.profileTableColumnData = res;
      this.profileTableColumnData.forEach((element:any) => {
        element.dropdownShow= false
        element.selectedTemp= element.template ?  element.template.id:null
        this.radioChange(element)
      });
    
    });
    
  }
  /******************************************** Radio Change ******************************************/
  public radioChange(element:any) {
    if (this.profile.profile.defaultTenantCategory === "SALES") {
      this.profile.profile.sales = true;
    } else if (this.profile.profile.defaultTenantCategory === "SERVICE") {
      this.profile.profile.service = true;
    } else if (this.profile.profile.defaultTenantCategory === "FLEET") {
      this.profile.profile.fleet = true;
    }
    this.chkChange(element);
  }

  editView(index: any){
    console.log(this.profileTableColumnData[index])
    this.profileTableColumnData[index].dropdownShow = !this.profileTableColumnData[index].dropdownShow
  }

  showConfirm(index:any){
    this.confirmYes = true;
    this.selectedData = this.profileTableColumnData[index]
    this.approveForAll()
  }
  cancel() {
    this.confirmYes = false;
  }
  approveForAll(){
    console.log(this.selectedData)
    var req = {
      "id": this.selectedData.id,
      "templateId": this.selectedData.selectedTemp,
      "apiModule": this.selectedData.apiModule,
      "userId": this.profileId
}
    this.dynamicTableColumnsService.saveUsersTableTemplate(req).subscribe((res:any) => {
      this.spinner.hide();
    
          console.log(res)
           this.errorUtil.setErrorMessage(200, this.translate?.general.messages.updatedSuccessfully ,  null, 'success',1000);
            this.cancel()
            this.getAccessTableColumnSetups()
          
        }, err => {
      this.spinner.hide();
    
        })
  }

  public chkChange(element: any) {
    
      element.moduleAccessShow = false;
      for (var i = 0; i <= this.profile.moduleAccessFeatures.length - 1; i++) {
        if (this.profile.moduleAccessFeatures[i].feature.name === "Customers" && element.module == "Customer" && this.profile.moduleAccessFeatures[i].readFeature ) {
          if ( (this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer) ) {
            element.moduleAccessShow = true;
          } 
          element.url = '/customer/listView';
         // console.log(element,"=====>>>>",this.profile.moduleAccessFeatures[i],"===",this.profile.profile.sales,"===",this.profile.profile.sales)
        
        } else if (
          this.profile.moduleAccessFeatures[i].feature.name === "Sales Order" && element.module == "Sales Order"  && this.profile.moduleAccessFeatures[i].readFeature) {
            if ( (this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer) ) {
            element.moduleAccessShow = true;
          } 
          element.url = '/salesOrder/listView';
        } else if (
          this.profile.moduleAccessFeatures[i].feature.name === "Job Order" && element.module == "Job Order" && this.profile.moduleAccessFeatures[i].readFeature) {
            if ( (this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer) ) {
            element.moduleAccessShow = true;
          }
          element.url = '/jobOrder/listView';
        } else if (
          this.profile.moduleAccessFeatures[i].feature.name === "Products" && element.module == "Product" && this.profile.moduleAccessFeatures[i].readFeature ) {
    
          if ( (this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer) ) {
            element.moduleAccessShow = true;
          } 
          element.url = '/products/listView';
        } else if (this.profile.moduleAccessFeatures[i].feature.name === "Tasks" && element.module == "Task"  && this.profile.moduleAccessFeatures[i].readFeature) {
        
          if ((this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer) ) {
            element.moduleAccessShow = true;
          }
          element.url = '/tasks/listView';
        }  else if ( this.profile.moduleAccessFeatures[i].feature.name === "Assets" && element.module == "Asset" && this.profile.moduleAccessFeatures[i].readFeature) {
          if ((this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer)
          ) {
            element.moduleAccessShow = true;
          } 
          element.url = '/asset/listView';
        } else if (this.profile.moduleAccessFeatures[i].feature.name === "Asset Contracts" && element.module == "Contract" && this.profile.moduleAccessFeatures[i].readFeature) {
          if ((this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer)) {
            element.moduleAccessShow = true;
          } 
          element.url = '/contracts/listView';
        } else if (this.profile.moduleAccessFeatures[i].feature.name === "Scheduled Maintenance" && element.module == "Preventive Maintenance" && this.profile.moduleAccessFeatures[i].readFeature) {
          if ((this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer)) {
            element.moduleAccessShow = true;
          } 
          element.url = '/preventiveMaintenance/listView';
       } else if (this.profile.moduleAccessFeatures[i].feature.name === "Tickets" && element.module == "Ticket" && this.profile.moduleAccessFeatures[i].readFeature) {
          if ((this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer)) {
            element.moduleAccessShow = true;
          } 
          element.url = '/ticket/listView';
        } else if (this.profile.moduleAccessFeatures[i].feature.name === "Site" && element.module == "Site" && this.profile.moduleAccessFeatures[i].readFeature) {
          if ((this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer) ) {
            element.moduleAccessShow = true;
          } 
          element.url = '/sites/listView';
        } else if (this.profile.moduleAccessFeatures[i].feature.name === "Project" && element.module == "Project" && this.profile.moduleAccessFeatures[i].readFeature) {
          if ( (this.profile.profile.sales && this.salePer) || (this.profile.profile.service && this.servicePer)) {
            element.moduleAccessShow = true;
          } 
          element.url = '/projects/listView';
        }
        
      }
    }

  redirectPage(element:any){
    this.router.navigate(['dashboard/setup/'+element.url])
  }
  /******************************************** Menu Permission ******************************************/
  private findMenuPermissions(tenantCategories: any) {
    var saleIndex = tenantCategories.indexOf("SALES");
    if (saleIndex != -1) {
      this.salePer = true;
    }
    var serviceIndex = tenantCategories.indexOf("SERVICE");
    if (serviceIndex != -1) {
      this.servicePer = true;
    }
    var fleetIndex = tenantCategories.indexOf("FLEET");
    if (fleetIndex != -1) {
      this.fleetPer = true;
    }
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.setup.users.users.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
