import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator'
})
export class ThousandSeparatorPipe implements PipeTransform {

  transform(value: number, locale?: string): string {
    if (isNaN(value)) {
      return 'Invalid number'; // Handle invalid input
    }
    const formattedValue = value.toLocaleString(locale || 'en-US', { useGrouping: true });
    return formattedValue;
  }
}