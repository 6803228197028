import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GetContextMenuItemsParams, GridOptions,IServerSideGetRowsParams, MenuItemDef } from 'ag-grid-community';
import "ag-grid-enterprise";  
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../../auth/authentication.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-search-customer-contact',
  templateUrl: './search-customer-contact.component.html',
  styleUrls: ['./search-customer-contact.component.css']
})
export class SearchCustomerContactComponent  extends SubscriptionUtil implements OnInit, OnDestroy {
  @Output() customerSelector = new EventEmitter();
  @Output() closeCustomerView = new EventEmitter();
  @Input() lookupFilter: any =''
  @Input() field: any =''
  searchValue: any='';
  customers: any;

  gridApi: any;
   gridColumnApi: any;
   columnDefs: any;
   defaultColDef: any;
   frameworkComponents: any;
   gridParams : any;
   startFrom: any;
   product: any;
   pageSize: any = 25;
   //rowData1: any[] = [];
   parameters: any;
   public gridOptions: Partial<GridOptions>;
  overlayNoRowsTemplate: string;
  noCustomersIcon!: boolean;
  totalRecord: any;
  translate: any;

  constructor(
    private customerService: CustomersService,
    private http: HttpClient,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'clientSide',
      sortingOrder : ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      }
    this.columnDefs = [
      { headerName: this.translate?.customer.fields.fieldExID.singular, field: 'customer.customerNumber', width: 200,cellClass: function (params:any) { return ['table_default_color'];}},
      { headerName: this.translate?.customer.label.singular+" " + this.translate?.customer.fields.name.singular, field: 'customer.name', width: 220,
        headerValueGetter: (params:any) => {
          const addonFeature = this.auth.addonFeature();
          return addonFeature==='TOUCHANDGO' ? 'Account Name' : 'Customer Name';
        }
      },
      { headerName: this.translate?.contacts.fields.contactName.singular, field: 'fullName',width: 220, sortable: true},
      { headerName: this.translate?.contacts.fields.phone.singular, field: 'phone',width: 130},
      { headerName: this.translate?.contacts.fields.email.singular, field: 'email',width: 200, headerClass: "marging-auto hide-action-border"},
       ];
    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
    };
    this.overlayNoRowsTemplate = "<span style=\"padding: 10px; font-size: 14px;\">Record not found</span>";

  }

  ngOnInit() {
    console.log(this.lookupFilter,"====",this.field)
    this.getContactData()
  }
  getContactData() {
    this.customers = []
    this.customerService.getAllCustomerByContact(this.field.lookupUrl,this.searchValue, this.lookupFilter).subscribe((data: any) => {
      console.log(data)
       this.customers = data;
       this.totalRecord = data.length
         if(this.customers.length === 0){
           this.gridApi.showNoRowsOverlay();
          }else{
          }
    
    
    
      })
  }

  quickSearch() {
    if (this.searchValue == "" || this.searchValue.length > 2) {
      this.gridApi.setQuickFilter(this.searchValue);
      this.totalRecord = this.gridApi.getDisplayedRowCount();
      if (this.totalRecord == 0) {
        this.gridParams.api.showNoRowsOverlay();
      } else {
        this.gridParams.api.hideOverlay();
      }
    }
   }
 
/********************************** Initilize Grid Table **************************/
onGridReady(params: any) {
  //console.log(params)
  this.gridParams = params;

  this.gridApi = params.api;
}

   viewCustomer(e :any) {
      this.selectCustomer(e.data)

   }

  selectCustomer(val:any){
    this.customerSelector.emit(val);
  }

  closeCustomer(){
    this.closeCustomerView.emit(false)
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    ///this.mainLabel = this.translate?.customer.label.plural
  }

    /******************************************** Add ALl the Subscription ******************************************/
    push(obs:any) {
      super.push(obs);
    }

    ngOnDestroy(): void {
      super.ngOnDestroy();
    }

}

