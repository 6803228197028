import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { _throw } from "rxjs/observable/throw";
import { catchError, map } from "rxjs/operators";
import { UtilServiceService } from "./utilService/util-service.service";
import { throwError } from "rxjs";

@Injectable()
export class ChubbServicesService {
  private endPoints = {
    baseUrl: environment.base_url + "/",
    subUrl: "chubb-dashboard/",

    taskByTeam: {
      url: "task-by-team",
      tableView: "table-view",
    },
    tasksStatus: {
      url: "task-status-by-team",
      tableView: "table-view",
    },
    technicians: {
      url: "technicians",
      joByTechnicians: "jo-by-technicians",
    },
  };

  public refreshJMP$: EventEmitter<any> = new EventEmitter();

  private tempData = {};

  constructor(
    private http: HttpClient,
    private utilService: UtilServiceService
  ) {}

  public getRegions() {
    return [
      {
        name: "Central",
        label: "Central",
      },
      {
        name: "Coastal Selangor",
        label: "Coastal Selangor",
      },
      {
        name: "Northern",
        label: "Northern",
      },
      {
        name: "Southern",
        label: "Southern",
      },
      {
        name: "Sabah & Sarawak",
        label: "Sabah & Sarawak",
      },
      {
        name: "East Coast",
        label: "East Coast",
      },
      {
        name: "All Nation",
        label: "All Nation",
      },
    ];
  }

  public getTimeFrames(whatType: string) {
    let allTimeFrames: any[] = [];

    if (whatType === "standard") {
      allTimeFrames = [
        {
          name: "daily",
          label: "Daily",
        },
        {
          name: "weekly",
          label: "Week to Date",
        },
        {
          name: "monthly",
          label: "Month to Date",
        },
        {
          name: "annually",
          label: "Year to Date",
        },
      ];
    }

    if (whatType === "noYear") {
      allTimeFrames = [
        {
          name: "daily",
          label: "Daily",
        },
        {
          name: "weekly",
          label: "Week to Date",
        },
        {
          name: "monthly",
          label: "Month to Date",
        },
      ];
    }

    return allTimeFrames;
  }

  public getAllStatus() {
    return [
      {
        status: "Open",
        label: "Open",
        fieldLabel: "Open",
        colorCode: "#B2B2B2",
        id: 1,
      },
      {
        status: "Pending",
        label: "Pending",
        fieldLabel: "Pending",
        colorCode: "#9485A8",
        id: 2,
      },
      {
        status: "In Progress",
        label: "In Progress",
        fieldLabel: "In Progress",
        colorCode: "#F9C145",
        id: 3,
      },
      {
        status: "Incomplete",
        label: "In Complete",
        fieldLabel: "In Complete",
        colorCode: "#DB7B29",
        id: 4,
      },
      {
        status: "Completed",
        label: "Completed",
        fieldLabel: "Complete",
        colorCode: "#78C16E",
        id: 5,
      },
      {
        status: "Cancelled",
        label: "Cancelled",
        fieldLabel: "Cancelled",
        colorCode: "#E07776",
        id: 6,
      },
    ];
  }

  public getTaskByTeam(params: any) {
    const urlParam = this.utilService.returnGeneratedParams(params);

    return this.http
      .get(
        this.endPoints.baseUrl +
          this.endPoints.subUrl +
          this.endPoints.taskByTeam.url +
          urlParam
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getAllTaskByTeam(params: any) {
    const urlParam = this.utilService.returnGeneratedParams(params);

    return this.http
      .get(
        this.endPoints.baseUrl +
          this.endPoints.subUrl +
          this.endPoints.taskByTeam.url +
          "/" +
          this.endPoints.taskByTeam.tableView +
          urlParam
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getAllTeamTasksStatus(params: any) {
    const urlParam = this.utilService.returnGeneratedParams(params);

    return this.http
      .get(
        this.endPoints.baseUrl +
          this.endPoints.subUrl +
          this.endPoints.tasksStatus.url +
          urlParam
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getAllTaskStatusByTeam(params: any) {
    const urlParam = this.utilService.returnGeneratedParams(params);

    return this.http
      .get(
        this.endPoints.baseUrl +
          this.endPoints.subUrl +
          this.endPoints.tasksStatus.url +
          "/" +
          this.endPoints.tasksStatus.tableView +
          urlParam
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getAllTechniciansByTeam(params: any) {
    const urlParam = this.utilService.returnGeneratedParams(params);

    return this.http
      .get(
        this.endPoints.baseUrl +
          this.endPoints.subUrl +
          this.endPoints.technicians.url +
          urlParam
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getJobOrdersByTechnician(params: any) {
    const urlParam = this.utilService.returnGeneratedParams(params);

    return this.http
      .get(
        this.endPoints.baseUrl +
          this.endPoints.subUrl +
          this.endPoints.technicians.joByTechnicians +
          urlParam
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public setTempData(data: any) {
    this.tempData = data;
  }

  public getTempData(): Promise<any> {
    return new Promise((resolve) => {
      const temp = this.tempData;
      this.tempData = {};

      resolve(temp);
    });
  }

  public getJMP(
    fromDate: any,
    timeZone: any,
    status: any,
    supervisorId: any,
    searchValue: any,
    parameters: any
  ) {
    let params = new HttpParams();

    if (fromDate !== "") {
      params = params.set("startOrderDate", fromDate);
    }

    if (status !== "") {
      params = params.set("OrderStatus", status);
    }

    if (supervisorId !== "" && supervisorId != null) {
      params = params.set("supervisorId", supervisorId);
    }

    if (timeZone !== "" && timeZone != null) {
      params = params.set("timeZone", timeZone);
    }

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(`${environment.base_url}/journey-management-plans/filter`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getJMPByID(id: any) {
    return this.http
      .get(`${environment.base_url}/journey-management-plans/` + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getJMPApprove(id: any) {
    return this.http
      .get(`${environment.base_url}/journey-management-plans/approve/` + id)
      .pipe(
        map((resp: any) => {
          this.refreshJMP$.emit("Refresh JMP");

          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public getJMPReject(id: any) {
    return this.http
      .get(`${environment.base_url}/journey-management-plans/reject/` + id)
      .pipe(
        map((resp: any) => {
          this.refreshJMP$.emit("Refresh JMP");

          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public generateJMPFormPDF(id: any) {
    return this.http
      .post(
        environment.base_url +
          "/journey-management-plans/generate-pdf?id=" +
          id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public deleteJMP(id: any){
    return this.http.delete( environment.base_url+'/journey-management-plans/'+id, { observe: 'response' })
    .pipe(
      map((resp: any) => {
        this.refreshJMP$.emit('Deleted JMP');
        return resp;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}
