<div class="table-space ag-grid-table-full" style="height: 100%;" [hidden]="noData">
    <ag-grid-angular
        #agGrid
        style="height: 100%;"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [pagination]="false"
        [cacheOverflowSize]="2"
        [maxConcurrentDatasourceRequests]="-1"
        [suppressDragLeaveHidesColumns]="true"
        [allowContextMenuWithControlKey]="true"
        [frameworkComponents]="frameworkComponents"
        (cellClicked)="viewCell($event)"
        (gridReady)="onGridReady($event)"
        [getContextMenuItems]="getContextMenuItems"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    >
    </ag-grid-angular>

    <div class="col-12">
        <p class="ag-record-row-summary-panel padds text-end">
            {{ totalRecords }}&nbsp;{{translate?.general.messages.records}}
        </p>
    </div>
</div>

<div *ngIf="noData" class="col-12 pb-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-3">
        <img src="assets\svg\empty-state.svg" alt="" />
         <p class="text-6 my-1 font-weight-bold">{{translate?.general.messages.noRecordsFound}}</p>
    </div>
</div>
