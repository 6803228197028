<ng-container>
    <div class="left-right-space flex-column-100 overflow-hidden">
        <div class="flex-between mb-2">
            <!-- <div class="main-list-header" *ngIf="noLeaves !== null && noLeaves === false" >
                {{ "my_leave" }}
            </div> -->
            <div class="fw-normal" >
                <span *ngIf="selectedRows.length > 0" class="text-12 font-medium ms-2" id="label_OvertimeAccept">{{selectedRows.length + " Selected"}}</span>
                <p-button *ngIf="selectedRows.length > 0" type="button" [label]="translate?.general.buttons.accept.singular" icon="fa-regular fa-plus"
                    id="btn_OvertimeAccept" (onClick)="onAcceptMultipleStatus()" styleClass="ms-2 h-32"></p-button>

            </div>
            <!-- Filter and New -->
            <div class="custella-dashboard-top-right d-flex ms-auto">
                <button class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" [id]="'btn_' + attributeIdName +'Refresh'" (click)="refresh()">
                    <i class="fa-regular fa-arrows-rotate"></i>
                </button>

                <button class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" [id]="'btn_' + attributeIdName +'Filter'" (click)="toggleFilterView()">
                    <i class="fa-regular fa-filter-list"></i>
                </button>
                <div class="p-inputgroup search ms-2">
                    <span class="p-input-icon-right">
                        <input type="text" class="w-100" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" [id]="'input_' + attributeIdName +'Search'" [(ngModel)]="searchValue" (keyup)="onSearchCheck()" (keydown.enter)="onSearch()" />
                        <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()"></i>
                    </span>   
                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" (click)="onSearch()" [id]="'btn_' + attributeIdName +'Search'"></button>
                </div>
                <p-button  *ngIf="create"  type="button" [label]="translate?.general.buttons.new.singular" (onClick)="createView()" icon="fa-regular fa-plus" styleClass="ms-2 h-32" [id]="'btn_' + attributeIdName +'New'" ></p-button>
               </div>
        </div>
        <div *ngIf="noOvertimeDataIcon" class="col-12 mt-5 py-4">
            <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                <img src="assets\svg\empty-state.svg" alt="" id="img_NoRecordsFound" />
                <p class="text-6 my-1 font-weight-bold" >
                    {{translate?.general.messages.noRecordsFound}}
                </p>
            </div>
        </div>
        <div class="position-relative d-flex h-100-70">
            <div [hidden]="!noOvertime"  class="table-space ag-grid-table-full h-100" [ngClass]="{ 'ag-grid-table': filterView }">
                <ag-grid-angular #agGrid
                style="height: 100%;"
                class="ag-theme-balham"
                [columnDefs]="columnDefs"
                [gridOptions]="gridOptions"
                [defaultColDef]="defaultColDef"
                [cacheOverflowSize]="2"
                [maxConcurrentDatasourceRequests]="-1"
                [suppressDragLeaveHidesColumns]="true"
                [allowContextMenuWithControlKey]="true"
                [frameworkComponents]="frameworkComponents"
                (cellClicked)="viewVisit($event)" 
                (gridReady)="onGridReady($event)"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                rowSelection="multiple" 
                [suppressRowClickSelection]="true"
                (selectionChanged)="onSelectionChanged($event)"
                [getContextMenuItems]="getContextMenuItems">
                </ag-grid-angular>

                <div class="col-12">
                    <p class="ag-record-row-summary-panel padds text-end" id="label_OvertimeTableRec">
                        {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
                    </p>
                </div>
            </div>

            <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': filterView }">
                <div class="custella-form-container-content-pane-title">
                    <span class="text-14 font-medium" id="title_OvertimeFilter">{{ translate?.general.messages.filterBy }}</span>
                    <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" id="btn_OvertimeFilterBack" (click)="toggleFilterView()"></i>
                </div>

                <div class="custella-content">
                    <div class="custella-content-input col-12" [formGroup]="myGroup" *ngIf="!display">
                        <span class="custella-content-input-title" id="label_OvertimeDateTime">{{ translate?.overtime.fields.overtimeDate.singular }}</span>
                        <span class="p-input-icon-right w-100">
                            <i class="pi pi-calendar" style="z-index: 1;"></i>
                            <p-calendar class="custella-calendar" #dateFilter [hideOnDateTimeSelect]="hideOnDateTimeSelect" [readonlyInput]="true"
                                formControlName="dateRange"  id="picklist_OvertimeDateTimeField"
                                placeholder="dd/mm/yyyy - dd/mm/yyyy" selectionMode="range" dateFormat="dd/mm/yy"
                                yearRange="2000:2030" [showButtonBar]="false"  [yearNavigator]="true"  [(ngModel)]="dateRange"
                                (onSelect)="onChangeDate()"appendTo="body"></p-calendar>
                        </span>
                    </div>
                    <div class="custella-content-input col-12 "  *ngIf="!machineSpecialists">
                        <span class="custella-content-input-title" id="label_OvertimeMachineSpecialist">{{ translate?.machineSpecialist.label.singular }}</span>
                            <div class="p-inputgroup search">
                                <span class="p-input-icon-right w-100">
                                    <p-autoComplete styleClass="w-100" id="picklist_OvertimeMachineSpecialistField"
                                    (onSelect)="onMachineSpecialistSelect($event)" field="name"
                                    [suggestions]="allMachineSpecialist" (completeMethod)="getMachineSpecialist($event)"
                                    [(ngModel)]="selectMachineSpecialist" [placeholder]="translate?.general.messages.none"
                                    [ngModelOptions]="{standalone: true}"> </p-autoComplete>
                                    <i *ngIf="filter.userId" (click)="clearValueFrom('machineSpecialist')" id="btn_OvertimeMachineSpecialistClear" class="p-autocomplete-clear-icon pi pi-times"></i>
                                </span>   
                                <button type="button" pButton icon="fa-regular fa-magnifying-glass"  [pTooltip]="translate?.machineSpecialist.tooltips.machineSpecialistLookup.singular" tooltipPosition="left"id="btn_OvertimeMachineSpecialistSearch" (click)="showTable('machineSpecialist')"></button>
                            </div>

                    </div>
                    <div *ngIf="pendingStatus == null" class="custella-content-input col-12">
                        <span class="custella-content-input-title" id="label_OvertimeStatus">{{ translate?.overtime.fields.overtimeStatus.singular }}</span>
                        <p-dropdown name="types" [options]="statuses" id="picklist_OvertimeStatusField" 
                            [placeholder]="translate?.general.messages.none" [(ngModel)]="filter.status" [showClear]="true"
                            optionLabel="label" filterBy="label" optionValue="name" (ngModelChange)="filterResults($event)">
                        </p-dropdown>
                    </div>
                    

                    <div>
                        <span href="#" class="clear" (click)="clearValue()" id="subtitle_OvertimeFilterClear">{{ translate?.general.buttons.clearAll.singular }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<!--*************************************** Display Machine Specialist Table ************************************-->
<footer *ngIf="showingTable.machineSpecialist" class="custella-form-container-footer hidePopup">
    <p-dialog [header]="translate?.machineSpecialist.label.singular" [(visible)]="showingTable.machineSpecialist" showEffect="fade"
        [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
        <app-custella-machines-specialist (onMSSelect)="onMachineSpecialistSelect($event)"
            (closeView)="closeTable('machineSpecialist')"></app-custella-machines-specialist>

    </p-dialog>
</footer>


<app-common-dialog-box (visibleOut)="visibleOut($event)" [data]="dialogStatusData"
    (onClickYes)="onClickDialogYes($event)" [visible]="showStatusDialog"></app-common-dialog-box>