<div class="table-space" style="height: calc(100% - 73px);">
    <ag-grid-angular
        #agGrid
        style="height: 100%;"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [maxConcurrentDatasourceRequests]="-1"
        [suppressDragLeaveHidesColumns]="true"
        [allowContextMenuWithControlKey]="true"
        [frameworkComponents]="frameworkComponents"
        (cellClicked)="viewCustomer($event)"
        (gridReady)="onGridReady($event)"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [getContextMenuItems]="getContextMenuItems"
    ></ag-grid-angular>
    <div class="col-12"><p class="ag-record-row-summary-panel padds text-end" id="label_CusTableRec">{{totalRecord}}&nbsp;{{translate?.general?.messages.records}}</p></div>
</div>
