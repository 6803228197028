
import {Observable, of as throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ServerRequest } from '../_models/global.data.model';

@Injectable()
export class InventoryService {
  private ENDPOINT_INVENTORY_LOCATION= "/ware-house-locations"
  private ENDPOINT_INVENTORY_LOCATION_BY_ID= "/ware-house-locations/"
  
  private ENDPOINT_INVENTORY_ZONE= "/zones"
  private ENDPOINT_INVENTORY_ZONE_BY_ID= "/zones/"
  
  private ENDPOINT_INVENTORY_BIN= "/bins"
  private ENDPOINT_INVENTORY_BIN_BY_ID= "/bins/"
  
  public refresherLocation$: EventEmitter<any>;
  public refresherZone$: EventEmitter<any>;
  public refresherBin$: EventEmitter<any>;
  public refreshInventoryUserList$: EventEmitter<any>;
  public refreshInventoryLocationList$: EventEmitter<any>;
  public refreshInventoryList$: EventEmitter<any>;
  public refreshBinTransfer$: EventEmitter<any>;

constructor(private http: HttpClient,
    private auth: AuthenticationService,
    private router : Router) {

      this.refresherLocation$ = new EventEmitter();
      this.refresherZone$ = new EventEmitter();
      this.refresherBin$ = new EventEmitter();
      this.refreshInventoryUserList$ = new EventEmitter();
      this.refreshInventoryLocationList$ = new EventEmitter();
      this.refreshInventoryList$ = new EventEmitter();
      this.refreshBinTransfer$ = new EventEmitter();
    
  }



//**================================== Inventory setup =>location  =======================*/
public getAllLocation(searchValue: any,parameters: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/ware-house-locations`, { 
    observe: 'response',params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public createLocation(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_INVENTORY_LOCATION, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherLocation$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getLocationById(stepId: any){
  return this.http.get( environment.base_url+this.ENDPOINT_INVENTORY_LOCATION_BY_ID + stepId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
public updateLocation(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_INVENTORY_LOCATION, body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresherLocation$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

public deleteLocation(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_INVENTORY_LOCATION_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherLocation$.emit('Deleted Location');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

public getLocationPickList(){
  return this.http.get( environment.base_url+'/ware-house-locations/pick-list', {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

//**================================== Inventory setup =>Zone  =========================*/
public getAllZone(searchValue: any,parameters: any,wareHouseLocationId: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  if( wareHouseLocationId != null){
    params = params.set('wareHouseLocationId', wareHouseLocationId);
  }
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/zones`, {observe: 'response', params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public createZone(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_INVENTORY_ZONE, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherZone$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getZoneById(stepId: any){
  return this.http.get( environment.base_url+this.ENDPOINT_INVENTORY_ZONE_BY_ID + stepId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
public updateZone(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_INVENTORY_ZONE, body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresherZone$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

public deleteZone(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_INVENTORY_ZONE_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherZone$.emit('Deleted Zone');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}
public getZonePickList(locationId: any){
  return this.http.get( environment.base_url+'/zones/pick-list?wareHouseLocationId='+locationId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getZonePickListWithoutLocation(){
  return this.http.get( environment.base_url+'/zones/pick-list?', {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
//**================================== Inventory setup =>Bin  =========================*/
public getAllBin(searchValue: any,parameters: any,zoneId: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  if(zoneId != null){
    params = params.set('zoneId', zoneId);
  }
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/bins`, {observe: 'response' ,params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public createBin(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_INVENTORY_BIN, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherBin$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getBinById(stepId: any){
  return this.http.get( environment.base_url+this.ENDPOINT_INVENTORY_BIN_BY_ID + stepId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
public updateBin(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_INVENTORY_BIN, body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresherBin$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

public deleteBin(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_INVENTORY_BIN_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherBin$.emit('Deleted Bin');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

public getBinPickListInventory(locationId: any , zoneId: any){
  return this.http.get( environment.base_url+'/bins/pick-list?wareHouseLocationId='+locationId+'&zoneId='+zoneId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
public getBinPickListForChecklist( zoneId: any){
  return this.http.get( environment.base_url+'/bins/pick-list?zoneId='+zoneId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
public getBinPickData(){
  return this.http.get( environment.base_url+'/bins/pick-list', {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

//**================================== Inventory => Location Bin  =========================*/
public getAllInventoryBin(searchValue: any,parameters: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/bins/location-bins`, {params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public createInventoryBin(body:any){
  return this.http.post( environment.base_url+'/inventories/adjust', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshInventoryLocationList$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getInventoryBinById(stepId: any){
  return this.http.get( environment.base_url+'/bins/inventory-details/' + stepId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}


public deleteInventoryBin(id: any){
  return this.http.delete( environment.base_url+'/bins/inventory-details/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshInventoryLocationList$.emit('Deleted Bin');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

public getProductByLocationBin(locationId: any,zoneId: any,binId: any){
  let params = new HttpParams();
  
  if(locationId !== undefined && locationId != null){
    params = params.set('wareHouseLocationId', locationId);
  }
  if(zoneId !== undefined && zoneId != null){
    params = params.set('zoneId', zoneId);
  }
  if(binId !== undefined && binId != null){
    params = params.set('binId', binId);
  }
  
  console.log(params)

  return this.http.get( environment.base_url+'/inventories/location-bin/adjust', {params : params, observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
//**================================== Inventory =>Bin  =========================*/
public getAllUserBin(searchValue: any,parameters: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/bins/user-bins`, {params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public createUserBin(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_INVENTORY_BIN, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshInventoryUserList$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}




public getProductByUser(type : any, transferFromBinId:any = null){
  let params = new HttpParams();
  params = params.set('type', type);
  if(transferFromBinId != null){
    params = params.set('selectedFromBinId', transferFromBinId);
  }

  return this.http.get( environment.base_url+'/bins/combination/pick-list', {observe: "response", params : params}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
public getFromBinByProduct(type: any,productId: any,assetId:any =null){
  let params = new HttpParams();
   
  if(productId !== '' && productId != null){
    params = params.set('productId', productId);
  }
  if(assetId !== '' && assetId != null){
    params = params.set('assetsId', assetId);
  }
  params = params.set('type', type);

  return this.http.get( environment.base_url+'/bins/combination/pick-list', {observe: "response",params : params} ).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}


//**================================== Inventory API  =========================*/
public getAllInventory(searchValue: any,parameters: any, filter: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  if(filter.productFamilyId !== '' && filter.productFamilyId != null){
    params = params.set('productFamilyId', filter.productFamilyId);
  }
  if(filter.productTypeId !== '' && filter.productTypeId != null){
    params = params.set('productTypeId', filter.productTypeId);
  }
  if(filter.assetsTypeId !== '' && filter.assetsTypeId != null){
    params = params.set('assetsTypeId', filter.assetsTypeId);
  }

  
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/inventories`, {params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public createInventory(body:any){
  return this.http.post( environment.base_url+'/inventories/adjust', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshInventoryList$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getInventoryById(stepId: any){
  return this.http.get( environment.base_url+'/inventories/' + stepId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public deleteInventory(id: any){
  return this.http.delete( environment.base_url+'/inventories/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshInventoryList$.emit('Deleted Inventory');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}
public getLocationByProductAndAssets(productId: any , assetId: any){
  return this.http.get( environment.base_url+'/inventories/adjust?productId='+productId+'&assetsId='+assetId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

//**================================== Inventory History  =========================*/
public getAllInventoryHistory(searchValue: any,parameters: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/bin-inventory-histories`, {params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
//**===================================== Check Avalibility =============================== */
public checkAvalibility(locationId : any, zoneId: any, binId: any , productId: any ,assetsId: any){

  if(locationId == null){
    locationId =''
  }
  if(zoneId == null){
    zoneId =''
  }
  
  if(binId == null){
    binId =''
  }
  if(productId == null){
    productId =''
  }
  if(assetsId == null){
    assetsId =''
  }
  return this.http.get( environment.base_url+'/inventories/check-availability?productId='+productId+'&assetsId='+assetsId+'&wareHouseLocationId='+locationId+'&zoneId='+zoneId+'&binId=' + binId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
public checkAvalibilityFilters(locationId : any, zoneId: any, binId: any , productId : any,productTypeId: any,productFamilyId: any,assetsId: any,assetsTypeId: any, parameters: any){
  let params = new HttpParams();
 
  if(locationId !== '' && locationId != null){
    params = params.set('wareHouseLocationId', locationId);
  }
  if(zoneId !== '' && zoneId != null){
    params = params.set('zoneId', zoneId);
  }
  if(binId !== '' && binId != null){
    params = params.set('binId', binId);
  }
  if(productId !== '' && productId != null){
    params = params.set('productId', productId);
  }
  if(productTypeId !== '' && productTypeId != null){
    params = params.set('productTypeId', productTypeId);
  }
  if(productFamilyId !== '' && productFamilyId != null){
    params = params.set('productFamilyId', productFamilyId);
  }
  if(assetsId !== '' && assetsId != null){
    params = params.set('assetsId', assetsId);
  }
  if(assetsTypeId !== '' && assetsTypeId != null){
    params = params.set('assetsTypeId', assetsTypeId);
  }
 
  
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  
  return this.http.get( environment.base_url+'/inventories/check-availability/paged', {observe: "response",params : params}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

//**================================== Inventory =>Bin Transfer  =========================*/
public getAllBinTransfer(searchValue: string | number | boolean | null,parameters: any, approvalStatus: string | number | boolean | null){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  if(approvalStatus !== '' && approvalStatus != null){
    params = params.set('approvalStatuses', approvalStatus);
  }
  
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/bin-transfers`, {observe: 'response' ,params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
  ),catchError( error => {
    console.log(error)
    return <Observable<void>><any>throwError( error );
}),);
}

public createBinTransfer(body:any){
  return this.http.post( environment.base_url+'/bin-transfers', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshBinTransfer$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public createBinTransferByProduct(body:any){
  return this.http.post( environment.base_url+'/v2/bin-transfers', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshBinTransfer$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}


public getBinTransferById(stepId: any){
  return this.http.get( environment.base_url+'/bin-transfers/' + stepId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public deleteBinTransfer(id: any){
  return this.http.delete( environment.base_url+'/inventories/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshBinTransfer$.emit('Deleted Inventory');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}
public binTransfersUpdateStatus(binTransferId: any,assignedStatus: any,body:any){
  return this.http.put( environment.base_url+'/bin-transfers/update-status?binTransferId='+binTransferId+'&assignedStatus='+assignedStatus,body, { observe: "response" }).pipe(map( (resp:any) => {
    this.refreshBinTransfer$.emit(resp);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getProductByBinTransferFrom(transferFromBinId: any){
  return this.http.get( environment.base_url+'/inventories/location-bin/adjust?wareHouseLocationId&zoneId&binId='+transferFromBinId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getProductByBinTransferFromUser(transferFromBinId: any){
  return this.http.get( environment.base_url+'/inventories/user-bin/adjust?userId='+transferFromBinId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}


public getProductByBinFrom(transferFromBinId: any){
  return this.http.get( environment.base_url+'/inventories/all-bin/adjust/product-list?binId='+transferFromBinId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

public getAllInventoryByProduct(searchValue: any,parameters: any,productId: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  if(productId !== '' && productId != null){
    params = params.set('productId', productId);
  }
  
  console.log(parameters)
  if(parameters)

  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( `${environment.base_url}/inventories/by-product`, {params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public getAllFromBin(searchValue: any,parameters: any, transferType: any,binType: any, selectedFromBinId: any){
  let params = new HttpParams();

  if(parameters)
  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  if(transferType){
    params = params.set('type', transferType);
  }
  if(searchValue){
    params = params.set('search', searchValue);
  }
  console.log(binType)
  if(binType == 'ToBin'){
    params = params.set('selectedFromBinId',selectedFromBinId)
  }

  return this.http.get(environment.base_url+'/bins/combination/pick-list/paged',  {observe: "response", params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  ////console.log(error);
      return throwError( error );
  }),);
}

public searchAllFromBin(searchValue: any,parameters: any, transferType: any,selectedFromBinId: any){
  let params = new HttpParams();
  if(searchValue){
    params = params.set('search', searchValue);
  }
 
  if(transferType){
    params = params.set('type', transferType);
  }
 
  if(selectedFromBinId){
    params = params.set('selectedFromBinId',selectedFromBinId)
  }
  if(parameters)
  params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
 
  return this.http.get(environment.base_url+'/bins/combination/pick-list/paged',  {observe: "response", params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  ////console.log(error);
      return throwError( error );
  }),);
}
//**================================== Inventory => Location Bin  =========================*/
public getAllLocationBin(searchValue: any,parameters: any, lookupFilter: any){
 
 return this.http.get(environment.base_url+'/bins/location-bins?startFrom='+parameters.startRow+'&perPage=25&search='+searchValue+'&columnName='+parameters.sortModel[0].colId+'&sortOrder='+parameters.sortModel[0].sort+lookupFilter ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public getAllFromBinCombination(searchValue: any, serverParameters: any, binParams: any){
  let params = new HttpParams();

  if(serverParameters) {
    params = params.set('startFrom', serverParameters.startRow).set('perPage', '25').set('columnName', serverParameters.sortModel[0].colId).set('sortOrder', serverParameters.sortModel[0].sort);
  }

  if(binParams && binParams.transferType){
    params = params.set('type', binParams.transferType);
  }

  if(binParams && binParams.productId){
    params = params.set('productId', binParams.productId);
  }

  if(searchValue){
    params = params.set('search', searchValue);
  }

  return this.http.get(environment.base_url+'/bins/combination/pick-list/paged',  {observe: "response", params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  ////console.log(error);
      return throwError( error );
  }),);
}
}