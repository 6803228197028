<div class="">
        <div class="custella-modal d-flex flex-column">
            <div class="custella-modal-header">
                <div class="custella-modal-header-text">
                    <!-- <span> {{ "Guide"  }} </span> -->
                </div>
  
                <i class="pi pi-times pt-1" (click)="modalRef.hide()"></i>
            </div>
  
            <div class="custella-modal-body">
                <div class="custella-content-row-input mb-2">
                    <!-- <span class="custella-content-row-input-title">{{ "VIDEO GUIDE GOES HERE"  }}</span> -->
                </div>
  
            </div>

        </div>
  </div>
  