<div class="modal-header flex-between border-0 pb-1">
    <div>
        <span class="text-16 font-medium" id="title_JobSurveySendLink">{{translate?.jobOrder.fields.sendSurveyLink.singular}}</span>
        <span class="text-12 color-var-500 d-block pt-1" id="subtitle_JobSurveySendLink" >{{translate?.jobOrder.fields.sendSurveyToCustJo.singular}}</span>
    </div>
    <i class="fa-regular fa-xmark" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" id="btn_JobSurveyClose" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body tix pt-2">
    <form [formGroup]="curveyLinkForm">
        <div class="custella-form-container rounded-2 ms-0 me-2" style="box-shadow: none; border: 1px solid #dee2e6;">
            <!-- Container Content -->
            <div class="custella-form-container-title text-14 font-medium rounded-top" id="title_JobSurveyDets" >
                {{translate?.jobOrder.sections.sec26.singular}}
            </div>
            <div class="custella-form-container-title-two-grey flex-align-center fst-italic" >
                <span class="text-12 font-bold" id="title_JobSurveyLinkStepOne">{{translate?.general.messages.step1}}&colon;&nbsp;</span>
                <span class="text-12" id="title_JobSurveyLinkStepOne">{{translate?.jobOrder.sections.sec31.singular}}</span>
            </div>
            <div class="custella-form-container-content">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_JobSurveySendTo">{{translate?.jobOrder.fields.surveyToSend.singular}}</span>
                        <div class="p-inputgroup search">
                            <span class="p-input-icon-right w-100">
                                <p-autoComplete
                                [(ngModel)]="customerSurveyConfigName"
                                (onBlur)="onvalidcustomerSurveyConfig()"
                                (onSelect)="selectValuecustomerSurveyConfig($event)"
                                field="name"
                                formControlName="customerSurveyConfig"
                                [suggestions]="customerSurveyConfigs"
                                (completeMethod)="getcustomerSurveyConfigIdsFirstWay($event)"
                                styleClass="w-100"
                            >
                            </p-autoComplete>
                            <i *ngIf="customerSurveyConfigName" (click)="clearcustomerSurveyConfigValue()" id="btn_CustSurverySearchClear" class="p-autocomplete-clear-icon pi pi-times"></i>
                            </span>
                            <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.customer.tooltips.customerLookup.singular" tooltipPosition="left" id="btn_CustSurverySearch" (click)="showcustomerSurveyConfigTable()"></button>
                        </div>
                        <footer *ngIf="displaycustomerSurveyConfigTable" class="custella-form-container-footer hidePopup">
                            <p-dialog [header]="translate?.jobOrder.sections.sec10.singular" [(visible)]="displaycustomerSurveyConfigTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false">
                                <app-custella-survey [isActive]="true" (customerSurveyConfigSelector)="gotSelectedcustomerSurveyConfig($event)" (closecustomerSurveyConfigView)="closecustomerSurveyConfigView()"></app-custella-survey>
                            </p-dialog>
                        </footer>
                        <div *ngIf="curveyLinkForm.controls['customerSurveyConfig'].touched && curveyLinkForm.controls['customerSurveyConfig'].invalid">
                            <span class="text-10 p-error" *ngIf="curveyLinkForm.controls['customerSurveyConfig'].errors?.required" id="label_SetInvLocAddrReqField" >{{translate?.general.validationFE.requiredField}}</span>
                        </div>
                    </div>

                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_JobSurveyLink" >{{translate?.jobOrder.fields.surveyLink.singular}}</span>
                        <p-button type="submit" *ngIf="!isSend" styleClass="h-32" [label]="translate?.general.buttons.generate.singular" (onClick)="customerSurveyGenarate()"></p-button>
                        <div class="flex-align-center" *ngIf="isSend">
                            <span class="custella-content-row-input-title-three-link">
                                <div class="d-flex">
                                    <img src="../../../../assets/svg/table/copy-link-primary.svg" class="w-12px" />
                                    <a [href]="customerSurveyDetails?.link ?? '-'" target="_blank" class="ms-2 survey-peek custella-content-row-input-title-three-link" id="value_JobSurveyLink" >{{customerSurveyDetails?.link}}</a>
                                </div>
                            </span>
                            <button class="rounded-1 no-border w-24px ms-2" tooltipPosition="top" data-toggle="tooltip" [hideDelay]="1000" pTooltip="{{ tooltipText }}" (click)="copyURL()">
                                <img src="../../../../assets/svg/table/copy-link-copy.svg" class="w-14px" id="btn_JobSurveyLinkCopy" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-title-two-grey flex-align-center fst-italic" >
                <span class="text-12 font-bold" id="title_JobSurveyLinkStepTwo" >{{translate?.general.messages.step2}}&colon;&nbsp;</span>
                <span class="text-12" id="title_JobSurveyLinkStepTwo">{{translate?.jobOrder.sections.sec32.singular}}</span>
            </div>
            <div class="custella-form-container-content" >
                <div class="custella-content-row-input col-12">
                    <span class="custella-content-row-input-title" id="label_JobSurveySendTo">{{translate?.jobOrder.fields.sendSurveyTo.singular}}&colon;</span>
                    <div class="flex-align-center">
                        <input type="text" class="mx-455px" #toEmails *ngIf="!custCont && sendTo !='order'" [(ngModel)]="curveyLinkData.sentTOEmails" formControlName="sentTOEmails" 
                        pInputText autocomplete="off" maxlength="255" id="input_JobSurveySendToField"/>
                        <input type="text" class="mx-455px" #toEmails *ngIf="!custCont && sendTo =='order'" [(ngModel)]="curveyLinkData.sentTOEmails" formControlName="sentTOEmails" 
                        pInputText autocomplete="off" maxlength="255" id="input_JobSurveySendToField" [attr.disabled]="true"/>
                        <!-- <p-dropdown class="w-100" ></p-dropdown> -->
                        <p-dropdown *ngIf="custCont && !isContactsDisabled" class="w-54" placeholder="" id="picklist_TaskSmartNoUsersField" #toEmails2
                            [(ngModel)]="curveyLinkData.sentTOEmails"
                            [options]="contacts" 
                            [showClear]="true" 
                            formControlName="sentTOEmails" 
                            optionLabel="email"
                            dataKey="id"
                            [autoDisplayFirst]="false"
                            filterBy="email" (onChange)="onchangeContacts($event)" optionValue="email" [disabled]="isContactsDisabled">
                        </p-dropdown>
                        <input type="text" class="mx-455px" #toEmails *ngIf="custCont && isContactsDisabled" [(ngModel)]="curveyLinkData.sentTOEmails" formControlName="sentTOEmails" 
                        pInputText autocomplete="off" maxlength="255" id="input_JobSurveySendToField" [attr.disabled]="true"/>
                        <div class="flex-align-center ms-2">
                            <button pButton type="button" id="btn_JobSurveySendToOrderCont" class="p-button text-nowrap ms-2" [ngClass]="{'p-button-secondary':sendTo !='order'}" (click)="sendEmailTo('order')">
                                {{translate?.jobOrder.fields.joContact.singular}}
                            </button>
                            <button pButton type="button" id="btn_JobSurveySendToCustCont" class="p-button text-nowrap ms-2" [ngClass]="{'p-button-secondary':sendTo !='cust'}" (click)="sendEmailTo('cust')">
                                {{translate?.jobOrder.fields.mainCustContact.singular}}
                            </button>
                            <button pButton type="button" id="btn_JobSurveySendToCustom" class="p-button text-nowrap ms-2" [ngClass]="{'p-button-secondary':sendTo !='email'}" (click)="sendEmailTo('email')">{{translate?.jobOrder.fields.custom.singular}}</button>
                        </div>
                    </div>
                    <div *ngIf="curveyLinkForm.controls['sentTOEmails'].touched && curveyLinkForm.controls['sentTOEmails'].invalid  && isSubmited">
                        <!-- <span class="text-10 p-error" *ngIf="curveyLinkForm.controls['sentTOEmails'].errors?.required" id="label_SetInvLocAddrReqField" >{{'general.validationFE.requiredField' }}</span> -->
                        <span class="text-10 p-error" *ngIf="curveyLinkForm.controls['sentTOEmails'].invalid && sendTo =='email'" id="label_SetInvLocAddrReqField" >{{translate?.general.validationFE.invalidEmail}}</span>
                        <!-- <span class="text-10 p-error" *ngIf="curveyLinkForm.controls['sentTOEmails'].errors?.pattern && sendTo =='email'"  class="text-10 p-error"  >{{'general.validationFE.invalidEmailFormat'}}</span> -->
                        <span class="text-10 p-error" *ngIf="curveyLinkForm.controls['sentTOEmails'].invalid && sendTo =='order'" id="label_SetInvLocAddrReqField" > {{translate?.jobOrder.validationFE.noContactFoundForThisJobOrder}}</span>
                        <span class="text-10 p-error" *ngIf="curveyLinkForm.controls['sentTOEmails'].invalid && sendTo =='cust'" id="label_SetInvLocAddrReqField" > {{translate?.jobOrder.validationFE.noContactFoundForTheJobOrdersCustomer}}</span>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-footer flex-between mb-0">
                <!-- <button *ngIf="customerSurveyDetails" type="button" class="custella-btn-white flex-align-center" id="btn_JobSurveyCopyLink" (click)="copyURL()" tooltipPosition="top"  pTooltip="{{ tooltipText }}"  [hideDelay]="1000">
                <img src="../../../../assets/svg/table/copy-link.svg" style="width: 12px">
                <span class="text-12 ms-2">{{ "copy_link"  }}</span>
            </button> -->
                <div class="flex-align-center justify-content-end ms-auto">
                    <button type="button" class="custella-btn-white text-12 ms-2" id="btn_JobSurveyCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                    <p-button type="submit" styleClass="ms-2 h-32" id="btn_JobSurveySend" [label]="buttonLabel" (onClick)="onSubmitForm()" [disabled]="!isSend"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>
