import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UsersService } from 'src/app/_services/admin/users.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { TeamService } from 'src/app/_services/team.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { WebSocketsService } from 'src/app/_services/web-sockets.service';
import { environment } from 'src/environments/environment';
import * as Stomp from 'stompjs';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
;
declare var google: any;

@Component({
  selector: 'app-task-map',
  templateUrl: './task-map.component.html',
  styleUrls: ['./task-map.component.scss']
})
export class TaskMapComponent implements OnInit {

   empClass: string = "pullUp";
   markerOptions: google.maps.MarkerOptions = {draggable: false};
   mapOptions: google.maps.MapOptions = {maxZoom: 22,styles: [ 
    { "featureType": "administrative", "elementType": "geometry", "stylers": [ { "visibility": "off" } ] }, 
    { "featureType": "poi", "stylers": [ { "visibility": "off" } ] }, 
    { "featureType": "road", "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, 
    { "featureType": "transit", "stylers": [ { "visibility": "off" } ] } 
]
  };
   pointer: any={x:18.4,y:22}
   scaledSize :any ={height: 48,width: 38}

   onlineUsersList: any;
  
  typeOfUsersList: any;
  @ViewChild(MapInfoWindow,{static: false})
  infoWindow!: MapInfoWindow;
  displayActual:boolean = false

  stompClient: any;
  employeeCardWidth: any;
  timezone: any;
  collapse: any;
  refreshCollapse : Subscription;
  refreshLocations : Subscription;
  refreshUser : Subscription;
  show!: boolean;
  markers: any[] =[];
  userMarkers: any[] =[];
  users: any;
  latlngBounds: any;
  sub!: Subscription;
  date: any;
  taskLoader: any;
  taskId: any;
  markerWaypoints: any[] = [];
  destination: any = { lat: 0, lng: 0 };
  origin: any = { lat: 0, lng: 0 };
  infoWindowOpened = null;
  userSelected : boolean = false;
  iconUrl : string = '../../../../assets/images/map-icons/userIcons/';
  selectedUserAddress : any = '';
  styleValue: any;

  originIcon : string = '../../../../assets/images/map-icons/current_location_marker.png';

  options = {
    markerOptions: {
      icon: 'https://image.ibb.co/cLwp5n/678111_map_marker_256.png'
    },
  }

  markerOpts = {
    origin: {
      icon: '../../../../assets/images/map-icons/current_location_marker.png',
    },
    destination: {
      icon: 'https://image.ibb.co/cLwp5n/678111_map_marker_256.png',
      // label: 'marker label',
      opacity: 0.8,
    },
  };

  renderOpts = {
    suppressMarkers: true,
  };


  waypoints: any[] = [];
  tasks: any[] = [];
  teamView: any;
  public latitude: number = 3.1412;
  public longitude: number = 101.68653;
  public zoom: number = 10;
  travelMode = 'DRIVING';
  showDirections : Boolean = true;
  overlays: any[] = [];
  optionsMap: any;

  searchEmployee:any
  filter : any = {};
  mapUsersArray : any[] = [];
  originalArray: any;
  selectUser : boolean = false;
  selectedUserdata: any;
  translate: any;

  constructor(private route: ActivatedRoute,
  private taskService: TasksService,
  private auth: AuthenticationService,
  private util : UtilServiceService,
  private cdRef : ChangeDetectorRef,
  private modalService : ModalServiceService,
  private webSocket: WebSocketsService,
  private teamService : TeamService,
  private customerService: CustomersService,
  private usersService: UsersService,
  private languageTranslateService: LanguageTranslateService
) {
  
    this.refreshCollapse = this.util.refreshCollapse$.subscribe((o) => {
      this.collapse = o;
    });

    this.refreshLocations = this.webSocket.refreshLocations$.subscribe((user) => {
      //console.log("Socket User ", user);
      this.updateUserLocationUsingWebSocket(user);
    });

    this.refreshUser = webSocket.refreshOnlineStatus$.subscribe(user => {
      this.updateUserLocationUsingWebSocket(JSON.parse(user.body));
    });

    this.refreshLocations = this.webSocket.refreshOnlineStatus$.subscribe((user) => {
      this.updateUserLocationUsingWebSocket(JSON.parse(user.body));
    });
  }

  ngOnInit() {

    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data
        this.onlineUsersList=[
          {label: this.translate?.task.fields.allUsers.singular,value:'ALL'},
          {label:this.translate?.task.fields.onlineUsers.singular,value:'ONLINE'},
          {label:this.translate?.task.fields.offlineUsers.singular,value:'OFFLINE'}
        ]
        this.typeOfUsersList =[
          {label:this.translate?.task.fields.directReports.singular,value:'ALL'},
          {label:this.translate?.task.fields.allStaff.singular,value:'SUBORDINATES'}
        ]
      }
    })

    this.optionsMap = {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: 10,
    };
    var collapse: any = this.util.getNavCollapseStatus();
    this.collapse = (collapse === "true");
    this.route.queryParams.subscribe(params => {
      this.teamView = params['teamView'];
      this.taskId = params['taskId'];
      this.date = params['date'];
      if(params['directions']){
        this.showDirections = (params['directions'] === 'true'); ;
      }
    });
    //console.log('teamView ', this.teamView);
    this.initMap();
    window.scrollTo(0, 0);
    this.timezone = this.auth.getUserTimezone()
    if(this.teamView == 'true'){
    this.filter.onlineUsers = 'ALL';
    this.filter.typeOfUsers = 'ALL';
    this.loadUsers();
    }
  }
  
  ngAfterContentInit()
  {
   // this.employeeCardWidth = this.elementView.nativeElement.offsetWidth;
    this.cdRef.detectChanges();
  }

  ngOnDestroy(){
    if(this.sub){
      this.sub.unsubscribe();
    }
    this.refreshCollapse.unsubscribe();
    this.refreshLocations.unsubscribe();
  }

  // updateUserInfoUsingWebSocket(user){
  //   let matchingIndex = _.findIndex(this.users, function(o: any) { return o.id == user.id; });
  //   if(matchingIndex !== -1){
  //     this.users[matchingIndex].online = user.online;
  //   }
  // }

  initializeWebSocketConnection(){
    var serverUrl = environment.webSocket_url+this.auth.getToken();
    let ws = new SockJS(serverUrl);
    this.stompClient = Stomp.over(ws);
    let that = this;
    // this.stompClient.debug = null; //Debug logging for sockets else{
    that.stompClient.connect({}, function(frame: any) {
      if(frame.command == "CONNECTED"){
        that.stompClient.subscribe("/user/queue/notify-location", (location: any) => {
          that.webSocket.emitLocation(JSON.parse(location.body));
        });
      }
    });
  }

  updateUserLocationUsingWebSocket(user: any){
    let matchingIndex = _.findIndex(this.userMarkers, function(o) { return o.id == user.id; });
    if(matchingIndex !== -1){
      let firstLetter = user.firstName.charAt(0).toLowerCase();
      let secondLetter = user.lastName.charAt(0).toLowerCase();
      let statusLetter, path;
      if(user.online){
        path = 'online/';
        statusLetter = 'g.png';
      } else {
        path = 'offline/'
        statusLetter = 'r.png'
      }
      user.markerUrl = '../../../../assets/svg/Planing-hub/green-marker.svg'
      user.label = firstLetter.toUpperCase() + secondLetter.toUpperCase() 
      this.userMarkers[matchingIndex] = user;
      this.users[matchingIndex] = user;
    }
  }

  onRefreshMap(users: any) {
    if(!users.length){
      this.markers = [];
      this.loadUserLocations(null, null);
      return;
    }
    console.log("Users Received ", users);
    this.tasks = [];
    this.markers = [];
    this.userMarkers = [];
    if(this.markerWaypoints.length){
      this.markerWaypoints.forEach((direction) => {
        direction.visible = false;
      })
    }
    console.log(this.markerWaypoints,"===>>>")
    // if(users.length)
    //   this.userSelected = true;
    // else
    //   this.userSelected = false;
    users.forEach((element: any) => {
      if(element.userLattitude && element.userLongitude){
        let firstLetter = element.firstName.charAt(0).toLowerCase();
        let secondLetter = element.lastName.charAt(0).toLowerCase();
        let statusLetter, path;
        if(element.online){
          path = '../../../../assets/svg/Planing-hub/green-marker.svg';
          statusLetter = 'g.png';
        } else {
          path = '../../../../assets/svg/Planing-hub/red_marker.svg'
          statusLetter = 'r.png'
        }
        element.lat = element.userLattitude 
        element.lng = element.userLongitude
        element.markerUrl = path
        element.label = firstLetter.toUpperCase() + secondLetter.toUpperCase() 
        this.userMarkers.push(element);
      }
    });
    for(var i=0;i<users.length;i++){
      this.taskService.getTodaysTasks(users[i].id, this.date, this.auth.getUserTimezone()).subscribe((tasks:any)=>{
        this.tasks = tasks;
        if(tasks.length){
          //Show only accepted tasks
          this.tasks = _.filter(this.tasks, (o) => {
            return o.assignStatus == 'ACCEPTED';
          })
          // var marker : any = {
          //   userLattitude : this.latitude,
          //   userLongitude : this.longitude,
          //   firstName : 'Current Location',
          //   markerUrl : '../../../../assets/images/map-icons/current_location_marker.png'
          // }
          // this.userMarkers.push(marker);
          for(var j=0;j<this.tasks.length;j++){
            if(j == 0){
              var temp : any = {
                origin: { lat: users[0].userLattitude, lng: users[0].userLongitude },
                destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
                visible: true,
                travelMode: google.maps.TravelMode.DRIVING
              }
              var marker : any= {
                latitude : this.tasks[j].latitude,
                longitude : this.tasks[j].longitude,
                lat : this.tasks[j].latitude,
                lng : this.tasks[j].longitude,
                location : this.tasks[j].location,
                taskName : this.tasks[j].taskName,
                icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                startTime : this.tasks[j].startDateTime,
                endTime : this.tasks[j].endDateTime,
                id : this.tasks[j].id,
                 label:(j+1).toString()
              }
            } else if (j == this.tasks.length-1){
              var temp : any = {
                origin: { lat: this.tasks[j-1].latitude, lng: this.tasks[j-1].longitude  },
                destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
                visible: true,
                travelMode: google.maps.TravelMode.DRIVING
              }
              var marker : any= {
                latitude : this.tasks[j].latitude,
                longitude : this.tasks[j].longitude,
                lat : this.tasks[j].latitude,
                lng : this.tasks[j].longitude,
                location : this.tasks[j].location,
                taskName : this.tasks[j].taskName,
                icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                startTime : this.tasks[j].startDateTime,
                endTime : this.tasks[j].endDateTime,
                id : this.tasks[j].id,
                 label:(j+1).toString()
              }
            } else {
              var temp : any = {
                origin: { lat: this.tasks[j-1].latitude, lng: this.tasks[j-1].longitude  },
                destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
                visible: true,
                travelMode: google.maps.TravelMode.DRIVING
              }
              var marker : any= {
                latitude : this.tasks[j].latitude,
                longitude : this.tasks[j].longitude,
                lat : this.tasks[j].latitude,
                lng : this.tasks[j].longitude,
                location : this.tasks[j].location,
                taskName : this.tasks[j].taskName,
                icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                startTime : this.tasks[j].startDateTime,
                endTime : this.tasks[j].endDateTime,
                id : this.tasks[j].id ,
                label:(j+1).toString()
              }
            }
            ///this.directionsResults$ = this.mapDirectionsService.route(temp).pipe(map((response:any) => return response.result));

            this.markerWaypoints.push(temp);
            this.markers.push(marker);
            this.zoom = 15
          }
        }
        var groupedMatchingLocations = _.groupBy(this.markers, 'location');
        for (var key in groupedMatchingLocations) {
          if (groupedMatchingLocations.hasOwnProperty(key) && groupedMatchingLocations[key].length > 1) {
            for (var index in groupedMatchingLocations[key]) {
              if(parseInt(index) > 0){
                groupedMatchingLocations[key][index].latitude = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                groupedMatchingLocations[key][index].longitude = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                //console.log(key, groupedMatchingLocations[key][index]);
              }
            }
          }
        }
        //console.log('--------------', this.markerWaypoints, this.markers);
        //console.log('Todays Tasks API With Map Changes', this.tasks);
        if(this.markers.length)
          this.fitBounds(this.markers);
      });
    }
  }

  renderMap(){
    console.log(this.userMarkers)
      this.optionsMap.center= { lat: this.latitude, lng: this.longitude }
      this.zoom=5;
      this.latlngBounds = new window['google'].maps.LatLngBounds();
      if(this.userMarkers.length){
        this.userMarkers.forEach((location) => {
          if(location.userLattitude && location.userLongitude){
            this.latlngBounds.extend(new window['google'].maps.LatLng(location.lat, location.lng));
          }
        })
      } else {
       this.latlngBounds.extend(new window['google'].maps.LatLng(this.latitude, this.longitude))
      }
  }

  loadUserLocations(allStaff: any, online: any){
    var tempUserArray : any= Object.assign([],this.userMarkers);
    this.userMarkers = [];
    if(this.markerWaypoints.length){
      this.markerWaypoints.forEach((direction) => {
        direction.visible = false;
      })
    }
    this.teamService.getManagedUsersWithTasks(this.auth.getUserId(), null, online, allStaff, null, null, null).subscribe((users:any) => {
      let iconUrl = '/assets/images/map-icons/userIcons/'
      if(users){
        if(this.userSelected){
          this.userMarkers = _.filter(users, (o) => {
            return o.id === tempUserArray[0].id
          });
          this.onRefreshMap(this.userMarkers);
        } else {
          users.forEach((element : any)=> {
            if(element.userLattitude && element.userLongitude){
              let firstLetter = element.firstName.charAt(0).toLowerCase();
              let secondLetter = element.lastName.charAt(0).toLowerCase();
              let statusLetter, path;
              if(element.online){
                path = '../../../../assets/svg/Planing-hub/green-marker.svg';
                statusLetter = 'g.png';
              } else {
                path = '../../../../assets/svg/Planing-hub/red_marker.svg'
                statusLetter = 'r.png'
              }
              element.lat = element.userLattitude 
              element.lng = element.userLongitude
              element.markerUrl = path
              element.label = firstLetter.toUpperCase() + secondLetter.toUpperCase() 
              this.userMarkers.push(element);
            }
          });
        }
      }
      this.renderMap();
      //console.log("live Tracking ", this.userMarkers);
    });
  }

  userSelectedSet(val: any){
    // this.userSelected = val;
    // this.markerWaypoints.forEach((direction) => {
    //   direction.visible = false;
    // })
    // ////debugger
    if(!val){
      if(this.markerWaypoints.length){
        this.markerWaypoints.forEach((direction) => {
          direction.visible = false;
        })
      }
    }
    //console.log("User Select Set ", val);
  }
  private initMap() {
    // if ("geolocation" in navigator) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     this.latitude = position.coords.latitude;
    //     this.longitude = position.coords.longitude;
    //     this.optionsMap = {
    //       center: { lat: this.latitude, lng: this.longitude },
    //       zoom: 15,
    //     };

    //     this.zoom = 15;
        //console.log(position);

        if(this.teamView === 'true'){
          this.initializeWebSocketConnection();
          this.loadUserLocations(null, null);
          // this.sub = Observable.interval(15000)
          // .subscribe((val) => { 
          //   this.loadUserLocations();
          // });
        } else {
          if(this.taskId){
            this.taskService.getTaskById(this.taskId).subscribe((task:any)=>{
              this.tasks.push(task.body);
              ////debugger
              if(this.tasks.length){
                // if(this.showDirections){
                //   var marker : any = {
                //     userLattitude : this.latitude,
                //     userLongitude : this.longitude,
                //     firstName : 'Current Location',
                //     markerUrl : '../../../../assets/images/map-icons/current_location_marker.png'
                //   }
                //   this.userMarkers.push(marker);
                // }
                for(var j=0;j<this.tasks.length;j++){
                  if(j == 0){
                    var temp : any = {
                      origin: { lat: this.latitude, lng: this.longitude },
                      destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : this.tasks[j].latitude,
                      longitude : this.tasks[j].longitude,
                      lat : this.tasks[j].latitude,
                      lng : this.tasks[j].longitude,
                      location : this.tasks[j].location,
                      taskName : this.tasks[j].taskName,
                      icon : '../../../../assets/images/map-icons/task_icon.png',
                      startTime : this.tasks[j].startDateTime,
                      endTime : this.tasks[j].endDateTime,
                      id : this.tasks[j].id 
                    }
                  } else if (j == this.tasks.length-1){
                    var temp : any = {
                      origin: { lat: this.tasks[j-1].latitude, lng: this.tasks[j-1].longitude  },
                      destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : this.tasks[j].latitude,
                      longitude : this.tasks[j].longitude,
                      lat : this.tasks[j].latitude,
                      lng : this.tasks[j].longitude,
                      location : this.tasks[j].location,
                      taskName : this.tasks[j].taskName,
                      icon : '../../../../assets/images/map-icons/task_icon.png',
                      startTime : this.tasks[j].startDateTime,
                      endTime : this.tasks[j].endDateTime,
                      id : this.tasks[j].id 
                    }
                  } else {
                    var temp : any = {
                      origin: { lat: this.tasks[j-1].latitude, lng: this.tasks[j-1].longitude  },
                      destination: { lat: this.tasks[j].latitude, lng: this.tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : this.tasks[j].latitude,
                      longitude : this.tasks[j].longitude,
                      lat : this.tasks[j].latitude,
                      lng : this.tasks[j].longitude,
                      location : this.tasks[j].location,
                      taskName : this.tasks[j].taskName,
                      icon : '../../../../assets/images/map-icons/task_icon.png',
                      startTime : this.tasks[j].startDateTime,
                      endTime : this.tasks[j].endDateTime,
                      id : this.tasks[j].id 
                    }
                  }
                  this.markerWaypoints.push(temp);
                  this.markers.push(marker);
                }
              }
              var groupedMatchingLocations = _.groupBy(this.markers, 'location');
              for (var key in groupedMatchingLocations) {
                if (groupedMatchingLocations.hasOwnProperty(key) && groupedMatchingLocations[key].length > 1) {
                  for (var index in groupedMatchingLocations[key]) {
                    if(parseInt(index) > 0){
                      groupedMatchingLocations[key][index].latitude = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      groupedMatchingLocations[key][index].longitude = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                       groupedMatchingLocations[key][index].lat = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      groupedMatchingLocations[key][index].lng = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      //console.log(key, groupedMatchingLocations[key][index]);
                    }
                  }
                }
              }
              console.log('--------------', this.markers);
              ////debugger
              //console.log('Todays Tasks API With Map Changes', this.tasks);
              if(this.markers.length)
                this.fitBounds(this.markers);
            });
          } else if(this.date){
            this.taskService.getTodaysTasks(this.auth.getUserId(), this.date, this.auth.getUserTimezone()).subscribe((tasks:any)=>{
              this.tasks = tasks;
              tasks = _.filter(tasks, (o) => {
                return o.assignStatus == 'ACCEPTED'
              });
              if(tasks.length){
                // var marker : any = {
                //   userLattitude : this.latitude,
                //   userLongitude : this.longitude,
                //   firstName : 'Current Location',
                //   markerUrl : '../../../../assets/images/map-icons/current_location_marker.png'
                // }
                // this.userMarkers.push(marker);
                for(var j=0;j<tasks.length;j++){
                  if(j == 0){
                    var temp : any = {
                      origin: { lat: tasks[j].latitude, lng: tasks[j].longitude },
                      destination: { lat: tasks[j].latitude, lng: tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : tasks[j].latitude,
                      longitude : tasks[j].longitude,
                      lat : tasks[j].latitude,
                      lng : tasks[j].longitude,
                      location : tasks[j].location,
                      taskName : tasks[j].taskName,
                      icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                      startTime : tasks[j].startDateTime,
                      endTime : tasks[j].endDateTime,
                      id : tasks[j].id ,
                       label:(j+1).toString()
                    }
                  } else if (j == tasks.length-1){
                    var temp : any = {
                      origin: { lat: tasks[j-1].latitude, lng: tasks[j-1].longitude  },
                      destination: { lat: tasks[j].latitude, lng: tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : tasks[j].latitude,
                      longitude : tasks[j].longitude,
                      lat : tasks[j].latitude,
                      lng : tasks[j].longitude,
                      location : tasks[j].location,
                      taskName : tasks[j].taskName,
                      icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                      startTime : tasks[j].startDateTime,
                      endTime : tasks[j].endDateTime,
                      id : tasks[j].id ,
                       label:(j+1).toString()
                    }
                  } else {
                    var temp : any = {
                      origin: { lat: tasks[j-1].latitude, lng: tasks[j-1].longitude  },
                      destination: { lat: tasks[j].latitude, lng: tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : tasks[j].latitude,
                      longitude : tasks[j].longitude,
                      lat : tasks[j].latitude,
                      lng : tasks[j].longitude,
                      location : tasks[j].location,
                      taskName : tasks[j].taskName,
                      icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                      startTime : tasks[j].startDateTime,
                      endTime : tasks[j].endDateTime,
                      id : tasks[j].id,
                       label:(j+1).toString() 
                    }
                  }
                  this.markerWaypoints.push(temp);
                  this.markers.push(marker);
                }
              }
              var groupedMatchingLocations = _.groupBy(this.markers, 'location');
              for (var key in groupedMatchingLocations) {
                if (groupedMatchingLocations.hasOwnProperty(key) && groupedMatchingLocations[key].length > 1) {
                  for (var index in groupedMatchingLocations[key]) {
                    if(parseInt(index) > 0){
                      groupedMatchingLocations[key][index].latitude = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      groupedMatchingLocations[key][index].longitude = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);

                      groupedMatchingLocations[key][index].lat = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      groupedMatchingLocations[key][index].lng = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      //console.log(key, groupedMatchingLocations[key][index]);
                    }
                  }
                }
              }
              //console.log('--------------', this.markerWaypoints, this.markers);
              //console.log('Todays Tasks API With Map Changes', this.tasks);
              if(this.markers.length)
                this.fitBounds(this.markers);
            });
          } else {
            this.taskService.getTodaysTasks(this.auth.getUserId(), moment().format('YYYY-MM-DD'), this.auth.getUserTimezone()).subscribe((tasks:any)=>{
              this.tasks = tasks;
              tasks = _.filter(tasks, (o) => {
                return o.assignStatus == 'ACCEPTED'
              });
              if(tasks.length){
                // var marker : any = {
                //   userLattitude : this.latitude,
                //   userLongitude : this.longitude,
                //   firstName : 'Current Location',
                //   markerUrl : '../../../../assets/images/map-icons/current_location_marker.png'
                // }
                // this.userMarkers.push(marker);
                for(var j=0;j<tasks.length;j++){
                  if(j == 0){
                    var temp : any = {
                      origin: { lat: tasks[j].latitude, lng: tasks[j].longitude },
                      destination: { lat: tasks[j].latitude, lng: tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : tasks[j].latitude,
                      longitude : tasks[j].longitude,
                      lat : tasks[j].latitude,
                      lng : tasks[j].longitude,
                      location : tasks[j].location,
                      taskName : tasks[j].taskName,
                      icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                      startTime : tasks[j].startDateTime,
                      endTime : tasks[j].endDateTime,
                      id : tasks[j].id,
                       label:(j+1).toString() 
                    }
                  } else if (j == tasks.length-1){
                    var temp : any = {
                      origin: { lat: tasks[j-1].latitude, lng: tasks[j-1].longitude  },
                      destination: { lat: tasks[j].latitude, lng: tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : tasks[j].latitude,
                      longitude : tasks[j].longitude,
                      lat : tasks[j].latitude,
                      lng : tasks[j].longitude,
                      location : tasks[j].location,
                      taskName : tasks[j].taskName,
                      icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                      startTime : tasks[j].startDateTime,
                      endTime : tasks[j].endDateTime,
                      id : tasks[j].id,
                       label:(j+1).toString() 
                    }
                  } else {
                    var temp : any = {
                      origin: { lat: tasks[j-1].latitude, lng: tasks[j-1].longitude  },
                      destination: { lat: tasks[j].latitude, lng: tasks[j].longitude },
                      visible: true,
                      travelMode: google.maps.TravelMode.DRIVING
                    }
                    var marker : any= {
                      latitude : tasks[j].latitude,
                      longitude : tasks[j].longitude,
                      lat : tasks[j].latitude,
                      lng : tasks[j].longitude,
                      location : tasks[j].location,
                      taskName : tasks[j].taskName,
                      icon : '../../../../assets/svg/Planing-hub/green-marker.svg',
                      startTime : tasks[j].startDateTime,
                      endTime : tasks[j].endDateTime,
                      id : tasks[j].id,
                       label:(j+1).toString() 
                    }
                  }
                  this.markerWaypoints.push(temp);
                  this.markers.push(marker);
                }
              }
              var groupedMatchingLocations = _.groupBy(this.markers, 'location');
              for (var key in groupedMatchingLocations) {
                if (groupedMatchingLocations.hasOwnProperty(key) && groupedMatchingLocations[key].length > 1) {
                  for (var index in groupedMatchingLocations[key]) {
                    if(parseInt(index) > 0){
                      groupedMatchingLocations[key][index].latitude = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      groupedMatchingLocations[key][index].longitude = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);

                      groupedMatchingLocations[key][index].lat = groupedMatchingLocations[key][index].latitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      groupedMatchingLocations[key][index].lng = groupedMatchingLocations[key][index].longitude + (Math.random() -.00005) / 15000;// * (Math.random() * (max - min) + min);
                      //console.log(key, groupedMatchingLocations[key][index]);
                    }
                  }
                }
              }
              console.log('--------------', this.markerWaypoints, this.markers);
              //console.log('Todays Tasks API With Map Changes', this.tasks);
              if(this.markers.length)
                this.fitBounds(this.markers);
            });
          }
        } 
    //   });
    // }
    // this.showMap = true;
  }


  goBack(){
    window.history.back();
  }

  showTaskDetails(marker: any){
    let selectedTask = _.filter(this.tasks, (o) => {
      return o.id == marker.id
    });
    if(selectedTask.length)
      this.viewTaskDetail(selectedTask[0]);
  }

  viewTaskDetail(data: any){
   /// this.taskService.viewTaskDetail(data);
  }

  openModal(modalView: any, data: any){
    this.modalService.openModal(modalView, data);
  }

  fitBounds(markers: any) {
    setTimeout(() => {
      if(this.taskId){
         this.zoom = 5;
         this.latitude = markers[0].latitude;
         this.longitude = markers[0].longitude;
         this.optionsMap.center= { lat: this.latitude, lng: this.longitude }
       
        
       } else {
    //     this.mapsAPILoader.load().then(() => {
           this.latlngBounds = new window['google'].maps.LatLngBounds();
          markers.forEach((marker: any) => {
            this.latlngBounds.extend(new window['google'].maps.LatLng(marker.lat, marker.lng))
          }); 
          this.userMarkers.forEach((marker) => {
            this.latlngBounds.extend(new window['google'].maps.LatLng(marker.lat, marker.ngg))
          });
    //     })
      }
    }, 1500);
  }

  showInfoWindow(infoWindow: any, user: any) {
    var geocoder = new google.maps.Geocoder;
    //console.log(position);
    var self = this;
    var latlng = {lat: user.userLattitude, lng: user.userLongitude};
    geocoder.geocode({'location': latlng}, function(results: any, status: any) {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          self.selectedUserAddress = results[0].formatted_address;
        }
      }
    });
    if (this.infoWindowOpened === infoWindow) {
        return;
    }

    if (this.infoWindowOpened !== null) {
        //this.infoWindowOpened.close();
    }
    
    this.infoWindowOpened = infoWindow;   
  }
  pullUp(){
    const a = document.querySelector('.custella-taskOpt-footer');
    if(a!.classList.contains('slideUpTask')){
      this.empClass = "pullUp";
      a!.classList.remove('slideUpTask');
      a!.classList.add('slideBottomTask');
  
    }else{
      this.empClass = "pullDown";
      a!.classList.remove('slideBottomTask');
      a!.classList.add('slideUpTask');
    }
  }





  loadUsers(){
    this.teamService.getManagedUsersWithTasks(this.auth.getUserId(), null, null, null, null, null, null).subscribe(users => {
      if(!users){
        return;
      }
      this.users = users;
      this.users.forEach((element:any) => {
        element.name = element.firstName + ' ' + element.lastName
      });
      this.originalArray = Object.assign([], this.users);
      console.log("Users API ", this.users);
    });
  }

  filterUsers(){
    var allStaff = null;
    var online = null;
    if(this.filter.typeOfUsers == 'SUBORDINATES'){
      allStaff = true;
    }
    
    if(this.filter.onlineUsers == 'ONLINE'){
      online = 'ONLINE';
    } else if(this.filter.onlineUsers == 'OFFLINE'){
      online = 'OFFLINE';
    }

    var filterObj = {
      'online' : online,
      'allStaff' : allStaff
    }
    this.loadUserLocations(allStaff, online);
  
    this.teamService.getManagedUsersWithTasks(this.auth.getUserId(), 'true', online, allStaff, 'true', null, null).subscribe((res:any) => {
      if(!res){
        return;
      }
      this.users = res;
      this.users.forEach((element :any)=> {
        element.name = element.firstName + ' ' + element.lastName
      });
      this.originalArray = Object.assign([], this.users);
    });
  }

  

  loadMoreUserTasks(user : any) {
    
    if(this.mapUsersArray.length){
      if(this.mapUsersArray[0].id == user.id){
        this.mapUsersArray[0].status = 'checkOut';
        this.mapUsersArray = [];
        this.selectUser = false;
      } else {
        this.mapUsersArray[0].status = 'checkOut';
        user.status= 'checkIn';
        this.mapUsersArray = [];
        this.mapUsersArray.push(user);
        this.selectUser = true;
      }
    } else {
      this.selectUser = true;
      user.status= 'checkIn';
      this.mapUsersArray.push(user);
    }

    this.userSelectedSet(this.selectUser);
    this.onRefreshMap(this.mapUsersArray);
  }

  // checkInStudent(user) {
  //   this.mapUsersArray.push(user);
  //   var checkStudent = _.includes(this.mapUsersArray, user);
  //   if(checkStudent){
  //     // var index = _.findIndex(this.mapUsersArray, function(o) { return o.id == user.id; });
  //     // console.log("Index Check ", index);
  //     // this.mapUsersArray.splice(index, 1);
  //   }
  //   else{
  //     this.mapUsersArray.push(user);
  //   }
  //   console.log(this.mapUsersArray);
  // }

  filterItem(value: any){
    if(!value) this.users = this.originalArray; //when nothing has typed
    var filteredItems = Object.assign([], this.originalArray).filter((
      item :any)=> item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
    this.users = filteredItems;
  }

  searchUsers(searchParam: any){
    var allStaff = true;
    if(this.filter.typeOfUsers == 'SUBORDINATES'){
      allStaff = false; // all means false
    }
    if(searchParam.length > 0){
      this.usersService.advancedSearchSetupUsers(searchParam, allStaff).subscribe((users:any)=>{
        for(var i=0;i<=users.length-1;i++){
          users[i].userLattitude = users[i].locationLattitude;
          users[i].userLongitude = users[i].locationLongitude;

          users[i].lat = users[i].locationLattitude;
          users[i].lng = users[i].locationLongitude;        }
        this.users = users;
      });
    }
    else{
      //this.loadUsers();
      this.filterUsers();
    }
  }
  refresh() {
    this.searchEmployee = "";
    this.filterUsers();
    }
  openInfoWindow(marker: MapMarker,data : any) {
    var geocoder = new google.maps.Geocoder;
    console.log(marker)
    var self = this;
    var latlng = {lat: data.userLattitude, lng: data.userLongitude};
    geocoder.geocode({'location': latlng}, function(results: any, status: any) {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          self.selectedUserAddress = results[0].formatted_address;
        }
      }
    });
    this.displayActual = true
    this.selectedUserdata = data
    this.infoWindow.open(marker);
  }

  openInfoWindow2(marker: MapMarker,data : any) {
   console.log(data)
   this.displayActual = false
    this.selectedUserdata = data
    this.infoWindow.open(marker);
  }
}
