import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {of as observableOf,  Observable, throwError } from 'rxjs';
import { Http, RequestOptions, Headers } from "@angular/http";
import { AuthenticationService } from '../auth/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class CalendlyIntService {
  refreshList$ = new EventEmitter();
 
  private ENDPOINT_CALENDLY= "/calendly-event-job-order-types"
  // previous image for when in company settings upload but cancel operation
  private previousImage: any;

  constructor(private http: HttpClient,
    private httpOnly: Http,
    private auth: AuthenticationService,) {
      
    this.refreshList$ = new EventEmitter(); 
  }

   /**------------------------- Update ------------------------*/
public getCalendlyJobOrderTypes(){
  return this.http.get( environment.base_url+this.ENDPOINT_CALENDLY).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return observableOf( error );
  }),);
}
  /**------------------------- Update ------------------------*/

public updateCalendly(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_CALENDLY, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(body);
    return resp;
  }
),catchError( error => {
  ////console.log(error);
      return observableOf( error );
  }),);
}

 /**------------------------- Update ------------------------*/

 public updateTheme(body:any){
  return this.http.put( environment.base_url+'/tenant-integration-configs', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(body);
    return resp;
  }
),catchError( error => {
  ////console.log(error);
      return observableOf( error );
  }),);
}

/*****************************************Upload Customer Attchment *********************************************************************/
public uploadAttachment(formData: any) {
  const headers = new Headers({});
  headers.append("Authorization", "Bearer " + this.auth.getToken());
  let options = new RequestOptions({ headers: headers });
  return this.httpOnly
    .post( environment.base_url +'/attachments/upload?parentType=DYNAMIC_FORM', formData, options ).pipe(map((resp: any) => {
         this.refreshList$.emit(resp);
         return JSON.parse(resp._body);
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}

/**------------------------- Get By Id ------------------------*/
public getCalendlyLink(joId: any){
  return this.http.get( environment.base_url+'/job-order-calendly/first/' + joId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

/**------------------------- Generate SUrvey Link ------------------------*/
public generateCalendlyLink(body:any){
  if(!body.id){
    return this.http.post( environment.base_url+'/job-order-calendly', body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(body);
      return resp;
    }),catchError( error => {
        return throwError( error );
    }));
  } else {
    return this.http.put( environment.base_url+'/job-order-calendly', body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(body);
      return resp;
    }),catchError( error => {
        return throwError( error );
    }));
  }
}

/**------------------------- Generate SUrvey Link ------------------------*/
public sendCalendlyLink(body:any){
  return this.http.put( environment.base_url+'/job-order-calendly/send-email', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}


public getPublicCalendlyTheme(tenantId: any){
  return this.http.get( environment.base_url+'/open/tenant-integration-configs?integrationType=CALENDLY&tenantId='+tenantId).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return observableOf( error );
  }),);
}

}

