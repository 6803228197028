import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ImportExportService {

  public refreshList$: EventEmitter<any>;
  public confirmImportEmit$: EventEmitter<any>;
  private ENDPOINT_MODULE_LIST = "/export/module/list";
  private ENDPOINT_MODULE_FIELD_LIST = "/export/module/fields";
  private ENDPOINT_EXPORT = "/export/module";
  private ENDPOINT_HEADER_VLIDATION = "/csv/import/header-values";
  private ENDPOINT_IMPORT = "/csv/import";
  private ENDPOINT_LOGS = "/import-export-logs";

  constructor(private http: HttpClient, private auth: AuthenticationService, private httpOnly: Http) {
    this.refreshList$ = new EventEmitter();
    this.confirmImportEmit$ = new EventEmitter();
  }

  public getLogList(parameters: any) {
    let params = new HttpParams();
    params = params.set('startFrom', parameters.start).set('perPage', parameters.length).set('search', parameters.search.value);

    return this.http.get(environment.base_url + this.ENDPOINT_LOGS, { params: params }).pipe(map((resp: any) => {
      return resp;
    }), catchError((error: any) => {
      return throwError(error);
    }));
  }

  importExportLogs( searchValue: any,parameters: any) {
    let params = new HttpParams();
  
    if(searchValue !== '' && searchValue != null){
      params = params.set('search', searchValue);
    }
    console.log(parameters)
    if(parameters)
  
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    
    return this.http.get(environment.base_url + '/import-export-logs', { observe: "response",params : params }).pipe(map((resp: any) => {
        return resp.body;
      }), catchError((error: any) => {
        return throwError(error);
      }));
  }

  public deleteLogItem(id: number) {
    return this.http.delete(environment.base_url + this.ENDPOINT_LOGS + "/" + id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit({ result: 'success' });
      return resp;
    }), catchError((error: any) => {
      return throwError(error);
    }));
  }

  public getModuleList() {
    return this.http.get(environment.base_url + this.ENDPOINT_MODULE_LIST).pipe(map((resp: any) => {
      return resp;
    }), catchError((error: any) => {
      return throwError(error);
    }));
  }

  public getModuleFieldList(moduleName: string, includeRelational: any) {
    let params = new HttpParams().set('moduleName', moduleName);
    params = params.set('includeRelational', includeRelational);

    return this.http.get(environment.base_url + this.ENDPOINT_MODULE_FIELD_LIST, { params: params }).pipe(map((resp: any) => {
      return resp;
    }), catchError((error: any) => {
      return throwError(error);
    }));
  }

  public exportCsv(body: any, moduleName: string) {
    let options: any = { headers: { Authorization: 'Bearer ' + this.auth.getToken(), Accept: 'application/csv' } };
    options.responseType = 'blob';
    return this.http.put<Blob>(`${environment.base_url}/export/module?moduleName=${moduleName}`, body, options).pipe(map((resp: any) => {
      return resp;
    }), catchError((error: any) => {
      return throwError(error);
    }));
  }

  public uploadCSV(formData: any) {
    let options: any = { headers: { 'Authorization': 'Bearer ' + this.auth.getToken() } };
    return this.httpOnly.post(environment.base_url + this.ENDPOINT_HEADER_VLIDATION, formData, options).pipe(map((resp: any) => {
      if (resp._body == '') this.refreshList$.emit(resp);
      else this.refreshList$.emit(resp);
      return resp;
    }), catchError((error: any) => {
      return throwError(error);
    }));
  }
  public uploadCSVFile(formData: any) {
    let options: any = { headers: { 'Authorization': 'Bearer ' + this.auth.getToken() } };
    return this.httpOnly.post(environment.base_url + this.ENDPOINT_IMPORT, formData, options).pipe(map((resp: any) => {
      if (resp._body == '') this.refreshList$.emit(resp);
      else this.refreshList$.emit(resp);
      return resp;
    }), catchError((error: any) => {
      return throwError(error);
    }));
  }

  public getKeysByModule(moduleName: any) {
    return this.http.get(environment.base_url + '/csv/import/update-keys?moduleName=' + moduleName).pipe(map((resp: any) => {
      return resp;
    }), catchError((error: any) => {
      return throwError(error);
    }));
  }

  public markResultSuccess() {
    this.refreshList$.emit({ result: 'success' });
  }
  public confirmImport() {
    this.confirmImportEmit$.emit({ result: 'import' });
  }
  public getLicenceKeysByModule(key : any) {
    var data =[
      {   "key": "task",
          "trial": "LJCQ95amOXmSOO9xLKtqnjLumhNEA8",
          "uat":"",
          "prod": "Rvq2u4ahHPUQiVS7Xzthc4F9hCnfxH",
          "aut":"4zOQyLlftaK8c4y3exI3EV2aXam53o",
          "beta": "UhuKHd7PCUgJsQ4bpNHkS9xG969PPl",
          "chubb": "XI8bYGfQPtjI1GqtmBnuhv8uXi90fl"
          
      },
      {
          "key": "user_task",
          "trial": "o5V6vpvuvRaWjUrUizqgDf5rZgJivO",
          "uat":"",
          "prod": "EHs5tCiRXfq2RqrZP5615aGslofWsK",
          "aut":"YCerGoNrq8H4zJDpI9eb8xjP4FTndq",
          "beta": "doKBnfKAlF8rSHO1fcxhSEZHZSspI0",
          "chubb": "bJUQHsv1HHaA0ub8NJcQAwyCXBMvQE"
      },
      {
          "key": "customer",
          "trial": "5edaxrVCDty9AOmHMudsndQkqXz07b",
          "uat":"",
          "prod": "BNHmxtOQ4WBcExKAYS9AzOQr1LDeIi",
          "aut":"vm3VEzRFO7ZTA3sL7Bj8g0z81TyFWn",
          "beta": "qjhoBv8NQwmzZZdG7G0XRwrr1xqiBz",
          "chubb": "Tag2REDTfnC5C5e4WeRv3nQsZ5wNuP"
      },
      {
          "key": "sales_order",
          "trial":"HnQRAVCHkmx93GJ2NbDhSCGcoKH5mp",
          "uat":"",
          "prod":"qWeY98h82FVtsVPbztb1BcV9H23BdQ",
          "aut":"FUcxdl3VkQEVeDtSrFYflyxj1VXMqJ",
          "beta": "vrrOvoDBw4vpkKkJ3kiCPz2VbJrfXd",
          "chubb": "xWgzxedHJuyar5PhS6lppOpMamp6Sc"
      },
      {
          "key": "sales_order_product",
          "trial": "Sukekr0WSEx0nVIfG6tjGffec6j3X7",
          "uat":"",
          "prod": "GQ9DFD5WMc6VDyQYYtYChW4EcHYRnj",
          "aut":"gd2Ih67v8A9Ht7GztUco6lLvVdHnOx",
          "beta": "GfigOeF9ZUubR1tK5TMvLICbF7DM2l",
          "chubb": "2YVI0SUybTPGXPGsayi0d8iJEUynKJ"
      },
      {
          "key": "job_order",
          "trial": "dxD33KMhwzoQzzZFi9tm5p8mj6PjPA",
          "uat":"",
          "prod": "amipsjzjNmHTey4jmtV0dIAAzaZ5R7",
          "aut":"c6AdCZcNlfQDAGORDobF1JOLXLWzc9",
          "beta": "ZzaJ4nevea2xAGtSId3EmZGrPdNWwr",
          "chubb": "um3ehOxm7n8vptrg3TguDykcxw3Qzi"
      },
      {
          "key": "job_order_product_item",
          "trial": "AKk3Ss3zjPVwIS73xpv1yRef2qNoUP",
          "uat":"",
          "prod": "swWqqx9jdzMhmuFpOX2sqxx6bQyAnJ",
          "aut":"4Hyc8tUH6zD5YUxcqFgCvm0lKBRc7K",
          "beta": "wMAzunw69m6VDEQhc6sjItewRgUu5e",
          "chubb": "fiLAHsvgdhBfIieb9cyyUXCphXao84"
      },
      {
          "key": "contact",
          "trial": "d9x0clGhHURykbswzxqMQyu5yaSiLS",
          "uat":"",
          "prod": "E1HE5CPh10TagbkIPB36MMyK6znL4j",
          "aut":"hbNxlRkDjZWgi7pBj7yud4FrRk7ETR",
          "beta": "wEAPT2anyIjbP3hzjPT9usEegusceD",
          "chubb": "IxhYvKan4Sqx8WBd7IRNaCSnBsfCNi"
      },
      {
          "key": "product",
          "trial": "6kGdNrIrUcLABAiEbaneLLeEfmW27i",
          "uat":"",
          "prod": "BKv8udPrqrQGqaq7TXgV0acE1kSkGZ",
          "aut":"2wRA13V9nEIXpwaqwX5qDHvJnaCQxW",
          "beta": "t0P9mMZbfzHX1VegTiZ7t1LJZvbfTF",
          "chubb": "v1M7LdFJLViotQzegkzl9RehjQ164v"
      },
      {
          "key": "team",
          "trial": "DKMsvAyiXWxYKBFkpjrf8IgRV2jPrD",
          "uat":"",
          "prod": "f15njl2ojObFFPi1DpHDZ6z2M3qUGM",
          "aut":"eJZix83gmXrfeiIfzSbp97nJjkpioP",
          "beta": "s110LrJf19zEGzcpJwsuIu9WmgIze2",
          "chubb": "JEdPhEB7E4deY1dXgZi9j4iYZ64j4d"
      },
      {
          "key": "team_user",
          "trial": "CIXKCpLVwqFJEANBUVN3LxKkhF84Hv",
          "uat":"",
          "prod": "GBuvZh6Fk1JWCVsCf6nAJ7cvszr0WW",
          "aut":"a2oy4QAVvRniofTzsVzYCqt1n9WfhS",
          "beta": "6dU3hrk7tV1GJiJDnr9RTUBNcomyHU",
           "chubb": "GozzFbSbY4K28iAwFx2CgRQYtX2tsa"
      },
      {
          "key": "maintenance_item",
          "trial": "xUgkGOSbmEqKfhVerG6aropJaHpgHo",
          "uat":"",
          "prod": "YwT34kMJzuAzF0HpK8JHv1Ib3wNPxx",
          "aut":"wQHVrk1sjw7ImyLHk0RUImpkyQI2nr",
          "beta": "GPKZAHlKYGZMnCKRlslArojSSee77g",
          "chubb": "njCjdKvAr4ChHIs0oKcgtbBYuaXk3f"
      },
      {
          "key": "assets",
          "trial": "xlgKaTZPsH4pNoMnaRO9RBjKJW0a3z",
          "uat":"",
          "prod": "bkLB5IdYhjeFhrKwtufvPannwBnJYi",
          "aut":"j41CFgWgfjkvAH7ZTF9t8Omq7r0qVD",
          "beta": "l83ZMa3fnqRxmUM8JwMopWsNoVocqR",
          "chubb": "AT3qlUhAvjOmFoei50ChLAvdWYHCDd"
      }
  ]
  var envi = environment.environment_name
  var findLicence = _.filter(data, (o) => { return o.key == key;})
  if(findLicence[0]){
    return findLicence[0][envi]
  }
  
  }

  public importComapleted(body: any) {
    return this.http.post(environment.base_url + '/import/import-complete', body, { observe: "response",})
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  
}
