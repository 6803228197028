// Author: T4professor

import { Component, OnDestroy } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { LanguageTranslateService } from "../_services/language-translate.service";
import { SubscriptionUtil } from "../_utilities/subscription";

@Component({
  selector: "app-button-renderer",
  template: `
    <button *ngIf="actionMS && actionMS =='ActionMS'" [hidden]="this.hiddenAccept" (click)="onClick($event, 'acceptMS')" class="custella-link mr-2 p-button p-1 m-1 h-20 ms-action-button"  type="button">
    <span class="p-button-label ng-star-inserted" aria-hidden="false">{{translate?.general.buttons.accept.singular}}</span><span class="p-ink"></span></button>

    <button type="button" *ngIf="this.permissions?.clone" class="custella-ed-button p-0" (click)="onClick($event, 'clone')" >
      <i class="fa-regular fa-clone"></i>
    </button>
    <button type="button" [id]="buttonEdit" *ngIf="!label || actionMS =='ActionMS'" [pTooltip]="translate?.general.buttons.edit.singular" tooltipPosition="bottom"  class="custella-ed-button p-0" [disabled]="!this.permissions.update" [hidden]="this.hidden.update" (click)="onClick($event, 'edit')">
      <i class="fa-regular fa-pen"></i>
    </button>
    <button type="button" [id]="buttonDelete" *ngIf="!label || actionMS =='ActionMS'" [pTooltip]="translate?.general.buttons.delete.singular" tooltipPosition="bottom" class="custella-del-button p-0" [disabled]="!this.permissions.remove" [hidden]="this.hidden.remove" (click)="onClick($event, 'delete')" >
      <i class="fa-regular fa-trash"></i>
    </button>

    <button
      type="button"
      *ngIf="label && label !== 'Select'"
      class="custella-transp-button p-0 custella-link mr-3 text-1-5 py-1 font-weight-bold table_default_color"
      (click)="onClick($event, label)"
    >
       {{translate?.general.buttons.action.singular}} 
    </button>
    <button
      type="button"
      *ngIf="label && label == 'Select'"
      class="btn btnrender f-white text-1 py-0 hand"
      (click)="onClick($event, label)"
    >
      {{ label }}
    </button>

    <button
      type="button"
      *ngIf="labelJMP == 'JMP' && hiddenJMP"
      class="custella-transp-button ms-2"
      (click)="onClick($event, 'Approve')"
      style="background: transparent;"
      [pTooltip]="translate?.general.buttons.approve.singular" tooltipPosition="bottom"
    >
      <img
        class="cust-ed-del me-1"
        style="width: 15px"
        src="assets/images/chubb/green_optimized.png"
        alt=""
      />
    </button>
    <button
      type="button"
      *ngIf="labelJMP == 'JMP' && hiddenJMP"
      class="custella-transp-button ms-2"
      (click)="onClick($event, 'Reject')"
      style="background: transparent;"
      [pTooltip]="translate?.general.buttons.reject.singular" tooltipPosition="bottom"
    >
      <img
        class="cust-ed-del ms-1"
        style="width: 15px"
        src="assets/images/chubb/reject_leave.png"
        alt=""
      />
    </button>
  `,
})
export class ButtonRendererComponent extends SubscriptionUtil implements ICellRendererAngularComp, OnDestroy {
  params!: any;
  label!: string;
  permissions: any;
  hidden: any = { update: false, remove: false, clone: false };
  hiddenAccept: boolean = false;
  hiddenJMP: boolean = false;
  labelJMP: any;
  buttonEdit: any = 'Btn_RowEdit'
  buttonDelete: any = 'Btn_RowDel'

  actionMS: any;
  translate: any;

  constructor(
    private languageTranslateService: LanguageTranslateService,)
  {
    super()
this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
  if(Object.keys(data).length != 0){
    this.translate = data
  }
}))
  }

  agInit(params: any): void {
    //console.log("params11",params)
    this.params = params;
    if (this.params.data != undefined && this.params.data.buttonEdit != undefined) {
      this.buttonEdit = this.params.data.buttonEdit
      this.buttonDelete = this.params.data.buttonDelete
      //console.log(this.params.data.buttonEdit)
    }
    this.label = this.params.label || null;
    this.labelJMP = this.params.labelJMP || null;
    this.actionMS = this.params.actionMS || null;

    this.permissions = this.params.permissions;
    if (this.params.hidden) {
      this.hidden.remove = this.params.hidden.remove;
      this.hidden.update = this.params.hidden.update;
    }

    if (this.params.node &&  this.params.node.data && this.params.data.hideActionButton != undefined && this.actionMS == 'ActionMS') {

      if (this.params.data.hideActionButton == 'hideAction') {
        console.log(this.params.data,"======", this.params.node.data.status,"===",this.params.node.data.approvalStatus)
       
       if(this.params.node.data.approvalStatus != undefined){
        if (this.params.node.data.approvalStatus.name == 'APPROVED' || this.params.node.data.approvalStatus.name == 'REJECTED') {
         this.hiddenAccept = true
       }
       }
       if(this.params.node.data.status != null && this.params.node.data.status.name != undefined){
        if (this.params.node.data.status.name == 'APPROVED' || this.params.node.data.status.name == 'REJECTED') {
          this.hiddenAccept = true
        }
      }
      if(this.params.node.data.status != undefined){
        if (this.params.node.data.status == 'APPROVED' || this.params.node.data.status == 'REJECTED') {
          this.hiddenAccept = true
        }
      }

      
      }
     
    }

    if (
      this.params.node &&
      this.params.node.data &&
      this.params.node.data.approvalStatus != undefined
    ) {
      if (this.params.node.data.approvalStatus == "SUBMITTED") {
        this.hiddenJMP = true;
      }
    }
    ///////condition for journey: if system = false display edit button else hide

    if (
      this.params.data != undefined &&
      this.params.data.moduleName != undefined
    ) {
      // //console.log(this.params.node.data,"************************")
      if (this.params.node.data.moduleName == "Journey") {
        // //console.log(this.params.node.data.userLocationRouteTable.systemGenerated,"======")
        if (
          this.params.node.data.userLocationRouteTable.systemGenerated !=
          null &&
          this.params.node.data.userLocationRouteTable.systemGenerated == false
        ) {
          this.hidden = { update: false, remove: false, clone: true };
        } else {
          this.hidden = { update: true, remove: false, clone: true };
        }
      }
    }

    if (
      this.params.data != undefined && this.params.data.hideDelete != undefined) {
      console.log(this.params.node.data.createdBy, ">>>>>>>>>>>>>>>>>>");
      if (this.params.node.data.createdBy == "System" || this.params.node.data.createdBy == "system"
      ) {
        this.hidden = { update: false, remove: true, clone: true };
      } else {
        this.hidden = { update: false, remove: false, clone: true };
      }
      if (this.params.node.data.page == 'requisition') {
        if (this.params.node.data.loginUser != this.params.node.data.createdBy) {
          this.hidden = { update: true, remove: true };
        }
        else if (this.params.node.data.status == 'Approved' || this.params.node.data.status == 'HOD Approved' || this.params.node.data.status == 'Verified') {
          this.hidden = { update: false, remove: true };
        }
        else {
          this.hidden = { update: false, remove: false };
        }
      }
      if (this.params.node.data.page == 'task') {
        if (this.params.node.data.buttonStatus) {
          this.hidden = { update: true, remove: true };
        } else {
          this.hidden = { update: false, remove: false };
        }
      }
     

    }
    /********* Hide Only Edit or Delete  ************/
    if (
      this.params.node &&
      this.params.node.data &&
      this.params.node.data.hideOneActionButton != undefined
    ) {
      if (this.params.node.data.hideOneActionButton == 'hideEditButton') {
        this.hidden = { update: true, remove: true };
       
     }
     if (this.params.node.data.hideOneActionButton == 'hideDeleteButton') {
       this.hidden = { update: false, remove: true };
      
    }
    }
/********* Hide Only Edit or Delete  ************/

    if (
      this.params.data != undefined &&
      this.params.data.pageFrom != undefined
    ) {
      if (this.params.node.data.createdUserFullName == null) {
        this.hidden = { update: false, remove: true, clone: true };
      } else {
        this.hidden = { update: false, remove: false, clone: true };
      }
    }

    if (this.params.data != undefined && this.params.data.page != undefined) {
      // console.log(this.params.node.data)
      //console.log("*****************************************************************")
      if (this.params.node.data.page == "IQC") {
        if (
          this.params.node.data.status == "NEW" ||
          this.params.node.data.status == "DRAFT"
        ) {
          this.hidden = { update: false, remove: false, clone: true };
        } else {
          this.hidden = { update: true, remove: true, clone: true };
        }
      } else if (this.params.node.data.page == "vanLog") {
        if (this.params.node.data.status.value == "finished") {
          this.hidden = { update: true, remove: false, clone: true };
        } else {
          this.hidden = { update: false, remove: false, clone: true };
        }
      } else if (this.params.node.data.page == "userListPage") {
        if (this.params.node.data.status == "DEACTIVE") {
          this.hidden = { update: true, remove: true, clone: true };
        } else {
          this.hidden = { update: false, remove: true, clone: true };
        }
      } else {
        if (
          this.params.node.data.status == "NEW" ||
          this.params.node.data.status == "DRAFT"
        ) {
          this.hidden = { update: false, remove: true, clone: true };
        } else {
          this.hidden = { update: true, remove: true, clone: true };
        }
      }
    }

    if (this.params.data != undefined && this.params.data.page != undefined) {
      if (this.params.node.data.page == "consignment") {
        console.log(
          this.params.node.data,
          "====>>>",
          this.params.node.data.loginUser,
          " != ",
          this.params.node.data.createdBy
        );
        if (
          this.params.node.data.loginUser != this.params.node.data.createdBy
        ) {
          this.hidden = { update: true, remove: true };
        } else if (
          this.params.node.data.status == "Approved" ||
          this.params.node.data.status == "Acknowledged"
        ) {
          this.hidden = { update: false, remove: true };
        } else {
          this.hidden = { update: false, remove: false };
        }
        console.log(this.hidden);
      }
      if (this.params.node.data.page == "requisition") {
        if (
          this.params.node.data.loginUser != this.params.node.data.createdBy
        ) {
          this.hidden = { update: true, remove: true };
        } else if (
          this.params.node.data.status == "Approved" ||
          this.params.node.data.status == "HOD Approved" ||
          this.params.node.data.status == "Verified"
        ) {
          this.hidden = { update: false, remove: true };
        } else {
          this.hidden = { update: false, remove: false };
        }
      }

      if (this.params.node.data.page == "hieEdit") {
       this.hidden = { update: true, remove: false };
        
      }
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event: any, action: any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        action: action,
        // ...something
      };
      this.params.onClick(params);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
