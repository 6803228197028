import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';


@Component({
  selector: 'app-job-order-format',
  templateUrl: './job-order-format.component.html'
})
export class JobOrderFormatComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  tix  !: any[];
  tix2  !: any[];
  translate: any;

  constructor(
    public modalRef : BsModalRef,
    private languageTranslateService: LanguageTranslateService
  ) {super() }

  ngOnInit(): void {
      this.getLanguageData()
  }

  getLanguageData() {
    this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.tix=[
          {variable:"{0}", necessity:this.translate?.setup.modules.configuration.fields.required.singular, desc: this.translate?.setup.modules.configuration.messages.zeroVariable},
          {variable:"{YY} / {YYYY}", necessity:this.translate?.setup.modules.configuration.fields.optional.singular, desc: this.translate?.setup.modules.configuration.messages.yearVariable},
          {variable:"{MM}", necessity:this.translate?.setup.modules.configuration.fields.optional.singular, desc:this.translate?.setup.modules.configuration.messages.monthVariable},
          {variable:"{DD}", necessity:this.translate?.setup.modules.configuration.fields.optional.singular, desc:this.translate?.setup.modules.configuration.messages.dayVariable}
        ],
        this.tix2=[
          {format:"{0}", seq1:"10", seq2:"1000", number1:"10", number2:"1000"},
          {format:"{000000}", seq1:"10", seq2:"1000", number1:"000010", number2:"001000"},
          {format:"{YYYY}{MM}-{0}", seq1:"10", seq2:"1000", number1:"202108-10", number2:"202108-1000"},
          {format:"JO# {000000000}", seq1:"10", seq2:"1000", number1:"JO# 000000010", number2:"JO# 000001000"}
        ]
      }
    }))
    //console.log("translate",this.translate)
   // this.mainLabel = this.translate?.accessories.label.plural
  }
  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
