import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { ViewDynamicFormComponent } from './view-dynamic-form/view-dynamic-form.component';
import { SharedModule } from '../../shared/shared.module';
import { DeleteDynamicDataComponent } from './delete-dynamic-data/delete-dynamic-data.component';
import { ComponentSharedModule } from '../shared/component-shared.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AgGridModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentSharedModule
  ],
  declarations: [DynamicFormComponent, ViewDynamicFormComponent, DeleteDynamicDataComponent],
  exports: [DynamicFormComponent, ViewDynamicFormComponent,DeleteDynamicDataComponent],
  providers: [DynamicFormComponent, ViewDynamicFormComponent, DeleteDynamicDataComponent]
})
export class DynamicElementModule { }
