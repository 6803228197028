<div class="d-block p-1 pb-5" *ngIf="translate">
    <div class="d-block mt-4 mb-3 mx-4">
        <span class="text-16 font-semiBold font-white" id="title_Setup">{{translate?.setup.label.singular}}</span>
    </div>
    <div class="custella-setup d-block my-2 mx-4">     
        <div class="custella-accordion-menu accordion accordion-flush" *ngFor="let node of initialData; let i = index" [hidden]="node.hide">
            <div *ngIf="!isExpandable(node);"  class="accordion-item py-2 border-top border-1 border-secondary setup-sub-manu-two" tabindex="0">
                <div class="text-12 font-white cursor-pointer" routerLinkActive="active-menuitem"  (click)="openurl(node)" routerLink="{{node.value}}">{{node.name}}</div>
            </div>

            <div *ngIf="isExpandable(node);"  class="accordion-item py-2 border-top border-1 border-secondary">
                <div class="topic accordion-header" id="setup-header{{i}}">
                    <button type="button" (click)="changeData(node.value,a,node) ; toggle(a,node.value)" class="accordion-button collapsed text-12 font-lightGrey justify-content-between py-1 px-0 {{node.value}}" data-bs-toggle="collapse" attr.data-bs-target="#{{node.value}}" aria-expanded="false"  [attr.aria-controls]="node.value">
                        {{node.name}}
                        <i class="pi pi-angle-right {{node.value}}-main main-menu" #a></i>
                    </button>
                </div>
                <app-setup-menu-item *ngIf="isExpandable(node);" [data]="node.data" [dataId]="node.value" [nodeIndex]="i"></app-setup-menu-item>
            </div>
        </div>
    </div>
</div>
