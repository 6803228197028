import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

declare var google: any;

@Component({
  selector: "app-view-map-pin",
  templateUrl: "./view-map-pin.component.html",
  styleUrls: ["./view-map-pin.component.scss"],
})
export class ViewMapPinComponent implements OnInit {
  public options: any;

  overlays!: any[];

  data: any;
  translate: any;

  constructor(public modalRef: BsModalRef,
    private languageTranslateService: LanguageTranslateService
  ) {
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
  }

  ngOnInit() {
    this.init();
  }

  /******************************************** Init ******************************************/
  private init() {
    const lat = this.data.lat;
    const lng = this.data.lng;

    this.options = {
      center: { lat: lat, lng: lng },
      zoom: 12,
    };

    this.overlays = [
      new google.maps.Marker({
        position: { lat: lat, lng: lng },
        title: "Location",
      }),
    ];
  }
}
