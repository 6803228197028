<form (ngSubmit)="onSubmit()" [formGroup]="addScheduledMaintenanceForm">
    <div class="mt-56px">
        <div class="custella-form-container left-right-space rounded-2">
            <div class="custella-form-container-title" id="title_AssetPmDet" >{{ translate?.preventiveMaintenance.sections.sec0.singular }}</div>

            <div class="custella-form-container-content">
                <!-- Asset Name, Status -->
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title mandatory" id="label_AssetPmName" >{{ translate?.asset.fields.assetName.singular }} </span>
                        <div class="p-inputgroup search">
                            <span class="p-input-icon-right w-100">
                                <p-autoComplete (onSelect)="gotSelectedAsset($event)" field="name"  [suggestions]="assets" styleClass="w-100" [placeholder]="translate?.general.messages.none"
                                [disabled]="mode == 'edit' || (mode == 'add' && hasAssetsData)"  (completeMethod)="getAssetIdsFirstWay($event)" [(ngModel)]="selectedAsset" name="assetName"
                                    id="picklist_AssetPmNameField" formControlName="assetName"></p-autoComplete>
                                
                                <i *ngIf="selectedAsset && (mode == 'add'&& !hasAssetsData )"  id="btn_AssetPmNameClear" (click)="clearValue1()" class="p-autocomplete-clear-icon pi pi-times"></i>
                            </span>    
                            <button *ngIf="!hasAssetsData && mode != 'edit'" type="button" [pTooltip]="translate?.asset.tooltips.assetLookup.singular" tooltipPosition="left"  pButton icon="fa-regular fa-magnifying-glass" id="btn_AssetPmNameSearch" (click)="showTable1()"></button>
                        </div>
                            <footer *ngIf="displayAssetTable" class="custella-form-container-footer hidePopup">
                                <p-dialog [header]="translate?.asset.sections.sec16.singular" id="title_AssetDet" [(visible)]="displayAssetTable" showEffect="fade"
                                    [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                    <app-custella-assets (closeAssetView)="closeAssetView()"
                                        (assetSelector)="gotSelectedAsset($event)"></app-custella-assets>
                                </p-dialog>
                            </footer>
                        <div class="p-error text-10"
                            *ngIf="addScheduledMaintenanceForm.controls['assetName'].touched && addScheduledMaintenanceForm.controls['assetName'].invalid">
                            <span *ngIf="addScheduledMaintenanceForm.controls['assetName'].errors?.required" id="label_AssetPmNameReqField"
                                >{{ translate?.general.validationFE.requiredField}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title mandatory" id="label_AssetPmStatus" >{{ translate?.maintenance.fields.status.singular }} </span>
                    
                        <p-dropdown [placeholder]="translate?.general.messages.none" id="picklist_AssetPmStatusField" [options]="allStatus" optionLabel="label"
                            optionValue="value" formControlName="status" (onChange)="statusChange()" name="status"
                            [(ngModel)]="scheduledMaintenance.status">
                            <ng-template pTemplate="selectedItem">
                                <div *ngIf="scheduledMaintenance.status" id="value_AssetPmStatus">
                                    {{ returnValue("status", scheduledMaintenance.status)  }}
                                </div>
                            </ng-template>
                    
                            <ng-template let-status pTemplate="item" id="value_AssetPmStatus">
                                {{ status.label  }}
                            </ng-template>
                        </p-dropdown>
                        <div class="p-error text-10" *ngIf="addScheduledMaintenanceForm.controls['status'].touched && addScheduledMaintenanceForm.controls['status'].invalid">
                            <span *ngIf="addScheduledMaintenanceForm.controls['status'].errors?.required" id="label_AssetPmStatusReqField" >{{translate?.general.validationFE.requiredField}}</span>
                        </div>
                    </div>
                </div>

                <!-- Maintenance, Service Zone -->
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_AssetPmMain" >{{ translate?.preventiveMaintenance.fields.maintenance.singular }} </span>
                        <p-dropdown [placeholder]="translate?.general.messages.none" [options]="maintenances"
                            [showClear]="scheduledMaintenance.maintenance.id ? true : false" id="picklist_AssetPmMainField" optionLabel="name" optionValue="id" name="maintenance" (onChange)="
                                  changeMaintenance(scheduledMaintenance.maintenance.id)"
                            [(ngModel)]="scheduledMaintenance.maintenance.id" [ngModelOptions]="{ standalone: true }">
                        </p-dropdown>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_AssetPmSerZone" >{{ translate?.setup.serviceZone.label.singular }} </span>
                        <input pInputText autocomplete="off" type="text" id="input_AssetPmSerZoneField" disabled [(ngModel)]="scheduledMaintenance.serviceZone.name" [ngModelOptions]="{ standalone: true }" />
                    </div>
                </div>

                <div class="custella-content-row" *ngIf="addonFeature?.addonDYNAMICMAINTENANCE">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_AssetPmTemp" >{{ translate?.preventiveMaintenance.fields.maintenanceTemplate.singular }} </span>
                        <input pInputText autocomplete="off" type="text" disabled id="input_AssetPmTempField"
                            [(ngModel)]="scheduledMaintenance.preventativeTemplate.name"
                            [ngModelOptions]="{ standalone: true }" />
                    </div>

                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_AssetPmFreq" >{{ translate?.preventiveMaintenance.fields.maintenanceFrequency.singular }} </span>
                        <p-dropdown  [placeholder]="translate?.general.messages.none" [options]="frequencies"
                            [showClear]="scheduledMaintenance.preventativeFrequency.id ? true : false" optionLabel="name" optionValue="id" name="frequencies" (onChange)="
                                  changeMaintenanceFrequency(scheduledMaintenance.preventativeFrequency.id)" id="picklist_AssetPmFreqField"
                            [(ngModel)]="scheduledMaintenance.preventativeFrequency.id" [ngModelOptions]="{ standalone: true }">
                        </p-dropdown>
                          <!-- <input *ngIf="mode == 'edit'" pInputText autocomplete="off" type="text" id="input_AssetPmFreqField" [disabled]="true"
                        [(ngModel)]="scheduledMaintenance.preventativeFrequency.name"
                        [ngModelOptions]="{ standalone: true }" /> -->
                    </div>
                </div>

                <!-- Type, Maintenance Date -->
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title mandatory" id="label_AssetPmType" translate *ngIf="!addonFeature?.addonDYNAMICMAINTENANCE">{{ translate?.preventiveMaintenance.fields.types.singular }} </span>
                        <span class="custella-content-row-input-title mandatory" id="label_AssetPmJobType" translate *ngIf="addonFeature?.addonDYNAMICMAINTENANCE">{{translate?.jobOrder.label.singular}}&nbsp;{{ translate?.preventiveMaintenance.fields.types.singular }} </span>

                        <ng-container *ngIf="!addonFeature?.addonDYNAMICMAINTENANCE">
                            <p-dropdown [placeholder]="translate?.general.messages.none" [options]="allTypes" id="picklist_AssetPmTypeField" optionLabel="label"
                            optionValue="value" [(ngModel)]="scheduledMaintenance.type" formControlName="type"
                            name="type" >
                            <ng-template pTemplate="selectedItem">
                                <div *ngIf="scheduledMaintenance.type" id="value_AssetPmType">
                                    {{ returnValue("type", scheduledMaintenance.type)  }}
                                </div>
                            </ng-template>
                            <ng-template let-status pTemplate="item" id="item_AssetPmType">
                                {{ status.label  }}
                            </ng-template>
                        </p-dropdown>
                        </ng-container>


                        <ng-container *ngIf="addonFeature?.addonDYNAMICMAINTENANCE">
                            <p-dropdown [placeholder]="translate?.general.messages.none" id="label_AssetPmJobType" [options]="allTypes" optionLabel="label" 
                            optionValue="id" [(ngModel)]="scheduledMaintenance?.jobOrderType.id" formControlName="type" [showClear]="scheduledMaintenance.jobOrderType.id ? true : false"
                            name="type">
                            <ng-template let-status pTemplate="item" id="item_AssetPmJobType">
                                {{ status.label  }}
                            </ng-template>
                        </p-dropdown>
                    </ng-container>
                        <div class="p-error text-10"
                            *ngIf="addScheduledMaintenanceForm.controls['type'].touched && addScheduledMaintenanceForm.controls['type'].invalid">
                            <span *ngIf="addScheduledMaintenanceForm.controls['type'].errors?.required" id="label_AssetPmTypeReqField" >{{translate?.general.validationFE.requiredField}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title mandatory" id="label_AssetPmMainDate" >{{ translate?.preventiveMaintenance.fields.maintenanceDate.singular }}</span>
                        <p-calendar [defaultDate]="defaultDate" name="dueDate"  id="picklist_AssetPmMainDateField"
                        [placeholder]="translate?.general.messages.none" selectionMode="single" dateFormat="mm/dd/yy"
                            yearRange="2000:2030" (onChange)="maintenanceDateChange($event)"
                            [(ngModel)]="scheduledMaintenance.scheduledDateTime" formControlName="maintenanceDate"
                            name="maintenanceDate" [yearNavigator]="true" appendTo="body"></p-calendar>
                        <div class="p-error text-10"
                            *ngIf="addScheduledMaintenanceForm.controls['maintenanceDate'].touched && addScheduledMaintenanceForm.controls['maintenanceDate'].invalid">
                            <span *ngIf="addScheduledMaintenanceForm.controls['maintenanceDate'].errors?.required" id="label_AssetPmMainDateReqField" >{{translate?.general.validationFE.requiredField}}</span>
                        </div>
                    </div>
                </div>

                <!-- Frequency Name, Actual Completion Date -->
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_AssetPmJobOrder" >{{ translate?.jobOrder.label.singular }} </span>
                        <div class="p-inputgroup search">
                            <span class="p-input-icon-right w-100">
                                <p-autoComplete styleClass="w-100" (onSelect)="selectJoNumber($event)" id="picklist_AssetPmJobOrderField" field="jobOrderNumber"
                                [suggestions]="jobOrders" (onKeyUp)="getJobOrderIdsFirstWay($event)"
                                [placeholder]="translate?.general.messages.none" [(ngModel)]="selectedJobOrder"
                                [disabled]="scheduledMaintenance.generateJobOrder"
                                [ngModelOptions]="{ standalone: true }"></p-autoComplete>
                            <i *ngIf="scheduledMaintenance?.jobOrder?.id" (click)="clearJoValue()"
                                class="p-autocomplete-clear-icon pi pi-times" id="btn_AssetPmJobOrderClear"></i>
                            </span>    
                            <button type="button" [pTooltip]="translate?.jobOrder.tooltips.jobOrderLookup.singular" tooltipPosition="left"  pButton icon="fa-regular fa-magnifying-glass" id="btn_AssetPmJobOrderSearch" (click)="showJoTable()"></button>
                        </div>
                        <footer *ngIf="displayTable1" class="custella-form-container-footer hidePopup">
                            <p-dialog [header]="translate?.jobOrder.sections.sec40.singular" id="title_JobDet" [(visible)]="displayTable1" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                <app-custella-job-orders (itemSelector)="gotSelectedJobOrder($event)" (closeView)="closeJobOrderView()"> </app-custella-job-orders>
                            </p-dialog>
                        </footer>
                    </div>

                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_AssetPmActCompDate" >{{ translate?.preventiveMaintenance.fields.actualCompletionDate.singular }} </span>
                        <p-calendar [defaultDate]="defaultDate" id="picklist_AssetPmActCompDateField" name="completionDate" 
                        [placeholder]="translate?.general.messages.none"selectionMode="single" dateFormat="mm/dd/yy"
                            yearRange="2000:2030" [disabled]="scheduledMaintenance.status != 'COMPLETED'"
                            [(ngModel)]="scheduledMaintenance.actualCompletionDateTime"
                            [ngModelOptions]="{ standalone: true }" [yearNavigator]="true" appendTo="body"></p-calendar>
                    </div>
                     </div>

                <!-- Frequency -->
                <!-- <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" >{{ "frequency" }} </span>
                        <input pInputText autocomplete="off" type="text"
                            [disabled]="scheduledMaintenance.type != 'OTHERS'"
                            [(ngModel)]="scheduledMaintenance.otherType" [ngModelOptions]="{ standalone: true }" />
                    </div>
                </div> -->
                <!-- Weekdays -->
                <!-- <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" >{{ "allow_to_schedule_on_weekdays" }}
                        </span>
                        <p-calendar [defaultDate]="defaultDate" name="completionDate" showClear="true"
                            [placeholder]="'select_date' " selectionMode="single" dateFormat="mm/dd/yy"
                            yearRange="2000:2030" [disabled]="scheduledMaintenance.status != 'COMPLETED'"
                            [(ngModel)]="scheduledMaintenance.actualCompletionDateTime"
                            [ngModelOptions]="{ standalone: true }" [yearNavigator]="true"appendTo="body"></p-calendar>
                    </div>
                </div> -->
                <!-- Weekends -->
                <!-- <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" >{{ "allow_to_schedule_on_weekends" }}
                        </span>
                        <p-calendar [defaultDate]="defaultDate" name="completionDate" showClear="true"
                            [placeholder]="'select_date' " selectionMode="single" dateFormat="mm/dd/yy"
                            yearRange="2000:2030" [disabled]="scheduledMaintenance.status != 'COMPLETED'"
                            [(ngModel)]="scheduledMaintenance.actualCompletionDateTime"
                            [ngModelOptions]="{ standalone: true }" [yearNavigator]="true"appendTo="body"></p-calendar>
                    </div>
                </div> -->
            </div>

            <!-- Job Order Details -->
            <!--<div class="custella-form-container-title-two text-14 font-medium" >
                {{ "job_order_details" }}
            </div> -->
            <!-- Job Order -->


            <div class="custella-form-container-content">
                <!-- <div class="custella-content-row mb-2 pe-0">

                </div> -->

                <!-- <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" >{{ "other"}}</span>

                        <input type="text" pInputText [ngClass]="{disableClass : scheduledMaintenance.type!='OTHERS'}" [disabled]="scheduledMaintenance.type!='OTHERS'"  [(ngModel)]="scheduledMaintenance.otherType" [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" >{{ "service_type"}}</span>

                        <p-dropdown [placeholder]="'select_service_type' " [options]="allServiceTypes" [showClear]="true" optionLabel="label"
                        optionValue="value" name="serviceType" [(ngModel)]="scheduledMaintenance.serviceType" [ngModelOptions]="{standalone: true}">
                    </p-dropdown>
                    </div>
                </div> -->

                <div class="custella-content-row mb-2 pe-0">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title mandatory" id="label_AssetPmAssignUser" >{{ translate?.preventiveMaintenance.fields.assignedUser.singular}}</span>
                        <p-dropdown id="picklist_AssetPmAssignUserField" [options]="users" [showClear]="true" optionLabel="firstName"
                            optionValue="id" [(ngModel)]="scheduledMaintenance.jobOrderOwner.id"[placeholder]="translate?.general.messages.none"
                            name="user" formControlName="user">
                            <ng-template pTemplate="selectedItem">
                                <div *ngIf="scheduledMaintenance.jobOrderOwner.id" id="value_AssetPmAssignUser">
                                    {{ returnValue("user", scheduledMaintenance.jobOrderOwner.id)  }}
                                </div>
                            </ng-template>
                        
                            <ng-template let-user pTemplate="item" id="item_AssetPmAssignUser">
                                {{ user.firstName }} {{ user.lastName }}
                            </ng-template>
                        </p-dropdown>
                        
                        <div class="p-error text-10"
                            *ngIf="addScheduledMaintenanceForm.controls['user'].touched && addScheduledMaintenanceForm.controls['user'].invalid">
                            <span *ngIf="addScheduledMaintenanceForm.controls['user'].errors?.required" id="label_AssetPmAssignUserReqField" >
                                {{ translate?.general.validationFE.requiredField}}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- Job Order Type -->
                <!-- <div class="custella-content-row mb-2 pe-0">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" >{{ "job_order_type" }}</span>
                        <input type="text" id="subject" pInputText autocomplete="off"
                           formControlName="subject" />

                        <div class="p-error col px-0 py-0 text-left"
                            *ngIf="addTicketsForm.controls['subject'].touched &&addTicketsForm.controls['subject'].invalid">
                            <span *ngIf="addTicketsForm.controls['subject'].errors?.required"
                                >{{"general.validationFE.requiredField"}}</span>
                            <span *ngIf="addTicketsForm.controls['subject'].errors?.maxlength"
                                >{{"subject_less_than_25" }}</span>
                        </div>
                    </div>
                </div> -->
                <!-- Assigned User Group -->
                <!-- <div class="custella-content-row mb-2 pe-0">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" >{{ "assigned_to_user_group"}}</span>
                        <input type="text" id="subject" pInputText autocomplete="off"
                           formControlName="subject" />

                        <div class="p-error col px-0 py-0 text-left"
                            *ngIf="addTicketsForm.controls['subject'].touched &&addTicketsForm.controls['subject'].invalid">
                            <span *ngIf="addTicketsForm.controls['subject'].errors?.required"
                                >{{"general.validationFE.requiredField"}}</span>
                            <span *ngIf="addTicketsForm.controls['subject'].errors?.maxlength"
                                >{{"subject_less_than_25" }}</span>
                        </div>
                    </div>
                </div> -->
                <!-- Assigned to User -->
                <!-- <div class="custella-content-row mb-2 pe-0">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" >{{ "assigned_to_users"}}</span>
                        <p-dropdown [placeholder]="'select_user' " [options]="users" [showClear]="true" optionLabel="firstName"
                            optionValue="id" [(ngModel)]="scheduledMaintenance.jobOrderOwner.id" placeholder="{{ 'select_user'  }}"
                            name="user" formControlName="user">
                            <ng-template pTemplate="selectedItem">
                                <div *ngIf="scheduledMaintenance.jobOrderOwner.id">
                                    {{ returnValue("user", scheduledMaintenance.jobOrderOwner.id)  }}
                                </div>
                            </ng-template>
                        
                            <ng-template let-user pTemplate="item">
                                {{ user.firstName }} {{ user.lastName }}
                            </ng-template>
                        </p-dropdown>
                        
                        <div class="p-error text-10"
                            *ngIf="addScheduledMaintenanceForm.controls['user'].touched && addScheduledMaintenanceForm.controls['user'].invalid">
                            <span *ngIf="addScheduledMaintenanceForm.controls['user'].errors?.required" >
                                {{ "general.validationFE.requiredField"}}
                            </span>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="custella-form-container-footer">
                <div class="d-flex align-items-center justify-content-end">
                    <div class="d-flex align-items-center me-2">
                        <p-checkbox class="my-2" [binary]="true" [disabled]="selectedJobOrder"
                            (change)="jobOrderChecked()" [(ngModel)]="scheduledMaintenance.generateJobOrder"
                            [ngModelOptions]="{ standalone: true }" id="check_GenerateJobOrder"></p-checkbox>

                        <span class="text-12 ms-2" id="label_GenerateJobOrder" >{{ translate?.preventiveMaintenance.fields.generateJobOrder.singular }} </span>
                    </div>
                    <button type="button" id="btn_AssetPmCancel" class="custella-btn-white text-12 ms-2" (click)="cancel()">
                        {{translate?.general.buttons.cancel.singular}}
                    </button>
                    <p-button [disabled]="submitTouched" [label]="translate?.general.buttons.save.singular" (onClick)="onSubmit()" id="btn_AssetPmSubmit" styleClass="ms-2 h-32"></p-button>
                </div>
            </div>
        </div>
    </div>
</form>
