import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  // Magnum Dashboard
  private ENDPOINT_FAULT_PIE_CHART = "/dashboard/pie-chart";
  private ENDPOINT_FAULT_BAR_CHART = "/dashboard/assets-data";
  private ENDPOINT_DAILY_PROGRESS = "/dashboard/daily-progress";
  private ENDPOINT_OVER_DUE = "/dashboard/overdue-fault-calls";
  private ENDPOINT_PREVENTIVE_MAINTENANCE =
    "/dashboard/preventative-maintenance";
  private ENDPOINT_FAULT_TYPES = "/faults";
  private ENDPOINT_FAULT_HIERARCHY = "/dashboard/hierarchy";

  constructor(private http: HttpClient) {}

  ///////////////////////////////////////////////////////  Magnum Dashboard  //////////////////////////////////

  public getMagnumPieChart(
    joType: any,
    group: any,
    roleId: any,
    fromDate: any,
    toDate: any,
    systems: any
  ) {
    // var fromDate = '2020-01-01';
    let params = new HttpParams();
    params = params.set("joTypes", joType);
    params = params.set("group", group);
    params = params.set("fromDate", fromDate);
    params = params.set("toDate", toDate);

    if (systems) {
      params = params.set("systems", systems);
    }

    if (roleId != null) {
      params = params.set("roleId", roleId);
    }

    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_PIE_CHART, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumBarHorizontalStacked(
    roleId: any,
    fromDate: any,
    toDate: any
  ) {
    // roleId no need here
    let params = new HttpParams();
    //params = params.set('fromDate', fromDate);
    //params = params.set('toDate', toDate);
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_BAR_CHART, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumProgressBar(roleId: any, fromDate: any, toDate: any) {
    let params = new HttpParams();
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }
    params = params.set("fromDate", fromDate);
    params = params.set("toDate", toDate);
    return this.http
      .get(environment.base_url + this.ENDPOINT_DAILY_PROGRESS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumOverDue(roleId: any, fromDate: any, toDate: any) {
    let params = new HttpParams();
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }
    params = params.set("fromDate", fromDate);
    params = params.set("toDate", toDate);
    return this.http
      .get(environment.base_url + this.ENDPOINT_OVER_DUE, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumPreventiveMaintenance(
    roleId: any,
    fromDate: any,
    toDate: any
  ) {
    // var fromDate = '2020-01-01';
    let params = new HttpParams();
    params = params.set("fromDate", fromDate);
    params = params.set("toDate", toDate);
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }

    return this.http
      .get(environment.base_url + this.ENDPOINT_PREVENTIVE_MAINTENANCE, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumFaultTypes() {
    return this.http.get(environment.base_url + this.ENDPOINT_FAULT_TYPES).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getMagnumCompanies() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_HIERARCHY)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
