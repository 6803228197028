<div class="custella-login">

    <div class="language">
        <li class="custella-lang-front">
            <div class="custella-lang-menu accordion accordion-flush">
                <div class="accordion-item">
                    <div class="accordion-header" id="language">
                        <button class="accordion-button collapsed justify-content-between px-1 py-2" data-bs-toggle="collapse" data-bs-target="#choose-lang" aria-expanded="false" aria-controls="choose-lang">
                            <div class="flex-align-center">
                                <span class="fi fi-{{currentLang.flag}} me-2"></span>
                                <span class="text-12 ms-2">{{currentLang.value}}</span>

                                <!-- <span class="text-12 font-white" *ngIf="currentLang === 'Malay'">
                                    Malay
                                </span>

                                <span class="text-12 font-white" *ngIf="currentLang === 'Chinese'">
                                    Chinese
                                </span> -->
                            </div>
                            <i class="fa-regular fa-chevron-down ms-4"></i>
                        </button>

                    </div>
                    <ul class="p-0 m-0 accordion-collapse collapse"  id="choose-lang" #chooseLang>
                        <ng-container *ngFor="let lang of languageList">
                            <li class="py-1 px-0 my-0"aria-labelledby="language"  *ngIf="currentLang != lang" (click)="onChangeLanguage(lang)">
                                <a class="px-1">
                                    <span class="fi fi-{{lang.flag}} me-2"></span>
                                    <span class="text-12 ms-2">{{lang.value}}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </li>
    </div>


    <div class="container">
        <div class="row">
            <!-- <div class="col-sm-9 col-md-7 col-lg-4 mx-auto"> -->
            <div class="mx-auto">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body py-4 px-3 px-sm-3 py-sm-4">
                    <span>
                        <img src="../../../assets/svg/FieldEx_L_T.svg" alt="FieldEx Logo">
                    </span>
                    <h3 class="card-title font-bold pt-5 pb-4" id="title_RequestNewPwd">{{langData.resetPassword.sections.sec0.singular}}</h3>
                    <form (ngSubmit)="submitForgotPassword()" [formGroup]="forgotPasswordForm">
                        <div class="form-group">
                            <label id="label_ReqPwdUsername">{{langData.resetPassword.fields.username.singular}}</label>
                            <span class="p-input-icon-left">
                                <i class="fa-regular fa-user pe-none"></i>
                                <input pInputText type="email" [(ngModel)]="forgotPassword.username" id="input_ReqPwdUsernameField"formControlName="username" name="username" id="exampleInputEmail1"aria-describedby="emailHelp"/>
                            </span>
                            <div *ngIf="forgotPasswordForm.controls['username'].touched && forgotPasswordForm.controls['username'].invalid">
                                <span class="p-error text-10" *ngIf="forgotPasswordForm.controls['username'].errors?.required" id="label_ReqPwdUsernameReqField">{{langData.general.validationFE.requiredField}}</span>
                                <span class="p-error text-10" *ngIf="forgotPasswordForm.controls['username'].errors?.pattern" id="label_ReqPwdUsernameInvalid">{{langData.general.validationFE.invalidEmailFormat}}</span>
                            </div>
                        </div>
                        <div class="my-4">
                            <button type="submit" [disabled]="disableSubmitButton" class="custella-btn-long" id="btn_LoginSignInSubmit" (click)="submitForgotPassword()">
                                <span class="text-12">{{langData.resetPassword.buttons.resetPassword.singular}}</span>
                            </button>
                        </div>
                        <div class="forgot-me">
                            <label class="click-forgot" routerLink="/login">{{langData.resetPassword.fields.returnLogin.singular}}&nbsp;</label>
                        </div>
                    </form>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="vh-100 overflow-hidden">
    <div class="d-flex vh-100">
        <div class="left-form">
            <form (ngSubmit)="submitForgotPassword()" [formGroup]="forgotPasswordForm">
                <div class="container">
                    <div class="custella-login p-5">
                        <img src="assets\images\custella_logo.png" />
                        <div class="text-20 font-medium" id="title_RequestNewPwd" >{{'request_new_pwd'}}</div>
                        <div class="text-14 font-medium mt-3" id="label_RequestKeyInUsername">
                            <span >{{'key_in_username'}}</span>
                        </div>
                        <div class="custella-login-form">
                            <span class="mb-2 text-12 font-medium" id="label_ReqPwdUsername" >{{'username'}}</span>
                            <input pInputText type="email" [(ngModel)]="forgotPassword.username" id="input_ReqPwdUsernameField"
                                formControlName="username" name="username" id="exampleInputEmail1"
                                aria-describedby="emailHelp" placeholder="{{'enter_username' }}" />

                            <div class="alert col-12 px-0 py-0 text-right custella-content-row-input"
                                *ngIf="forgotPasswordForm.controls['username'].touched && forgotPasswordForm.controls['username'].invalid">
                                <small class="custella-content-row-input-sub p-error" *ngIf="forgotPasswordForm.controls['username'].errors?.required" id="label_ReqPwdUsernameReqField" >{{'general.validationFE.requiredField' }}</small>
                                <small class="custella-content-row-input-sub p-error" *ngIf="forgotPasswordForm.controls['username'].errors?.pattern" id="label_ReqPwdUsernameInvalid" >{{'general.validationFE.invalidEmailFormat'}}</small>
                            </div>
                        </div>
                        <div class="mt-5">
                            <button type="submit" class="custella-btn-long" id="btn_RequestPwdSubmit">
                                <label >{{'reset_password'}}</label>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="w-75" [ngStyle]="{background:'var(--surface-300)'}">
            <div class="right-form">
                <div class="custella-quote">
                    <div class="custella-quote-text">
                        “<span class="custella-quote-text-bold">Refine</span> the way your <span
                            class="custella-quote-text-bold">Time</span> is spent, to <span
                            class="custella-quote-text-bold">Achieve</span> more towards a greater
                        <span class="custella-quote-text-bold">Purpose”</span>
                        <br />
                        <span class="custella-quote-text-small"> – Custella Team</span>
                    </div>
                    <div class="custella-quote-img">
                        <img src="assets\images\login_view.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->