import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AttachmentService {
  public refreshList$: EventEmitter<any>;
  
  constructor(private http: HttpClient) {
    this.refreshList$ = new EventEmitter();
  }

  private ENDPOINT_GENERAL = "/attachments/";

  public deleteAttachment(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_GENERAL + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
         // this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getFileType(attachmentContentContentType : any, attachmentUrl : any){
    var file: any
    if(attachmentContentContentType.indexOf('application/zip') >= 0 ){
      return 'assets/svg/fileTypes/zip.svg'
    }else if(attachmentContentContentType.indexOf('text/plain') >= 0){
      return 'assets/svg/fileTypes/txt.svg'
    }else if(attachmentContentContentType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0){
      return 'assets/svg/fileTypes/doc.svg'
    }else if(attachmentContentContentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0){
      return 'assets/svg/fileTypes/xls.svg'
    }else if(attachmentContentContentType.indexOf('image') >= 0){
      return attachmentUrl
    }else if(attachmentContentContentType.indexOf('pplication/pdf') >= 0){
      return 'assets/svg/fileTypes/pdf.svg'
    } else if(attachmentContentContentType.indexOf('text/csv') >= 0){
      return 'assets/svg/fileTypes/csv.svg';
    } else if(attachmentContentContentType.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0){
      return 'assets/svg/fileTypes/ppt.svg';
    }
    else{
      return 'assets/svg/fileTypes/general file.svg'
    }
  }

  public getFileicon(attachmentContentContentType : any){
    var file: any
    if(attachmentContentContentType.indexOf('application/zip') >= 0 ){
      return 'assets/svg/fileTypes/zip.svg'
    }else if(attachmentContentContentType.indexOf('text/plain') >= 0){
      return 'assets/svg/fileTypes/txt.svg'
    }else if(attachmentContentContentType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0){
      return 'assets/svg/fileTypes/doc.svg'
    }else if(attachmentContentContentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') >= 0){
      return 'assets/svg/fileTypes/xls.svg'
    }else if(attachmentContentContentType.indexOf('image') >= 0){
      return 'assets/svg/fileTypes/ig.svg'
    }else if(attachmentContentContentType.indexOf('pplication/pdf') >= 0){
      return 'assets/svg/fileTypes/pdf.svg'
    }  else if(attachmentContentContentType.indexOf('text/csv') >= 0){
      return 'assets/svg/fileTypes/csv.svg';
    }else if(attachmentContentContentType.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') >= 0){
      return 'assets/svg/fileTypes/ppt.svg';
    }
    else{
      return 'assets/svg/fileTypes/general file.svg'
    }
  }

}
