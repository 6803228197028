import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "distanceToKm2",
})
export class DistanceToKm2Pipe implements PipeTransform {
  transform(value: number): string | undefined {
    if (value) {
      if (value < 1000) {
        var distance: string = value.toFixed(2).toString();
        return distance + "m";
      } else {
        var distance: string = (value / 1000).toFixed(2).toString();
        return distance + " km";
      }
    }
  }
}
