import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  showNewThread$: EventEmitter<{}>;
  refreshList$: EventEmitter<{}>;
  message$ = new EventEmitter();

  private ENDPOINT_CREATE_MESSAGE = "/message-threads";
  private ENDPOINT_SEND_MESSAGE = "/messages";
  private ENDPOINT_GET_THREADS = "/message-threads/by-user";
  private ENDPOINT_GET_THREAD_MESSAGES = "/messages/by-message-thread";
  private ENDPOINT_GET_UNREAD_MESSAGE = "/messages/unread-by-user";
  private ENDPOINT_SET_READ_MESSAGE = "/messages/update-status";

  constructor(private http: HttpClient, private auth: AuthenticationService) {
    this.message$ = new EventEmitter();
    this.refreshList$ = new EventEmitter();
    this.showNewThread$ = new EventEmitter();
  }

  public createNewMessageThread(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_MESSAGE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.showNewThread$.emit(resp.body);
          this.message$.emit(resp.body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public sendMessage(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SEND_MESSAGE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp.body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getThreads(id: any) {
    let params = new HttpParams().set("userId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_THREADS, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getThreadMessages(id: any) {
    let params = new HttpParams().set("messageThreadId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_THREAD_MESSAGES, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getUnreadMessages(id: any) {
    let params = new HttpParams().set("userId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_UNREAD_MESSAGE, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public markMessageAsRead(id: any) {
    return this.http
      .put(
        environment.base_url +
          this.ENDPOINT_SET_READ_MESSAGE +
          "?messageThreadId=" +
          id +
          "&newStatus=READ&userId=" +
          this.auth.getUserId(),
        {},
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  showMessages(obj: any) {
    return this.message$.emit(obj);
  }
}
