import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { NotificationsService } from 'src/app/_services/notifications.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss']
})
export class UserNotificationComponent  extends SubscriptionUtil implements OnInit, OnDestroy {
  default!: any[]
  confirmYes: boolean = false;

  @Input() profileId:any
  @Input() profile: any
  profileTableColumnData: any
 


  configure: boolean = false;
  display: boolean = false;
  params: any;
  notificationsData: any;
  notificationListData: any[]=[];
  translate: any;

  
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private errorUtil: ErrorUtil,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
}

  ngOnInit(): void {
    this.getLanguageData() 

  }
  getStandardNotifications(){
   
    this.notificationsService.getUserStandardNotifications().subscribe((res:any) => {
      this.notificationsData = res;
       this.spinner.hide()
       this.notificationsData.forEach((element: any) => {
        element.name = element.standardNotification.name;
        element.module = element.standardNotification.module;
        element.description = element.standardNotification.description;
        element.triggerAction = element.standardNotification.triggerAction;
       
        });
        // group by module
       const groupBy = (array: any, key: any) => {
        return array.reduce((result: any, currentValue: any) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
          return result;
        }, {}); // empty object is the initial value for result object
      };
      // group by module
      this.notificationListData = [];
      const personGroupedByColor = groupBy(this.notificationsData, 'module');
      for (var i in personGroupedByColor) {
       var newModuleName= personGroupedByColor[i][0].module;
       var moduleNameLowercase = personGroupedByColor[i][0].module.toLowerCase()
        
       if(personGroupedByColor[i][0].module == 'TASK'){
          newModuleName = 'Task'

        }else if(personGroupedByColor[i][0].module == 'JOB_ORDER'){
          newModuleName = 'Job Order'
        }else if(personGroupedByColor[i][0].module == 'INVENTORY'){
          newModuleName = 'Inventory'
        }else if(personGroupedByColor[i][0].module == 'MAINTENANCE'){
          newModuleName = 'Preventive Maintenance'
        }else if(personGroupedByColor[i][0].module == 'USER'){
          newModuleName = 'User'
        }

        this.notificationListData.push({
          name:  personGroupedByColor[i][0].name,
          module: personGroupedByColor[i][0].module,
          newModuleName: newModuleName,
          description: personGroupedByColor[i][0].description,
          detail: personGroupedByColor[i],
          moduleNameLowercase: moduleNameLowercase
        });
      }
      console.log(this.notificationListData);
       
     
    })
   }

  editConfigure(){
    this.configure = !this.configure
  }
  cancelConfigure(){
    this.configure = !this.configure
    this.getStandardNotifications()
  }
  showDialog() {
    this.display = true;
  }
  cancel() {
    this.display = false;
  }
  

  onSubmit(){
    this.spinner.show();
    console.log(this.notificationListData)

    var req: any[]=[];
    this.notificationListData.forEach((element: any) => {

      element.detail.forEach((element: any) => {

        req.push(element)
      })
    });
    console.log(req)
    this.notificationsService.saveUserStandardNotifications(req).subscribe((res:any) => {
      this.spinner.hide();

          console.log(res)
          if(res.status == 201 || res.status == 200){
           this.errorUtil.setErrorMessage(200, this.translate?.general.messages.updatedSuccessfully ,  null, 'success',1000);
            this.cancel()
            this.configure = false
            this.getStandardNotifications()
          } else {
            this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
           
          }
          
        }, err => {
          this.errorUtil.setErrorMessage(err.status, null ,  err.error.title, 'error',3000);
          this.spinner.hide();
    
        })
  }

  /************  set notification status true or false base on the Active checkbox *********/
changeEmailStatus(event: any, data: any){
  console.log(event.checked)
 
  if(!event.checked  ){
    data.email = false
    data.inApp = false
  }
}


getLanguageData() {
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.getStandardNotifications()
      }
    }))
  console.log("translate",this.translate)
  //this.mainLabel = this.translate?.setup.users.users.label.plural
}

push(obs:any) {
  super.push(obs);
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}
}
