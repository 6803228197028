<div class="helpdesk-action-container flex">
  <span
    class="cursor-pointer ms-1 border rounded p-1"
    [hidden]="!permissions"
    (click)="onClick($event, 'assign')"
    id="btn_HelpAssign"
  >
    {{ translate?.general.buttons.assign.singular }}
  </span>

  <span
    class="cursor-pointer ms-1 border rounded p-1 claimBtn"
    [hidden]="!permissions"
    (click)="onClick($event, 'claim')"
    id="btn_HelpClaim"
  >
    {{ translate?.general.buttons.claim.singular }}
  </span>
</div>
