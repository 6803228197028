import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from 'src/app/_services/admin/users.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-disconnect-confmation',
  templateUrl: './disconnect-confmation.component.html',
  styleUrls: ['./disconnect-confmation.component.scss']
})
export class DisconnectConfmationComponent extends SubscriptionUtil implements OnInit, OnDestroy  {

  data: any;
  page: any
  title: any;
  goBack : boolean = false;
  translate: any;

  constructor(public modalRef : BsModalRef,
    private errorUtil: ErrorUtil,
    private userService : UsersService,
    private languageTranslateService: LanguageTranslateService) {
      super()
    this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
    if(Object.keys(data).length != 0){
    this.translate = data
  }
}))
     }

  ngOnInit() {
    console.log(this.data);
  }

  onSubmit() {

if(this.page == 'Google'){
    this.userService.disconnectGoogleCalender().subscribe((res:any)=>{
      // console.log("Response ", res);
      this.errorUtil.setErrorMessage(200, this.translate?.setup.users.access.messages.googleCalendarDisconnectedSuccessfully ,  null, 'success',3000);;
      this.modalRef.hide();
    });
  }else if(this.page == 'Microsoft'){
    this.userService.disconnectMicrosoftCalender().subscribe((res:any)=>{
      // console.log("Response ", res);
      this.errorUtil.setErrorMessage(200, this.translate?.setup.users.access.messages.microsoftCalendarDisconnectedSuccessfully ,  null, 'success',3000);;
      this.modalRef.hide();
    });
  }else{
    this.userService.disconnectCalendlyCalender().subscribe((res:any)=>{
      // console.log("Response ", res);
      this.errorUtil.setErrorMessage(200, this.translate?.setup.users.access.messages.calendyDisconnectedSuccessfully ,  null, 'success',3000);;
      this.modalRef.hide();
    });
  }

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}