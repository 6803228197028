import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerSurveyService } from 'src/app/_services/customer-survey.service';
import { Model, SurveyNG, StylesManager } from "survey-angular";
import { surveyLocalization } from "survey-core";
import { setLicenseKey } from "survey-core";
import "survey-core/survey.i18n";

// Override individual translations in an existing locale:
const engLocale = surveyLocalization.locales["en"];
engLocale.completingSurvey = "Back";
const completingSurvey = {
  completingSurvey: "We appreciate your participation in the survey! Your feedback is incredibly valuable to us. Rest assured, we've taken note of all your insightful responses.",
};

surveyLocalization.locales["en"] = completingSurvey;

@Component({
  selector: 'app-customer-survey',
  templateUrl: './customer-survey.component.html',
  styleUrls: ['./customer-survey.component.scss']
})
export class CustomerSurveyComponent implements OnInit {
  svData: any;
  locationDetail: any;
  surveyData: any;
   surveyJson :any=  {}
  tenantId: any;
  surveyInfo: any;
   form : any={
    configId:null,
    surveySchema:null,
    jobOrder:{ id:null},
    content: null,
    jobOrderSurvey:{id:null}
    }
  configData: any;
  isSubmitted : any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private customerSurveyService: CustomerSurveyService
  ) {
    setLicenseKey(
      "Nzc4MmFhMmUtZmUzMC00OWE5LTlhZWUtMmQwYjNjYjM4MGFlOzE9MjAyNC0xMS0yOCwyPTIwMjQtMTEtMjgsND0yMDI0LTExLTI4"
  );
   }

  ngOnInit(): void {
    this.spinner.show()
    this.route.queryParams.subscribe(params => {
  
      this.svData = params['sv'];
      console.log(params['sv'])
      this.surveyData = atob(this.svData || '');
      this.surveyInfo = JSON.parse(this.surveyData);
      this.tenantId = this.surveyInfo.tenantId


        this.form.surveySchema=this.surveyInfo.surveySchemaId,
        this.form.jobOrder.id= this.surveyInfo.jobOrderId
        this.form.jobOrderSurvey.id=this.surveyInfo.jobOrderSurveyId
        this.form.configId=this.surveyInfo.surveyConfigId

      console.log(this.surveyData,"====",this.surveyInfo,"===",this.form,"===",this.surveyInfo.jobOrderId)
      this.getConfig()
    }); 

  }
  getConfig(){
    
    this.customerSurveyService.getPublicConfig(this.surveyInfo.surveyConfigId, this.surveyInfo.tenantId,this.surveyInfo.jobOrderId).subscribe((res:any) => {
      this.configData = res;
     
      this.configData.submitted === "false" ? this.isSubmitted =false : this.isSubmitted = true;
      // window.alert(this.isSubmitted)
      if(!this.isSubmitted){
      this.loadData()
      }
     
    })
   }
  loadData(){
    
    this.customerSurveyService.getPublicSurveyById(this.surveyInfo.surveyConfigId, this.surveyInfo.tenantId,this.surveyInfo.jobOrderId).subscribe((res:any) => {
     
      this.locationDetail = res;
      this.spinner.hide()
      this.surveyJson = this.locationDetail.content
     
      StylesManager.applyTheme("modern");

        const survey = new Model(this.surveyJson);
        survey.focusFirstQuestionAutomatic = false;
        
        // survey.locale = 'en';
        survey.onComplete.add((sender, options) => {
          console.log(JSON.stringify(sender.data, null, 3));
          this.saveCustomerSurvey(sender)
      });
        // survey.onComplete.add(this.saveCustomerSurvey);
        SurveyNG.render("surveyContainer", { model: survey });
      this.spinner.hide();
     
    })
   }
  
   /***********************************************************************************
    * 
    * When i am trying to save data , api throe 404 Status. please arfan to fix it..
    * 
    **********************************************************************************/

  saveCustomerSurvey(sender: any){
    this.spinner.show();
     
    console.log(sender.data)
    const results = JSON.stringify(sender.data);
    
    this.form.content = sender.data

console.log(this.form,"====",this.tenantId);    
    this.customerSurveyService.savePublicSurveyResult(this.form, this.tenantId).subscribe((res:any) => {
      console.log(res)
      this.spinner.hide();
     
    })
  }

}