
<div *ngIf="noTaskIcon" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
        <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
         <p class="text-6 my-1 font-weight-bold">{{ translate?.general.messages.noRecordsFound}}</p>
    </div>
</div>

<!-- TabMenu -->
<div [hidden]="!noTask" style="height: calc(100% - 25px);">
       <ag-grid-angular
            #agGrid
            style="height: 100%;"
            class="ag-theme-balham"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [pagination]="false"
            [cacheOverflowSize]="2"
            [suppressDragLeaveHidesColumns]="true"
            [maxConcurrentDatasourceRequests]="-1"
            [allowContextMenuWithControlKey]="false"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="viewCustomer($event)"
            (gridReady)="onGridReady($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [getContextMenuItems]="getContextMenuItems"
          
        ></ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_TaskAllTeamTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p>
        </div>
   
  
</div>

