import { DeleteRecordService } from './../../../_services/delete-record.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { Subscription } from 'rxjs';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-hazard-control',
  templateUrl: './hazard-control.component.html',
  styleUrls: ['./hazard-control.component.scss']
})
export class HazardControlComponent  extends SubscriptionUtil implements OnInit, OnDestroy {
  data: any
  title:any;
  id: any;
  take5Form: any;
  timezone: any;
  disableField: boolean = true;
  selectedConfig : any;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-md'
  };
  modalState : any;
  selectedComponent : any;
 
  dateFormat: any;
  addBtnShow:boolean = true;
  editForm :boolean = false;
  refresher!: Subscription;
  hazardsCtr: any[]=[];
  translate: any;
  constructor(
    private route: ActivatedRoute,
    private taskService: TasksService,
    private auth: AuthenticationService,
    private router: Router,
    private modalService: BsModalService, 
    private spinner : NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private breadcrumbService: BreadcrumbService,
    private deleteRecordService: DeleteRecordService,
    public modalRef : BsModalRef,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()
      this.refresher = this.taskService.refreshList$.subscribe((item:any) => {
        console.log(item)
        if(item == 'Task Hazard'){
          modalRef.hide()
         // this.router.navigate(['dashboard/tasks/view' + this.take5Form.task.id], { queryParams: { jobOrderId: this.take5Form.task.id}});
        }
      });
     }

  ngOnInit() {
    this.dateFormat = localStorage.getItem('date_format');
    this.timezone = this.auth.getUserTimezone();
    this.hazardsCtr = []
    this.id = this.data.take5Form.id;
    this.taskService.getTake5ById(this.id).subscribe((task:any)=>{
      if(task.status === 200 || task.status === 201){
        //this.take5Form = task.body.take5Form;
        this.take5Form = task.body;
       // this.loadBreadCrumb()
       this.take5Form.hazards.forEach((element: any) => {
        var newData={
          name : element,
          img : element.replace('&', "and")
        }
        this.hazardsCtr.push(newData)
       });
        if(this.take5Form){
          this.addBtnShow = this.take5Form.review ? false : true;
        }
      }else {
        this.errorUtil.setErrorMessage(task.status, null , task.error.title, 'error',3000);
      }
    },(err) => {
      this.spinner.hide()
     const title = this.errorUtil.processErrorTitle(err.error,this.translate)
     this.errorUtil.setErrorMessage(err.status, null ,  title, 'error',3000);
     //this.submitTouched = false;
  })
  }
  //   loadBreadCrumb(){
  //   this.breadcrumbService.setSetupItems([
  //     { label: 'Job Order', command: e => {
  //       this.router.navigate(['dashboard/jobOrders'],);
  //     }},
  //     { label: this.take5Form.task.jobOrder.jobOrderNumber, command: e => {
  //       this.router.navigate(['dashboard/jobOrders/view/' + this.take5Form.task.jobOrder.id], { queryParams: { jobOrderId: this.take5Form.task.jobOrder.id}});;
  //     }},
  //     { label: this.take5Form.task.taskName, command: e => {
  //       this.router.navigate(['dashboard/tasks/view' + this.take5Form.task.id], { queryParams: { jobOrderId: this.take5Form.task.id}});;
  //     }},
  //     { label: 'Hazard Control' }
  //     ]);
  // }
  clickEdit(){
    this.editForm = !this.editForm;
  }

  openModal() {
    this.selectedConfig = this.config;
    this.take5Form.deleteType = 'Task Hazard'
    this.take5Form.deleteHeader = this.translate.task.label.singular + " " +  this.translate.task.sections.sec32.singular
    this.take5Form.message = this.translate?.general.messages.confirmDelete
    this.take5Form.id = this.take5Form.id;
    this.deleteRecordService.getItems(this.take5Form);
  }


  saveNote(){
    this.taskService.saveNoteTask(this.take5Form.id,this.take5Form.review).subscribe((res:any)=>{
      console.log(res)
      this.editForm = false;
      this.addBtnShow = false;
       this.errorUtil.setErrorMessage(200, this.translate?.general.messages.savedSuccessfully,  null ,'success',1000);
    })
  }

  goBack(){
    window.history.back();
  }

  viewJoborder(jobOrder: any){
    this.router.navigate(['dashboard/jobOrder/' + jobOrder.id], { queryParams: { jobOrderId: jobOrder.id } });
  }

  viewUser(id: any, edit: any){
    this.router.navigate(['/dashboard/admin/users/allUser/'+id], { queryParams: { id: id, edit: edit}});
  }

  generateChubbPfd(data: any){
    this.spinner.show();
    this.taskService.generateTake5FormPDF(data.id).subscribe((res:any)=>{
      if(res){
        window.open(res.attachmentUrl, "_blank");
        this.spinner.hide();
      }else{
        this.errorUtil.setErrorMessage(res.status, null , this.translate?.attachments.messages.pdfNotAvailable, 'error',3000);
        this.spinner.hide();
      }
    });
  }
downloadChubbPfd(attachmentUrl: any){
    this.spinner.show();
   
        window.open(attachmentUrl, "_blank");
        this.spinner.hide();
      
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}