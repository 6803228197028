import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { AppComponent } from "src/app/app.component";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ProfilesService } from "src/app/_services/admin/profiles.service";
import { UsersService } from "src/app/_services/admin/users.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { chineseLangData, deutschLangData, EngLangData, languageOptions, MalayLangData, spanishLangData } from "src/app/_models/language.data.model";

@Component({
  selector: "app-profile-info",
  templateUrl: "./profile-info.component.html",
  styleUrls: ["./profile-info.component.scss"],
})
export class ProfileInfoComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  refreshPhoto!: Subscription;
  profileInfoForm!: UntypedFormGroup;
  profile: any = {};
  photoUrl: any = "assets/svg/Avatar.svg";
  submitTouched!: boolean;

  fieldTextType!: boolean;
  fieldTextType2!: boolean;
  @ViewChild('chooseLang') chooseLangEl: ElementRef;
  translate: any;
  currentLang: any = 'English'
  languageList: any;
  constructor(
    private userService: UsersService,
    private router: Router,
    private auth: AuthenticationService,
    private modalService: ModalServiceService,
    private profileService: ProfilesService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.profileInfoForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.emailPattern),
      ]),
      phone: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.phonePattern),
      ]),
    });

    this.refreshPhoto = this.profileService.refreshProfilePhoto$.subscribe(
      (res: any) => {
        if (res.attachmentUrl) this.photoUrl = res.attachmentUrl;
      }
    );
  }

  /******************************************** Init ******************************************/
  private init() {

    var langCode = localStorage.getItem('lang');
    if (langCode == 'my') this.onChangeLanguage('Bahasa Melayu')
      else if (langCode == 'en') this.onChangeLanguage('English')
      else if (langCode == 'zh') this.onChangeLanguage('简体中文')
    else this.onChangeLanguage('English')
    this.languageList = Object.values(languageOptions);

    this.spinner.show();
    this.profile.language = "en";
    //this.getLanguageData();
    this.auth.getUserId();

    this.push(
      this.userService
        .getUserProfile(this.auth.getUserId())
        .subscribe((res) => {
          this.profile.firstName = res.firstName;
          this.profile.lastName = res.lastName;
          this.profile.phone = res.phone;
          this.profile.email = res.email;
          this.profile.language = res.language;
          this.spinner.hide();
        })
    );
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** On Submit ******************************************/
  public onSubmit() {
    this.submitTouched = true;

    if (!this.profileInfoForm.valid) {
      for (var i in this.profileInfoForm.controls) {
        this.profileInfoForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    if (this.profile.password !== this.profile.cPassword) {
      this.errorUtil.setErrorMessage(
        "400",
        null,
        this.translate?.setup.users.users.messages.passwordsDoNotMatchTryAgain,
        "warn",
        2000
      );
    } else {
      var formData = {
        id: this.auth.getUserId(),
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        phone: this.profile.phone,
        email: this.profile.email,
        language: this.profile.language,
        password: this.profile.password,
      };

      console.log(formData);
debugger
      this.push(
        this.userService.adjustProfile(formData).subscribe((res: any) => {
          if (res.status === 200 || res.status === 201) {
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.savedSuccessfully,
              null,
              "success",
              2000
            );

            this.router.navigate(["/dashboard/home"]);
          } else {
            this.submitTouched = false;

            this.errorUtil.setErrorMessage(
              400,
              this.translate?.general.messages.somethingWentWrong,
              this.translate?.general.messages.somethingWentWrong,
              "error",
              2000
            );
          }
        })
      );
    }
  }

  /******************************************** Toggle Password Show ******************************************/
  public toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  /******************************************** Password Length ******************************************/
  public getPassowrdLength(password: any) {
    const value = password.target.value;

    if (value.length < 9) {
      this.profileInfoForm.controls["password"].setValidators([
        Validators.required,
        Validators.pattern(AppComponent.passwordPattern),
      ]);
      this.profileInfoForm.controls["password"].updateValueAndValidity();
    } else {
      this.profileInfoForm.controls["password"].clearValidators();
      this.profileInfoForm.controls["password"].updateValueAndValidity();
    }
  }

  /******************************************** Toggle Confirm Password Show ******************************************/
  public toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  /******************************************** Confirm Password Length ******************************************/
  public getCPassowrdLength(cPassword: any) {
    const value = cPassword.target.value;

    if (value.length < 9) {
      this.profileInfoForm.controls["cPassword"].setValidators([
        Validators.required,
        Validators.pattern(AppComponent.passwordPattern),
      ]);
      this.profileInfoForm.controls["cPassword"].updateValueAndValidity();
    } else {
      this.profileInfoForm.controls["cPassword"].clearValidators();
      this.profileInfoForm.controls["cPassword"].updateValueAndValidity();
    }
  }

  /******************************************** Confirm Password Length ******************************************/
  public openModal(modalView: any, data: any) {
    const dataToSend = {
      id: this.auth.getUserId(),
      type: "user",
    };

    this.modalService.openModal(modalView, dataToSend);
  }
   /******************************************** Add ALl the Subscription ******************************************/
   push(obs:any) {
    super.push(obs);
  }

  // getLanguageData() {
  //   this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
  //     if(Object.keys(data).length != 0){
  //       this.translate = data
  //       //this.loadBreadCrum()
  //     }
  //   }))
  //   console.log("translate",this.translate)
  //   //this.mainLabel = this.translate?.setup.users.users.label.plural
  // }

  onChangeLanguage(landKey: any) {
    console.log(landKey)

    if (landKey.value == 'English' || landKey == 'English') {
      this.currentLang = languageOptions.english
      this.translate = EngLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'en')
    }
    if (landKey.value == 'Bahasa Melayu' || landKey == 'Bahasa Melayu') {
      this.currentLang = languageOptions.malay
      this.translate = MalayLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'my')

    }
    if (landKey.value == '简体中文' || landKey == '简体中文') {
      this.currentLang = languageOptions.chinese
      this.translate = chineseLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'zh')

    }
    if (landKey.value == "Deutsch" || landKey == "Deutsch") {
      this.currentLang = languageOptions.deutsch;
      this.translate = deutschLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "de");
    }
    if (landKey.value == "Español" || landKey == "Español") {
      this.currentLang = languageOptions.chinese;
      this.translate = spanishLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "es");
    }
    console.log(this.translate)
  }

}
