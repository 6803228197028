import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerSurveyService } from 'src/app/_services/customer-survey.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { Model, SurveyNG, StylesManager } from "survey-angular";
import "survey-core/survey.i18n";
import { SurveyPDF } from "survey-pdf";
import { setLicenseKey } from "survey-core";
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
var json : any
@Component({
  selector: 'app-view-survey-form',
  templateUrl: './view-survey-form.component.html'
})
export class ViewSurveyFormComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  surveyJson :any=  {}
  data: any
  configData: any;
  answerData: any;
  modifiedField: any = [];
  model!: Model;
  translate: any;
  

  constructor(public modalRef : BsModalRef,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private customerSurveyService: CustomerSurveyService,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService) { 
      super()
      setLicenseKey(
        "Nzc4MmFhMmUtZmUzMC00OWE5LTlhZWUtMmQwYjNjYjM4MGFlOzE9MjAyNC0xMS0yOCwyPTIwMjQtMTEtMjgsND0yMDI0LTExLTI4"
    );
    }

  ngOnInit(): void {
    console.log(this.data)
    this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.loadData()
      }
    }))

  }

  loadData(){
    this.spinner.show()
    this.customerSurveyService.getBuildSurveyById(this.data.customerSurveyConfig.id).subscribe((res:any) => {
      this.spinner.hide()
      this.configData = res;
      json = this.configData.content
     this.getPublicResult()
     
    })
   }


  getPublicResult(){
    
    this.customerSurveyService.getPublicSurveyResultByJo(this.data.surveySchemaId,this.auth.getTenantId(), this.data.jobOrder?.id).subscribe((res:any) => {
      this.answerData = res;
     
      const keys = Object.keys(res[0].content);
      for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          this.configData.content.pages.forEach((element  :any)=> {
            element.elements.forEach((column  :any)=> {
                  if(key == column.name){
                    console.log(key,"===",column.name,"====",res[0].content[key])
                    if(column.type == 'tagbox'){
                      let choices = new Map();
                      column.choices.forEach((v:any)=> _.has(v, 'value') ? choices.set(v.value, v.text) : choices.set(v, v) );
                      column.value = res[0].content[key];
                      column.value = column.value.map((v:any)=> choices.get(v));
                    } else if(column.type == 'radiogroup'){
                      let choices = new Map();
                      column.choices.forEach((v:any)=> choices.set(v.value, v.text));
                      column.value = res[0].content[key];
                      column.value = choices.get(column.value);
                    } else if(column.type == 'matrixdropdown'){
                      let rows = new Map();
                      column.value = res[0].content[key];
                      column.rows.forEach((v:any)=> rows.set(v.text, column.value[v.value]));
                      column.value = rows;
                    } else if(column.type == 'file'){
                      column.value = res[0].content[key];
                      column.value = column.value.content ? [column.value] : column.value;
                    } else {
                      column.value = res[0].content[key];
                    }
                  }
                });
          });

        }
        console.log(this.configData.content.pages)
        

//// Cusrrently i am receving data in an array format..later you have json formtal please cheange it to normal
      StylesManager.applyTheme("modern");
      this.surveyJson = this.configData.content
      const survey = new Model(this.surveyJson);

      survey.data = this.answerData[0].content
     
      this.model = survey;
      console.log( survey.data,"===",this.answerData[0].content)
      
      survey.focusFirstQuestionAutomatic = false;
      
     SurveyNG.render("surveyContainer", { model: survey });


      this.spinner.hide();
     
    })
   }
   public savePdf() {
    saveSurveyToPdf("surveyResult.pdf", this.model);
  };
  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
function createSurveyPdfModel(surveyModel: { pdfWidth: any; pdfHeight: any; data: any; }) {
    // alert(json)
  let pdfWidth = !!surveyModel && surveyModel.pdfWidth ? surveyModel.pdfWidth : 210;
  let pdfHeight = !!surveyModel && surveyModel.pdfHeight ? surveyModel.pdfHeight : 297;
  let options = {
      fontSize: 14,
      margins: {
          left: 10,
          right: 10,
          top: 10,
          bot: 10
      },
      
      format: [pdfWidth, pdfHeight]
  };
  console.log(json)
  const surveyPDF = new SurveyPDF(json, options);
  if (surveyModel) {
      surveyPDF.data = surveyModel.data;
  }
  
  return surveyPDF;
}
function saveSurveyToPdf(filename: any, surveyModel: any) {
  createSurveyPdfModel(surveyModel).save(filename);
}
