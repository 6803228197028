<div class="custella-modal" *ngIf="configData">
    <form>
        <div class="custella-modal-header align-items-center">
            <div class="text-14 font-medium" id="title_JobSurveyFormResults">{{translate?.jobOrder.sections.sec45.singular}}</div>
            <i class="pi pi-times" id="btn_JobSurveyFormResultsClose" [pTooltip]="translate?.general.buttons.close.singular" (click)="modalRef.hide()"></i>
        </div>
  
        <div class="">
            <!-- Container -->
            <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0" style="height: fit-content;">
                <ng-container *ngFor="let section of configData.content.pages;let p = index">
                    <div class="custella-form-container-title-two text-14 font-medium" >{{section?.name}}</div>
                    <!-- Content -->
                    <div class="custella-form-container-content-border-bottom m-0"  *ngFor="let element of section.elements;let p = index">
                        <div class="custella-content-row mb-3 pe-0" *ngIf="element.type != 'matrixdropdown' && element.type != 'file'">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title-two" >{{element?.title}}</span>
                                <p class="custella-content-row-input-title-three">{{element?.value}}</p>
                            </div>
                        </div>
                        <div class="custella-content-row mb-3 pe-0" *ngIf="element.type == 'matrixdropdown'">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title-two" >{{element?.title}}</span>
                                <div class="custella-content-row-input-title-three d-flex" *ngFor="let el of element?.value | keyvalue">
                                    {{el?.key}} 
                                    <p class="text-sm-start text-black-50" style="margin-left: 10px;">&nbsp; {{el?.value | json }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="custella-content-row mb-3 pe-0" *ngIf="element.type == 'file'">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title-two" >{{element?.title}}</span>
                                <div class="custella-content-row-input-title-three d-flex" >
                                    <!-- <img  [src]="el?.content" class="position-relative mt-2" style="width: 32px;"/> -->
                                    <p-avatar [image]="el?.content" styleClass="mx-2 mt-1"size="large" *ngFor="let el of element?.value"></p-avatar>
                                </div>
                                <!-- <div class="custella-content-row-input-title-three d-flex" *ngIf="element?.value?.type == 'image/png'">
                                    <img  [src]="element?.value?.content" class="position-relative mt-2" style="width: 28px;"/>
                                </div> -->
                            </div>
                        </div>
                    </div>
                   
                </ng-container>
               
                <!-- Footer -->
                <div class="custella-form-container-footer mb-0">
                    <div class="d-flex align-items-center justify-content-end">
                        <p-button (onClick)="savePdf()" styleClass="ms-2 h-28" icon="fa-regular fa-download" [label]="translate?.general.buttons.download.singular" id="btn_JobSurveyFormResultsDownload"></p-button>
                    </div>
                </div>
            </div>
        
        </div>
    </form>
</div>