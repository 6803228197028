import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SettingService } from 'src/app/_services/admin/setting.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-salesforce-integration',
  templateUrl: './salesforce-integration.component.html'
})
export class SalesforceIntegrationComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  company : any = {};
  title: any;
  submitTouched : boolean = false;
  translate: any;

  constructor(private settingsService : SettingService,
    private errorUtil: ErrorUtil,
    public modalRef : BsModalRef,
    private languageTranslateService: LanguageTranslateService) {
      super()
     }

  ngOnInit() {
   
  }

  onSubmit(){
    this.submitTouched = true;
    var data = {
      tenantId: 1,
      syncCustomerToSf: this.company.syncCustomerToSf,
      syncConstactToSf: this.company.syncConstactToSf,
      syncJoToSf: this.company.syncJoToSf,
      syncSoToSf: this.company.syncSoToSf,
      syncProductToSf: this.company.syncProductToSf
    }
    this.settingsService.updateOrgSFSettings(data).subscribe((res:any) => {
      if(res.status === 200 || res.status === 201){
        this.errorUtil.setErrorMessage(200, 
          this.translate?.general.messages.updatedSuccessfully, null, "success", 2000);
      this.modalRef.hide();
      }
    })
  }


  getLanguageData() {
   
   this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
          this.settingsService.getOrgSFSettings().subscribe((res:any) => {
            this.company = res;
          })
        }
      }))

    //this.mainLabel = this.translate?.site.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
