import { Component } from "@angular/core";
import { ITooltipAngularComp } from "ag-grid-angular";
import { ITooltipParams } from "ag-grid-community";

@Component({
  selector: "app-table-header-tool-tip",
  templateUrl: "./table-header-tool-tip.component.html",
  styleUrls: ["./table-header-tool-tip.component.scss"],
  styles: [],
})
export class TableHeaderToolTipComponent implements ITooltipAngularComp {
  public params!: ITooltipParams;

  public headerName: string | null = "";

  agInit(params: ITooltipParams): void {
    this.params = params;

    if (this.params?.colDef?.headerName) {
      this.headerName = this.params?.colDef?.headerName;
    }
  }
}
