import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { AnyARecord } from "dns";
import * as moment from "moment";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TeamService {
  private ENDPOINT_LIST_MY_TEAMS = "/teams/by-user";
  private ENDPOINT_RECOMMENT_USER_BY_TEAM = "/recommend/user-by-team/v2";
  private ENDPOINT_LIST_ALL_TEAMS="/teams/get-all-teams";
  private ENDPOINT_GET_TEAM_SETTINGS="/teams/get-team-settings";
  private ENDPOINT_CREATE_TEAM="/teams/create";
  private ENDPOINT_DELETE_TEAM="/teams/";
  private ENDPOINT_TEAM_PERFORMANCE="/teams/get-team-performance";
  private ENDPOINT_EMPLOYEE_BY_TEAM="/teams/get-all-team-users";

  private ENDPOINT_ADD_TEAM_MEMBER="/teams/add-new-member";
  private ENDPOINT_UPDATE_TEAM_MEMBER="/teams/update-team-member";
  private ENDPOINT_TEAMS_V3_WITH_TASKS="/v3/teams/by-user";
  private ENDPOINT_TEAMS_PICKLIST="/teams/pick-list/by-user/with-image";
  private ENDPOINT_MANAGED_V3_USER_WITH_TASKS="/v3/users/managed-downline";
  private ENDPOINT_MANAGED_USER_WITH_TASKS="/users/managed-downline";

  public refreshList$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.refreshList$ = new EventEmitter();
  }

  /******************************************** comment ******************************************/
  public getTeamsByUserIdForTask(id: any) {
    let params = new HttpParams().set("userId", id);
    return this.http
      .get(environment.base_url + "/v4/teams/pick-list/by-user", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getTeamsByUserId(id: any) {
    let params = new HttpParams().set("userId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_MY_TEAMS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public recommendedUsersByTeam(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_RECOMMENT_USER_BY_TEAM, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
/******************************************** Get All Team ******************************************/
  public getAll(id : any){
    let params = new HttpParams().set('userId', id);
    return this.http.get( environment.base_url+this.ENDPOINT_LIST_ALL_TEAMS, {params : params,observe: "response"}).pipe(map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
}
/******************************************** Get  Team By Id******************************************/
public getTeamSettings(id : any){
  let params = new HttpParams().set('teamId', id);
  return this.http.get( environment.base_url+this.ENDPOINT_GET_TEAM_SETTINGS, {params : params}).pipe(map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
}
/******************************************** Create / update  Team By Id******************************************/
public createTeam(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_CREATE_TEAM, body, { observe: 'response' }).pipe(map((resp: any) => {
    return resp;
  }),
  catchError((error) => {
    return throwError(error);
  })
);
}
public getTeamById(id : any){
  return this.http.get(environment.base_url+this.ENDPOINT_DELETE_TEAM+id, {observe: 'response'}).pipe(map((resp: any) => {
    return resp;
  }),
  catchError((error) => {
    return throwError(error);
  })
);
}
/******************************************** Delete  Team By Id******************************************/
public deleteTeam(id : any){
    return this.http.delete( environment.base_url+this.ENDPOINT_DELETE_TEAM+id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit('Deleted Team');
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }
  /**************************************************** Get Team Performance ******************************************/
  public getTeamPerformance(id: any, date: any, timezone: any){
    let params = new HttpParams().set('teamId', id).set('date', date).set('timezone', timezone);
    return this.http.get( environment.base_url+this.ENDPOINT_TEAM_PERFORMANCE, {params : params}).pipe(map((resp: any) => {
     // this.refreshList$.emit('Deleted Team');
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }
 /**************************************************** Get Eployee By Team ******************************************/
  public getEmployeeByTeam(id : any, leave : any,excludeDrivers: any){
    let params = new HttpParams().set('teamId', id);
    if(leave)
      params = params.set('leaveDate', leave);
    params = params.set('excludeDrivers', excludeDrivers);
    return this.http.get( environment.base_url+this.ENDPOINT_EMPLOYEE_BY_TEAM, { params : params}).pipe(map((resp: any) => {
     // this.refreshList$.emit('Deleted Team');
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }
/**************************************************** Add Eployee By Team ******************************************/
  public addTeamMember(body:any){
    return this.http.put( environment.base_url+this.ENDPOINT_ADD_TEAM_MEMBER, body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit(body);
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }
  /**************************************************** Add Eployee By Team ******************************************/
  deleteTeamProfilePic(id: any){
    return this.http.put( environment.base_url+'/teams/remove/image?teamId='+ id, null, {observe: 'response' })
    .pipe(
      map((resp: any) => {
        this.refreshList$.emit('Picture Deleted');
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
/**************************************************** Update Team Member ******************************************/
  public editTeamMember(body:any){
    return this.http.put( environment.base_url+this.ENDPOINT_UPDATE_TEAM_MEMBER, body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit(body);
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }
/**************************************************** Delete Member ******************************************/
  public deleteTeamMember(data : any){
    return this.http.delete( environment.base_url+this.ENDPOINT_DELETE_TEAM+data.teamId+'/remove-member/'+data.userId, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit('Deleted Team Member');
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }
/**************************************************** Team Task LIst ******************************************/

  public getTeamTasks(teamId : any,search : any,parameters : any){
    let params = new HttpParams();
    params = params.set('teamId', teamId);
    if(search)
      params = params.set('search', search);
      if(parameters)
      params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
        return this.http.get( environment.base_url+'/v4-1/tasks/by-module', { observe: 'response', params : params }).pipe(map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );
  }

  public getTeamsWithTasksV3(id: any, startDate: any, endDate: any,teamIds: any,isAllTeam: any,taskStatuses: any){
    let params = new HttpParams().set('userId', id).set('withTasks', 'true');
    if(endDate){
      params = params.set('startDate', startDate).set('endDate', endDate);
    } else {
      params = params.set('startDate', moment().format('YYYY-MM-DD')).set('endDate', moment().format('YYYY-MM-DD'));
    }
    //if(teamIds){
      params = params.set('teamIds', teamIds);
    //}
      params = params.set('isAllTeam', isAllTeam);
      if(taskStatuses !== '' && taskStatuses != null){
        params = params.set('taskStatuses', taskStatuses);
      }

      return this.http.get( environment.base_url+this.ENDPOINT_TEAMS_V3_WITH_TASKS, {params : params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
    }

    public getTeamsPickList(id: any){
      let params = new HttpParams()
      if(id)
        params = params.set('userId', id);;
      return this.http.get( environment.base_url+this.ENDPOINT_TEAMS_PICKLIST, {params : params}).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
    }

    public getManagedUsersWithTasksV3(id:any, leave:any, online:any, userTypes:any, tasks:any, startDate:any, endDate:any,excludeDrivers:any, taskStatuses: any){
      let params = new HttpParams().set('userId', id)
      if(leave)
        params = params.set('leaveDate', leave);
      if(userTypes)
        params = params.set('allStaff', userTypes);
      if(online)
        params = params.set('onlineStatus', online);
      if(tasks)
        params = params.set('withTasks', tasks);
      if(endDate){
        params = params.set('startDate', startDate).set('endDate', endDate);
      } else {
        params = params.set('startDate', moment().format('YYYY-MM-DD')).set('endDate', moment().format('YYYY-MM-DD'));
      }
      if(taskStatuses !== '' && taskStatuses != null){
        params = params.set('taskStatuses', taskStatuses);
      }
      params = params.set('excludeDrivers', excludeDrivers);
      var allowFilterByHierarchy = localStorage.getItem('allowFilterByHierarchy');
      if(allowFilterByHierarchy != 'false' &&  allowFilterByHierarchy != 'null' && allowFilterByHierarchy != null){
        params = params.set('byHierarchy', allowFilterByHierarchy);
      }
      return this.http.get( environment.base_url+this.ENDPOINT_MANAGED_V3_USER_WITH_TASKS, {observe: "response", params : params}).pipe(map((resp: any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
    }

    public getManagedUsersWithTasks(id : any, leave : any, online : any, userTypes : any, tasks : any, startDate : any, endDate : any){
      let params = new HttpParams().set('userId', id)
      if(leave)
        params = params.set('leaveDate', leave);
      if(userTypes)
        params = params.set('allStaff', userTypes);
      if(online)
        params = params.set('onlineStatus', online);
      if(tasks)
        params = params.set('withTasks', tasks);
      if(endDate){
        params = params.set('startDate', startDate).set('endDate', endDate);
      } else {
        params = params.set('startDate', moment().format('YYYY-MM-DD')).set('endDate', moment().format('YYYY-MM-DD'));
      }
      var allowFilterByHierarchy = localStorage.getItem('allowFilterByHierarchy');
      if(allowFilterByHierarchy != 'false' &&  allowFilterByHierarchy != 'null' && allowFilterByHierarchy != null){
        params = params.set('byHierarchy', allowFilterByHierarchy);
      }
      return this.http.get( environment.base_url+this.ENDPOINT_MANAGED_USER_WITH_TASKS, {observe: "response", params : params}).pipe(map((resp: any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
    }

    public setUserCalendarColor(body:any){
      return this.http.post( environment.base_url+'/user-calender-colors', body, { observe: 'response' }).pipe(map((resp: any) => {
        this.refreshList$.emit(body);
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
    }

    public getTeamsByUserIdForSmartAssign(id: any) {
      let params = new HttpParams().set("userId", id);
      params = params.set("forSmartAssign", true);

      return this.http
        .get(environment.base_url + this.ENDPOINT_LIST_MY_TEAMS, {
          params: params,
        })
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        );
    }

    public getAllTeamsPickList(){
       return this.http.get( environment.base_url+'/v4/teams/pick-list').pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
    }
}

