import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  
   /****************** Breadcrumb Variable *********/
   breadcrumbSetupSubscription!: Subscription;
   setupItems: MenuItem[]=[];
   
  constructor(
    private breadcrumbService : BreadcrumbService,
    private cdr: ChangeDetectorRef
  ) { 
    this.breadcrumbSetupSubscription = breadcrumbService.setupItemsHandler.subscribe((response: any) => {
      this.setupItems = response;
   });
  }
  ngOnInit(): void {
  }

}
