<div class="custella-setup-body pt-0">
    <div class="custella-form-container left-right-space mt-56px rounded-2">
      <form  [formGroup]="connectForm">
        
        <div class="custella-form-container-title text-14 font-medium rounded-top" id="title_IntegrationConnect" >{{translate?.setup.integrations.sections.sec0.singular}}</div>
        <div class="custella-form-container-content">
          <div class="custella-content-row mb-3">
            <div class="custella-content-row-input col-6">
              <span class="custella-content-row-input-title mandatory" id="label_IntegrationConnectClientId" > {{translate?.setup.integrations.fields.clientId.singular}} </span>
              <input type="text" pInputText autocomplete="off"  [(ngModel)]="connect.clientId" id="input_IntegrationConnectClientIdField" formControlName="clientId" />
              <div *ngIf="connectForm.controls['clientId'].touched && connectForm.controls['clientId'].invalid">
                <span class="text-10 p-error" *ngIf="connectForm.controls['clientId'].errors?.required" id="label_IntegrationConnectClientIdReqField" >{{translate?.general.validationFE.requiredField }}</span>
              </div>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title mandatory" id="label_IntegrationConnectClientSecret" > {{translate?.setup.integrations.fields.clientSecret.singular}} </span>
                <input type="text" pInputText autocomplete="off" [placeholder]="translate?.general.messages.none " [(ngModel)]="connect.clientSecret" id="input_IntegrationConnectClientSecretField" formControlName="clientSecret" />
                <div *ngIf="connectForm.controls['clientSecret'].touched && connectForm.controls['clientId'].invalid">
                  <span class="text-10 p-error" *ngIf="connectForm.controls['clientSecret'].errors?.required" id="label_IntegrationConnectClientSecretReqField" >{{translate?.general.validationFE.requiredField }}</span>
                </div>
              </div>
          </div>
         
        </div>
      </form>
      <div class="custella-form-container-footer">
        <div class="d-flex align-items-center justify-content-end">
          <p-button [disabled]="submitTouched" [label]="translate?.general.buttons.save.singular" styleClass="ms-2 h-32" id="btn_IntegrationConnectClientSecretSubmit" (onClick)="connectSalesforce()"></p-button>
        </div>
      </div>
    </div>
  </div>
  
  