import { of as throwError, Observable, Subject } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AuthenticationService } from "../auth/authentication.service";
import { RequestOptions, Http, Headers } from "@angular/http";
import { UtilServiceService } from "./utilService/util-service.service";

@Injectable({
  providedIn: "root",
})
export class SalesOrdersService {
  private ENDPOINT_ALL_SALES_ORDERS = "/v4/sales-orders";
  private ENDPOINT_SALES_ORDERS_BY_ID = "/v4/sales-orders/";
  private ENDPOINT_GET_SALES_ORDER_PRODUCTS = "/v4/sales-order-products"
  private ENDPOINT_ALL_SALES_ORDERS_V2 = "v4-1/sales-orders";
  private ENDPOINT_FILTER_SALES_ORDERS = "/v4-1/sales-orders/";

  public refreshSalesOrderList$: EventEmitter<any>;
  private soItemsSource = new Subject<any>();
  soitemsHandler = this.soItemsSource.asObservable();

  public refresherPickList$: EventEmitter<any>;

  constructor(
    private http: HttpClient,
    private httpOnly: Http,
    private auth: AuthenticationService,
    private utilService: UtilServiceService
  ) {
    this.refreshSalesOrderList$ = new EventEmitter();
    this.refresherPickList$ = new EventEmitter();
  }
  setItems(items: any) {
    this.soItemsSource.next(items);
  }
  /*****************************************Get the SO list by Customer Id*****************************************/
  public getSalesOrderByCustomerV2(searchValue: any, parameters: any, id: any) {
    let params = new HttpParams();
    if (searchValue) {
      params = params.set("search", searchValue);
    }
    params = params.set("origin", "customer");
    
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort)
        .set("customerId", id);

    return this.http
      .get(environment.base_url + "/v4-1/sales-orders/", {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public getSalesOrderByCustomerPickList(searchValue: any, id: any) {
    let params = new HttpParams();
    if (searchValue) {
      params = params.set("value", searchValue);
    }
    return this.http
      .get(
        environment.base_url +
          "/v2/sales-orders/pick-list/search?customerId=" +
          id,
        { observe: "response", params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************Create SO*********************************************************************/
  public createSalesOrder(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_ALL_SALES_ORDERS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshSalesOrderList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************Update  SO****************************************************************************/

  public editSalesOrder(body: any) {
    console.log('EDIT', body);

    return this.http
      .put(environment.base_url + this.ENDPOINT_ALL_SALES_ORDERS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshSalesOrderList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************Delete SO*********************************************************************/

  public deleteSalesOrder(id: any) {
    return this.http
      .delete(environment.base_url + '/sales-orders/' + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshSalesOrderList$.emit("Deleted Sales Order");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*****************************************Get SO Products*********************************************************************/

  public getSalesOrderProducts(id: any) {
    let params = new HttpParams();
    params = params.set("salesOrderId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_SALES_ORDER_PRODUCTS, {
        observe: "response",
        params: params
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  public getSalesOrderStatuses(active: any = null) {
    var active1=''
    if(active){ active1='?isActive=true'}
    return this.http.get(environment.base_url + "/sales-order-statuses"+active1).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getSalesOrderById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_SALES_ORDERS_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllSalesOrders(parameters: any) {
    const fullParams = this.utilService.returnGeneratedParams(parameters);

    return this.http
      .get(
        environment.base_url +
          "/" +
          this.ENDPOINT_ALL_SALES_ORDERS_V2 +
          fullParams
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public filterSalesOrder(
    startFrom: any,
    perPage: any,
    fromDate: any,
    toDate: any,
    search: any,
    status: any,
    customerId: any,
    parameters: any,
    origin: any
  ) {
    let params = new HttpParams();
    params = params.set("startFrom", startFrom);

    if (perPage && perPage) {
      params = params.set("perPage", perPage);
    }

    if (fromDate && fromDate !== "") {
      params = params.set("fromDate", fromDate);
    }

    if (toDate && toDate !== "") {
      params = params.set("toDate", toDate);
    }

    if (status && status !== "") {
      params = params.set("status", status);
    }

    if (customerId && customerId !== "" && customerId != null && customerId !== undefined) {
      params = params.set("customerId", customerId);
    }

    if (status && status !== "") {
      params = params.set("status", status);
    }

    if (origin && origin === "customer") {
      params = params.set("origin", origin);
    }

    if (parameters) {
      params = params
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    }

    return this.http
      .get(environment.base_url + this.ENDPOINT_FILTER_SALES_ORDERS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSalesOrderType() {
    return this.http
      .get(environment.base_url + "/sales-order-types?isActive=true")
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSalesChannel(active: any = null) {
    var active1=''
    if(active){ active1='?isActive=true'}
    return this.http.get(environment.base_url+'/sales-order-channels'+active1).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getSalesOrderHistoryById(salesOrderId: number) {
    return this.http
      .get(
        environment.base_url +
          "/sales-order-audit-event-histories/by-sales-order/" +
          salesOrderId
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /******************************************** get job order Resolution ******************************************/
  public getSOrderStatuses(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?active=true";
    }

    return this.http
      .get(environment.base_url + "/sales-order-statuses/all" + active1)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public createSOrderStatuses(body: any) {
    return this.http
      .post(environment.base_url + "/sales-order-statuses", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateSOrderStatuses(body: any) {
    return this.http
      .put(environment.base_url + "/sales-order-statuses", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getSOrderStatusesById(id: any) {
    return this.http
      .get(environment.base_url + "/sales-order-statuses/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteSOrderStatuses(id: any) {
    return this.http
      .delete(environment.base_url + "/sales-order-statuses/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted SO status");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /******************************************** get Sales order Channel ******************************************/
  public getSOChannel(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?active=true";
    }

    return this.http
      .get(environment.base_url + "/sales-order-channels" + active1)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public createSOChannel(body: any) {
    return this.http
      .post(environment.base_url + "/sales-order-channels", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateSOChannel(body: any) {
    return this.http
      .put(environment.base_url + "/sales-order-channels", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getSOChannelById(id: any) {
    return this.http
      .get(environment.base_url + "/sales-order-channels/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteSOChannel(id: any) {
    return this.http
      .delete(environment.base_url + "/sales-order-channels/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted SO channel");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /******************************************** get Sales order Type ******************************************/
  public getSOType(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?active=true";
    }

    return this.http
      .get(environment.base_url + "/sales-order-types" + active1)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public createSOType(body: any) {
    return this.http
      .post(environment.base_url + "/sales-order-types", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateSOType(body: any) {
    return this.http
      .put(environment.base_url + "/sales-order-types", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getSOTypeById(id: any) {
    return this.http
      .get(environment.base_url + "/sales-order-types/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteSOType(id: any) {
    return this.http
      .delete(environment.base_url + "/sales-order-types/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted SO Type");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
}
