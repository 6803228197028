import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { AppEvents } from "src/app/_models/global.data.model";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { PartOrderService } from "src/app/_services/part-order.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-view-part-order",
  templateUrl: "./view-part-order.component.html",
  styleUrls: ["./view-part-order.component.scss"],
})
export class ViewPartOrderComponent implements OnInit {
  partOrder: any;
  id: any;
  dateFormat!: string;
  timezone: any;
  partOrderLineItems: any;

  selectedConfig: any;
  modalState: any;
  configCustomLg: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg",
  };
  config2: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-md",
  };
  selectedComponent: any;

  public items: any[] = [];

  constructor(
    private route: ActivatedRoute,
    public util: UtilServiceService,
    private router: Router,
    private partOrderService: PartOrderService,
    private auth: AuthenticationService,
    private deleteRecordService: DeleteRecordService,
    private breadCrumbs: BreadcrumbService
  ) {
    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  private preInit() {
    this.items = [
      {
        id:"btn_JobPartOrdDelete",
        label: "Delete",
        command: (e: any) => {
          this.deletePartOrder();
        },
      },
    ];
  }

  /******************************************** init ******************************************/
  private init() {
    this.dateFormat = localStorage.getItem("date_format")!;
    this.timezone = this.auth.getUserTimezone();
    this.route.queryParams.subscribe((params) => {
      this.id = params["id"];
    });
    this.loadData();
  }

  /******************************************** load part ******************************************/
  private loadData() {
    this.partOrderService.getViewPartOrder(this.id).subscribe((res: any) => {
      this.partOrder = res;

      this.breadCrumbs.setItems([
        { label: "Part Order", meta: {navigation: {root: true}} },
        { label: this.partOrder.partOrderNumber, meta: {navigation: {include: true}} },
      ]);
    });
  }

  /******************************************** edit part order ******************************************/
  public editPartOrder() {
    this.util.sendData({
      action: AppEvents.PASS_JO_DATA_TO_PART_ORDER_CREATE,
      data: {
        partOrder: this.partOrder,
        mode: "edit",
      },
    });

    this.router.navigate(["dashboard/partOrder/updatePartOrder"]);
  }

  /******************************************** delete part order ******************************************/
  private deletePartOrder() {
    const obj = {
      
      deleteType: "Job Order Product",
      id: this.id,
      message: `Are you sure you want to delete ${this.partOrder.id} of ${this.partOrder.jobOrder.jobOrderNumber}?`,
    };

    this.deleteRecordService.getItems(obj).subscribe((data) => {
      // refresh the table
      this.util.goBack();
    });
  }
}
