<div class="d-flex vh-100 position-fixed overflow-hidden justify-content-between">
    <div class="left-setup-task vh-100 overflow-scroll" style="z-index: 200;">
        <app-task-frame></app-task-frame>
    </div>
    <div class="flex-fill px-0 right-setup-task" id="right-setup">
        <div class="custella-setup-stickey-breadcrumb" *ngIf="setupItems.length !=0">
            <p-breadcrumb [model]="setupItems"></p-breadcrumb>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>