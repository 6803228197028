import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileType",
})
export class FileTypePipe implements PipeTransform {
  transform(value: string, option = "fileOnly", seperator: string): string {
    let final = "";
    let chopped: any[] = [];

    if (option === "fileOnly") {
      chopped = value.split(seperator);
      chopped.splice(chopped.length - 1, 1);

      final = chopped.toString();
    }

    if (option === "typeOnly") {
      chopped = value.split(seperator);

      final = chopped[chopped.length - 1].toString();
    }

    return final;
  }
}
