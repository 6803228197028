import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PartOrderService {
  private ENDPOINT_PART_ORDER_GET_ALL = "/part-orders/get-all?jobOrderId=";
  private ENDPOINT_PART_ORDER_BY_ID = "/part-orders/get-by/";
  private ENDPOINT_PART_ORDER_BY_LINE_ITEM =
    "/job-order-products/by-part-order/";

  constructor(private http: HttpClient) {}

  public getAllPartOrder(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_PART_ORDER_GET_ALL + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getPartOrderLineItems(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_PART_ORDER_BY_LINE_ITEM + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public createPartOrder(body: any) {
    return this.http
      .post(environment.base_url + "/part-orders/save", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public editPartOrder(body: any) {
    return this.http
      .put(environment.base_url + "/part-orders/update", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getViewPartOrder(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_PART_ORDER_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /******************************************** delete part order by id ******************************************/
  public deletePartOrder(id: any) {
    return this.http
      .delete(environment.base_url + "/part-orders/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
}
