import { Component, Input, OnInit } from "@angular/core";

import * as JsBarcode from "jsbarcode";

@Component({
  selector: "app-barcode-generator",
  templateUrl: "./barcode-generator.component.html",
  styleUrls: ["./barcode-generator.component.scss"],
})
export class BarcodeGeneratorComponent implements OnInit {
  elementType = "canvas";
  @Input() value = "Testing1234";
  @Input() barCodeType = "CODE128";
  //bcValue = 'Testing1234'

  codeList: string[] = [
    "",
    "CODE128",
    "CODE128A",
    "CODE128B",
    "CODE128C",
    "UPC",
    "EAN8",
    "EAN5",
    "EAN2",
    "CODE39",
    "ITF14",
    "MSI",
    "MSI10",
    "MSI11",
    "MSI1010",
    "MSI1110",
    "pharmacode",
    "codabar",
  ];

  constructor() {}

  ngOnInit() {
    this.init();
  }

  /******************************************** bar code ******************************************/
  private init() {
    JsBarcode("#barcode", this.value, {
      format: this.barCodeType,
    });
  }

  get values(): string[] {
    return this.value.split("\n");
  }
}
