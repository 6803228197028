<!-- TabMenu -->
<div class="position-relative fw-normal col-12 d-flex h-100-28">
    <div class="table-space ag-grid-table-full h-100" [ngClass]="{ ' ag-grid-table': isFilterToggleOn }">
        <ag-grid-angular
            #agGrid
            style="height: 100%;"
            class="ag-theme-balham"
            [suppressDragLeaveHidesColumns]="true"
            [cacheOverflowSize]="2"
            [maxConcurrentDatasourceRequests]="-1"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="view($event)"
            (gridReady)="onGridReady($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [getContextMenuItems]="getContextMenuItems"
        >
        </ag-grid-angular>
        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_AssetMainTableRec">
                {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
    </div>

    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': isFilterToggleOn }">
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="label_ScheMainFilterBy">{{translate?.general.messages.filterBy  }}</span>
            <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left"  id="btn_ScheMainClose" (click)="filterToggle()"></i>
        </div>
        <div class="custella-content">
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ScheMainDateRange">{{ translate?.preventiveMaintenance.fields.maintenanceDateRange.singular }}</span>
                <span class="p-input-icon-right w-100">
                    <p-calendar class="custella-calendar" id="picklist_ScheMainDateRangeField"  placeholder="dd/mm/yyyy - dd/mm/yyyy" selectionMode="range" dateFormat="dd/mm/yy" yearRange="2000:2030" (ngModelChange)="onDateChange($event)" [(ngModel)]="dateRange" [yearNavigator]="true" [showButtonBar]="false"appendTo="body"></p-calendar>
                    <i class="fa-regular fa-calendar"></i>
                </span>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ScheMainTypes" *ngIf="!addonFeatureDYNAMIC">{{ translate?.preventiveMaintenance.fields.types.singular }} </span>
                <span class="custella-content-input-title" id="label_ScheMainJobTypes" *ngIf="addonFeatureDYNAMIC">{{translate?.jobOrder.label.singular}}&nbsp;{{ translate?.preventiveMaintenance.fields.types.singular }} </span>

                <p-multiSelect name="types" [options]="allTypes" id="picklist_ScheMainTypeField" [placeholder]="translate?.general.messages.none" optionLabel="label" optionValue="value" [ngModelOptions]="{ standalone: true }" [(ngModel)]="filter.types" (onChange)="refresh()">
                   <!-- <ng-template let-value pTemplate="selectedItems">
                        <ng-container *ngIf="filter.types">
                            <div *ngFor="let type of filter.types">
                                <div class="text-12">{{ returnLabel("types", type)  }}</div>
                            </div>
                        </ng-container>

                        <div *ngIf="!filter.types || filter.types.length === 0">
                            {{ "select_type"  }}
                        </div>
                    </ng-template> -->

                    <!-- <ng-template let-item pTemplate="item">
                        {{ item.label  }}
                    </ng-template> -->
                </p-multiSelect>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ScheMainStatus">{{ translate?.maintenance.fields.status.singular }}</span>
                <p-multiSelect
                    name="status"
                    [options]="allStatus"
                    [placeholder]="translate?.general.messages.none"
                    optionLabel="label"
                    optionValue="value"
                    id="picklist_ScheMainStatusField"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="filter.statuses"
                    (onChange)="refresh()"
                >
                    <!-- <ng-template let-value pTemplate="selectedItems">
                        <div *ngFor="let status of filter.statuses">
                            <div class="text-12">{{ returnLabel("status", status)  }}</div>
                        </div>
                        <div *ngIf="!filter.statuses || filter.statuses.length === 0">
                            {{ "select_status"  }}
                        </div>
                    </ng-template>

                    <ng-template let-item pTemplate="item">
                        {{ item.label  }}
                    </ng-template> -->
                </p-multiSelect>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ScheMainSerZone">{{ translate?.setup.serviceZone.label.singular }}</span>
                <p-dropdown name="types" [filter]="true" id="picklist_ScheMainSerZoneField" [showClear]="filter.serviceZoneId ? true : false" [options]="serviceZones" [placeholder]="translate?.general.messages.none" optionLabel="name" optionValue="id" [(ngModel)]="filter.serviceZoneId" (onChange)="refresh()">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="filter.serviceZoneId" id="value_ScheMainSerZone">
                            {{returnLabel('serviceZone', filter.serviceZoneId)}}
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>

            <div>
                <span href="#" class="clear" id="subtitle_ScheMainClear" (click)="reset()">{{translate?.general.buttons.clearAll.singular }}</span>
            </div>
        </div>
    </div>
</div>
