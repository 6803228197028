import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

import { GetContextMenuItemsParams, GridOptions,IServerSideGetRowsParams, MenuItemDef } from 'ag-grid-community';
import "ag-grid-enterprise"; 
import { Subscription } from 'rxjs';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-custella-transfer-from-to-bin',
  templateUrl: './custella-transfer-from-to-bin.component.html',
  styleUrls: ['./custella-transfer-from-to-bin.component.scss']
})
export class CustellaTransferFromToBinComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  @Output() locationBin = new EventEmitter();
  @Output() closeView = new EventEmitter();
  @Input() transferType: any;
  @Input() binType: any;
  @Input() transferId: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any =[];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams : any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: Partial<GridOptions>;
   overlayNoRowsTemplate: string;
   
  binTransferData: any;
   actionData:any;
   totalRecord: any;
   currentRecordNumber: any;
   refresher!: Subscription;
   noCustomers: boolean = false;
   noCustomersIcon: boolean = false;
   dateFormat: any;
  searchValue: any='';
  translate: any;

  constructor(
    private inventoryService: InventoryService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();

    
    
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'infinite',
      sortingOrder : ["desc", "asc"],
      tooltipShowDelay: 100,
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
        }
      }
     
    this.defaultColDef = {
      enableRowGroup: false,//ag-Grid-Enterprise
      enablePivot: false,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
    //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";

      
  }

  ngOnInit() {
    this.getLanguageData()
   
    console.log(this.transferId)
  }
  currencyFormatter(params: any) {
    var usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    });
    return usdFormate.format(params.value);
  }
  
/********************************** Search Data **************************/
  quickSearch() {
     this.onGridReady(this.gridParams);
               
   }
   /********************************** View Singal Record **************************/
   onGridReady(params:any) {
    //console.log(params)
    if(params != undefined){
    this.gridParams = params;

   this.gridApi = params.api;
   this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      }
     }

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }
}

  /********************************** Call Customer API to Display data **************************/
   getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
   // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
       var selectedFromBinId=''
       var transferType = 'user'
       if(this.transferType == 'LOCATION_BIN'){
         transferType = 'location'
       }
       console.log(this.binType,"==========",this.transferId)
       if(this.binType == 'ToBin' && this.transferId){
         selectedFromBinId = this.transferId.transferFromBin.id
       }
       
    this.push(  
      this.inventoryService.getAllFromBin(this.searchValue, serverRequest, transferType, this.binType ,selectedFromBinId) .subscribe((data: any) => {
        console.log(data.body)
      this.binTransferData = data.body.data;
      this.totalRecord = data.body.total
      this.setGridData(agGridGetRowsParams, this.binTransferData, data.body.total, data.body.status);
      //this.spinner.hide()
      },
      (err) => {
        //console.log(err,"===>>>")
        this.errorUtil.setErrorMessage(err); 
      
       })
     )
  }
 
  /********************************** Set Data for Grid Table **************************/
  private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number,status:string) {
    console.log(resultItems,"===")
   
    if(status === 'NO_DATA'){
      this.noCustomers = false;
      this.noCustomersIcon = true
      this.gridApi.showNoRowsOverlay();
    }else{
      
      this.noCustomers = true;
      this.noCustomersIcon = false

      if(this.binTransferData.length === 0){
        this.gridApi.showNoRowsOverlay();
       }else{
        agGridGetRowsParams.successCallback(this.binTransferData, totalCount);
        this.gridApi.sizeColumnsToFit();
       }

    }
   
  }
/********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
   
     if(agGridRequest.sortModel.length <= 0){
          this.parameters = {colId : "binDisplayName",sort : "desc"};
          agGridRequest.sortModel.push(this.parameters);
        }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel
    }
  }
  /********************************** View Singal Record **************************/

  selectLocationBinData(e:any) {

     this.selectLocationBin(e.data)
   }
   selectLocationBin(val: any){
    this.locationBin.emit(val);
  }

  closeBin(){
    this.closeView.emit(false)
  }



  ngOnDestroy() {
    super.ngOnDestroy();
  }
  push(obs:any) {
    super.push(obs);
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        if(this.transferType == 'LOCATION_BIN'){
          this.columnDefs = [
            { headerName: this.translate?.setup.modules.inventory.bins.fields.displayName.singular, field: 'binDisplayName', width: 150, headerTooltip: "#"},
            { headerName: this.translate?.setup.modules.inventory.bins.fields.shortCode.singular, field: 'shortCode', width: 100,headerClass: ' hide-action-border', headerTooltip: "#" }
            ];
        }else{
          this.columnDefs = [
            { headerName: this.translate?.inventory.fields.userName.singular, field: 'binDisplayName', width: 150, headerTooltip: "#"},
            { headerName:  this.translate?.setup.modules.inventory.bins.fields.shortCode.singular,field: 'shortCode', width: 100,headerClass: ' hide-action-border', headerTooltip: "#" }
            ];
        }
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }

}
