
    <div class="d-flex button-box-top-right">
        <ng-container>
            <p-button [label]="translate?.general.buttons.edit.singular" styleClass=" ms-2 h-28" [disabled]="!update" id="btn_AssetPmEdit" (onClick)="edit()"></p-button>

            <button type="button" class="btn-view-icon ms-2" (click)="menu.toggle($event)" [pTooltip]="translate?.general.buttons.more.singular" tooltipPosition="bottom" *ngIf="remove" id="btn_AssetPmDrop">
                <i class="fa-regular fa-ellipsis-vertical"></i>
            </button>
            <p-slideMenu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu [model]="items" [popup]="true" class="options2" id="btn_AssetPmDropDel"></p-slideMenu>
        </ng-container>
    </div>

    <!-- <p-tabView class="px-0" (onChange)="handleChange($event, tabView)" [(activeIndex)]="activeIndex">
        <p-tabPanel id="title_ScheMainDet" [header]="'details' ">
            <app-scheduled-maintenance-details [scheduledMaintenance]="scheduledMaintenance" *ngIf="toggleSection.details"></app-scheduled-maintenance-details>
        </p-tabPanel>
    </p-tabView> -->
    <app-scheduled-maintenance-details [scheduledMaintenance]="scheduledMaintenance"></app-scheduled-maintenance-details>

