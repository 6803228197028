<div class="left-right-space">
      <!-- sla
        <div class="sla-first-response-container d-flex mb-2" *ngIf="ticketDetails?.violatingSoonSla?.slaStatus">
            <div class="sla-box d-flex ms-auto">
                <div class="left-side bg-header">
                    <img
                        class="response-image"
                        src="assets/images/tickets/first-response/{{
        ticketDetails?.violatingSoonSla?.slaStatus.toUpperCase()
    }}.png"
                        alt="First Response Status"
                        [title]="ticketDetails?.violatingSoonSla?.slaStatus"
                    />

                    <p class="" translate>
                        {{ ticketDetails?.violatingSoonSla?.slaStatus | titlecase }}
                    </p>
                </div>

                <div class="right-box">
                    <div class="top">
                        <span class="first-response-header">{{ "first_response"  }}</span>
                    </div>

                    <div class="bottom d-flex">
                        <div class="at-risk-box d-flex flex-column">
                            <span class="fw-bolder">{{ "at_risk"  }}</span>

                            <span class="at-risk-data">{{ atRiskDateTime }}</span>
                        </div>

                        <div class="risk-date-box ms-3">
                            <div class="row">
                                <div class="col-6 fw-bolder">{{ "start_time"  }}</div>
                                <div class="col-6">
                                    {{ ticketDetails?.violatingSoonSla?.startDateTime | momentDate: "hh:mm a":timezone }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6 fw-bolder">{{ "end_time"  }}</div>
                                <div class="col-6">
                                    {{ violationSoonSlaEndTime | momentDate: "hh:mm a":timezone }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    -->
    <div class="flex-between mb-2">
        <div></div>
        <button pButton *ngIf="create" [label]="translate?.general.buttons.addComment.singular" icon="fa-regular fa-comment" class="ms-2 text-nowrap h-32" id="btn_TixAddComment" (click)="openModal('addComment', ticketDetails)"></button>
    </div>

    <!-- conversation -->
    <div *ngIf="noConversation !== null && noConversation === false">
        <div class="converse">
            <div class="custella-form-container position-relative d-block w-75 me-3 ms-0 rounded-2">
                <div class="custella-form-container-title flex-between rounded-top">
                    <div class="font-medium font-white text-14" id="title_TixConversation">
                        {{ translate?.ticket.sections.sec8.singular }}
                    </div>
                </div>
                <div class="conversation-message-box-content">
                    <div class="px-4 border-bottom">
                        <div class="custella-content-row mb-2 pe-0">
                            <div class="custella-content-row-input col-12">
                                <div class="flex-between">
                                    <div class="d-flex align-items-center">
                                        <span class="custella-content-row-input-title-two me-2 mb-0" id="label_TixConvAssignTo"><span>{{translate?.ticket.fields.ticketOwner.singular}}</span>:</span>
                                        <span class="custella-content-row-input-title-three ms-2" id="value_TixConvAssignToName">{{ conversationDetails?.fromName }}</span>
                                    </div>
                                    <button type="button" *ngIf="createJO" class="custella-btn-white text-12 ms-2" id="btn_TixConvEscalate" (click)="openModal('addJo-Escalate', ticketDetails)">
                                        <img alt="logo" src="assets\svg\table\escalate.svg" style="width: 1rem;" id="img_TixConvEscalate"/>
                                        {{translate?.general.buttons.escalate.singular}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custella-form-container-content px-4" style="min-height: 4rem;">
                        <div class="custella-content-row mb-3 pe-0">
                            <div class="custella-content-row-input col-12">
                                <div class="d-flex justify-content-between">
                                    <span class="custella-content-row-input-title-two" id="label_TixConvSubj">{{ translate?.ticket.fields.subject.singular }}</span>
                                    <span class="custella-content-row-input-title-three" id="value_TixConvSubjDateTime">{{ conversationDetails?.createdDate | momentDate: conversationDateFormat:timezone }}</span>
                                </div>
                                <span class="text-16 font-medium" id="title_TixConvSubj">{{ conversationDetails?.ticket.subject }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="custella-form-container-content px-4" style="min-height: 4rem;">
                        <div class="custella-content-row mb-3 pe-0">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title color-var-500" id="label_TixConvDesc">{{ translate?.ticket.fields.description.singular }}</span>
                                <div class="custella-content-row-input-title conv-desc-box" id="value_TixMessage"></div>
                            </div>
                        </div>
                    </div>
                    <div class="custella-form-container-content px-4" style="min-height: 4rem;">
                        <div class="custella-content-row mb-3 pe-0">
                            <div class="custella-content-row-input col-12">
                                <div class="custella-content-row-input-title-two border-bottom padding-top-10px padding-bottom-10px" id="label_TixConvAttachments">
                                    {{ translate?.attachments.label.plural }}
                                </div>
                                <div class="conv-attach-body"> 
                                    <div class="flex-between border-bottom padding-top-10px padding-bottom-10px" *ngFor="let attachment of conversationDetails?.attachmentList">
                                        <div>
                                            <i class="pi pi-file"></i>
                                            <span class="text-12 font-light padding-left-10px padding-right-10px" id="value_TixConvAttachmentsFileName">{{ attachment.fileName }}</span>
                                        </div>
                                        <div>
                                            <button type="button" pButton *ngIf="create" [label]="translate?.general.buttons.download.singular" id="btn_TixConvAttachmentsDownload" icon="fa-regular fa-download" class="p-button-outlined p-mr-2 p-mb-2" (click)="downloadFile(attachment)"></button>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Footer -->
                <div class="custella-form-container-footer">
                    <div class="d-flex align-items-center justify-content-end">
                        <p-button [label]="translate?.general.buttons.addComment.singular" *ngIf="create" icon="fa-regular fa-comment" styleClass="ms-2 text-nowrap h-32" id="btn_TixAddComment2" (onClick)="openModal('addComment', ticketDetails)"></p-button>
                    </div>
                </div>
            </div>

            <!-- Interactions  -->
            <div class="position-relative d-block w-25">
                <div class="rounded-2 bg-header" style="box-shadow: 0px 4px 15px -4px rgb(117 115 115 / 80%);">
                    <ul class="custella-notification p-0 interaction-conversation-box">
                        <li class="flex-between border-bottom border-secondary px-1" style="height: 45px;">
                            <div class="d-flex align-items-center me-3">
                                <img class="mx-1 w-28px" src="assets\svg\header\comments.svg" id="img_TixInteractions" />
                                <span class="fs-6 ps-1 font-white" style="padding-top: 2px;" id="label_TixInteractions">{{ translate?.ticket.sections.sec9.plural }}</span>
                            </div>
                        </li>
                        <div class="interactions-body">
                            <ng-container *ngIf="conversationData.length !== 0">
                                <li class="border-bottom border-secondary px-1 convelist" *ngFor="let conversation of conversationData" [attr.data-conversation-id]="conversation.id" (click)="setConversationDetail(conversation)">
                                    <span class="flex-between mx-1 mb-2 p-2 convoSelected">
                                        <div class="text-left mx-2 elips">
                                            <span class="text-12"><strong class="font-violet" id="value_TixInteractionsName">{{ conversation?.fromName }}</strong> <span class="font-white ms-2" id="value_TixInteractionsMsg"> {{ conversation?.message }}</span></span>
                                            <span class="d-block color-var-500 text-10" id="value_TixInteractionsDateTime">{{ conversation?.createdDate | momentDate: conversationDateFormat:timezone }}</span>
                                        </div>
                                    </span>
                                </li>
                            </ng-container>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="noConversation" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound"/>
            <p class="text-6 my-1 font-weight-bold" >
                {{translate?.general.messages.noRecordsFound}}
            </p>
        </div>
    </div>
</div>
