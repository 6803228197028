
import {tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor ( public auth: AuthenticationService, private router: Router ) { }

    intercept ( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        var req = request.clone( {
            setHeaders: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ this.auth.getToken() }`
            }
        } );

        return next.handle( req ).pipe(tap(( event: HttpEvent<any> ) => {
            if ( event instanceof HttpResponse ) {
                // do stuff with response if you want
            }
        }, ( err: any ) => {
            if ( err instanceof HttpErrorResponse ) {
                // Handling CORS issue or server downtime
              //  console.log(err);
                if (err.status === 0 && err.error instanceof ProgressEvent) {
                    console.error('Server is down or CORS issue detected!');
                    // Store the current URL before redirecting to the maintenance page
                    const currentUrl = this.router.url;
                    localStorage.setItem('previousUrl', currentUrl);

                    // Redirect to the maintenance page
                    //this.router.navigateByUrl("/maintenance");
                }

                // Handle unauthorized (401) error
                if ( err.status === 401) {
                    // Clear localStorage and redirect to login page
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                }
            }
        } ));
    }
}