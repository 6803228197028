import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class PermissionService {

  public authorities: any = [];
  features: any = {
    'customers': 'customers',
    'sales order':'sales order',
    'job order':'job order',
    'products':'products',
    'teams': 'teams',
    'tasks': 'tasks',
    'customer purchase history' : 'customer purchase history',
    'planning hub': 'planning hub',
    'delivery orders': 'delivery orders',
    'shifts': 'shifts',
    'drivers': 'drivers',
    'packages': 'packages',
    'vehicles': 'vehicles',
    'assets' : 'assets',
    'asset contracts' : 'asset contracts',
    'scheduled maintenance' : 'scheduled maintenance',
    'inventory' : 'inventory',
    'tickets' : 'tickets',

    'allow assign task' : 'allow assign task',
    
    'manage org detail' : 'manage org detail',
    'manage users' : 'manage users',
    'manage hierarchy' : 'manage hierarchy',
    'manage access' : 'manage access',
    'fleet setup': 'fleet setup',
    'manage tickets': 'manage tickets',
    'manage inventory': 'manage inventory',
    'manage import & export': 'manage import & export',
    'manage geo fence': 'manage geo fence',
    'manage notification':'manage notification',

    'manage teams' : 'manage teams',
    'manage billing' : 'manage billing',
    'manage licenses' : 'manage licenses',
    'myProfile': 'myProfile',
    'setup': 'setup',
    'messages': 'messages',
    'reports': 'reports',
    'home': 'home',
    'schedule': 'schedule',
    'iqc-fqc':'iqc-fqc',
    'HelpDesk User' : 'HelpDesk User',
    'site' : 'site',
    'project' : 'project',
    "manage module": "manage module",

    "attendance":"attendance",
    "productivity":"productivity",
    "overtime":"Overtime",
    "pmv":"pmv",
    "visits":"vsits",
    "machine specialist":"machine specialist",

    'manage workflow': 'manage workflow',
    'manage customer surveys': 'manage customer surveys',
    'manage integrations': 'manage integrations',
  }

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  public capable(module: string, action: string): boolean {

    var can = false;
    if (this.authService.isAdmin()) {

      can = this.authService.can(module, action) && this._capable(this.features[module], action);
    }
    else {
      can = this.authService.can(module, action);
    }

    //can=this.authService.can(module,action);
    return can;

  }

  private _capable(module: string, action: string) {
    var can = false;

    var role = this.authorities.filter((per:any) => {
      ////console.log( per[ac]);
      return per.feature.name == module;
    });




    if (role[0] != undefined && role[0] != null) {
      if (role[0][action] != undefined && role[0][action] != null) {
        return role[0][action];
      }
      else {
        return true;
      }
    }

    return true;

  }


}
