<!-- Welcome to Custella, display after user login -->
<div class="custella-modal">
    <div class="d-flex">
        <div class="w-50 p-3">
            <img src="assets\images\Revamp Update-imageleft.jpg" alt="Custella" class="w-100">
        </div>
        <div class="w-50 pb-5 pt-1 ps-4">
            <div class="pe-5" style="transform: translate(10px, 28px);">
                <h3 class="font-semiBold mb-3">Welcome to Custella 4.0</h3>
                <span>
                    <p class="text-14">We&#8217;ve been tirelessly working behind the scenes to make Custella even better.</p>
                    <p class="mb-2">Here's what we've done&#58;</p>
                    <p class="ms-2 text-13">
                        &#8226; Given Custella a slick, brighter new look.<br>
                        &#8226; Streamlined our user interface for a smoother experience.<br>
                        &#8226; Restructured and enhanced the Setup module.<br>
                        &#8226; Added 2 new modules: Sites & Projects.<br>
                        &#8226; Added new and enhanced dashboards.<br>
                        &#8226; Enhanced the Preventative Maintenance module.<br>
                        &#8226; Optimized our code to help you get stuff done faster.<br>
                    </p>
                    <p> But don&#8217;t worry. Everything is exactly where you left it. Enjoy.</p>
                    <p> Got a burning question&#63; Email us at <a class="color-primary cursor-pointer">help@support.fieldex.com</a></p>
                </span>
                <div class="d-flex justify-content-end ms-auto mt-3">
                    <p-button label="Got it!" icon="pi pi-arrow-right" iconPos="right" styleClass="ms-2 h-32" (onClick)="modalRef.hide()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div> 
<!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
<!-- Update alert in Old Custella for existing users -->
<!-- <div class="custella-modal">
    <div class="d-flex">
        <div class="w-50 p-3">
            <img src="assets\images\Revamp Update-imageleft.jpg" alt="Custella" class="w-100">
        </div>
        <div class="w-50 py-4 ps-4">
            <div class="pe-5" style="transform: translate(10px, 28px);">
                <h3 class="font-semiBold mb-3">Custella 4.0 will be live on 15th April 2023</h3>
                <span>
                    <p class="text-14">Our team has been working hard to bring you new features and improvements that will enhance your experience with our platform.</p>
                    <p>Here are just a few of the updates that you can look forward to&#58;</p>
                    <p class="ms-2">
                        &#8226; Given Custella a slick, brighter new look <br>
                        &#8226; Our user interface has been streamlined to give you a smoother experience <br>
                        &#8226; We&#8217;ve restructured and enhanced the Setup module to allow for further flexibility in configuration. <br>
                        &#8226; We&#8217;ve added 2 new modules: Sites & Projects<br>
                        &#8226; We have new and enhanced dashboards to give you a broader view of your team&#8217;s statistics and activities<br>
                        &#8226; Our Preventative Maintenance module has been enhanced to be highly customizable <br>
                        &#8226; Our code has been optimized to help you get stuff done faster.<br>
                    </p>
                    <p> And there&#8217;s a lot more to come.</p>
                    <p>Rest assured, the existing features have been maintained and all your data is safe and secure. If you have any questions or concerns, please don't hesitate to reach out to our support team at <a class="color-primary cursor-pointer">help@support.custella.com</a></p>
                </span>
                <div class="d-flex justify-content-end ms-auto mt-3">
                    <p-button label="Got it!" icon="pi pi-arrow-right" iconPos="right" styleClass="ms-2 h-32"></p-button>
                </div>
            </div>
        </div>
    </div>
</div> -->

