import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ImportExportService } from 'src/app/_services/import-export.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { ModuleField } from '../import-data/import-data.component';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
var translateData: any;
@Component({
  selector: 'app-import-data-list',
  templateUrl: './import-data-list.component.html',
  styleUrls: ['./import-data-list.component.scss']
})
export class ImportDataListComponent 
extends SubscriptionUtil implements OnInit, OnDestroy {
  display: boolean = true;
  buttonLabel: string = 'Import';

  validFile: boolean = false;
  keysByModules: any;
  selectedKey: any;
  isUpdate: any;

  data: any;

  // uploadCSVForm: FormGroup;
  moduleList: any = [];
  selectedModule: any = null;
  type: any = '';
  moduleFieldList: any = [];
  selectedModuleFieldList: any = Array();
  body = new Array();
  files: FileList | any;
  selectedFileName: string = "";
  remainingDdList: any = [];
  allList: any = [];


  fileHeaderValues: ModuleField[] | any[] = [];
  selectedFileHeaderValues: any = [];
  confirmImport: EventEmitter<any> | any;

  selectedComponent: any;
  configMd: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: 'modal-md'
  };
  selectedConfig: any;
  modalState: any;
  confirmModalRef: BsModalRef | any;
  public modalRefRes: BsModalRef | any;
  _data: any;
  salePer: boolean = false;
  servicePer: boolean = false;
  fleetPer: boolean = false;

  appendData: any = {};
  translate: any;

  constructor(public modalRef: BsModalRef, private util: UtilServiceService, private modal: ModalServiceService,
    private dataExportService: ImportExportService,
    private spinner: NgxSpinnerService,
    private auth: AuthenticationService,
    private cd: ChangeDetectorRef,
    private errorUtil: ErrorUtil,
    public options: ModalOptions,
    private languageTranslateService: LanguageTranslateService
  ) {
    super()
    this.appendData = options.initialState?.data;
    this.appendData = this.appendData ? this.appendData : {};
    if (this.appendData.validFile) {
      this.selectedFileHeaderValues = this.appendData.selectedFileHeaderValues;
      this.fileHeaderValues = this.appendData.fileHeaderValues;
      this.selectedKey = this.appendData.selectedKey;
      this.selectedModule = this.appendData.selectedModule;
      this.keysByModules = this.appendData.keysByModules;
      this.type = this.appendData.type;
      this.moduleList = this.appendData.moduleList;
      this.allList = this.appendData.allList;
      this.remainingDdList = this.appendData.remainingDdList;
      this.moduleFieldList = this.appendData.moduleFieldList ?? [];
      this.isUpdate = this.appendData.isUpdate ?? false;
      this.files = this.appendData.files;
    }
  }

  ngOnInit(): void {
    this.getLanguageData();
    // let a: any = localStorage.getItem('addonFeatureList');
    // var addonFeatureLists = JSON.parse(a);
  }

  onModuleFieldChanged(event: any, csvHeaderItem: { custellaHeader: any; csvHeaderIndex: string | any; moduleFieldName: any; dataType: any; }, index: number) {
    let selectedItem: any = {};
    this.allList[index].forEach((it: any) => { if (it.name === event?.value) selectedItem = it; });
    //previous value index
    var moduleFieldListRowIndex = _.findIndex(this.moduleFieldList, (moduleFieldListRow: any) => {
      return moduleFieldListRow.name == csvHeaderItem.custellaHeader;
    });

    if (selectedItem.field) {
      this.selectedFileHeaderValues[csvHeaderItem.csvHeaderIndex] = selectedItem;
      //current value index
      var currentIndex = _.findIndex(this.moduleFieldList, (moduleFieldListRow: any) => {
        return moduleFieldListRow.field == selectedItem.field;
      });
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex]["lookupData"] = this.moduleFieldList[currentIndex].lookups ?? [];
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex]["lookUpChk"] = false;
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex]["deleteClick"] = false;
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex]["custellaHeader"] = selectedItem.name;
      csvHeaderItem.moduleFieldName = selectedItem.field;
      csvHeaderItem.dataType = selectedItem.type;
    } else {
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex]["custellaHeader"] = '';
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex]["lookupData"] = [];
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex]["lookUpChk"] = false;
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex].moduleFieldName = '';
      this.fileHeaderValues[csvHeaderItem.csvHeaderIndex].dataType = '';
    }
    this.removedList(selectedItem, csvHeaderItem, moduleFieldListRowIndex, index);
  }

  delete(itm: ModuleField, i: string | any) {
    this.fileHeaderValues[i]["deleteClick"] = true;
    let fieldIndex = this.moduleFieldList.findIndex((f: any) => f.field == itm.moduleFieldName);
    this.allList.forEach((it: any, j: number) => { if (i != j) it[it.length] = this.moduleFieldList[fieldIndex]; });
    this.selectedFileHeaderValues.splice(itm?.csvHeaderIndex, 1);
    //todo set remaining

  }

  removedList(selectedItem: any, itm: any, moduleFieldListRowIndex: number, index: number) {

    this.allList.forEach((it: any, j: number) => {
      if (itm.csvHeaderIndex != j)
        it.forEach((itInner: any, k: number) => { if (itInner.field === itm.moduleFieldName) it.splice(k, 1) });
    });

    // for (let i = 0; i <= this.allList.length - 1; i++) {
    //   if (itm.csvHeaderIndex != i) {
    //     let index = _.findIndex(this.allList[i], (HeaderValue: any) => {
    //       if (HeaderValue) {
    //         return itm.custellaHeader == HeaderValue.name;
    //       }
    //     });
    //     if (index > -1) {
    //       this.allList[i].splice(index, 1);
    //     }
    //     if (moduleFieldListRowIndex > -1) {
    //       this.allList[i].push(this.moduleFieldList[moduleFieldListRowIndex]);
    //     }
    //   }
    // }
  }

  fieldsMapped() {
    for (var i = 0; i <= this.fileHeaderValues.length - 1; i++) {
      if (!this.fileHeaderValues[i].moduleFieldName) {
        this.errorUtil.setErrorMessage('Error', null, this.translate?.setup.importExport.messages.mapFieldsBefore, 'error', 3000);
        return;
      }
    }
    this.uploadFileStep2();
  }

  uploadFileStep2() {
    // this.confirmImport = this.dataExportService.confirmImportEmit$.subscribe((res: any) => {
    if (this.files != null) {
      this.performUpload();
    }
    // });
  }

  performUpload() {
    if (this.files != null && this.files.length > 0) {
      this.spinner.show();
      const uploadfile: File = this.files[0];
      const formData = new FormData();
      formData.append('file', uploadfile);
      formData.append('json', JSON.stringify(this.fileHeaderValues));
      formData.append('moduleName', this.selectedModule.key);
      formData.append('isUpdate', this.isUpdate);
      formData.append('updateByColumnKey', this.selectedKey);
      this.dataExportService.uploadCSVFile(formData).subscribe((res: any) => {
        if (res.status == 200) {
          this.dataExportService.markResultSuccess();
          this.errorUtil.setErrorMessage(200, this.translate?.setup.importExport.messages.importedSuccesfully, null, 'success', 3000);
          this.modalRef.hide();
          // this.modal.openModal('import', null);
        } else {
          let blob = new Blob([res._body], { type: 'text/json; charset=utf-8' });
          FileSaver.saveAs(blob, `${this.selectedModule.key}_${moment().format('DD-MM-YYYY')}-errors.csv`);
          this.reset();
          this.errorUtil.setErrorMessage(400, null, this.translate?.setup.importExport.messages.unableToImport,'error', 2000);
        }
        this.spinner.hide();
      }, (error: any) => {
        if (error?._body?.includes('Errors')) {
          let blob = new Blob([error?._body], { type: 'text/json; charset=utf-8' });
          FileSaver.saveAs(blob, `${this.selectedModule.key}_${moment().format('DD-MM-YYYY')}-errors.csv`);
          this.reset();
          this.errorUtil.setErrorMessage(400, null,this.translate?.setup.importExport.messages.unableToImport,'error', 2000);
        } else { this.errorUtil.setErrorMessage(error.status, null, 'Error', 'error', 3000); }
        this.spinner.hide();
      });
    } else {
      this.errorUtil.setErrorMessage(400, null, this.translate?.setup.importExport.messages.selectValidFile, 'error', 3000);
    }
  }

  reset() {
    this.files = null;
    $("#file-input").val('');
    this.selectedFileName = '';
    this.fileHeaderValues = [];
    this.selectedFileHeaderValues = [];
  }

  lookupChkChange(i: any, itm: { lookUpChk: any; lookup: null; errorIfMultiple: boolean; allowNull: boolean; }) {
    if (!itm.lookUpChk) {
      itm.lookup = null;
      itm.errorIfMultiple = false;
      itm.allowNull = false;
    }
  }

  goBack() {
    this.modalRefRes = this.modal.openModal('importUpload', this.appendData);
    this.modalRef.hide();
  }


  getLanguageData() {
    this.spinner.show()
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
          //translateData = data;
         // this.loadTable = 1;
          //this.getData()
          //this.onGridReady(this.gridParams)
          //this.overlayNoRowsTemplate = "<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
         // console.log("translate",translateData,"=======",this.columnDefs)
          this.spinner.hide()
        }
      }))
    //console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }
  
  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
