import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmailTemplateService } from 'src/app/_services/email-template.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-add-image-email',
  templateUrl: './add-image-email.component.html',
  styleUrls: ['./add-image-email.component.scss']
})
export class AddImageEmailComponent implements OnInit {
  data: any;

  @ViewChild("fileUpload", { static: false })
  fileUpload!: any;
  translate: any
  constructor(public modalRef : BsModalRef,
    private spinner: NgxSpinnerService,
    private emailTemplateService: EmailTemplateService,
    private errorUtil: ErrorUtil
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.translate = this.data.translate;
  }
  public uploadFile($event: any) {
    
    this.spinner.show();
    //console.log($event.files);
  
    this.parse($event.files);
  }
  
  parse(files: FileList): void {
    // const file: File = files.item(0) as File;
    const file: File = files[0] as File;
  
    //console.log("FILES", file);
  
    const formData = new FormData();
    formData.append("file", file);
  
    
    formData.forEach((data) => {
    });
  
    this.emailTemplateService.uploadImageForEmailTemplate(this.data.uploadType, formData).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.modalRef.hide()
      },
      (error) => {
        this.spinner.hide();
        this.clearUpload();
        this.errorUtil.setErrorMessage(error.status, null, error.detail);
      }
    );
  }
  private clearUpload() {
    this.fileUpload.clear();
  }
}
