import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { PartOrderService } from "src/app/_services/part-order.service";

import * as moment from "moment-timezone";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-parts-table",
  templateUrl: "./parts-table.component.html",
  styleUrls: ["./parts-table.component.scss"],
})
export class PartsTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Input() id: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultCol: any;
  gridParams: any;
  public gridOptions!: Partial<GridOptions>;
  public noParts = false;
  public frameworkComponents: any;
  public overlayNoRowsTemplate =
    '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

  totalRecord: any;

  partOrdersAllRows: any = [];

  constructor(
    private partOrderService: PartOrderService,
    private perm: PermissionService,
    private deleteRecordService: DeleteRecordService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** init ******************************************/
  private init() {
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "serverSide",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.columnDefs = [
      {
        headerName: "Item ID",
        field: "product.productNumber",
        width: 100,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold"];
        },
      },
      {
        headerName: "Name",
        field: "product.name",
        width: 100,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return [""];
        },
      },
      {
        headerName: "RMA Number",
        field: "rmaNumber",
        width: 100,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return [""];
        },
      },

      {
        headerName: "Import Expiry Date",
        field: "expireDataTime",
        width: 100,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return [""];
        },
        cellRenderer: (params: any) => {
          if (params.data) {
            return moment(params.data.createdDate).format(
              "hh:mm A" +
                ", " +
                localStorage.getItem("date_format")?.toUpperCase()
            );
          }
        },
      },

      {
        headerName: "Status",
        field: "jobOrderProductStatus.name",
        width: 70,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return [""];
        },
      },
      {
        headerName: "Action",
        filter: false,
        sortable: false,
        width: 70,
        headerTooltip: "#",
        headerClass: "marging-auto hide-action-border",
        cellClass: "text-center",
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.edit.bind(this),
          permissions: {
            update: this.perm.capable("job order", "update"),
            remove: this.perm.capable("job order", "remove"),
          },
          hidden: {
            update: !this.perm.capable("job order", "update"),
            remove: !this.perm.capable("job order", "remove"),
          },
        },
      },
    ];
  }

  /******************************************** on grid ready ******************************************/
  public onGridReady(params: any) {
    this.gridParams = params;

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();

    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setServerSideDatasource(dataSource);
  }

  /******************************************** get data ******************************************/
  public getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    this.gridApi.hideOverlay();

    this.push(
      this.partOrderService
        .getPartOrderLineItems(this.id)
        .subscribe((res: any) => {
          this.partOrdersAllRows = res;

          this.setGridData(
            agGridGetRowsParams,
            this.partOrdersAllRows,
            this.partOrdersAllRows.length
          );
        })
    );
  }

  /******************************************** set data on table ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number
  ) {
    if (resultItems.length === 0) {
      this.noParts = true;
      this.gridApi.showNoRowsOverlay();

      agGridGetRowsParams.successCallback([], 0);
    } else {
      this.noParts = false;
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }

    this.totalRecord = totalCount;
  }

  /******************************************** edit/delete ******************************************/
  public edit(e: any) {
    console.log(e);

    if (e.action === "edit") {
    }

    if (e.action === "delete") {
      const rowData = e.rowData;

      const data = {
        deleteType: "Job Order Product",
        message: `Are you sure you want to delete ${rowData.product.productNumber} - ${rowData.product.name}?`,
        id: rowData.id,
      };

      this.push(
      this.deleteRecordService.getItems(data).subscribe((data) => {
        // refresh the table

        this.onGridReady(this.gridParams);
      }));
    }
  }
  push(obs:any) {
    super.push(obs);
  }
}
