<div>
  <div class="flex-between">
    <i class="fa-regular fa-xmark w-24px dialog-close-icon" (click)="closeTicketTable()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
  </div>
  <div class="position-relative text-start">
    <span class="mb-0 mt-2 text-14 font-light color-var-500">{{translate?.general.commonComponent.sections.sec15.singular}}</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input
          type="text"
          pInputText autocomplete="off"[placeholder]="translate?.general.buttons.search.singular"
          [(ngModel)]="searchValue"
        />
      </span>
    </div>
    <div class="table-space" style="height: 40vh;">
        <app-ticket-table [searchValue]="searchValue" [translate]="translate"></app-ticket-table>
    </div>
  </div>
</div>
