import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { MenuService } from 'src/app/_services/menu.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';

@Component({
  selector: 'app-setup-frame',
  templateUrl: './setup-frame.component.html'
})
export class SetupFrameComponent implements OnInit, AfterViewInit {

  toggleSetup: boolean = false;
  initialData: any[] = [];
  translate: any;

  constructor(
    private menuItemService: MenuService,
    public router: Router,
    private breadcrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef,
    private util: UtilServiceService,
    private languageTranslateService: LanguageTranslateService
  ) {}

  ngOnInit(): void {
    this.menuItemService.getMenuNodeWithData2().subscribe((data: any) => {
      this.initialData = data;
      console.log("onInit", data);

      // Check and navigate after the initial data is loaded
      this.checkAndNavigate();
    });

    this.languageTranslateService.menuSrc$.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.translate = data;
      }
    });

    this.clearBreadcrumb();
  }

  /****************************************** Check and Navigate *****************************/
  private checkAndNavigate(): void {
    const currentUrl = this.router.url;
    
    // Ensure the DOM is fully loaded and available
    setTimeout(() => {
      this.traverseNodes(this.initialData, this.initialData, currentUrl);
      this.cdr.detectChanges(); // Detect changes after the DOM manipulation
    }, 5000);
  }

  private traverseNodes(parentNode: any, nodes: any[], currentUrl: string): void {
    for (let node of nodes) {
      if (node.routerLink && currentUrl.includes(node.routerLink)) {
        const element = document.querySelector('.' + parentNode?.value + '-main');
        
        if (element) {
          this.toggle(element, parentNode?.value);  // Pass the actual element reference
          
        }
        break;
      }
      if (node.data && node.data.length > 0) {
        this.traverseNodes(node, node.data, currentUrl);
      }
    }
  }

  collapseMenu(): void {
    this.toggleSetup = !this.toggleSetup;
  }

  isExpandable(node: any): boolean {
    return node.data.length !== 0;
  }

  openurl(node: any): void {
    if (node.routerLink) {
      this.router.navigate([node.routerLink]);
    }
  }

  changeData(name: any, element: any, nodeData: any): void {
   
      console.log(element, "-----We are in " + name, "=====", nodeData);
      $('.' + name).attr('aria-expanded', 'true');
      $('.accordion-button').removeClass('collapsed');
      const contains = element.classList.contains("pi-angle-right");
      const collapsSubMenu = contains;
      const data = { name: name, collapsSubMenu: collapsSubMenu };
      this.menuItemService.onMenuStateChange(data);
    
  }

  clearBreadcrumb(): void {
    this.breadcrumbService.setItems([]);
    this.breadcrumbService.setSetupItems([]);
  }

  /******************************************** Toggle ******************************************/
  public toggle(element: any, nodeData: any): void {
    //console.log(element, "=====", nodeData);
    const contains = element.classList.contains("pi-angle-right");
    console.log(contains);
  

    if (contains) {
      $('.' + nodeData + '-main').removeClass('pi-angle-right');
      $('.' + nodeData + '-main').addClass('pi-angle-down');
    } else {
      $('.' + nodeData + '-main').removeClass('pi-angle-down');
      $('.' + nodeData + '-main').addClass('pi-angle-right');

      const contains2 = element.classList.contains("pi-angle-right");
      
      $('.' + nodeData).attr('aria-expanded', 'true');
      $('.accordion-button').removeClass('collapsed');
      const collapsSubMenu = contains2;
      const data = { name: nodeData, collapsSubMenu: collapsSubMenu };
      this.menuItemService.onMenuStateChange(data);

    }
  }

  ngAfterViewInit(): void {
    // No need to call checkAndNavigate here as it's now handled after initialData is loaded
    this.cdr.detectChanges();
  }
}
