import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetContextMenuItemsParams, GridOptions, IServerSideGetRowsParams, MenuItemDef } from 'ag-grid-community';
import "ag-grid-enterprise"; 
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { ErrorUtil } from 'src/app/_utilities/error';
var router: Router;
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-task-journey',
  templateUrl: './task-journey.component.html'
})
export class TaskJourneyComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  taskId: any
  userId: any
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams : any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  nopaymentsDataIcon!: boolean;
  productsFamilies: any;
  productsType: any;
  searchValue: any
  currentRecordNumber: any;
  totalRecord: any;
  data: any
  title: any

  create: any;
  paymentsData: any;
  persons: any[]=[];
  noJourney!: boolean;
  noJourneyIcon: boolean= false;
  refresher!: Subscription;
  timezone: any;
  dateTimeFormat: any;
  userIds: any[]=[];
  taskJourneys: any;
  tasks: any;
  loadList: any = 0
  mode: any
  jId: any;
  searchValueData: boolean =false;
  translate: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner : NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private taskService : TasksService,
    private auth : AuthenticationService,
    private breadcrumbService: BreadcrumbService,
    private perm: PermissionService,
    public modalRef : BsModalRef,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData();

    this.refresher = this.taskService.refreshList$.subscribe((item:any) => {
      console.log(item)
     // if(item == 'Deleted Journey'){
         this.getJourneyData()
     // }
    });
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent
    }
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'clientSide',
      paginationPageSize: 25,
      sortingOrder : ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      }
    this.columnDefs = [
      { headerName: this.translate?.task.fields.dateTime.singular,  field: 'userLocationRouteTable.startDateTime',sort: 'desc' ,width: 180,cellClass: function (params: any) { return ['table_default_color'];},
      headerTooltip: "#",
          cellRenderer: function (params: any) {
            if(params.data){
              var date_format:any =localStorage.getItem('date_format')
              return  moment.tz(params.data.userLocationRouteTable.startDateTime,auth.getUserTimezone()).format('hh:mm A, '+date_format.toUpperCase())
            }
          }
        },
        { headerName:  this.translate?.task.fields.name.singular, field: 'userLocationRouteTable.userFullName',width: 180,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
              var img='';
              if(params.data.userLocationRouteTable.imageUrl !== null){
                img='<img class="prof-img-task mx-2 my-0 ml-4" src="'+params.data.userLocationRouteTable.imageUrl+'">';
              }else if(params.data.userLocationRouteTable.imageUrl === null){
              img='<img  class="prof-img-task mx-2 my-0 ml-4" src="../../../../assets/svg/Avatar.svg">';
              }
              return '<div class="text-1-5 d-flex align-items-center"><p class="mb-0 mt-1 normal-font" style="color:#000">'+params.data.userLocationRouteTable.userFullName+'</p></div> ';
             
              }
        },
        
      { headerName:  this.translate?.task.fields.from.singular,field: "userLocationRouteTable.startLocationAddress",width: 180, headerTooltip: "#",},
      { headerName: this.translate?.task.fields.to.singular,field: "userLocationRouteTable.endLocationAddress",width: 180, headerTooltip: "#",},
      { headerName: this.translate?.task.fields.distanceTravelled.singular,field: "userLocationRouteTable.totalDistanceTravelled",width: 100,
      headerTooltip: "#",
      cellRenderer: function (params: any) {
        if(params.data.userLocationRouteTable.totalDistanceTravelled == 0){
         return '0.0 KM'
        }else{
         return params.data.userLocationRouteTable.totalDistanceTravelled+' KM'
        }
      
        }},
      { headerName:  this.translate?.task.fields.duration.singular,width: 100,field: 'userLocationRouteTable.duration', headerTooltip: "#", },
      { headerName:  this.translate?.task.fields.createdVia.singular, field: 'userLocationRouteTable.systemGenerated',width: 100,
      headerTooltip: "#",
      cellRenderer: function (params: any) {
            var img='';
            if(params.data.userLocationRouteTable.systemGenerated != null && params.data.userLocationRouteTable.systemGenerated == false){
              return 'Web Generation'
            }else{
              return 'Mobile GPS'
            }
           
            }
      },
    { headerName: this.translate?.general.buttons.action.singular, sortable: false,filter: false, width: 150,headerClass: 'marging-auto hide-action-border', cellStyle: { "text-align": "center" }, cellRenderer: 'buttonRenderer',
    headerTooltip: "#",
    cellRendererParams: {
      onClick: this.editContact.bind(this),
      permissions:{update: this.perm.capable("tasks", "update"),remove: this.perm.capable("tasks", "remove")},
      hidden:{update: !this.perm.capable("tasks", "update"),remove: !this.perm.capable("tasks", "remove")}
    }
    },
    ]
    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
    //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";  
    }

  ngOnInit(): void {
    router = this.router;
    this.create = this.perm.capable("tasks", "create");

    this.dateTimeFormat = `hh:mm a, ${localStorage.getItem('date_format')}`;
    this.timezone = this.auth.getUserTimezone();
   
    this.taskId = this.data.id;
       this.userId = this.data.assignedTo.id
       this.getJourneyData()
    // this.push(
    // this.route.queryParams.subscribe(params => {
    //   this.taskId = params['taskId'];
    //   this.userId = params['userId'];
    //   this.getJourneyData()
    // }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

 /****************************************** Load BreadCrumb *****************************/
 loadBreadCrum() {
  this.breadcrumbService.setSetupItems([
    { label: this.translate?.task.label.plural, routerLink: ["/dashboard/tasks"], meta: {navigation: {root: true}} },
    { label: this.tasks.taskName,command: e => {
      this.router.navigate(['/dashboard/tasks/view'], {queryParams: {'id' : this.tasks.id}});
    } 
   },
    { label:this.translate?.journey.label.plural,  meta: {navigation: {include: true}}}
  ]);
}
  getJourneyData(){
    this.taskJourneys = [];
    this.tasks = this.data
       
      this.userIds=[]
      this.tasks.assignedUsersList.forEach((element :any)=> {
            this.userIds.push(element.assignedTo.id)
          });
          console.log(this.userIds)
     
          this.push(
          this.taskService.getJourneyByTask(this.taskId, this.userId,this.userIds).subscribe((res:any) => {
            this.taskJourneys = res.userLocationRouteVMList.reverse();
            this.spinner.hide();
            this.taskJourneys.forEach((element :any)=>{
              element.moduleName = "Journey"
            })
            this.totalRecord = this.taskJourneys.length
            console.log(this.taskJourneys)
            
            if(this.taskJourneys.length === 0){
              this.noJourney = false;
              this.noJourneyIcon = true;
            }else{
              this.noJourney = true;
              this.noJourneyIcon = false;
            }

          }))
     
    
  }

 /********************************** Click Cell Event *****************************/
 editContact(e:any) {
  if (e.action === 'edit') {
    this.mode = 'edit'
    this.loadList= 1
    this.jId= e.rowData.userLocationRouteTable.id
   // this.router.navigate(['dashboard/tasks/journeys/update'], {queryParams: {'taskId' : this.taskId, 'userId' : this.userId,'id': e.rowData.userLocationRouteTable.id,'name':this.tasks.taskName}})
   
   }
  else if (e.action === 'delete') {
    e.rowData.deleteHeader = this.translate.task.label.singular + " " + this.translate.journey.label.singular
    e.rowData.deleteType='Task Journey'
    e.rowData.message=this.translate?.general.messages.confirmDelete 
    console.log(e.rowData)

    this.push(
    this.deleteRecordService.getItems(e.rowData)
    )    

  }
 }
 
  /********************************** Search Data **************************/
  public quickSearch() {
    if (this.searchValue.length > 0) {
      this.searchValueData = true
      this.gridApi.setQuickFilter(this.searchValue);
      this.totalRecord = this.gridApi.getDisplayedRowCount();
      if (this.totalRecord == 0) {
        this.gridParams.api.showNoRowsOverlay();
      } else {
        this.gridParams.api.hideOverlay();
      }
    }else{
     
      this.gridApi.setQuickFilter(this.searchValue);
      this.totalRecord = this.gridApi.getDisplayedRowCount();
      this.gridParams.api.hideOverlay();
    }
  }

  public onSearch(){
    
    if(this.searchValue.length == 0){
      this.searchValueData = false
      this.searchValue = ''
      this.quickSearch()
    } 
  }
  public onClear(){
    
    this.searchValueData = false
    this.searchValue = ''
    this.quickSearch()
   
  }
getFilter(){
  this.getJourneyData()
  this.onGridReady(this.gridParams);
 }

 /********************************** Initilize Grid Table **************************/
 onGridReady(params:any) {
  //console.log(params)
  this.gridParams = params;

 this.gridApi = params.api;

}

/********************************** Request Parameter for api **************************/


  viewJourney(e: any)
  {
    console.log(e)
    if (e.colDef.field === "userLocationRouteTable.startDateTime"){
      this.loadList= 2
      this.jId= e.data.userLocationRouteTable.id
      // this.router.navigate(['dashboard/tasks/journeys/view'], {queryParams: {'taskId' : this.taskId, 'userId' : this.userId,id:e.data.userLocationRouteTable.id}})
    }
  }
  addJourneys(){
   
    this.router.navigate(['dashboard/tasks/journeys/create'], {queryParams: {'taskId' : this.taskId, 'userId' : this.userId,'name':this.tasks.taskName}})
  }
 
openNewForm(){
  this.mode = 'add'
  this.loadList= 1
}

openViewForm(){
  this.loadList= 2
}
goBackTablePage(){
  this.loadList= 0
}
push(obs:any) {
  super.push(obs);
}

getLanguageData() {
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
  console.log("translate",this.translate)
  //this.mainLabel = this.translate?.accessories.label.plural
}

}
