import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFormatter'
})
export class DurationFormatterPipe implements PipeTransform {

  transform(value: number): string {
    console.log(value,"-----")
    if(value !== null){
    if(value < 60){
      var hours : string = '';
      var minutes: string = value.toString();
    } else if (value == 60) {
      var hours : string = Math.floor(value/ 60).toString();
      var minutes: string = '';
    } else {
      var hours : string = Math.floor(value/ 60).toString();
      var minutes: string = Math.floor((value % 60)/60 * 60).toString();
    }

    if(hours == '1' && minutes == ''){
      return hours + ' Hour ';
    }  else if(hours == '1' && minutes !== ''){
      return hours + ' Hour ' + minutes + ' Mins';
    } else if(hours !== '1' && hours !== ''){
      return hours + ' Hours ' + minutes + ' Mins';
    } else {
      return minutes + ' Mins';
    }
 
}else{
  return '-';
}
  }
}
