import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";
import { Headers, Http, RequestOptions } from "@angular/http";

@Injectable({
  providedIn: "root",
})
export class SiteService {
  private ENDPOINT_SITE = "/v4/sites";
  private ENDPOINT_SITE_POST = "/sites";
  private ENDPOINT_SITE_DELETE = "/sites";
  private ENDPOINT_SITE_V5 = "/v4-1/sites/";
  private ENDPOINT_SITE_TYPES = "/site-types";
  private ENDPOINT_SITE_AREAS = "/site-areas";
  private ENDPOINT_SITE_CATEGORIES = "/site-categories";
  private ENDPOINT_SITE_SUB_CATEGORIES = "/site-sub-categories";
  private ENDPOINT_SITE_PICKLIST = "/sites/pick-list";
  private UPDLOAD_PARENT_TYPE = "SITE_ATTACHMENT";
  private ENDPOINT_SITE_CONTACTS_BY_SITE = "/contacts/by-site";
  private ENDPOINT_SITE_CONTACTS = "/contacts";
  private ENDPOINT_GET_ATTACHMENTS_BY_SITE_ID = "/v4/attachments/site/"

  private selectSubject = new Subject();

  public select(val: any) {
    this.selectSubject.next(val);
  }

  public getSelectItem() {
    return this.selectSubject.asObservable();
  }

  constructor(
    private http: HttpClient,
    private httpOld: Http,
    private auth: AuthenticationService
  ) {}

  public getAllSites(
    serverParams: any,
    searchValue: string | null,
    customerId: any | null,
    projectId: any | null,
    typeId: any | null,
    serviceZoneId: any | null,
    categoryId: any | null,
    geofenceId: any | null,
    origin: any | null
  ) {
    let params = new HttpParams();

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    if (customerId && customerId !== "" && customerId != null) {
      params = params.set("customerId", String(customerId));
    }

    if (projectId && projectId !== "" && projectId != null) {
      params = params.set("projectId", String(projectId));
    }

    if (typeId && typeId !== "" && typeId != null) {
      params = params.set("typeId", String(typeId));
    }

    if (serviceZoneId && serviceZoneId !== "" && serviceZoneId != null) {
      params = params.set("serviceZoneId", String(serviceZoneId));
    }

    if (categoryId && categoryId !== "" && categoryId != null) {
      params = params.set("categoryId", String(categoryId));
    }

    if (geofenceId && geofenceId !== "" && geofenceId != null) {
      params = params.set("geofenceId", String(geofenceId));
    }

    if (origin && origin !== "" && origin != null) {
      params = params.set("origin", String(origin));
    }

    if (serverParams) {
      params = params
        .set("startFrom", serverParams.startRow)
        .set("perPage", "25")
        .set("columnName", (serverParams.sortModel[0] as any).colId)
        .set("sortOrder", (serverParams.sortModel[0] as any).sort);
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SITE_V5}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** TYPES ******************************************/
  public getAllSiteTypes(isActive: boolean | null, isOrder: boolean | null) {
    let params = new HttpParams();

    if (isActive !== null && (isActive === true || isActive === false)) {
      params = params.set("isActive", String(isActive));
    }

    if (isOrder !== null && (isOrder === true || isOrder === false)) {
      params = params.set("isOrder", String(isOrder));
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SITE_TYPES}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createSiteType(formData: any) {
    return this.http
      .post(`${environment.base_url}${this.ENDPOINT_SITE_TYPES}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateSiteType(formData: any) {
    return this.http
      .put(`${environment.base_url}${this.ENDPOINT_SITE_TYPES}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteSiteType(id: any) {
    return this.http
      .delete(
        `${environment.base_url}${this.ENDPOINT_SITE_TYPES}/${String(id)}`
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** AREAS ******************************************/
  public getAllSiteAreas(isActive: boolean | null, isOrder: boolean | null) {
    let params = new HttpParams();

    if (isActive !== null && (isActive === true || isActive === false)) {
      params = params.set("isActive", String(isActive));
    }

    if (isOrder !== null && (isOrder === true || isOrder === false)) {
      params = params.set("isOrder", String(isOrder));
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SITE_AREAS}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createSiteArea(formData: any) {
    return this.http
      .post(`${environment.base_url}${this.ENDPOINT_SITE_AREAS}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateSiteArea(formData: any) {
    return this.http
      .put(`${environment.base_url}${this.ENDPOINT_SITE_AREAS}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteSiteArea(id: any) {
    return this.http
      .delete(
        `${environment.base_url}${this.ENDPOINT_SITE_AREAS}/${String(id)}`
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** CATEGORIES ******************************************/
  public getAllSiteCategories(
    isActive: boolean | null,
    isOrder: boolean | null
  ) {
    let params = new HttpParams();

    if (isActive !== null && (isActive === true || isActive === false)) {
      params = params.set("isActive", String(isActive));
    }

    if (isOrder !== null && (isOrder === true || isOrder === false)) {
      params = params.set("isOrder", String(isOrder));
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SITE_CATEGORIES}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createSiteCategory(formData: any) {
    return this.http
      .post(`${environment.base_url}${this.ENDPOINT_SITE_CATEGORIES}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateSiteCategory(formData: any) {
    return this.http
      .put(`${environment.base_url}${this.ENDPOINT_SITE_CATEGORIES}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteSiteCategory(id: any) {
    return this.http
      .delete(
        `${environment.base_url}${this.ENDPOINT_SITE_CATEGORIES}/${String(id)}`
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** SUB CATEGORIES ******************************************/
  public getAllSiteSubCategories(
    isActive: boolean | null,
    isOrder: boolean | null,
    siteCategoryId: any | null
  ) {
    let params = new HttpParams();

    if (isActive !== null && (isActive === true || isActive === false)) {
      params = params.set("isActive", String(isActive));
    }

    if (isOrder !== null && (isOrder === true || isOrder === false)) {
      params = params.set("isOrder", String(isOrder));
    }

    if (siteCategoryId && siteCategoryId !== null) {
      params = params.set("siteCategoryId", String(siteCategoryId));
    }

    console.log(params);

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SITE_SUB_CATEGORIES}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createSiteSubCategory(formData: any) {
    return this.http
      .post(
        `${environment.base_url}${this.ENDPOINT_SITE_SUB_CATEGORIES}`,
        formData
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateSiteSubCategory(formData: any) {
    return this.http
      .put(
        `${environment.base_url}${this.ENDPOINT_SITE_SUB_CATEGORIES}`,
        formData
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteSiteSubCategory(id: any) {
    return this.http
      .delete(
        `${environment.base_url}${this.ENDPOINT_SITE_SUB_CATEGORIES}/${String(
          id
        )}`
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getListColumn(apiModule: any) {
    return this.http
      .get(
        environment.base_url +
          "/table-column-setups/by-module?apiModule=" +
          apiModule
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createSite(formData: any) {
    return this.http
      .post(`${environment.base_url}${this.ENDPOINT_SITE_POST}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteSite(id: any) {
    return this.http
      .delete(`${environment.base_url}${this.ENDPOINT_SITE_DELETE}/${String(id)}`)
      .pipe(
        map((resp: any) => {
          console.log(resp);
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSiteById(id: any) {
    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SITE}/${String(id)}`)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateSite(formData: any) {
    return this.http
      .put(`${environment.base_url}${this.ENDPOINT_SITE_POST}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSitePicklist(search: string | null) {
    let params = new HttpParams();

    if (search !== null && search !== "") {
      params = params.set("search", String(search));
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SITE_PICKLIST}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public uploadAttachment(id: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });

    return this.httpOld
      .post(
        environment.base_url +
          `/attachments/upload?parentType=${this.UPDLOAD_PARENT_TYPE}&parentId=` +
          id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getSiteContacts(id: any | null) {
    let params = new HttpParams();

    if (id !== null && id !== "") {
      params = params.set("siteId", String(id));
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SITE_CONTACTS_BY_SITE}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

    /************************************* Get Site Attachments  Info By ID *************************************************************************/
    public getAttachmentsBySiteId(id: any) {
      let params = new HttpParams();
      params = params.set("siteId", id);
      return this.http
        .get(environment.base_url + this.ENDPOINT_GET_ATTACHMENTS_BY_SITE_ID,
          {params : params})
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            ////console.log(error);
            return throwError(error);
          })
        );
    }

  public createSiteContact(formData: any) {
    return this.http
      .post(`${environment.base_url}${this.ENDPOINT_SITE_CONTACTS}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateSiteContact(formData: any) {
    return this.http
      .put(`${environment.base_url}${this.ENDPOINT_SITE_CONTACTS}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSiteTasks(siteId: any) {
    return this.http
      .get(`${environment.base_url}/sites/${siteId}/tasks`)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
