import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of as throwError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ServerRequest } from '../_models/global.data.model';

@Injectable({
  providedIn: 'root'
})
export class IqcFqcService {

  private ENDPOINT_TASK_TYPE_LIST = "/v1/task-list/paged";
  private ENDPOINT_TASK_TYPE_BY_ID = "/v1/task-list/";

  private ENDPOINT_DEPARTMENT_LIST = "/v1/department";
  private ENDPOINT_DEPARTMENT_BY_ID = "/v1/department/";

  private ENDPOINT_EQUIPMENT_LIST = "/v1/equipment-type/paged";
  private ENDPOINT_EQUIPMENT_BY_ID = "/v1/equipment-type/";

  private ENDPOINT_MAINTENANCE_LIST = "/v1/maintenance-task/paged";
  private ENDPOINT_MAINTENANCE_BY_ID = "/v1/maintenance-task/";

  public iqcList$: EventEmitter<any>;
  public refreshIqc$: EventEmitter<any>;
  public refresherTasks$: EventEmitter<any>;
  public refresherDepartment$: EventEmitter<any>;
  public refresherEquipment$: EventEmitter<any>;
  public refresherMaintenance$: EventEmitter<any>;
  public refresherIqcFqcQuestion$: EventEmitter<any>;
  public refresherFrequency$: EventEmitter<any>;


  constructor(private http: HttpClient,
    private auth: AuthenticationService,
    private router: Router) {
    this.iqcList$ = new EventEmitter();
    this.refreshIqc$ = new EventEmitter();
    this.refresherTasks$ = new EventEmitter();
    this.refresherDepartment$ = new EventEmitter();
    this.refresherEquipment$ = new EventEmitter();
    this.refresherMaintenance$ = new EventEmitter();
    this.refresherIqcFqcQuestion$ = new EventEmitter();
    this.refresherFrequency$ = new EventEmitter();
  }


public getSalesForceAccessToken(){
  let params = new HttpParams();

   return this.http.get( `${environment.node_url}/get-sales-force-access-token`).pipe(map((resp: any) => {
    return resp;
  }
  ), catchError(error => {
    return throwError(error);
  }),);
}

  //**================================== Iqc  =======================*/
  public getAllIqc(searchValue: any, parameters: ServerRequest) {
    let params = new HttpParams();

    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    console.log(parameters)
    // if (parameters)
    //   params = params
    //     .set('location', parameters.location)
    //     .set('fromDate', parameters.fromDate).set('toDate', parameters.toDate)
    //     .set('startFrom', parameters.startFrom).set('perPage', parameters.perPage)
    //     .set('columnName', parameters.columnName).set('sortOrder', parameters.sortOrder);
    // else new Error("No params");
    return this.http.get(`${environment.node_url}/v1/record-data/iqc/paged`, { params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public getIqcById(id: any) {
    return this.http
      .get(environment.node_url + '/v1/record-data/iqc/' + id)
      .pipe(map(
        (resp: any) => {
          return resp;
        },
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      )
      );
  }
  public getQuestionForm(equipmentType: any, location: any, qcType: any) {
    return this.http
      .get(environment.node_url + '/v1/question/form?equipmentType=' + equipmentType + '&location=' + location + '&qcType=' + qcType, { observe: "response" })
      .pipe(map(
        (resp: any) => {
          return resp.body;
        },
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      )
      );
  }

  public createIqc(body: any) {
    return this.http.post(environment.node_url + '/v1/record-data/iqc', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshIqc$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public updateIqc(body: any) {
    return this.http.put(environment.node_url + '/v1/record-data/iqc', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshIqc$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public deleteIqc(id: any) {
    return this.http.delete(environment.node_url + '/v1/record-data/iqc/' + id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshIqc$.emit('Deleted IQC');
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return throwError(error);
    }),);
  }

  public getEquipment() {
    return this.http.get(`${environment.node_url}/v1/equipment-type/pick-list?active=true`).pipe(map((resp: any) => {
      if (resp.status == 204) {
        return false;
      } else {
        return resp;
      }
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }
  public getMakeModel() {
    return this.http.get(`${environment.node_url}/v1/equipment-type/make-model/pick-list`).pipe(map((resp: any) => {
      if (resp.status == 204) {
        return false;
      } else {
        return resp;
      }
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  public getManufacturer() {
    return this.http.get(`${environment.node_url}/v1/equipment-type/manufacturer/pick-list`).pipe(map((resp: any) => {
      if (resp.status == 204) {
        return false;
      } else {
        return resp;
      }
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  public saveTaskForIqcFqc(body: any) {
    return this.http.post(`${environment.node_url}/v1/task-list/instant/create-task`, body, { observe: 'response' }).pipe(map((resp: any) => {
      if (resp.status == 204) {
        return false;
      } else {
        this.refreshIqc$.emit(body);
        return resp;
      }
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  /*================================================== IQC Qt ======================================*/

  public getAllIQC(equipmentTypeId: any) {
    return this.http.get(`${environment.node_url}/v1/qc-setup?type=IQC&equipmentTypeId=${equipmentTypeId}`, { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  //**================================== Fqc  =======================*/
  public getAllFqc(searchValue: any, parameters: ServerRequest) {
    let params = new HttpParams();

    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    console.log(parameters)
    // if (parameters)
    //   params = params
    //     .set('location', parameters.location)
    //     .set('fromDate', parameters.fromDate).set('toDate', parameters.toDate)
    //     .set('startFrom', parameters.startFrom).set('perPage', parameters.perPage)
    //     .set('columnName', parameters.columnName).set('sortOrder', parameters.sortOrder);
    // else new Error("No params");
    return this.http.get(`${environment.node_url}/v1/record-data/fqc/paged`, { params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public getFqcById(id: any) {
    return this.http
      .get(environment.node_url + '/v1/record-data/fqc/' + id)
      .pipe(map(
        (resp: any) => {
          return resp;
        },
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      )
      );
  }

  public updateFqc(body: any) {
    return this.http.put(environment.node_url + '/v1/record-data/fqc', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshIqc$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public deleteFqc(id: any) {
    return this.http.delete(environment.node_url + '/v1/record-data/fqc/' + id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshIqc$.emit('Deleted FQC');
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return throwError(error);
    }),);
  }

  public getAllPMList() {
    return this.http.get(environment.base_url + '/preventative-templates/pick-list', { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) {
          return false;
        } else {
          return resp.body;
        }
      }
      ), catchError(error => {
        ////console.log(error);
        return throwError(error);
      }));
  }

  /*================================================== FQC qt ======================================*/

  public getAllFQC(equipmentTypeId: any) {
    return this.http.get(`${environment.node_url}/v1/qc-setup?type=FQC&&equipmentTypeId=${equipmentTypeId}&active=`, { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  /*================================================== TaskType ======================================*/

  public getAllTaskType(searchValue: any, parameters: any) {
    let params = new HttpParams();

    if (searchValue !== '' && searchValue != null) params = params.set('search', searchValue);

    if (parameters)
      params = params.set('startFrom', parameters.startRow).set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get(environment.node_url + this.ENDPOINT_TASK_TYPE_LIST, { observe: 'response', params: params })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public getTaskTypeById(TaskTypeId: any) {
    return this.http.get(environment.node_url + this.ENDPOINT_TASK_TYPE_BY_ID + TaskTypeId, { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public createTaskType(body: any) {
    return this.http.post(environment.node_url + '/v1/task-list', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherTasks$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public updateTaskType(body: any) {
    return this.http.put(environment.node_url + '/v1/task-list', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherTasks$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }
  public deleteTaskType(id: any) {
    return this.http.delete(environment.node_url + this.ENDPOINT_TASK_TYPE_BY_ID + id, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherTasks$.emit('Deleted TaskType');
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  /*================================================== Setup ======================================*/

  public createIqcFqc(body: any) {
    return this.http.post(environment.node_url + '/v1/qc-setup', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refreshIqc$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public updateIqcFqc(body: any) {
    return this.http.put(environment.node_url + '/v1/qc-setup', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refreshIqc$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public getIqcFqcById(IqcFqId: any) {
    return this.http.get(environment.node_url + '/v1/qc-setup/' + IqcFqId, { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public deleteIqcFqct(id: any) {
    return this.http.delete(environment.node_url + '/v1/qc-setup/' + id, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refreshIqc$.emit('Deleted IQC FQC');
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  /*================================================== Department ======================================*/

  public getAllDepartment(searchValue: any, parameters: any) {
    let params = new HttpParams();

    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    if (parameters)
      params = params.set('startFrom', parameters.startRow).set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);

    return this.http.get(environment.node_url + this.ENDPOINT_DEPARTMENT_LIST, { observe: 'response', params: params })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public getDepartment() {
    return this.http.get(`${environment.node_url}/v1/department/pick-list`).pipe(map((resp: any) => {
      if (resp.status == 204) {
        return false;
      } else {
        return resp;
      }
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  public createDepartment(body: any) {
    return this.http.post(`${environment.node_url}/v1/department`, body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherDepartment$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  public getDepartmentById(DepartmentId: any) {
    return this.http.get(environment.node_url + this.ENDPOINT_DEPARTMENT_BY_ID + DepartmentId, { observe: 'response' }).pipe(map((resp: any) => {
      if (resp.status == 204) {
        return false;
      } else {
        return resp.body;
      }
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }
  public updateDepartment(body: any) {
    return this.http.put(environment.node_url + '/v1/department', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherDepartment$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }
  public deleteDepartment(id: any) {
    return this.http.delete(environment.node_url + this.ENDPOINT_DEPARTMENT_BY_ID + id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherDepartment$.emit('Deleted Department');
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  /*================================================== Equipment ======================================*/

  public getAllEquipment(searchValue: any, parameters: any) {
    let params = new HttpParams();
    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    if (parameters)
      params = params.set('startFrom', parameters.startRow).set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);

    return this.http.get(environment.node_url + this.ENDPOINT_EQUIPMENT_LIST, { observe: 'response', params: params })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public createEquipment(body: any) {
    return this.http.post(environment.node_url + '/v1/equipment-type', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherEquipment$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public getEquipmentById(EquipmentId: any) {
    return this.http.get(environment.node_url + this.ENDPOINT_EQUIPMENT_BY_ID + EquipmentId, { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }
  public updateEquipment(body: any) {
    return this.http.put(environment.node_url + '/v1/equipment-type', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherEquipment$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }
  public deleteEquipment(id: any) {
    return this.http.delete(environment.node_url + this.ENDPOINT_EQUIPMENT_BY_ID + id, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherEquipment$.emit('Deleted Equipment');
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }


  /*================================================== Maintenance ======================================*/

  public getAllMaintenance(searchValue: any, parameters: any) {
    let params = new HttpParams();
    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    if (parameters)
      params = params.set('startFrom', parameters.startRow).set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);

    return this.http.get(environment.node_url + this.ENDPOINT_MAINTENANCE_LIST, { observe: 'response', params: params })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public createMaintenance(body: any) {
    return this.http.post(environment.node_url + '/v1/maintenance-task', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherMaintenance$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public getMaintenanceById(maintenanceId: any) {
    return this.http.get(environment.node_url + this.ENDPOINT_MAINTENANCE_BY_ID + maintenanceId, { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }
  public updateMaintenance(body: any) {
    return this.http.put(environment.node_url + '/v1/maintenance-task', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherMaintenance$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }
  public deleteMaintenance(id: any) {
    return this.http.delete(environment.node_url + this.ENDPOINT_MAINTENANCE_BY_ID + id, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherMaintenance$.emit('Deleted Maintenance');
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public deleteMaintenanceTask(taskListId: any, maintenanceTaskId: any) {
    return this.http.post(environment.node_url + '/v1/maintenance-task/remove-task?maintenanceTaskId=' + maintenanceTaskId + '&taskListId=' + taskListId, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherMaintenance$.emit('Deleted Maintenance Task');
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  /*================================================== TaskTrigger ======================================*/

  public getAllTaskTrigger(equipmentType: any, manufacturer: any, makeModel: any, taskType: any, location: any, departmentId: any, skipTaskListIds: any) {
    let params = new HttpParams();

    if (equipmentType !== '' && equipmentType != null) {
      params = params.set('equipmentType', equipmentType);
    }
    if (manufacturer !== '' && manufacturer != null) {
      params = params.set('manufacturer', manufacturer);
    }
    if (makeModel !== '' && makeModel != null) {
      params = params.set('makeModel', makeModel);
    }
    if (taskType !== '' && taskType != null) {
      params = params.set('taskType', taskType);
    }
    if (location !== '' && location != null) {
      params = params.set('location', location);
    }
    if (departmentId !== '' && departmentId != null) {
      params = params.set('departmentId', departmentId);
    }

    if (skipTaskListIds !== '' && skipTaskListIds != null) {
      params = params.set('skipTaskListIds', skipTaskListIds);
    }
    return this.http.get(`${environment.node_url}/v1/task-list`, { observe: 'response', params: params }).pipe(map((resp: any) => {
      if (resp.status == 204) {
        return false;
      } else {
        return resp.body;
      }
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  public saveTaskToAnswer(body: any) {
    return this.http.post(environment.node_url + '/v1/task-answer/assign-task-to-answer', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherIqcFqcQuestion$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public getAllMaintenanceTask(searchValue: any, parameters: any, maintenanceTaskId: any) {
    let params = new HttpParams();
    if (searchValue !== '' && searchValue != null) {
      params = params.set('search', searchValue);
    }
    if (maintenanceTaskId !== '' && maintenanceTaskId != null) {
      params = params.set('maintenanceTaskId', maintenanceTaskId);
    }
    if (parameters)
      params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);

    return this.http.get(`${environment.node_url}/v1/task-list/maintenance-tasks/paged`, { observe: 'response', params: params })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public saveTaskToMaintenance(body: any) {
    return this.http.post(environment.node_url + '/v1/maintenance-task/add-task', body, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherMaintenance$.emit(body);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  /*================================================== IQC / FQC Question======================================*/

  public getAllIQCFQCQuestion(qcSetupId: any) {
    return this.http.get(`${environment.node_url}/v1/question?qcSetupId=${qcSetupId}`, { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public createIqcFqcQuestion(body: any) {
    return this.http.post(environment.node_url + '/v1/question', body, { observe: 'response' }).pipe(map((resp: any) => {
      // this.refresherIqcFqcQuestion$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  public updateIqcFqcQuestion(body: any) {
    return this.http.put(environment.node_url + '/v1/question', body, { observe: 'response' }).pipe(map((resp: any) => {
      console.log(resp)
      // this.refresherIqcFqcQuestion$.emit(body);
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }));
  }

  public getIqcFqcQuestionById(IqcFqId: any) {
    return this.http.get(environment.node_url + '/v1/question/' + IqcFqId, { observe: 'response' })
      .pipe(map((resp: any) => {
        if (resp.status == 204) return false; else return resp.body;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  public removeTaskFromQuestion(answerId: any, taskListId: any, questionId: any) {
    return this.http.put(environment.node_url + '/v1/task-answer/remove-task-from-answer?answerId=' + answerId + '&taskListId=' +
      taskListId + '&questionId=' + questionId, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherIqcFqcQuestion$.emit(resp);
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }


  public deleteIqcFqcQuestion(id: any) {
    return this.http.delete(environment.node_url + '/v1/question/' + id, { observe: 'response' })
      .pipe(map((resp: any) => {
        this.refresherIqcFqcQuestion$.emit('Deleted Question');
        return resp;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }
  /*================================================== Setup Maintenance ======================================*/

  public getAllSetupPM(){
    return this.http.get(environment.base_url+'/preventative-templates', {observe: 'response'} ).pipe(map((resp: any) => {
      if(resp.status == 204){
        return false;
      } else {
        return resp.body;
      }
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public createPMSetup(body:any){
    return this.http.post( environment.base_url+'/preventative-templates', body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public updatPMSetup(body:any){
    return this.http.put( environment.base_url+'/preventative-templates', body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public getPMSetup(id: any){
    return this.http.get( environment.base_url+'/preventative-templates/'+id, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public deletePMSetup(id: any){
    return this.http.delete( environment.base_url+'/preventative-templates/'+id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherMaintenance$.emit('Deleted Preventative Maintenance Setup');
      return resp;
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  
  /*================================================== Setup Maintenance ======================================*/

  public getAllSetupPFrequency(pmId: any){
    return this.http.get(environment.base_url+'/preventative-frequencies?preventativeTemplateId='+pmId, {observe: 'response'} ).pipe(map((resp: any) => {
      if(resp.status == 204){
        return false;
      } else {
        return resp.body;
      }
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public createPFrequencySetup(body:any){
    return this.http.post( environment.base_url+'/preventative-frequencies', body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
      
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public updatePFrequencySetup(body:any){
    return this.http.put( environment.base_url+'/preventative-frequencies', body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
      
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public getPFrequencySetup(id: any){
    return this.http.get( environment.base_url+'/preventative-frequencies/'+id, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
      
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public deletePFrequencySetup(id: any){
    return this.http.delete( environment.base_url+'/preventative-frequencies/'+id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherFrequency$.emit('Deleted Frequency setup');
      return resp;
      
    }),
      catchError(error => {
        return throwError(error);
      }));
}

  /*================================================== Setup Spare Part ======================================*/

  public getAllSetupSparePart(pId: any){
    return this.http.get(environment.base_url+'/preventative-frequency-parts?preventativeFrequencyId='+pId, {observe: 'response'} ).pipe(map((resp: any) => {
      if(resp.status == 204){
        return false;
      } else {
        return resp.body;
      }
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public createSparePartSetup(body:any){
    return this.http.post( environment.base_url+'/preventative-frequency-parts', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherFrequency$.emit(body);
      return resp;
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public updateSparePartSetup(body:any){
    return this.http.put( environment.base_url+'/preventative-frequency-parts', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherFrequency$.emit(body);
      return resp;
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public getSparePartSetup(id: any){
    return this.http.get( environment.base_url+'/preventative-frequency-parts/'+id, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }),
      catchError(error => {
        return throwError(error);
      }));
}
  public deleteSparePartSetup(id: any){
    return this.http.delete( environment.base_url+'/preventative-frequency-parts/'+id, { observe: 'response' }).pipe(map((resp: any) => {
      this.refresherFrequency$.emit('Deleted Spar Part');
      return resp;
    }),
      catchError(error => {
        return throwError(error);
      }));
}


}
