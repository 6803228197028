import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { VisitsService } from '../_services/visits.service';

@Component({
  selector: 'app-select-all',
  template: ` 
  <div  class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all" >
                <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-checkbox-label" ></div>
                <div ref="eWrapper" id="ms_AllCheck" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" >
                    <input   class="ag-input-field-input ag-checkbox-input cursor-pointer" type="checkbox" (change)="toggleSelect()" [ngModel]="isChecked">
                </div>
            </div>`,
})
export class SelectAllComponent implements IHeaderAngularComp {
  headerName: any;
  isChecked: boolean= false;
  isIndeterminate: boolean= false;
  showCheckBox: boolean = true;
  sortIcon: string = 'none';
  sortSubIcon: string = 'none';
  params: any;
//ag-indeterminate
  constructor(
    private visitService: VisitsService,
   ) {}
  agInit(params: IHeaderParams | any): void {
    console.log("params11",params)
    this.params = params;
    this.headerName = this.params?.displayName;

    this.params.api.addEventListener('selectionChanged', () => {
      this.setCheckboxState();
    });

    this.showCheckBox = this.params?.checkboxSelection;
    this.checkdata()
  
  }

  toggleSelect(): void {
   
    this.isChecked = this.isChecked ? false:true;
    
    console.log(this.isChecked,"=======")
    this.visitService.select(this.isChecked);
    if (this.isChecked) {
      //$('#checkboxCustella1').removeClass('indeterminate');
      $('#ms_AllCheck').addClass('ag-checked');

      this.params?.api?.forEachNode((node: any) => {
        node.selectThisNode(true);
      });

      const event: any = {
        type: 'selectionChanged',
        api: this.params.api,
        columnApi: this.params.columnApi
      };

      this.params?.api?.eventService?.dispatchEvent(event);
    }
    else {
      $('#ms_AllCheck').removeClass('ag-checked');
     // $('#checkboxCustella1').addClass('c-checkbox-icon');
      this.params.api.deselectAll();
    }
  }

  checkdata(){
    console.log(this.isChecked,"=======")
    if (this.isChecked) {
      this.params?.api?.forEachNode((node: any) => {
        node.selectThisNode(true);
      });

      const event: any = {
        type: 'selectionChanged',
        api: this.params.api,
        columnApi: this.params.columnApi
      };

      this.params?.api?.eventService?.dispatchEvent(event);
    }
    else {
      this.params.api.deselectAll();
    }

  }
  refresh(params: IHeaderParams): boolean {
    return false;
  }

  private setCheckboxState(): void {
    this.isIndeterminate = false;
    const selectedNodesLength: number = this.params?.api?.getSelectedNodes()?.length;
    const renderedNodesLength: number = this.params?.api?.getDisplayedRowCount();

    this.isChecked = selectedNodesLength > 0 && selectedNodesLength === renderedNodesLength;
    this.isIndeterminate = selectedNodesLength > 0 && !this.isChecked;
    console.log(this.isIndeterminate,"======")
  }

 

  
}
