<div class="left-right-space mb-4">
    <div class="flex-between mb-2">
        <div></div>
        <div class="d-flex align-items-center">
            <span class="text-10 font-medium px-3" id="label_TixUpload">(<span>{{translate?.attachments.fields.max25Mb.singular }}</span>)</span>
            <p-fileUpload #fileUpload mode="basic" *ngIf="createTicket" id="btn_TixAttachmentUpload"
                [chooseLabel]="translate?.general.buttons.upload.singular" [chooseIcon]="'pi pi-upload'"
                (uploadHandler)="uploadFile($event)" [customUpload]="true" [auto]="true" class="max-size" [maxFileSize]="25000000"
                [invalidFileSizeMessageSummary]="translate?.attachments.messages.invalidFileSize" 
                [invalidFileSizeMessageDetail]="translate?.attachments.messages.allowedFileSize"></p-fileUpload>
        </div>
    </div>

    <div *ngIf="attachmentsArray.length === 0" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" >
                {{translate?.general.messages.noRecordsFound}}
            </p>
        </div>
    </div>

    <div class="d-flex align-items-baseline" *ngIf="attachmentsArray.length !== 0">
        <!-- Attachments -->
        <div class="custella-form-container position-relative d-block w-75 ms-0 me-1 rounded-2">
            <!-- Attachments -->
            <div class="custella-form-container-title flex-between rounded-top">
                <div class="font-medium text-14" id="title_TixAttachments">{{ translate?.attachments.label.singular }}</div>
            </div>
            <!--  -->
            <div class="mt-2 px-4">
                <div class="custella-content-row-three">
                    <div class="custella-content-row-three-attachment col-12">
                        <div class="flex-between border-bottom" style="padding-bottom: 5px;">
                            <span class="text-12 font-medium color-var-500" id="label_TixFiles">{{ translate?.attachments.fields.file.singular }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Attachment Files -->
            <ul class="custella-attachment-files p-0">
                <li *ngFor="let attachment of attachmentsArray" class="cursor-pointer" (click)="onSelectedAttachment(attachment)">
                    <div class="px-4" [ngClass]="{ selected: seletedAttachment?.id == attachment.id }">
                        <div class="custella-content-row-three border-bottom py-2">
                            <div class="custella-content-row-three-input col-12">
                                <div class="content">
                                    <div class="flex-align-center">
                                        <img [src]="attachment.icon" style="width: 30px;" id="img_AttachmentIcon"/>
                                        <div>
                                            <span class="filename px-3" id="label_TixFileName">{{ attachment.fileName }}</span>
                                            <span class="text-10 font-medium color-var-500 d-block text-wrap mt-1 px-3" id="label_TixFilesDateTime">
                                                {{ attachment?.createdDate | momentDate: " hh:mm a":timezone }} {{ attachment?.createdDate | momentDate: dateFormat:timezone }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex-between">
                                        <button type="button" pButton icon="fa-regular fa-download" id="btn_TixAttachmentDownload" class="p-button-outlined attachment-delete" [pTooltip]="translate?.general.buttons.download.singular" tooltipPosition="top" (click)="downloadAttachment(attachment)"></button>
                                        <button *ngIf="deleteTicket" type="button" pButton  icon="fa-regular fa-trash" class="p-button-danger ms-2" [pTooltip]="translate?.general.buttons.delete.singular" tooltipPosition="top" id="btn_TixAttachmentDelete" (click)="deleteAttachment(attachment)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <!-- Preview -->
        <div class="custella-form-container position-relative d-block w-25 ms-1 me-0 rounded-2">
            <!-- Preview -->
            <div class="custella-form-container-title flex-between rounded-top">
                <div class="font-medium text-14" id="title_TixAttachmentPreview">{{ translate?.attachments.sections.sec0.singular }}</div>
            </div>
            <!-- Attached File -->
            <div class="my-2 px-4">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-12">
                        <img *ngIf="seletedAttachment?.contentContentType.indexOf('image') < 0" [src]="seletedAttachment?.file" class="w-92px my-2" id="img_TixAttachment1"/>
                        <img *ngIf="seletedAttachment?.contentContentType.indexOf('image') >= 0" [src]="seletedAttachment?.file" class="w-100 my-2" id="img_TixAttachment2"/>
                        <div class="text-left mt-1">
                            <span class="text-14 font-semiBold d-block elips" id="label_TixSelectedFileName">{{ seletedAttachment?.fileName }}</span>
                            <span class="text-10 font-medium color-var-500 d-block text-wrap my-1" id="label_TixSelectedFileDateTime">
                                {{ seletedAttachment?.createdDate | momentDate: " hh:mm a":timezone }} {{ seletedAttachment?.createdDate | momentDate: dateFormat:timezone }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-2 mb-3 px-4">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-12">
                        <div class="d-flex align-items-center flex-wrap">
                            <button pButton type="button" id="btn_TixAttachmentDelete2" *ngIf="deleteTicket" [label]="translate?.general.buttons.delete.singular" icon="fa-regular fa-trash" iconPos="left" class="p-button-danger me-2 h-32" (click)="deleteAttachment(seletedAttachment)"></button>
                            <button pButton type="button" id="btn_TixAttachmentDownload2" [label]="translate?.general.buttons.download.singular" icon="fa-regular fa-download" iconPos="left" class="p-button-secondary h-32" (click)="downloadAttachment(seletedAttachment)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
