<form class="custella-custom-dialog" [formGroup]="passwordForm" *ngIf="type == 'self'">
    <div class="modal-header p-dialog-header flex-between">
      <div class=" d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 font-medium ms-3">{{translate?.resetPassword.sections.sec1.singular}}</span>
      </div>
      <i class="fa-regular fa-xmark w-24px" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
    </div>
      <div class="modal-body">
      <div class="container">   
          <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" >{{translate?.resetPassword.fields.oldPassword.singular}}</span>
             <input pInputText autocomplete="off" formControlName="oldPassword" [(ngModel)]="password.oldPassword" name="oldPassword" type="password" >
                  <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['oldPassword'].touched && passwordForm.controls['oldPassword'].invalid">
                    <span *ngIf="passwordForm.controls['oldPassword'].errors?.required" class="text-10 p-error" >{{translate?.general.validationFE.requiredField}}</span>
                    <span *ngIf="passwordForm.controls['oldPassword'].errors?.pattern" class="text-10 p-error" >{{translate?.resetPassword.messages.minimumChars}}</span>
                  </div>  
          </div>                 
          <div class="custella-content-row-input col-12 mb-3">
              <span class="custella-content-row-input-title" >{{translate?.resetPassword.fields.newPassword.singular}}</span>
                <input pInputText autocomplete="off" formControlName="password" [(ngModel)]="password.password" name="password" type="password" >
                  <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['password'].touched && passwordForm.controls['password'].invalid">
                    <span *ngIf="passwordForm.controls['password'].errors?.required" class="text-10 p-error" >{{translate?.general.validationFE.requiredField}}</span>
                    <span *ngIf="passwordForm.controls['password'].errors?.pattern" class="text-10 p-error" >{{translate?.resetPassword.messages.minimumChars}}</span>
                  </div>   
          </div>
          <div class="custella-content-row-input col-12">
              <span class="custella-content-row-input-title" >{{'resetPassword.fields.confirmNewPassword.singular'}}</span>
              <input pInputText autocomplete="off" formControlName="cPassword" [(ngModel)]="password.cPassword" name="cPassword" type="password" >
                <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['cPassword'].touched && passwordForm.controls['cPassword'].invalid">
                  <span *ngIf="passwordForm.controls['cPassword'].errors?.required" class="text-10 p-error" >{{translate?.general.validationFE.requiredField}}</span>
                  <span *ngIf="passwordForm.controls['cPassword'].errors?.pattern" class="text-10 p-error" >{{translate?.resetPassword.messages.minimumChars}}</span>
                </div>    
          </div>
      </div>
    </div>
    <div class="modal-footer p-0">
        <button class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
        <p-button  (onClick)="changePassword()" [disabled]="submitTouched" [label]="translate?.general.buttons.reset.singular" styleClass="mx-1"></p-button>
    </div>
  </form>
  
  <form class="custella-custom-dialog"  *ngIf="type == 'others' || type == 'resendCustomerUserEmail'">
    <div class="modal-header p-dialog-header">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 font-medium ms-3">{{translate?.langData.resetPassword.sections.sec1.singular}}</span>
      </div>
      <i class="fa-regular fa-xmark w-24px" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
    </div>
    <div class="modal-body">
      <div class="container text-center my-4">       
        <p >{{translate?.resetPassword.messages.anEmailWillBeSentTo}}&nbsp;{{data1?.email}}&period;&nbsp;{{translate?.resetPassword.messages.doYouWishProceed}}&quest;</p>
      </div>
    </div>
    <div class="modal-footer p-0">
        <button [disabled]="submitTouched"  class="custella-btn-white text-12" (click)="modalRef.hide()">{{translate?.general.buttons.no.singular}}</button>
        <p-button  [disabled]="submitTouched" (onClick)="onSubmit('reset')" [disabled]="submitTouched" [label]="translate?.general.buttons.yes.singular" styleClass=""></p-button>
    </div>

  </form>
  
  
  <form class="custella-custom-dialog" *ngIf="type == 'resendEmail'">
    <div class="modal-header p-dialog-header">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 ffont-medium ms-3">{{translate?.general.buttons.resendEmail.singular}}</span>
      </div>
      <i class="fa-regular fa-xmark" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"></i>
    </div>
      <div class="modal-body">
        <div class="container text-center my-4">       
          <p >{{translate?.resetPassword.messages.anEmailWillBeSentTo}}&nbsp;{{data1?.email}}&period;&nbsp;{{translate?.resetPassword.messages.doYouWishProceed}}&quest;</p>
        </div>
      </div>
      
      <div class="modal-footer p-0">
        <button [disabled]="submitTouched"  class="custella-btn-white text-12" (click)="modalRef.hide()">{{translate?.general.buttons.no.singular}}</button>
        <p-button  [disabled]="submitTouched" (onClick)="onSubmit('email')" [disabled]="submitTouched" [label]="translate?.general.buttons.yes.singular" styleClass=""></p-button>
    </div>
    </form>


   
      