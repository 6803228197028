<div class="d-block p-1 pb-5 overflow-scroll">
  <div class="custella-setup d-block my-2 mx-0">
    <div *ngFor="let node of menus; let i = index" [hidden]="node.hide">
    <div class="custella-accordion-menu accordion accordion-flush" *ngIf="!isExpandable(node)">
        <div  class="accordion-item py-1 border-1 border-secondary border-top">
            <div routerLinkActive="active-menuitem" class="text-12 font-white cursor-pointer h-30 py-1 ps-3" [id]="node.name" [ngClass]="isActive(node) ? 'onSelected' : ''" (click)="onSelect(node)">
              {{ node.name }}  

              <ng-container *ngIf="node.notifications !== null">
                ({{ node.notifications }})
              </ng-container>
            </div>
        </div>
    </div>

    <div class="task-left" *ngIf="isExpandable(node)">
        <div class="py-2 px-3 border-top border-1 border-secondary ">
            <div class="topic">
                <div class="color-var-500 px-0 py-1 text-12 ">{{ node.label  }}</div>
            </div>
            <ul class="my-0 ps-0 sub-topic ">
              <li *ngFor="let sub of node.subMenu; let i = index" class="ps-0 setup-sub-manu py-1" [id]="sub.label" aria-labelledby="setup-header0" [ngClass]="isActive(sub) ? 'onSelected' : ''" (click)="onSelect(sub)" ><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">
                {{ sub.label  }}
                <ng-container *ngIf="sub.notifications !== null">
                  ({{ sub.notifications }})
                </ng-container>
              </span></a>
            </li>
            </ul>
        </div>
     </div>
    </div>
</div>
</div>
