import { Component, Input, OnInit } from '@angular/core';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

import {Message, MessageService} from 'primeng/api';

@Component({
  selector: 'app-messages',
  template: `<p-messages [value]="msgs"></p-messages>`,
  providers: [MessageService]
})
export class MessagesComponent extends SubscriptionUtil implements  OnInit {
//type is either 'error','warning','info'
showWelcomeModal = false;
msgs: Message[] = [];



constructor(
  private errorUtil: ErrorUtil,
  private service: MessageService
) {
  super();
  this.push(
    this.errorUtil.subjectOpenMessageModal$.subscribe((data: any) => {
      //console.log(data);
      this.showWelcomeModal = data.showWelcomeModal;
      
        this.msgs = [];
        this.msgs.push({
        severity: data.severity,
        summary:data.summary,
        detail: data.detail,
        });

      
    })
  )

}

ngOnInit() {
}

showInfoViaMessages() {
  this.msgs = [];
  this.msgs.push({severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks'});
}

showWarnViaMessages() {
  this.msgs = [];
  this.msgs.push({severity: 'warn', summary: 'Warn Message', detail: 'There are unsaved changes'});
}

showErrorViaMessages() {
  this.msgs = [];
  this.msgs.push({severity: 'error', summary: 'Error Message', detail: 'Validation failed'});
}

showSuccessViaMessages() {
  this.msgs = [];
  this.msgs.push({severity: 'success', summary: 'Success Message', detail: 'Message sent'});
}

closeAll(value = null) {
  this.showWelcomeModal = false;
}

push(obs:any) {
  super.push(obs);
}

ngOnDestroy() {
  super.ngOnDestroy();
}

}

