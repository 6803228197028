import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ApiService } from 'src/app/_services/api.service';
import { ProfileFeaturesService } from 'src/app/_services/profile-features.service';
import { SignUpService } from 'src/app/_services/sign-up.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-reset-contact-password',
  templateUrl: './reset-contact-password.component.html'
})
export class ResetContactPasswordComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  submitTouched: boolean = false;
  password : any = {};
  passwordForm!: UntypedFormGroup;
  params: any;
  data : any;
  data1 : any;
  type : any;
  translate: any;

  constructor(
    private route : ActivatedRoute,
    private auth : AuthenticationService,
    private apiService : ApiService,
    private profileService : ProfileFeaturesService,
    public modalRef : BsModalRef,
    private signUpService : SignUpService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()
    }
    
    ngOnInit() {
      console.log(this.data1)
      this.passwordForm = new UntypedFormGroup({
        'oldPassword': new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.passwordPattern)]),
        'password': new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.passwordPattern)]),
        'cPassword': new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.passwordPattern)]),
      });
      this.route.queryParams.subscribe(params => {
      if(params.id){
        this.params = params;
      } else {
        this.params = {id : this.auth.getUserId()};
      }
    });
  }

  changePassword(){
    this.submitTouched=true;
    
    if(!this.passwordForm.valid){
      for (var i in this.passwordForm.controls) {
        this.passwordForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    if(this.password.password !== this.password.cPassword){
      this.submitTouched = false;
    ///////  this.toaster.warn("", "Passwords do not match. Please try again");
    } else {
      if(this.params.id == this.auth.getUserId()){
        this.apiService.getProfile().subscribe((res:any) => {
          var formData = {
            login : res.login,
            newPassword : this.password.password,
            oldPassword : this.password.oldPassword
          }
          this.profileService.changePassword(formData).subscribe((res:any)=>{
            //console.log("Response ", res);
            if(res.status === 200 || res.status === 201){
              this.password = {};
              this.errorUtil.setErrorMessage(200, this.translate?.general.messages.updatedSuccessfully,  null, 'success',1000);
            ///////  this.toaster.success('', 'Password updated succesfully');
              this.modalRef.hide();
            } else {
            ////////  this.toaster.error("", res.error.message);
            this.errorUtil.setErrorMessage(400, null ,  res.error.message, 'error',3000);
            }
          });
        });
      }
     
    }
  }

  onSubmit(type: any){
    this.submitTouched=true;
  
    var formData : any = {
      login : this.data
    }
      this.signUpService.resetPasswordForCustomer(formData).subscribe((res:any)=>{
        if(res.status === 200 || res.status === 201){
          this.modalRef.hide();
          //////this.toaster.success('', 'An email has been sent to you to activate the account');
          this.errorUtil.setErrorMessage(200,  this.translate?.setup.users.users.messages.accountActivationEmailSent ,  null, 'success',1000);
        } else {
          this.submitTouched=false;
          var body = JSON.parse(res._body);
          this.errorUtil.setErrorMessage(400, null ,  body.title, 'error',3000);
          /////this.toaster.error("", body.title);
        }
      });
    }
    push(obs: any) {
      super.push(obs);
    }
  
    getLanguageData() {
      this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
        }
      }))
      console.log("translate",this.translate)
     // this.mainLabel = this.translate?.accessories.label.plural
    }

    ngOnDestroy(): void {
      super.ngOnDestroy();
    }

}
