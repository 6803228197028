import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html'
})
export class SuccessComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  time : any = 5;
  windowHeight: any;
  translate: any;
  constructor( private languageTranslateService: LanguageTranslateService) { 
    super()
   
  }

  ngOnInit() {

    this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))

    this.windowHeight = `${window.innerHeight}px`;
    var timeleft = 5;
    var downloadTimer = setInterval(() => {
      timeleft -= 1;
      this.time = timeleft;
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        setTimeout(() => {
          window.close();
        }, 1000);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}