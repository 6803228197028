import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConfirmActionsService {
  private afterConfirmSub = new Subject<any>();

  private confirmSub = new Subject<any>();

  confirmSubObservable = this.confirmSub.asObservable();

  /******************************************** during activation ******************************************/
  public passItem(items: any = null) {
    this.confirmSub.next(items);

    return this.afterConfirmSub.asObservable();
  }

  /******************************************** after confirm pass data down to whom ever component consumes and subscribe ******************************************/
  public triggerAfterConfirm(data: any) {
    this.afterConfirmSub.next(data);
  }
}
