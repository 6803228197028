
<div *ngIf="displayLookup">
  <form  [formGroup]="dynamicForm">
    <!-- Container Content -->
    <ng-container *ngFor="let section of modifiedField;let p = index">
      <div *ngIf="!section.hidden">
        <div *ngIf="section.labelType== 'Header'" [ngClass]="{'custella-form-container-title text-14 font-medium rounded-top': p ==0 }" class="custella-form-container-title-two">{{section.label}}</div>

        <div *ngIf="section.labelType== 'Sub-Header'" class="custella-form-container-title-two-grey w-100">{{section.label}}</div>
        
        <div class="custella-form-container-content my-1" *ngIf="section.fields">
          <div class="row mb-2">
              <div class="custella-content-row-input col-md-6 my-2" *ngFor="let field of section.fields;let c = index" [ngClass]="{'
              d-none ': field.componentType == 'Hidden' || field.componentType == 'Signature','col-md-12': columnDisplaystyle == '1'}">
                  <span class="custella-content-row-input-title" [ngClass]="{mandatory: field.validations !=null}" > {{field.label}} </span>
                  
                  <div [ngSwitch]="field.componentType"  >
                    <!--************************************************** Text *************************************************-->
                    <div  *ngSwitchCase="'Text'">
                      <input [type]="field.inputType" pInputText autocomplete="off" [readOnly]="field.readOnly" (keypress)="onKeypressEvent($event , field)" [maxLength]="field.length" [placeholder]="field.placeholder ? field.placeholder:''" [formControlName]="field.name"  class="form-control"   [name]="field.name" [(ngModel)]="field.value" >
                    </div>
                    <!--************************************************** PlaceAutoComplete *************************************************-->
                    <div  *ngSwitchCase="'PlaceAutoComplete'">
                    <input  pInputText autocomplete="off" [attr.disabled]="field.readOnly ? '' : null"  ngx-google-places-autocomplete  type="text" class="form-control"  style="height: 2rem !important;" [placeholder]="field.placeholder ? field.placeholder:''" [formControlName]="field.name" [name]="field.name" [(ngModel)]="field.value.description"  #placesRef="ngx-places" (change)="resetAddress(c,p)" (onAddressChange)="handleAddressChange($event,c,p)">
                    </div>
                  <!--************************************************** Date Time Picker *************************************************-->
                    <div class="d-flex" *ngIf="field.componentType == 'DateTimePicker'">
                      <p-calendar type="text" class="w-100" dateFormat="dd/mm/yy" [showTime]="true" hourFormat="12" [disabled]="field.readOnly"
                      [name]="field.name"   [ngModelOptions]="{standalone: true}" [(ngModel)]="field.value" [yearNavigator]="true" yearRange="2000:2030">
                    </p-calendar>
                    <input type="text"  [formControlName]="field.name"    [name]="field.name" [(ngModel)]="field.value" style="width: 0; border: none;height: 0;" >
                  

                      </div>
                <!--************************************************** Date Time Picker *************************************************-->
                  <div class="d-flex" *ngIf="field.componentType == 'TimePicker'">
                      <span class="p-input-icon-right w-100">
                        <i class="pi pi-clock" style="z-index: 1;"></i>
                        <p-calendar  hourFormat="12" [disabled]="field.readOnly" [timeOnly]="true" (ngModelChange)="changeEndTime(field)" [placeholder]="field.placeholder ? field.placeholder:''" [ngModelOptions]="{standalone: true}" [name]="field.name" [(ngModel)]="field.value" appendTo="body"></p-calendar>
                    </span>
                    <input type="text"  [formControlName]="field.name"    [name]="field.name" [(ngModel)]="field.value" style="width: 0; border: none;height: 0;" >
                  </div>

                <!--************************************************** Date Picker *************************************************-->
              
                    <div class="d-flex" *ngIf="field.componentType == 'DatePicker'">
                      <p-calendar type="text" class="w-100" dateFormat="dd/mm/yy"  [disabled]="field.readOnly"
                      [name]="field.name" [placeholder]="field.placeholder ? field.placeholder:''"  [ngModelOptions]="{standalone: true}" [(ngModel)]="field.value" [yearNavigator]="true" yearRange="2000:2030">
                    </p-calendar>
                    <input type="text"  [formControlName]="field.name"    [name]="field.name" [(ngModel)]="field.value" style="width: 0; border: none;height: 0;" >
                  
                    </div>
              
                      <!--************************************************** TextArea *************************************************-->
                    <div  *ngSwitchCase="'TextArea'">
                    <textarea pInputTextarea autocomplete="off" [disabled]="field.readOnly"  [placeholder]="field.placeholder ? field.placeholder:''" [formControlName]="field.name" [maxlength]="field.length"  class="form-control"   [name]="field.name" [(ngModel)]="field.value" style="height: unset;" rows="3"  ></textarea>
                    <!-- <small class="text-10 font-medium">{{ field.value == null ? '0': field?.value.length}}/{{field.length ? field.length:'255'}}</small> -->
                  </div>
              
                    <div *ngSwitchCase="'PickList' "> 
                      <div *ngIf="!field.multiSelect">
                          <p-dropdown  *ngIf="field.options"
                          [options]="field.options"
                          [showClear]="true"
                          optionLabel="label"
                          optionValue="value"
                          [id]="field.name"
                          [name]="field.name"
                          [formControlName]="field.name"
                          [(ngModel)]="field.value.value"
                          [disabled]="field.readOnly" 
                          [placeholder]="translate?.general.messages.none"
                          (onChange)="getDataPickListInfo(field,$event)"
                          ></p-dropdown>
                    </div>
                    <div *ngIf="field.multiSelect">
                        <p-multiSelect  *ngIf="field.options"
                        [options]="field.options"
                        display="chip"
                        optionLabel="label"
                        optionValue="value"
                        [id]="field.name"
                        [name]="field.name"
                        [formControlName]="field.name"
                        [(ngModel)]="field.value"
                        [disabled]="field.readOnly" 
                        [placeholder]="translate?.general.messages.none"
                        (onChange)="getMultiDataPickListInfo(field,$event)"
                        ></p-multiSelect>
                      </div>
                    </div>

              
                    <div *ngSwitchCase="'Select'">
                      <div *ngIf="field.selectData">
                        <p-dropdown 
                        [options]="field.selectData"
                        [showClear]="true"
                        optionLabel="name"
                        optionValue="id"
                        [id]="field.name"
                        [formControlName]="field.name"
                        [(ngModel)]="field.value.id"
                        [placeholder]="translate?.general.messages.none"
                        (onChange)="getDataInfo(field,$event)"
                        ></p-dropdown>
                      </div>
                    </div>
              
                    <div class="col d-flex p-0"  *ngIf="field.componentType == 'CheckBox' || field.componentType == 'Checkbox'" [ngClass]="{'padding-top': columnDisplaystyle == '2'}">
                        <div class="p-field-checkbox">
                          <p-checkbox [binary]="true" [(ngModel)]="field.value" [attr.disabled]="field.readOnly ? true : null" [name]="field.name" [formControlName]="field.name" ></p-checkbox>
                      </div>
                    </div>
                      <div *ngSwitchCase="'Lookup'">
                            <custella-autocomplete [modifiedField]="modifiedField" [mode]="mode" [child]="c" [parent]="p" [field]="field" [group]="dynamicForm" (selectedData)="gotSelectedData($event)"></custella-autocomplete>
                      </div>
              
                      <!-- Question Options -->
                      <div *ngIf="field.componentType == 'PickListButton' && !field.multiSelect" class="btn-group btn-group-toggle" data-toggle="buttons" style="display: grid !important;grid-template-columns: repeat(3, minmax(122px, auto));">
                          <label (click)="getAnswer(answer,c,p)" class="btn btn-outline-dark mr-2 d-flex align-items-center" *ngFor="let answer of field.options" [ngClass]="{'active': field.value?.value == answer.value}">
                              <input type="radio" name="options" value="{{answer.value}}" [(ngModel)]="answer.value"  [ngModelOptions]="{standalone: true}">{{answer.label}}
                          </label>
                        </div>

                        <div *ngIf="field.componentType == 'PickListButton' && field.multiSelect">
                            <div class="setup align-items-center" >
                              <button
                                  pButton
                                  type="button"
                                  class="rounded-default-button me-2 py-1 my-1"
                                  (click)="getAnswerMuliSelect(answer,c,p)"
                                  *ngFor="let answer of field.options"
                                  [ngClass]="{'rounded-default-button': !answer?.check, 'rounded-chosen-button': answer?.check}"
                                >
                                  {{answer.label}}
                              </button>
                          </div>
                    </div>
                      <!-------------- File Upload ---------------->
                    <div *ngSwitchCase="'FileUpload'">
                      
                          <div class="d-flex align-items-center">
                              <div class="d-flex ms-auto">
                                  <p-button type="button" [pTooltip]="translate?.general.buttons.upload.singular"  [label]="translate?.general.buttons.upload.singular"  (onClick)="openFileUploader(field)" class="d-flex rounded-1 px-2 py-2 ms-1">
                                      <i class="pi pi-upload text-white pe-2"></i>
                                  </p-button>
                                  <input [hidden]="true"  [placeholder]="translate?.note.messages.typeYourNoteHere" [name]="field.name" [id]="field.name" type="file" (change)="uploadFile($event,field)" />
                              </div>
                          </div>
                          <div class="custella-form-container-content-border-bottom text-12 font-medium" *ngIf="field?.value?.length>0">
                              <div class="row">
                                  <div class="custella-content-row-input col-12" *ngFor="let attachment of field?.value;let j = index">
                                      <div class="d-block position-relative me-3 col-12">
                                          <div class="flex-between border-bottom"
                                              style="margin-top: 10px; padding-bottom: 10px;">
                                              <div class="d-flex aligm-items-center">
                                                <div *ngIf="attachment.contentContentType.indexOf('image') >= 0">
                                                  <a class="custella-link" [href]="attachment.attachmentUrl" target="_blank">
                                                      <img style="width: 100px;" [src]="attachment.attachmentUrl" />
                                                  </a>
                                              </div>
                                              <div *ngIf="attachment.contentContentType.indexOf('video') >= 0" class="video-container">
                                                  <video #media id="singleVideo" class="video-player" preload="auto" controls>
                                                      <source src="{{attachment?.attachmentUrl}}" type="{{attachment?.contentContentType}}" />
                                                  </video>
                                              </div>
                                              <div class="d-flex align-items-center" *ngIf="attachment.contentContentType.indexOf('application') >= 0 || attachment.contentContentType.indexOf('text') >= 0">
                                                <img class="me-3" style="width: 25px;" src="../../../../assets/svg/fileTypes/general file.svg" />
                                                <a class="f-blue ml-2" style="text-decoration: underline;" [href]="attachment.attachmentUrl" target="_blank">
                                                    {{ attachment.fileName }}
                                                </a>
                                            </div>
                                                  <div>
                                                      <span class="text-12 font-medium px-3 elips">{{attachment.fileName}}</span>
                                                      <span class="text-10 font-medium color-var-500 d-block text-wrap mt-1 px-3">
                                                          {{ attachment.createdDate | momentDate: " hh:mm a":timezone }}
                                                          {{ attachment.createdDate | momentDate: dateFormat:timezone }}
                                                        </span>
                                                  </div>
                                            
                                              </div>
                                              <div class="flex-between">
                                                  <button pButton icon="fa-regular fa-download" class="p-button-outlined attachment-delete" [pTooltip]="translate?.general.buttons.download.singular" tooltipPosition="top" (click)="downloadAttachment(attachment,field,j)"></button>
                                                  <button type="button" class="custella-btn-white px-2 pt-1 ms-2" (click)="deleteAttchment(attachment,field,j)">
                                                    <img alt="delete attachment" src="assets\svg\table\pi-trash.svg" class="w-19px" />
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                    </div>
              
              <!-------------- Look Up ---------------->
                      <div *ngIf="field.validations != null && field.componentType !='Lookup'">
              
                      <ng-container *ngFor="let validation of field.validations.rules">
                        <div *ngIf="(dynamicForm.controls[field.name].touched && dynamicForm.controls[field.name].errors) && validation.name != 'nullable'">
                          <span class="text-10 p-error" *ngIf="dynamicForm.controls[field.name].errors?.[validation.validateName]" >{{validation.message}}</span>
                        </div>
                    </ng-container>
              
                        </div>
                      </div>
              
              </div>
              
          </div>
          
        </div>
    </div>
   </ng-container>

</form>
</div>
