<div class="custella-modal d-flex flex-column">
  <div class="custella-modal-header">
    <div class="custella-modal-header-text">
      <span> {{ translate?.general.locationDetails.fields.location.singular }} </span>
    </div>

    <i class="fa-regular fa-xmark pt-1"  [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="modalRef.hide()"></i>
  </div>

  <div class="location custella-modal-body">
    <div class="custella-content-row-input">
      <p-gmap
        [options]="options"
        [overlays]="overlays"
        [style]="{ width: '100%', height: '320px' }"
      ></p-gmap>
    </div>
  </div>
</div>
