<div class="modal-header p-dialog-header flex-between">
    <div class="">
        <span class="text-16 font-medium" id="title_SetIntegrationsSalesforce">{{translate?.setup.integrations.fields.salesforceIntegration.singular }}</span>
    </div>
    <i class="fa-regular fa-xmark" (click)="modalRef.hide()" id="btn_SetIntegrationsSalesforceClose"></i>
</div>
<div class="modal-body">
    <div class="container">
        <div class="flex-align-center justify-content-center">
            <img src="../../../../../assets/svg/salesforce.svg" class="w-40"  id="img_SetIntegrationsSalesforce">
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" id="label_SetIntegrationsSalesforceSyncCust">{{translate?.setup.integrations.fields.syncCustomer.singular }}</span>
            <input pInputText autocomplete="off" name="username" [(ngModel)]="company.syncCustomerToSf" id="input_SetIntegrationsSalesforceSyncCustField">
           
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" id="label_SetIntegrationsSalesforceSyncContacts">{{translate?.setup.integrations.fields.syncContacts.singular }}</span>
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" [(ngModel)]="company.syncConstactToSf" name="syncConstactToSf" id="check_SetIntegrationsSalesforceSyncContactsField"></p-checkbox>
            </div>
          
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" id="label_SetIntegrationsSalesforceSyncJob">{{translate?.setup.integrations.fields.syncJobOrders.singular }}</span>
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" [(ngModel)]="company.syncJoToSf" name="syncJoToSf" id="check_SetIntegrationsSalesforceSyncJobField"></p-checkbox>
            </div>
          
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" id="label_SetIntegrationsSalesforceSyncSalesOrder">{{translate?.setup.integrations.fields.syncSalesOrders.singular }}</span>
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" [(ngModel)]="company.syncSoToSf" name="syncSoToSf" id="check_SetIntegrationsSalesforceSyncSalesOrderField"></p-checkbox>
            </div>
          
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" id="label_SetIntegrationsSalesforceSyncProduct">{{translate?.setup.integrations.fields.syncProducts.singular }}</span>
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" [(ngModel)]="company.syncProductToSf" name="syncProductToSf" id="check_SetIntegrationsSalesforceSyncProductField"></p-checkbox>
            </div>
          
        </div>
    </div>
</div>
<div class="modal-footer p-0">
    <button class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()" id="btn_SetIntegrationsSalesforceCancel">{{translate?.general.buttons.cancel.singular }}</button>
    <p-button [label]="translate?.general.buttons.save.singular" styleClass="mx-1" (onClick)="onSubmit()" id="btn_SetIntegrationsSalesforceSubmit"></p-button>
</div>