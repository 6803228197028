import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { throwError, of as observableOf, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OvertimeService {

  public refreshList$: EventEmitter<any>;
  private selectSubject = new Subject();
  constructor(private http: HttpClient) {
    this.refreshList$ = new EventEmitter();
  }

  private ENDPOINT_OVERTIME_GET_ALL = "ms/v1/overtimes/filter/";
  private ENDPOINT_OVERTIME = "ms/v1/overtimes/";
  private ENDPOINT_OVERTIME_GET_BY_ID = "ms/v1/overtimes/";
  private ENDPOIN_OVERTIME_STATUS = "ms/v1/overtime-statuses"
  private ENDPOIN_OVERTIME_STATUS_UPDATE = "ms/v1/overtimes/status"
  private ENDPOINT_OVERTIME_UPDATE_REJECT_REASON = "ms/v1/overtimes/reason"

  public select(val: any) {
    this.selectSubject.next(val);
  }
  public getSelectItem() {
    return this.selectSubject.asObservable();
  }
  



  public getAllOvertime(search: any, parameters: any, apiParams: any) {
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (apiParams.userId) {
      params = params.set("userId", apiParams.userId);
    }
    if (apiParams.fromDate) {
      params = params.set('fromDate', apiParams.fromDate);
    }
    if (apiParams.toDate) {
      params = params.set('toDate', apiParams.toDate);
    }
    if (apiParams.status) {
      params = params.set('status', apiParams.status)
    }
    if (apiParams.siteId) {
      params = params.set('siteId', apiParams.siteId)
    }

    if (parameters)
      params = params.set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_OVERTIME_GET_ALL, { observe: 'response', params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public getAllOvertimeStatus() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOIN_OVERTIME_STATUS, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }


    /************************************* Get overTime Info By ID *************************************************************************/
    public getOverTimeById(id: any) {
      return this.http
        .get(environment.ms_node_base_url + this.ENDPOINT_OVERTIME_GET_BY_ID + id)
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            ////console.log(error);
            return throwError(error);
          })
        );
    }

    /************************************* Update overTime  status *************************************************************************/
  public updateOvertimeStatus(body: any,) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOIN_OVERTIME_STATUS_UPDATE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Status Updated");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
          
        })
      );
  }

        /************************************* Update overtime reject status *************************************************************************/
        public updateOvertimeRejectReason(body: any) {
          return this.http
            .put(environment.ms_node_base_url + this.ENDPOINT_OVERTIME_UPDATE_REJECT_REASON, body, {
              observe: "response",
            })
            .pipe(
              map((resp: any) => {
                this.refreshList$.emit("Status Updated");
                return resp;
              }),
              catchError((error) => {
                return observableOf(error);
              })
            );
        }


            /******************************************** create overtime ******************************************/
    public createOvertime(body: any) {
      return this.http
        .post(environment.ms_node_base_url + this.ENDPOINT_OVERTIME, body, {
          observe: "response",
        })
        .pipe(
          map(
            (resp: any) => {
              return resp;
            },
            catchError((error) => {
              //console.log(error);
              return throwError(error);
            })
          )
        );
    }


   /******************************************** edit overtime ******************************************/
   public editOvertime(body: any) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_OVERTIME, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

        /************************************* Delete overtime *************************************************************************/
        public deleteOvertime(id: any) {
          return this.http
            .delete(environment.ms_node_base_url + this.ENDPOINT_OVERTIME + id, {
              observe: "response",
            })
            .pipe(
              map((resp: any) => {
                this.refreshList$.emit("Deleted Visit");
                return resp;
              }),
              catchError((error) => {
                return observableOf(error);
              })
            );
        }

            /************************************* Get Overtime History By ID *************************************************************************/
    public getOvertimeHistoryById(overtimeId: any) {
      return this.http
        .get(environment.ms_node_base_url + 'ms/v1/overtime-audit-event-histories?overtimeId='+overtimeId)
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            ////console.log(error);
            return throwError(error);
          })
        );
    }

    public uploadAttachmentByOvertimeId(body: any) {
      return this.http.patch(environment.ms_node_base_url + 'ms/v1/overtimes/attachments', body, { observe: "response" }).pipe(
          map((resp: any) => {
            this.refreshList$.emit(resp);
            return resp;
          }),
          catchError((error) => {
            //console.log(error);
            return observableOf(error);
          })
        );
    }
}
