import { InventoryService } from 'src/app/_services/inventory.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GetContextMenuItemsParams, GridOptions,IServerSideGetRowsParams, MenuItemDef } from 'ag-grid-community';
import "ag-grid-enterprise";  
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../../auth/authentication.service';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { Router } from '@angular/router';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-search-location-bin',
  templateUrl: './search-location-bin.component.html',
  styleUrls: ['./search-location-bin.component.css']
})
export class SearchLocationBinComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  @Output() customerLocationBin = new EventEmitter();
  @Output() closePartView = new EventEmitter();
  @Input() lookupFilter: any =''
  searchValue: any='';
  locationBins: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams : any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  noinventoryDataIcon!: boolean;
  productsFamilies: any;
  productsType: any;
  displayIcon: boolean = false;
  currentRecordNumber: any;
  totalRecord: any;
  noLocationBinsIcon: boolean = false;
  translate: any;
  
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private errorUtil: ErrorUtil,
    private inventoryService: InventoryService,
    private util: UtilServiceService,
    private router: Router,
    private languageTranslateService: LanguageTranslateService
  ) { 
    super();
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'infinite',
      sortingOrder : ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      }
    this.columnDefs = [
         { headerName: 'Bin Location', field: 'displayName', width: 150, cellClass: function (params: any) { return ['table_default_color'];}, headerTooltip: "#" },
         { headerName: 'No. of Products', field: 'productAndAssetsCount', width: 100,headerClass: 'marging-auto hide-action-border', headerTooltip: "#" }
         ];
    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
    this.overlayNoRowsTemplate = "<span style=\"padding: 10px; font-size: 14px;\">Record not found</span>";

  }
  currencyFormatter(params: any) {
    var usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    });
    return usdFormate.format(params.value);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

 
/********************************** Search Data **************************/
quickSearch() {
  if (this.searchValue == '' || this.searchValue.length > 2) {
    this.onGridReady(this.gridParams);
   }
}
/********************************** Initilize Grid Table **************************/
onGridReady(params:any) {
 //console.log(params)
 this.gridParams = params;

this.gridApi = params.api;
this.gridApi.sizeColumnsToFit();
console.log("Step 1")
 var dataSource = this.serverSideDatasource()
  console.log(dataSource)

 //this.gridApi.sizeColumnsToFit();
 this.gridApi.setDatasource(dataSource);
}
private serverSideDatasource() {
  return {
    getRows: (params: IServerSideGetRowsParams) => {
      this.getServerSideData(params);
    }
  }
}



/********************************** Call Customer API to Display data **************************/
getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
  console.log("Step 2")
 this.gridApi.hideOverlay();
 const serverRequest = this.getRequestParams(agGridGetRowsParams);
console.log('Making a server request: ', serverRequest);

this.push(
   this.inventoryService.getAllLocationBin(this.searchValue, serverRequest, this.lookupFilter).subscribe((data: any) => {
      console.log(data)
       this.locationBins = data.data;
       this.totalRecord = data.total
       this.setGridData(agGridGetRowsParams, this.locationBins, data.total,data.status);
     },
     (err) => {
       this.errorUtil.setErrorMessage(err); 
     
     }))
  
}
/********************************** Set Data for Grid Table **************************/
private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number, status: string) {
 //if (resultItems) {

   if(this.locationBins.length === 0){
     this.gridParams.api.showNoRowsOverlay();
   }else{
     agGridGetRowsParams.successCallback(resultItems, totalCount);
   }

}
/********************************** Request Parameter for api **************************/

getRequestParams(agGridRequest: any): ServerRequest {

  if(agGridRequest.sortModel.length <= 0){
       this.parameters = {colId : "id",sort : "desc"};
       agGridRequest.sortModel.push(this.parameters);
     }

 //console.log(agGridRequest)
 this.currentRecordNumber = agGridRequest.startRow
 return {
   startRow: agGridRequest.startRow,
   pageSize: 25,
   filterModel: null,
   sortModel: agGridRequest.sortModel
 }
}



  
   selectLocationBinData(e: any) {
      this.selectLocationBin(e.data)
     
   }

  selectLocationBin(val: any){
    this.customerLocationBin.emit(val);
  }

  closeCustomer(){
    this.closePartView.emit(false)
  }

    /******************************************** Right Click Context Menu ******************************************/
    public getContextMenuItems = (params: any) => {
      params.node.data.field = params.column.colId;
      let url = "";
      let data = "";
  
      let results = [];
  
      if (params.column.colId == "displayName") {
        const data = params.node.data;
  
        results.push({
          name: "Open link in new tab",
          action: () => this.redirectToNewTab(data),
        });
      }
  
      return results;
    };
  
    /******************************************** Redirect To ******************************************/
    private redirectToNewTab(data: any) {
      let url;
      if (data.field === "displayName") {
        url = this.router.serializeUrl(
          this.router.createUrlTree(
            ["/dashboard/inventory/binTransfer/view/" + data.id],
            {
              queryParams: { id: data.id },
            }
          )
        );
      }
  
      if (url) {
        url = url.replace("%23", "#");
        this.util.openItemToNewTab(url);
      }
    }
    push(obs:any) {
      super.push(obs);
    } 
}
