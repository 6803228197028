import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { SignUpService } from "src/app/_services/sign-up.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { UsersService } from "src/app/_services/admin/users.service";
import { NgxSpinnerService } from "ngx-spinner";
import { EngLangData, MalayLangData, chineseLangData, deutschLangData, languageOptions, spanishLangData } from "src/app/_models/language.data.model";
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  host: {
    "(body:click)": "onClick($event)",
  },
})
export class ResetPasswordComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  resetkey: any;
  submitTouched: boolean = false;
  forgotPassword: any = {};
  forgotPasswordForm!: UntypedFormGroup;
  showtooltip: boolean= false;
  fieldTextType!: boolean;
  fieldTextType2!: boolean;
  isPasswordMatching!: boolean;
  isPasswordHasUserName: boolean=false;
  userName : any ;
  errorMsg: any='';
  isPasswordValid: boolean = true;

  
  @ViewChild('chooseLang') chooseLangEl: ElementRef;


   langData: any;
   languageList: any;
  currentLang: any;

  constructor(
    private signUpService: SignUpService,
    private router: Router,
    private route: ActivatedRoute,
    private errorUtil: ErrorUtil,
    private userService: UsersService,
    private spinner: NgxSpinnerService
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    var langCode = localStorage.getItem('lang');
    if (langCode == 'my') this.onChangeLanguage('Bahasa Melayu')
    else if (langCode == 'en') this.onChangeLanguage('English')
    else if (langCode == 'zh') this.onChangeLanguage('简体中文')
    else this.onChangeLanguage('English')
    this.languageList = Object.values(languageOptions);
    this.forgotPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.strongPasswordRegx), this.passwordValidator]),
      confirmPassword: new UntypedFormControl(null, [Validators.required, Validators.pattern(AppComponent.strongPasswordRegx)]),
    });
  }

  /******************************************** Init ******************************************/
  private init() {

    

    this.push(
      this.route.url.subscribe((params) => {
        //console.log("Params", params)
        this.resetkey = params[1].path;
        //this.userName = params

        this.userService
        .validateActivationToken(this.resetkey)
        .subscribe((res: any) => {
          if (res && res.status && res.status !== 200) {
            this.errorUtil.setErrorMessage(
              400,
              res.error.title,
              res.error.title,
              "error",
              2000
            );

            this.router.navigate(["login"]);
          }
          else{
            //console.log("responce activation link",res)
            this.userName = res.email;
          }

        });

      })
    );


  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Submit ******************************************/
  public submitResetPassword() {
    this.errorMsg =''
    this.submitTouched = true;

    if (!this.forgotPasswordForm.valid) {
      for (var i in this.forgotPasswordForm.controls) {
        this.forgotPasswordForm.controls[i].markAsTouched();
      }
      return false;
    }

    if (this.isPasswordHasUserName) {
      this.errorMsg = this.langData.resetPassword.messages.passwordCriteriaNotMet
      return false;
    }

    if (this.forgotPassword.password !== this.forgotPassword.cPassword) {
      this.errorUtil.setErrorMessage(
        "400",
        null,
        this.langData.resetPassword.messages.passwordsDoNotMatch,
        "warn",
        2000
      );

      this.submitTouched = false;
    } else {
      var formData = {
        key: this.resetkey,
        newPassword: this.forgotPassword.password,
      };

      this.spinner.show();
      this.push(
        this.signUpService.resetPassword(formData).subscribe((res: any) => {
          ////console.log("Response ", res);
          if (res.status === 200 || res.status === 201) {
            this.spinner.hide();

            this.router.navigate(["/login"]);

            this.errorUtil.setErrorMessage(
              200,
              this.langData.resetPassword.messages.passwordResetSuccess,
              null,
              "success",
              2000
            );
          } else {
            this.submitTouched = false;
            this.errorUtil.setErrorMessage(
              res.status,
              null,
              res.title,
              "error",
              2000
            );
          }
        })
      );
    }
  }

  /******************************************** Toggle Password Show ******************************************/
  public toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  /******************************************** Password Length ******************************************/
  public getPassowrdLength(password: any) {
    const value = password.target.value;
   if(this.passwordFormField?.valid){
    this.isPasswordValid = true;
   }
   else{
    this.isPasswordValid = false;
   }
   console.log(this.userName, "====", value, "====", this.passwordFormField?.get('password')?.valid)
   const substringLower = value.toLowerCase();
   let matchFound = false;
   if(this.isPasswordHasUserName)
   {console.log("has 1")
     if(substringLower && substringLower.length > 4 && this.userName && this.userName.indexOf(substringLower) !== -1)
      {
       if(substringLower && substringLower.length > 5 && this.userName && this.userName.indexOf(substringLower) == -1)
       {
       this.isPasswordHasUserName = true;
       }
      }
      else if(substringLower && substringLower.length < 5){
       this.isPasswordHasUserName = false;
      }
   }
   else{
     if(substringLower && substringLower.length > 5 && this.userName && this.userName.indexOf(substringLower) !== -1){
       this.isPasswordHasUserName = true;
      }
   }
    if (this.forgotPasswordForm.get('password')?.valid == false) {
      this.showtooltip = true;
    }
    else{
      ///this.showtooltip = false;
    }
    console.log(this.forgotPasswordForm.get('password')?.valid)
  }

  /******************************************** Toggle Confirm Password Show ******************************************/
  public toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  /******************************************** Confirm Password Length ******************************************/
  public getCPassowrdLength(cPassword: any) {
    const value = cPassword.target.value;
    if (this.forgotPassword.password !== this.forgotPassword.cPassword) {
      this.isPasswordMatching = false;
    }
    if (this.forgotPassword.password == this.forgotPassword.cPassword) {
      this.isPasswordMatching = true
    }
  }


  get passwordFormField() {
    return this.forgotPasswordForm.get('password');
  }

  push(obs:any) {
    super.push(obs);
  }

  onClick(event: any) {
    if ( event.target.id =='input_PwdActivateNewPwdField') {
      this.showtooltip = true;
    }else{
      this.showtooltip = false;
    }
  }

  passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!this.isPasswordHasUserName) {
      return null; // No error if password dose not have username
    }
    else {
      //alert('invalid')
      return { dynamicError: { message: 'Please enter a valid value.' } };
    }
  };


  onChangeLanguage(landKey: any) {
    console.log(landKey)
    //debugger
    if (landKey.value == 'English' || landKey == 'English') {
      this.currentLang = languageOptions.english
      this.langData = EngLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'en')
    }
    if (landKey.value == 'Bahasa Melayu' || landKey == 'Bahasa Melayu') {
      this.currentLang = languageOptions.malay
      this.langData = MalayLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'my')

    }
    if (landKey.value == '简体中文' || landKey == '简体中文') {
      this.currentLang = languageOptions.chinese
      this.langData = chineseLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'zh')

    }
    if (landKey.value == "Deutsch" || landKey == "Deutsch") {
      this.currentLang = languageOptions.deutsch;
      this.langData = deutschLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "de");
    }
    if (landKey.value == "Español" || landKey == "Español") {
      this.currentLang = languageOptions.chinese;
      this.langData = spanishLangData;
      this.chooseLangEl?.nativeElement.classList.remove("show");
      localStorage.setItem("lang", "es");
    }
    console.log(this.langData)
    //debugger
  }
  
}
