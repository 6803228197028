import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServiceZoneService {
  private ENDPOINT_SERVICE_ZONES = "/service-zones";
  private ENDPOINT_SERVICE_ZONES_PICKLIST = "/service-zones/pick-list";


  constructor(private http: HttpClient) {}

  public getAllServiceZones() {
    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SERVICE_ZONES}`, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllServiceZonesPicklist() {
    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_SERVICE_ZONES_PICKLIST}`, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createServiceZone(formData: any) {
    return this.http
      .post(
        `${environment.base_url}${this.ENDPOINT_SERVICE_ZONES}`,
        formData
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateServiceZone(formData: any) {
    return this.http
      .put(
        `${environment.base_url}${this.ENDPOINT_SERVICE_ZONES}`,
        formData
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteServiceZone(serviceZoneId: any) {
    return this.http
      .delete(
        `${environment.base_url}${this.ENDPOINT_SERVICE_ZONES}/${String(
          serviceZoneId
        )}`,
        {
          observe: "response",
        }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
