import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-open-new-tab-view-task',
  templateUrl: './open-new-tab-view-task.component.html',
  styleUrls: ['./open-new-tab-view-task.component.scss']
})
export class OpenNewTabViewTaskComponent implements OnInit {
  taskId: any;

  constructor(
    private router : Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.taskId = params['id'];

    });
  }

  closeSlide(){}
}
