<div class="custella-custom-dialog">
  <p-confirmDialog
    #cd
    [style]="{ width: '30vw' }"
    [baseZIndex]="1104"
    key="confirm2"
  >
    <ng-template pTemplate="header">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg" />
        <div class="text-16 font-medium ms-3">
          {{translate?.general.commonComponent.messages.confirm}}&nbsp;{{ data.deleteType }}
        </div>
      </div>
    </ng-template>
    <p-footer>
      <div class="d-flex align-items-center justify-content-end">
        <button class="custella-btn-white text-12 ms-2 me-0" (click)="cd.reject()">
          {{translate?.general.buttons.cancel.singular}}
        </button>
        <button
          pButton
          type="button"
          [label]="translate?.general.buttons.yes.singular"
          iconPos="left"
          class="p-button-info ms-2 me-0 h-32"
          (click)="cd.accept()"
        ></button>
      </div>
    </p-footer>
  </p-confirmDialog>
</div>
