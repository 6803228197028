
import * as moment from "moment";


export class Global {
 
    private selectedHub: string = '';

    setSelectedHub(value:any) {
        this.selectedHub = value;
    }

    getSelectedHub() {
        return this.selectedHub;
    }

    /******************************************** Setup Column Base on Data Type ******************************************/
    static setColumnByDataType(element: any,timezone:any=null,dateFormateChange :any = null){
    var data;
    //console.log(element)
    if (element.dataType == 'date') {
      
      element.cellRenderer = function (params: any) {
        //console.log(params,"===",dateFormateChange,"===",timezone)
        
          if (params && params.value) {
            
            return moment.tz(params.value, timezone).format(dateFormateChange.toUpperCase());
          }else{
            return '-'
          }
        };
    }else if (element.dataType == "text") {
        element.cellRenderer = function (params: any) {
        //  console.log(params.value,"===>>>>",params)
          if (params && params.value && params.value != undefined) {
            return params.value
            }else{
              return '-'
            }
          }  
    }else if (element.dataType == 'datetime') {
          element.cellRenderer = function (params: any) {
            
              if (params && params.value) {
                return moment.tz(params.value, timezone) .format(dateFormateChange.toUpperCase() + ", " + "hh:mm A");
              
              }else{
                return '-'
              }
            };
          
        }else if (element.dataType == "boolean") {
          element.cellRenderer = function (params: any) {
              if (params && params.value) {
                return 'Yes'
                }else{
                  return 'No'
                }
              }  
        }
    //return data
  }


  static setHeaderComponentParams(element: any,id :any){

    var setHeader = {
        template:
            '<div class="ag-cell-label-container" role="presentation" id="'+id+'">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>'
    }

    return setHeader
  }

  static setCellRenderer(element: any,id :any,displayName: any){

    if (  element.value != null) {
        return '<p id="'+id+'">'+displayName+'</p>';
      } else if( element.value == null ) {
        return '<span class="p-tag-value text-dark"> ' + "-"+ '</span>';
      }

  }
}
