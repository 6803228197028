<div class="left-right-space h-100-70 mb-3 mt-2">
  <!-- Title -->
  <div class="flex-between mt-1 ms-2 mb-2">
      <div class="text-20 font-semiBold" id="title_TaskAcceptDecl">
       {{translate?.task.sections.sec14.singular}}
      </div>
    <!-- Filter and New -->
    <div class="custella-dashboard-top-right d-flex ms-auto">
      <button *ngIf="noTasksToday" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_TaskRefresh" (click)="getFilter()">
        <i class="fa-regular fa-arrows-rotate"></i>
      </button>
      <div class="p-inputgroup search ms-2" *ngIf="noTasksToday">
          <span class="p-input-icon-right ">
            <input type="text" class="w-100" pInputText autocomplete="off" id="input_TaskSearchField" [placeholder]="translate?.general.buttons.search.singular" (keydown.enter)="quickSearch()" [(ngModel)]="searchValue" (keyup)="onSearchCheck()" />
            <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" id="btn_TaskSearchClear" (click)="onClear()"></i>
          </span>   
          <button type="button" pButton icon="fa-regular fa-magnifying-glass" id="btn_TaskSearch" (click)="quickSearch()"></button>
      </div>

    </div>
  </div>
  <div *ngIf="noTasksTodayIcon" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
      <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound">
       <p class="text-6 my-1 font-weight-bold">{{translate?.general.messages.noRecordsFound}}</p>
    </div>
  </div>

  <!-- TabMenu -->
  <div [hidden]="!noTasksToday" class="position-relative fw-normal display-table d-flex" style="height: calc(100% - 60px);">

    <div [hidden]="!noTasksToday" class="table-space ag-grid-table-full h-100">
      <ag-grid-angular
       #agGrid style="height: 100%;" 
       class="ag-theme-balham" 
       [columnDefs]="columnDefs"
       [gridOptions]="gridOptions" 
       [defaultColDef]="defaultColDef" 
       [maxConcurrentDatasourceRequests]="-1"
       [suppressDragLeaveHidesColumns]="true" 
       [paginationPageSize]="25"
        [pagination]="false" 
        [paginationPageSize]="25"
       [rowData]="tasks" 
       [frameworkComponents]="frameworkComponents" 
       (gridReady)="onGridReady($event)"
       [overlayNoRowsTemplate]="overlayNoRowsTemplate" 
       (cellClicked)="viewTask($event)"
       [getContextMenuItems]="getContextMenuItems"
       ></ag-grid-angular>

      <div class="col-12">
        <p class="ag-record-row-summary-panel padds text-end" id="label_TaskAccDeclTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p>
      </div>

    </div>

   
  </div>
</div>