import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.scss']
})
export class MyDashboardComponent implements OnInit {
  @Input() dashboardURL: any
  urlSafe!: SafeResourceUrl;
  url: any;
  loadDataDashboard: any = 0;

 constructor(public sanitizer:DomSanitizer,
  private spinner: NgxSpinnerService) { }

  ngOnInit() {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', 'http-portal');
    formData.append('username', 'admin');
    formData.append('password', 'admin');
    formData.append("client_secret", "zIkLhjdUVG9nTtHD3UKgIr7p");
   
    var access_token = localStorage.getItem( 'access_token');
    this.spinner.show()
    setTimeout(() => {
      this.url= environment.active_report_dashboard_url+this.dashboardURL+"?token=" + access_token;
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    console.log(this.urlSafe)
      this.loadDataDashboard = 1
      this.spinner.hide()
     }, 2000);
  //https://uat-report.custella.com/dashboards/view/9f362146-d3a0-40fc-aa7f-5ff6ae18b592?theme=Custella%20Theme%2013-10&lng=en-US
  setTimeout(() => {

    console.log($("#wynframe"))
       var framDocument=$("#wynframe")
       document.querySelector('.nb-footer')



    console.log(document.querySelector('.nb-footer'),"=====")

    $("#wynframe").contents().find(".nb-footer").html("---");

  }, 20000);
  }

}
