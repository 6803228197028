<div class="custella-modal d-flex flex-column">
    <div class="custella-modal-header">
        <div class="custella-modal-header-text" id="title_MagDash"> {{title}}</div>

        <i class="pi pi-times pt-1" id="btn_MagDashClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="modalRef.hide()"></i>
    </div>

    <div class="custella-modal-body mb-3 pt-0">
        <div class="d-flex align-items-center justify-content-end my-3">
            <!-- Filter and New -->
            <div class="custella-dashboard-top-right d-flex">
                <button type="button" class="btn-list-icon ms-2"  id="btn_MagDashRefresh"  [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" id="btn_MagDashRefresh" (click)="refresh()">
                    <i class="fa-regular fa-arrows-rotate"></i>
                </button>
                <button type="button" class="btn-list-icon ms-2" id="btn_MagDashFilter" [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" (click)="toggleFilterView()" id="btn_MagDashFilter">
                    <i class="fa-regular fa-filter-list"></i>
                </button>
                <div class="p-inputgroup search ms-2">
                    <span class="p-input-icon-right ">
                        <input type="text" class="w-100" [(ngModel)]="searchValue" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" id="input_MagDashSearchField" (keydown.enter)="onSearch()" (keyup)="onSearchCheck()" />
                        <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" id="btn_MagDashSearchClear" (click)="onClear()"></i>
                    </span>   
                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" (click)="onSearch()" id="btn_MagDashSearch"></button>
                </div>
            </div>
        </div>

        <div class="position-relative fw-normal d-flex" style="height: 60vh;">
            <div class="table-space ag-grid-table-full" *ngIf="title!='Workshop Spare Monitoring' && title!='Preventive Maintenance'">
                <ag-grid-angular #agGrid style="height: 100%;" class="ag-theme-balham" [columnDefs]="columnDefs" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [cacheOverflowSize]="2" [maxConcurrentDatasourceRequests]="-1" [frameworkComponents]="frameworkComponents"
                    (cellClicked)="view($event)" (gridReady)="onGridReady($event)" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
                <div class="col-12">
                    <p class="ag-record-row-summary-panel padds text-end" id="label_MagDashTableRec">
                        {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
                    </p>
                </div>
            </div>

            <div class="table-space ag-grid-table-full" *ngIf="title=='Workshop Spare Monitoring'">
                <ag-grid-angular #agGrid style="height: 100%;" class="ag-theme-balham" [columnDefs]="columnDefsMonitoring" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [cacheOverflowSize]="2" [maxConcurrentDatasourceRequests]="-1" [frameworkComponents]="frameworkComponents"
                    (cellClicked)="viewAsset($event)" (gridReady)="onGridReadyMonitoring($event)" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
                <div class="col-12">
                    <p class="ag-record-row-summary-panel padds text-end" id="label_MagDashWorkshopTableRec">
                        {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
                    </p>
                </div>
            </div>

            <div class="table-space ag-grid-table-full" *ngIf="title=='Preventive Maintenance'">
                <ag-grid-angular #agGrid style="height: 100%;" class="ag-theme-balham" [columnDefs]="columnDefsPreventiveMaintenance" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [cacheOverflowSize]="2" [maxConcurrentDatasourceRequests]="-1" [frameworkComponents]="frameworkComponents"
                    (cellClicked)="viewMaintenance($event)" (gridReady)="onGridReadyPreventiveMaintenance($event)" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
                <div class="col-12">
                    <p class="ag-record-row-summary-panel padds text-end" id="label_MagDashPreventiveTableRec">
                        {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
                    </p>
                </div>
            </div>

            <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': filterView }" *ngIf="filterView">
                <div class="custella-form-container-content-pane-title">
                    <span class="text-14 font-medium" id="title_MagDashFilterBy">{{translate?.general.messages.filterBy}}</span>
                    <i class="pi pi-arrow-right" id="btn_MagDashFilterClose" (click)="toggleFilterView()"></i>
                </div>
                <div class="custella-content">
                    <ng-container *ngIf="title != 'Workshop Spare Monitoring' && title!='Preventive Maintenance' && title!='Total Assignments'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashStatus">{{translate?.dashboard.jobOrder.fields.status.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    [showClear]="true"
                                    [options]="statuses"
                                    filterBy="fieldLabel"
                                    id="picklist_MagDashStatusField"
                                    optionLabel="fieldLabel"
                                    optionValue="name"
                                    placeholder="{{ translate?.general.messages.none }}"
                                    [(ngModel)]="filter.status"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.status ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="title=='Total Assignments'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashStatusAssign">{{translate?.dashboard.jobOrder.fields.status.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    [showClear]="true"
                                    [options]="allStatus"
                                    filterBy="label"
                                    optionLabel="label"
                                    optionValue="value"
                                    id="picklist_MagDashStatusAssignField"
                                    placeholder="{{ translate?.general.messages.none }}"
                                    [(ngModel)]="filter.status"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.status ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="title=='Workshop Spare Monitoring'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashStatusWorkshop">{{translate?.dashboard.jobOrder.fields.status.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    [options]="assetstatuses"
                                    [showClear]="true"
                                    filterBy="name"
                                    optionLabel="name"
                                    optionValue="id"
                                    id="picklist_MagDashStatusWorkshopField"
                                    placeholder="{{ translate?.general.messages.none }}"
                                    [(ngModel)]="filter.status"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.status ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="title=='Preventive Maintenance'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashStatusPreventive">{{translate?.dashboard.jobOrder.fields.status.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    id="picklist_MagDashStatusPreventiveField"
                                    [showClear]="true"
                                    [options]="allStatusPreventiveMaintenance"
                                    filterBy="label"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="{{ translate?.general.messages.none }}"
                                    [(ngModel)]="filter.status"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.status ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="title == 'Data Line Fault Calls'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashDataFault">{{translate?.dashboard.jobOrder.fields.faulttype.singular}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    [showClear]="true"
                                    [options]="faultTypes"
                                    filterBy="name"
                                    id="picklist_MagDashDataFaultField"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="{{ translate?.general.messages.none }}"
                                    [(ngModel)]="filter.fault"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.fault ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <div>
                        <a href="#" class="clear" id="subtitle_MagDashFilterClear" (click)="clearAll()">{{translate?.general.buttons.clearAll.singular}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>