
import {Observable, of as throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ServerRequest } from '../_models/global.data.model';

@Injectable()
export class CustomerSurveyService {
  private ENDPOINT_INVENTORY_CS= "/customer-survey-configs"
  private ENDPOINT_INVENTORY_CS_BY_ID= "/customer-survey-configs/"
  

  
  public refresherCS$: EventEmitter<any>;
  // public refresherZone$: EventEmitter<any>;
  // public refresherBin$: EventEmitter<any>;
  // public refreshInventoryUserList$: EventEmitter<any>;
  // public refreshInventoryLocationList$: EventEmitter<any>;
  // public refreshInventoryList$: EventEmitter<any>;
  // public refreshBinTransfer$: EventEmitter<any>;

constructor(private http: HttpClient,
    private auth: AuthenticationService,
    private router : Router) {

      this.refresherCS$ = new EventEmitter();
      // this.refresherZone$ = new EventEmitter();
      // this.refresherBin$ = new EventEmitter();
      // this.refreshInventoryUserList$ = new EventEmitter();
      // this.refreshInventoryLocationList$ = new EventEmitter();
      // this.refreshInventoryList$ = new EventEmitter();
      // this.refreshBinTransfer$ = new EventEmitter();
    
  }

//**================================== Inventory setup =>location  =======================*/
/**------------------------- List ------------------------*/
public getAllCS(searchValue: any,parameters: any){
  let params = new HttpParams();
  
  if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
  console.log(parameters)
  if(parameters){
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId)
    .set('sortOrder', parameters.sortModel[0].sort);
    if(parameters.isActive != null) params = params.set('isActive', parameters.isActive);
  }
  return this.http.get( `${environment.base_url}/customer-survey-configs/filter`, { observe: 'response',params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**------------------------- Create ------------------------*/
public createCS(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_INVENTORY_CS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/**------------------------- Get By Id ------------------------*/
public getCSById(stepId: any){
  return this.http.get( environment.base_url+this.ENDPOINT_INVENTORY_CS_BY_ID + stepId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/**------------------------- Update ------------------------*/
public updateCS(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_INVENTORY_CS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}
/**------------------------- Delete ------------------------*/
public deleteCS(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_INVENTORY_CS_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherCS$.emit('Deleted Customer Survey');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

/**------------------------- Create ------------------------*/
public createBuildSurvey(body:any){
  return this.http.post( environment.multiTenant_node_url+'/survey-schema', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherCS$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/**------------------------- Get By Id ------------------------*/
public getBuildSurveyById(configId: any){
  return this.http.get( environment.multiTenant_node_url+'/survey-schema/by-config-details?configId=' + configId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/**------------------------- Update ------------------------*/
public updateBuildSurvey(body:any){
  return this.http.put( environment.multiTenant_node_url+'/survey-schema', body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresherCS$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return throwError( error );
  }),);
}

/**------------------------- Generate SUrvey Link ------------------------*/
public generateSurveyLink(body:any){
  return this.http.post( environment.base_url+'/job-orders/survey/generation', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherCS$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

/**------------------------- send SUrvey Link ------------------------*/
public sendSurveyLink(body:any){
  return this.http.put( environment.base_url+'/job-orders/survey/send-email', body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherCS$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

/**------------------------- Get By Id ------------------------*/
public getSurveyLink(joId: any){
  return this.http.get( environment.base_url+'/job-orders/survey/' + joId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

/**------------------------- Get Public URL By Id ------------------------*/
public getPublicSurveyById(configId: any,tenantId: any,jobOrderId: any){
  return this.http.get( environment.multiTenant_node_url+'/public/survey-schema/by-config-details?tenantId=' + tenantId+'&configId='+configId+'&jobOrderId='+jobOrderId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status != 200){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

/**------------------------- Generate SUrvey Link ------------------------*/
public savePublicSurveyResult(body:any,tenantId: any){
 
  return this.http.post( environment.multiTenant_node_url+'/public/survey-result?tenantId='+tenantId, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherCS$.emit(body);
    return resp;
  }
),catchError( error => {
      return throwError( error );
  }),);
}

/**------------------------- Get Public URL By Id ------------------------*/
public getPublicSurveyResult(surveyId: any,tenantId: any){
  return this.http.get( environment.multiTenant_node_url+'/public/survey-result?tenantId=' + tenantId+'&surveyId='+surveyId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);

  
}

public getPublicSurveyResultByJo(surveyId: any,tenantId: any,jobOrderId: any ){
  return this.http.get( environment.multiTenant_node_url+'/public/survey-result?tenantId=' + tenantId+'&surveyId='+surveyId+'&jobOrderId='+jobOrderId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);

  
}

/**------------------------- Get Public URL By Id ------------------------*/
public getPublicConfig(configId: any,tenantId: any,jobOrderId: any){
  return this.http.get( environment.multiTenant_node_url+'/public/survey-schema/by-config?tenantId=' + tenantId+'&configId='+configId+'&jobOrderId='+jobOrderId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 200){
      return resp.body;
    } else {
      return false;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
}