import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { pipe, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import * as moment from "moment-timezone";

@Injectable({
  providedIn: "root",
})
export class ShiftsService {
  public refreshList$: EventEmitter<any>;
  private ENDPOINT_CREATE_SHIFT = "/user-shifts";
  private ENDPOINT_DELETE_TASK = "/user-shifts/";
  private ENDPOINT_USER_TYPES = "/user-types"; //dd
  private ENDPOINT_ACCESS_Profiles = "/profiles"; //dd
  private ENDPOINT_ALL_TEAMS = "/teams/get-all-teams"; //dd
  private ENDPOINT_ALL_USERS = "/v4/get-all-users"; //dd
  private ENDPOINT_CANCEL_SHIFT = "/user-shifts/request-cancellation";
  private ENDPOINT_REJECT_SHIFT = "/user-shifts/status";
  private ENDPOINT_USER_SHIFTS_INFO_FILTER = "/user-shifts/info/filter";
  private ENDPOINT_USER_SHIFTS_CANCEL_REQUESTS =
    "/user-shifts/cancellation-requests";

  public dateEmit$: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {
    this.refreshList$ = new EventEmitter();
  }

  //add
  public createShift(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_SHIFT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  //edit
  public editShift(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_SHIFT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  //dd
  public getUsers(
    userTypeId: any,
    teamId: any,
    profileId: any,
    managerId: any,
    shift: any
  ) {
    let params = new HttpParams();
    if (userTypeId) params = params.set("userTypeId", userTypeId);
    if (teamId) params = params.set("teamId", teamId);
    if (profileId) params = params.set("profileId", profileId);
    if (managerId) params = params.set("managerId", managerId);
    params = params.set("shift", shift); //boolean
    return this.http
      .get(environment.base_url + this.ENDPOINT_ALL_USERS, {
        observe: "response",
        params: params,
      })

      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  //dd
  public getUserTypes() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_TYPES, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  //dd
  public getAccessProfiles() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ACCESS_Profiles, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  //dd
  public getAllTeams(id: any) {
    let params = new HttpParams().set("userId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_ALL_TEAMS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  //3rd tab grid call
  public getAllCancelShifts(assignedByUserId: any) {
    let params = new HttpParams().set("assignedByUserId", assignedByUserId);
    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_SHIFTS_CANCEL_REQUESTS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteShift(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_TASK + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Task");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public cancelShift(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CANCEL_SHIFT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public rejectShift(shiftId: any, status: any) {
    return this.http
      .put(
        environment.base_url +
          this.ENDPOINT_REJECT_SHIFT +
          "?shiftId=" +
          shiftId +
          "&status=" +
          status,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Check In Task");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getMyShiftsInfo(
    fromDate: any,
    toDate: any,
    withLeave: any,
    isMyShift: any
  ) {
    let params = new HttpParams();
    if (toDate) {
      params = params.set("fromDate", fromDate).set("toDate", toDate);
    } else {
      params = params
        .set("fromDate", moment().format("YYYY-MM-DD"))
        .set("toDate", moment().format("YYYY-MM-DD"));
    }
    if (withLeave) params = params.set("withLeave", withLeave);
    if (isMyShift) params = params.set("isMyShift", isMyShift);
    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_SHIFTS_INFO_FILTER, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getStaffShiftsInfo(
    fromDate: any,
    toDate: any,
    userTypeId: any,
    teamId: any,
    profileId: any,
    withLeave: any,
    isMyShift: any
  ) {
    let params = new HttpParams();
    if (toDate) {
      params = params.set("fromDate", fromDate).set("toDate", toDate);
    } else {
      params = params
        .set("fromDate", moment().format("YYYY-MM-DD"))
        .set("toDate", moment().format("YYYY-MM-DD"));
    }

    if (userTypeId) params = params.set("userTypeId", userTypeId);
    if (teamId) params = params.set("teamId", teamId);
    if (profileId) params = params.set("profileId", profileId);
    if (withLeave) params = params.set("withLeave", withLeave);
    if (isMyShift) params = params.set("isMyShift", isMyShift);
    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_SHIFTS_INFO_FILTER, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public emitDateChange(date: any, view: any) {
    this.dateEmit$.emit({ date: date, view: view });
  }
}
