import * as moment from "moment-timezone";
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from '../../../../_services/language-translate.service'
import { Injectable, OnDestroy } from "@angular/core";




@Injectable({
  providedIn: 'root'
})
export class ChubbTableColumns implements OnDestroy {
  private subscriptions: Subscription[] = [];
  translate: any = {};
  techniciansTableColumns : any = []
  techniciansTotalTaskByTeamColumns: any = [];
  techniciansTaskByTeamColumns: any = [];
  allTasksByTechnicianColumns
  constructor(private languageTranslateService: LanguageTranslateService) {
    this.getLanguageData();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getLanguageData() {
    this.subscriptions.push(
      this.languageTranslateService.wordSrc$.subscribe(data => {
        if (Object.keys(data).length != 0) {
          this.translate = data;

          this.techniciansTableColumns = [
            {
              headerName: this.translate.dashboard.chubb.fields.technician.singular,
              field: "technician",
              colId: "technician",
              width: 150,
              cellClass: function (params: any) {
                return ["text-1-5 font-weight-bold table_default_color"];
              },
              filter: false,
              headerTooltip: "Technicians",
            },
            {
              headerName: this.translate.dashboard.chubb.fields.jobsIn.singular,
              field: "joCount",
              width: 80,
              filter: false,
              headerTooltip: "Jobs In",
            },
            {
              headerName: this.translate.general.status.active.singular,
              field: "openCount",
              width: 70,
              filter: false,
              headerTooltip: "Open",
            },
            {
              headerName:  this.translate.general.status.inProgress.singular,
              field: "inProgressCount",
              width: 100,
              filter: false,
              headerTooltip: "In Progress",
            },
            {
              headerName: this.translate.general.status.pending.singular,
              field: "pendingCount",
              width: 80,
              filter: false,
              headerTooltip: "Pending",
            },
            {
              headerName:  this.translate.general.status.completed.singular,
              field: "completeCount",
              width: 100,
              filter: false,
              headerTooltip: "Completed",
            },
            {
              headerName:  this.translate.general.status.incompleted.singular,
              field: "incompleteCount",
              width: 100,
              filter: false,
              headerTooltip: "Incomplete",
            },
            {
              headerName:  this.translate.general.status.cancelled.singular,
              field: "cancelCount",
              width: 100,
              filter: false,
              headerTooltip: "Cancelled",
            },
            {
              headerName:  this.translate.dashboard.chubb.fields.attendFault.singular,
              field: "attendFault",
              width: 100,
              filter: false,
              headerTooltip: "Attend Fault",
            },
            {
              headerName:  this.translate.dashboard.chubb.fields.servicing.singular,
              field: "service",
              width: 100,
              filter: false,
              headerTooltip: "Servicing",
            },
            {
              headerName:  this.translate.dashboard.chubb.fields.installation.singular,
              field: "installations",
              width: 100,
              filter: false,
              headerTooltip: "Installation",
            },
            {
              headerName:  this.translate.dashboard.chubb.fields.billingPending.singular,
              field: "billingPending",
              width: 100,
              filter: false,
              headerTooltip: "Billing Pending",
            },
            {
              headerName:  this.translate.dashboard.chubb.fields.billingCompleted.singular,
              field: "billingComplete",
              width: 100,
              filter: false,
              headerTooltip: "Billing Completed",
            },
          ];

          this.techniciansTaskByTeamColumns = [
            {
              headerName: this.translate.dashboard.chubb.fields.technician.singular,
              field: "name",
              colId: "name",
              width: 170,
              cellClass: function (params: any) {
                return [""];
              },
              filter: false,
              headerTooltip: "#",
            },
            {
              headerName: this.translate.dashboard.chubb.fields.openTask.singular,
              field: "openTasks",
              colId: "openTasks",
              width: 250,
              filter: false,
              headerTooltip: "#",
            },
            {
              headerName: this.translate.dashboard.chubb.fields.closedTask.singular,
              field: "closedTasks",
              colId: "closedTasks",
              width: 250,
              filter: false,
              headerTooltip: "#",
            },
          ];

          this.techniciansTotalTaskByTeamColumns = [
            {
              headerName: this.translate.dashboard.chubb.fields.technician.singular,
              field: "name",
              colId: "name",
              width: 170,
              cellClass: function (params: any) {
                return [""];
              },
              filter: false,
              headerTooltip: "#",
            },
            {
              headerName: this.translate.dashboard.chubb.fields.pendingTask.singular,
              field: "pendingTasks",
              colId: "pendingTasks",
              width: 250,
              filter: false,
              headerTooltip: "#",
            },
            {
              headerName: this.translate.dashboard.chubb.fields.incompleteTask.singular,
              field: "inCompleteTasks",
              colId: "inCompleteTasks",
              width: 250,
              filter: false,
              headerTooltip: "#",
            },
            {
              headerName: this.translate.dashboard.chubb.fields.completedTask.singular,
              field: "completeTasks",
              colId: "completeTasks",
              width: 250,
              filter: false,
              headerTooltip: "#",
            },
          ];

          this.allTasksByTechnicianColumns = [
            {
              headerName: this.translate.dashboard.chubb.fields.jobOrderId.singular,
              field: "joNumber",
              colId: "joNumber",
              width: 170,
              cellClass: function (params: any) {
                return ["text-1-5 font-weight-bold table_default_color"];
              },
              filter: false,
              headerTooltip: "#",
            },
            {
              headerName: this.translate.dashboard.chubb.fields.orderName.singular,
              field: "joName",
              colId: "joName",
              width: 200,
              filter: false,
              headerTooltip: "#",
            },
            {
              headerName: this.translate.dashboard.chubb.fields.orderTimeDate.singular,
              field: "orderDateTime",
              colId: "orderDateTime",
              width: 150,
              filter: false,
              cellRenderer: function (params: any) {
                if (params.data && params.data.orderDateTime) {
                  return (
                    moment(params.data.orderDateTime).format("hh:mm A") +
                    ", " +
                    moment(params.data.orderDateTime).format(
                      localStorage.getItem("date_format")!.toUpperCase()
                    )
                  );
                }
              },
              headerTooltip: "#",
            },
            {
              headerName: this.translate.customer.label.singular,
              field: "customer.name",
              colId: "customer",
              width: 200,
              filter: false,
              headerTooltip: "#",
            },
          ];

        }
      })
    );
  }



}







export const techniciansTableColumns = [
  {
    headerName: "Technicians",
    field: "technician",
    colId: "technician",
    width: 150,
    cellClass: function (params: any) {
      return ["text-1-5 font-weight-bold table_default_color"];
    },
    filter: false,
    headerTooltip: "Technicians",
  },
  {
    headerName: "Jobs In",
    field: "joCount",
    width: 80,
    filter: false,
    headerTooltip: "Jobs In",
  },
  {
    headerName: "Open",
    field: "openCount",
    width: 70,
    filter: false,
    headerTooltip: "Open",
  },
  {
    headerName: "In Progress",
    field: "inProgressCount",
    width: 100,
    filter: false,
    headerTooltip: "In Progress",
  },
  {
    headerName: "Pending",
    field: "pendingCount",
    width: 80,
    filter: false,
    headerTooltip: "Pending",
  },
  {
    headerName: "Completed",
    field: "completeCount",
    width: 100,
    filter: false,
    headerTooltip: "Completed",
  },
  {
    headerName: "Incomplete",
    field: "incompleteCount",
    width: 100,
    filter: false,
    headerTooltip: "Incomplete",
  },
  {
    headerName: "Cancelled",
    field: "cancelCount",
    width: 100,
    filter: false,
    headerTooltip: "Cancelled",
  },
  {
    headerName: "Attend Fault",
    field: "attendFault",
    width: 100,
    filter: false,
    headerTooltip: "Attend Fault",
  },
  {
    headerName: "Servicing",
    field: "service",
    width: 100,
    filter: false,
    headerTooltip: "Servicing",
  },
  {
    headerName: "Installation",
    field: "installations",
    width: 100,
    filter: false,
    headerTooltip: "Installation",
  },
  {
    headerName: "Billing Pending",
    field: "billingPending",
    width: 100,
    filter: false,
    headerTooltip: "Billing Pending",
  },
  {
    headerName: "Billing Completed",
    field: "billingComplete",
    width: 100,
    filter: false,
    headerTooltip: "Billing Completed",
  },
];

export const techniciansTaskByTeamColumns = [
  {
    headerName: "Technicians",
    field: "name",
    colId: "name",
    width: 170,
    cellClass: function (params: any) {
      return [""];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Open Tasks",
    field: "openTasks",
    colId: "openTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Closed Tasks",
    field: "closedTasks",
    colId: "closedTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
];

export const techniciansTotalTaskByTeamColumns = [
  {
    headerName: "Technicians",
    field: "name",
    colId: "name",
    width: 170,
    cellClass: function (params: any) {
      return [""];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Pending Tasks",
    field: "pendingTasks",
    colId: "pendingTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Incomplete Tasks",
    field: "inCompleteTasks",
    colId: "inCompleteTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Completed Tasks",
    field: "completeTasks",
    colId: "completeTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
];

export const allTasksByTechnicianColumns = [
  {
    headerName: "Job Order Id",
    field: "joNumber",
    colId: "joNumber",
    width: 170,
    cellClass: function (params: any) {
      return ["text-1-5 font-weight-bold table_default_color"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Order Name",
    field: "joName",
    colId: "joName",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Order Time & Date",
    field: "orderDateTime",
    colId: "orderDateTime",
    width: 150,
    filter: false,
    cellRenderer: function (params: any) {
      if (params.data && params.data.orderDateTime) {
        return (
          moment(params.data.orderDateTime).format("hh:mm A") +
          ", " +
          moment(params.data.orderDateTime).format(
            localStorage.getItem("date_format")!.toUpperCase()
          )
        );
      }
    },
    headerTooltip: "#",
  },
  {
    headerName: "Customer",
    field: "customer.name",
    colId: "customer",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
];
