import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-connect-google-calender-success',
  templateUrl: './connect-google-calender-success.component.html'
})
export class ConnectGoogleCalenderSuccessComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  time : any = 5;
  page:any
  translate: any;
  constructor(
    private route: ActivatedRoute,
    private languageTranslateService: LanguageTranslateService
  ) { 
    super()

  }

  ngOnInit() {

    if (this.route.snapshot.data['page']) {
      this.page = this.route.snapshot.data['page']

      this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
        }
      }))
    }

    var timeleft = 5;
    var downloadTimer = setInterval(() => {
      timeleft -= 1;
      this.time = timeleft;
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        setTimeout(() => {
          window.close();
        }, 1000);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}