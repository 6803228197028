import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from "@angular/http";
import { param } from 'jquery';
import { throwError, of as observableOf, Subject, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ProductivityService {

  public refreshList$: EventEmitter<any>;

  private ENDPOINT_PRODUCTIVITY = "ms/v1/productivities/";
  private ENDPOINT_PRODUCTIVITY_SECONDARY = "ms/v1/secondary-works";
  private ENDPOINT_PRODUCTIVITY_SECONDARY_DELETE = "ms/v1/secondary-works/";
  private ENDPOINT_PRODUCTIVITY_PRIMARY_DELETE = "ms/v1/primary-works/";
  private ENDPOINT_PRODUCTIVITY_PRIMARY = "ms/v1/primary-works";
  private ENDPOINT_PRIMARY_PRODUCTIVITY = "ms/v1/primary-works/filter";
  private ENDPOINT_SECONDARY_PRODUCTIVITY = "ms/v1/secondary-works/filter";
  private ENDPOINT_UPDATE_PRODUCTIVITY_STATUS = "ms/v1/productivities/status";
  private ENDPOINT_GET_ALL_OUT_TURN_CODES = "ms/v1/out-turn-codes/";
  private ENDPOINT_PRODUCTIVITY_GET_BY_ID = "ms/v1/productivities/"
  private ENDPOINT_PRODUCTIVITY_PRIMARY_GET_BY_ID = "ms/v1/primary-works/"
  private ENDPOINT_PRODUCTIVITY_SECONDARY_GET_BY_ID = "ms/v1/secondary-works/"
  private ENDPOINT_PRODUCTIVITY_UPDATE_REJECT_REASON = "ms/v1/productivities/reason"
  private ENDPOINT_GET_PRODUCTIVITY_STATUS = "ms/v1/productivity-statuses";
  private ENDPOINT_GET_PRODUCTIVITY_ISSUES = "ms/v1/primary-work-issues";
  private ENDPOINT_GET_PRODUCTIVITY_TOTAL_AREA = "ms/v1/productivities/total-area"
  private ENDPOINT_GET_PRODUCTIVITY_SECONDARY_WORK_TYPE = "ms/v1/sw-type-of-works"
  private ENDPOINT_GET_PRODUCTIVITY_SECONDARY_WORK_NAME = "ms/v1/name-of-works"
  private ENDPOINT_GET_PRODUCTIVITY_SECONDARY_MACHINE_USED = "ms/v1/machine-useds"


  private selectSubject = new Subject();

  constructor(private http: HttpClient,
    private httpOnly: Http,
    private auth: AuthenticationService) {
    this.refreshList$ = new EventEmitter();
  }
  public select(val: any) {
    this.selectSubject.next(val);
  }
  public getSelectItem() {
    return this.selectSubject.asObservable();
  }

  public getAllProductivities(search: any, parameters: any, apiParams: any) {
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (apiParams.fromDate) {
      params = params.set('fromDate', apiParams.fromDate);
    }
    if (apiParams.toDate) {
      params = params.set('toDate', apiParams.toDate);
    }
    if (apiParams.siteId) {
      params = params.set('siteId', apiParams.siteId)
    }
    if (apiParams.status) {
      params = params.set('status', apiParams.status)
    }
    if (apiParams.userId) {
      params = params.set('userId', apiParams.userId)
    }
    params = params.set('skipDraft', 'true')
    //params = params.set('origin', 'customer');
    if (parameters)
      params = params.set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY + 'filter', { observe: 'response', params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public getAllPrimaryProductivities(search: any, parameters: any, apiParams: any, productivityId: any) {
    console.log(productivityId)
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (apiParams.userId) {
      params = params.set("userId", apiParams.userId);
    }
    if (productivityId) {
      console.log("pid", productivityId)
      params = params.set("productivityId", productivityId);
    }
    if (apiParams.fromDate) {
      params = params.set('fromDate', apiParams.fromDate);
    }
    if (apiParams.toDate) {
      params = params.set('toDate', apiParams.toDate);
    }

    if (apiParams.siteId) {
      params = params.set('siteId', apiParams.siteId)
    }
    if (apiParams.status) {
      params = params.set('status', apiParams.status)
    }
    if (apiParams.userId) {
      params = params.set('userId', apiParams.userId)
    }

    //params = params.set('origin', 'customer');
    if (parameters)
      params = params.set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    console.log("pidppp", params)

    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_PRIMARY_PRODUCTIVITY, { observe: 'response', params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);

  }

  public getAllSecondaryProductivities(search: any, parameters: any, apiParams: any, productivityId: any) {
    console.log(productivityId)
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (apiParams.userId) {
      params = params.set("userId", apiParams.userId);
    }
    if (productivityId) {
      console.log("pid", productivityId)
      params = params.set("productivityId", productivityId);
    }
    if (apiParams.fromDate) {
      params = params.set('fromDate', apiParams.fromDate);
    }
    if (apiParams.toDate) {
      params = params.set('toDate', apiParams.toDate);
    }

    if (apiParams.siteId) {
      params = params.set('siteId', apiParams.siteId)
    }
    if (apiParams.status) {
      params = params.set('status', apiParams.status)
    }
    if (apiParams.userId) {
      params = params.set('userId', apiParams.userId)
    }

    //params = params.set('origin', 'customer');
    if (parameters)
      params = params.set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    console.log("pidppp", params)

    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_SECONDARY_PRODUCTIVITY, { observe: 'response', params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);

  }

  public getAllProductivityStatus() {
    let params = new HttpParams();
    params = params.set('skipDraft', true)
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_GET_PRODUCTIVITY_STATUS, { observe: 'response', params:params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }
  public getIssues() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_GET_PRODUCTIVITY_ISSUES, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }  
  


  /************************************* Update visits  status *************************************************************************/
  public updateProductivityStatus(body: any,) {

    // let params = new HttpParams();
    // params = params.set('id', id);
    // params = params.set('status', status)
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_UPDATE_PRODUCTIVITY_STATUS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Status Updated");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /************************************* Get Productivity Info By ID *************************************************************************/
  public getproductivityById(id: any) {
    return this.http
      .get(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_GET_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* Get Productivity Primary Info By ID *************************************************************************/
  public getProductivityPrimaryById(id: any) {
    return this.http
      .get(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_PRIMARY_GET_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Get Productivity Secondary Info By ID *************************************************************************/
  public getProductivitySecondaryById(id: any) {
    return this.http
      .get(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_SECONDARY_GET_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }


  /************************************* Update attendance reject status *************************************************************************/
  public updateProductivityRejectReason(body: any) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_UPDATE_REJECT_REASON, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Status Updated");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /******************************************** edit Productivity ******************************************/
  public editProductivity(body: any) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  // /******************************************** create Productivity ******************************************/
  public createProductivity(body: any) {
    return this.http
      .post(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }
  // /******************************************** create Productivity Secondary ******************************************/
  public createSecondary(body: any) {
    return this.http
      .post(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_SECONDARY, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** create Productivity Primary ******************************************/
  public createPrimary(body: any) {
    return this.http
      .post(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_PRIMARY, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** edit Primary ******************************************/
  public editPrimary(body: any) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_PRIMARY, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** edit Secondary ******************************************/
  public editSecondary(body: any) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_SECONDARY, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /************************************* Delete Secondary *************************************************************************/
  public deleteSecondary(id: any) {
    return this.http
      .delete(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_SECONDARY_DELETE + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Productivity Secondary");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /************************************* Delete Secondary *************************************************************************/
  public deletePrimary(id: any) {
    return this.http
      .delete(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY_PRIMARY_DELETE + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Productivity Secondary");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /************************************* Delete productivity *************************************************************************/
  public deleteProductivity(id: any) {
    return this.http
      .delete(environment.ms_node_base_url + this.ENDPOINT_PRODUCTIVITY + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Productivity");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /************************************* Get Productivity History By ID *************************************************************************/
  public getProductivityHistoryById(productivityId: any) {
    return this.http
      .get(environment.ms_node_base_url + 'ms/v1/productivity-audit-event-histories?productivityId=' + productivityId)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }


  /************************************* Get Productivity Hectare Info By ID *************************************************************************/
  public getHectareInfoById(id: any) {
    let params = new HttpParams();
    if (id !== "" && id != null) {
      params = params.set("prodId", id);
    }
    return this.http
      .get(environment.ms_node_base_url + this.ENDPOINT_GET_PRODUCTIVITY_TOTAL_AREA,
        {
          observe: 'response',
          params: params
        })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public getAllSecondaryWorkType() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_GET_PRODUCTIVITY_SECONDARY_WORK_TYPE, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }


  public getAllSecondaryNameOfWork() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_GET_PRODUCTIVITY_SECONDARY_WORK_NAME, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }


  public getAllMachineUsed() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_GET_PRODUCTIVITY_SECONDARY_MACHINE_USED, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

    /*****************************************Upload  Attchment *********************************************************************/
    public uploadAttachment(id: any, formData: any) {
      const headers = new Headers({});
      headers.append("Authorization", "Bearer " + this.auth.getToken());
      let options = new RequestOptions({ headers: headers });
      return this.httpOnly
        .post(
          environment.base_url + '/attachments/upload?parentType=DYNAMIC_FORM',
          formData,
          options
        )
        .pipe(
          map((resp: any) => {
           
            return resp.json();
          }),
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        );
    }

    public uploadAttachmentByProductivityId(body: any) {
      return this.http.patch(environment.ms_node_base_url + 'ms/v1/productivities/attachments', body, { observe: "response" }).pipe(
          map((resp: any) => {
            this.refreshList$.emit(resp);
            return resp;
          }),
          catchError((error) => {
            //console.log(error);
            return observableOf(error);
          })
        );
    }


}
