import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  private ENDPOINT_PROJECT = "/v4/projects";
  private ENDPOINT_PROJECT_POST = "/projects";
  private ENDPOINT_PROJECT_LIST = "/v4-1/projects";
  private ENDPOINT_PROJECT_PICKLIST = "/projects/pick-list";
  private ENDPOINT_PROJECT_CATEGORIES = "/project-categories";
  private ENDPOINT_PROJECT_TYPES = "/project-types";
  private UPDLOAD_PARENT_TYPE = "PROJECT_ATTACHMENT";
  private ENDPOINT_PROJECT_CONTACTS = "/contacts";
  private ENDPOINT_GET_ATTACHMENTS_BY_PROJECT_ID = "/v4/attachments/project/"

  private selectSubject = new Subject();

  public select(val: any) {
    this.selectSubject.next(val);
  }

  public getSelectItem() {
    return this.selectSubject.asObservable();
  }

  constructor(
    private http: HttpClient,
    private httpOld: Http,
    private auth: AuthenticationService
  ) {}

  public createProject(formData: any) {
    return this.http
      .post(`${environment.base_url}${this.ENDPOINT_PROJECT_POST}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateProject(formData: any) {
    return this.http
      .put(`${environment.base_url}${this.ENDPOINT_PROJECT_POST}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllProjects(
    serverParams: any,
    searchValue: string,
    projectType: any | null,
    serviceZone: any | null,
    categoryId: any | null,
    geofenceId: any | null,
    siteId: any | null,
    customerId: any | null,
    origin: any | null,
  ) {
    let params = new HttpParams();

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    if (projectType !== "" && projectType != null) {
      params = params.set("projectType", projectType);
    }

    if (serviceZone !== "" && serviceZone != null) {
      params = params.set("serviceZoneId", serviceZone);
    }

    if (categoryId !== "" && categoryId != null) {
      params = params.set("categoryId", categoryId);
    }

    if (geofenceId !== "" && geofenceId != null) {
      params = params.set("geofenceId", geofenceId);
    }

    if (siteId !== "" && siteId != null) {
      params = params.set("siteId", siteId);
    }

    if (customerId !== "" && customerId != null) {
      params = params.set("customerId", customerId);
    }

    if (origin !== "" && origin != null) {
      params = params.set("origin", origin);
    }

    if (serverParams) {
      params = params
        .set("startFrom", serverParams.startRow)
        .set("perPage", "25")
        .set("columnName", (serverParams.sortModel[0] as any).colId)
        .set("sortOrder", (serverParams.sortModel[0] as any).sort);
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_PROJECT_LIST}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getProjectById(id: any) {
    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_PROJECT}/${String(id)}`)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteProject(projectId: any) {
    return this.http
      .delete(
        `${environment.base_url}${this.ENDPOINT_PROJECT_POST}/${String(projectId)}`,
        {
          observe: "response",
        }
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllProjectPickList(searchValue: string | null) {
    let params = new HttpParams();

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_PROJECT_PICKLIST}`, {
        observe: "response",
        params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** CATEGORY ******************************************/
  public getAllProjectCategories(
    isActive: boolean | null,
    isOrder: boolean | null
  ) {
    let params = new HttpParams();

    if (isActive !== null && (isActive === true || isActive === false)) {
      params = params.set("isActive", String(isActive));
    }

    if (isOrder !== null && (isOrder === true || isOrder === false)) {
      params = params.set("isOrder", String(isOrder));
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_PROJECT_CATEGORIES}`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createProjectCategory(formData: any) {
    return this.http
      .post(
        `${environment.base_url}${this.ENDPOINT_PROJECT_CATEGORIES}`,
        formData
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateProjectCategory(formData: any) {
    return this.http
      .put(
        `${environment.base_url}${this.ENDPOINT_PROJECT_CATEGORIES}`,
        formData
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteProjectCategory(projectCategoryId: any) {
    return this.http
      .delete(
        `${environment.base_url}${this.ENDPOINT_PROJECT_CATEGORIES}/${String(
          projectCategoryId
        )}`,
        {
          observe: "response",
        }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************** TYPE ******************************************/
  public getAllProjectTypes(isActive: boolean | null) {
    let params = new HttpParams();

    if (isActive !== null && (isActive === true || isActive === false)) {
      params = params.set("isActive", String(isActive));
    }

    return this.http
      .get(`${environment.base_url}${this.ENDPOINT_PROJECT_TYPES}`, {
        observe: "response",
        params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createProjectTypes(formData: any) {
    return this.http
      .post(`${environment.base_url}${this.ENDPOINT_PROJECT_TYPES}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateProjectTypes(formData: any) {
    return this.http
      .put(`${environment.base_url}${this.ENDPOINT_PROJECT_TYPES}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteProjectType(projectTypeId: any) {
    return this.http
      .delete(
        `${environment.base_url}${this.ENDPOINT_PROJECT_TYPES}/${String(
          projectTypeId
        )}`,
        {
          observe: "response",
        }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getListColumn(apiModule: any) {
    return this.http
      .get(
        environment.base_url +
          "/table-column-setups/by-module?apiModule=" +
          apiModule
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public uploadAttachment(id: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });

    return this.httpOld
      .post(
        environment.base_url +
          `/attachments/upload?parentType=${this.UPDLOAD_PARENT_TYPE}&parentId=` +
          id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getProjectContact(projectId: any | null) {
    let params = new HttpParams();

    if (projectId !== null && projectId !== "") {
      params = params.set("projectId", String(projectId));
    }

    return this.http
      .get(
        `${environment.base_url}${this.ENDPOINT_PROJECT_CONTACTS}/by-project`,
        {
          observe: "response",
          params: params,
        }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createProjectContact(formData: any) {
    return this.http
      .post(
        `${environment.base_url}${this.ENDPOINT_PROJECT_CONTACTS}`,
        formData
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateProjectContact(formData: any) {
    return this.http
      .put(`${environment.base_url}${this.ENDPOINT_PROJECT_CONTACTS}`, formData)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getProjectTasks(siteId: any) {
    return this.http
      .get(`${environment.base_url}/projects/${siteId}/tasks`)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          } else {
            throwError(resp);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

    /************************************* Get Project Attachments Info By ID *************************************************************************/
    public getAttachmentsByProjectId(id: any) {
      let params = new HttpParams();
      params = params.set("projectId", id);
      return this.http
        .get(environment.base_url + this.ENDPOINT_GET_ATTACHMENTS_BY_PROJECT_ID,
          {params : params})
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          catchError((error) => {
            ////console.log(error);
            return throwError(error);
          })
        );
    }
}
