<ag-grid-angular
#agGrid
style="height: 100%;width: 100%;"
class="ag-theme-balham"

[columnDefs]="columnDefs"
[gridOptions]="gridOptions"
[defaultColDef]="defaultColDef"
[paginationPageSize]="25"
[pagination]="false"
[cacheOverflowSize]="2"
[suppressDragLeaveHidesColumns]="true"
[maxConcurrentDatasourceRequests]="-1"
[allowContextMenuWithControlKey]="false"
[frameworkComponents]="frameworkComponents"
(cellClicked)="viewTasks($event)"
(gridReady)="onGridReadyTask($event)"
[overlayNoRowsTemplate]="overlayNoRowsTemplate"
[getContextMenuItems]="getContextMenuItems"
></ag-grid-angular>
<div class="col-12"><p class="ag-record-row-summary-panel padds text-end" id="label_TaskTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p></div>
