<div>
    <div class="flex-between">
      <i class="fa-regular fa-xmark w-24px dialog-close-icon" id="btn_ProjectsClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="closeTable()"></i>
    </div>
    <div class="position-relative text-start">
      <span class="title" id="label_ProjectsSelectList"> {{translate?.general.commonComponent.sections.sec4.singular}}</span>
      <div class="d-flex align-items-center mt-2">
        <span class="p-input-icon-left w-100 py-2">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText autocomplete="off"
            id="input_ProjectsSelectListField"
            [placeholder]="translate?.general.buttons.search.singular"
            [(ngModel)]="searchValue"
          />
        </span>
      </div>
      <div class="height-42-stat border" *ngIf="translate">
          <app-projects-table [translate]="translate" [searchValue]="searchValue" [translate]="translate"></app-projects-table>
      </div>
    </div>
  </div>