import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { PasswordActivationComponent } from "./password-activation/password-activation.component";
import { ProfileInfoComponent } from "./profile-info/profile-info.component";
import { PublicComponent } from "./public.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ConnectGoogleCalenderSuccessComponent } from "./google-calendar/connect-google-calender-success/connect-google-calender-success.component";
import { CalendlyThemeComponent } from "./calendly-theme/calendly-theme.component";
import { CustomerSurveyComponent } from "./customer-survey/customer-survey.component";
import { PreviewCustomerSurveyComponent } from "./preview-customer-survey/preview-customer-survey.component";
import { ConnectComponent } from "./sales-force/connect/connect.component";
import { SuccessComponent } from "./sales-force/success/success.component";
import { FailureComponent } from "./sales-force/failure/failure.component";
import { LoginFromPhoneComponent } from "./login-from-phone/login-from-phone.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "",
    component: PublicComponent,
    children: [
      {
        path:'resetpassword/:id',
        component: ResetPasswordComponent,
        pathMatch : 'prefix'
      },
      {
        path: "login",
        component: LoginComponent,
        pathMatch: "full",
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        pathMatch: "full",
      },

      {
        path: "activate-user/:id",
        component: PasswordActivationComponent,
        pathMatch: "full",
      },
      {
        path: "profile-info",
        component: ProfileInfoComponent,
        pathMatch: "full",
      },
      {
        path: "mobile",
        component: LoginFromPhoneComponent,
        pathMatch: "full",
      },
      {
        path: "schedule",
        component: CalendlyThemeComponent,
        pathMatch: "full",
      },
      {
        path: 'google-calender/connect',
        component: ConnectGoogleCalenderSuccessComponent,
        pathMatch: 'full',
        data: { page: 'google' }
      },
      {
        path: 'google-calender/success',
        component: ConnectGoogleCalenderSuccessComponent,
        pathMatch: 'full',
        data: { page: 'google' }
      },
      {
        path: 'google-calender/failure',
        component: ConnectGoogleCalenderSuccessComponent,
        pathMatch: 'full',
        data: { page: 'google' }
      },
      {
        path: 'microsoft-calender/success',
        component: ConnectGoogleCalenderSuccessComponent,
        pathMatch: 'full',
        data: { page: 'microsoft' }
      },
      {
        path: 'microsoft-calender/failure',
        component: ConnectGoogleCalenderSuccessComponent,
        pathMatch: 'full',
        data: { page: 'microsoft' }
      }, 
      {
        path: 'calendly/success',
        component: ConnectGoogleCalenderSuccessComponent,
        pathMatch: 'full',
        data: { page: 'calendly' }
      },
      {
        path: 'calendly-calender/failure',
        component: ConnectGoogleCalenderSuccessComponent,
        pathMatch: 'full',
        data: { page: 'calendly' }
      },
      {
        path: "customerSurvey",
        component: CustomerSurveyComponent,
        pathMatch: "full",
      },
      {
        path: "preview-customer-survey",
        component: PreviewCustomerSurveyComponent,
        pathMatch: "full",
      },
      {
        path: 'salesforce/connect',
        component: ConnectComponent,
        pathMatch: 'full'
      },
      {
        path: 'salesforce/success',
        component: SuccessComponent,
        pathMatch: 'full'
      },
      {
        path: 'salesforce/failure',
        component: FailureComponent,
        pathMatch: 'full'
      },

      
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
