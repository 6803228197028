import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridParams,
  IGetRowsParams,
} from "ag-grid-community";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { ServerRequest } from "src/app/_models/global.data.model";
import { ProjectService } from "src/app/_services/project.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { Global } from "src/app/_utilities/global";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-projects-table",
  templateUrl: "./projects-table.component.html",
  styleUrls: ["./projects-table.component.scss"],
})
export class ProjectsTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy, OnChanges
{
  // table
  public frameworkComponents!: any;
  public gridOptions!: GridOptions;
  public defaultColDef!: ColDef;
  public columnDefs: any[] = [];
  private gridParams!: GridParams;
  private gridApi!: GridApi;
  public overlayNoRowsTemplate :any

  public totalRecords: any;

  public noData = false;

  @Input() searchValue = "";
  @Input() translate: any;
  loadTable: any = 0;
  public allProjectType: any[] = [];
  public allServiceZones: any[] = [];
  public allProjectCategorys: any[] = [];
  public allGeofences: any[] = [];
  public allSites: any[] = [];
  additionalData: any;
  sortBy: any;
  sortOrder: any;

  constructor(
    private util: UtilServiceService,
    private projectService: ProjectService,
    private errorUtil: ErrorUtil,
    private dynamicTableColumnsService:DynamicTableColumnsService,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService
  ) {
    super();

    this.preInit();
  }
  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.searchValue.currentValue !== changes.searchValue.previousValue
    ) {
      this.refresh();
    }

    if (
      changes.additionalData &&
      changes.additionalData.currentValue !==
        changes.additionalData.previousValue
    ) {
      this.refresh();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.push(
      this.projectService.getListColumn("project").subscribe((resp) => {
        if (resp && resp.tableColumnSetups) {
          this.sortBy = resp.sortBy
          this.sortOrder = resp.sortOrder
          var columnDefs: any = this.gridOptions.columnDefs;
          resp.tableColumnSetups.forEach((element: any) => {
            this.cellRenderer(element);
      
            columnDefs.push(element);
            this.gridOptions.api!.setColumnDefs(columnDefs);
          });
         
          this.loadTable = 1;
          this.overlayNoRowsTemplate ="<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
          this.onGridReady(this.gridParams);
        }
      })
    );
  }

  /******************************************** Init ******************************************/
  private init() {}

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

 
  /******************************************** Configure Columns ******************************************/
  private cellRenderer(tc: any) {
    
    console.log(this.additionalData);
if(tc.translatePath){
      tc.headerName = this.languageTranslateService.getNestedValue(this.translate, tc.translatePath);
     } 

     tc.headerTooltip = "#";
    let from = this.additionalData?.data.from;

      if (tc.field === "projectNumber") {
        tc.headerComponentParams =  Global.setHeaderComponentParams(tc,'Tbl_HeadProjNo'),
        tc.cellRenderer = function (params: any) {
          if(params.data){
            return  Global.setCellRenderer(params,params.data.rowId, params.data.projectNumber)
          }
        };

      }

      if (tc.field === "customer.name") {
        tc.headerComponentParams =  Global.setHeaderComponentParams(tc,'Tbl_HeadProjCust'),
        tc.cellRenderer = function (params: any) {
          if(params.data){
            return  Global.setCellRenderer(params,params.data.rowId2, params.data.customer?.name)
          }
        };

        // if (from === "customer") {
        //   column.hide = true;
        // }
      }


      if (tc.field === "active") {
        tc.cellRenderer = (params: any) =>{
          if (params.data) {
            if (params.data.active) {
              return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value">'+this.translate?.general.status.active.singular+ '</span></span>';
            } else {
              return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">'+this.translate?.general.status.inactive.singular+'</span></span>';
            }

          }
        };
      }

      // if (tc.field === "project_status.name") {
      //   column.hide = true;
      // }

      // if (tc.field === "projectStatus.name") {
      //   column.hide = true;
      // }
      var dateFormat: any = localStorage.getItem("date_format");
      if (tc.dataType == 'date'  || tc.dataType == "text" || tc.dataType == "datetime") {
       
        tc = Global.setColumnByDataType(tc,this.auth.getUserTimezone(),dateFormat)
        console.log(tc,"=====>>>>");
       }
      
  
  }


 
  /******************************************** On Table Ready ******************************************/
  public onGridReady(params: any) {
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();

      var dataSource = {
        getRows: (params: IGetRowsParams) => {
          if (this.loadTable == 1) {
            this.getServerSideData(params);
            }
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** Get Data ******************************************/
  private getServerSideData(agGridGetRowsParams: IGetRowsParams) {
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any=serverRequest.startRow;

    this.push(
      this.projectService
        .getAllProjects(
          serverRequest,
          this.searchValue,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        )
        .subscribe(
          (resp) => {
            if (resp) {
              resp.data.forEach((element: any) => {
                count++
                element.rowId='Tbl_RecProjNo'+count
                element.rowId2='Tbl_RecProjCust'+count
                element.buttonEdit='Btn_ProjEdit'+count
                element.buttonDelete='Btn_ProjDel'+count
              });

              this.setGridData(
                agGridGetRowsParams,
                resp.data,
                resp.total,
                resp.status
              );
            }
          },
          (err) => {
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
  }

  /******************************************** Get Params ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      const parameters = { colId: this.sortBy, sort: this.sortOrder }
      agGridRequest.sortModel.push(parameters);
    }

    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** Set Data ******************************************/
  private setGridData(
    agGridGetRowsParams: IGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    this.gridApi.hideOverlay();

    this.totalRecords = totalCount;

    if (status === "SEARCH_NO_RESULTS") {
      this.noData = true;
      this.gridApi.showNoRowsOverlay();
    } else {
      this.noData = false;

      if (resultItems.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        console.log(totalCount);
        agGridGetRowsParams.successCallback(resultItems, totalCount);
      }

      ///CS-4758 this.gridApi.sizeColumnsToFit();
    }

  }

  /******************************************** Refresh Table ******************************************/
  public refresh() {
    this.onGridReady(this.gridParams);
  }

 


  /******************************************** View Cell ******************************************/
  public viewCell($event: any) {
    const field = $event.colDef.field;

    if (field === "projectNumber") {
      this.projectService.select($event.data);
    }
  }

  /******************************************** Table Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;

    return this.dynamicTableColumnsService.rightClick(params);
  };

  /******************************************** Returns Filter Object ******************************************/
  private returnFilterObject() {
    return {
      type: null,
      service_zone: null,
      category_type: null,
      geofence: null,
      site: null,
    };
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }
}
