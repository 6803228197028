import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { HELPDESK_APP_EVENTS } from "src/app/_models/global.data.model";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-helpdesk-tabs",
  templateUrl: "./helpdesk-tabs.component.html",
  styleUrls: ["./helpdesk-tabs.component.scss"],
})
export class HelpdeskTabsComponent implements OnInit {
  private subscriptionCollection: Subscription[] = [];

  public allTabs = [
    {
      name: "all_my_tickets",
      closable: false,
      active: true,
      type: "main",
      metaData: {},
    },
  ];

  public currentTab = "all_my_tickets";
  private maxTabs = 10;
  private helpdeskTabElement = null;
  private position = null;

  constructor(private utilService: UtilServiceService) {}

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit(): void {
    // this.initDragToScroll();
  }

  ngOnDestroy(): void {
    this.cleanUp();
  }

  private init() {
    const sub = this.utilService.getData().subscribe((data: any) => {
      if (data) {
        if (data.action === HELPDESK_APP_EVENTS.GET_TICKET_MENU) {
          this.clearActiveAllTabs();
          this.updateCurrentMainTab(data.data.name);
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_SEARCH_RESULT) {
          const result = data.data;
          this.processForTab(result);
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_TICKET_CLICK) {
          const tabData = data.data;

          this.processForTab(tabData);
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_DELETE_TICKET) {
          const result = data.data;
          this.deleteTab("tickets", result);
        }
      }
    });

    this.subscriptionCollection.push(sub);
  }

  private cleanUp() {
    this.subscriptionCollection.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private deleteTab(type: string, data: any) {
    const id = data.id;

    for (let at = 0; at < this.allTabs.length; at++) {
      const metaData = this.allTabs[at].metaData as any;

      if (
        this.allTabs[at].type === type &&
        metaData.hasOwnProperty("id") &&
        metaData.id === id
      ) {
        this.allTabs.splice(at, 1);
      }
    }

    const previousTab = this.allTabs[this.allTabs.length - 1];

    // set previous as active tab
    this.onTabClick(previousTab);
  }

  // only update the first tab
  private updateCurrentMainTab(tabName: string) {
    this.currentTab = tabName;

    this.allTabs[0].name = tabName;
    this.allTabs[0].active = true;
  }

  // on search result tap enter or clicked on
  private processForTab(searchResult: any) {
    const newTab = this.buildTheTab(searchResult);

    this.addToTabs(newTab).then(() => {
      this.executeTab(newTab);
    });
  }

  // execute tab on what it is and its operations
  private executeTab(tab: any): void {
    const type = tab.type as string;
    const metaData = tab.metaData;

    if (type === "main") {
      // call ticket menu to find active and update table
      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_TAB_CHANGE,
        data: {
          name: tab.name,
        },
      });
    }

    if (type === "tickets") {
      const id = metaData.id;

      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_VIEW_TICKET,
        data: { id },
      });
    }

    if (type === "search") {
      // call table to set up search
      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_TAB_CHANGE,
        data: {
          type: "search",
          metaData,
        },
      });
    }
  }

  /** Build Tab
   *  - builds the tab, and return the new tab
   * - for all components that send there data refer to this object type
   *  {
      item: 'test-123'
      type: 'tickets | search'
      metaData: {
        // any meta data
        id: <id-value> etc..
      }
      };
   *
   */
  private buildTheTab(data: any): any {
    const type = data.type as string;
    const metaData = data.metaData;

    let newTab = {
      name: data.item,
      closable: true,
      active: true,
      type,
      metaData: metaData,
    };

    if (type === "tickets") {
      newTab.name = "#" + data.item;
    }

    if (type === "search") {
      newTab.name = `Search: "${data.item}"`;
    }

    return newTab;
  }

  // add to tabs
  /*
      {
      name: 'queue',
      closable: true, //required
      active: true,
      metaData: {}
      },
  */
  private addToTabs(newTab: any): Promise<void> {
    return new Promise((resolve) => {
      // check if such tab exist on all tabs
      let index = null;

      if (newTab.type === "tickets") {
        index = this.allTabs.findIndex((tab) => tab.name === newTab.name);
      }

      if (newTab.type === "search") {
        index = this.allTabs.findIndex((tab) => tab.type === newTab.type);
      }

      this.clearActiveAllTabs();

      if (index === -1) {
        if (this.allTabs.length < this.maxTabs) {
          this.allTabs.push(newTab);
        } else {
          // if more than stated max tabs assign it first ticket
          for (let at = 0; at < this.allTabs.length; at++) {
            if (this.allTabs[at].type === "tickets") {
              this.allTabs[at] = newTab;
              break;
            }
          }
        }
      } else {
        if (index !== null) {
          // if same tab of result active that tab
          this.allTabs[index].active = true;

          // replace old with new
          if (newTab.type === "tickets" || newTab.type === "search") {
            this.allTabs[index] = newTab;
          }
        }
      }

      resolve();
    });
  }

  // closes tab
  public closeTab(name: any): void {
    for (let at = 0; at < this.allTabs.length; at++) {
      if (this.allTabs[at].name === name) {
        if (this.allTabs[at].type === "tickets") {
          const metaData = this.allTabs[at].metaData as any;
          // tell tickets view to be removed
          this.utilService.sendData({
            action: HELPDESK_APP_EVENTS.ON_CLOSE_TAB_TICKET,
            data: {
              id: metaData.id,
            },
          });
        }

        this.allTabs.splice(at, 1);
        break;
      }
    }

    const previousTab = this.allTabs[this.allTabs.length - 1];

    // set previous as active tab
    this.onTabClick(previousTab);
  }

  // set all tabs to active false
  private clearActiveAllTabs() {
    this.allTabs.forEach((tab) => (tab.active = false));
  }

  // on tab click and set that tab to true
  public onTabClick(tab: any) {
    this.clearActiveAllTabs();

    this.executeTab(tab);

    tab.active = true;
  }

  // ref https://github.com/1milligram/html-dom/blob/master/public/demo/drag-to-scroll/index.html
  // public initDragToScroll() {
  //   this.helpdeskTabElement = document.querySelector('#heldesk-tab');
  //   this.helpdeskTabElement.style.cursor = 'grab';

  //   this.position = { top: 0, left: 0, x: 0, y: 0 };

  //   this.helpdeskTabElement.addEventListener('mousedown', this.mouseDown.bind(this));
  // }

  // private mouseDown(e) {
  //   console.log(this);
  //   console.dir(this.helpdeskTabElement)

  //   this.position = {
  //     left: this.helpdeskTabElement.scrollLeft,
  //     top: this.helpdeskTabElement.scrollTop,
  //     // Get the current mouse position
  //     x: e.clientX,
  //     y: e.clientY,
  // };

  //   this.helpdeskTabElement.addEventListener('mousemove', this.mouseMove);
  //   this.helpdeskTabElement.addEventListener('mouseup', this.mouseUp);
  // }

  // private mouseMove(e) {
  //   const dx = e.clientX - this.position.x;
  //   const dy = e.clientY - this.position.y;

  //   this.helpdeskTabElement.scrollTop = this.position.top - dy;
  //   this.helpdeskTabElement.scrollLeft = this.position.left - dx;
  // }

  // private mouseUp() {
  //   this.helpdeskTabElement.style.cursor = 'grab';
  //   this.helpdeskTabElement.style.removeProperty('user-select');

  //   this.helpdeskTabElement.removeEventListener('mousemove', this.mouseMove);
  //   this.helpdeskTabElement.removeEventListener('mouseup', this.mouseUp);
  // }
}
