// Author: T4professor

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-status-renderer",
  template: `<span
    *ngIf="value"
    class="p-mr-2 p-tag p-component cursor-edit"
    [attr.style]="
      'background-color:' + colorCode + '50 !important;' + 'color:' + colorCode
    "
    ><span class="p-tag-value text-dark">{{ label }}</span></span
  >`,
})
export class StatusRendererComponent implements ICellRendererAngularComp {
  params: any;
  value: any;
  colorCode: any;
  statusList: any = null;
  label = "";

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.statusList = JSON.parse(localStorage.getItem("joStatuses")! ?? "[]");

    if (params.value != null) {
      if (params.value.fieldLabel == undefined) {
        this.statusList.forEach((element: any) => {
          if (String(params.value) == String(element.name)) {
            this.value = element;
            this.colorCode = this.value.colorCode ? this.value.colorCode : "";
            this.label = this.value.fieldLabel;
          }
        });
      } else {
        this.value = params.value;
        this.colorCode = this.value.colorCode ? this.value.colorCode : "";
        this.label = this.value.fieldLabel;
      }
    }
  }

  refresh(params?: any): boolean {
    return true;
  }
}
