<div class="wrapper" [ngClass]="{'layout-light':app.lightMenu,
                                'layout-sidebar-active':menuInactiveDesktop,
                                'collaps-sidebar-active':collapsMenuActive,
                                'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}">
    <app-top-header></app-top-header>

    <div class="sidebar" (mouseover)="getMouseEvent($event)" (mouseout)="getMouseEventOut($event)">
        <div class="d-flex px-3 align-items-center justify-content-between logo"  style="padding-top: 7px; padding-bottom: 12px; position: fixed; background-color: #1a2428; z-index: 100;">
            <div class="d-flex align-items-center">
                <!-- <img src="../../assets/svg/header/FieldEx_White.svg" class="position-relative mt-2 menuText"> -->
                <img src="../../assets/svg/header/Fieldex_F.svg" class="position-relative mt-2 w-28px">
                <img src="../../assets/svg/header/FieldEx.svg" class="position-relative mt-2 ms-2 w-60px menuText">
                <!-- <label class="font-medium mt-2 ms-2 text-white fs-5 menuText">FieldEx</label> -->
            </div>
            <img *ngIf="!menuInactiveDesktop"  src="assets/svg/lock-key.svg" alt="" class="w-24px position-relative mt-2 text-white subMenu cursor-pointer" (click)="toggleMenu($event)">
            <img *ngIf="menuInactiveDesktop"  src="assets/svg/lock-key-open.svg" alt="" class="w-24px position-relative mt-2 text-white subMenu cursor-pointer" (click)="toggleMenu($event)">

            <!-- <i class="pi  position-relative mt-2 text-white subMenu" [ngClass]="{'pi-lock': !menuInactiveDesktop,'pi-lock-open': menuInactiveDesktop}" (click)="toggleMenu($event)"></i> -->
        </div>
        <div class="sidebar-scroll-content" style="padding-top: 55px">
            <app-left-menu></app-left-menu>
           <!-- <div class="bottom-menu gap-4">
                <div>
                    <span class="icon" pTooltip="Setup" tooltipPosition="top">
                        <i class="fa-light fa-gear-complex fa-xl"></i>
                    </span>
                </div>
                <div>
                    <span class="icon" pTooltip="Academy" tooltipPosition="top">
                        <i class="fa-light fa-book-open-cover fa-xl"></i>
                    </span>
                </div>
                <div>
                    <span class="icon" pTooltip="Contact Us" tooltipPosition="top">
                        <i class="fa-light fa-user-headset fa-xl"></i>
                    </span>
                </div>
           </div> -->
        </div>
    </div>
    <div class="main" id="main">
        <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" template="<img src='assets/images/dots.svg' />" ></ngx-spinner> -->
        <ngx-spinner *ngIf="!menuInactiveDesktop" class="unlock-Spinner" bdColor = "rgb(255 255 255 / 76%)" size = "medium" color = "#6366f1" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > </p></ngx-spinner>
        <ngx-spinner *ngIf="menuInactiveDesktop" class="lock-Spinner" bdColor = "rgb(255 255 255 / 76%)" size = "medium" color = "#6366f1" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > </p></ngx-spinner>
        <!-- Breadcrumb -->
        <!-- <div class="custella-stickey-breadcrumb" *ngIf="items.length !=0">
            <p-breadcrumb [model]="items"></p-breadcrumb>
        </div> -->

        <app-navi-bar></app-navi-bar>

        <router-outlet  ></router-outlet>
    </div>

   <app-comman-delete *ngIf="deleteData" [data]="deleteData"></app-comman-delete> 
   <app-confirm-action *ngIf="confirmdata" [data]="confirmdata"></app-confirm-action>

</div>
