import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UtilServiceService } from "./utilService/util-service.service";

@Injectable({
  providedIn: "root",
})
export class HelpdeskService {
  private rootUrl = "/helpdesk/";

  private endPoints = {
    count: "count",
    searchAll: "search-all",
    searchTickets: "search-tickets",
    ticketOwner: "ticket-owner",
    ticketView: "ticket-view",
    helpDeskUser: "users/active/help-desk",
  };

  private keepRowData: any;

  constructor(
    private http: HttpClient,
    private utilService: UtilServiceService
  ) {}

  /** Gets Ticket Count
   *
   * @returns {Observable<any>}
   */
  public getTicketCount(): Observable<any> {
    return this.http
      .get(environment.base_url + this.rootUrl + this.endPoints.count)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /** Get Help Desk Tickets
   * Pass in paramObject
   * Example
   * {
   *  startFrom: 0,
   *  sortOrder: 'asc'
   * }
   *
   * @param paramObj
   * @returns Observable
   */
  public getHelpDeskTicketsBySearch(paramObj: any): Observable<any> {
    let fullParams = "";

    fullParams = this.utilService.returnGeneratedParams(paramObj);

    return this.http
      .get(
        environment.base_url +
          this.rootUrl +
          this.endPoints.searchTickets +
          fullParams
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getHelpDeskSearchData(paramObj: any): Observable<any> {
    let fullParams = "";

    fullParams = this.utilService.returnGeneratedParams(paramObj);

    return this.http
      .get(
        environment.base_url +
          this.rootUrl +
          this.endPoints.searchAll +
          fullParams
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /** General Search Item
   *  - whether it be tickets, assets, customers or joborders
   * - make sure you pass in that item string to search of what
   *
   * Example searchAPI
   * "assets" --> becomes "search-assets"
   *
   * Example paramObj
   * {
   *  startFrom: 0,
   *  sortOrder: 'asc'
   * }
   *
   * @param paramObj
   * @returns Observable
   */
  public generalSearchItem(searchAPI: string, paramObj: any): Observable<any> {
    let fullParams = "";
    let search = "search-";

    if (searchAPI === "customers") {
      search += "customer";
    } else if (searchAPI === "jobOrders") {
      search += "jo";
    } else {
      search += searchAPI;
    }

    fullParams = this.utilService.returnGeneratedParams(paramObj);

    return this.http
      .get(environment.base_url + this.rootUrl + search + fullParams)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /** Set Assign / Claim to a Ticket
   * - give param objects prop value of id - ticket
   * - give param object prop value of userId - the person to assign to
   * - -- if userId prop value is not included then it auto assigns to logged in user
   *
   * - example -
   * --- assign
   * {
   *  id: 29,
   *  userId: 100
   * }
   *
   * --- claim
   * {
   *  id: 29
   * }
   *
   * @param paramObj
   * @returns
   */
  public setAssignClaimToTickets(paramObj: any) {
    let fullParams = "";

    fullParams = this.utilService.returnGeneratedParams(paramObj);

    return this.http
      .put(
        environment.base_url +
          this.rootUrl +
          this.endPoints.ticketOwner +
          fullParams,
        {}
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getHelpdeskUsers() {
    return this.http
      .get(environment.base_url + "/v2/" + this.endPoints.helpDeskUser)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getHelpdeskUsersByUserGroup(userGroupId: number | string) {
    return this.http
      .get(
        environment.base_url +
          "/v2/" +
          this.endPoints.helpDeskUser +
          `?userGroupId=${userGroupId}`
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /** View A Ticket
   * - pass in the id as a parameter
   *
   * {
   *  id: <ticket-id>
   * }
   *
   * @param paramObj
   * @returns
   */
  public viewATicket(paramObj: any) {
    let fullParams = "";

    fullParams = this.utilService.returnGeneratedParams(paramObj);

    return this.http
      .put(
        environment.base_url +
          this.rootUrl +
          this.endPoints.ticketView +
          fullParams,
        {}
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /** Save Row Data
   * - saves the row data
   *
   * @param rowData
   */
  public saveRowData(rowData: any, index: any): void {
    this.keepRowData = {
      rowData,
      index,
    };
  }

  /** Get ROW DATA
   *
   * @returns rowData
   */
  public getRowData() {
    if (this.keepRowData !== null) {
      return this.keepRowData;
    } else {
      throw new Error("ROW DATA MISSING");
    }
  }
}
