<form class="custella-custom-dialog-no">
  <div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
      <img src="assets\svg\dialog\Confirmation-w.svg">  
      <span class="text-16 font-medium ms-3" id="title_VerifyRequis">{{title}}</span>
    </div>
    <i class="fa-regular fa-xmark" id="btn_VerifyRequisClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="bottom" (click)="modalRef.hide()"></i>
  </div>
  <div class="modal-body">
    <div class="container">   
      <div class="custella-content-row-input col-12 mb-3">
        <span class="custella-content-row-input-title" id="label_VerifyRequisComment">{{translate?.requisition.fields.verificationComment.singular}}</span>
        <textarea class="form-control txt-a" rows="3" id="input_VerifyRequisCommentField" [(ngModel)]="comments" [maxlength]="320000" [ngModelOptions]="{standalone: true}"></textarea>  
      </div>                 
      <div class="custella-content-row-input col-12 mb-3" *ngIf="data.type=='Approve'">
        <span class="custella-content-row-input-title" id="label_VerifyPicToNotify">{{translate?.requisition.fields.picToNotify.singular}}</span>
        <p-dropdown [disabled]="data.type=='Reject' && submitBtnDisabled"
          id="picklist_VerifyPicToNotifyField"
          [ngModelOptions]="{standalone: true}" 
          name="picToNotify"  
          [(ngModel)]="picToNotify"
          optionLabel="fullName" 
          class="w-100" [filter]="true" 
          name="company" [options]="users" [showClear]="true" optionValue="id"
          [placeholder]="translate?.general.messages.none">
        </p-dropdown>
      </div>
      <div class="custella-content-row-input col-12" *ngIf="data.type=='Approve'">
        <div class="d-flex position-relative align-items-center">
          <div class="p-field-checkbox">
            <p-checkbox [binary]="true" id="check_SubmitToHod" [disabled]="data.type=='Reject' && submitBtnDisabled" [(ngModel)]="submitToHod"  [ngModelOptions]="{standalone: true}"></p-checkbox>
          </div>
          <span class="text-12 ms-2 mt-1" id="label_SubmitToHod">{{translate?.requisition.fields.submitToHod.singular}}</span>
        </div> 
      </div>
    </div>
  </div>
  <div class="modal-footer p-0">
    <button class="custella-btn-white ms-2 text-12" id="btn_VerifyRequisCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
    
    <p-button *ngIf="data.type=='Approve'" type="button" id="btn_VerifyRequisApprove" (onClick)="approve()" styleClass="ms-2 h-32 p-button-success" [label]="translate?.general.buttons.approve.singular"></p-button>
    <p-button *ngIf="data.type=='Reject'" type="button" id="btn_VerifyRequisReject" (onClick)="reject()" styleClass="ms-2 h-32 p-button-danger" [label]="translate?.general.buttons.reject.singular"></p-button>
    <p-button *ngIf="data.type=='HODApprove'" type="button" (onClick)="approve1()" id="btn_VerifyRequisHodApprove" styleClass="ms-2 h-32 p-button-success" [label]="translate?.general.buttons.approve.singular"></p-button>
    <p-button *ngIf="data.type=='HODReject'" type="button" (onClick)="reject1()" id="btn_VerifyRequisHodReject" styleClass="ms-2 h-32 p-button-danger" [label]="translate?.general.buttons.reject.singular"></p-button>
    <p-button *ngIf="data.type=='consignmentApprove'" type="button" (onClick)="approve2()" id="btn_VerifyRequisConsignmentApprove" styleClass="ms-2 h-32 p-button-success" [label]="translate?.general.buttons.approve.singular"></p-button>
    <p-button *ngIf="data.type=='consignmentReject'" type="button" (onClick)="reject2()" id="btn_VerifyRequisConsignmentReject" styleClass="ms-2 h-32 p-button-danger" [label]="translate?.general.buttons.reject.singular"></p-button>
  </div>
</form>
