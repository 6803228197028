import { Component, OnDestroy, OnInit } from "@angular/core";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-not-found404",
  templateUrl: "./not-found404.component.html",
  styleUrls: ["./not-found404.component.scss"],
})
export class NotFound404Component
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  translate: any;

  constructor(private languageTranslateService: LanguageTranslateService) {
    super();

    this.getLanguageData();
  }

  ngOnInit(): void {}

  getLanguageData() {
    // this.spinner.show()
    this.push(
      this.languageTranslateService.wordSrc$.subscribe((data) => {
        if (Object.keys(data).length != 0) {
          this.translate = data;
        }
      })
    );
    console.log("translate", this.translate);
    //this.mainLabel = this.translate?.site.label.plural
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
