import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';


@Component({
  selector: 'app-common-dialog-box',
  templateUrl: './common-dialog-box.component.html',
  styleUrls: ['./common-dialog-box.component.scss'],
  providers: [ConfirmationService]
})
export class CommonDialogBoxComponent extends SubscriptionUtil implements OnInit, OnDestroy, OnChanges {


  @Input() visible: boolean = false;
  @Input() data: any;
  module: any;
  addForm!: UntypedFormGroup;
  recordNumber: any;
  actionStatus: any;
  translatedActionStatus: any;
  multipleDataAcceptTitle: any;
  actionDescription: boolean = false;
  multipleDataAccept: any;
  headerTitle: any;
  assetData: any
  @Output() visibleOut = new EventEmitter();
  @Output() onClickYes = new EventEmitter();
  description: any;
  action: any;
  message = "Are you sure?"
  draggable:boolean= false
  translate: any;

  constructor(private confirmationService: ConfirmationService,
    private languageTranslateService: LanguageTranslateService
  ) { 
        super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.data)
    if (this.data && this.data.multipleDataAccept == "MULTI") {
      console.log("true");
      this.module = this.data.module
      this.actionStatus = this.data.actionStatus
      if(this.actionStatus == "APPROVED"){ this.translatedActionStatus = this.translate?.general.status.approved.singular}
      if(this.actionStatus == "REJECT"){ this.translatedActionStatus = this.translate?.general.status.reject.singular}
      this.multipleDataAccept = this.data.multipleDataAccept
      this.multipleDataAcceptTitle = this.data.multipleDataAcceptTitle
      this.headerTitle = this.data.headerTitle
    }
    else if (this.data && this.data.multipleDataAccept === "SINGLE") {
      this.module = this.data.module
      this.recordNumber = this.data.recordNumber
      this.actionStatus = this.data.actionStatus
      if(this.actionStatus == "APPROVED"){ this.translatedActionStatus = this.translate?.general.status.approved.singular}
      if(this.actionStatus == "REJECT"){ this.translatedActionStatus = this.translate?.general.status.reject.singular}
      this.actionDescription = this.data.actionDescription
      this.multipleDataAccept = this.data.multipleDataAccept
      this.headerTitle = this.data.headerTitle
    }
    // else if (this.data && this.data.multipleDataAccept === "ASSET STATUS") {
    
    //   this.assetData = this.data
    // }
    if(this.actionStatus == "REJECT")
    {
      console.log("Status")
      this.addForm.controls["rejectReason"].setValidators([ Validators.required ]);
      this.addForm.controls["rejectReason"].updateValueAndValidity();
      //this.addForm.controls.rejectReason.enable()
    }


  }

  form() {
    this.addForm = new UntypedFormGroup({
      rejectReason: new UntypedFormControl(null),
    });
  }

  ngOnDestroy(): void {
    this.visible = false;
    this.data = null;
    this.module = null;
    this.recordNumber = null;
    this.actionStatus = null;
    this.actionDescription = false;
    this.multipleDataAccept = false;
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    console.log(this.data);
    // this.confirm1();
   this.push(this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    }))
    this.form();
  }



  onAfterHide() {
    this.visibleOut.emit(false);
    this.visible = false;
  }
  
  onClickAction(action: any) {
    console.log("clicked")
    if (action == 'yes') {

      if (this.actionStatus == "REJECT" && !this.addForm.valid) {
        for (let i in this.addForm.controls) {
          if (this.addForm.controls[i].invalid) {
          }
          this.addForm.controls[i].markAsTouched();
        }
        return false;
      }

      if (this.module == "Visits") {
        console.log("module")
        let data = { id: "", rejectReason: "", actionStatus: "" };
        data.id = this.data.recordNumber;
        this.description ? data.rejectReason = this.description : null;
        data.actionStatus = this.actionStatus
        this.onClickYes.emit(data);
      }
      else if (this.module == "Attendance") {
        console.log("module Attendance")
        let data = { id: "", rejectReason: "", actionStatus: "" };
        data.id = this.data.recordNumber;
        this.description ? data.rejectReason = this.description : null;
        data.actionStatus = this.actionStatus
        this.onClickYes.emit(data);
      }
      else if (this.module == "Overtime") {
        console.log("module Attendance")
        let data = { id: "", rejectReason: "", actionStatus: "" };
        data.id = this.data.recordNumber;
        this.description ? data.rejectReason = this.description : null;
        data.actionStatus = this.actionStatus
        this.onClickYes.emit(data);
      }
      else if (this.module == "Productivity") {
        console.log("module Productivity")
        let data = { id: "", rejectReason: "", actionStatus: "" };
        data.id = this.data.recordNumber;
        this.description ? data.rejectReason = this.description : null;
        data.actionStatus = this.actionStatus
        this.onClickYes.emit(data);
      }
      else if (this.module == this.translate?.leave.label.plural) {
        console.log("module Leaves")
        let data = { id: "", rejectReason: "", actionStatus: "" };
        data.id = this.data.recordNumber;
        this.description ? data.rejectReason = this.description : null;
        data.actionStatus = this.actionStatus
        this.onClickYes.emit(data);
      }
      else if (this.module == this.translate?.asset.label.singular || this.data.module == this.translate?.asset.label.singular ) {
        console.log("module Leaves")
        let data = { id: "", rejectReason: "", actionStatus: "" };
        data.id = this.data.recordNumber;
        this.description ? data.rejectReason = this.description : null;
        data.actionStatus = this.actionStatus
        this.onClickYes.emit(data);
      }

    }
  }
}
