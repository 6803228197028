<!--=======-=======-=======-=======-=======-=======-=======-======= Deline Buttone Action -=======-=======-=======-=======-=======-->
<div class="custella-custom-dialog">
    <p-dialog [draggable]="draggable" [(visible)]="visible" showEffect="fade" [style]="{width: '30vw'}" [modal]="true" [draggable]="false">
        <ng-template pTemplate="header">
            <div class="d-flex align-items-center">
                <img *ngIf="actionStatus == 'REJECT' " id="img_DialogDelete" src="assets\svg\dialog\Danger-w.svg" />
                <img *ngIf="actionStatus == 'APPROVED' || data.actionStatus == 'Change Status' " id="img_DialogConfirmation" src="assets\svg\dialog\Confirmation-w.svg" />
                <div id="title_DialogConfirmation" class="text-16 font-medium ms-3">{{headerTitle ?? this.data.headerTitle }}</div>
            </div>
        </ng-template>
        <span *ngIf="this.data && this.data.multipleDataAccept == 'ASSET STATUS'" class=""> {{ this.data.module + " " + this.data.recordNumber + " " + translate?.general.commonComponent.messages.willBeChangedFrom + " " + this.data.oldStatus + " " + translate?.general.commonComponent.messages.to + " " + this.data.newStatus + ". " + translate?.general.commonComponent.messages.doYouWishToContinue }}</span>
        <span *ngIf="this.data && this.data.multipleDataAccept == 'SINGLE'" class=""> {{ module + " " + recordNumber + " " + translate?.general.commonComponent.messages.willNowBe + " " + translatedActionStatus + ". " + translate?.general.commonComponent.messages.doYouWishToContinue }}</span>
        <span *ngIf="this.data && this.data.multipleDataAccept == 'MULTI'" class=""> {{ multipleDataAcceptTitle }}</span>
        <form [formGroup]="addForm">
            <div class="d-block position-relative" *ngIf="actionDescription">
                <span class="mandatory text-12" id="label_RejectReason" >{{ translate?.general.commonComponent.fields.rejectReason.singular }} </span>
                <div style="margin-top: 4px;">
                    <textarea pInputTextarea autocomplete="off"  formControlName="rejectReason" id="input_CancelRemarksField" [(ngModel)]="description" [maxlength]="320000"></textarea>
                    <div *ngIf="description == undefined && description == '' ">
                        <small class="p-error text-10" id="label_RejectReasonReqField" >{{translate?.general.validationFE.requiredField}}</small>
                    </div>
                    <div *ngIf="addForm.controls['rejectReason'].touched && addForm.controls['rejectReason'].invalid">
                        <small *ngIf="addForm.controls['rejectReason'].errors?.required" class="text-10 p-error" id="label_RejectReasonReqField">{{translate?.general.validationFE.requiredField}}</small>
                    </div>
                </div>
            </div>
        </form>
        <p-footer>
            <div class="d-flex align-items-center justify-content-end">
                <button class="custella-btn-white text-12 ms-2" id="btn_ApproveNo" (click)="onAfterHide()">{{translate?.general.buttons.no.singular}}</button>
                <p-button type="submit" (onClick)="onClickAction(action = 'yes')" [label]="translate?.general.buttons.yes.singular" styleClass="h-32" id="btn_ApproveYes"></p-button>
            </div>
        </p-footer>
    </p-dialog>
</div>