import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { TicketsService } from "src/app/_services/tickets.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-custella-tickets",
  templateUrl: "./custella-tickets.component.html",
  styleUrls: ["./custella-tickets.component.scss"],
})
export class CustellaTicketsComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  @Output() ticketSelector = new EventEmitter();
  @Output() closeTicketView = new EventEmitter();

  public searchValue = "";
  translate: any;

  constructor(private ticketService: TicketsService,private languageTranslateService: LanguageTranslateService) {
    super();
    this.getLanguageData()
    this.ticketService.getSelectTicket().subscribe((data) => {
      if (data) {
        this.selectCustomer(data);
      }
    });
  }

  ngOnInit(): void {}

  getLanguageData() {
    // this.spinner.show()
    this.push(
      this.languageTranslateService.wordSrc$.subscribe((data) => {
        if (Object.keys(data).length != 0) {
          this.translate = data;
        }
      })
    );
    console.log("translate", this.translate);
  }

  selectCustomer(val: any) {
    this.ticketSelector.emit(val);
  }

  public closeTicketTable() {
    this.closeTicketView.emit(false);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
