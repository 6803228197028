import { AuthenticationService } from 'src/app/auth/authentication.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { InventoryService } from 'src/app/_services/inventory.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { Subscription } from 'rxjs';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import "ag-grid-enterprise"; 
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import * as moment from 'moment';
import { IconRendererComponent } from 'src/app/renderer/icon-renderer.component';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
var dateFormateChange: any;
var translateData: any;
@Component({
  selector: 'app-list-tasks',
  templateUrl: './list-tasks.component.html'
})
export class ListTasksComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  
  tasks: any;
 
  
  status: any;
  view_inventory!: boolean;
  create_inventory!: boolean;
  types: any;
  statuses: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams : any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: GridOptions;
  overlayNoRowsTemplate: string;
  productsFamilies: any;
  productsType: any;
  searchValue: any
  currentRecordNumber: any;
  totalRecord: any;

  noTasksToday: boolean = false;
  noTasksTodayIcon: boolean = false;
  refresher: Subscription
  dateFormat: any
  searchValueData: boolean =false;
  translate: any;
  
  constructor(
    private router: Router,
    private spinner : NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private breadcrumbService: BreadcrumbService,
    private taskService: TasksService,
    private auth : AuthenticationService,
    private utilService: UtilServiceService,
    private modalService: ModalServiceService,
    private perm: PermissionService,
    private util: UtilServiceService,
    private languageTranslateService: LanguageTranslateService
    ) {
      super();
     
      console.log('HERE', !this.perm.capable("tasks", "update"));


      this.refresher = this.taskService.refreshList$.subscribe((o:any) => {
        this.getTasksToAccept();
      });
      this.frameworkComponents = {
        iconRenderer: IconRendererComponent
      }
      this.gridOptions = {
        cacheBlockSize: 25,
        rowModelType: 'clientSide',
        paginationPageSize: 25,
        sortingOrder : ["desc", "asc"],
        onGridSizeChanged: (params) => {
          params.api.sizeColumnsToFit();
          },
          tooltipShowDelay: 100,
        }
      this.defaultColDef = {
        enableRowGroup: true,//ag-Grid-Enterprise
        enablePivot: true,//ag-Grid-Enterprise
        enableValue: true,//ag-Grid-Enterprise
        sortable: true,
        resizable: true,
        filter: false,
        tooltipComponent: TableHeaderToolTipComponent
      };
      //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";
    }
  
    ngOnInit() {
      this.spinner.show();
      this.languageTranslateService.menuSrc$.subscribe(data => {
        if(Object.keys(data).length != 0){ this.translate = data;
          translateData = data;
          this.getTasksToAccept();
          this.initializeColumns();
        }
      })
      dateFormateChange = this.auth.getUserTimezone();

      this.dateFormat = `EEE ${localStorage.getItem('date_format')}`;
      
    }

/********************************** Get Workflow Data **************************/
    initializeColumns() {
  
      this.columnDefs = [];
     
      const newData = [
        { headerName: this.translate?.task.fields.taskName.singular, field: 'taskName', width: 250, cellClass: function (params: any) { return ['table_default_color'];}, headerTooltip: "#",},
        {headerName:this.translate?.task.fields.taskDate.singular,field: "startDateTime", width: 200,
        headerTooltip: "#",
          cellRenderer: function (params: any) {
            if (params.data && params.data.startDateTime) {
              return ( moment.tz(params.data.startDateTime, dateFormateChange).format(localStorage.getItem("date_format")!.toUpperCase())
              );
            }
          },
        },
        { headerName: this.translate?.task.fields.time.singular, field: 'startDateTime', width: 250,filter: false,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if(params.data){
            return  moment.tz( params.data.startDateTime, dateFormateChange).format("hh:mm A")+' - '+moment.tz( params.data.endDateTime, dateFormateChange).format("hh:mm A");
          }
        }
      },
      { headerName:  this.translate?.task.fields.location.singular, field: 'location', width: 220, headerTooltip: "#",},
      { headerName: this.translate?.task.fields.assignedBy.singular, field: 'assignedBy.fullName', width: 200, headerTooltip: "#",},
      { headerName: this.translate?.task.fields.team.singular, field: 'team.teamName', width: 220, headerTooltip: "#",},
      { headerName:  translateData?.general.buttons.action.singular, sortable: false,filter: false, width: 200,headerClass: 'marging-auto hide-action-border-left', cellStyle: { "text-align": "center" }, cellRenderer: 'iconRenderer',
      headerTooltip: "#",
      cellRendererParams: {
        onClick: this.editTask.bind(this),
        permissions:{confirm:this.perm.capable("tasks", "update"),cancel: this.perm.capable("tasks", "update")},
        hidden:{confirm: !this.perm.capable("tasks", "update"),cancel: !this.perm.capable("tasks", "update")},

      }
      },
      ]
    
      this.columnDefs = newData;
        if (this.gridApi) {
          this.gridApi.setColumnDefs(this.columnDefs);
          setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
        }
    }


    getTasksToAccept(){
     
      this.push(
      this.taskService.getTasksToAccept(this.auth.getUserId()).subscribe((tasks:any)=>{
        console.log(tasks)
        this.tasks = tasks;
        this.totalRecord = this.tasks.length
        for(let task of tasks){
          task.difference = this.utilService.durationFormatter(task.startDateTime, task.endDateTime);
          task.past = this.utilService.pastFinder(task.startDateTime);
        }
        console.log(this.totalRecord,'Tasks To Accept API', this.tasks);
        if(this.totalRecord == 0){
          this.noTasksToday = false;
          this.noTasksTodayIcon = true 
        }
        else{
          this.noTasksToday = true;
          this.noTasksTodayIcon = false 
        }
        this.spinner.hide();
        
      }));
    }
   /********************************** Click Cell Event *****************************/
   editTask(e:any) {
    console.log(e.rowData)
    if (e.action === 'confirm') {
      this.modalService.openModal('acceptTask', e.rowData);
      
     }
    else if (e.action === 'cancel') {
      this.modalService.openModal('declineTask', e.rowData)    
  
    }
   }
   
  /********************************** Search Data **************************/
  public quickSearch() {
    if (this.searchValue.length > 0) {
      this.searchValueData = true
      this.gridApi.setQuickFilter(this.searchValue);
      this.totalRecord = this.gridApi.getDisplayedRowCount();
      if (this.totalRecord == 0) {
        this.gridParams.api.showNoRowsOverlay();
      } else {
        this.gridParams.api.hideOverlay();
      }
    }else{
     
      this.gridApi.setQuickFilter(this.searchValue);
      this.totalRecord = this.gridApi.getDisplayedRowCount();
      this.gridParams.api.hideOverlay();
    }
  }

  public onSearchCheck(){
    
    if(this.searchValue.length == 0){
      this.searchValueData = false
      this.searchValue = ''
      this.quickSearch()
    } 
  }
  public onClear(){
    
    this.searchValueData = false
    this.searchValue = ''
    this.quickSearch()
   
  }



  getFilter(){
    this.getTasksToAccept()
    this.onGridReady(this.gridParams);
   }
  
   /********************************** Initilize Grid Table **************************/
   onGridReady(params:any) {
    //console.log(params)
    this.gridParams = params;
  
   this.gridApi = params.api;
  
  }
  
  /********************************** Request Parameter for api **************************/
  
  
  /********************************** View Singal Record **************************/
  
   viewTask(e:any) {
    if (e.colDef.field === "taskName") {
    // this.router.navigate(['dashboard/tasks/view'], { queryParams: { id: e.data.id}});
    this.util.openTaskVIewPage(e.data.id)
    }
      //this.selectCustomer(e.data)
   }
  
  ngOnDestroy() {
    this.refresher.unsubscribe()
    super.ngOnDestroy();
  }
  
  openCreateContact(){
    this.router.navigate(['dashboard/customers/contact/Create'])
  }
  
  openModal(modalView: any, data : any) {
    data={
      mode : 'add'
    }
    this.modalService.openModal(modalView, data);
  }

    /******************************************** Right Click Context Menu ******************************************/
    public getContextMenuItems = (params: any) => {
      params.node.data.field = params.column.colId;
      let url = "";
      let data = "";
  
      let results: any[] = [];
  
      // if (params.column.colId == "taskName") {
      //   const data = params.node.data;
  
      //   results.push({
      //     name: "Open link in new tab",
      //     action: () => this.redirectToNewTab(data),
      //   });
      // }
  
      return results;
    };
  
    /******************************************** Redirect To ******************************************/
    private redirectToNewTab(data: any) {
      let url;
      if (data.field === "taskName") {
        url = this.router.serializeUrl(
          this.router.createUrlTree(
            ['dashboard/tasks/view'], { queryParams: { id: data.id}}
          )
        );
      }
  
      if (url) {
        url = url.replace("%23", "#");
        this.util.openItemToNewTab(url);
      }
    }
    push(obs:any) {
      super.push(obs);
    }
  }
  
  