import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DateHelper, Scheduler, StringHelper , Popup, EventModel } from '@bryntum/scheduler';
import { BryntumSchedulerComponent } from '@bryntum/scheduler-angular';
import * as _ from 'lodash';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { TeamService } from 'src/app/_services/team.service';
import { Subscription } from 'rxjs';
import { WebSocketsService } from 'src/app/_services/web-sockets.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

var dateFormateChange: any;
var viewPresetSC : any
@Component({
  selector: 'app-view-task-schedules',
  templateUrl: './view-task-schedules.component.html',
  styleUrls: ['./view-task-schedules.component.scss']
})
export class ViewTaskSchedulesComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  mode: any
  taskStatus: any;
  filterDay: any;
  fromDateTime: any;
  toDateTime: any;

  createTask: any;
  filterByView = "hourAndDay"
  taskDateRange: any;
  ///////////////////////////////////////////////////////////////
  @ViewChild('BryntumSchedulerComponent') schedulerComponent: BryntumSchedulerComponent | undefined;tasks: any;
  show: any;
  users: any[]=[];
  teams: any;
  selectedTeam: any[]=[];
  child: any;

  scheduler: Scheduler | undefined;
  translate: any;
;
  resources: any[] = [];
  events: any = [];
  byTeamOnlyChk: boolean = true;
  employees: any;
  notTeamMember: Boolean = false;
  teamsChkShow: boolean = false;
  leaves: any[] = [];
  team: any;
  schedulerConfig: any
  features:any
  viewPreset :any;
  refreshUser: Subscription;
  refreshTeam : Subscription;
  colors = ['#AD1457','#AB1A58','#D62161','#D30915','#E47D75','#F1522C','#ED6C1F','#EE9224','#F5BE3B','#E3C34D','#C0C842','#7DB249','#3BB57B','#167F45','#159588','#1B9CE2','#4688F1','#4054B2','#7A88C9','#B39ED9','#9D6BAD','#8D2CA8','#785549','#616161','#A79B8F']
  filter: any={onlineUsers:'ALL',typeOfUsers : 'ALL',status : '', fromDateTime : '', toDateTime : ''};

  filterActive: boolean = false;
  filterClick: boolean = false;

  toggle : boolean = true;
  status: any = null

  constructor(
    private router: Router,
    private spinner : NgxSpinnerService,
    private taskService: TasksService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private route: ActivatedRoute,
    private modalService2 : ModalServiceService,
    private auth : AuthenticationService,
    private perm: PermissionService,
    private util: UtilServiceService,
    private teamService : TeamService,
    private webSocket : WebSocketsService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.features = {
      timeRanges : {
        showCurrentTimeLine : true,
      },
      cellEdit  :false,
      eventDrag : {
          constrainDragToResource : true,
          showExactDropPosition   : true
      }
     
    }

     this.refreshUser = webSocket.refreshOnlineStatus$.subscribe(user => {
        this.updateUserInfoUsingWebSocket(JSON.parse(user.body));
      });
     
     
      this.refreshTeam = teamService.refreshList$.subscribe(item => {
        console.log(item,"======>>>>>>>")
        if(item == 'Deleted Team'){
          this.router.navigate(['dashboard/setup/teams/all'])
        }else{
          this.filterTeamTasks(this.selectedTeam)
          this.getTeamList();
        }
        
      });

  }
getBrynthumDataInit(){
  var currentDate: any = new Date()
  this.events = [];
  this.schedulerConfig = {
  columns :[
    { type: 'tree', 
    text: this.translate?.task.fields.teamMember.plural, id:"label_TaskTeamMembers",
    field: 'title',align: 'left',
    width: '16em', 
    collapsible: false,
     // Hide default tree icons
     expandedFolderIconCls  : null,
     collapsedFolderIconCls : null,
     leafIconCls            : null,
     // Set to `false` to render our custom markup
     htmlEncode             : false,
     cellEdit : {disabled : true },
    renderer({ cellElement,record, value, size }: {cellElement:any,record: any,value: any , size: any}) {
        // Parent rows are employees
        if (record.isParent) {
            // Make employee row higher
            size.height = 60;
            // Employee template
            var style=`style="border: solid 2px ${record.color}"`
                 return '<div style="height: 32px; padding: 2px 0; display: flex; align-items: center;"><div '+style+' class="mr-2 p-avatar p-component p-avatar-image p-avatar-circle task-avatar" >'+
                  '<img src="'+record.imageUrl+'" id="img_TaskTeamAvatarUser">'+
                  '</div>'+ '<span class="text-12 ms-2" id="value_TaskTeamGroupName">'+ value +'</span>' +'</div>';
          }
            // Other rows are clients
            else {
            // Client template(child template)
            var img="../../../../../assets/svg/Avatar.svg"
            var status=''
            if(record.imageUrl !== null){
              img=record.imageUrl
            }
            var style=`style="border: solid 2px ${record.color}"`
            var displayMap=''
            if(viewPresetSC == 'hourAndDay'){
              displayMap ='<img alt="logo" class="viewRoute cursor-pointer" src="../../../../../assets/svg/sidebar/map.svg" id="img_TaskTeamMap"/>'
           }

            if(record.online){
              status='<div class="custella-online-mini" style="top: 12px;left: -15px;position: relative;" ></div>';
            }else{
              status=' <div class="custella-offline-mini" style="top: 12px;left: -15px;position: relative;" ></div>';
            }
            return '<div style="height: 32px; padding: 2px 0; display: flex; align-items: center;"><div '+style+'  class="mr-2 p-avatar p-component p-avatar-image p-avatar-circle task-avatar" >'+
            '<img src="'+img+'"id="img_TaskAvatarTeam">'+
            '</div>'+status+'<span class="text-12" id="value_TaskTeamName">'+ value +'</span>' +displayMap+'</div>';

        }
    }
  }
  ],
  startDate  : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 7),
  endDate    : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23),//should not give>23),
  rowHeight : 40,
  barMargin : 5,
  features : {
    eventEdit : true,
    cellEdit : {disabled : true    },
    resourceTimeRanges : true,
    tree           : true,
    eventTooltip : {
      showOnHover: true,
      bodyCls : {
        'b-resize-monitors'     : 1,
        },
      // template returns a custom element created in app.module.ts
      template : ({ eventRecord }:{ eventRecord: any }): string => {
        console.log(eventRecord)
        setTimeout(() => {  
          $('body').addClass('b-resize-monitored');
        }, 500);
        var startTime = moment.tz(eventRecord.data.startDate,this.auth.getUserTimezone()).format('DD-MM-YYYY');
        var startDateTime =  moment(eventRecord.data.startDate).format("hh:mm a")
        var endDateTime =  moment(eventRecord.data.endDate).format("hh:mm a")
        var location = eventRecord.data.location ? eventRecord.data.location:'-'
        var taskStatus = ''
        if (eventRecord.data.taskStatus == "OPEN") {
          taskStatus='<span class="p-mr-2 p-tag p-component p-tag-open" id="tag_TaskStatusOpen"><span class="p-tag-value text-dark">Open</span></span>' ;
        } else if (eventRecord.data.taskStatus == "PENDING") {
          taskStatus= '<span class="p-mr-2  p-tag p-component p-tag-pending" id="tag_TaskStatusPending"><span class="p-tag-value text-dark">Pending</span></span> ';
        } else if ( eventRecord.data.taskStatus == "IN PROGRESS" || eventRecord.data.taskStatus == "IN_PROGRESS") {
          taskStatus= '<span class="p-mr-2  p-tag p-component p-tag-in-progress" id="tag_TaskStatusInProgress"><span class="p-tag-value text-dark">In Progress</span></span>';
       
        } else if (eventRecord.data.taskStatus == "COMPLETED") {
          taskStatus='<span class="p-mr-2  p-tag p-component p-tag-completed" id="tag_TaskStatusComplete"><span class="p-tag-value text-dark">Completed</span></span>';
        
        } else if ( eventRecord.data.taskStatus == "INCOMPLETE" ||  eventRecord.data.taskStatus == "IN_COMPLETED") {
          taskStatus='<span class="p-mr-2  p-tag p-component p-tag-incomplete" id="tag_TaskStatusIncomplete"><span class="p-tag-value text-dark">Incomplete</span></span>';
        } else if (eventRecord.data.taskStatus == "CANCELLED") {
          taskStatus='<span class="p-mr-2  p-tag p-component p-tag-cancel" id="tag_TaskStatusCancel"><span class="p-tag-value text-dark">Cancelled</span></span>';
        }
          return `
          <div class="custella-form-container rounded-2 m-0 h-100">
              <div class="custella-form-container-title d-flex px-3">
              <div class="font-medium text-14 elips col justify-content-start" id="label_TaskName">${eventRecord.name}</div>
              <span class="text-12 font-medium ms-2 text-wrap col" style="text-align: end;" id="label_TaskStatus">${taskStatus}</span>
          </div>
            <!-- Name and Email -->
            <div class="custella-form-container-content font-black">
                
                <div class="custella-content-row">
                    <div class="custella-content-row-input">
                      <div class="d-flex align-items-center">
                          <i class="pi pi-clock" id="img_TaskDuration"></i>
                          <span class="text-12 font-medium ms-2" id="value_TaskDuration">${startDateTime} - ${endDateTime}</span>
                      </div>
                    </div>
                    <div class="custella-content-row-input">
                      <div class="d-flex align-items-center">
                        <i class="pi pi-calendar" id="img_TaskDurStart"></i>
                        <span class="text-12 font-medium ms-2" id="value_TaskDurStart">${startTime}</span>
                      </div>
                    </div>
                </div>
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                      <div class="d-flex align-items-baseline">
                        <i class="pi pi-map" id="img_TaskLocation"></i>
                        <span class="text-12 font-medium ms-2 text-wrap" id="value_TaskLocation">${location}</span>
                      </div>
                    </div>
                </div>
            </div>
        </div>`
      }
     }

    },
    createEventOnDblClick: false,
    readOnly: true,
  };

}
  ngOnInit() {
    this.createTask = this.perm.capable("tasks", "create");
    viewPresetSC = this.filterByView
    dateFormateChange = this.auth.getUserTimezone();
    if (this.route.snapshot.data['mode']) {
      this.mode = this.route.snapshot.data['mode']
      
      if(this.mode == 'upcoming'){
        var currentDate: any = new Date()
        currentDate.setDate(currentDate.getDate() + 1);
        var newDate : any = new Date(currentDate)
        var newDate: any = moment(newDate).format('YYYY-MM-DD')
        var fromDateTime = moment(newDate+ ' ' + " 12:00 AM");
        this.fromDateTime = moment.tz(fromDateTime, this.auth.getUserTimezone()).utc().format();
       
        var toDateTime: any = moment(newDate+ ' ' + " 11:59 PM");
        this.toDateTime = moment.tz(toDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
        console.log( this.fromDateTime,"======",this.toDateTime);

       
      }else if(this.mode == 'completed'){
        this.fromDateTime =  null;
        this.toDateTime = null
       // this.filter.status = 'COMPLETED'
        
        }
      }
      this.getLanguageData()

     
   //this.spinner.show()
  }
  getFilter(){
    if(this.toggle){
      this.changeCalendarView()
    }else{
      console.log(this.filter);
    }
  }

  changeCalendarView(){
   console.log(this.filterByView);
   viewPresetSC = this.filterByView
   this.viewPreset = this.filterByView
    if(this.filterByView =='hourAndDay'){
      this.viewPreset = {
        base              : 'hourAndDay',
        displayDateFormat : 'll'
      }
      this.getUsersAndTasksByTeam(this.selectedTeam);
    }
    else if(this.filterByView==='weekAndDay'){
      this.viewPreset = {
        base              : 'weekAndDay',
        displayDateFormat : 'll',
        headers : [// Override headers
          {
            unit : "WEEK",
            dateFormat : 'MMM YYYY',
          },
          {
            unit       : 'day',
            dateFormat : 'ddd DD',
         
          }
        ]       
     }
      this.getUsersAndTasksByTeam(this.selectedTeam);
    }else if(this.filterByView==='monthAndYear'){
      this.viewPreset= {
        base              : 'monthAndYear',
        headers : [
            {
                unit       : 'month',
                dateFormat : 'MMM YYYY',
            },
            {
                unit     : 'week',
                renderer : (startDate:any, endDate: any) => `Week ${DateHelper.format(startDate, 'WW')}`
            },
          
            {
                unit       : 'day',
                dateFormat : 'ddd DD'
            },
            // {
            //     unit     : 'day',
            //     renderer : (startDate: any, endDate: any, headerConfig: any, index: any) => index % 4 === 0 ? Math.round(Math.random() * 5) : ''
            // }
        ]
      }
      this.getUsersAndTasksByTeam(this.selectedTeam);
    }
 
  }

  getTeamList(){
    
    this.teamService.getTeamsPickList(this.auth.getUserId()).subscribe((res:any) => {
      console.log(res,"=====>>>>>>")
      
      this.teams = res;
      var teamId =this.teams.length != 0 ? this.teams[0].id : null
      console.log(teamId)
      
      if(teamId != null){
      this.selectedTeam.push(teamId);
      }
     this.getTodaysData()
  });
  }
  openMapLocation(){
    // if(this.mode == 'upcoming'){
    //     this.router.navigate(['dashboard/tasks/upcomingTeamTask'])
    // }else if(this.mode == 'today'){
    //   this.router.navigate(['dashboard/tasks/todayTeamTask'])
    //  }else{
    //   this.router.navigate(['dashboard/tasks/allTeamTask'])
    // }
    this.toggle = !this.toggle
  }
  optimize(){
    this.router.navigate(['dashboard/tasks/optimize'])
  }
  
  clone(){
       var currentDate: any = moment(this.taskDateRange).format('YYYY-MM-DD')
        var fromDateTime = moment(currentDate+ ' ' + " 12:00 AM");
        this.fromDateTime = moment.tz(fromDateTime, this.auth.getUserTimezone()).utc().format();
       
        var toDateTime: any = moment(currentDate+ ' ' + " 11:59 PM");
        this.toDateTime = moment.tz(toDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
        console.log( this.fromDateTime,"======",this.toDateTime);
        
    const data={
      fromDateTime: this.fromDateTime,
      toDateTime: this.toDateTime
    }
    this.modalService2.openModal('taskClone', data);
  }

  openModal(modalView: any, data : any) {
    data={mode : 'add' }
    this.modalService2.openModal(modalView, data);
  }
  getTodaysData(){
    
   if(this.mode == 'upcoming'){
    this.taskDateRange = new Date()
    this.taskDateRange.setDate(this.taskDateRange.getDate() + 1);
   }else{
    this.taskDateRange = new Date();
   }
    var startD = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD');
    console.log(this.team)
    
    this.getUsersAndTasksByTeam(this.selectedTeam);
  }

  onCompletionDateChange(event: any){
    console.log(event,"====",this.taskDateRange)
      this.fromDateTime = moment(this.taskDateRange).format('YYYY-MM-DD');
      
        console.log(event,"====",this.taskDateRange)
        if(this.taskDateRange){
          this.schedulerConfig.startDate = new Date(event.getFullYear(), event.getMonth(), event.getDate(), 7);
          this.schedulerConfig.endDate = new Date(event.getFullYear(), event.getMonth(), event.getDate(), 23);//should not give>23

          var startD = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD');
          console.log(this.team)
          this.getUsersAndTasksByTeam(this.selectedTeam);
        
    } 
   
  }
 
  updateUserInfoUsingWebSocket(user : any){
   
    var tasks = Object.assign([], this.tasks);
    this.tasks = [];
    let matchingIndex = _.findIndex(this.users, function(o) { return o.id == user.id; });
    if(matchingIndex !== -1){
      this.users[matchingIndex].online = user.online;
      this.users = _.cloneDeep(this.users);
      let matchingTasks = _.filter(tasks, function(o) { return o.assignedTo.id == user.id; });
      if(matchingTasks.length){
        matchingTasks.forEach((task, index) => {
          var matchingIndex2 = _.findIndex(tasks, function(o:any) { return o.id == matchingTasks[index].id; });
          tasks[matchingIndex2].assignedTo.online = user.online;
        });
        this.tasks = tasks;
      }
      setTimeout(() => {
        // this.showCalendar = true;
      }, 0);
    }
  }
/***************************** View Route *********************************************************************/
filterTeam(team : any) {
  this.filterTeamTasks(team.value)
}

  filterTeamTasks(team : any) {
    console.log(team)
    if(!team.length){
      this.errorUtil.setErrorMessage(400, null , this.translate?.task.messages.selectAtLeastOneTeam , 'error',3000);
      var teamId =this.teams.length != 0 ? this.teams[0].id : null
      if(teamId != null){
      this.selectedTeam.push(teamId);
      }
      }
    else{
      this.team = team;
      this.leaves = [];
      this.events = [];
      this.getUsersAndTasksByTeam(team);
    }
  }

  getUsersAndTasksByTeam(team : any){
    console.log(team)
   
    var users : any[] = [];
    this.users = [];
    var allTeams = (team && team.length) ? 'false' : 'true';
    team = (team && team.length) ? team : '';
     //below api is old api(only one team data)
    // this.teamService.getEmployeeByTeam(team.id, 'true').subscribe((res:any) => {
      if(team !=''){
      try {
        this.spinner.show();
        this.taskService.getEmployeeByMulTeam(team, 'true',this.auth.getUserId(),allTeams,true).subscribe((res:any) => {
          console.log(res)
        this.teams.forEach((user : any) => {
            if(team && team.length){
            var index = _.findIndex(this.selectedTeam, (row:any) => { 
                return row == user.id; 
              });
            }
            else{//all teams 
              var index = 1;
            }
            if(index > -1) {
            if(user.colorCode){
              var color: any = user.colorCode;
            } else {
              var color: any = this.util.getRandomColor();
            }
            var photo;
            if(user.imageUrl && user.imageUrl !== ''){
              photo = user.imageUrl
            } else {
              photo = "../../../../../assets/svg/Avatar.svg"
            }
            
            var resource : any = { 
              id: user.id, 
              userId: user.id, 
              title: user.teamName,
              fullName: user.teamName,
              photoUrl : photo,
              imageUrl : photo,
              role : user.positionInTeam,
              color : color,
              online : user.online,
              teamName : user.teamName,
              expanded : true,//by default tree expand/collapse
              children : [],
              name : user.teamName
              //clients : []
            };
            var children : any[] = [];
                  var res1 = [];
                  for(var i=0;i<=res.length-1;i++){
                    for(var j=0;j<=res[i].length-1;j++){
                      res1.push(res[i][j])
                    }
                  }
                res1.forEach(childrenRow => {
                  if(user.teamName == childrenRow.teamName){
                      if(childrenRow.colorCode){
                        var color1: any = childrenRow.colorCode;
                      } else {
                        var color1: any = this.util.getRandomColor();
                      }
                      var photo1;
                      if(childrenRow.imageUrl && childrenRow.imageUrl !== ''){
                        photo1 = childrenRow.imageUrl
                      } else {
                        photo1 = "../../../../../assets/svg/Avatar.svg";
                      }
                      this.child = { 
                        //id : childrenRow.id+'_line',
                        id : childrenRow.userId+'_line_'+childrenRow.teamId,
                        userId : childrenRow.userId,
                        title: childrenRow.userFirstName + ' ' + childrenRow.userLastName,
                        fullName: childrenRow.userFirstName + ' ' + childrenRow.userFirstName,
                        photoUrl : photo1,
                        imageUrl : photo1,
                        role : childrenRow.userRole,
                        color : color1,
                        online : childrenRow.online,
                        teamName : childrenRow.teamName,
                        name : childrenRow.userFirstName + ' ' + childrenRow.userLastName
                      }
                      children.push(this.child);
                    }
                    // if(user.userLeaves && user.userLeaves.length){
                      //user.userLeaves.forEach(element => {
                        console.log(childrenRow.userLeaves)
                        
                      if(childrenRow.userLeaves && childrenRow.userLeaves.length){
                        childrenRow.userLeaves.forEach((element : any) => {
                          if(element.status == 'APPROVED'){
                            
                            var dates = this.util.getDates(new Date(element.fromDate), new Date(element.toDate));  
                                                                                                                                     
                            dates.forEach( (date) => {
                              
                              var start = moment.tz(date, this.auth.getUserTimezone()).hour(0).minute(0).format('YYYY-MM-DD HH:mm');
                              var end = moment.tz(date, this.auth.getUserTimezone()).hour(23).minute(59).format('YYYY-MM-DD HH:mm');
                              var temp : any = {};
                              temp.type = "Leave"
                              temp.title = element.leaveType + ' Leave'
                              temp.start = start
                              temp.end = end
                              temp.color = "#9b9b9b"
                              temp.borderColor  = '#9b9b9b'
                              temp.textColor = "#000000"
                              //temp.resourceId = element.userId
                              temp.resourceId = childrenRow.userId+'_line_'+childrenRow.teamId
                              temp.eventStyle = "border"
                              temp.eventColor = "#9b9b9b"
                              temp.startDate = start
                              temp.endDate = end
                              temp.startTime = moment.tz(element.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                              temp.endTime = moment.tz(element.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                              temp.name = element.leaveType + ' Leave'
                              this.leaves.push(temp)
                            });
                          }
                        });
                      }
                  })
                  resource.children = children;
                  //resource.clients = children;
                  users.push(resource);
                  this.spinner.hide();
            this.users = users;
            console.log('userssssssssss' ,this.users)
            console.log('userssssssssss' ,this.leaves)
            ////debugger
          }
          })
          this.filterTaskByTeam(team)
        });
      } catch (error) {
        console.error(error);
        this.spinner.hide();
      }
    }

  }
  filterTaskByTeam(team:any){
    this.spinner.show();
    if(team){
    var events :any[]= [];
    if(this.filterByView === 'weekAndDay'){
      var startOfWeek = moment(this.taskDateRange).startOf('week').toDate();
      var endOfWeek   = moment(this.taskDateRange).endOf('week').toDate();
      var startDate = moment.tz(startOfWeek, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      var endDate = moment.tz(endOfWeek, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      this.schedulerConfig.startDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate(), 7);
      this.schedulerConfig.endDate = new Date(endOfWeek.getFullYear(), endOfWeek.getMonth(), endOfWeek.getDate(), 23);//should not give>23
      setTimeout(() => {  
        if(this.scheduler){
         //this.scheduler.schedulerInstance.setTimeSpan(startDate1,endDate1);
        }
      }, 500);
    }
    else if(this.filterByView === 'monthAndYear'){
       var startOfMonth = moment(this.taskDateRange).startOf('month').toDate();
      var endOfMonth   = moment(this.taskDateRange).endOf('month').toDate();
      var startDate = moment.tz(startOfMonth, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      var endDate = moment.tz(endOfMonth, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      this.schedulerConfig.startDate = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), startOfMonth.getDate(), 7);
      this.schedulerConfig.endDate = new Date(endOfMonth.getFullYear(), endOfMonth.getMonth(), endOfMonth.getDate(), 23);//should not give>23
    
    }
    else{
      var startDateTemp = new Date(this.taskDateRange.getFullYear(), this.taskDateRange.getMonth(), this.taskDateRange.getDate(), 7);
      var endDateTemp = new Date(this.taskDateRange.getFullYear(), this.taskDateRange.getMonth(), this.taskDateRange.getDate(), 23);//should not give>23
      console.log(startDateTemp,"----",endDateTemp,"------",this.taskDateRange)
      var startDate = moment.tz(startDateTemp, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      var endDate = moment.tz(endDateTemp, this.auth.getUserTimezone()).format('YYYY-MM-DD');
      this.schedulerConfig.startDate = new Date(this.taskDateRange.getFullYear(), this.taskDateRange.getMonth(), this.taskDateRange.getDate(), 7);
      this.schedulerConfig.endDate = new Date(this.taskDateRange.getFullYear(), this.taskDateRange.getMonth(), this.taskDateRange.getDate(), 23);//should not give>23
      setTimeout(() => {  
        if(this.scheduler){
          //this.scheduler.schedulerInstance.setTimeSpan(this.taskDateRange,this.taskDateRange);
        }
      }, 500);
    }
    team = (team && team.length) ? team : '';
    console.log(startDate,"----",endDate,"------", this.schedulerConfig.startDate,"======>>>", this.schedulerConfig.endDate)
      
    this.teamService.getTeamsWithTasksV3(this.auth.getUserId(), startDate, endDate,team,this.byTeamOnlyChk, this.status).subscribe((res:any) => {
      var matchingTeam:any[] = [];
       _.filter(res, (o) => { 
        if(team && team.length){//team using
          for(var i=0;i<=team.length-1;i++){
            if(o.id == team[i]){
              matchingTeam.push(o);
            }; 
          }
        }
        else{ //all teams selected
          matchingTeam.push(o);
        }
      });
      for(var k=0;k<=matchingTeam.length-1;k++){
        team = matchingTeam[k];
      if(team.nonTeamTasks){
        team.nonTeamTasks.forEach((task :any)=> {
         
          var resourceChildren = [];
          for(var i=0;i<=this.users.length-1;i++){
            for(var j=0;j<=this.users[i].children.length-1;j++){
              var idLine = task.assignedTo.id+'_line_'+team.id
              if(this.users[i].children[j].id == idLine){
                  resourceChildren = this.users[i].children;
                }
            }
          }
          var findColorMatch = _.filter(resourceChildren, function(o) {
            return o.id === idLine
          });
          if(!findColorMatch.length){
            var tempColor = {color : '#60e0d6'}
            findColorMatch.push(tempColor);
          }
          var temp = task;
          if(task.assignStatus == 'PENDING'){
          temp.title = 'Busy',
          temp.location = '',
          temp.locationName = '',
          temp.start = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
          temp.end = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
          temp.color = "#ffffff",
          temp.borderColor  = findColorMatch[0].color,
          temp.textColor = "#000000",
          temp.resourceId = task.assignedTo.id+'_line_'+team.id
          temp.type = 'Busy'
          temp.taskId = task.id          
          temp.id = task.id+Math.random()
          //temp.eventStyle = "border"
          temp.eventStyle = "border"
          //temp.eventColor = ""
          temp.eventColor = findColorMatch[0].color
          temp.startDate = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
          temp.endDate = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
          temp.startTime = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
          temp.endTime = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
          temp.name = 'Busy'
          temp.customerName = task.customer ? task.customer.name : ''
        }
          else if(task.assignStatus == 'ACCEPTED'){
            temp.title = 'Busy',
            temp.location = '',
            temp.locationName = '',
            temp.start = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.end = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.color = findColorMatch[0].color,
            temp.textColor = "#ffffff",
            //temp.resourceId = task.assignedTo.id,
            temp.resourceId = task.assignedTo.id+'_line_'+team.id
            temp.type = 'Busy'
            temp.taskId = task.id          
            temp.id = task.id+Math.random()
            temp.eventStyle = "border"
            temp.eventColor = findColorMatch[0].color
            temp.startDate = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.endDate = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.startTime = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
            temp.endTime = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
            //temp.name = "Discuss new features"
            temp.name = 'Busy'
            temp.customerName = task.customer ? task.customer.name : ''
          }
          events.push(temp);
        });
      }
      if(team.tasks){
        team.tasks.forEach((task : any) => {
          // var findColorMatch = _.filter(this.users, function(o) {
          //   return o.id === task.assignedTo.id
          // });
          var resourceChildren = [];
          for(var i=0;i<=this.users.length-1;i++){
            for(var j=0;j<=this.users[i].children.length-1;j++){
              var idLine = task.assignedTo.id+'_line_'+team.id
                if(this.users[i].children[j].id == idLine){
                  resourceChildren = this.users[i].children;
                }
            }
          }
          var findColorMatch = _.filter(resourceChildren, function(o) {
            return o.id === idLine
          });
          if(!findColorMatch.length){
            var tempColor = {color : '#60e0d6'}
            findColorMatch.push(tempColor);
          }
          var temp = task;
          if(task.assignStatus == 'PENDING'){
            temp.title = task.taskName,
            temp.location = task.location,
            temp.start = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.end = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.color = "#ffffff",
            temp.borderColor  = findColorMatch[0].color,
            temp.textColor = "#000000",
            //temp.resourceId = task.assignedTo.id
            temp.resourceId = task.assignedTo.id+'_line_'+team.id
            temp.taskId = task.id          
            temp.id = task.id+Math.random()
            //temp.eventStyle = "border"
            temp.eventStyle = "border"
            temp.eventColor = findColorMatch[0].color
            temp.startDate = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.endDate = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
            temp.startTime = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
            temp.endTime = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
            temp.name = task.taskName
            temp.customerName = task.customer ? task.customer.name : ''
            temp.title = task.taskStatus
          }
          else if (task.assignStatus == 'ACCEPTED'){
            temp.title = task.taskName,
            temp.location = task.location,
            temp.start = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.end = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.color = findColorMatch[0].color,
            temp.textColor = "#000000",
            //temp.resourceId = task.assignedTo.id
            temp.resourceId = task.assignedTo.id+'_line_'+team.id
            temp.taskId = task.id          
            temp.id = task.id+Math.random()
            temp.eventStyle = "border"
            temp.eventColor = findColorMatch[0].color
            temp.startDate = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
            temp.endDate = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
            temp.startTime = moment.tz(task.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
            temp.endTime = moment.tz(task.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
            temp.name = task.taskName
            temp.customerName = task.customer ? task.customer.name : ''
            temp.title = task.taskStatus
          }
          events.push(temp);
        });
        console.log(this.leaves)
        if(this.leaves){
            this.leaves.forEach((element :any)=> {
                var index = _.findIndex(events, (row:any) => { 
                  return (row.resourceId == element.resourceId && element.type == "Leave" && row.startDate == element.startDate);
                })
                if(index <= -1) {
                  events.push(element);
                }
            });
          }
        this.events = events;
        this.tasks = team.tasks;
        console.log('eventsssss' ,this.users)
        console.log('eventsssss' ,this.events)
        this.spinner.hide();
      }
    }
    });
  }
  }
   /***************************************** Bryntum Cell Click Event**********************************************/
     
   onSchedulerEvents(event : any) {
    //console.log(event.type)
    switch(event.type) {
     
      case 'eventclick':
              if(event.eventRecord.data){
                if(event.eventRecord.data.name != 'Busy' && !(event.eventRecord.data.name.includes('Leave'))){
                  this.viewTask(event.eventRecord.data.taskId);
                }
              }
                break;
      case "cellclick":
            if(event.event.target.classList.contains('viewRoute')){
                 this.viewRoute(event);
                }
                  break
      case 'celldblclick':
            this.onCellDblClick(event);
            break;
  } // eo function dispatchEvent
}
onCellDblClick({ record, cellElement, column }:{ record:any, cellElement: any, column :any}): void {
  // Show a custom editor when dbl clicking a client cell
    if (column.field === 'title' && record.isLeaf) {
      
     var popup = new Popup({
          autoShow     : true,
          autoClose    : true,
          closeAction  : 'destroy',
          scrollAction : 'realign',
          forElement   : cellElement,
          anchor       : true,
          width        : '20em',
          cls          : 'client-editor',
          items        : [{
              type       : 'text',
              name       : 'name',
              label      : 'User',
              labelWidth : '4em',
              disabled   : true,
              value      : record.title,
              onChange   : ({ value }:{ value :any}) => {
                  record.title = value;
              }
          }, {
              type        : 'combo',
              cls         : 'b-last-row',
              name        : 'color',
              label       : 'Color',
              labelWidth  : '0em',
              listCls     :'demo',
              editable    :false,
              items       : this.colors.map(color => [color.toLowerCase(), color]),
              listItemTpl : (data :any)=> `<div style="background-color: ${data.text};border-radius: 20px;height: 20px;width: 20px;float: left;"></div>`,
              value       : record.color,
              onChange    : ({ value }:{value: any}) => {
                  record.color = value;
                  // this.changeCalendarColor(record.color,record)
              }
          }]
      });
       popup.show()
       // eo new Popup
  } // eo if(name && leaf)
} // eo function onCellDblClick


saveEvent(record: any){
  console.log('save record', record)
}

changeCalendarColor(colorCode: any, resource: any){
 var req = {
   colorCode: colorCode,
   type:"CALENDER",
   user:{id: resource.userId},
   calenderOwner:{id: this.auth.getUserId()}
 }
 this.teamService.setUserCalendarColor(req).subscribe((res:any) => {
 })
}
 /***************************** View Task *********************************************************************/ 
viewTask(id: any){
  //  var url = this.router.serializeUrl(

  //    this.router.createUrlTree(['/dashboard/tasks/allMyTask'], { queryParams: { taskId:id,requestAssistance:true}})
  //  );
  //  url = url.replace('%23', '#');
  //  window.open(url, '_blank');
  //  window.focus();
  this.util.openTaskVIewPage(id)
 }
 /***************************** View Route *********************************************************************/
 viewRoute({ record, cellElement, column }:any): void {
  var startDate = moment.tz(this.taskDateRange, this.auth.getUserTimezone()).format('YYYY-MM-DD')
  var url = this.router.serializeUrl(
    this.router.createUrlTree(['/dashboard/tasks/dailyRouteMaps'], { queryParams : {'userId': record.userId, 'date' : startDate}})
  );
  url = url.replace('%23', '#');
  window.open(url, '_blank');
}

getLanguageData() {
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.filterDay  =[
          {label: this.translate?.task.fields.day.singular,value:'hourAndDay'},
          {label: this.translate?.task.fields.week.singular,value:'weekAndDay'},
          {label: this.translate?.task.fields.month.singular,value:'monthAndYear'},
        ]
        this.taskStatus  =[
          {label:this.translate?.general.status.open.singular,value:'OPEN'},
          {label:this.translate?.general.status.pending.singular,value:'PENDING'},
          {label:this.translate?.general.status.inProgress.singular,value:'IN_PROGRESS'},
          {label:this.translate?.general.status.completed.singular,value:'COMPLETED'},
          {label:this.translate?.general.status.incompleted.singular,value:'IN_COMPLETED'}
        ]
        this.getTeamList()
        this.changeCalendarView()
        this.getBrynthumDataInit()
      }
    }))
  //console.log("translate",this.translate)
}



onFilteruttonClick(event: any) {
  this.filterActive = !this.filterActive;
  this.filterClick = !this.filterClick;
  event.preventDefault();
}
resetSearch(){
  this.status = null;
  this.selectedTeam = []
  var teamId =this.teams.length != 0 ? this.teams[0].id : null
  if(teamId != null){
      this.selectedTeam.push(teamId);
  }
    this.getFilter();
  
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}
}
