
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { RequestOptions, Http, Headers } from '@angular/http';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class DynamicFormService {
  public refreshDynamicList$: EventEmitter<any>;
  public valueChanged$: EventEmitter<any>;



  constructor(private http: HttpClient,
    private router: Router,
    private httpOnly : Http,
    private auth: AuthenticationService) {
      this.refreshDynamicList$ = new EventEmitter();
      this.valueChanged$ = new EventEmitter();

    }
    public returnNodeurl(apiServerType: any){
      if(apiServerType == "magnum"){
        return environment.magnum_node_url

      }else  if(apiServerType == "java"){
        return environment.base_url

      }else  if(apiServerType == "tngo"){
        return environment.touchandgo_node_url
      }else  if(apiServerType == "coffex"){
        return environment.coffex_node_url
      }else  if(apiServerType == "multiTenantNode"){
        return environment.multiTenant_node_url
      }else  if(apiServerType == "agribio"){
        return environment.agribio_node_url
      }else{
        return environment.node_url
      }
    }

    public getReadingDynamicForm(parentId:any,formSchemaName:any,apiServerType:any, parentName:any, sectionName:any = null){
      let params = new HttpParams();
      var url = this.returnNodeurl(apiServerType)
      params = params.set('formSchemaName', formSchemaName);
      params = params.set('parentName', parentName);
      params = params.set('parentId', parentId);
      if(sectionName != null){
        params = params.set('sectionName', sectionName);
      }

      return this.http.get(url+'/v1/dynamic-form/schemas/template', {observe: "response",params : params}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }

      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }




    public getReadingDynamicForm1(){
      let params = new HttpParams();
      var url = this.returnNodeurl('magnum')

      return this.http.get( url+'/v1/dynamic-form/schemas', {observe: "response",params : params}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public getEditReadingDynamicForm(formSchemaName:any,id:any,apiServerType:any,apiUrl: any = null){
      var url = this.returnNodeurl(apiServerType)

      if(apiUrl ==null){
        apiUrl= '/v1/dynamic-form/data/'
      }
      return this.http.get( url+apiUrl+formSchemaName+'/'+id, {observe: "response"}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        }if(resp.status == 403){
          this.logOut()
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public getData(lookupUrl:any, apiServerType:any){
      var url = this.returnNodeurl(apiServerType)
      return this.http.get( url+'/'+lookupUrl, {observe: "response"}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public valueChanged(){
      this.valueChanged$.emit();
    }
  /////////////////////////////// Create templet of Dynamic form ----------------------------------
    public createDynamicFormData(body:any,formAPIName:any,apiServerType:any,refresh:any){
      console.log(refresh)
      var url = this.returnNodeurl(apiServerType)
      return this.http.post( url+'/'+formAPIName, body, { observe: 'response' }).pipe(map( (resp:any) => {
        if(refresh !=null){
          this.refreshDynamicList$.emit(resp);
        }
      return resp;
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }
   // ======================= Update
    public editDynamicFormData(body:any,formAPIName:any,apiServerType:any){
      var url = this.returnNodeurl(apiServerType)
      return this.http.put( url+'/'+formAPIName, body, { observe: 'response' }).pipe(map( (resp:any) => {
        this.refreshDynamicList$.emit(resp);
       return resp;
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    logOut(){
      this.router.navigate(['login']);
      localStorage.clear();
      sessionStorage.clear();
    }

    public getDynamicList(searchValue:any, parameters:any, parentName:any, parentId:any,tableName:any,apiServerType:any){
      var url = this.returnNodeurl(apiServerType)
      let params = new HttpParams();
        params = params.set('parentName', parentName);
        params = params.set('parentId', parentId);

      if(searchValue !== '' && searchValue != null){
        params = params.set('search', searchValue);
      }
      var apiUrl= '/v1/dynamic-form/data/'
      if(apiServerType == 'multiTenantNode' || apiServerType == 'agribio'){
        apiUrl= '/dynamic-form-schema-data/'
      }
      if(parameters)

      params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);      
      return this.http.get(  `${url}${apiUrl}${tableName}`, {observe: 'response',params : params} ).pipe(map( (resp:any) => {
        return resp;
        }
      ),catchError( error => {
        //console.log(error);
            return observableOf( error );
        }),);

    }

    public deleteDynamicData(id:any,title:any,formSchemaName:any,apiServerType:any){
      console.log(apiServerType)
      var url = this.returnNodeurl(apiServerType)
      return this.http.delete( url+'/'+formSchemaName+'/'+id, {observe: 'response' }).pipe(map( (resp:any) => {
        this.refreshDynamicList$.emit('Deleted '+title);
        return resp;
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }


    public getSingleByParent(parentName:any, formSchemaName:any,  parentId:any){
      let params = new HttpParams();
        params = params.set('parentName', parentName);
        params = params.set('parentId', parentId);





      return this.http.get( `${environment.magnum_node_url}/v1/dynamic-form/data/${formSchemaName}/first`, {observe: 'response',params : params} ).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
        }
      ),catchError( error => {
        //console.log(error);
            return observableOf( error );
        }),);

    }
    public getTableColumn(apiModule: any){
      return this.http.get( environment.base_url+'/table-column-setups/by-module?apiModule='+apiModule).pipe(map( (resp:any) => {
        return resp;
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }
    /**************************************** Dynamic Search  *************************************/
    public getDynamicSearch(lookupUrl: any ,displayColumn: any,  searchData:any, lookupFilter: any){
      return this.http.get( environment.base_url+'/'+lookupUrl+'?startFrom=1&perPage=50&'+displayColumn+'='+searchData+'&columnName=name&sortOrder=asc'+lookupFilter, { observe: 'response' }).pipe(map( (resp:any) => {
        return resp;
      }
    ),catchError( error => {
      //console.log(error);
        return observableOf( error );
      }),);
    
    }

    public getDataBySchecmaForPSS(schemas: any,parentName: any,parentId: any){
      let params = new HttpParams();

      params = params.set('schemas', schemas);
      params = params.set('parentName', parentName);
      params = params.set('parentId', parentId);
      return this.http.get(  environment.multiTenant_node_url+'/dynamic-form-schema-data/data/check', {observe: "response",params : params}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public getReadingDynamicFormPSS(parentId: any,formSchemaName: any,apiServerType: any, parentName: any, sectionName: any = null){
      let params = new HttpParams();
      var url = this.returnNodeurl(apiServerType)
      params = params.set('formSchemaName', formSchemaName);
      params = params.set('parentName', parentName);
      params = params.set('parentId', parentId);
      if(sectionName != null){
        params = params.set('sectionName', sectionName);
      }
console.log(url,"===",apiServerType)

      return this.http.get(url+'/dynamic-form-schema/template', {observe: "response",params : params}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }


    public getReadingDynamicForm1PSS(){
      let params = new HttpParams();
      var url = this.returnNodeurl('magnum')

      return this.http.get( url+'/dynamic-form-schema', {observe: "response",params : params}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public getEditReadingDynamicFormPSS(formSchemaName: any,id: any,apiServerType: any){
      var url = this.returnNodeurl(apiServerType)
      return this.http.get( url+'/dynamic-form-schema-data/'+formSchemaName+'/'+id, {observe: "response"}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        }if(resp.status == 403){
          this.logOut()
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public getDataPSS(lookupUrl: any,apiServerType: any){
      var url = this.returnNodeurl(apiServerType)
      return this.http.get( url+'/'+lookupUrl, {observe: "response"}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public uploadAttachmentById(formData:any,uploadUrl: any){
      const headers = new Headers({});
      headers.append('Authorization', 'Bearer '+this.auth.getToken());
      let options = new RequestOptions( { headers: headers } )
      return this.httpOnly.post(uploadUrl, formData, options).pipe(map( (resp:any) => {
        return resp;
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }
    public deleteAttachment(id: any){
      return this.http.delete( environment.base_url+'/attachments/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
        return resp;
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public getDataBySchecmaForSterra(schemas: any,parentName: any,parentId: any){
      let params = new HttpParams();

      params = params.set('schemas', schemas);
      params = params.set('parentName', parentName);
      params = params.set('parentId', parentId);
      return this.http.get(  environment.multiTenant_node_url+'/dynamic-form-schema-data/data/check', {observe: "response",params : params}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }

    public getDataBySchecmaForAll(jobOrderTypeId: any){
      let params = new HttpParams();

      if(jobOrderTypeId != null){
            params = params.set('jobOrderTypeId', jobOrderTypeId);
      }
      return this.http.get(  environment.base_url+'/dynamic-form-configs', {observe: "response",params : params}).pipe(map( (resp:any) => {
        if(resp.status == 204){
          return false;
        } else {
          return resp.body;
        }
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }
    public deleteDynamicData1(id:any,title:any,formSchemaName:any,apiServerType:any, parentName: any){
      console.log(parentName)
      var url = this.returnNodeurl(apiServerType)
      return this.http.delete( url+'/'+formSchemaName+'/'+id, {observe: 'response' }).pipe(map( (resp:any) => {
        if(parentName == 'jobOrder'){
        this.refreshDynamicList$.emit('Deleted Form');
        }else{
          this.refreshDynamicList$.emit('Deleted Sub Form');
        }
        return resp;
      }
    ),catchError( error => {
          return observableOf( error );
      }),);
    }
  }
