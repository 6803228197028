<div class="custella-form-container left-right-space mt-56px">
    <div class="custella-form-container-title" id="title_AssetPmDet">{{ translate?.preventiveMaintenance.sections.sec0.singular }}</div>

    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmName" >{{ translate?.asset.fields.assetName.singular }} </span>
                <p class="custella-content-row-input-title-three-link" id="link_AssetPmName" (click)="navigateTo('asset', scheduledMaintenance?.assets?.id)">{{ scheduledMaintenance?.assets?.name }}</p>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmStatus" >{{ translate?.maintenance.fields.status.singular }} </span>
                <!-- <p class="custella-content-row-input-title-three" id="value_AssetPmStatus" *ngIf="scheduledMaintenance?.status != 'OVER_DUE'">
                    <span class="p-mr-2 p-tag p-component" [ngClass]="{'cancel': scheduledMaintenance?.status === 'CANCELLED','pending': 
                    scheduledMaintenance?.status === 'PENDING','complete': 
                    scheduledMaintenance?.status === 'COMPLETED' }" >
                        <span class="p-tag-value text-dark"> {{ scheduledMaintenance?.status | titlecase }}</span></span>
                </p>
                <p class="custella-content-row-input-title-three overdue" id="value_AssetPmStatus " *ngIf="scheduledMaintenance?.status == 'OVER_DUE'">{{ translate?.general.status.overdue.singular }}</p> -->

                <span *ngIf="scheduledMaintenance?.status == 'PENDING'" class="p-tag p-component pending" id="value_CheckStatusNew"><span class="p-tag-value text-dark">{{translate?.general.status.pending.singular}}</span></span>
                <!-- <span  *ngIf="checklistData?.status == 'DRAFT'" class="p-mr-2  p-tag p-component p-checklist-tag-draft" id="value_CheckStatusDraft"><span class="p-tag-value text-dark">{{translate?.general.status.draft.singular}}</span></span> -->
                <span  *ngIf="scheduledMaintenance?.status == 'COMPLETED'" class="p-tag p-component complete" id="value_CheckStatusDraft"><span class="p-tag-value text-dark">{{translate?.general.status.completed.singular}}</span></span>
                <span  *ngIf="scheduledMaintenance?.status == 'OVER_DUE'" class="p-tag p-component overdue" id="value_CheckStatusCompl"><span class="p-tag-value text-dark">{{translate?.general.status.overdue.singular}}</span></span>
                <span  *ngIf="scheduledMaintenance?.status == 'CANCELLED'" class="p-tag p-component cancel" id="value_CheckStatusCancel"><span class="p-tag-value text-dark">{{translate?.general.status.cancelled.singular}}</span></span>


            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmMain" >{{ translate?.preventiveMaintenance.fields.maintenance.singular }} </span>
                <p class="custella-content-row-input-title-three-link" id="link_AssetPmMain" (click)="navigateTo('sub-maintenace', scheduledMaintenance?.maintenance?.id)" *ngIf="scheduledMaintenance?.maintenance">
                    {{ scheduledMaintenance?.maintenance?.maintenanceNumber }}
                </p>
                <p class="custella-content-row-input-title-three" id="link_AssetPmMain" *ngIf="!scheduledMaintenance?.maintenance">-</p>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmSerZone" >{{ translate?.setup.serviceZone.label.singular }} </span>
                <p class="custella-content-row-input-title-three" id="value_AssetPmSerZone" *ngIf="scheduledMaintenance?.serviceZone">{{ scheduledMaintenance?.serviceZone?.name }}</p>
                <p class="custella-content-row-input-title-three" id="value_AssetPmSerZone" *ngIf="!scheduledMaintenance?.serviceZone">-</p>
            </div>
       
        </div>
    </div>

    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmTemp" >{{ translate?.preventiveMaintenance.fields.maintenanceTemplate.singular }} </span>
                <p class="custella-content-row-input-title-three" id="value_AssetPmTemp">
                    {{ scheduledMaintenance?.preventativeMaintenanceFrequency?.preventativeMaintenanceTemplate?.name || '-' }}
                </p>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmFreq" >{{ translate?.preventiveMaintenance.fields.maintenanceFrequency.singular }} </span>
                <p class="custella-content-row-input-title-three" id="value_AssetPmFreq">{{ scheduledMaintenance?.preventativeMaintenanceFrequency?.name || '-' }}</p>
            </div>

        </div>
    </div>

    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmType" translate *ngIf="!addonFeature?.addonDYNAMICMAINTENANCE">{{ translate?.preventiveMaintenance.fields.types.singular }} </span>
                <span class="custella-content-row-input-title-two" id="label_AssetPmJobType" translate *ngIf="addonFeature?.addonDYNAMICMAINTENANCE">{{translate?.jobOrder.label.singular}}&nbsp;{{ translate?.preventiveMaintenance.fields.types.singular }} </span>

                <ng-container *ngIf="!addonFeature?.addonDYNAMICMAINTENANCE">
                    <p class="custella-content-row-input-title-three" id="value_AssetPmType" *ngIf="scheduledMaintenance?.type != 'ON_DEMAND'">{{ scheduledMaintenance?.type | titlecase }}</p>
                    <p class="custella-content-row-input-title-three" id="value_AssetPmType" *ngIf="scheduledMaintenance?.type == 'ON_DEMAND'">{{ translate?.general.status.onDemand.singular }}</p>
                </ng-container>

                <ng-container *ngIf="addonFeature?.addonDYNAMICMAINTENANCE">
                    <p class="custella-content-row-input-title-three" id="value_AssetPmJobType" *ngIf="scheduledMaintenance?.jobOrderType">{{ scheduledMaintenance?.jobOrderType?.name }}</p>
                    <p class="custella-content-row-input-title-three" id="value_AssetPmJobType" *ngIf="!scheduledMaintenance?.jobOrderType">-</p>
                </ng-container>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmMainDate" >{{ translate?.preventiveMaintenance.fields.maintenanceDate.singular }}</span>
                <p class="custella-content-row-input-title-three" id="value_AssetPmMainDate" *ngIf="scheduledMaintenance?.scheduledDateTime">{{ scheduledMaintenance?.scheduledDateTime | momentDate: dateFormat }}</p>
                <p class="custella-content-row-input-title-three" id="value_AssetPmMainDate" *ngIf="!scheduledMaintenance?.scheduledDateTime">-</p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6" *ngIf="false">
                <span class="custella-content-row-input-title-two" id="label_AssetPmFreqName" >{{ translate?.preventiveMaintenance.fields.frequencyName.singular }}</span>
                <!-- <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.type != 'ON_DEMAND'">{{ scheduledMaintenance?.type | titlecase }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.type == 'ON_DEMAND'">{{ "on_demand"  }}</p> -->
            </div>
            <!-- <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" >{{ "service_type" }}</span>
                <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.serviceType">{{ scheduledMaintenance?.serviceType | titlecase }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="!scheduledMaintenance?.serviceType">-</p>
            </div> -->
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmJobOrder" >{{ translate?.jobOrder.label.singular }} </span>
                <p class="custella-content-row-input-title-three-link" id="value_AssetPmJobOrder" *ngIf="scheduledMaintenance?.jobOrder" (click)="navigateTo('viewJO', scheduledMaintenance.jobOrder.id)">{{ scheduledMaintenance?.jobOrder?.jobOrderNumber }}</p>
                <p class="custella-content-row-input-title-three-link" id="value_AssetPmJobOrder" *ngIf="!scheduledMaintenance?.jobOrder && createJO" (click)="createJobOrder()">{{ translate?.general.buttons.create.singular }}&nbsp;{{translate?.jobOrder.label.singular}}</p>
                <p class="custella-content-row-input-title-three" id="value_AssetPmJobOrder" *ngIf="!scheduledMaintenance?.jobOrder && !createJO" title="No permission to create job order">{{ translate?.general.buttons.create.singular }}&nbsp;{{translate?.jobOrder.label.singular}}</p>
            </div>

            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmActCompDate" >{{ translate?.preventiveMaintenance.fields.actualCompletionDate.singular }} </span>
                <p class="custella-content-row-input-title-three" id="value_AssetPmActCompDate" *ngIf="scheduledMaintenance?.actualCompletionDateTime">{{ scheduledMaintenance?.actualCompletionDateTime | momentDate: dateFormat }}</p>
                <p class="custella-content-row-input-title-three" id="value_AssetPmActCompDate" *ngIf="!scheduledMaintenance?.actualCompletionDateTime">-</p>
            </div>
        </div>
    </div>

    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmAssignUser" >{{ translate?.preventiveMaintenance.fields.assignedUser.singular}}</span>
                <p class="custella-content-row-input-title-three" id="value_AssetPmAssignUser" *ngIf="scheduledMaintenance?.jobOrderOwner">{{ scheduledMaintenance?.jobOrderOwner?.fullName }}</p>
                <p class="custella-content-row-input-title-three" id="value_AssetPmAssignUser" *ngIf="!scheduledMaintenance?.jobOrderOwner">-</p>
            </div>
        </div>
    </div>

    <div class="custella-form-container-content-border-bottom " *ngIf="false">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmFreq" >{{ translate?.setup.modules.preventiveFrequency.label.singular }}</span>
                <!-- <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.type != 'ON_DEMAND'">{{ scheduledMaintenance?.type | titlecase }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.type == 'ON_DEMAND'">{{ "on_demand"  }}</p> -->
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom " *ngIf="false">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmAllowWeekdays" >{{ "allow_to_schedule_on_weekdays" }}</span>
                <!-- <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.type != 'ON_DEMAND'">{{ scheduledMaintenance?.type | titlecase }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.type == 'ON_DEMAND'">{{ "on_demand"  }}</p> -->
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom " *ngIf="false">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_AssetPmAllowWeekends" >{{ "allow_to_schedule_on_weekends" }}</span>
                <!-- <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.type != 'ON_DEMAND'">{{ scheduledMaintenance?.type | titlecase }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.type == 'ON_DEMAND'">{{ "on_demand"  }}</p> -->
            </div>
        </div>
    </div>
    
    <!-- JO Details -->
    <!-- <div class="custella-form-container-title-two">
        <span class="text-14 font-medium">{{ "job_order_details"  }}</span>
    </div>
    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" >{{ "job_order" }}</span>
                <p class="custella-content-row-input-title-three-link" *ngIf="scheduledMaintenance?.jobOrder" (click)="navigateTo('viewJO', scheduledMaintenance.jobOrder.id)">{{ scheduledMaintenance?.jobOrder?.jobOrderNumber }}</p>
                <p class="custella-content-row-input-title-three-link" *ngIf="!scheduledMaintenance?.jobOrder && createJO" (click)="createJobOrder()">{{ "create_job_order"  }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="!scheduledMaintenance?.jobOrder && !createJO" title="No permission to create job order">{{ "create_job_order"  }}</p>
            </div>
        </div>
    </div> -->
    <!--<div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" >{{ "job_order_type" }}</span>
                <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.serviceType">{{ scheduledMaintenance?.serviceType | titlecase }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="!scheduledMaintenance?.serviceType">-</p>
            </div>
        </div>
    </div> -->
    <!-- <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" >{{ "assigned_to_user" }}</span>
                <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.jobOrderOwner">{{ scheduledMaintenance?.jobOrderOwner?.fullName }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="!scheduledMaintenance?.jobOrderOwner">-</p>
            </div>
        </div>
    </div> -->
    <!-- <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" >{{ "others" }}</span>
                <p class="custella-content-row-input-title-three" *ngIf="scheduledMaintenance?.otherType">{{ scheduledMaintenance?.otherType | titlecase }}</p>
                <p class="custella-content-row-input-title-three" *ngIf="!scheduledMaintenance?.otherType">-</p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" >{{ "service_type" }}</span>
            
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom ">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" >{{ "user" }}</span>
              
            </div>
        </div>
    </div> -->

    <app-system-information
        [createdByUser]="scheduledMaintenance?.createdByUser"
        [createdDate]="scheduledMaintenance?.createdDate"
        [modifiedByUser]="scheduledMaintenance?.lastModifiedByUser"
        [lastModifiedDate]="scheduledMaintenance?.lastModifiedDate"
    ></app-system-information>
</div>
