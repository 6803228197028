<div class="custella-modal">
    <div class="custella-modal-header align-items-center">
        <span class="text-14 font-medium ms-2" id="title_CheckProduct"> {{ translate?.general.buttons.add.singular  }}&nbsp;{{translate?.product.label.singular}} </span>
        <i class="pi pi-times" (click)="modalRef.hide()" [pTooltip]="translate?.general.buttons.close.singular" id="btn_CheckProductClose"></i>
    </div>

    <div class="">
        <!-- Container -->
        <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0">
            <form [formGroup]="addProductForm">
                <div class="custella-form-container-title-two-grey fst-italic">
                    <div class="text-12 font-medium" id="subtitle_CheckProdAddProdQty">{{translate?.product.sections.sec8.singular}}</div>
                </div>
                <div class="custella-form-container-content my-2">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-10">
                            <span class="custella-content-row-input-title mandatory" id="label_CheckProdDetailsName" >{{translate?.product.label.singular}}</span>
                            <div class="p-inputgroup search">
                                <span class="p-input-icon-right w-100">
                                    <p-autoComplete
                                    (onSelect)="gotProduct($event)"
                                    (onKeyUp)="getProductList($event, 'products')"
                                    id="picklist_CheckProdDetailsNameField" 
                                    field="name"
                                    (onBlur)="onBlurProduct()"
                                    formControlName="name"
                                    [(ngModel)]="selectedProduct"
                                    [suggestions]="products"
                                    styleClass="w-100"
                                >
                                </p-autoComplete>
                                <i class="p-autocomplete-clear-icon pi pi-times" *ngIf="selectedProduct" id="btn_CheckProdDetailsNameClear" (click)="clearValue()"></i>
                                </span>
                                <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.product.sections.sec4.singular" tooltipPosition="left" id="btn_CheckProdDetailsNameSearch" (click)="displayProductTable()"></button>
                            </div>
      
                            <footer *ngIf="showProductTable" class="custella-form-container-footer hidePopup">
                                <p-dialog [header]="translate?.product.sections.sec4.singular" id="title_CheckProdDetails" [(visible)]="showProductTable" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                    <app-custella-products (closeProductView)="closeProductTable()" (productSelector)="gotProduct($event)"></app-custella-products>
                                </p-dialog>
                            </footer>
    
                            <div class="p-error text-10 col px-0 py-0 text-left" *ngIf="  addProductForm.controls['name'].touched && addProductForm.controls['name'].invalid " >
                                <span *ngIf="addProductForm.controls['name'].errors?.required" id="label_CheckProdDetailsNameRequiredField" >{{translate?.general.validationFE.requiredField}}</span>
                            </div>
                           </div>
                        <div class="custella-content-row-input col-2">
                            <span class="custella-content-row-input-title mandatory" id="label_CheckProdQty" > {{translate?.product.fields.quantity.singular}} </span>
                           <input pInputText autocomplete="off"  id="input_CheckProdQtyField" type="number" class="form-control"  [(ngModel)]="product.quantity" formControlName="quantity" name="quantity" />

                    <div class="p-error text-10" *ngIf="addProductForm.controls['quantity'].touched && addProductForm.controls['quantity'].invalid">
                        <span *ngIf="addProductForm.controls['quantity'].errors?.required" id="label_CheckProdReqField" >{{translate?.general.validationFE.requiredField}}</span>
                        <span *ngIf="addProductForm.controls['quantity'].errors?.pattern" id="label_CheckProdInvalidQty" >{{translate?.general.validationFE.invalidQuantity}}</span>
                    </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="custella-form-container-footer mb-0">
                <div class="flex-align-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" id="btn_CheckProdCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                    <p-button [label]="buttonLabel" styleClass="ms-2 h-32" id="btn_CheckProdSubmit"  (onClick)="onSubmit()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>