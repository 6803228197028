<!-- <div class="custella-stickey-breadcrumb" *ngIf="items.length !=0">
    <p-breadcrumb [model]="items"></p-breadcrumb>
</div> -->

<div class="navigation-container" *ngIf="items && items.length > 0">
    <div class="navigation-inner">
        <div class="navigation-item" *ngFor="let item of items; let i = index; first as isFirst; last as isLast">
            <div (click)="item.command ? item.command() : ''">
                <span [ngClass]="item?.routerLink && item.routerLink !== '' ? 'cursor-pointer' : ''" (click)="navigateTo($event, item, i)" class="text-12 font-medium">{{item.label}}</span>
            </div>
    
            <i class="pi pi-chevron-right pt-1 pe-2" *ngIf="!isLast" style="font-size: 11px;"></i>
        </div>
    </div>
</div>