import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  GridOptions,
  IGetRowsParams,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { ChubbServicesService } from "src/app/_services/chubb-services.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

import * as moment from "moment-timezone";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import { ErrorUtil } from "src/app/_utilities/error";

import { LanguageTranslateService } from "src/app/_services/language-translate.service";
var translateData: any;
@Component({
  selector: "app-journey-management-plan",
  templateUrl: "./journey-management-plan.component.html",
  styleUrls: ["./journey-management-plan.component.scss"],
  providers: [ConfirmationService]
})
export class JourneyManagementPlanComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  public gridOptions!: Partial<GridOptions>;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  pageSize: any = 25;
  gridParams: any;
  parameters: any;
  startFrom: any;
  searchValue: any;
  noJMP: boolean = false;
  filter: any = { status: "", supervisorId: null, fromDate: "", timeZone: "" };

  jmpData: any[] = [];
  frameworkComponents!: { buttonRenderer: typeof ButtonRendererComponent };
  overlayNoRowsTemplate : any;
  //   '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  refreshJMP!: Subscription;

  totalRecord: any;
  searchValueData: boolean = false;
  translate: any;

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private chubbService: ChubbServicesService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService
  ) {
    super();
    this.spinner.show();
    this.preInit();
  }

  ngOnInit() {
   
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.refreshJMP = this.chubbService.refreshJMP$.subscribe((res: any) => {
      this.refresh();
    });

    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      getRowHeight: function (params) {
        if (params.node.level === 0) {
          return 35;
        }
        if (params.node.level === 1) {
          return 30;
        }
        return 30;
      },
      tooltipShowDelay: 100,
    };
    this.columnDefs 

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };
  }



  /******************************************** Init ******************************************/
  private init() {
    this.filter.timeZone = this.auth.getUserTimezone();
    this.filter.supervisorId = this.auth.getUserId();
    this.getLanguageData();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();

    if (this.refreshJMP) {
      this.refreshJMP.unsubscribe();
    }
  }

  /******************************************** View ******************************************/
  public view(e: any) {
    if (e.colDef.field === "jmpNo") {
      this.router.navigate(["/dashboard/chubb/journeyManagementPlanDetail"], {
        queryParams: { id: e.data.id },
      });
    }
  }

  /******************************************** Delete / Approve and Rehect ******************************************/
  private jmpClickEvent(e: any) {
    if (e.action == "delete") {
      const item = {
        deleteType: "JMP",
        id: e.rowData.id,
        message: this.translate?.general.messages.confirmDelete+" "+` ${e.rowData.jmpNo}?`,
      };

     this.deleteRecordService.getItems(item);
    } else if (e.action === "Approve") {
      this.confirmTransfer(e.rowData,'approve')
     // this.jmpApprove(e.rowData.id);
    } else if (e.action === "Reject") {
      this.confirmTransfer(e.rowData,'reject')
      //this.jmpReject(e.rowData.id);
    }
  }

  /******************************************** Approve ******************************************/
  private jmpApprove(id: any) {
    this.push(
      this.chubbService.getJMPApprove(id).subscribe((data: any) => {
        this.errorUtil.setErrorMessage(200, 'Journey Management Plan approved successfully' ,  null, 'success',1000);
        // console.log(data);
      })
    );
  }

  /******************************************** Reject ******************************************/
  private jmpReject(id: any) {
    this.chubbService.getJMPReject(id).subscribe((data: any) => {
      this.errorUtil.setErrorMessage(200, 'Journey Management Plan rejected successfully' ,  null, 'success',1000);
      // console.log(data);
    });
  }

  /******************************************** Search ******************************************/
  quickSearch() {
    if (this.searchValue.length > 0) {
      this.searchValueData = true
      this.onGridReady(this.gridParams);
    }
  }
  public onSearch(){
    
    if(this.searchValue.length == 0){
      this.spinner.show()
      this.searchValueData = false
      this.searchValue = ''
      this.refresh()
    } 
  }
  public onClear(){
    
    this.searchValueData = false
    this.searchValue = ''
    this.refresh()
   
  }
  /******************************************** On Grid Ready ******************************************/
  public onGridReady(params: any) {
    //console.log(params)
    this.gridParams = params;
    this.searchValue = this.searchValue ? this.searchValue : "";
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var dataSource = {
      getRows: (params: IGetRowsParams) => {
        this.startFrom = params.startRow;
        if (params.sortModel.length <= 0) {
          this.parameters = { colId: "jmpNo", sort: "asc" };
          params.sortModel.push(this.parameters);
        }

        this.push(
          this.chubbService
            .getJMP(
              this.filter.fromDate,
              this.filter.timeZone,
              this.filter.status,
              this.filter.supervisorId,
              this.searchValue,
              params
            )
            .subscribe((data: any) => {
              this.spinner.hide()
              this.gridApi.hideOverlay();
              if (data.status === "NO_DATA") {
                this.noJMP = true;
              } else {
                this.noJMP = false;
                this.jmpData = data.data;

                this.jmpData.forEach((element) => {
                  element.type = "JMP";
                  element.userId = this.filter.supervisorId;
                });

                if (this.jmpData.length === 0) {
                  this.totalRecord = 0;
                  this.gridApi.showNoRowsOverlay();
                  params.successCallback([], 0);
                } else {
                  this.totalRecord = data.total;
                  params.successCallback(this.jmpData, data.total);
                }
              }
            })
        );
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }

  /******************************************** Refresh ******************************************/
  public refresh() {
    //debugger
    this.onGridReady(this.gridParams);
  }

  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];

    if (params.column.colId == "jmpNo") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    if (data.field === "jmpNo") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/dashboard/chubb/journeyManagementPlanDetail"],
          {
            queryParams: { id: data.id },
          }
        )
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }
   
  push(obs:any) {
    super.push(obs);
  }
  confirmTransfer(req: any,action:any) {
console.log(req,"=====",action)
    if(action == 'approve'){
      this.confirmationService.confirm({
        key: 'confirmationJMP',
        message: 'Are you sure you want to approve this JMP '+req.jmpNo+'?',
        accept: () => {
          this.jmpApprove(req.id)
         
      },
      reject:()=>{
        //this.reject()
      }
      });

    }else{


    this.confirmationService.confirm({
        key: 'confirmationJMP',
        message: 'Are you sure you want to reject this JMP '+req.jmpNo+'?',
        accept: () => {
          this.jmpReject(req.id)
         
      },
      reject:()=>{
        //this.reject()
      }
      });
    }
  }
  
  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => {
      if (Object.keys(data).length != 0) {
        this.translate = data
        translateData = data;
        this.initializeColumns();
        this.gridOptions.overlayNoRowsTemplate = `<div class='not-found'><span>` + this.translate?.general.messages.noRecordsFound + `</span></div>`;
        if (this.gridApi && this.gridApi.getDisplayedRowCount() === 0) {
          this.gridApi.showNoRowsOverlay();
        }

      }
    }))
    console.log("translate", this.translate)
  }


  initializeColumns() {
  
    this.columnDefs = [];
   
    const newData  = [
      {
        headerName: this.translate?.dashboard.chubb.fields.jmpNo.singular,
        field: "jmpNo",
        width: 250,
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.jmpNo;
          }
        },
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.dashboard.chubb.fields.date.singular,
        field: "createdDate",
        width: 250,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            return moment(params.data.createdDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: this.translate?.dashboard.chubb.fields.submittedBy.singular,
        field: "submittedByUser.fullName",
        width: 150,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params && params.data && params.data.submittedByUser) {
            return params.data.submittedByUser.fullName;
          }
        },
      },
      {
        headerName: this.translate?.dashboard.chubb.fields.status.singular,
        width: 150,
        field: "orderStatus",
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.approvalStatus == "SUBMITTED") {
              return (
                '<span class="p-mr-2 p-tag p-component" style="background-color: #C4C8CB50 !important ;color: #C4C8CB"><span class="p-tag-value text-dark">' +
                translateData?.general.status.submitted.singular +
                "</span></span>"
              );
            } else if (params.data.approvalStatus == "APPROVED") {
              return (
                '<span class="p-mr-2 p-tag p-component" style="background-color: #1aac2050 !important ;color: #1aac20"><span class="p-tag-value text-dark">' +
                translateData?.general.status.approved.singular +
                "</span></span>"
              );
            } else {
              return (
                '<span class="p-mr-2 p-tag p-component" style="background-color: #db538350 !important ;color: #db5383"><span class="p-tag-value text-dark">' +
                translateData?.general.status.rejected.singular +
                "</span></span>"
              );
            }
          }
        },
      },
      {
        headerName: this.translate?.general.buttons.action.singular,
        filter: false,
        sortable: false,
        width: 150,
        headerClass: "center-header-column",
        cellClass: "text-center",
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.jmpClickEvent.bind(this),
          hidden: { update: true, remove: false },
          labelJMP: "JMP",
          permissions: { update: false, remove: true },
        },
        headerTooltip: "#",
      },
    ];
  
    this.columnDefs = newData;
      if (this.gridApi) {
        this.gridApi.setColumnDefs(this.columnDefs);
        setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
      }
  }

}
