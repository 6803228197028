import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-preview-email',
  templateUrl: './preview-email.component.html',
  styleUrls: ['./preview-email.component.scss']
})
export class PreviewEmailComponent implements OnInit {

  data: any;
  translate: any
  constructor(public modalRef : BsModalRef) { }

  ngOnInit(): void {
    console.log(this.data);
    this.translate = this.data.translate;
    if(this.data.tableHeaders){
    this.data.tableHeaders.forEach((element: any) => {
      element.header = '{{'+element.label+'}}';
    })
  }
  }

}
