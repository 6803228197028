import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class WebSocketsService {

  public refreshMessages$: EventEmitter<any>;
  public refreshSFMessages$: EventEmitter<any>;
  public refreshNotifications$: EventEmitter<any>;
  public refreshShiftsCancelNotifications$: EventEmitter<any>;
  public refreshLocations$: EventEmitter<any>;
  public refreshOnlineStatus$: EventEmitter<any>;
  public refreshGCelenderMessages$: EventEmitter<any>;
  public refreshActivityNotifications$: EventEmitter<any>;
  constructor() { 
    this.refreshMessages$ = new EventEmitter();
    this.refreshSFMessages$ = new EventEmitter();
    this.refreshNotifications$ = new EventEmitter();
    this.refreshShiftsCancelNotifications$ = new EventEmitter();
    this.refreshLocations$ = new EventEmitter();
    this.refreshOnlineStatus$ = new EventEmitter();
    this.refreshGCelenderMessages$=new EventEmitter();
    this.refreshActivityNotifications$ = new EventEmitter();
  }

  public emitMessage(message : any) { 
    this.refreshMessages$.emit(message);
  }

  public emitNotification(notification : any) { 
    this.refreshNotifications$.emit(notification);
  }

  public emitShiftsCancelNotification(notification : any) { //call this in shifts component
    this.refreshShiftsCancelNotifications$.emit(notification);
  }
  public emitLocation(location : any) { 
    this.refreshLocations$.emit(location);
  }
  
  public emitOnlineStatus(status : any) { 
    this.refreshOnlineStatus$.emit(status);
  }

  public emitSalesforceMessage(message : any) { 
    this.refreshSFMessages$.emit(message);
  }

  public emitGoogleCalenderMessage(message : any) { 
    this.refreshGCelenderMessages$.emit(message);
  }
  
  public emitActivityNotification(notification : any) { 
    this.refreshActivityNotifications$.emit(notification);
  }


}
