<div class="left-right-space">
  <div class="table-space ag-grid-table-full" style="height: 50vh">
    <ag-grid-angular
      style="height: 100%"
      class="ag-theme-balham"
      [maxConcurrentDatasourceRequests]="-1"
      [suppressDragLeaveHidesColumns]="true"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultCol"
      (gridReady)="onGridReady($event)"
      [frameworkComponents]="frameworkComponents"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    ></ag-grid-angular>
    <div class="col-12">
      <p class="ag-record-row-summary-panel padds text-end">
        {{ totalRecord }}&nbsp;{{'general.messages.records'}}
      </p>
    </div>
  </div>
</div>
