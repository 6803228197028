import * as moment from "moment-timezone";

export const generalTicketsColumns = [
  {
    headerName: "Ticket No.",
    field: "ticketNo",
    colId: "ticketNo",
    width: 170,
    metaData: { navigationTo: "tickets" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Subject",
    field: "subject",
    width: 250,
    filter: false,
    headerTooltip: "#",
    //translatePath: "ticket.fields.subject.singular", // Add translate path
  },
  {
    headerName: "Type",
    field: "ticketType.name",
    width: 100,
    cellClass: function (params: any) {
      return [""];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Customer",
    field: "customer.name",
    width: 170,
    metaData: { navigationTo: "customers" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Assets",
    field: "assets.name",
    width: 130,
    metaData: { navigationTo: "assets" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Created Date & Time",
    field: "createdDate",
    width: 150,
    cellClass: function (params: any) {
      return [""];
    },
    cellRenderer: function (params: any) {
      if (params.data) {
        if (
          params.data.createdDate !== null ||
          (params.data.createdDate !== undefined &&
            params.data.createdDate !== "")
        ) {
          return (
            moment(params.data.createdDate).format("hh:mm A") +
            ", " +
            moment(params.data.createdDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            )
          );
        } else {
          return "";
        }
      }
    },
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ["inRange"],
      defaultOption: "inRange",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
  {
    headerName: "Last Modified Date & Time",
    field: "lastModifiedDate",
    metaData: { navigationTo: "users" },
    width: 210,
    cellClass: function (params: any) {
      return [""];
    },
    cellRenderer: function (params: any) {
      if (params.data && params.data.lastModifiedByUser) {
        if (
          params.data.lastModifiedByUser !== null ||
          (params.data.lastModifiedByUser !== undefined &&
            params.data.lastModifiedByUser !== "")
        ) {
          return `<span class="">${
            params.data.lastModifiedByUser.fullName
          }</span>, <span>${
            moment(params.data.lastModifiedDate).format("hh:mm A") +
            ", " +
            moment(params.data.lastModifiedDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            )
          }</span>`;
        } else {
          return "";
        }
      }
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Owner",
    field: "ticketOwner.fullName",
    width: 130,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Status",
    width: 140,
    field: "ticketStatus.fieldLabel",
    cellRenderer: (param: any) => {
      let status = "";
      if (param.data && param.data.ticketStatus) {
        const colorCode = param.data.ticketStatus.colorCode ? param.data.ticketStatus.colorCode : "#6366F1";

        status =
          ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
          colorCode +
          "50 !important ;color:" +
          colorCode +
          '"><span class="p-tag-value text-dark">' +
          param.data.ticketStatus.fieldLabel +
          "</span></span>";
      }

      return status;
    },
    filter: "text",
    filterParams: {
      filterOptions: ["contains"],
      defaultOption: "contains",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
];

export const additionalColsExtras = [
  {
    headerName: "Channel",
    field: "channel.name",
    width: 100,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "Contact Name",
    field: "contact.fullName",
    width: 140,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "Created By",
    field: "createdByUser.fullName",
    width: 140,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "Department",
    field: "department.name",
    width: 140,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "Description",
    field: "description",
    width: 200,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "Due Date",
    field: "dueDate",
    width: 150,
    filter: false,
    hide: true,
    cellRenderer: function (params: any) {
      if (params.data && params.data.dueDate) {
        return moment(params.data.dueDate).format(
          localStorage.getItem("date_format")!.toUpperCase()
        );
      } else {
        return "";
      }
    },
    headerTooltip: "#",
  },
  {
    headerName: "Due Time",
    field: "dueTime",
    width: 100,
    filter: false,
    hide: true,
    cellRenderer: function (params: any) {
      if (params.data) {
        if (
          params.data.dueTime !== null ||
          (params.data.dueDate !== undefined && params.data.dueTime !== "")
        ) {
          return moment(params.data.dueTime).format("hh:mm A");
        } else {
          return "";
        }
      }
    },
    headerTooltip: "#",
  },
  {
    headerName: "Fault Type",
    field: "faultType.name",
    width: 130,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "Group",
    field: "userGroup.name",
    width: 130,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "Priority",
    field: "priority.name",
    width: 100,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "Resolution Type",
    field: "resolution",
    width: 150,
    filter: false,
    hide: true,
    headerTooltip: "#",
  },
  {
    headerName: "SLA Package(s)",
    field: "slaPackages",
    width: 150,
    filter: false,
    hide: true,
    cellRenderer: function (params: any) {
      if (params.data) {
        if (params.data.slaPackages && params.data.slaPackages.length > 0) {
          const packs = params.data.slaPackages;
          const moments = packs.map((x: any) => moment(x.createdDate));

          let max = moment.min(moments);
          let selectedPack;

          for (let pack of packs) {
            if (moment(pack.createdDate).unix() === max.unix()) {
              selectedPack = pack;
              break;
            }
          }

          return selectedPack.name;
        } else {
          return "";
        }
      }
    },
    headerTooltip: "#",
  },
  {
    headerName: "Warranty End Date",
    field: "warrantyEndDate",
    width: 150,
    filter: false,
    hide: true,
    cellRenderer: function (params: any) {
      if (params.data && params.data.dueDate) {
        if (
          params.data.dueDate !== null ||
          (params.data.dueDate !== undefined && params.data.dueDate !== "")
        ) {
          return (
            moment(params.data.dueDate).format("hh:mm A") +
            ", " +
            moment(params.data.dueDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            )
          );
        } else {
          return "";
        }
      }
    },
    headerTooltip: "#",
  },
];

// FOR QUEUE ONLY
export const queueTicketColumns = [
  {
    headerName: "Ticket No",
    field: "ticketNo",
    colId: "ticketNo",
    width: 170,
    metaData: { navigationTo: "tickets" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Subject",
    field: "subject",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Customer",
    field: "customer.name",
    width: 170,
    metaData: { navigationTo: "customers" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Assets",
    field: "assets.name",
    width: 130,
    metaData: { navigationTo: "assets" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Created Date & Time",
    field: "createdDate",
    width: 150,
    cellClass: function (params: any) {
      return [""];
    },
    cellRenderer: function (params: any) {
      if (params.data) {
        if (
          params.data.createdDate !== null ||
          (params.data.createdDate !== undefined &&
            params.data.createdDate !== "")
        ) {
          return (
            moment(params.data.createdDate).format("hh:mm A") +
            ", " +
            moment(params.data.createdDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            )
          );
        } else {
          return "";
        }
      }
    },
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ["inRange"],
      defaultOption: "inRange",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
  {
    headerName: "Priority",
    field: "priority.name",
    width: 100,
    cellClass: function (params: any) {
      return [""];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Type",
    field: "ticketType.name",
    width: 100,
    cellClass: function (params: any) {
      return [""];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Last Modified Date & Time",
    field: "lastModifiedDate",
    width: 210,
    cellClass: function (params: any) {
      return [""];
    },
    metaData: { navigationTo: "users" },
    cellRenderer: function (params: any) {
      if (params.data) {
        if (
          params.data.lastModifiedByUser !== null ||
          (params.data.lastModifiedByUser !== undefined &&
            params.data.lastModifiedByUser !== "")
        ) {
          return `<span class="text-1-5 f-blue hand">${
            params.data.lastModifiedByUser.fullName
          }</span>, <span>${
            moment(params.data.lastModifiedDate).format("hh:mm A") +
            ", " +
            moment(params.data.lastModifiedDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            )
          }</span>`;
        } else {
          return "";
        }
      }
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Status",
    width: 140,
    field: "ticketStatus.fieldLabel",
    cellRenderer: (param: any) => {
      let status = "";
      if (param.data && param.data.ticketStatus) {
        status =
          ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
          param.data.ticketStatus.colorCode +
          "50 !important ;color:" +
          param.data.ticketStatus.colorCode +
          '"><span class="p-tag-value text-dark">' +
          param.data.ticketStatus.fieldLabel +
          "</span></span>";
      }

      return status;
    },
    filter: "text",
    filterParams: {
      filterOptions: ["contains"],
      defaultOption: "contains",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
  {
    headerName: "Action",
    filter: false,
    sortable: false,
    width: 150,
    headerClass: "marging-auto hide-action-border",
    cellClass: "text-center",
    cellRenderer: "ActionButtonRenderer",
    cellRendererParams: {},
    headerTooltip: "#",
  },
];

// -- ON SEARCH TABLE -- //

// ASSETS
export const assetsColumns = [
  {
    headerName: "Name",
    field: "name",
    width: 130,
    metaData: { navigationTo: "assets" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Asset Code",
    field: "assetsCode",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Serial Number",
    field: "serialNo",
    width: 150,
    cellClass: function (params: any) {
      return [""];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Customer",
    field: "customer.name",
    width: 200,
    metaData: { navigationTo: "customers" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: "text",
    filterParams: {
      filterOptions: ["contains"],
      defaultOption: "contains",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
  {
    headerName: "Type",
    field: "assetsType.name",
    width: 140,
    filter: "text",
    filterParams: {
      filterOptions: ["contains"],
      defaultOption: "contains",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
  {
    headerName: "Make/Model",
    field: "model",
    width: 200,
    filter: false,
    cellRenderer: function (params: any) {
      let makeModel = "";
      if (
        params.data &&
        params.data.hasOwnProperty("model") &&
        params.data.model != null
      ) {
        makeModel = params.data.model
          ? params.data.model
          : "" + params.data.manufacturer
          ? "/"
          : "" + params.data.manufacturer
          ? params.data.manufacturer
          : "";
        return makeModel;
      } else {
        return "";
      }
    },
    headerTooltip: "#",
  },
  {
    headerName: "Status",
    field: "assetsStatus.name",
    width: 150,
    filter: false,
    headerTooltip: "#",
  },
];

// CUSTOMERS
export const customersColumns = [
  {
    headerName: "Customer Name",
    field: "name",
    width: 200,
    metaData: { navigationTo: "customers" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Service Zone",
    field: "serviceZone.name",
    width: 250,
    filter: "text",
    filterParams: {
      filterOptions: ["contains"],
      defaultOption: "contains",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
  {
    headerName: "Category",
    field: "categoryType.name",
    width: 150,
    cellClass: function (params: any) {
      return ["text-1-5"];
    },
    filter: "text",
    filterParams: {
      filterOptions: ["contains"],
      defaultOption: "contains",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
  {
    headerName: "Contact",
    field: "contactPersonName",
    metaData: { navigationTo: "customers" },
    width: 200,
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Phone",
    field: "phone",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Email",
    field: "email",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Customer Reference ID",
    field: "customerReferenceNo",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
];

// JOB ORDERS
export const jobordersColumns = [
  {
    headerName: "Job Order No.",
    field: "jobOrderNumber",
    width: 200,
    metaData: { navigationTo: "jobOrders" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Name",
    field: "jobOrderName",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Customer",
    field: "customer.name",
    width: 150,
    metaData: { navigationTo: "customers" },
    cellClass: function (params: any) {
      return [""];
    },
    filter: "text",
    filterParams: {
      filterOptions: ["contains"],
      defaultOption: "contains",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
  {
    headerName: "Asset",
    field: "asset.name",
    width: 200,
    metaData: { navigationTo: "assets" },
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Order Date",
    field: "installationDate",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Type",
    field: "jobOrderType.name",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Ticket No.",
    field: "ticket.ticketNo",
    metaData: { navigationTo: "joTickets" },
    width: 200,
    cellClass: function (params: any) {
      return ["table_default_color cursor-pointer"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Status",
    field: "status",
    width: 200,
    filter: "text",
    cellRenderer: "statusRenderer",
    filterParams: {
      filterOptions: ["contains"],
      defaultOption: "contains",
      suppressAndOrCondition: true,
    },
    headerTooltip: "#",
  },
];

// sort model columns array
// to help filter and return label value for API instead of ticketOwner.fullName - you get ticketOwner
export const helpdeskColumnsArray = [
  {
    table: ["tickets"],
    field: "ticketOwner.fullName",
    label: "ticketOwner",
  },
  {
    table: ["assets", "jobOrders"],
    field: "customer.name",
    label: "customerName",
  },
  {
    table: ["tickets"],
    field: "ticketStatus.fieldLabel",
    label: "ticketStatus",
  },
  {
    table: ["jobOrders"],
    field: "status",
    label: "joStatus",
  },
  {
    table: ["assets"],
    field: "assetsType.name",
    label: "assetsTypeName",
  },
  {
    table: ["customers"],
    field: "serviceZone.name",
    label: "serviceZoneName",
  },
  {
    table: ["customers"],
    field: "categoryType.name",
    label: "categoryTypeName",
  },
];
