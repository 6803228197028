<div>
    <div class="flex-between"> 
     <i class="fa-regular fa-xmark w-24px dialog-close-icon" id="btn_CusClose"  [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="closeCustomer()"></i>
    </div>
    <div class="position-relative text-start">
      <span class="title" id="label_CusSelectCustomerList"> {{translate?.general.commonComponent.sections.sec5.singular}}</span>
      <div class="flex-align-center mt-2">
        <span class="p-input-icon-left w-100 py-2">
          <i class="pi pi-search"></i>
          <input type="text" pInputText autocomplete="off" id="input_CusSearchCustomerField" [placeholder]="translate?.general.buttons.search.singular" [(ngModel)]="searchValue"/>
        </span>
      </div>
      <div class="height-42-stat border" *ngIf="translate">
        <app-customer-table [lookupFilter]="lookupFilter" [searchValue]="searchValue" [translate]="translate"></app-customer-table>
      </div>
    </div>
  
  </div>