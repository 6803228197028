<div class="left-right-space">
    
    <div class="flex-between mb-2">
        <div class="main-list-header" *ngIf="!noJMP" id="title_ChubbJmp" >
            {{translate?.dashboard.chubb.sections.sec16.singular}}
        </div>

        <!-- Filter and New -->
        <div class="custella-dashboard-top-right d-flex ms-auto">
            <button *ngIf="!noJMP" class="btn-list-icon ms-2" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" (click)="refresh()" id="btn_SetInvLocRefresh">
                <i class="fa-regular fa-arrows-rotate"></i>
            </button>
            <div class="p-inputgroup search ms-2" *ngIf="!noJMP">
                <span class="p-input-icon-right ">
                  <input type="text" class="w-100" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" [(ngModel)]="searchValue" (keydown.enter)="quickSearch()"  id="input_SetInvLocSearchField" (keyup)="onSearch()" />
                  <i *ngIf="searchValueData" id="btn_SetInvLocSearchClear" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()"></i>
                </span>   
                <button type="button" pButton icon="fa-regular fa-magnifying-glass" id="btn_SetInvLocSearch" (click)="quickSearch()"></button>
            </div>
            
      
          </div>
    </div>
</div>

<div *ngIf="noJMP" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
        <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" />
        <p class="text-6 my-1 font-weight-bold" >
            {{translate?.general.messages.noRecordsFound}}
        </p>
    </div>
</div>

<div class="left-right-space position-relative fw-normal d-flex" style="height: calc(100% - 75px);">
    <div class="table-space ag-grid-table-full" style="height: 100%;" [hidden]="noJMP">
        <ag-grid-angular
            #agGrid
            id="jmpTable"
            style="height: 100%;"
            class="ag-theme-balham"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [cacheOverflowSize]="2"
            [maxConcurrentDatasourceRequests]="-1"
            [suppressDragLeaveHidesColumns]="true"
            [allowContextMenuWithControlKey]="true"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="view($event)"
            (gridReady)="onGridReady($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [getContextMenuItems]="getContextMenuItems"
        >
        </ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end"  id="label_ChubbJmpTableRec">
                {{ totalRecord }} {{ translate?.general.messages.records }}
            </p>
        </div>
    </div>
</div>
<!--=======-=======-=======-=======-=======-=======-=======-======= Accept and Cancel Buttone Action -=======-=======-=======-=======-=======-->
<div class="custella-custom-dialog" >
    <p-confirmDialog #acceptDeclineConfirmationJMP  [style]="{width: '30vw'}" [baseZIndex]="10000" key="confirmationJMP">
        <ng-template pTemplate="header">
            <div class="d-flex align-items-center">

                <img  src="assets\svg\dialog\Confirmation-w.svg"> 
                <div  class="text-16 font-medium ms-3" id="title_InvProdTransferBin" >{{translate?.dashboard.chubb.sections.sec16.singular}}</div>

            
            </div>
        </ng-template>
        <p-footer>
            <div class="d-flex align-items-center justify-content-end">
                <button pButton class="custella-btn-white text-12 ms-2" id="btn_InvProdTransBinCancel" (click)="acceptDeclineConfirmationJMP.reject()"> {{ translate?.general.buttons.cancel.singular }}</button>
                <button  pButton type="button" [label]="translate?.general.buttons.yes.singular" id="btn_InvProdTransBinAccept" iconPos="left" class="p-button-danger ms-2 h-32" (click)="acceptDeclineConfirmationJMP.accept()"></button>
            </div>
        </p-footer>
    </p-confirmDialog>
</div>