import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  public static publicEncryptionKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu2YkyCdXpTjTrKDZfiotQlZkVpAZESSBM43+f2G7cdB1SfJaM/ACMyil79ABtkvcxGFLSxn8CmoRX016myXIQDbZ6MdT/e7FbsdwWRPrkCPKaze/kBYV8lC8TWAadFSdpJQInObT6L/FQYO0KwsQdkOrkUR+6fshz7zBG1zywJrRCZ0M6BVpcYOT8Z8qJmV5yK+CSp7UvB6LL0LhRryv42x23ze1jXtamjVppznw3Hi7fSlKMhlYPlmIpdecCTaerGWU+WynWvmx+LadydXdZwlhI4rLaDMiL0/UuGHrz5e5fkyWbF52L6lgY+NrdADmFdjqLMAnuqpmHGXy7xqjZQIDAQAB";
  
  constructor() { }

  ngOnInit(): void {
  }

}
