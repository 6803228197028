
import {of as observableOf,  Observable, throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ProfileFeaturesService {

  private ENDPOINT_PROFILE_FEATURE="/users/profile-feature/";
  private ENDPOINT_CHANGE_PASSWORD="/users/change-password";

  constructor(private http: HttpClient) { }

  public getProfileFeatures(){
    return this.http.get( environment.base_url+this.ENDPOINT_PROFILE_FEATURE).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return throwError( error );
    }),);
  }

  public changePassword(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_CHANGE_PASSWORD, body, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }
  
}
