// import { OnDestroy } from '@angular/core';
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs/Subscription";
@Injectable()
export class SubscriptionUtil {
    protected subscriptions: Subscription[] = [];

    push(obs:any) {
      //  console.log(this.subscriptions)
        this.subscriptions.push(obs);
        //console.log(this.subscriptions)
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s: any) => { s.unsubscribe() });
       // console.log(this.subscriptions)
    }
}