import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  displayData: boolean = false
  constructor(
    private router : Router
  ) { }

  ngOnInit(): void {
    this.displayData=true
  }
}
