<div class="custella-modal d-flex flex-column">
  <div class="custella-modal-header">
    <div class="custella-modal-header-text">{{translate?.asset.sections.sec9.singular}}</div>
    <i class="fa-regular fa-xmark pt-1" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="modalRef.hide()"></i>
  </div>

  <div class="custella-modal-body">
    <div class="custella-content-row-input d-flex justify-content-center">
      <app-barcode-generator *ngIf="barCodeType !== 'QR Generator'" [barCodeType]="barCodeType" [value]="data?.serialNo"></app-barcode-generator>
      <app-qr-generator *ngIf="barCodeType === 'QR Generator'" [value]="data?.serialNo"></app-qr-generator>
    </div>
  </div>

  <div class="custella-modal-footer flex-align-center justify-content-end">
    <button pButton icon="fa-regular fa-download" [label]="translate?.general.buttons.download.singular" class="p-button-outlined p-button-secondary h-32" (click)="downloadQRCode()"></button>
  </div>
</div>
