<div class="custella-custom-dialog">
    <p-confirmDialog #cd  [style]="{width: '33vw'}" [baseZIndex]="10000" key="confirm1">
        <ng-template pTemplate="header">
            <div class="d-flex align-items-center">
                <img  *ngIf="!data.deleteIconType " id="img_DialogDelete" src="assets\svg\dialog\Danger-w.svg">
                <div *ngIf="!data.deleteIconType && !data.displayDeleteTitle" id="title_DialogDelete" class="text-16 font-medium ms-3">{{  translate?.general.commonComponent.messages?.deleteConfirmation +" "+deleteHeader }}</div>

                <div *ngIf="data.displayDeleteTitle" id="title_DialogDelete" class="text-16 font-medium ms-3">{{deleteHeader }}</div>

                <img  *ngIf="data.deleteIconType == 'confirm'" id="img_DialogConfirmation" src="assets\svg\dialog\Confirmation-w.svg"> 
                <div *ngIf="data.deleteIconType == 'confirm'" id="title_DialogConfirmation" class="text-16 font-medium ms-3">{{deleteHeader}}</div>
            </div>
        </ng-template>
        <p-footer>
            <div class="d-flex align-items-center justify-content-end">
                <button class="custella-btn-white text-12 ms-2 me-0" id="btn_DialogCancel" (click)="cd.reject()">{{translate?.general.buttons.cancel.singular}}</button>
                <button  *ngIf="!data.deleteIconType"  pButton type="button" id="btn_DialogDelete" [label]="translate?.general.buttons.delete.singular" icon="fa-regular fa-trash" iconPos="left" class="p-button-danger ms-2 me-0 h-32" (click)="cd.accept()"></button>
                <button  *ngIf="data.deleteIconType == 'confirm'"  pButton type="button" id="btn_DialogSubmit" [label]="translate?.general.buttons.submit.singular" iconPos="left" class="p-button-danger ms-2 me-0 h-32" (click)="cd.accept()"></button>
            </div>
        </p-footer>
    </p-confirmDialog>
</div>
