import { Component, OnInit } from '@angular/core';
import { Router ,NavigationEnd} from '@angular/router';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { MenuService } from 'src/app/_services/menu.service';

@Component({
  selector: 'app-task-frame',
  templateUrl: './task-frame.component.html'
})
export class TaskFrameComponent implements OnInit {
  initialData: any[] = [];
  translate: any;

  constructor(private menuItemService: MenuService,
    public router: Router,
    private languageTranslateService: LanguageTranslateService
    ) { 
      this.languageTranslateService.menuSrc$.subscribe(data => {
        if(Object.keys(data).length != 0){ this.translate = data
          console.log("translateTask",this.translate)
          this.initialData = this.menuItemService.getMenuTask();
        }
      })
    this.initialData = this.menuItemService.getMenuTask();
   }

  ngOnInit(): void {
    
  }

  isExpandable(node: any) {
    return node.data.length == 0 ? false : true;
  }


}
