import { Pipe, PipeTransform } from "@angular/core";

// reference
//https://dev.to/this-is-angular/search-and-highlight-text-feature-using-angular-l98

@Pipe({
  name: "highlightText",
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, args: any, type: string) {
    if (!args) return value;

    if (type === "full") {
      const re = new RegExp("\\b(" + args + "\\b)", "igm");
      if (value) {
        value = value.replace(re, '<span class="highlighted-text">$1</span>');
      }
    } else {
      const re = new RegExp(args, "igm");
      if (value) {
        value = value.replace(re, '<span class="highlighted-text">$&</span>');
      }
    }
    return value;
  }
}
