import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { HELPDESK_APP_EVENTS } from "src/app/_models/global.data.model";
import { HelpdeskService } from "src/app/_services/helpdesk.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public initialData: any[] = [];

  @Input() count: any = {};

  private allSubscriptions: Subscription[] = [];

  public menus = [];
  translate: any;

  /*

       {name:"General",value:"general",data:[
            {name:"Organisation Information",value:"OrganisationInformation", routerLink:"generalSettings"},
            {name:"Default Formats",value:"DefaultFormats", routerLink:"defaultFormats"},
            {name: "License and Billing",value:"LicenseBilling", routerLink:"licenseBilling"},
            // {name: "License and Billing",value:"LicenseBilling", routerLink:"dashboard/setup/company"},
            {name: "Org Wide Working Hours",value:"OrgWideWorkingHours", routerLink:"orgWorkingHours"},
          ]},

  */

  constructor(
    private utilService: UtilServiceService,
    private helpdeskService: HelpdeskService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    this.getLanguageData();
  }

  ngOnInit() {
    this.init();

   
  }

  ngOnDestroy(): void {
    this.cleanUp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.count && Object.keys(this.count).length > 0) {
        this.processCount(this.count);
      }
    }
  }

  private init() {
    // set up for on tab changes
    // for main tab (first one) usually
    const sub = this.utilService.getData().subscribe(async (data: any) => {
      if (data) {
        if (data.action === HELPDESK_APP_EVENTS.ON_TAB_CHANGE) {
          const name = data.data.name;

          this.findMenu(name);
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_UPDATE_TICKET_MENU_COUNT) {
          const updateWhere = data.data.updateWhere;
          const mathOperation = data.data.mathOperation;
          const value = data.data.value;
          this.updateCount(updateWhere, mathOperation, value);
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_DELETE_TICKET) {
          const countData = await this.helpdeskService
            .getTicketCount()
            .toPromise();

          this.processCount(countData);
        }
      }
    });

    this.allSubscriptions.push(sub);
  }

  public isExpandable(node: any) {
    if (node && node.subMenu) {
      return node.subMenu.length == 0 ? false : true;
    }
  }

  public openurl(node: any) {
    if (node.routerLink) {
      // this.router.navigate([node.routerLink]);
    }
  }

  // for queue and recently viewed usage
  private updateCount(
    whereToUpdate: string,
    mathOperation: string,
    value: number
  ): void {
    if (mathOperation === "+") {
      this.menus.forEach((menu: any) => {
        if (menu.name === whereToUpdate) {
          menu.notifications += value;
        }
      });
    }

    if (mathOperation === "-") {
      this.menus.forEach((menu: any) => {
        if (menu.name === whereToUpdate) {
          menu.notifications -= value;
        }
      });
    }
  }

  // find tab based on which is active
  private findMenu(name: any) {
    let foundMenuItem = null;

    outerLoop: for (let m = 0; m < this.menus.length; m++) {
      if (this.menus[m].name === name && this.menus[m].active) {
        foundMenuItem = this.menus[m];
        break;
      }

      if (this.menus[m].hasOwnProperty("subMenu")) {
        const subMenus = this.menus[m].subMenu;

        for (let sm = 0; sm < subMenus.length; sm++) {
          if (subMenus[sm].name === name && subMenus[sm].active) {
            foundMenuItem = subMenus[sm];
            break outerLoop;
          }
        }
      }
    }

    if (foundMenuItem !== null) {
      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.GET_TICKET_MENU,
        data: foundMenuItem,
      });
    }
  }

  // when selecting a menu item
  public onSelect(menuItem: any): void {
    for (let m = 0; m < this.menus.length; m++) {
      this.menus[m].active = false;
      if (this.menus[m].hasOwnProperty("subMenu")) {
        const subMenus = this.menus[m].subMenu;

        for (let sm = 0; sm < subMenus.length; sm++) {
          subMenus[sm].active = false;
        }
      }
    }

    menuItem.active = true;

    this.utilService.sendData({
      action: HELPDESK_APP_EVENTS.GET_TICKET_MENU,
      data: menuItem,
    });
  }

  // check if menu item has menu item
  public hasSubMenu(menuItem: any): boolean {
    if (menuItem.hasOwnProperty("subMenu") && menuItem.subMenu.length > 0) {
      return true;
    }

    return false;
  }

  // check if menu is active
  public isActive(menuItem: any): boolean {
    if (menuItem.hasOwnProperty("active")) {
      return menuItem.active;
    }

    return false;
  }

  // process count for ticket menu
  private processCount(countObject: any) {
    if (countObject && Object.keys(countObject).length >= 1) {
      for (let m = 0; m < this.menus.length; m++) {
        if (this.menus[m].name === "queue") {
          this.menus[m].notifications = countObject["countForQueue"];
        }

        const subMenu = this.menus[m].subMenu as any[];

        for (let sm = 0; sm < subMenu.length; sm++) {
          const name = subMenu[sm].name;
          const parent = subMenu[sm].parent;
          const notification = subMenu[sm].notifications;

          if (notification !== null && parent === "my_tickets") {
            if (name === "open_&_pending_tickets") {
              subMenu[sm].notifications =
                countObject["myTicketCountForOpenAndPending"];
            }

            if (name === "overdue_tickets") {
              subMenu[sm].notifications =
                countObject["myTicketCountForOverDue"];
            }

            if (name === "unresolved_tickets") {
              subMenu[sm].notifications =
                countObject["myTicketCountForUnresolved"];
            }

            if (name === "due_today") {
              subMenu[sm].notifications =
                countObject["myTicketCountForDueToday"];
            }
          }

          if (notification !== null && parent === "all_tickets") {
            if (name === "open_&_pending_tickets") {
              subMenu[sm].notifications =
                countObject["allTicketCountForOpenAndPending"];
            }

            if (name === "overdue_tickets") {
              subMenu[sm].notifications =
                countObject["allTicketCountForOverDue"];
            }

            if (name === "unresolved_tickets") {
              subMenu[sm].notifications =
                countObject["allTicketCountForUnresolved"];
            }

            if (name === "due_today") {
              subMenu[sm].notifications =
                countObject["allTicketCountForDueToday"];
            }
          }
        }
      }
    }
  }

  // clean up
  private cleanUp() {
    this.allSubscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  public changeData(data: any) {
    console.log("-----");
    $(".accordion-button").attr("aria-expanded", "false");
    $(".accordion-button").addClass("collapsed");
    $(".setup-sub-manu").removeClass("show");
  }

  getLanguageData() {
    this.allSubscriptions.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        console.log("translate",this.translate)

        this.menus = [{
          name: this.translate?.helpdesk.sections.sec0.plural,
          label: this.translate?.helpdesk.sections.sec0.plural,
          notifications: 0,
          active: false,
          parent: null,
          hide: false,
          subMenu: [],
        },
        {
          name:  this.translate?.helpdesk.sections.sec1.plural,
          label:  this.translate?.helpdesk.sections.sec1.plural,
          notifications: null,
          active: false,
          parent: null,
          hide: false,
          subMenu: [],
        },
        {
          name:  this.translate?.helpdesk.sections.sec2.plural,
          label:  this.translate?.helpdesk.sections.sec2.plural,
          active: false,
          parent: null,
          hide: false,
          notifications: null,
          subMenu: [
            {
              name:  this.translate?.helpdesk.fields.allMyTickets.plural,
              label:  this.translate?.helpdesk.fields.allMyTickets.plural,
              notifications: null,
              parent: this.translate?.helpdesk.sections.sec2.plural,
              active: true,
            },
            {
              name: this.translate?.helpdesk.fields.openPendingTickets.plural,
              label: this.translate?.helpdesk.fields.openPendingTickets.plural,
              notifications: 0,
              parent: this.translate?.helpdesk.sections.sec2.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.overdueTickets.plural,
              label:this.translate?.helpdesk.fields.overdueTickets.plural,
              notifications: 0,
              parent: this.translate?.helpdesk.sections.sec2.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.highPriorityTickets.plural,
              label: this.translate?.helpdesk.fields.highPriorityTickets.plural,
              notifications: null,
              parent: this.translate?.helpdesk.sections.sec2.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.unresolvedTickets.plural,
              label: this.translate?.helpdesk.fields.unresolvedTickets.plural,
              notifications: 0,
              parent: this.translate?.helpdesk.sections.sec2.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.dueToday.plural,
              label: this.translate?.helpdesk.fields.dueToday.plural,
              notifications: 0,
              parent: this.translate?.helpdesk.sections.sec2.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.raisedByMe.plural,
              label: this.translate?.helpdesk.fields.raisedByMe.plural,
              notifications: null,
              parent: this.translate?.helpdesk.sections.sec2.plural,
              active: false,
            },
          ],
        },
        {
          name:  this.translate?.helpdesk.sections.sec3.plural,
          label: this.translate?.helpdesk.sections.sec3.plural,
          active: false,
          parent: null,
          hide: false,
          notifications: null,
          subMenu: [
            {
              name: this.translate?.helpdesk.fields.allTickets.plural,
              label: this.translate?.helpdesk.fields.allTickets.plural,
              notifications: null,
              parent: this.translate?.helpdesk.sections.sec3.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.openPendingTickets.plural,
              label:this.translate?.helpdesk.fields.openPendingTickets.plural,
              notifications: 0,
              parent: this.translate?.helpdesk.sections.sec3.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.overdueTickets.plural,
              label: this.translate?.helpdesk.fields.overdueTickets.plural,
              notifications: 0,
              parent: this.translate?.helpdesk.sections.sec3.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.highPriorityTickets.plural,
              label: this.translate?.helpdesk.fields.highPriorityTickets.plural,
              notifications: null,
              parent: this.translate?.helpdesk.sections.sec3.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.unresolvedTickets.plural,
              label: this.translate?.helpdesk.fields.unresolvedTickets.plural,
              notifications: 0,
              parent: this.translate?.helpdesk.sections.sec3.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.dueToday.plural,
              label: this.translate?.helpdesk.fields.dueToday.plural,
              notifications: 0,
              parent: this.translate?.helpdesk.sections.sec3.plural,
              active: false,
            },
            {
              name: this.translate?.helpdesk.fields.recentlyUpdatedTickets.plural,
              label: this.translate?.helpdesk.fields.recentlyUpdatedTickets.plural,
              notifications: null,
              parent: this.translate?.helpdesk.sections.sec3.plural,
              active: null,
            },
          ],
        },]

      }
    }))

  }
}
