import { Component, Input, OnInit } from "@angular/core";



@Component({
  selector: "app-qr-generator",
  templateUrl: "./qr-generator.component.html",
  styleUrls: ["./qr-generator.component.scss"],
})
export class QrGeneratorComponent implements OnInit {
  @Input() value: any;

  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  /******************************************** Init ******************************************/
  private init() {
    // QRCode.toCanvas(
    //   document.getElementById("qrGenerator"),
    //   this.value,
    //   function (error: any) {
    //     if (error) console.error(error);
    //     console.log("success!");
    //   }
    // );
  }
}
