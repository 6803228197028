import { Component, OnInit, Output, EventEmitter,Input, ChangeDetectorRef, OnChanges, ChangeDetectionStrategy, SimpleChanges, AfterViewChecked } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
import { CustomersService } from 'src/app/_services/customers.service';
import { DynamicFormService } from 'src/app/_services/dynamic-form.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
@Component({
  selector: 'custella-autocomplete',
  templateUrl: './custella-autocomplete.component.html',
  styleUrls: ['./custella-autocomplete.component.css'],
  //changeDetection : ChangeDetectionStrategy.Default,
  
})
export class CustellaAutocompleteComponent implements OnInit, AfterViewChecked, OnChanges  {
  @Output() selectedData = new EventEmitter();
  @Input() field: any;
  @Input() parent: any;
  @Input() child: any;
  @Input() mode: any;
  @Input()
  group!: UntypedFormGroup;
  @Input() modifiedField: any;
  selectedValue: boolean =false

  showDropDown = false;
  displayTable: boolean = false;
  searchData: any;
  data: any;
  autoCompleteData:any = null

  showMulDropDown = false;
  displayTableMul: boolean = false;
  dataMul: any;
  autoCompleteMultipleData:any = null
  searchMultiData :any = [];
  lookupFilter: string = '';
  initData = false
  translate: any;
  constructor(
    private customerService: CustomersService,
    private http: HttpClient,
    private  cdr: ChangeDetectorRef,
    private dynamicFormService : DynamicFormService,
    private languageTranslateService: LanguageTranslateService
    ) { 
      this.languageTranslateService.menuSrc$.subscribe(data => {
        if(Object.keys(data).length != 0){ this.translate = data}
      })
      //this.cdr.detach();
      
     
  }

  ngOnInit() {
    
    this.cdr.markForCheck();
    console.log(this.field,"====",this.initData)
    if(!this.initData){
      this.loadData()
    }
  
  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  loadData(){
    this.cdr.detectChanges();
    this.initData = true
    if(this.mode== 'add' && this.field.defaultValue){
    
      if(this.field.multiSelect){
        
        console.log(this.field.defaultValue,"====")
        this.displayMulipleData(this.field.defaultValue)
        // this.field.defaultValue.forEach((element :any)=> {
        //   this.displayMulipleData(element)
        // });

      }else{
        
        this.gotSelectedData(this.field.defaultValue)
      }

    }

    if(this.mode== 'edit'){
     console.log(this.field.value,"=====>>> Step 1")
     if(this.field.value){
      if(this.field.multiSelect){
        console.log(this.field.value,"=====>>> Step 2")
        this.displayMulipleData(this.field.value)
        // this.field.value.forEach((element :any) => {
        //   this.displayMulipleData(element)
        // });
      
      }else{
        this.gotSelectedData(this.field.value)
      }
    }
    }
    
  }
  ngOnChanges(change: SimpleChanges): void {
    console.log(change)
    this.cdr.detectChanges();
   
  }

getCustomerIdsFirstWay(event :any,selectionType :any){
  console.log(this.modifiedField,"====",this.field.lookupFilter)
    var k= event.keyCode
    // let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    // if(k == 8){
    //  this.clearValue()
    // }else{
this.lookupFilter =''
if(this.field.lookupFilter != null){

  this.field.lookupFilter.forEach((lookupFilterElement  :any)=> {

    this.lookupFilter +='&'+lookupFilterElement.paramName+'='
    console.log("**********************************************************************************")
    console.log(lookupFilterElement.valueField,"==============");

    var name = lookupFilterElement.valueField.split(".")
      this.modifiedField.forEach((element :any) => {
        if(element.fields){
            element.fields.forEach((column  :any)=> {
              
              if(name.length == 1){
                var columName = lookupFilterElement.valueField

                          if(columName == column.name && column.value != null){
                            console.log(columName,"==============",column.name,"====",column.value);
                            const keys = Object.keys(column.value);
                              for (let i = 0; i < keys.length; i++) {
                                const key = keys[i];
                                console.log(key,"^^^^^^^^",lookupFilterElement.dataColumn)
                                  if(key == lookupFilterElement.dataColumn){
                                    console.log(key,"=====",lookupFilterElement.dataColumn)
                                    this.lookupFilter += column.value[key]
                                  }
                              }
                           // this.lookupFilter += column.value.id
                            }
              }else{
                if(name[0] == column.name && column.value != null){
                  const keys = Object.keys(column.value);
                    for (let i = 0; i < keys.length; i++) {
                      const key = keys[i];
                        if(key == name[1]){
                          console.log(key,"=====",name[0])
                          this.lookupFilter += column.value[key]
                        }
                    }
                 // this.lookupFilter += column.value.id
                  }


                }
                  
                });
          }
      });

    });

    }


      this.selectedValue = false
      // console.log(this.selectedValue,"====== Reseting data on keypress",this.autoCompleteData)

       if (this.searchData !=null) {

        if (this.field.lookupTo == "customer") {
         
          this.customerService.getCustomerSearch(this.searchData).subscribe((data: any) => {
            console.log(data)
            this.data = data.body.data;
            console.log(this.data)
                this.openDropDown()
            
           })
          
        }else{

          
            this.dynamicFormService.getDynamicSearch(this.field.lookupUrl,this.field.displayColumn,this.searchData , this.lookupFilter).subscribe((data:any)=>{
     
            if(data.body.data != undefined){
              this.data = this.customerService.concatDisplayName(this.field, data.body.data)

            }else{
              this.data = this.customerService.concatDisplayName(this.field, data.body)

            }
            console.log(this.data.length)
           if(this.data.length != 0){
             this.openDropDown()
             }
           })


       
      }
     }
   // }

     
   }
   displayData(value :any){
    
      console.log(value,">>>>>>>>>>>>>>>>>")
    var name = this.field.name
     this.autoCompleteData=value
     if(this.field.searchTo == "Job Order"){
      this.searchData = value
      // this.group.patchValue({
      //   name : value.displayColumn
      //  });
     }else{
      this.searchData = value
      this.group.patchValue({
        name : value.displayColumn
       });
     }
     this.cdr.detectChanges();

   }
   selectValue(value: any) {
     
     console.log(value,"======>>>>>")
    if(value){
      this.autoCompleteData=value
        this.displayData(value)
        this.field.value=value
        this.showDropDown = false;
        this.data=[]
        this.cdr.detectChanges();
        // console.log(value)
        var sendData={
          data:value,
          parent:this.parent,
          child:this.child

        }
        this.searchData = this.autoCompleteData
        this.selectedValue = true
        this.selectedData.emit(sendData);
        console.log(this.selectedValue,"====== We got the value",this.autoCompleteData)
      }
   }
    closeDropDown() {
      this.checkValue()
      this.showDropDown = false;
      this.showMulDropDown = false;
    }
  
    openDropDown() {
      this.showDropDown = true;
    }
    showTable(){
      console.log(this.field,"==============",this.field.lookupFilter);
      this.lookupFilter =''
        if(this.field.lookupFilter != null){

          this.field.lookupFilter.forEach((lookupFilterElement :any) => {

            this.lookupFilter +='&'+lookupFilterElement.paramName+'='
            console.log("**********************************************************************************")
            
            var name = lookupFilterElement.valueField.split(".")
            //console.log(name)
              this.modifiedField.forEach((element :any) => {
                if(element.fields){
                    element.fields.forEach((column  :any)=> {
                      console.log(name[0]," == ",column.name," && ",column.value)
                      if(name.length == 1){
                        var columName = lookupFilterElement.valueField

                        if(columName == column.name && column.value != null){
                          console.log(columName,"==============",column.name,"====",column.value);
                          const keys = Object.keys(column.value);
                            for (let i = 0; i < keys.length; i++) {
                              const key = keys[i];
                              console.log(key,"^^^^^^^^",lookupFilterElement.dataColumn)
                                if(key == lookupFilterElement.dataColumn){
                                  console.log(key,"=====",lookupFilterElement.dataColumn)
                                  this.lookupFilter += column.value[key]
                                }
                            }
                         // this.lookupFilter += column.value.id
                          }
                      }else{
                        if(name[0] == column.name && column.value != null){
                          const keys = Object.keys(column.value);
                            for (let i = 0; i < keys.length; i++) {
                              const key = keys[i];
                                if(key == name[1]){
                                  console.log(key,"=====",name[0])
                                  this.lookupFilter += column.value[key]
                                }
                            }
                         // this.lookupFilter += column.value.id
                          }


                        }
                          
                        });
                  }
              });

            });

            }

            console.log(this.lookupFilter)
      this.displayTable = !this.displayTable;
    }
  
    gotSelectedData(val :any){
    console.log(val)
    if(this.field.displayColumn){
    const keys = Object.keys(val);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
          console.log(key,"==============",this.field.displayColumn,"-------",val[this.field.displayColumn]);
          val.displayColumn = val[this.field.displayColumn] ? val[this.field.displayColumn]:val.name 
         }
          }else{
            val.displayColumn = val.name
        }

    // if(this.field.searchTo == "Job Order"){
    //   val.name = val.jobOrderNumber
      
    //  }else if(this.field.searchTo == "Asset"){
    //   val.name = val.assetsCode
    //  }
    
    this.selectValue(val)
    this.displayTable = false
   }
   closeView(){
     this.displayTable =false
   }
   checkValue() {
    //console.log(this.selectedValue,"====== We are clearing the value with validation")
    if(!this.selectedValue){
     this.autoCompleteData=null
     this.searchData=null
     this.showDropDown = false;
     this.data=[]
    this.field.value = null
    var sendData={
      data:null,
      parent:this.parent,
      child:this.child

    }
    this.selectedData.emit(sendData);

    }
     //console.log("All clear data")
   }
   clearValue() {
    console.log(this.selectedValue,"====== We are clearing the value")
      this.selectedValue = false
     this.autoCompleteData=null
     this.searchData=null
     this.showDropDown = false;
     var sendData={
      data:null,
      parent:this.parent,
      child:this.child

    }
     this.selectedData.emit(sendData);
     this.data=[]
   }
  
  onvalid(){
     if(this.autoCompleteData==null){
       this.searchData=null
     }
   
   }

/*================ Muli select and look  ================*/

   getMultipleData(event :any,selectionType :any){

    var k= event.keyCode
    // let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    // if(k == 8){
    //  this.clearMulipleValue()
    // }else{
      let query = event.query;
      console.log(this.searchMultiData,"=====",this.field,"===",event.query)
       if (query !=null && query.length > 1) {

        if (this.field.lookupTo == "customer") {

          this.customerService.getCustomerSearch(query).subscribe((data: any) => {
            //console.log(data)
            this.data = this.customerService.concatDisplayName(this.field, data.body.data)
           // this.data = data.body.data;
                this.openMulipleDropDown()
            
           })
          
        }else{
          this.dynamicFormService.getDynamicSearch(this.field.lookupUrl,this.field.displayColumn,query ,'').subscribe((data:any)=>{
        if(data.body.data != undefined){
              this.data = this.customerService.concatDisplayName(this.field, data.body.data)

            }else{
              this.data = this.customerService.concatDisplayName(this.field, data.body)

            }
           // console.log(this.data.length)
           if(this.data.length != 0){
             this.openMulipleDropDown()
             }
           })


       
      }
     }
    //}

     
   }

   
   displayMulipleData(value :any){
    
    console.log(value,"=====>>> Step 3")
    this.searchMultiData=value
    this.searchMultiData = [...value]
    this.field.value=[...this.searchMultiData]
    this.data=[]
    this.cdr.detectChanges();
    console.log(this.searchMultiData)
    var sendData={
      data:this.searchMultiData,
      parent:this.parent,
      child:this.child

    }
    console.log(this.searchMultiData)
    //this.selectedData.emit(sendData);

   }
   selectMulipleValue(value :any) {
    let selectrdData = _.filter(this.searchMultiData, (o) => {
      return o.id == value.id;
    });
    console.log(selectrdData[0],"********")
    if(selectrdData[0]){
      ////debugger
    this.searchMultiData.push(value)
    ///this.field.value=this.searchMultiData
   // this.showMulDropDown = false;
    this.data=[]
    //this.cdr.detectChanges();
    console.log(value)
  this.modifiedField[this.parent].fields[this.child] = {...this.modifiedField[this.parent].fields[this.child],value: this.searchMultiData }
    var sendData={
      data:this.searchMultiData,
      parent:this.parent,
      child:this.child

    }
    console.log(this.searchMultiData)
    this.selectedData.emit(sendData);
 
  }else{
    ////debugger
    //this.clearMulipleValue()
  }
  }
  removeData(data :any){

    console.log(data)
    //this.searchMultiData.splice(ind,1)
    this.field.value=this.searchMultiData
    var sendData={
      data:this.searchMultiData,
      parent:this.parent,
      child:this.child

    }
    console.log(this.searchMultiData)
   
  }

   closeMulipleDropDown() {
     this.showMulDropDown = false;
   }
 
   openMulipleDropDown() {
     this.showMulDropDown = true;
   }
   showMulipleTable(){
     this.displayTableMul = !this.displayTableMul;
   }
 
   gotSelectedMulipleData(val :any){
   console.log(val)
   this.selectMulipleValue(val)
   this.displayTableMul =false
  }
  closeMulipleView(){
    this.displayTableMul =false
  }
  clearMulipleValue() {
    this.autoCompleteData=null
    this.searchMultiData=null
    this.showMulDropDown = false;
    this.data=[]
   // console.log("All clear data")
  }
  
   onvalidMulti(){
     if(this.autoCompleteData==null){
       this.searchMultiData=null
     }
     this.cdr.detectChanges();
   
   }


}
