import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'momentDate'
})

export class MomentDatePipe extends DatePipe implements PipeTransform {
  transform(  value: any | moment.MomentInput,format: string  ='mediumDate', timezone: string = 'Asia/Kuala_Lumpur'): any {
    const timezoneOffset = moment(value).tz(timezone).format('Z');
    return super.transform(value , format, timezoneOffset);
    }
  }
