import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-active-reports',
  templateUrl: './active-reports.component.html',
  styleUrls: ['./active-reports.component.scss']
})
export class ActiveReportsComponent implements OnInit {
  urlSafe!: SafeResourceUrl;
  url: any;

 constructor(public sanitizer:DomSanitizer) { }

  ngOnInit() {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', 'http-portal');
    formData.append('username', 'admin');
    formData.append('password', 'admin');
    formData.append("client_secret", "zIkLhjdUVG9nTtHD3UKgIr7p");
    //this.reportService.getPrtalToken(formData).subscribe((res:any) => {
     // console.log(res)
    var access_token = localStorage.getItem( 'access_token');
      this.url= environment.active_report_url+access_token+'&hideavatarmenu=true';
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  //  })


  setTimeout(() => {

    console.log($("#wynframe"))
       var framDocument=$("#wynframe")
       document.querySelector('.nb-footer')



    console.log(document.querySelector('.nb-footer'),"=====")

    $("#wynframe").contents().find(".nb-footer").html("---");

  }, 20000);
  }

}

