import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UsersService } from 'src/app/_services/admin/users.service';

@Component({
  selector: 'app-custella-update',
  templateUrl: './custella-update.component.html',
  styleUrls: ['./custella-update.component.scss']
})
export class CustellaUpdateComponent implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private userService : UsersService,
    public modalRef : BsModalRef
  ) { }

  ngOnInit(): void {
   var req = {
      "userId": this.auth.getUserId(),
      "viewed": true,
      "notice":"V4"
    }
    this.userService.updateWelcomeNotice(req).subscribe((res: any) => {
      console.log(res,"----->>>>>>>")
     
    })
  }

}
