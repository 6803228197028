import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  addonFeature: any;

  constructor(
    private auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.addonFeature = this.auth.getAddonFeatureLists();
  }

}
