import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { MomentDatePipe } from "../../../_pipes/moment-date.pipe";
import { PermissionService } from 'src/app/auth/permission.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { AssetService } from 'src/app/_services/asset.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
var dateFormateChange: any;
@Component({
  selector: 'app-summary-slider',
  templateUrl: './summary-slider.component.html',
  styleUrls: ['./summary-slider.component.scss'],
  
})
export class SummarySliderComponent extends SubscriptionUtil implements OnInit, OnChanges {

  @Input() assetsSummary: any;

  @Input() module: any;
  @Output() onAcceptAsset =  new EventEmitter();
  @Output() onClosetAsset =  new EventEmitter();
  @Output() onChageAssetStatus =  new EventEmitter();

  assetsStatusId: any;
  assetStatuses: any;
  momentpipe: any;
  timezone: any;
  dateFormat: any;
  updateAssets!: boolean;
  translate: any;
  
  constructor( private util: UtilServiceService,
    private perm: PermissionService,
    private auth: AuthenticationService,
    private assetService: AssetService,
    private languageTranslateService: LanguageTranslateService ) {
      super();
      this.languageTranslateService.menuSrc$.subscribe(data => {
        if(Object.keys(data).length != 0){ this.translate = data}
      })
     }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.assetsSummary)
    this.assetsSummary ?  this.assetsStatusId = this.assetsSummary.assetsStatus.id : this.assetsStatusId = null;
    // var dateFormate: any = localStorage.getItem("date_format");
    // if (this.assetsSummary && this.assetsSummary.warrantyEndDate) {
    //   this.assetsSummary.warrantyEndDate =  moment
    //   .tz(this.assetsSummary.warrantyEndDate, dateFormateChange)
    //   .format("hh:mm A" + ", " + dateFormate.toUpperCase());
    // }
   
    console.log(this.assetsSummary)
  }

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem("date_format");
    this.timezone = this.auth.getUserTimezone()
    this.updateAssets = this.perm.capable("assets", "update");

    this.push(
      this.assetService.getStatuses().subscribe((res: any) => {
        this.assetStatuses = res.body;
        console.log("res11", this.assetStatuses)
      })
      
    );
  }

  closeRightNav()
  {
    this.onClosetAsset.emit(true);
  }

  onAssetViewOnNewTab()
  {localStorage.setItem("activeIndexAssetView", "0");
          const id = this.assetsSummary.id;
          const path = `/dashboard/assets/view/${id}?id=${id}`;
          this.util.openItemToNewTab(path);
  }
  onCustomerViewOnNewTab()
  {localStorage.setItem("activeIndexAssetView", "0");
          const id = this.assetsSummary.id;
          const path = `/dashboard/assets/view/${id}?id=${id}`;
          this.util.openItemToNewTab(path);
  }

  onAcceptAssetStatus(data: any, event: any)
  {
      console.log("data",data)
      this.assetsStatusId = event.id;
      const newStatus = this.assetStatuses.find((item : any) => item.id === this.assetsStatusId);
    
      data.oldStatus = data?.assetsStatus.name,
      data.newStatus = newStatus.name;

      this.onAcceptAsset.emit(data)
      this.onChageAssetStatus.emit(this.assetsStatusId)
      console.log("data",data)

  }

    /******************************************** Add ALl the Subscription ******************************************/
    push(obs: any) {
      super.push(obs);
    }
  

}
