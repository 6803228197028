
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../auth/authentication.service';

@Injectable()
export class ProfilesService {

  refreshProfilePhoto$: EventEmitter<{}>;
  refreshPhoto$: EventEmitter<{}>;
  refreshList$ = new EventEmitter();
  private ENDPOINT_PROFILES="/profiles";
  private ENDPOINT_DELETE_PROFILE="/profiles/";
  private ENDPOINT_GET_PROFILE="/get-profile/";
  private ENDPOINT_UPDATE_PROFILE="/update-profile/";
  private ENDPOINT_CLONE_PROFILE="/clone-profile/";
  private ENDPOINT_SALESFORCE_INTEGRATION="/saleforce/update-accesstoken";
  private ENDPOINT_DELETE_USER_PROFILE_PICTURE="/user-profile-remove";

  private ENDPOINT_CALENDLY= "/calendly-event-job-order-types"
  // previous image for when in company settings upload but cancel operation
  private previousImage: any;

  constructor(private http: HttpClient,
    private auth: AuthenticationService) { 
    this.refreshList$ = new EventEmitter(); 
    this.refreshProfilePhoto$ = new EventEmitter();
    this.refreshPhoto$ = new EventEmitter();
  }

  public getAllProfiles(){
    return this.http.get( environment.base_url+this.ENDPOINT_PROFILES).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public createProfile(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_PROFILES, body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(body);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public cloneProfile(id:any, profileName:any){
    return this.http.post( environment.base_url+this.ENDPOINT_CLONE_PROFILE + id + '?newProfileName='+profileName, null, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(resp);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public deleteProfile(id:any){
    return this.http.delete( environment.base_url+this.ENDPOINT_DELETE_PROFILE+id, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit('Deleted Profile');
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public getProfileById(id:any){
    return this.http.get( environment.base_url+this.ENDPOINT_GET_PROFILE+id).pipe(map( (resp:any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public editProfile(body:any, id:any){
    return this.http.put( environment.base_url+this.ENDPOINT_UPDATE_PROFILE+id, body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(body);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public refreshList(){
    this.refreshList$.emit();
  }
  public profilePhotoUploaded(body:any){
    this.refreshProfilePhoto$.emit(body);
  }

  public photoUploaded(body:any){
    this.refreshPhoto$.emit(body);
  }

  public integrateSalesforce(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_SALESFORCE_INTEGRATION, body, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public uploadPhotoToServerJson(url:string, body:any){
     console.log(url,"-----")
    return this.http.post( url, body, {observe : 'response'}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  public deleteProfilePicture(id:any){
    return this.http.delete( environment.base_url+this.ENDPOINT_DELETE_USER_PROFILE_PICTURE+'/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshProfilePhoto$.emit(resp);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }
  
  /** Set Previous Image
   * 
   * @param {any} imagData
   * @param {string} imageUrl
   * 
   * @return {void}
   */
   public setPreviousImage(imageData: any, imageUrl: string): void {
    this.previousImage = {
      data: imageData,
      url: imageUrl
    };
  }

  /** Get Previous Image
   * Shape of {data: <base64>, url: <string>}
   * @returns {any}
   */
  public getPreviousImage(): any {
    return this.previousImage;
  }

  /** Reset Previous Image Null
   * 
   * @return {null};
   */
  public resetPreviousImage(): void {
    this.previousImage = null;
  }


  public getTenantIntegration(){
    return this.http.get( environment.base_url+'/tenant-integration-configs').pipe(map( (resp:any) => {
    
      return resp;
    }
  ),catchError( error => {
       return observableOf( error );
    }),);
  }
  public updateTenantIntegration(body:any){
    return this.http.put( environment.base_url+'/tenant-integration-configs/multi', body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(body);
      return resp;
    }
  ),catchError( error => {
    ////console.log(error);
        return observableOf( error );
    }),);
  }

  /**------------------------- Update ------------------------*/
public getCalendlyJobOrderTypes(){
  return this.http.get( environment.base_url+this.ENDPOINT_CALENDLY).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return observableOf( error );
  }),);
}
public getZendeskTokenByCustellaToken(){
  return this.http.get( environment.base_url+'/authenticate/zendesk/token', { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  ////console.log(error);
      return observableOf( error );
  }),);
}
}
