import { of as throwError, Observable, Subject } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

import * as moment from "moment-timezone";
import { AuthenticationService } from "../auth/authentication.service";
import { Headers, Http, RequestOptions } from "@angular/http";

@Injectable()
export class ProductService {
  refreshList$: EventEmitter<{}>;
  private ENDPOINT_LIST_PRODUCTS = "/products";
  private ENDPOINT_LIST_PRODUCT_FAMILY_LIST = "/product-families";
  private ENDPOINT_SALES_ORDER_PRODUCT = "/sales-order-products";
  private ENDPOINT_JOB_ORDER_PRODUCT = "/job-order-products";
  private ENDPOINT_LIST_PRODUCT_VIEWALL = "/products?viewAll=true";
  private ENDPOINT_LIST_PRODUCT_VIEWALL_V2 = "/v4-1/products?viewAll=false";
  private ENDPOINT_ASSETS_PICKLIST = "/v4/assets/pick-list";
  private ENDPOINT_ATTACHMENTS = "/attachments/";
  private productItemsSource = new Subject<any>();
  productitemsHandler = this.productItemsSource.asObservable();

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOld: Http
  ) {
    this.refreshList$ = new EventEmitter();
  }
  setItems(items: any) {
    this.productItemsSource.next(items);
  }
  public getAllProductsByStatus() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_PRODUCT_VIEWALL)
      .pipe(
        map((resp: any) => {
          // //console.log(resp);
          // return <Admin>resp;
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public getAllProductsV2(searchValue: any, parameters: any) {
    let params = new HttpParams();
    if (searchValue) {
      params = params.set("search", searchValue);
    }
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_PRODUCT_VIEWALL_V2, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public getAllProducts() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_PRODUCTS)
      .pipe(
        map((resp: any) => {
          // //console.log(resp);
          // return <Admin>resp;
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public getAllProductsPicklist() {
    return this.http.get(environment.base_url + "/v4/products/pick-list").pipe(
      map((resp: any) => {
        // //console.log(resp);
        // return <Admin>resp;
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error);
      })
    );
  }
  public getAssetsPicklist() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_ASSETS_PICKLIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAllProductFamilies() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_PRODUCT_FAMILY_LIST)
      .pipe(
        map((resp: any) => {
          // //console.log(resp);
          // return <Admin>resp;
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteProductFamily(id: string) {
    return this.http
      .delete(
        environment.base_url +
          this.ENDPOINT_LIST_PRODUCT_FAMILY_LIST +
          "/by-id/" +
          id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Product Family");
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public createProduct(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_LIST_PRODUCTS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public editProduct(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_LIST_PRODUCTS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteProduct(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_LIST_PRODUCTS + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Product");
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public createSalesOrderProduct(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SALES_ORDER_PRODUCT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public editSalesOrderProduct(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SALES_ORDER_PRODUCT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteSalesOrderProduct(id: any) {
    return this.http
      .delete(
        environment.base_url + this.ENDPOINT_SALES_ORDER_PRODUCT + "/" + id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Product");
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public createJobOrderProduct(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_JOB_ORDER_PRODUCT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public editJobOrderProduct(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_JOB_ORDER_PRODUCT, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteJobOrderProduct(id: any) {
    return this.http
      .delete(
        environment.base_url + this.ENDPOINT_JOB_ORDER_PRODUCT + "/" + id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Product");
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public getAllProductType() {
    return this.http.get(environment.base_url + "/product-types").pipe(
      map((resp: any) => {
        // //console.log(resp);
        // return <Admin>resp;
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error);
      })
    );
  }

  public getAllProductUnitOfMeasurement() {
    return this.http.get(environment.base_url + "/product-measure-units").pipe(
      map((resp: any) => {
        // //console.log(resp);
        // return <Admin>resp;
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error);
      })
    );
  }
  public getProductById(id: any) {
    return this.http.get(environment.base_url + "/products/" + id).pipe(
      map((resp: any) => {
        // //console.log(resp);
        // return <Admin>resp;
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error);
      })
    );
  }

  public getProductByIdJo(id: any) {
    return this.http
      .get(environment.base_url + "/job-order-products/" + id)
      .pipe(
        map((resp: any) => {
          // //console.log(resp);
          // return <Admin>resp;
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  public getProductPickListSearchable(partName: any) {
    return this.http
      .get(environment.base_url + "/v4/products/pick-list?name=" + partName)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  //**================================== History tab =========================*/
  public geProductHistory(
    startFrom: any,
    perPage: any,
    search: any,
    productId: any,
    columnName: any,
    sortOrder: any
  ) {
    let params = new HttpParams();
    if (startFrom) params = params.set("startFrom", startFrom);
    if (perPage) params = params.set("perPage", perPage);
    if (search) params = params.set("search", search);
    if (columnName) params = params.set("columnName", columnName);
    if (sortOrder) params = params.set("sortOrder", sortOrder);
    if (productId) params = params.set("productId", productId);
    return this.http
      .get(environment.base_url + "/bin-inventory-histories", {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== Get product picklist by search =========================*/
  public getProductPickListBySearchValue(searchValue: string) {
    this.http
      .get(
        environment.base_url +
          "/v4/products/pick-list/search?name=" +
          searchValue +
          "&limit=25"
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== Get product picklist by search product sku =========================*/
  public getProductBySku(searchValue: string) {
    return this.http
      .get(
        environment.base_url +
          "/v4/products/pick-list/search?productSku=" +
          searchValue +
          "&limit=25"
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== Upload File =========================*/
  public uploadProductPhoto(fileName: string, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });

    return this.httpOld
      .post(
        environment.base_url +
          "/attachments/upload?parentId=" +
          moment().format("x") +
          "&parentType=PRODUCT_PROFILE",
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //**================================== Delete Attachment =========================*/
  public deleteAttachment(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_ATTACHMENTS + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /******************************************* Product By checklist **************************/
   getAllProducttByChecklist(pmvId: any,checkListRecordId: any) {
    let params = new HttpParams();
    if (pmvId) { params = params.set("pmvId", pmvId);}
    if (checkListRecordId) { params = params.set("checkListRecordId", checkListRecordId);}
    return this.http.get(environment.base_url + "/v4/job-order-products",{ observe: "response", params: params }).pipe(map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error);
      })
    );
  }

  public createProductType(body: any) {
    return this.http
      .post(environment.base_url + "/product-types", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateProductType(body: any) {
    return this.http
      .put(environment.base_url + "/product-types", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getProductTypeById(id: any) {
    return this.http.get(environment.base_url + "/product-types/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteProductType(id: any) {
    return this.http
      .delete(environment.base_url + "/product-types/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Product Types");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public createProductFamily(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_LIST_PRODUCT_FAMILY_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateProductFamily(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_LIST_PRODUCT_FAMILY_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getProductFamilyById(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_LIST_PRODUCT_FAMILY_LIST+"/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }

  public createProductUnit(body: any) {
    return this.http
      .post(environment.base_url + "/product-measure-units", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateProductUnit(body: any) {
    return this.http
      .put(environment.base_url + "/product-measure-units", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getProductUnitById(id: any) {
    return this.http.get(environment.base_url + "/product-measure-units/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteProductUnit(id: any) {
    return this.http
      .delete(environment.base_url + "/product-measure-units/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Product Unit");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
 

}
