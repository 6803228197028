<div class="d-block p-1 pb-5">
    <!-- <div class="d-block mt-4 mb-3 mx-4"><span class="text-16 font-semiBold font-white">Task</span></div> -->
    <div class="custella-setup d-block my-2 mx-0">
        <div class="custella-accordion-menu accordion accordion-flush ">
            <div  class="accordion-item py-1 border-1 border-secondary border-top">
                <div routerLinkActive="active-menuitem" class="text-12 font-white cursor-pointer h-30 py-1 ps-3" id="btn_TaskAcceptDecl"  routerLink="all">{{ translate?.task.sections.sec14.singular }}</div>
            </div>
        </div>
        <div class="task-left">
            <div class="py-2 px-3 border-top border-1 border-secondary ">
                <div class="topic">
                    <div class="color-var-500 px-0 py-1 text-12 " id="title_TaskStaff"> {{ translate?.task.fields.staff.singular }}&nbsp;{{translate?.task.label.plural}}</div>
                </div>
                <ul class="my-0 ps-0 sub-topic ">
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskStaffToday" aria-labelledby="setup-header0" routerLinkActive="active-menuitem" routerLink="todayStaffTaskMapView"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.today.singular }}</span></a></li>
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskStaffTomorrow" aria-labelledby="setup-header0" routerLinkActive="active-menuitem" routerLink="upcomingStaffTaskMapView"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.tomorrow.singular }}</span></a></li>
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskStaffAllTask" aria-labelledby="setup-header0"  routerLinkActive="active-menuitem" routerLink="staffTaskMapView"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.all.singular }}&nbsp;{{translate?.task.label.plural}}</span></a></li>
                </ul>
            </div>
         </div>
        <div class="task-left">
            <div class="py-2 px-3 border-top border-1 border-secondary ">
                <div class="topic">
                    <div class="color-var-500 px-0 py-1 text-12 " id="title_TaskTeam">{{ translate?.setup.users.teams.label.singular }}&nbsp;{{translate?.task.label.plural}}</div>
                </div>
                <ul class="my-0 ps-0 sub-topic ">
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskTeamToday" aria-labelledby="setup-header0" routerLinkActive="active-menuitem" routerLink="todayTeamTaskMapView"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.today.singular }}</span></a></li>
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskTeamTomorrow" aria-labelledby="setup-header0" routerLinkActive="active-menuitem" routerLink="upcomingTeamTaskMapView"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.tomorrow.singular }}</span></a></li>
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskTeamAllTask" aria-labelledby="setup-header0"  routerLinkActive="active-menuitem" routerLink="teamTaskMapView"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.all.singular }}&nbsp;{{translate?.task.label.plural}}</span></a></li>
                </ul>
            </div>
         </div>
        <div class="task-left">
            <div class="py-2 px-3 border-top border-1 border-secondary ">
                <div class="topic">
                    <div class="color-var-500 px-0 py-1 text-12 " id="title_TaskMy">{{ translate?.task.fields.my.singular }}&nbsp;{{translate?.task.label.plural}}</div>
                </div>
                <ul class="my-0 ps-0 sub-topic ">
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskMyToday" aria-labelledby="setup-header0" routerLinkActive="active-menuitem" routerLink="todayMyTask"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.today.singular }}</span></a></li>
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskMyTomorrow" aria-labelledby="setup-header0" routerLinkActive="active-menuitem" routerLink="upcomingMyTask"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.tomorrow.singular }}</span></a></li>
                    <li class="ps-0 setup-sub-manu py-1"  id="label_TaskMyAllTask" aria-labelledby="setup-header0"  routerLinkActive="active-menuitem" routerLink="allMyTask"><a  class="accordion-body py-0 ps-1 "><span  class="no-expand-topic text-12 p-0">{{ translate?.task.fields.all.singular }}&nbsp;{{translate?.task.label.plural}}</span></a></li>
                </ul>
            </div>
         </div>
    </div>
</div>