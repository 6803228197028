import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TasksService } from "src/app/_services/tasks.service";
import { TicketsService } from "src/app/_services/tickets.service";
import * as _ from "lodash";
import { ErrorUtil } from "src/app/_utilities/error";
import { ActivatedRoute } from "@angular/router";

import * as moment from "moment-timezone";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { AppEvents } from "src/app/_models/global.data.model";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-add-conversation",
  templateUrl: "./add-conversation.component.html",
  styleUrls: ["./add-conversation.component.scss"],
})
export class AddConversationComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  addComment: any = { type: "INTERNAL" };
  attachmentsArray: any[] = [];
  submitTouched!: boolean;
  data: any;
  reply_customer: boolean = false;
  file: any;
  title: any;
  errorMessage: any;

  public disableCheckbox = false;

  private id: any;

  @ViewChild("fileUpload", { static: false })
  fileUpload!: any;

  public event: EventEmitter<any> = new EventEmitter();
  translate: any;

  constructor(
    private spinner: NgxSpinnerService,
    private ticketsService: TicketsService,
    private taskService: TasksService,
    public modalRef: BsModalRef,
    public authService: AuthenticationService,
    private errorUtil: ErrorUtil,
    private route: ActivatedRoute,
    private util: UtilServiceService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();

}

  ngOnInit() {
    this.init();
  }

  /******************************************** init ******************************************/
  private init(): void {
    this.id = this.route.snapshot.queryParamMap.get("id") ?? this.data.id;
    this.getLanguageData()
    // disable checkbox if not same ticket owner id
    const currentId = Number(this.authService.getUserId());

    const ticketOwnerId = this.data.ticketOwner
      ? this.data.ticketOwner.id
      : null;

    if (currentId !== ticketOwnerId) {
      this.disableCheckbox = true;
    }
  }

  /******************************************** change status ******************************************/
  public changeStatus($event: any) {
    this.addComment.type = $event.checked ? "PUBLIC" : "INTERNAL";
  }

  /******************************************** start upload file ******************************************/
  public uploadFile($event: any) {
    this.spinner.show();

    this.parse($event.files);
  }

  /******************************************** parse the file and upload ******************************************/
  public parse(files: FileList): void {
    const file: File = files[0] as File;

    const form = new FormData();
    form.append("file", file);

    this.ticketsService.uploadAttachment(this.id, form).subscribe(
      (res: any) => {
        this.clearUpload();

        var body = JSON.parse(res._body);
        body.fileName = file.name;
        body.calendarTime = moment(body.createdDate).calendar(
          null,
          this.util.returnCalendarFormat()
        );

        this.attachmentsArray.push(body);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.clearUpload();
        this.errorUtil.setErrorMessage(error.status, null, error.detail);
      }
    );
  }

  /******************************************** clear upload ******************************************/
  private clearUpload() {
    this.fileUpload.clear();
  }

  /******************************************** delete attachment ******************************************/
  public deleteAttachment(attachment: any) {
    this.spinner.show();

    this.taskService.deleteTaskAttachment(attachment.id).subscribe(
      (resp: any) => {
        if (resp.status == 200) {
          this.attachmentsArray = _.remove(this.attachmentsArray, (o: any) => {
            return o.id !== attachment.id;
          });

          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
        console.log(err, "===>>>");
        this.errorUtil.setErrorMessage(err);
      }
    );
  }

  /******************************************** create conversation ******************************************/
  public addNote() {
    var attachmentsArray: {
      attachmentUrl: any;
      fileName: any;
      contentContentType: any;
    }[] = [];
    if (
      (this.addComment.message && this.addComment.message.length) ||
      this.attachmentsArray.length
    ) {
      this.errorMessage = "";
      this.submitTouched = true;
      if (this.attachmentsArray.length) {
        this.attachmentsArray.forEach((a) => {
          var attachment = {
            attachmentUrl: a.attachmentUrl,
            fileName: a.fileName,
            contentContentType: a.contentContentType,
          };
          attachmentsArray.push(attachment);
        });
      }

      if (
        this.addComment.message &&
        (this.addComment.message === "" || this.addComment.message.length === 0)
      ) {
        this.addComment.message = null;
      }

      var formData = {
        message: this.addComment.message,
        type: this.addComment.type,
        ticket: {
          id: this.data.id,
        },
        attachmentList: attachmentsArray,
      };

      this.ticketsService.createConversations(formData).subscribe(
        (res: any) => {
          if (res.status === 200 || res.status === 201) {
            this.addComment.message = "";
            this.attachmentsArray = [];
            this.submitTouched = false;

            this.event.next({
              action: AppEvents.ADD_CONVERSATION_MODAL_SUCCESS,
              data: null,
            });

            this.modalRef.hide();
          }
        },
        (error) => this.errorUtil.setErrorMessage(error)
      );
    } else {
      this.errorMessage = "Please type a message or add an attachment";
    }
  }
  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
